import React from "react";

import "./ConfirmEmail.css";
import logo from "../../../assets/images/logoyellow.svg";

const PodioAuthorizationError = () => {
  return (
    <div className="confirm__email">
      <div className="background"></div>
      <div className="container">
        <h1>Podio Rate Limit Error</h1>
        <div>
          You have exhausted your podio rate limit for today, please reach out
          to us at info@thatapp.io
        </div>
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
      </div>
    </div>
  );
};

export default PodioAuthorizationError;
