import React, {useCallback, useEffect, useState} from "react";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import PlanCards from "./PlanCards";
import AlertDialogSlide from "./AlertDialogue";
import "./plan.css";
import shortid from 'shortid';
import {changePlan, getPlans, registerPlan} from "../../services/sync";
import {useDispatch, useSelector} from "react-redux";
import { toast } from "../../helpers/apiRequests";
import {getMe} from "../../services/auth";
import {setUser} from "../../redux/actions/user";

const Plans = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [data, setData]  = useState([]);
  const [plan, setPlan] = useState({});
  const [disabled, setDisabled] = useState(false);
  const user = useSelector((state) => state.user.authUser);

  const active_plan  = user?.active_plan;
  const dispatch = useDispatch();

  const reloadStore = useCallback(() => {
    getPlans()
        .then(res => {
          setData(res);
        })

    const reloadUser = async () => {
      const response = await getMe();
      dispatch(setUser(response.data));
    }

    reloadUser();
  }, [dispatch]);

  useEffect(() => {
    reloadStore();
  }, [reloadStore]);

  const handleButtonClick = (item)=> {
    setPlan(item)
    setShowDialog(true);
  }

  const performAction = () => {
    if(plan)
    {
      setDisabled(true);
      //Check if active plan exists
      if(active_plan)
      {
        //Change plan
        changePlan({
          plan_id: plan.id,
          user_plan_id: active_plan.plan_id
        }).then(res => {
          toast.notify("Your plan was changed successfully.")
          setDisabled(false)
          reloadStore(true)
          closeDialog();
        }).catch(err => {
          setDisabled(false)
          closeDialog();
        })
      } else {
        //Buy new plan
        registerPlan({
          plan_id: plan.id,
          user_plan_id: null
        }).then(res => {
          toast.notify("You have purchased this successfully.")
          setDisabled(false);
          reloadStore(true);
          closeDialog();
        }).catch(err => {
          setDisabled(false);
          closeDialog();
        })
      }
    }
  }

  const closeDialog = () => {
    setPlan({})
    setShowDialog(false)
  }

  return (
    <div>
      {data.length === 0 ? (
        <React.Fragment>
          <LinearProgress className="w-xs" color="secondary" />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div>
          <div className="color__background"></div>
            <div className="px-4">
              <div className="flex__center">
                {data.map((item) => {
                  return (
                    <PlanCards
                      key={shortid.generate()}
                      item={item}
                      mainPlanId={active_plan ? active_plan.plan_id : null}
                      onButtonClick={handleButtonClick}
                      disabled={disabled}
                    />
                  );
                })}
              </div>
              <div className="">
                <Typography
                  variant="h4"
                  style={{ margin: "4rem 0", textAlign: "center" }}
                >
                  Frequently Asked Questions
                </Typography>

                <div className="grid-2">
                  <div className="w-full sm:w-1/2 p-24">
                    <Typography className="text-20 mb-8">
                      What are your payment options?
                    </Typography>
                    <Typography className="text-16" color="textSecondary">
                      Our preferred method of payment is via credit card: we
                      accept Visa, MasterCard, American Express, Discover,
                      Diners, UnionPay and JCB cards. ThatApp has a secure
                      credit card processing system provided by Bluesnap.
                      Registered retail customers and
                      corporate/government/organizational clients can arrange
                      payments via ACH or wire transfer.
                    </Typography>
                  </div>

                  <div className="w-full sm:w-1/2 p-24">
                    <Typography className="text-20 mb-8">
                      What if I don't have a credit card?
                    </Typography>
                    <Typography className="text-16" color="textSecondary">
                      We also accept PayPal payments. Registered retail
                      customers and corporate/government/organizational clients
                      can arrange payments via ACH or wire transfer.
                    </Typography>
                  </div>

                  <div className="w-full sm:w-1/2 p-24">
                    <Typography className="text-20 mb-8">
                      Who processes my payment?
                    </Typography>
                    <Typography className="text-16" color="textSecondary">
                      Our primary processing system for credit cards is provided
                      by Bluesnap.
                    </Typography>
                  </div>

                  <div className="w-full sm:w-1/2 p-24">
                    <Typography className="text-20 mb-8">
                      How long is a quote valid?
                    </Typography>
                    <Typography className="text-16" color="textSecondary">
                      If you have registered and saved a quote, the quote will
                      remain valid for up to seven days.
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
      <AlertDialogSlide isActive={showDialog} closeDialog={closeDialog} performAction={performAction}/>
    </div>
  );
};

export default Plans;
