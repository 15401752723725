import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import sms from "../assets/sms.svg";
import LeftbarCard from "../components/LeftbarCard";
import chrome from "../assets/bxl_chrome.svg";
import email from "../assets/email.svg";
import podio from "../assets/podio.svg";
import AlertDiv from "../components/AlertDiv";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { GetAllApps, GetAllDomains } from "redux/actions/tmail";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: "191.43px",
    height: "100%",
    backgroundColor: "#F0B429",
    flexShrink: 0,
    objectFit: "cover",
  },
  fitHeight: {
    height: "216px",
    borderRadius: "5px",
  },
  contentContainer: {
    maxWidth: "278px",
  },
  roundedIcons: {
    height: "65px",
    width: "65px",
    background: "#F0B429",
    borderRadius: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  backButton: {
    backgroundColor: "#f7c948",
    boxShadow: "0px 3px 6px #00000029",
    padding: "1vh 1vw",
    outline: "none",
    textDecoration: "none",
    border: "none",
    borderRadius: "6px",
    color: "white",
    marginBottom: "1%"
  },
}));

const DashboardView = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useSelector((state) => state.user.authUser);
  const domains = useSelector((state) => state.tmail.domains);
  const [img] = React.useState(
    user?.podio_profile?.profile?.image
      ? user?.podio_profile?.profile?.image
      : "https://i.stack.imgur.com/l60Hf.png"
  );
  const tmail = useSelector((state) => state.tmail);
  const { apps } = tmail;

  const gotoLink = () => {
    const url =
      "https://chrome.google.com/webstore/detail/tmail-for-podio/nbonnidamdbokahofcindhjengelpnmc";
    window.open(url, "_blank", "noopener,noreferrer");
  };

  useEffect(() => {
    dispatch(GetAllDomains());
    dispatch(GetAllApps());
    // getRateLimit();
  }, [dispatch]);



  const handleBackButtonClick = () => {
    window.history.back();
  };

  return (
    <div>
      <div>
        <button onClick={handleBackButtonClick} className={classes.backButton}>
          Back
        </button>
      </div>

      <AlertDiv message="You have reached your credit limit, kindly subscribe now. Subscribe" />

      <div className="row">
        <div className="col-12">
          <div className="card overflow-hidden w-100 mx-0 mb-4 shadow-sm border-0">
            <div className="card-body p-0 d-flex align-items-center">
              <div className={"media position-relative " + classes.fitHeight}>
                <div className={classes.avatar}>
                  <img
                    src={img}
                    width="100%"
                    className={classes.avatar}
                    alt=""
                  />
                </div>
                <div className="media-body h-100 py-5 ml-4">
                  <h4 className="font-weight-bold mb-3">
                    Welcome {user?.user?.name}!
                  </h4>
                  <div className={classes.contentContainer + " row"}>
                    <div className="col-4 mb-3">Email:</div>
                    <div className="col-8 mb-3"> {user?.user?.email}</div>
                    <div className="col-4 mb-3">Plan:</div>
                    <div className="col-8 mb-3">
                      {user?.active_plan?.plan?.name}
                    </div>
                    <div className="col-4">Domains:</div>
                    <div className="col-8">
                      <p className="text-primary">{domains.length} Domains</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-6">
          <div className="card w-100 m-0 mb-4 shadow-sm border-0">
            <div className="card-body">
              <div className="media align-items-center">
                <div className={classes.roundedIcons}>
                  <img src={sms} alt="" />
                </div>
                <div className="media-body ml-4">
                  <h6 className="mb-2">Total Email Credit</h6>
                  <h5 className="font-weight-bold">20</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-6">
          <div className="card w-100 m-0 mb-4 shadow-sm border-0">
            <div className="card-body">
              <div className="media align-items-center">
                <div className={classes.roundedIcons}>
                  <img src={sms} alt="" />
                </div>
                <div className="media-body ml-4">
                  <h6 className="mb-2">Total Credit Remaining</h6>
                  <h5 className="font-weight-bold">20</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-4">
          <LeftbarCard
            title="Browser Extension"
            body={
              <p className="text-primary" onClick={gotoLink}>
                Install Chrome
              </p>
            }
            icon={chrome}
          />
        </div>

        <div className="col-4">
          <LeftbarCard
            title="Podio Apps"
            count={`${apps.length}/${apps.length}`}
            body="Connect your podio app and start communicating with your contact"
            icon={podio}
          />
        </div>

        <div className="col-4">
          <LeftbarCard
            title="Email Campaigns"
            count="0"
            body="Send beautiful bulk email campaigns to your contact list"
            icon={email}
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardView;
