import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import deleteIcon from "../../../assets/icons/delete.svg";
import cancel from "../../../assets/icons/cancel.svg";
import "./style.css";
import Axios from "../../../config";
import { toast } from "helpers/apiRequests";

const DeleteMapperV2 = ({
  openModal,
  handleClose,
  itemData,
  removeMapper,
  connectorId,
}) => {
  const [loading, setLoading] = useState(false);

  const deleteTable = async () => {
    try {
      setLoading(true);
      const url = "/delete_table";
      const data = {
        app_id: itemData.app_id,
        credential_ids: [itemData.credential_id],
      };
      await Axios.post(url, data);
      setLoading(false);
      removeMapper();
      toast.notify(`Mapper deleted successfully`);
    } catch (error) {
      setLoading(false);
      toast.notify(error.response.message, { type: "error" });
    }
  };

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={handleClose}
        // hideBackdrop={true}
        disableBackdropClick={false}
        disableEscapeKeyDown={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="connector-modal"
      >
        <DialogContent>
          <div style={{ textAlign: "right", marginBottom: "3%" }}>
            <img alt="cancel" onClick={handleClose} src={cancel} />
          </div>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div style={{ textAlign: "center", marginBottom: "3%" }}>
                <img alt="delete" src={deleteIcon} />
              </div>
              <Typography style={{ textAlign: "center" }} gutterBottom>
                You are about to delete a Mapper. <br /> Would you like to
                proceed?
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            {/* <Button
              id="button-cancel"
              disabled={loading}
              onClick={handleClose}
              color="primary"
            >
              No
            </Button> */}
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              <Button
                // id="button-save"
                onClick={deleteTable}
                // color="primary"
                autoFocus
                style={{
                  backgroundColor: "#E12D39",
                  color: "white",
                  fontSize: "12px",
                  padding: "2% 15%",
                }}
              >
                Yes Proceed
              </Button>
            )}
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DeleteMapperV2;
