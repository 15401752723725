import React from "react";
import { Editor } from "@tinymce/tinymce-react";

const TinyEditor = (props) => {
    const { value, onChange } = props;
    // const editorRef = useRef(null);
    // const apiKey = process.env.REACT_APP_TINYMCE_KEY;
    const handleEditorChange = (content, editor) => {
        if (onChange) {
            onChange(content);
        }
    };

    return (
        <>
            <Editor
                // apiKey={apiKey}
                apiKey='i5d8bliiucjdcbvb1ujy9uvz8fjgh6hi2apv0gohq3ii3w6e'
                // onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue=""
                value={value}
                init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "code",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "code",
                        "help",
                        "wordcount",
                    ],
                    toolbar:
                        "undo redo | blocks | " +
                        "bold italic forecolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                    content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
                onEditorChange={handleEditorChange}
            />
        </>
    );
};

export default TinyEditor;
