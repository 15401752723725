import React from 'react';
import { Box, Dialog, DialogTitle, DialogContent, Typography, DialogActions } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import styles from './deleteTeamMember.module.css';

const DeleteTeamMember = (props) => {
  const { open, handleClose, loading, execute } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableEscapeKeyDown={true}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      className={styles.override}
      maxWidth='lg'>
      <Box className={styles.container}>
        <DialogTitle>
          <Typography id='modal-modal-title' variant='h6' component='p'>
            Delete User
          </Typography>
        </DialogTitle>
        <DialogActions style={{ position: 'absolute', right: 0, top: 0 }}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogContent style={{ padding: 0 }}>
          <section>
            <p>
              Are you sure you want to delete this user <br /> <b>You cannot undo this action!</b>
            </p>
          </section>

          <footer>
            <div className={styles.button_container}>
              <button onClick={handleClose}>Cancel</button>
              <button onClick={execute} disabled={loading}>
                {loading ? 'Deleting...' : 'Delete'}
              </button>
            </div>
          </footer>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default DeleteTeamMember;
