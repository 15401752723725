/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    LinearProgress,
    DialogActions,
    Select,
    MenuItem,
    InputLabel
} from "@material-ui/core";
import { toast } from "helpers/apiRequests";
import "./style.css";
import TextEditor from '../../../../containers/TmailMessage/TextEditor';
import axios from "../../../../config";
import MoveComment from './MoveComment';

const ComposeMessageModal = (props) => {
    const {
        openModal,
        handleClose,
        comment,
        changeState,
        removeComment
    } = props;
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState("");
    const [toEmail, setToEmail] = useState('');
    const [ccEmail, setCCEmail] = useState('');
    const [fromEmail, setFromEmail] = useState('');
    const [date, setDate] = useState('');
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const openMoveModal = () => {
        handleClose();
        setOpen(true);
    }

    const closeMoveModal = () => setOpen(false);


    useEffect(() => {
        const convertedCC = comment ? JSON.parse(comment.cc) : '';
        const convertedTo = comment ? JSON.parse(comment.to) : '';
        let cc;
        let to;
        if (Array.isArray(convertedCC)) {
            if (convertedCC.length === 0) {
                cc = '';
            } else {
                cc = convertedCC.join(",")
            }
        }
        if (Array.isArray(convertedTo)) {
            if (convertedTo.length === 0) {
                to = '';
            } else {
                to = convertedTo.join(",")
            }
        }

        const from = comment ? `"${comment.from_name}"  <${comment.from_email}>` : '';
        const msg = comment ? comment.message : '';
        const sub = comment ? comment.subject : '';
        const createdAt = comment ? comment.created_at : '';
        setCCEmail(cc);
        setMessage(msg);
        setFromEmail(from);
        setDate(createdAt);
        setToEmail(to);
        setSubject(sub);
    }, [openModal]);

    const deleteComment = async (action) => {
        try {
            setLoading(true);
            handleClose();
            let url = `/tmail/comment-moderation/delete?tmail_app_id=${comment.appId}&tmail_comment_id=${comment.id}`;
            if (action === "blacklist") {
                url = `/tmail/comment-moderation/delete?tmail_app_id=${comment.appId}&tmail_comment_id=${comment.id}&blacklist=1`;
            }

            const res = await axios.post(url);
            if (res.success === true) {
                removeComment();
            }
            setLoading(false);
            toast.notify(res.message);
        } catch (error) {
            setLoading(false);
            toast.notify(error.message, { type: "error" });
        }
    }

    const messageChangeHandler = (value) => {
        setMessage(value)
    }
    const changeSubject = e => setSubject(e.target.value);

    const updateComment = async () => {
        try {
            setLoading(true);
            // handleClose();
            const url = "/tmail/comment-moderation/update"
            const payload = {
                tmail_app_id: comment.appId,
                tmail_comment_id: comment.id,
                name: comment.from_name,
                email: comment.from_email,
                subject,
                message,
            };
            const res = await axios.post(url, payload);
            if (res.success === true) {
                const newComment = { ...comment }
                newComment.name = payload.name;
                newComment.email = payload.email;
                newComment.subject = payload.subject;
                newComment.message = payload.message;
                changeState(newComment);
            }

            setLoading(false);
            toast.notify(res.message);
        } catch (error) {
            setLoading(false);
            toast.notify(error.message, { type: "error" });
        }
    }

    const fixedStyle = {
        width: "100%",
        height: "60px",
        position: "absolute",
        top: "0",
        right: "0",
        left: "0",
        background: "#ffffff",
        zIndex: "10",
        display: "flex",
        alignItems: "center",
        padding: "1.4rem",
    }

    const bottomFixedStyle = {
        width: "100%",
        height: "60px",
        position: "absolute",
        bottom: "0",
        right: "0",
        left: "0",
        background: "#ffffff",
        zIndex: "10",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    }

    return (
        <div >
            <MoveComment
                openModal={open}
                handleClose={closeMoveModal}
                comment={comment}
                removeComment={removeComment}
            />
            <Dialog
                open={openModal}
                onClose={handleClose}
                // hideBackdrop={true}
                disableEscapeKeyDown={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div style={{ overflowY: "scroll", maxHeight: "90%", width: "100%" }}>

                    <DialogContent>
                        <div style={fixedStyle}>
                            <h5>Moderate Email Comment</h5>
                        </div>
                        <div className="row jsutify-content-center mt-3 pt-5 pb-5">
                            <div className="col-md-12">
                                <div className="form-row">
                                    <div className="form-group col-md-2">
                                        <label
                                            style={{
                                                fontWeight: "normal",
                                                fontSize: "18px",
                                                marginTop: "5px"
                                            }}>
                                            Date
                                        </label>
                                    </div>
                                    <div className="form-group col-md-10">
                                        <input
                                            className="form-control"
                                            name="date"
                                            value={date}
                                            type="text"
                                            readOnly
                                        />

                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-2">
                                        <label
                                            style={{
                                                fontWeight: "normal",
                                                fontSize: "18px",
                                                marginTop: "5px"
                                            }}>
                                            From
                                        </label>
                                    </div>
                                    <div className="form-group col-md-10">
                                        <input
                                            className="form-control"
                                            name="fromEmail"
                                            value={fromEmail}
                                            type="text"
                                            readOnly
                                        />

                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-2">
                                        <label
                                            style={{
                                                fontWeight: "normal",
                                                fontSize: "18px",
                                                marginTop: "5px"
                                            }}>
                                            To
                                        </label>
                                    </div>
                                    <div className="form-group col-md-10">
                                        <input
                                            className="form-control"
                                            name="toEmail"
                                            value={toEmail}
                                            type="text"
                                            readOnly
                                        />

                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-2">
                                        <label
                                            style={{
                                                fontWeight: "normal",
                                                fontSize: "18px",
                                                marginTop: "5px"
                                            }}>
                                            CC
                                        </label>
                                    </div>
                                    <div className="form-group col-md-10">
                                        <input
                                            className="form-control"
                                            name="cc"
                                            value={ccEmail}
                                            type="text"
                                            readOnly
                                        />

                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-2">
                                        <label
                                            style={{
                                                fontWeight: "normal",
                                                fontSize: "18px",
                                                marginTop: "5px"
                                            }}>
                                            Subject
                                        </label>
                                    </div>
                                    <div className="form-group col-md-10">
                                        <input
                                            className="form-control"
                                            name="subject"
                                            value={subject}
                                            type="text"
                                            onChange={(e) => changeSubject(e)}
                                        />

                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <TextEditor
                                            change={messageChangeHandler}
                                            msg={message}
                                        />

                                    </div>
                                </div>
                                {
                                    loading ?
                                        <center className="mt-3 mb-3">
                                            <LinearProgress />
                                        </center> : null
                                }
                                <div className="form-row">
                                    <div className="form-group col-md-4 mt-2 ml-2">
                                        <InputLabel id="demo-simple-select-label" style={{ marginBottom: "-15px" }}>Action</InputLabel>
                                        <Select
                                            id="demo-simple-select"
                                            labelId="demo-simple-select-label"
                                            style={{
                                                marginTop: "-10px"
                                            }}
                                        >
                                            <MenuItem
                                                value="deleteItem"
                                                onClick={() => deleteComment("delete")}
                                            >
                                                <i className='fa fa-trash text-danger'></i>&nbsp;&nbsp;
                                                {"Delete Comment"}
                                            </MenuItem>
                                            <MenuItem
                                                value="blacklistItem"
                                                onClick={() => deleteComment("blacklist")}
                                            >
                                                <i className="fa fa-fan text-dark"></i>&nbsp;&nbsp;
                                                {"Spam: Delete And Blacklist"}
                                            </MenuItem>
                                            <MenuItem
                                                value="moveItem"
                                                onClick={openMoveModal}
                                            >
                                                <i className="fa fa-arrows-alt text-dark"></i>&nbsp;&nbsp;
                                                {"Move to another App"}
                                            </MenuItem>
                                        </Select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <div style={bottomFixedStyle}>
                            <div className='col-md-4'>
                                <button
                                    className='btn btn-block'
                                    type='button' style={{
                                        color: "#fff",
                                        background: "#F7C948",
                                    }}
                                    onClick={updateComment}
                                >Save</button>
                            </div>
                            <div>
                                <button
                                    className='btn btn-secondary mr-3'
                                    onClick={handleClose}>
                                    Close
                                </button>
                            </div>
                        </div>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    )

}
export default ComposeMessageModal