import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
  container: {
    width: '270px',
    background: '#f7f7f7',
  },
  menuList: {
    fontSize: '14px',
  },
  mainButton: { width: '100%', fontSize: '16px', boxShadow: 'none', height: '46px', textTransform: 'none' },
  disabled: { opacity: 0.9 },
  outlinedButton: {
    color: '#28a745',
    borderColor: '#28a745',

    '&:hover': {
      backgroundColor: '#28a745',
      color: '#fff',
    },
  },
}));

const extensionOptions = [
  { label: 'Portable Document Format (PDF)', value: 'pdf' },
  { label: 'Microsoft Word (Docx)', value: 'docx' },
  { label: 'Microsoft Word (Doc)', value: 'doc' },
  { label: 'Rich Text Format (RTF)', value: 'rtf' },
  { label: 'Hypertext Markup Language (HTML)', value: 'html' },
];

export default function SplitButton({ type = 'button', options = extensionOptions, label = '', disabled = true, onClick, disabledOptions = [] }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleMenuItemClick = (_event, index) => {
    onClick(options[index].value);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Grid container direction='column' className='mb-3'>
      <Button
        onClick={handleToggle}
        className={`${classes.mainButton} ${disabled ? classes.disabled : ''} ${classes.outlinedButton}`}
        variant='outlined'
        disabled={disabled}
        type={type}
        ref={anchorRef}>
        {label} <ArrowDropDownIcon />
      </Button>

      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id='split-button-menu' className={classes.container}>
                  {options.map((option, index) => (
                    <MenuItem
                      key={index}
                      disabled={disabledOptions.includes(option.value)}
                      onClick={(event) => handleMenuItemClick(event, index)}
                      className={classes.menuList}>
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Grid>
  );
}
