export const USER_ACCOUNT = '/dashboard/account';
export const PLANS = '/dashboard/account?section=plans';
export const MY_INVOICES = '/dashboard/invoices';
export const PLATFORM_USERS = '/dashboard/platform';
export const PLATFORM_REFFERALS = '/dashboard/referrals';
export const MY_PARTNERS = '/dashboard/my-partner';
export const PODIO_PARTNERS = '/dashboard/podio-partners';
export const THATAPP_TRAFFIC = '/dashboard/app-traffic';
export const CONNECTOR_BACKUP = '/dashboard/connector/backup';
export const MAPPER = '/dashboard/mappers/:orgId/:credentialId';
export const CONNECTOR = '/dashboard/connector';
export const CONNECT_PRINT = '/connect-print';

// Print routes
export const PRINT_HOME = '/print-home';
export const PRINT_DATA_EDIT = '/print-data/edit/:printId';
export const PRINT_CREATE_TEMPLATE = '/create-template';
export const PRINT_RIGHT_SIGNATURE_REDIRECT = '/print-data/signature/auth';

// Tmail Routes
export const CONNECT_TMAIL = '/connect-tmail';
export const TMAIL_DASHBOARD = '/tmail/dashboard';
export const TMAIL_DASHBOARDV2 = '/dashboard/tmail';
export const TMAIL_DASHBOARD_APPS = '/tmail/dashboard/apps';
export const TMAIL_DASHBOARD_APPSV2 = '/dashboard/tmail/apps';
export const ADD_APP = '/tmail/dashboard/apps/new';
export const ADD_APP_V2 = '/dashboard/tmail/apps/new';
export const DOMAINS = '/tmail/dashboard/domains';
export const DOMAINSV2 = '/dashboard/tmail/domains';
export const MEMBERS = '/tmail/dashboard/members';
export const MEMBERSV2 = '/dashboard/tmail/members';
export const COMING_SOON = '/dashboard/tmail/coming_soon';
export const CAMPAIGN = '/tmail/dashboard/campaigns';
export const CAMPAIGNV2 = '/dashboard/tmail/campaigns';
export const CONNECT_TMAILV2 = '/dashboard/connect/tmail';
export const APP_SETTING = '/dashboard/tmail/apps/setting/:appId';
export const COMMENT_MODERATE = '/dashboard/tmail/comment-moderate/:appId';
export const ITEM_COMMENTS = '/dashboard/tmail/comments';
export const ADD_MEMBERS = '/dashboard/tmail/add-members';
export const ORPHAN_EMAIL = '/dashboard/tmail/orphan-emails';
