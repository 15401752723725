import React from 'react'
import Modal from '@material-ui/core/Modal';
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import { LiaTimesSolid } from "react-icons/lia";
import { useDispatch } from 'react-redux';
import { resyncApp } from 'redux/actions/tmail';


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalContent: {
    position: 'relative',
    width: '387px',
    height: '222px',
    borderRadius: '6px',
    background: '#FFF',
    padding: '39px 33px'
  },
  footer: {
    position: 'absolute',
    left: '0px',
    bottom: '0px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0px 32px',
    width: '387px',
    height: '64px',
    flexShrink: '0',
    borderRadius: '6px 6px',
    background: '#FFFAEB'
  },
  cancelBtn: {
    width: '80px',
    height: '32px',
    flexShrink: "0",
    background: 'transparent',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
    textTransform: "capitalize"
  },
  deleteBtn: {
    width: '80px',
    height: '32px',
    flexShrink: "0",
    borderRadius: "3px",
    background: '#3EBD93',
    color: '#FFF',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
    textTransform: "capitalize"
  },
  headerTexts: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px'
  }, 
  headerText: {
    color: "#222",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal"
  },
  icon: {
    fontSize: '24px'
  },
  text: {
    color: "#7E7E7E",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16.8px"
  }
}));

const ResyncModal = ({ open, onClose, payload }) => {
  const classes = useStyles()
  const dispatch = useDispatch();

  const handleResyncAction = () => { 
    dispatch(resyncApp(payload))
    onClose()
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modal}
    >
      <div className={classes.modalContent}>
        <div className={classes.headerTexts}>
          <h4 className={classes.headerText}>Resync</h4>
          <LiaTimesSolid className={classes.icon} onClick={onClose} />
        </div>
        <p className={classes.text}>Are you sure you want to resync connected app?</p>
        <div className={classes.footer}>
          <Button type='button' onClick={onClose} className={classes.cancelBtn}>Cancel</Button>
          <Button type='button' onClick={handleResyncAction} className={classes.deleteBtn}>Yes</Button>
        </div>
      </div>
    </Modal>
  )
}

export default ResyncModal