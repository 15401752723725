/* eslint-disable no-unused-vars */
import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { connect, useDispatch } from "react-redux";
import Typography from "@material-ui/core/Typography";
// import ClearIcon from "@material-ui/icons/Clear";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { useDrag } from "react-dnd";
import { ItemTypes } from "../../../../../constants/ItemTypes";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Popover from "@material-ui/core/Popover";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import {
  updateFieldDetail,
} from "../../../../../redux/actions/appBuilder";
import CustomButton from "../../../../../components/atoms/CustomButton";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  section: {
    marginTop: "25px",
    height: "120px",
    width: "100%",
    backgroundColor: "#fffffffa",
  },
  fieldPaper: {
    "&:hover $clearIndicatorDirty, & .Mui-focused $clearIndicatorDirty": {
      visibility: "visible"
    },
    backgroundColor: "#F7F7F7",
    border: "1px solid #B1B1B1",
    height: "70px",
  },
  popover: {
    width: "200px",
padding: "10px",
    borderRadius: theme.shape.borderRadius,
  },
  checkboxLabel: {
    fontSize: "14px",
  },
  fieldPallete: {
    borderRadius: "3px",
  },
  clearIndicatorDirty: {},
  clearIndicator: {
    visibility: "hidden"
  },
}));

const DraggableFieldEmail = ({
  view,
  removeSelectedField,
  fieldId,
  sectionId,
  columnType,
  fieldDetails,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [helpTextAnchorEl, setHelpTextAnchorEl] = useState(null);
  const [hoverOnField, setHoverOnField] = useState(false);

  const [label, setLabel] = useState(
    fieldDetails?.config?.label ? fieldDetails.config.label : "Email"
  );

  const [requiredField, setRequired] = useState(
    fieldDetails?.config?.required
      ? fieldDetails.config.required
      : false
  );
  const [hiddenIfEmpty, setHiddenIfEmpty] = useState(
    fieldDetails?.config?.hidden
      ? fieldDetails.config.hidden
      : false
  );

  const [alwaysHidden, setAlwaysHidden] = useState(
    fieldDetails?.config?.hidden_create_view_edit ? fieldDetails.config.hidden_create_view_edit : false);

  const [status] = useState(
    fieldDetails?.status ? fieldDetails.status : "active"
  );

  const [defaultValue] = useState(
    fieldDetails?.config?.default_value ?
      fieldDetails.config.default_value :
      null
  );

  const [unique] = useState(
    fieldDetails?.config?.unique ?
      fieldDetails.config.unique :
      false
  )

  const [description, setDescription] = useState(
    fieldDetails?.config?.description ?
      fieldDetails.config.description :
      "Add Help Text"
  )

  const [settings] = useState(
    fieldDetails?.config?.settings ?
      fieldDetails.config.settings :
      {}
  )
  const [storeEmail, setStoreEmail] = useState(
    fieldDetails?.config?.settings?.include_in_cc ? "cc" :
      (
        fieldDetails?.config?.settings?.include_in_bcc ? "bcc" : "no"
      )
  );

  const [includeInCc, setIncludeInCc] = useState(
    fieldDetails?.config?.settings?.include_in_cc ? fieldDetails.config.settings.include_in_cc : false);
  const [includeInBcc, setIncludeInBcc] = useState(
    fieldDetails?.config?.settings?.include_in_bcc ? fieldDetails.config.settings.include_in_bcc : false);

  const [externalId] = useState(
    fieldDetails?.external_id
      ? fieldDetails.external_id : null
  );

  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    item: {
      label,
      type: ItemTypes.FIELD_EMAIL,
      status,
      config: {
        label,
        required: requiredField,
        hidden: hiddenIfEmpty,
        default_value: defaultValue,
        description,
        settings: {
          ...settings,
          include_in_bcc: includeInBcc,
          include_in_cc: includeInCc
        },
        unique,
        hidden_create_view_edit: alwaysHidden
      }
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? "simple-popover" : undefined;

  const openHelpPopover = Boolean(helpTextAnchorEl);
  const helpTextID = openHelpPopover ? "help-text-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleHelpTextPopoverClose = () => {
    setHelpTextAnchorEl(null)
  }

  const handleHelpTextPopOverClick = (event) => {
    setHelpTextAnchorEl(event.currentTarget)
  }

  useEffect(() => {
    let config = {
      label,
      required: requiredField,
      hidden: hiddenIfEmpty,
      default_value: defaultValue,
      description,
      settings: {
        ...settings,
        include_in_bcc: includeInBcc,
        include_in_cc: includeInCc
      },
      unique,
      hidden_create_view_edit: alwaysHidden
    };

    let payload = {
      config,
      ...((externalId) && { external_id: externalId }),
      ...((status) && { status: status }),
      sectionId, fieldId: fieldId, columnType, label
    };

    dispatch(updateFieldDetail(payload));
  }, [label, requiredField, hiddenIfEmpty, alwaysHidden, sectionId, fieldId, columnType, dispatch, storeEmail, defaultValue, description, settings, includeInBcc, includeInCc, unique, externalId, status]);

  const handleStoreEmailChange = (value) => {
    setStoreEmail(value)
    if (value === "cc") {
      setIncludeInCc(true)
    }
    if (value === "bcc") {
      setIncludeInBcc(true)
    }
  }
  return (
    <Fragment>
      {view === "pallete" ? (
        <div className={classes.fieldPallete}>
          <div
            className="pb-2 pt-2"
            ref={dragPreview}
            style={{ opacity: isDragging ? 0.5 : 1 }}
          >
            <div className="row" ref={drag}>
              <div className="col-md-12">
                <Paper className={classes.fieldPaper}>
                  <Typography className="pt-4 ml-3">
                    <MailOutlineIcon size="small" />
                    <span className="ml-3">Email</span>
                  </Typography>
                </Paper>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.field}>
          <div
            className="pb-2 pt-2"
            ref={dragPreview}
            style={{ opacity: isDragging ? 0.5 : 1 }}
          >
            <div className="row">
              <div className="col-md-12">
                <Paper
                  className={classes.fieldPaper}
                  onMouseEnter={() => setHoverOnField(true)}
                  onMouseLeave={() => setHoverOnField(false)}
                >
                  <div className="row ml-1 mt-2">
                    <div className="col-md-9">
                      <TextField
                        style={{
                          backgroundColor: "white",
                          marginBottom: "10px",
                        }}
                        variant="outlined"
                        autoFocus
                        margin="dense"
                        value={label}
                        onChange={(e) => {
                          setLabel(e.target.value);
                        }}
                        id="name"
                        type="text"
                        size="small"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <MailOutlineIcon
                                style={{ cursor: "pointer" }}
                                onClick={handleClick}
                              />
                              <Popover
                                id={id}
                                open={openPopover}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                classes={{
                                  paper: classes.popover,
                                }}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "center",
                                }}
                                transformOrigin={{
                                  vertical: "bottom",
                                  horizontal: "center",
                                }}
                              >
                                <div className="" style={{ padding: "10px" }}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        color="primary"
                                        checked={requiredField}
                                        onChange={(e) => {
                                          setRequired(!requiredField);
                                          setHiddenIfEmpty(requiredField);
                                          setAlwaysHidden(requiredField);
                                        }}
                                        id="required_field"
                                        name="required_field"
                                      />
                                    }
                                    classes={{
                                      label: classes.checkboxLabel,
                                    }}
                                    label="Required Field"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        color="primary"
                                        checked={hiddenIfEmpty}
                                        onChange={(e) => {
                                          setHiddenIfEmpty(!hiddenIfEmpty);
                                          setRequired(hiddenIfEmpty);
                                          setAlwaysHidden(hiddenIfEmpty);
                                        }}
                                        id="hidden_if_empty"
                                        name="hidden_if_empty"
                                      />
                                    }
                                    classes={{
                                      label: classes.checkboxLabel,
                                    }}
                                    label="Hidden if empty"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        color="primary"
                                        checked={alwaysHidden}
                                        onChange={(e) => {
                                          setAlwaysHidden(!alwaysHidden);
                                          setHiddenIfEmpty(alwaysHidden);
                                          setRequired(alwaysHidden);
                                        }}
                                        id="always_hidden"
                                        name="always_hidden"
                                      />
                                    }
                                    classes={{
                                      label: classes.checkboxLabel,
                                    }}
                                    label="Always hidden"
                                  />
                                  <hr />
                                  <i>Store email in comments</i>
                                  <RadioGroup
                                    required={true}
                                    aria-label="store_email"
                                    id="store_email"
                                    aria-describedby="Store email in comments"
                                    name="store_email"
                                    value={storeEmail}
                                    style={{ fontSize: "14px" }}
                                    onChange={(e) => {
                                      handleStoreEmailChange(e.target.value);
                                    }}
                                  >
                                    <FormControlLabel
                                      value="no"
                                      aria-describedby="Select Line Type"
                                      control={<Radio color="primary" />}
                                      classes={{
                                        label: classes.checkboxLabel,
                                      }}
                                      label="Don't store"
                                    />
                                    <FormControlLabel
                                      value="cc"
                                      control={<Radio color="primary" />}
                                      classes={{
                                        label: classes.checkboxLabel,
                                      }}
                                      label="Store via link in Cc"
                                    />
                                    <FormControlLabel
                                      value="bcc"
                                      control={<Radio color="primary" />}
                                      classes={{
                                        label: classes.checkboxLabel,
                                      }}
                                      label="Store via link in Bcc"
                                    />
                                  </RadioGroup>
                                  <hr />
                                  <div onClick={handleHelpTextPopOverClick} style={{ cursor: "pointer" }}>
                                    <h6>Add Help Text</h6>
                                  </div>
                                  <hr />
                                </div>
                              </Popover>
                              <Popover
                                id={helpTextID}
                                open={openHelpPopover}
                                anchorEl={helpTextAnchorEl}
                                onClose={handleHelpTextPopoverClose}
                                classes={{
                                  paper: classes.optionPopover,
                                }}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "center",
                                }}
                                transformOrigin={{
                                  vertical: "bottom",
                                  horizontal: "center",
                                }}
                                style={{ width: "auto" }}
                              >
                                <div className="" style={{ padding: "10px" }}>
                                  <TextField
                                    label={"Description"}
                                    multiline
                                    style={{
                                      paddingBottom: "10px"
                                    }}
                                    placeholder="Enter help text"
                                    value={description}
                                    onChange={e => setDescription(e.target.value)}
                                    fullWidth
                                    type="text"
                                    margin="none"
                                  />
                                  <br />
                                  <CustomButton
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    style={{ paddingLeft: "10px" }}
                                    onClick={handleHelpTextPopoverClose}
                                  >
                                    Close
                    </CustomButton>
                                </div>
                              </Popover>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <div className="col-md-3 mt-2">
                      {/* <ClearIcon
                        className={clsx(classes.clearIndicator, {
                          [classes.clearIndicatorDirty]: hoverOnField
                        })}
                        size="small"
                        onClick={() =>
                          removeSelectedField(
                            fieldId,
                            sectionId,
                            columnType,
                            fieldDetails?.external_id
                          )
                        }
                        style={{
                          float: "right",
                          marginBottom: "10px",
                          marginRight: "5px",
                          fontSize: "20px",
                          fontWeight: "900",
                          cursor: "pointer",
                        }}
                      /> */}
                    </div>
                  </div>
                </Paper>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state, ownProps) => ({
  fieldDetails:
    !ownProps.columnType && !ownProps.sectionId && !ownProps.fieldId
      ? {}
      : state.app_builder.appSections
        .find((section) => section.id === ownProps.sectionId)
        .columns[ownProps.columnType].find(
          (field) => field.field_id === ownProps.fieldId
        ),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DraggableFieldEmail);
