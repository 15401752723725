import React from 'react';
import dayjs from 'dayjs';
import mastercard from '../../assets/icons/mastercard.svg';
import visa from '../../assets/icons/visa.svg';
import defaultCard from '../../assets/icons/default_card.svg';
import styles from './UserCard.module.scss';

const UserCard = (props) => {
  const selectedCardDetails = props?.selectedCardDetails;

  return (
    <div className={styles.container} key={selectedCardDetails?.hidden_id}>
      <img
        src={
          selectedCardDetails?.card_brand.toLowerCase() === 'visa'
            ? visa
            : selectedCardDetails?.card_brand.toLowerCase() === 'mastercard'
            ? mastercard
            : defaultCard
        }
        alt={selectedCardDetails?.card_brand.toLowerCase()}
        className={styles.card_brand}
      />
      <div>
        <p className={styles.mask}>
          {selectedCardDetails?.card_brand}: <span>....</span>
          {selectedCardDetails?.last_four_digit}
        </p>
        <p className={styles.created}>Added {dayjs(selectedCardDetails?.created_at).format('ddd, MMM DD YYYY')}</p>
      </div>
    </div>
  );
};

export default UserCard;
