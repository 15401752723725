/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  LinearProgress,
} from "@material-ui/core";
import { toast } from "helpers/apiRequests";
import { useSelector } from "react-redux";
import NewConnectorDetails from "./NewConnectorDetails";
import Axios from "../../config";
import { makeStyles } from "@material-ui/core/styles";
import cancel from "../../assets/icons/cancel.svg";

const useStyles = makeStyles((theme) => ({
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #F7F7F7",
    paddingBottom: "3%",
    marginBottom: "3%",
  },
  headerText: {
    color: "black",
  },
  btnContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  btnDesc: {
    color: "#DE911D",
    fontSize: "15px",
    marginBottom: "3%",
  },
}));

const ExternalConnector = ({
  openModal,
  handleClose,
  addConnectors,
  selectedConnector,
  isEdited,
  updateConnector,
}) => {
  const classes = useStyles();
  const [saveLoading, setSaveLoading] = useState(false);
  const [inputData, setInputData] = useState({
    db_username: "",
    db_password: "",
    db_schema: "",
    db_driver: "",
    db_host: "",
    db_port: "",
    db_name: "",
  });
  const [responseData, setResponseData] = useState({
    show: false,
    data: [],
  });
  const [org, setOrg] = useState("");

  const orgs = useSelector((state) => state.sync.orgs);
  const orgOptions =
    orgs.length > 0
      ? orgs.map((org) => {
          return (
            <option key={org.org_id} value={org.org_id}>
              {org.name}
            </option>
          );
        })
      : null;

  const changeOrganisation = (e) => {
    const { value } = e.target;
    setOrg(value);
  };
  const handlResponseChange = (val) => {
    setResponseData({
      show: true,
      data: [...val],
    });
    handleClose();
    setOpen(true);
  };
  const [open, setOpen] = useState(false);

  const handleModalClose = () => {
    setOpen(false);
  };

  const testConnection = () => {
    setSaveLoading(true);
    if (
      db_driver === "" ||
      db_host === "" ||
      db_name === "" ||
      db_password === "" ||
      db_port === "" ||
      db_username === ""
    ) {
      toast.notify("Please fill all required fields", { type: "info" });
      setSaveLoading(false);
      return;
    }
    const payload = {
      ...inputData,
      test_connection: 1,
      org_id: org,
    };
    const url = `/create-user-db/external`;
    Axios.post(url, payload)
      .then((res) => {
        setSaveLoading(false);
        toast.notify("Connection verified successfully");
      })
      .catch((err) => {
        setSaveLoading(false);
        toast.notify("Connection could not be made. Check your inputs", {
          type: "error",
        });
      });
  };

  useEffect(() => {
    if (selectedConnector != null) {
      setInputData({
        db_username: selectedConnector.db_username || "",
        db_password: selectedConnector.db_password || "",
        db_schema: selectedConnector.db_schema || "",
        db_driver: selectedConnector.db_driver || "",
        db_host: selectedConnector.db_host || "",
        db_port: selectedConnector.db_port || "",
        db_name: selectedConnector.db_name || "",
      });
      setOrg(selectedConnector.org_id || "");
    }
  }, [openModal]);

  const handleClose_ = () => {
    handleClose();
  };

  const {
    db_driver,
    db_host,
    db_name,
    db_password,
    db_port,
    db_schema,
    db_username,
  } = inputData;
  const handleInputChanges = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  const saveExternalConnector = async (e) => {
    try {
      e.preventDefault();
      setSaveLoading(true);
      if (
        org === "" ||
        db_driver === "" ||
        db_host === "" ||
        db_name === "" ||
        db_password === "" ||
        db_port === "" ||
        db_username === ""
      ) {
        toast.notify("Please fill all required fields", { type: "info" });
        setSaveLoading(false);
        return;
      }
      const payload = {
        ...inputData,
        org_id: org,
        credential_id: isEdited ? selectedConnector.id : null,
      };
      const url = isEdited
        ? "/update-user-db/external"
        : `/create-user-db/external`;
      const res = await Axios.post(url, payload);
      const selectedOrg = orgs.find((where) => where.org_id === Number(org));
      const newData = res.data;
      if (isEdited) {
        updateConnector(newData[0]);
      } else {
        addConnectors(newData);
      }
      handlResponseChange(res.data);
      setSaveLoading(false);
      setInputData({
        db_username: "",
        db_password: "",
        db_schema: "",
        db_driver: "",
        db_host: "",
        db_port: "",
        db_name: "",
      });
      toast.notify(res.message);
    } catch (error) {
    
      setSaveLoading(false);
      toast.notify(error?.response?.message, { type: "error" });
    }
  };

  return (
    <div>
      <NewConnectorDetails
        openModal={open}
        handleClose={handleModalClose}
        responseData={responseData}
      />
      <Dialog
        open={openModal}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            openModal(false);
          }
        }}
        // hideBackdrop={true}
        disableEscapeKeyDown={true}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        id="connector-modal"
        fullWidth="md"
        scroll="body"
      >
        <div>
          <DialogContent>
            <DialogContent>
              <DialogContentText id="scroll-dialog-description">
                <div style={{ overFlowX: "visible" }}>
                  <form>
                    <div className={classes.modalHeader}>
                      <h5 className={classes.headerText}>
                        Create External Connection
                      </h5>
                      <div>
                        <img
                          onClick={handleClose_ }
                          alt="cancel"
                          src={cancel}
                        />
                      </div>
                    </div>

                    <div className={classes.btnDesc}>
                      <span>Whitelist this IP Address : </span>
                      <span>159.65.226.21</span>
                    </div>
                    <div className="form-group">
                      <label htmlFor="app">
                        Select Organization{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control"
                        value={org}
                        onChange={changeOrganisation}
                      >
                        <option>Select An Organization</option>
                        {orgOptions}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="driver">
                        Drivers <span className="text-danger">*</span>
                      </label>
                      <select
                        name="db_driver"
                        id="db_driver"
                        className="form-control"
                        value={db_driver}
                        onChange={handleInputChanges}
                        required
                      >
                        <option value="">Select Drivers</option>
                        <option value={"mysql"}>MySql</option>
                        <option value={"pgsql"}>PostgreSql</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="db_schema">Database Schema</label>
                      <input
                        type="text"
                        name="db_schema"
                        className="form-control"
                        value={db_schema}
                        onChange={handleInputChanges}
                        placeholder="database schema (optional)"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="db_host">
                        Database Host <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="db_host"
                        className="form-control"
                        value={db_host}
                        onChange={handleInputChanges}
                        required
                        placeholder="192.178.190.19, us-cdbr-east-05.cleardb.net"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="db_port">
                        Database Port <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="db_port"
                        className="form-control"
                        value={db_port}
                        onChange={handleInputChanges}
                        required
                        placeholder="3306"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="db_name">
                        Database Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="db_name"
                        className="form-control"
                        value={db_name}
                        onChange={handleInputChanges}
                        required
                        placeholder="Name of database"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="db_username">
                        Database Username <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="db_username"
                        className="form-control"
                        value={db_username}
                        onChange={handleInputChanges}
                        required
                        placeholder="root"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="db_password">
                        Database Password <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="db_password"
                        className="form-control"
                        value={db_password}
                        onChange={handleInputChanges}
                        required
                        placeholder="Database password"
                      />
                    </div>

                    <div className="mt-2">
                      {saveLoading ? (
                        <center className="mt-3">
                          <LinearProgress />
                        </center>
                      ) : null}
                    </div>
                  </form>
                </div>
              </DialogContentText>
            </DialogContent>
            <div className="d-flex justify-content-end mb-4 mt-2">
              <button onClick={testConnection} type="button" className="btn btn-outline-secondary">
                Test Connection
              </button>

              <button
                type="button"
                style={{
                  color: "#fff",
                  background: "#F7C948",
                  width: "128px",
                  marginLeft: "3%"
                }}
                onClick={saveExternalConnector}
                className="btn btn-default"
              >
                Save
              </button>
            </div>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
};
export default ExternalConnector;
