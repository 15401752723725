import React from "react";
import { Card, CardContent, Typography, CardHeader } from "@material-ui/core";
// import { Icon } from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";
import classes from "./style.module.css";

const useStyles = makeStyles({
  blue: {
    color: "#3f50b5",
  },
  green: {
    color: "#4caf50",
    fontSize: 15,
  },
  grey: {
    color: "#666",
  },
  bgDanger: {
    background: "#f00",
    color: "#fff",
    fontSize: 15,
  },
  bgGrey: {
    background: "#f9f1c9",
    color: "#8a6d3b",
    fontSize: 15,
  },
  wn: {
    fontSize: 17,
  },
});

const ProfileCard = (props) => {
  const muClasses = useStyles();
  return (
    <Card variant="outlined" elevation={2} className={classes.cardClass}>
      <CardHeader title="Welcome" className={muClasses.grey} />

      <CardContent>
        <img className={classes.imgCenter} src={props?.img} alt="userImg" />

        <Typography variant="body2" sx={{ fontSize: 14 }}>
          Logged in as
        </Typography>
        <Typography variant="body2" sx={{ fontSize: 14 }}>
          {props.user.email}
        </Typography>
        <div className={classes.cardDiv}>
          <h6 className={muClasses.blue} id="my_intercom_link">Plan: Pay As you go</h6>
        </div>
        {/* <div className={`${classes.cardDiv} ${classes.reputation}`}>
              <h6 className={muClasses.green}>Reputation: </h6>
              <div>
                  <Icon className={muClasses.green} >star</Icon>
                  <Icon className={muClasses.green} >star</Icon>
                  <Icon className={muClasses.green} >star</Icon>
                  <Icon className={muClasses.green} >star</Icon>
                  <Icon className={muClasses.green} >star</Icon>
              </div>
            </div> */}
      </CardContent>
    </Card>
  );
};

export default ProfileCard;
