/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Typography,
} from "@material-ui/core";
import DeleteMember from './DeleteMember';

const ViewMemberModal = (props) => {
    const { openModal, applications, handleClose, appDetail } = props;
    const [openDelete, setOpenDelete] = useState(false);
    const [member, setMember] = useState();
    const [appId, setAppId] = useState('');

    const handleOpenDelete = (data) => {
        setMember(data);
        setAppId(data.app_id);
        handleClose();
        setOpenDelete(true);
    }
    const handleCloseDelete = () => setOpenDelete(false);

    
    return (
        <div >
            <Dialog
                open={openModal}
                onClose={handleClose}
                disableEscapeKeyDown={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                id="connector-modal"
                maxWidth="md"
            >
                <div >
                    <DeleteMember
                        openModal={openDelete}
                        handleClose={handleCloseDelete}
                        item={member}
                        appId={appId}
                    />
                    <DialogContent >
                        <DialogContent style={{ width: "700px" }}>

                            <DialogContentText id="alert-dialog-description">
                                <h5 className='mb-3'>Workspace Applications</h5>
                                <div className="table-responsive ">
                                    {
                                        applications.length > 0 ?
                                            <table className="table table-bordered ">
                                                <thead>
                                                    <th>#</th>
                                                    <th>App Id</th>
                                                    <th>App Name</th>
                                                    <th>Sending Email</th>
                                                </thead>

                                                <tbody>
                                                    {

                                                        applications.map((space, i) => (
                                                            <tr key={space.user_id}>
                                                                <td>{i + 1}</td>

                                                                <td>{space.app_id}</td>
                                                                <td>{space.app_name}</td>
                                                                <td>{appDetail.email}</td>

                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>

                                            </table>
                                            : <center>
                                                <h6>User not a member of any application</h6>
                                            </center>
                                    }



                                </div>
                            </DialogContentText>
                            <DialogActions>
                                <Button id="button-cancel" onClick={handleClose} color="primary">
                                    Close
                                </Button>
                            </DialogActions>
                        </DialogContent>

                    </DialogContent>

                </div>
            </Dialog>
        </div>
    )
}

export default ViewMemberModal