import { Box } from '@material-ui/core'
import React from 'react'

const LeftColumn = (props) => {
    const bgStyle = {
        background: "#f5f5f5",
        textAlign: "right",
        width: "10%",
        padding: "10px 15px"
    }
    return (
        <Box className="col-md-3" style={bgStyle}>
            <h6 className='text-bold'>
                {props.title}
            </h6>
        </Box>
    )
}

export default LeftColumn