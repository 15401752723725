import React from "react";
import { FaGraduationCap } from "react-icons/fa";
import DomainModal from "../components/DomainModal";
import AlertDiv from "../components/AlertDiv";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FaPlusCircle } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { TMAIL_V3_DOMAIN_LIST } from "../routes";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#F0B429",
    fontSize: "24px",
  },
  btn: {
    height: "44px",
    width: "185px",
    backgroundColor: "#F0B429",
    borderRadius: "3px",
    textTransform: "capitalize",
  },
  card: {
    borderRadius: "12px",
    background: "#fff",
    boxShadow: "0px 2px 16.9px 0px rgba(0, 0, 0, 0.11)",
  },
  cardBody: {
    padding: "41px 38px",
  },
  paddingX: {
    padding: "35px 38px",
  },
  backButton: {
    backgroundColor: "#f7c948",
    boxShadow: "0px 3px 6px #00000029",
    padding: "1vh 1vw",
    outline: "none",
    textDecoration: "none",
    border: "none",
    borderRadius: "6px",
    color: "white",
    marginBottom: "1%"
  },
}));

const DomainView = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const showDomainList = () => {
    history.push(TMAIL_V3_DOMAIN_LIST);
  };

  const handleBackButtonClick = () => {
    window.history.back();
  };

  return (
    <div>
      <div>
        <button onClick={handleBackButtonClick} className={classes.backButton}>
          Back
        </button>
      </div>
      <div className="d-flex align-items-start justify-content-between mb-5">
        <div>
          <h5 className="mb-2">Sending Domain</h5>
          <p className="mb-0">Send emails from your domain instead of ours.</p>
        </div>

        <div className="d-flex align-items-center">
          <Button
            variant="contained"
            color="primary"
            disableElevation={true}
            className={classes.btn}
            onClick={handleOpen}
          >
            <FaPlusCircle />
            &nbsp; Add Domain
          </Button>
          &nbsp; &nbsp;
          <Button
            variant="contained"
            color="primary"
            disableElevation={true}
            className={classes.btn}
            onClick={showDomainList}
          >
            {/* <FaPlusCircle /> */}
            {/* &nbsp; */}
            Domain List
          </Button>
        </div>
      </div>

      <AlertDiv message="Set up your domain so that emails from your team use your real FROM address" />

      <div className={"card w-100 m-0 border-0 " + classes.card}>
        <div className={"card-body " + classes.cardBody}>
          <p className="mb-0">
            In order to send emails from your domain and ensure maximum email
            deliverability, you are required to set up an SPF record on your
            domain.
            <br />
            <br />
            An SPF record is a type of Domain Name Service (DNS) record that
            identifies which mail servers are permitted to send email on behalf
            of your domain. The purpose of an SPF record is to prevent spammers
            from sending messages with forged From addresses at your domain.
            <br />
            <br />
            Once you add a domain mapping, you can get specific SPF instructions
            by clicking on the "configure" link.
            <br />
            <br />
            The DKIM settings are optional. They are not required, but
            recommended. The net effect of the DKIM setting is that it will
            remove the "via my-crm.io" that some mail clients display.
            <br />
            <br />
            Note: Due to infrastructure costs to provide this feature, it is
            only available to Premium and higher plans.
          </p>
        </div>
      </div>

      <div className={classes.paddingX}>
        <p className="mb-1 d-flex align-items-center">
          <FaGraduationCap className={classes.icon} />
          &nbsp;Tutorial:
        </p>
        <p className="text-primary mb-0">Sending Emails from your own Domain</p>
      </div>

      <DomainModal {...{ open, handleClose }} />
    </div>
  );
};

export default DomainView;
