import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import CircularProgress from "@material-ui/core/CircularProgress";

const ConnectCard = (props) => {
  // status can be connected, not connected, coming soon
  const {
    image,
    status = "coming soon",
    text,
    onClick,
    isLoading = false,
  } = props;

  return (
    <ConnectCard.Wrapper status={status} onClick={onClick}>
      <span>{status} </span>
      <div className="desc">
        <img src={image} alt="podio" />

        <p>{text}</p>
        {isLoading && (
          <div className="is-loading">
            <CircularProgress color="inherit" />
            <p>Loading Tmail Dashboard...</p>
          </div>
        )}
      </div>
    </ConnectCard.Wrapper>
  );
};

ConnectCard.Wrapper = styled.div`
  background: #ffffff;
  border: 1px solid transparent;
  box-sizing: border-box;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  /* max-width: 240px; */
  width: 325px;
  padding: 16px;
  transition: all 0.5s;
  position: relative;
  margin: auto;
  :hover {
    border: 1px solid #b1b1b1;
    cursor: pointer;
  }
  span {
    background: #e1e1e1;
    border-radius: 12.5px;
    font-size: 12px;
    line-height: 15px;
    color: #626262;
    padding: 5px 11px;
    text-transform: capitalize;
    ${(props) =>
      props.status.toLowerCase() === "connected" &&
      css`
        background-color: #c6f7e2;
        color: #147d64;
      `}
    ${(props) =>
      props.status.toLowerCase() === "disconnected" &&
      css`
        background-color: #ffe3e3;
        color: #e12d39;
      `}
  }

  .desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      margin-top: 25px;
      margin-bottom: 35px;
      height: 119px;
      max-width: 100px;
      object-fit: contain;
    }
  }
  .is-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    flex-direction: column;
  }
`;
export default ConnectCard;
