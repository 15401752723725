import React from "react";
import Typography from "@material-ui/core/Typography";
import { Button, Card, CardContent, Divider } from "@material-ui/core";

const PlanCards = ({ item, mainPlanId, onButtonClick, disabled }) => {
  const permission = JSON.parse(item.description);

  return (
    <div className="card">
      <Card>
        <div className="header__card">
          <Typography variant="subtitle1" style={{ color: "white" }}>
            {item?.name}
          </Typography>
        </div>

        <CardContent className="p-32">
          <div className="flex-center">
            <Typography variant="h5" color="textSecondary" className="currency">
              {item?.currency}
            </Typography>
            <div className="flex-center">
              <Typography className="pricee">{item.price}</Typography>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                className="month"
              >
                / {item.duration} days
              </Typography>
            </div>
          </div>

          <Divider className="my-32" />
          <div className="">
            <Typography variant="subtitle1" className="">
              <b className="font-bold mr-2">Orgs</b>
              {permission?.orgs}
            </Typography>
            <Typography variant="subtitle1" className="">
              <b className="font-bold mr-2">Iris</b>
              {permission?.iris ? "Enabled" : "Disabled"}
            </Typography>
            <Typography variant="subtitle1" className="">
              <b className="font-bold mr-2">Audit</b>
              {permission?.audit ? "Enabled" : "Disabled"}
            </Typography>
            <Typography variant="subtitle1" className="">
              <b className="font-bold mr-2">Restore</b>
              {permission?.restore ? "Enabled" : "Disabled"}
            </Typography>
            <Typography variant="subtitle1" className="">
              <b className="font-bold mr-2">Live Data</b>
              {permission?.live_data ? "Enabled" : "Disabled"}
            </Typography>
            <Typography variant="subtitle1" className="">
              <b className="font-bold mr-2">Data Mesh</b>
              {permission?.data_mesh ? "Enabled" : "Disabled"}
            </Typography>
            <Typography variant="subtitle1" className="">
              <b className="font-bold mr-2">App Builder</b>
              {permission?.app_builder ? "Enabled" : "Disabled"}
            </Typography>
            <Typography variant="subtitle1" className="">
              <b className="font-bold mr-2">File Haven</b>
              {permission?.file_haven ? "Enabled" : "Disabled"}
            </Typography>
          </div>
        </CardContent>
        {mainPlanId === item.id ? (
          <div className="flex-center pb-12">
            <Button variant="contained" color="secondary" className="w-128">
              Active
            </Button>
          </div>
        ) : (
          <React.Fragment>
            {item.price === 0 ? (
              <div className="flex-center pb-12">
                <Button
                  variant="contained"
                  color="primary"
                  className="w-128"
                  onClick={() => onButtonClick(item)}
                  disabled={disabled}
                  // onClick={() => setConfirmModal({message: "Plan", modal_type: "purchase_plan", modal_value: true, modal_data: item})}
                >
                  GET FREE
                </Button>
              </div>
            ) : (
              <div
                className="flex-center pb-12"
                // onClick={() => setConfirmModal({message: "Plan", modal_type: "purchase_plan", modal_value: true, modal_data: item})}
              >
                <Button
                  variant="contained"
                  color="primary"
                  className="w-128"
                  onClick={() => onButtonClick(item)}
                  disabled={disabled}
                >
                  BUY NOW
                </Button>
              </div>
            )}
          </React.Fragment>
        )}
      </Card>
    </div>
  );
};

export default PlanCards;
