/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from "react";
import ComposeMessageModal from "../Modals/ComposeMessageModal";
import WebhooksModal from "../Modals/WebhooksModal";
import AttachmentModal from "../Modals/AttachmentModal";

const CommentItemAction = (props) => {
    const data = typeof props["data"] !== "undefined" ? props["data"] : null;
    // const [statistics, setStatistics] = useState();
    const [hooks, setHooks] = useState();
    const [attachments, setAttachments] = useState();
    const [open, setOpen] = useState(false);
    const [openStatus, setOpenStatus] = useState(false);
    const [openHook, setOpenHook] = useState(false);
    const [singleComment, setSingleComment] = useState();

    const actionHandler = data => {
        setSingleComment(data);
        setOpen(true);
    }
    const handleClose = () => setOpen(false);
    const handleCloseAttachment = () => setOpenStatus(false);
    const handleCloseHook = () => setOpenHook(false);

    const handleOpenAttachment = (data) => {
        setAttachments(data);
        setOpenStatus(true);
    }
    const openHookModal = (data) => {
        setHooks(data)
        setOpenHook(true);
    }
   
    const updateComment = (newValue) =>{
        props["api"].getSelectedNodes()[0].setData(newValue);
    }

    const onRemoveSelected = useCallback(() => {
        const selectedData = props.api.getSelectedRows();
        props.api.applyServerSideTransaction({ remove: selectedData });
      }, []);


    return (
        <>
            <ComposeMessageModal
                openModal={open}
                handleClose={handleClose}
                comment={singleComment}
                changeState={updateComment}
                removeComment={onRemoveSelected}
            />
            <WebhooksModal
                openModal={openHook}
                handleClose={handleCloseHook}
                hooks={hooks}
            />
            <AttachmentModal
                openModal={openStatus}
                handleClose={handleCloseAttachment}
                attachments={attachments}
            />
            {data !== null ? (
                <center>
                    <i
                        className="fa fa-eye"
                        style={{
                            fontSize: "20px",
                            cursor: "pointer",
                            color: "#F0B429",
                        }}
                        title="View"
                      onClick={() => openHookModal(data.webhooks)}
                    ></i>
                    <i
                        className="fa fa-edit text-info"
                        title="Edit"
                        style={{
                            fontSize: "20px",
                            marginLeft: "10px",
                            cursor: "pointer",
                        }}
                      onClick={() => actionHandler(data)}
                    ></i>
                    <i
                        className="fa fa-paperclip text-light"
                        title="Edit"
                        style={{
                            fontSize: "20px",
                            marginLeft: "10px",
                            cursor: "pointer",
                        }}
                      onClick={() => handleOpenAttachment(data.attachments)}
                    ></i>


                </center>
            ) : (
                ""
            )}
        </>
    );
};

export default CommentItemAction;
