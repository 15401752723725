import React, { useEffect } from "react";
import "./pricing.css";
import Header from "../components/shared/Header";
import Footer from "../components/shared/Footer";
import { Link } from "react-router-dom";

const Pricing = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <div className="pricing__cont">
        <h1 className="pricing__h1">Flexible Pricing to Meet Your Needs</h1>
        <p className="pricing__sub__p">
          Only pay for what you use with dynamic billing and powerful data
          management features.
        </p>
        <div className="price__text__cont">
          <div className="billing__cont">
            <div className="billing__price">
              <h3>Dynamic Billing</h3>
              <p>
                $30<span>/1 million record</span>
              </p>
              <Link to="/register">
                <button>Subscribe Now</button>
              </Link>
            </div>
            <div className="billing__features">
              <p className="billing__features__p">Base features</p>
              <ul className="billing__features__list">
                <li>
                  <p>
                    <span>Backup & Sync:</span> Store and sync up to 1 million
                    records per month.
                  </p>
                </li>
                <li>
                  <p>
                    <span>Real-Time Syncing:</span> Automatic syncing across all
                    your devices.
                  </p>
                </li>
                <li>
                  <p>
                    <span>Data Insights:</span> Get real-time analytics and
                    monitoring for your data.
                  </p>
                </li>
                <li>
                  <p>
                    <span>Weekly Insights:</span> Get weekly insights on your
                    data usage
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="dynamic__cont">
            <h2>Understanding Dynamic Billing</h2>
            <p className="dynamic__cont__p">
              With dynamic billing, you are charged $30 for the standard plan,
              which includes the ability to back up and sync up to 1 million
              records per month. Beyond this, the cost of your monthly bill will
              depend on the additional services you choose to use, making it
              more flexible for you.
            </p>

            <h4>Optional Add-ons</h4>
            <ul className="add__ons__list">
              <li>
                <p>
                  <span>Email Services: $5.00/month</span>Manage and synchronise
                  emails effortlessly with your data.
                </p>
              </li>
              <li>
                <p>
                  <span>Database Syncing: $10.00/month</span>Sync databases like
                  MySQL, PostgreSQL, and MS SQL for seamless integration.
                </p>
              </li>
              <li>
                <p>
                  <span>Cloning Service: $0.05/month</span>Create quick and
                  efficient data clones for extra security and testing.
                </p>
              </li>
              <li>
                <p>
                  <span>Structured-only data cloning: $2.00/app</span>
                  Replicate your app structure without duplicating files and
                  items.
                </p>
              </li>
              <li>
                <p>
                  <span>Workflow: $1.00 </span>Automate key tasks directly
                  from your dashboard for seamless and secure workflows
                </p>
              </li>
              <li>
                <p>
                  <span>Print: $5.00/month per 1,000 print</span>Create, Manage
                  and print your dynamic template solution effortlessly
                </p>
              </li>
            </ul>
          </div>

          <hr style={{ width: "100%", marginTop: "5%" }} />

          <div className="dynamic__cont">
            <h2>What to Know About Our Free Trial</h2>
            <p className="dynamic__cont__p">
              Not ready to subscribe? There is a free trial for everyone - No credit card required!
            </p>
            <ul className="add__ons__list">
              <li>
                <p>
                  On logging in to your account and activating the 15-day free
                  trial, you can store up to 250 items per application.
                </p>
              </li>
              <li>
                <p>
                  To continue backing up and syncing your data after the trial
                  ends, a subscription will be required. If payment isn’t made,
                  all trial data will be deleted
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer bg="#f0b429" />
    </div>
  );
};

export default Pricing;
