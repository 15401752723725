/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import classes from './style.module.css';
import './style.css';
import { useSelector, useDispatch } from 'react-redux';
import ProfileCard from './ProfileCard';
import AlertCard from './AlertCard';
// import MultiPurposeCard from './MultiPurposeCard';
import PodioApps from './PodioApps';
import ExtensionCard from './ExtensionCard';
import LatestCard from './LatestCard';
import { GetAllApps, GetAllDomains } from '../../../redux/actions/tmail';
import axios from "../../../config";
import DomainCard from './utility/DomainCard';
// import { CircularProgress } from '@material-ui/core';

const DashboardView = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.authUser);
 
  useEffect(() => {
    dispatch(GetAllApps());
    dispatch(GetAllDomains());
    getRateLimit();
  }, []);
  
  const tmail = useSelector((state) => state.tmail);
  const domains = useSelector((state) => state.tmail.domains);
  const { apps } = tmail;

  const [img] = React.useState(
    user?.podio_profile?.profile?.image
      ? user?.podio_profile?.profile?.image
      : "https://i.stack.imgur.com/l60Hf.png"
  );
  const [rateLimit, setRateLimit] = useState([]);
  const [loading, setLoading] = useState(false);


  const getRateLimit = async () => {
    try {
      setLoading(true)
      const url = "/auth/mail/rate-limit";
      const res = await axios.get(url);
     
      setRateLimit(res.rate_limit);
      setLoading(false)
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  }
  return (
    <div className='container dashboardview'>
      <div className='row pt-5'>
        <div
          className={
            classes.cardContainer + ' col-12 thatapp-card-container  col-lg-4 col-sm-6'
          }
        >
          <ProfileCard user={user} img={img} />
        </div>
        <div
          className={
            classes.cardContainer + ' col-12  thatapp-card-container col-lg-4 col-sm-6'
          }
        >
          {
            domains.length === 0 ? <AlertCard domains={domains} /> : <DomainCard domains={domains} />
          }
          {/* <AlertCard domains={domains} /> */}
          {/* <DomainCard domains={domains} /> */}
        </div>
        <div
          className={
            classes.cardContainer + ' col-12 thatapp-card-container col-lg-4 col-sm-6'
          }
        >
          <ExtensionCard title='Browser Extension' />
        </div>
        <div
          className={
            classes.cardContainer + ' col-12 thatapp-card-container col-lg-4 col-sm-6'
          }
        >

          <PodioApps
            text={`${apps.length} / ${apps.length}`}
            title='Podio Apps'
            message='Connect your Podio apps and start communicating with your contacts'
          />
        </div>
        {/*<div*/}
        {/*  className={*/}
        {/*    classes.cardContainer + ' col-12 thatapp-card-container col-lg-4 col-sm-6'*/}
        {/*  }*/}
        {/*>*/}
        {/*  <MultiPurposeCard*/}
        {/*    text='0'*/}
        {/*    title='Email Campaigns'*/}
        {/*    message='Send beautiful bulk email campaigns to your contact lists'*/}
        {/*  />*/}
        {/*</div>*/}
        <div
          className={
            classes.cardContainer + ' col-12 thatapp-card-container col-lg-4 col-sm-6'
          }
        >
          {loading ? "Please Wait" :
            <LatestCard rateLimit={rateLimit} />
          }
        </div>
      </div>
      {/* <div className={classes.boxContainer}>
        <ProfileCard user={user} img={img} />
        <AlertCard />
        <ExtensionCard title='Browser Extension' />
        <PodioApps
          text='2 / 2'
          title='Podio Apps'
          message='Connect your Podio apps and start communicating with your contacts'
        />
        <MultiPurposeCard
          text='0'
          title='Email Campaigns'
          message='Send beautiful bulk email campaigns to your contact lists'
        />
        <LatestCard />
      </div> */}
    </div>
  );
};

// const View = styled.div`
//     width: 70%;
//     .display-cards {
//       display: grid;
//       grid-template-columns: repeat(3, 1fr);
//       gap: 33px;
//       margin-top: 81px;
//       @media screen and (max-width: 1100px) {
//         grid-template-columns: repeat(3, 1fr);
//       }
//       @media screen and (max-width: 768px) {
//         grid-template-columns: repeat(2, 1fr);
//       }
//       @media screen and (max-width: 500px) {
//         grid-template-columns: repeat(1, 1fr);
//       }
//     }
// `;

export default DashboardView;
