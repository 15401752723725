import React from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

const ThemedReactSelect = (props) => {
  return (
    <Select
      {...props}
      theme={(theme) => {
        return {
          ...theme,
          colors: {
            ...theme.colors,
            ...custiomTheme,
          },
        };
      }}
    />
  );
};

const ThemedAsyncReactSelect = (props) => {
  return (
    <AsyncSelect
      {...props}
      theme={(theme) => {
        return {
          ...theme,
          colors: {
            ...theme.colors,
            ...custiomTheme,
          },
        };
      }}
    />
  );
};

export { ThemedAsyncReactSelect, ThemedReactSelect };

const custiomTheme = {
  primary25: 'rgba(40, 167, 69, 0.2)',
  primary: 'rgba(40, 167, 69, 0.9)',
  primary50: 'rgba(40, 167, 69, 0.5)',
  primary75: 'rgba(40, 167, 69, 0.7)',
};