import React from 'react'
import { makeStyles } from '@material-ui/core';
import PaymentMethodsCard from './PaymentMethodsCard';
import { useState } from 'react';
import AddCard from './AddCard';
import CheckoutCardV2 from './CheckoutCardV2';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
  },
  leftWidth: {
    width: '383px'
  },
  rightWidth: {
    width: '316px'
  }
}));
// setActiveScreen, submitData,
const Payment = ({ cardList, responseData, flowData, handleCheckout, setSelectedCard, loading }) => {

  const classes = useStyles();
  const [currentScreen, setCurrentScreen] = useState('select-card')

  const isAddCardScreen = currentScreen === 'add-card';

  return (
    <div className={classes.container}>
      <div className={classes.leftWidth}>
        {currentScreen === 'select-card' && <PaymentMethodsCard {...{ setCurrentScreen, cardList, setSelectedCard }} />}
        {currentScreen === 'add-card' && <AddCard {...{ setCurrentScreen }} loading={loading} />}
      </div>
      <div className={classes.rightWidth}>
        <CheckoutCardV2 responseData={responseData} disabled={isAddCardScreen} flowData={flowData} onClick={handleCheckout} btnTitle="Pay and Checkout" loading={loading} />
      </div>
    </div>
  )
}

export default Payment