import React, { useState } from "react";
import { useDispatch } from "react-redux";
import DeleteDomain from "./Modals/DeleteDomain";
import VerifyDomain from "./Modals/VerifyDomain";
import ViewDomain from "./Modals/ViewDomain";
import { verifyDomainName } from "../../../redux/actions/tmail";
import RenderByPermission from "components/RenderByPermission";

const DomainList = ({ item }) => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [openVerify, setOpenVerify] = useState(false);
  const [loading, setLoading] = useState(false);
  const toggleModal = () => setOpenModal(false);
  const toggleViewModal = () => setViewModal(false);
  const toggleVerifyModal = () => setOpenVerify(false);
  const changeIsLoading = () => setLoading(false);
  const [isHover, setIsHover] = useState(false);


  const verifyDomain = (id) => {
    setLoading(true);
    dispatch(verifyDomainName(id, changeIsLoading, toggleVerifyModal));
  };

  const openVerifyDomain = (id) => {
    setOpenVerify(true);
    verifyDomain(id);
  };

  const openDomain = (domain) => {
    const url = `https://${domain}`;
    window.open(url, "blank");
  };

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const domainStyle = {
    domain: {
      textDecoration: "underline",
      cursor: "pointer",
      marginTop: ".7rem",
      color: isHover ? 'blue' : 'inherit',
    },
    domainFont: {
      fontSize: ".8rem",
    }
  }

  return (
    <div className="config-con-body">
      <div className="row align-items-center">
        <div className="col-md-2">{item.org.org_id}</div>
        <div className="col-md-2">{item.org.org_name}</div>
        <div className="col-md-2">
          <p
            onClick={() => openDomain(item.domain)}
            style={domainStyle.domain}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {item.domain}
          </p>
        </div>
        <div className="col-md-3">
          {item.verification_status === "active" ? (
            <span
              className="badge badge-pill badge-success text-bold"
              style={domainStyle.domainFont}
            >
              Verified
            </span>
          ) : (
            <span
              className="badge badge-pill badge-danger text-bold"
              style={domainStyle.domainFont}
            >
              Unverified
            </span>
          )}
        </div>
        <div className="col-md-3">
          <i
            className="fa fa-eye"
            style={{
              fontSize: "15px",
              marginRight: "10px",
              cursor: "pointer",

              // color: "#F0B429",
            }}
            title="View"
            onClick={() => setViewModal(true)}
          ></i>

          <i
            className="fa fa-sync"
            style={{
              fontSize: "15px",
              marginLeft: "20px",
              marginRight: "10px",
              cursor: "pointer",
            }}
            title="Refresh verification status"
            onClick={() => openVerifyDomain(item.id)}
          ></i>
          <RenderByPermission allowTeamMemberAccess={false}>
            <i
              className="fa fa-trash "
              style={{
                fontSize: "15px",
                marginLeft: "20px",
                cursor: "pointer",
              }}
              title="Delete"
              onClick={() => setOpenModal(true)}
            ></i>
          </RenderByPermission>
        </div>
      </div>
      <DeleteDomain
        openModal={openModal}
        handleClose={toggleModal}
        item={item.id}
      />
      <ViewDomain
        openModal={viewModal}
        domain={item}
        handleClose={toggleViewModal}
        verifyDomain={openVerifyDomain}
        isLoading={loading}
      />
      <VerifyDomain
        openModal={openVerify}
        domain={item}
        handleClose={toggleVerifyModal}
      />
    </div>
  );
};

export default DomainList;
