import React, { useEffect } from "react";
import Footer from "../components/shared/Footer";
import Header from "../components/shared/Header";

// import nigeria from "../Images/nigeria.png";
// import element from "../Images/contact-img.png";
// import chevron from "../Images/chevron-down.png";
// import "./contact.css";

// export default function Contact() {
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);
//   return (
//     <div className="contact-cont">
//       <div className="contact-inner">
//         <div className="conatct-title-cont">
//           <img src={element} alt="talk to us" />
//           <h2>Get in Touch with us</h2>
//         </div>
//         <form className="contact-form">
//           <div className="contact-input-cont">
//             <label htmlFor="firstName">First Name</label>
//             <input
//               placeholder="Enter first name"
//               type="text"
//               name="firstName"
//               id="firstName"
//             />
//           </div>
//           <div className="contact-input-cont">
//             <label htmlFor="lastName">Last Name</label>
//             <input
//               placeholder="Enter last name"
//               type="text"
//               name="lastName"
//               id="lastName"
//             />
//           </div>
//           <div className="contact-input-cont">
//             <label htmlFor="email">Email</label>
//             <input
//               placeholder="Enter your email"
//               type="email"
//               name="email"
//               id="email"
//             />
//           </div>
//           <div className=" contact-input-cont ">
//             <label htmlFor="phone">Phone Number</label>
//             <div className="contact-phone-cont">
//               <div className="contact-countrycode">
//                 <img src={nigeria} alt="country code" className="country" />
//                 <img src={chevron} alt="select" className="chevron" />
//               </div>
//               <input
//                 placeholder="Enter phone number"
//                 type="text"
//                 name="phone"
//                 id="phone"
//               />
//             </div>
//           </div>
//           <div className="contact-input-cont contact-textarea">
//             <label htmlFor="message">Message</label>
//             <textarea
//               placeholder="Enter your message"
//               name="message"
//               id="message"
//             ></textarea>
//           </div>
//           <div className="contact-terms-cont">
//             <div className="contact-checkbox">
//               <input type="checkbox" name="terms" id="terms" />
//               <label htmlFor="terms">
//                 I agree with Terms of Use and Privacy Policy
//               </label>
//             </div>
//             <button className="contact-submit-button" type="button">Send</button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// }


const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <div>
      <Header />
      <iframe
        src="https://podio.com/webforms/2933904/240507"
        width="100%"
        height="800px"
        frameBorder="0"
        allowFullScreen
        title="Contact Us Form"
      ></iframe>
      <div className="podio-webform-container">
        Podio -{" "}
        <a
          className="podio-webform-inner"
          rel="nofollow"
          href="https://podio.com"
        >
          CRM
        </a>{" "}
        done right
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
