import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import { Redirect, withRouter, useParams } from "react-router";
import users from "../../assets/icons/users.svg";
import apps from "../../assets/icons/apps.svg";
import spaces from "../../assets/icons/spaces.svg";
import items from "../../assets/icons/items.svg";
import { connect } from "react-redux";
import Config from "../../utils/Config";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  addMember,
  getAuditMembers,
  getHooksLog,
  getSyncedOrganizations,
} from "../../services/sync";
import { setAuditOrg } from "../../redux/actions/orgs";
import MemberGrid from "./AuditGrids/MemberGrid";
import Spaces from "./Resource/Spaces";
import { toast } from "helpers/apiRequests";
import LatestGridHooksClass from "../Hooks/LatestHooksGridClass";

let data = [
  { id: "1", tabTitle: "SPACES", tabContent: "Tab Content" },
  { id: "2", tabTitle: "MEMBERS", tabContent: "Tab Content 2" },
  { id: "3", tabTitle: "LOGS", tabContent: "Tab Content 3" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  hide: {
    display: "none",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    background: "white",
  },
  backButton: {
    backgroundColor: "#f7c948",
    boxShadow: "0px 3px 6px #00000029",
    padding: "1vh 1vw",
    outline: "none",
    textDecoration: "none",
    border: "none",
    borderRadius: "6px",
    color: "white",
  },
}));

const AuditServerSide = ({ orgs, match, setAuditOrg, user }) => {
  const classes = useStyles();
  const params = useParams();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [openMobile, setOpenMobile] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const orgId = parseInt(match.params.orgId);
  const [loader, setLoader] = React.useState(false);
  const roleArray = [
    {
      key: "light",
      title: "Light",
    },
    {
      key: "regular",
      title: "Regular",
    },
    {
      key: "admin",
      title: "Admin",
    },
  ];

  const handleDrawerOpen = () => {
    setOpen(!open);
    setOpenMobile(!openMobile);
  };

  const membersOption = (mem) => {
    let options = [];
    let uniqueArray = [];

    mem.forEach((element) => {
      if (!options.includes(element.user_id)) {
        options.push(element.user_id);
        uniqueArray.push({ user_id: element.user_id, name: element.name });
      }
    });
    return uniqueArray;
  };

  const spacesOption = (sp) => {
    let options = [];
    let uniqueArray = [];

    sp.forEach((element) => {
      if (!options.includes(element.space_id)) {
        options.push(element.space_id);
        uniqueArray.push({ space_id: element.space_id, name: element.name });
      }
    });
    return uniqueArray;
  };

  // write a function that navigates to the previous page when a button is clicked
  const handleBackButtonClick = () => {
    window.history.back();
  };

  const [dataTab, setDataTab] = useState("1");
  const [addUser, setAddUser] = useState(false);
  const [dashboardCounts, setDasboardCounts] = useState({
    users: 0,
    spaces: 0,
    apps: 0,
    items: 0,
  });
  const [none, setNone] = useState(false);

  const [members, setMembers] = React.useState({
    columns: [],
    data: [],
  });

  const [mem, setMem] = React.useState([]);
  const [_sp, setSp] = React.useState([]);
  const [formRole, setFormRole] = React.useState(null);
  const [formMember, setFormMember] = React.useState(null);
  const [formSpace, setFormSpace] = React.useState(null);
  const [hooksLog, setHooksLog] = React.useState(null);

  const handleAddMember = () => {
    if (formRole && formSpace && formMember) {
      addMember(
        `?org_id=${orgId}&space_id=${formSpace}&users=${formMember}&role=${formRole}`
      )
        .then((r) => {
          if (r.data.message === "Already added") {
            toast.notify("Member is already part of this space.", {
              type: "info",
            });
          } else {
            toast.notify("Added successfully.");
          }

          setAddUser(!addUser);
        })
        .catch((err) => {
          setAddUser(!addUser);
        });
    } else {
      toast.notify("Ensure your form is filled.", { type: "error" });
    }
  };

  const getAudits = useCallback(async () => {
    const _members = await getAuditMembers(`?org_id=${orgId}`);
    //Update Dashboard

    setDasboardCounts((prevState) => ({
      ...prevState,
      users: _members.count.unique_members,
    }));
    setMem(_members.data);
    let sp = [];

    _members.data.forEach((Ddata) => {
      const s = { space_id: Ddata.space_id, name: Ddata.space_name };

      sp.push(s);
      setMembers((prevState) => {
        const data = [...prevState.data];

        data.push({
          space: Ddata.space_name,
          name: Ddata.name,
          role: Ddata.role,
          user_id: Ddata.user_id,
          fields: Ddata.fields,
          link: Ddata.link,
          org_id: orgId,
          space_id: Ddata.space_id,
        });
        return { ...prevState, data };
      });
    });
    setSp(sp);
  }, [orgId]);

  useEffect(() => {
    let found = false;

    if (!Config.isEmpty(orgs)) {
      const x = orgs.find((x) => x.org_id === orgId);

      if (!Config.isEmpty(x)) {
        setDasboardCounts({
          users: 0,
          spaces: x.details.mongo_summary.count.spaces,
          apps: x.details.mongo_summary.count.apps,
          items: x.details.mongo_summary.count.items.toLocaleString(),
        });
        found = true;
      }
    } else {
      const newResponse = getSyncedOrganizations();

      setAuditOrg(newResponse.data);
      const x = newResponse.data.find((x) => x.org_id === orgId);

      if (!Config.isEmpty(x)) {
        setDasboardCounts({
          users: 0,
          spaces: x.details.mongo_summary.spaces,
          apps: x.details.mongo_summary.apps,
          items: x.details.mongo_summary.items.toLocaleString(),
        });
        found = true;
      }
    }

    if (!found) return setNone(true);
    //Get Spaces
    getAudits();
    getHooksLog().then((res) => {
      setHooksLog(res.data);
    });
  }, [orgId, orgs, setAuditOrg, getAudits]);

  if (none) return <Redirect to="/dashboard/sync" />;

  if (loader) {
    return (
      <div className="material_block">
        <svg
          className="spinner"
          width="65px"
          height="65px"
          viewBox="0 0 66 66"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            className="circle"
            fill="#fff"
            strokeWidth="6"
            strokeLinecap="round"
            cx="33"
            cy="33"
            r="30"
          ></circle>
        </svg>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header baropen={open} handleDrawerOpen={handleDrawerOpen} />
      {isMobile ? (
          <Sidebar open={openMobile} handleDrawerOpen={handleDrawerOpen} />
        ) : (
          <Sidebar open={open} handleDrawerOpen={handleDrawerOpen} />
        )}
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div>
          <button
            onClick={handleBackButtonClick}
            className={classes.backButton}
          >
            Back
          </button>
        </div>
        <div className="audit">
          <div className="id">ORG ID: {params.orgId}</div>

          <div className="audit-tabs">
            <div className="item">
              <div className="image">
                <img src={users} alt="users" />
              </div>
              <div className="label">Users</div>
              <div className="value">{dashboardCounts.users}</div>
            </div>

            <div className="item">
              <div className="image">
                <img src={spaces} alt="spaces" />
              </div>
              <div className="label">Spaces</div>
              <div className="value">{dashboardCounts.spaces}</div>
            </div>

            <div className="item">
              <div className="image">
                <img src={apps} alt="apps" />
              </div>
              <div className="label">Apps</div>
              <div className="value">{dashboardCounts.apps}</div>
            </div>

            <div className="item">
              <div className="image">
                <img src={items} alt="items" />
              </div>
              <div className="label">Items</div>
              <div className="value">{dashboardCounts.items}</div>
            </div>
          </div>

          <div className="table-ag-grid">
            {/* eslint-disable-next-line eqeqeq */}

            <div className={addUser ? "add-user-container" : "disappear"}>
              <div
                className="background"
                onClick={() => setAddUser(!addUser)}
              ></div>
              <div className="inner">
                <div className="title">Add Member</div>
                <div className="subtitle">Add member to space</div>

                <div className="form-group">
                  <select
                    className="form-control"
                    onChange={(e) => setFormMember(e.target.value)}
                  >
                    <option value={formMember}>Select a member</option>
                    {membersOption(mem).map((m) => {
                      return (
                        <option key={m.user_id} value={m.user_id}>
                          {m.name}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="form-group">
                  <select
                    className="form-control"
                    onChange={(e) => setFormSpace(e.target.value)}
                  >
                    <option value={formSpace}>Select a space</option>
                    {spacesOption(_sp).map((m) => {
                      return (
                        <option key={m.space_id} value={m.space_id}>
                          {m.name}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="form-group">
                  <select
                    className="form-control"
                    onChange={(e) => setFormRole(e.target.value)}
                  >
                    <option value={formRole}>Choose role</option>
                    {roleArray.map((m) => {
                      return (
                        <option key={m.key} value={m.key}>
                          {m.title}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="buttons">
                  <span onClick={() => setAddUser(!addUser)}>CANCEL</span>
                  <span onClick={() => handleAddMember()}>ADD USER</span>
                </div>
              </div>
            </div>

            <div className="navigation mb-3">
              {data.map((item) => {
                return (
                  <span
                    key={item.id}
                    className={item.id === dataTab ? "active" : ""}
                    onClick={() => setDataTab(item.id)}
                  >
                    {item.tabTitle}
                  </span>
                );
              })}
              <Link className="text-dark text-uppercase" to="/dashboard/hooks">
                {" "}
                <span>Hooks </span>{" "}
              </Link>
              <Link
                className="text-dark text-uppercase"
                to="/dashboard/manage-hook"
              >
                {" "}
                <span>Hooks Manager</span>{" "}
              </Link>
              <Link
                className="text-dark text-uppercase"
                to="/dashboard/raw-data"
              >
                {" "}
                <span>Raw Data</span>{" "}
              </Link>

              <button
                className={dataTab === "2" ? "add-user" : "disappear"}
                onClick={() => setAddUser(!addUser)}
                style={{ color: "white" }}
              >
                Add user to space
              </button>

              <button
                style={{ float: "right", color: "white", marginLeft: "10px" }}
                id="refreshThisGrid"
                onClick={() => {
                  setLoader(true);
                  setMembers({
                    columns: [],
                    data: [],
                  });
                  getAudits(); //Refresh members
                  setTimeout(() => {
                    setLoader(false);
                  }, 2000);
                }}
              >
                Refresh Grid
              </button>
            </div>

            <div className="content ">
              {dataTab === "1" && <Spaces org_id={orgId} user={user} />}
              {dataTab === "2" && <MemberGrid audit={members} user={user} />}
              {dataTab === "3" && hooksLog && (
                <LatestGridHooksClass hooksLog={hooksLog} user={user} />
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

AuditServerSide.Wrapper = styled.div`
  button {
    margin-left: 10px !important;
    font-size: 2px !important;
  }
`;

const mapStateToProps = (state) => ({
  user: state.user.authUser,
  orgs: state.sync.orgs,
});

const mapDispatchToProps = (dispatch) => ({
  setAuditOrg: (data) => dispatch(setAuditOrg(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AuditServerSide));
