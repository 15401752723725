import React from "react";
import { Modal } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  modalPresentation: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    padding: "50px 0",
    overflowY: "auto",
  },
  modal: {
    width: "711px",
    borderRadius: "6px",
    backgroundColor: "#FFF",
    margin: "auto",
    padding: "41px 40px",
  },
  modalTitle: {
    color: "#222",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
  },
  modalText: {
    color: "#626262",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  border: {
    width: "630px",
    height: "1px",
    background: "#E1E1E1",
    margin: "32px 0",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "16px",
  },
  btn: {
    width: "32px",
    height: "32px",
    padding: "0px",
    background: "transparent",
    border: "none",
  },

  "@media screen and (max-width: 600px)": {
    modal: {
      width: "94vw",
      padding: "41px 20px",
    },
    border: {
      width: "100%",
    },
  },
}));

const PdcLayout = ({ open, handleClose, message, children }) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modalPresentation}
    >
      <div className={classes.modal}>
        <div className={classes.header}>
          <h4 className={classes.modalTitle}>Copy Data</h4>
          <button type="button" className={classes.btn} onClick={handleClose}>
            <Close />
          </button>
        </div>
        <p className={classes.modalText}>{message}</p>
        <div className={classes.border}></div>
        {children}
      </div>
    </Modal>
  );
};

export default PdcLayout;
