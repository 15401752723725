import { LinearProgress } from "@material-ui/core";
import React from "react";
import AppFieldInput from "./AppFieldInput";
import ExtendedAppField from "./ExtendedAppField";

const GeneralTab = (props) => {
  const {
    option,
    changeContactField,
    changeRefApp,
    AppOptions,
    subJectOptions,
    changeSelector,
    app,
    EmailOptions,
    changeSubject,
    isLoadingRefApp,
    handleSubmit,
    // goBack,
    saveLoading,
    subject,
    contactField,
    refApp,
    //InAppSetting,
    uniqueFields,
    fullNameFields,
    uniqueOption,
    fullOption,
    changeUniqueSelector,
    changeFullSelector,
    changeUniqueRefApp,
    changeFullRefApp,
    orphLoading,
    uniqueRefApp,
    fullRefApp,
    fullContactField,
    uniqueContactField,
    changeUniqueContactField,
    changeFullContactField,
    classes,
  } = props;
  function openLink(link) {
    if (link == null) {
      return;
    }
    window.open(link, "_blanck");
  }
  return (
    <div className="row">
      <div className="col-md-8">
        <form>
          <h4 className={classes.header}>Podio App</h4>
          <h5 className={classes.title}>
            <span className="text-bold">App</span>:{" "}
            <span
              onClick={() => openLink(app?.app_link)}
              style={{ cursor: "pointer" }}
              className="text-muted"
            >
              {" "}
              {!app ? null : app.app_name}{" "}
            </span>
          </h5>
          <h5 className={classes.title}>
            <span className="text-bold">Workspace</span>:{" "}
            <span className="text-muted"> {!app ? null : app.space_name} </span>
          </h5>
          <hr />
          <br />
          <div className="form-group mb-4">
            <label htmlFor="">
              Where are the contacts' Email Addresses Stored?
            </label>
            <select
              value={option}
              onChange={(e) => changeSelector(e.target.value)}
              className="form-control mb-4"
            >
              <option value="one">
                not applicable - I will Manually add recipients to each email
              </option>
              <option value="three">
                in a Single-line Text field or Email field in this app.
              </option>
              <option value="four">
                in a Single-line Text field or Email field in a Referenced app.
              </option>
            </select>
            <AppFieldInput
              option={option}
              EmailOptions={EmailOptions}
              changeContactField={changeContactField}
              changeSubject={changeSubject}
              subJectOptions={subJectOptions}
              changeRefApp={changeRefApp}
              AppOptions={AppOptions}
              isloadingRefApp={isLoadingRefApp}
              subject={subject}
              refApp={refApp}
              contactField={contactField}
              //InAppSetting={InAppSetting}
            />
            <ExtendedAppField
              uniqueFields={uniqueFields}
              fullNameFields={fullNameFields}
              uniqueOption={uniqueOption}
              fullOption={fullOption}
              changeUniqueSelector={changeUniqueSelector}
              changeFullSelector={changeFullSelector}
              changeUniqueRefApp={changeUniqueRefApp}
              changeFullRefApp={changeFullRefApp}
              orphLoading={orphLoading}
              uniqueRefApp={uniqueRefApp}
              fullRefApp={fullRefApp}
              fullContactField={fullContactField}
              uniqueContactField={uniqueContactField}
              changeUniqueContactField={changeUniqueContactField}
              changeFullContactField={changeFullContactField}
            />
          </div>
          <div className="form-goup mt-3">
            <button
              className="btn btn-default mr-2"
              onClick={handleSubmit}
              style={{
                color: "#fff",
                background: "#F7C948",
                width: "150px",
                height: "50px",
              }}
            >
              Save
            </button>
            {/* <button className='btn btn-secondary ml-2' onClick={goBack}>Cancel</button> */}
          </div>
          <div className="mt-2">
            {saveLoading ? (
              <center className="mt-3">
                <LinearProgress />
              </center>
            ) : null}
          </div>
        </form>
      </div>
    </div>
  );
};

export default GeneralTab;
