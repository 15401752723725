import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Typography,
} from "@material-ui/core";
import ErrorImg from "../../../../assets/images/error.jpeg"
import {useHistory} from "react-router-dom";

const AlertModal = ({ openModal, handleClose, message, redirect, url }) => {
    const history = useHistory();
    const imageStyle = {
        height: "70px",
    };
    const alignCenter = {
        margin: "auto",
        textAlign: "center",
        marginBottom: "18px",
    };
    const handleClose_ = (event, reason) => {
        if (reason !== 'backdropClick') {
            history.push(`${url}`)
            handleClose()
        }
    }
    return (
        <div>
            <Dialog
                open={openModal}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        openModal(false)
                    }
                }}
                // hideBackdrop={true}
                disableEscapeKeyDown={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                id="connector-modal"
            >
                <DialogContent>
                    <DialogContent>
                       <p style={alignCenter}> <img src={ErrorImg} alt={"error message"} style={imageStyle}/></p>
                        <DialogContentText id="alert-dialog-description">
                            <Typography>
                                {message}
                            </Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button id="button-cancel" onClick={handleClose_} color="primary">
                            {redirect}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </div>
    )

}
export default AlertModal