import React from "react";
import EmptyImage from "../assets/images/Group 11732.svg";
import styled from "@emotion/styled";
import {Link} from 'react-router-dom'

function EmptyState() {
  return (
    <Wrapper>
      <Empty>
        <img src={EmptyImage} alt="" className="empty-state" />
        <h2>You have not synced an Organisation yet!</h2>
        <Link to="/dashboard/sync">
          <button className="sync__btn">
            Sync an Organisation
          </button>
        </Link>
        
      </Empty>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
  height: calc(100vh - 63px);
`
const Empty = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
  h2 {
    margin: 2rem auto;
    color: #f7c948;
    font-size: 1.6rem;
  };
  button {
    color: #fff;
    background: #f7c948;
    border-radius: 6px;
    border:none;
    padding: 15px 30px;
  }
`;

export default EmptyState;
