import React from 'react';
import CreatableSelect from 'react-select/creatable';

const EmailContact = (props) => {
    // const [contact, setContact] = useState([]);
  return (
    <CreatableSelect
        isMulti
        onChange={props.handleChange}
        options={props.options}
        defaultValue={props.default}
    />
  )
}

export default EmailContact;