/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../config";
import NewAppQuestion from "./NewAppQuestion";
import { addApp, GetAllDomains } from "../../../redux/actions/tmail";
import { CircularProgress, LinearProgress } from "@material-ui/core";
import { toast } from "helpers/apiRequests";
import SelectableItem from "./utility/SelectableItem";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backButton: {
    backgroundColor: "#f7c948",
    boxShadow: "0px 3px 6px #00000029",
    padding: "1vh 1vw",
    outline: "none",
    textDecoration: "none",
    border: "none",
    borderRadius: "6px",
    color: "white",
    marginBottom: "1%",
  },
}));

const NewApp = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [apps, setApps] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [isLoadingRefApp, setIsLoadingRefApp] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [selectedApp, setSelectedApp] = useState(null);
  const [option, setOption] = useState("");
  const [subject, setSubject] = useState("");
  const [contactField, setContactField] = useState("");
  const [refApp, setRefApp] = useState("");
  const [selectedDomain, setSelectedDomain] = useState("");
  const [appType, setAppType] = useState("");
  const [fields, setFields] = useState({
    app_fields: [],
    subject_fields: [],
    email_fields: [],
    ref_app_email_fields: [],
  });
  const domains = useSelector((state) => state.tmail.domains);

  const verifiedDomain = domains.filter(
    (domain) => domain.verification_status === "active"
  );
  const domainOptions =
    verifiedDomain.length > 0
      ? domains.map((domain) => {
          return (
            <option key={domain.id} value={domain.id}>
              {" "}
              {domain.domain}{" "}
            </option>
          );
        })
      : null;

  const getValue = (option) => {
    let result;
    if (option === "one") {
      result = "manually";
      setSubject("");
      setRefApp("");
      setContactField("");
    } else if (option === "two") {
      result = "workspace_contacts";
      setRefApp("");
    } else if (option === "three") {
      result = "text_email_field_in_app";
      setRefApp("");
    } else if (option === "four") {
      result = "text_email_field_in_referenced_app";
    }
    return result;
  };

  const changeOption = (value) => {
    setOption(value);
    if (value === "one") {
      setSubject("");
      setRefApp("");
      setContactField("");
    } else if (value === "two") {
      setRefApp("");
    } else if (value === "three") {
      setRefApp("");
    } else if (value === "four") {
    }
  };

  const changeSubject = (val) => setSubject(val);
  const changeIsLoadingRefApp = () => setIsLoadingRefApp(true);
  const changeIsLoadingSaveApp = () => setSaveLoading(false);

  const changeContactField = (val) => {
    setContactField(val);
  };
  const handleDomainChange = (e) => {
    setSelectedDomain(e.target.value);
  };
  const handleAppTypeChange = (e) => {
    setAppType(e.target.value);
  };

  const changeRefApp = (val) => {
    changeIsLoadingRefApp();
    const app = apps.find((app) => app?.app_id === Number(selectedApp));
    getRefAppEmailFields(app, val);
    setRefApp(val);
    changeIsLoadingRefApp();
  };

  const handleAppChange = (val) => {
    const value = val?.value;
    setShowOptions(false);
    setSelectedApp(value);
    const app = apps.find((app) => app?.app_id === Number(value));
    getAllFields(app);
    setOption("");
    setSubject("");
    setRefApp("");
    setContactField("");

    setShowOptions(true);
  };

  useEffect(() => {
    getAllApps();
    dispatch(GetAllDomains());
  }, []);

  const getAllApps = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/tmail/list/podio-apps");
      setApps(response.apps);
      setLoading(false);
    } catch (error) {
      setApps([]);
      setLoading(false);
      toast.notify("An Error Occured: " + error, { type: "error" });
    }
  };

  const getAllFields = async (app) => {
    if (!app?.app_id && !app?.org_id) return;
    try {
      setIsLoading(true);
      const response = await axios.get(
        `/tmail/get/fields?app_id=${app?.app_id}&org_id=${app?.org_id}`
      );
      const data = response.data;
      setFields({
        app_fields: data ? data.app_fields : [],
        email_fields: data ? data.email_fields : [],
        subject_fields: data ? data.subject_fields : [],
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.notify("An Error Occured: " + error, { type: "error" });
    }
  };

  const getRefAppEmailFields = async (app, appId) => {
    try {
      setIsLoadingRefApp(true);
      const response = await axios.get(
        `/tmail/get/fields?app_id=${appId}&org_id=${app?.org_id}`
      );
      const data = response.data;
      setFields({ ...fields, email_fields: data ? data.email_fields : [] });
      setIsLoadingRefApp(false);
      setContactField("");
    } catch (error) {
      setIsLoadingRefApp(false);
      toast.notify("An Error Occured: " + error, { type: "error" });
    }
  };

  const handleSubmit = (e) => {
    try {
      e.preventDefault();
      setSaveLoading(true);
      const optionValue = getValue(option);
      if (!option || option === "") {
        toast.notify("Select an option", { type: "warn" });
        setSaveLoading(false);
        return;
      } else if (
        option === "three" &&
        (contactField === "" || subject === "")
      ) {
        toast.notify("Select an option", { type: "warn" });
        setSaveLoading(false);
        return;
      } else if (
        option === "four" &&
        (contactField === "" || subject === "" || refApp === "")
      ) {
        toast.notify("Select an option", { type: "warn" });
        setSaveLoading(false);
        return;
      }
      const app = apps.find((ap) => ap?.app_id === Number(selectedApp));
      let appFieldData;
      let contactFieldData;
      let subjectData;
      if (refApp !== "") {
        appFieldData =
          fields.app_fields.length === 0
            ? null
            : fields.app_fields.find((fid) => fid?.app_id === Number(refApp));
        appFieldData.field_name = appFieldData.name;
      }
      if (contactField !== "") {
        contactFieldData =
          fields.email_fields.length === 0
            ? null
            : fields.email_fields.find(
                (fid) => fid.id === Number(contactField)
              );
        contactFieldData.field_id = contactFieldData.id;
        contactFieldData.field_name = contactFieldData.name;
      }
      if (subject !== "") {
        subjectData =
          fields.subject_fields.length === 0
            ? null
            : fields.subject_fields.find((fid) => fid.id === Number(subject));
        subjectData.field_id = subjectData.id;
        subjectData.field_name = subjectData.name;
      }

      const data = {
        email_subjects: subjectData,
        referenced_app: appFieldData,
        contact_settings: optionValue,
        contact_field: contactFieldData,
        app_id: app?.app_id,
        app_name: app?.app_name,
        space_name: app?.space_name,
        space_id: app?.space_id,
        org_id: app?.org_id,
        domain_id: selectedDomain,
        app_link: app?.link,
        app_type: appType,
      };
      dispatch(addApp(data, history, changeIsLoadingSaveApp));
    } catch (error) {
      setSaveLoading(false);
    }
  };

  const goBack = () => {
    history.goBack();
  };

  const textStyle = {
    color: "#333333",
  };
  // let options = apps.length > 0 ? apps.map(app => {
  //     return <option
  //         key={app?.app_id}
  //         value={app?.app_id}
  //         disabled={app?.connected ? true : false}
  //     >
  //         {app?.name.toUpperCase()}
  //     </option>
  // }) : null

  let options =
    apps.length > 0
      ? apps.map((app) => {
          return {
            label: app?.name.toUpperCase(),
            value: app?.app_id,
            disabled: app?.connected ? true : false,
          };
        })
      : [];

  const handleBackButtonClick = () => {
    window.history.back();
  };

  return (
    <div className="container" style={textStyle}>
      <div>
        <button onClick={handleBackButtonClick} className={classes.backButton}>
          Back
        </button>
      </div>
      {loading ? (
        <center>
          <CircularProgress size={100} />
        </center>
      ) : (
        <div className="row justify-content-center">
          <div className="col-md-10">
            <h3 className="text-bold">Add new Podio App</h3>
            <div className="form-group">
              <label htmlFor="app">Select Podio App</label>
              <SelectableItem
                handleChange={handleAppChange}
                options={options}
                placeholder="Select Podio App"
                isMulti={false}
              />
            </div>
            <div className="form-group">
              <label htmlFor="app">Select Domain (optional)</label>
              {verifiedDomain.length === 0 ? (
                <input
                  readOnly
                  type="text"
                  value="No Verified domain setup"
                  className="form-control"
                />
              ) : (
                <select
                  onChange={handleDomainChange}
                  value={selectedDomain}
                  className="form-control"
                >
                  <option>Select Domain</option>
                  {domainOptions}
                </select>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="app">Select App Type</label>
              <select
                className="form-control"
                value={appType}
                onChange={handleAppTypeChange}
              >
                <option>Select App Type</option>
                <option value="1">App with unique email</option>
                <option value="2">App without unique email</option>
              </select>
            </div>
            {showOptions ? (
              !isloading ? (
                <div>
                  <h5 className="mb-3">
                    Where are the contacts' Email Addresses Stored?
                  </h5>
                  <NewAppQuestion
                    changeContactField={changeContactField}
                    option={option}
                    changeOption={changeOption}
                    changeRefApp={changeRefApp}
                    changeSubject={changeSubject}
                    fields={fields}
                    isloadingRefApp={isLoadingRefApp}
                    subject={subject}
                    refApp={refApp}
                    contactField={contactField}
                    InAppSetting={true}
                  />
                </div>
              ) : (
                <LinearProgress />
              )
            ) : null}

            <div className="form-goup mt-3">
              <button
                className="btn btn-default mr-2"
                onClick={handleSubmit}
                type="button"
                style={{
                  color: "#fff",
                  background: "#F7C948",
                }}
              >
                Save
              </button>
              <button className="btn btn-secondary ml-2" onClick={goBack}>
                Cancel
              </button>
            </div>
            {saveLoading ? (
              <center className="mt-3">
                <LinearProgress />
              </center>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default NewApp;
