import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classes from './style.module.css';
import { DOMAINSV2 } from '../../../containers/pagePath';

const useStyles = makeStyles({
    blue: {
        color: '#3f50b5'
    },
    green: {
        color: "#4caf50",
        fontSize: 15,
    },
    grey: {
        color: "#666"
    },
    bgDanger: {
        background: "#e00d06",
        color: "#fff",
        fontSize: 15,
        paddingLeft: 18,
    },
    bgGrey: {
        background: "#f9f1c9",
        color: "#8a6d3b",
        fontSize: 15,
    },
    wn: {
        fontSize: 17
    },
    cardDisplay: {
        display: 'block',
    }

});

const AlertCard = () => {
    const muClasses = useStyles();
    return (
        <Card variant="outlined" elevation={2} className={classes.cardClass} style={{ padding: 20, }} >
            <CardHeader title="Domains" className={muClasses.grey} />
            <Link to={DOMAINSV2} style={{ textDecoration: "none" }}>
                <CardContent className={muClasses.cardDisplay} >


                    <div className={`${classes.cardDiv} ${muClasses.bgDanger}`}>
                        <div className={classes.alertBd}>
                            <Icon className={muClasses.wn}>warning</Icon>
                            <h6>
                                Sending Email Domain
                            </h6>
                        </div>

                    </div>
                    {/*<div className={`${classes.cardDiv} ${muClasses.bgGrey}`}>*/}
                    {/*    <div className={classes.alertBd}>*/}
                    {/*        <Icon className={muClasses.wn}>warning</Icon>*/}
                    {/*        <h6>*/}
                    {/*            Free Trials Expires In*/}
                    {/*        </h6>*/}
                    {/*    </div>*/}

                    {/*</div>*/}
                    {/* <div className={`${classes.cardDiv} ${muClasses.bgGrey}`}>
                    <div className={classes.alertBd}>
                        <Icon className={muClasses.wn}>swipe</Icon>
                        <h6>
                            Join us in Podio
                        </h6>
                    </div>

                </div> */}
                </CardContent>
            </Link>
        </Card>
    )
}

export default AlertCard