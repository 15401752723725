import React from 'react';
import { Buffer } from 'buffer';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import { extractLabelVariablePairs } from '../util';

import styles from './createTable.module.scss';
import { ThemedReactSelect } from 'components/templates/print-v2/components/ThemedReactSelect';

const CreateTable = ({ setCurrentView, dataToInsert, setDataToInsert }) => {
  const [includeHeader, setIncludeHeader] = React.useState(true);
  const [formValues, setFormValues] = React.useState({
    variables: [],
    emptyValue: 'List is empty',
    border: 1,
    cellSpacing: 1,
    cellPadding: 1,
  });

  const handleCheckBoxChange = (event) => {
    setIncludeHeader(event.target.checked);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((previousValues) => ({ ...previousValues, [name]: value }));
  };

  function handleVariableChange(val) {
    setFormValues((previousValues) => ({ ...previousValues, variables: val }));
  }

  const variableOptions = React.useMemo(() => {
    return extractLabelVariablePairs(dataToInsert?.data);
  }, [dataToInsert]);

  function getTableVariableLabel(key) {
    var result = false;
    formValues.variables.forEach((v) => {
      if (v.value === key) {
        result = v.label;
      }
    });
    return result;
  }

  function generateTable() {
    const emptyValue = formValues.emptyValue;
    const variableGroup = dataToInsert.data.variable;
    const variable = formValues.variables;
    const border = formValues.border;
    const cellSpacing = formValues.cellSpacing;
    const cellPadding = formValues.cellPadding;
    const variables = [];

    variable.forEach((v) => variables.push(v));
    let tableItems = '';
    let name = '';
    const headers = [];

    variables.forEach((variable) => {
      const varName = /^\$\w+/g.exec(variable.value);
      if (varName) {
        name = varName[0];
      }
      if (includeHeader) {
        headers.push(getTableVariableLabel(variable.value));
      }
      if (variable.type === 'linked_image') {
        tableItems += '\n <td>' + variable.value + '</td>\n';
      } else {
        tableItems += '\n <td>{!!' + variable.value + '!!}</td>\n';
      }
    });

    let html = `<table class="dynamic_table" border=${border} cellpadding=${cellPadding} cellspacing=${cellSpacing} style="width: 99%">\n`;
    if (includeHeader) {
      html += '\n <thead>\n';
      html += '\n <tr>\n';
      headers.forEach((header) => {
        html += '\n <td>' + header + '</td>\n';
      });
      html += '\n </tr>\n';
      html += '\n </thead>\n';
    }

    let uuid = window.crypto.randomUUID();
    const loopVariables = ` @if(isset(${variableGroup}) && !empty(${variableGroup})) @foreach(${variableGroup} as ${name}) loop_foreach_variable_${uuid} @endforeach @else loop_else_variable_${uuid} @endif `;
    let encoded = Buffer.from(loopVariables).toString('base64');

    html += `\n <tbody class="ck_editor_hidden_operator" data-ck_hidden_operator="${encoded}">\n`;
    html += `\n <tr class="variables-items loop_foreach_variable_${uuid}">\n ${tableItems} \n</tr>\n`;
    html += `\n <tr class="loop_else_variable_${uuid} text-center"><td colSpan=${variables.length}>${emptyValue}</td></tr>\n`;
    html += '\n </tbody>\n';
    html += '\n </table>\n <br>';

    if (window.CKEDITOR) {
      const editor = window.CKEDITOR;
      const insertPosition = editor.model.document.selection.getFirstPosition();

      const viewFragment = editor.data.processor.toView(html);
      const modelFragment = editor.data.toModel(viewFragment);

      editor.model.insertContent(modelFragment, insertPosition);

      // hack to prevent variable being pasted multiple times witn enter btn is pressed
      document.activeElement.blur();
    }
  }

  return (
    <div>
      <button
        onClick={() => {
          setDataToInsert(undefined);
          setCurrentView('variables');
        }}
        className={styles.back_button}>
        <i className='fa fa-arrow-left'></i>
        Go Back
      </button>
      <p className={styles.create_table_title}>Insert table into document</p>

      {dataToInsert ? (
        <>
          <fieldset className='d-flex flex-column mb-2'>
            <label htmlFor='variables' className={`${styles.label} mb-2`}>
              Variables
            </label>
            <ThemedReactSelect isMulti name='variables' options={variableOptions} onChange={handleVariableChange} style={{ fontSize: '14px' }} />
          </fieldset>

          <FormControlLabel
            control={
              <GreyCheckbox checked={includeHeader} onChange={handleCheckBoxChange} inputProps={{ 'aria-label': 'primary checkbox' }} size='small' />
            }
            label='Include headers'
          />

          <fieldset className='d-flex flex-column mt-2 mb-3'>
            <label htmlFor='border' className={`${styles.label}`}>
              Border
            </label>
            <input name='border' id='border' value={formValues.border} onChange={handleChange} type='number' min={0} />
          </fieldset>

          <div className={styles.fieldGroup}>
            <fieldset className='d-flex flex-column mb-3'>
              <label htmlFor='cellSpacing' className={`${styles.label} mb-2`}>
                Cell spacing
              </label>
              <input name='cellSpacing' id='cellSpacing' value={formValues.cellSpacing} onChange={handleChange} type='number' min={0} />
            </fieldset>

            <fieldset className='d-flex flex-column mb-3'>
              <label htmlFor='cellPadding' className={`${styles.label} mb-2`}>
                Cell padding
              </label>
              <input name='cellPadding' id='cellPadding' value={formValues.cellPadding} onChange={handleChange} type='number' min={0} />
            </fieldset>
          </div>

          <fieldset className='d-flex flex-column'>
            <label htmlFor='variables-list-empty' className={`${styles.label} mb-2`}>
              Empty text
            </label>
            <input name='emptyValue' id='emptyValue' value={formValues.emptyValue} onChange={handleChange} />
            <p className={styles.helper_text}>This text will show if the list is empty.</p>
          </fieldset>

          <button className={`${styles.change_button} btn-success`} onClick={generateTable}>
            Insert to document
          </button>
        </>
      ) : null}
    </div>
  );
};

export default CreateTable;

const GreyCheckbox = withStyles({
  root: {
    color: 'grey',
    '&$checked': {
      color: 'grey',
    },
  },
  checked: {},
})((props) => <Checkbox color='default' {...props} />);
