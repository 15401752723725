import React from 'react';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {Link} from 'react-router-dom';
import classes from './style.module.css';
import { TMAIL_DASHBOARD_APPSV2} from '../../../containers/pagePath';

const useStyles = makeStyles({
  blue: {
    color: '#3f50b5',
  },
  green: {
    color: '#4caf50',
    fontSize: 15,
  },
  grey: {
    color: '#666',
  },
  bgDanger: {
    background: '#f00',
    color: '#fff',
    fontSize: 15,
  },
  bgGrey: {
    background: '#f9f1c9',
    color: '#8a6d3b',
    fontSize: 15,
  },
  wn: {
    fontSize: 17,
  },
});

const PodioApps = (props) => {
  const muClasses = useStyles();
  return (
    <Card
      variant='outlined'
      elevation={2}
      className={classes.cardClass}
      style={{ padding: 20 }}
    >
      <CardHeader title={props.title} className={muClasses.grey} />

      <CardContent>
        <Link to={TMAIL_DASHBOARD_APPSV2} style={{textDecoration: "none"}}>
        <div className={classes.multiApp}>
          <h2 style={{ marginTop: '10%' }}>{props.text}</h2>
        </div>
        </Link>
        <p style={{ color: '#666', marginTop: 10 }}>{props.message}</p>
      </CardContent>
    </Card>
  );
};

export default PodioApps;
