import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import MailIcon from '@material-ui/icons/Mail';
import styles from './SigneeCard.module.scss';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

const useStyles = makeStyles(() => ({
  root: {
    color: '#fff',
    backgroundColor: '#0277BD',
    height: '30px',
    width: '30px',
  },
}));

const SigneeCard = (props) => {
  const classes = useStyles();
  const { email, name } = props.signer;

  if (!name || !email) return null;

  return (
    <div className={styles.container}>
      <div className={styles.nameWrapper}>
        <Avatar className={classes.root}>{name?.label?.charAt(0)}</Avatar>
        <p>{name?.valueObject?.label}</p>
      </div>

      <div className={styles.nameWrapper}>
        <MailIcon style={{ color: '#0277bd' }} />
        <p>{email?.valueObject?.label}</p>
      </div>

      <div>
        <IconButton onClick={() => props.removeSigner(props.signer)} disableRipple>
          <DeleteOutlinedIcon fontSize='small' style={{ color: '#ff0055' }} />
        </IconButton>
      </div>
    </div>
  );
};

export default SigneeCard;
