import React from "react";
import { TMailSelect } from "../components/TMailSelect";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router";
import axios from "../../../config";
import { toast } from "helpers/apiRequests";
import { useEffect, useState } from "react";
import { GetAllDomains, addApp } from "redux/actions/tmail";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import NewAppQuestion from "../components/NewAppQuestion";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  text_field: {
    background: "transparent",
    width: "459px",
    height: "58px",
    fontSize: "14px",
    marginBottom: "23px",
  },
  btn: {
    backgroundColor: "#F0B429",
    color: "#fff",
    textTransform: "capitalize",
    padding: "11px 27px",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "normal",
    borderRadius: "5px",
  },
  btnCancel: {
    color: "#fff",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    textTransform: "capitalize",
    padding: "11px 27px",
    background: "#989898",
    borderRadius: "5px",
    border: "1px solid #989898",
  },
  btnGroup: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    marginTop: "52px",
  },
  backButton: {
    backgroundColor: "#f7c948",
    boxShadow: "0px 3px 6px #00000029",
    padding: "1vh 1vw",
    outline: "none",
    textDecoration: "none",
    border: "none",
    borderRadius: "6px",
    color: "white",
    marginBottom: "2%",
  },
}));

const NewApplicationView = () => {
  const classes = useStyles();
  const history = useHistory();
  const [apps, setApps] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [isLoadingRefApp, setIsLoadingRefApp] = useState(false);
  const [selectedApp, setSelectedApp] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const [option, setOption] = useState(null);
  const [subject, setSubject] = useState(null);
  const [refApp, setRefApp] = useState(null);
  const [contactField, setContactField] = useState(null);
  const [isloading, setIsLoading] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [appType, setAppType] = useState(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [fields, setFields] = useState({
    app_fields: [],
    subject_fields: [],
    email_fields: [],
    ref_app_email_fields: [],
  });
  const changeIsLoadingSaveApp = () => setSaveLoading(false);
  const changeIsLoadingRefApp = () => setIsLoadingRefApp(true);
  const domains = useSelector((state) => state.tmail.domains);
  const domainsLoading = useSelector((state) => state.tmail.loading);
  const changeSubject = (val) => setSubject(val);
  const verifiedDomain = domains.filter(
    (domain) => domain.verification_status === "active"
  );
  const domainOptions =
    verifiedDomain.length > 0
      ? domains.map((domain) => {
          return { value: domain.id, label: domain.domain };
        })
      : null;

  const goBack = () => {
    history.goBack();
  };

  const getAllApps = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/tmail/list/podio-apps");
      setApps(response.apps);
      setLoading(false);
    } catch (error) {
      setApps([]);
      setLoading(false);
      toast.notify("An Error Occured: " + error, { type: "error" });
    }
  };

  const handleAppChange = (val) => {
    const value = val?.value;
    setShowOptions(false);
    setSelectedApp(value);
    const app = apps.find((app) => app?.app_id === Number(value));
    getAllFields(app);
    setOption("");
    setSubject(null);
    setRefApp(null);
    setContactField(null);
    setShowOptions(true);
  };

  const getAllFields = async (app) => {
    if (!app?.app_id && !app?.org_id) return;
    try {
      setIsLoading(true);
      const response = await axios.get(
        `/tmail/get/fields?app_id=${app?.app_id}&org_id=${app?.org_id}`
      );
      const data = response.data;
      setFields({
        app_fields: data ? data.app_fields : [],
        email_fields: data ? data.email_fields : [],
        subject_fields: data ? data.subject_fields : [],
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.notify("An Error Occured: " + error, { type: "error" });
    }
  };

  const handleDomainChange = (payload) => {
    setSelectedDomain(payload);
  };

  const handleAppTypeChange = (payload) => {
    setAppType(payload);
  };

  const changeContactField = (val) => {
    setContactField(val);
  };

  let options =
    apps.length > 0
      ? apps.map((app) => {
          return {
            label: app?.name.toUpperCase(),
            value: app?.app_id,
            disabled: app?.connected ? true : false,
          };
        })
      : [];

  const changeOption = (value) => {
    setOption(value);
    if (value === "manually") {
      setSubject(null);
      setRefApp(null);
      setContactField(null);
    } else if (value === "single") {
      setRefApp(null);
    }
  };

  const getRefAppEmailFields = async (app, appId) => {
    try {
      setIsLoadingRefApp(true);
      const response = await axios.get(
        `/tmail/get/fields?app_id=${appId}&org_id=${app?.org_id}`
      );
      const data = response.data;
      setFields({ ...fields, email_fields: data ? data.email_fields : [] });
      setIsLoadingRefApp(false);
      setContactField(null);
    } catch (error) {
      setIsLoadingRefApp(false);
      toast.notify("An Error Occured: " + error, { type: "error" });
    }
  };

  const getValue = (option) => {
    let result;
    if (option === "manually") {
      result = "manually";
      setSubject(null);
      setRefApp(null);
      setContactField(null);
    } else if (option === "single") {
      result = "text_email_field_in_app";
      setRefApp(null);
    } else if (option === "single-reference") {
      result = "text_email_field_in_referenced_app";
    }
    return result;
  };

  const changeRefApp = (val) => {
    changeIsLoadingRefApp();
    const app = apps.find((app) => app?.app_id === Number(selectedApp));
    getRefAppEmailFields(app, val.value);
    setRefApp(val);
    changeIsLoadingRefApp();
  };

  useEffect(() => {
    getAllApps();
    dispatch(GetAllDomains());
  }, [dispatch]);

  const handleSubmit = () => {
    try {
      // e.preventDefault();
      setSaveLoading(true);
      const optionValue = getValue(option);
      if (!option || option === "") {
        toast.notify("Select an option", { type: "warn" });
        setSaveLoading(false);
        return;
      } else if (
        option === "single" &&
        (contactField?.value === "" || subject?.value === "")
      ) {
        toast.notify("Select an option", { type: "warn" });
        setSaveLoading(false);
        return;
      } else if (
        option === "single-reference" &&
        (contactField?.value === "" ||
          subject?.value === "" ||
          refApp?.value === "")
      ) {
        toast.notify("Select an option", { type: "warn" });
        setSaveLoading(false);
        return;
      }
      const app = apps.find((ap) => ap?.app_id === Number(selectedApp));
      let appFieldData;
      let contactFieldData;
      let subjectData;
      if (refApp?.value !== "") {
      
        appFieldData =
          fields.app_fields.length === 0
            ? null
            : fields.app_fields.find(
                (fid) => fid?.app_id === Number(refApp?.value)
              );
        appFieldData.field_name = appFieldData.name;
      }
      if (contactField?.value !== "") {
        // TODO: here
      
        contactFieldData =
          fields.email_fields.length === 0
            ? null
            : fields.email_fields.find(
                (fid) => fid.id === Number(contactField?.value)
              );
        contactFieldData.field_id = contactFieldData.id;
        contactFieldData.field_name = contactFieldData.name;
      }
      if (subject?.value !== "") {
        
        subjectData =
          fields.subject_fields.length === 0
            ? null
            : fields.subject_fields.find(
                (fid) => fid.id === Number(subject?.value)
              );
        subjectData.field_id = subjectData.id;
        subjectData.field_name = subjectData.name;
      }

      const data = {
        email_subjects: subjectData,
        referenced_app: appFieldData,
        contact_settings: optionValue,
        contact_field: contactFieldData,
        app_id: app?.app_id,
        app_name: app?.app_name,
        space_name: app?.space_name,
        space_id: app?.space_id,
        org_id: app?.org_id,
        domain_id: selectedDomain.value,
        app_link: app?.link,
        app_type: appType.value,
      };


      dispatch(addApp(data, history, changeIsLoadingSaveApp));
    } catch (error) {
      setSaveLoading(false);
    }
  };
  const handleBackButtonClick = () => {
    window.history.back();
  };
  return (
    <div>
      <div>
        <button onClick={handleBackButtonClick} className={classes.backButton}>
          Back
        </button>
      </div>
      <h5 className="mb-4">Add new Podio App</h5>

      <TMailSelect
        required
        placeholder="Select Podio Apps"
        className={classes.text_field}
        name="podio-apps"
        options={options}
        // value={selectedSourceOrgValue}
        onChange={handleAppChange}
        isDisabled={!options?.length > 0 || loading}
        isLoading={loading}
      />

      <TMailSelect
        required
        placeholder="Select Domain ( Optional )"
        className={classes.text_field}
        name="domains"
        options={domainOptions}
        value={selectedDomain}
        isLoading={domainsLoading}
        onChange={handleDomainChange}
        isDisabled={!domainOptions?.length > 0 || verifiedDomain.length === 0}
      />

      <TMailSelect
        required
        placeholder="Select App Type"
        className={classes.text_field}
        name="app_type"
        value={appType}
        onChange={handleAppTypeChange}
        options={[
          {
            label: "App with unique email",
            value: "1",
          },
          {
            label: "App without unique email",
            value: "2",
          },
        ]}
      />

      {showOptions && (
        <div className="mt-5 mb-4">
          <h6 className="mb-4">
            Where are the Contact’s Email Address stored?
          </h6>

          {isloading ? (
            <CircularProgress size={24} />
          ) : (
            <NewAppQuestion
              changeContactField={changeContactField}
              option={option}
              changeOption={changeOption}
              changeRefApp={changeRefApp}
              changeSubject={changeSubject}
              fields={fields}
              isloadingRefApp={isLoadingRefApp}
              subject={subject}
              refApp={refApp}
              contactField={contactField}
              InAppSetting={true}
            />
          )}
        </div>
      )}

      {saveLoading ? (
        <CircularProgress size={26} />
      ) : (
        <div className={classes.btnGroup}>
          <Button
            variant="contained"
            color="primary"
            disableElevation={true}
            className={classes.btn}
            onClick={handleSubmit}
          >
            Save
          </Button>
          <Button
            variant="contained"
            disableElevation={true}
            className={classes.btnCancel}
            onClick={goBack}
          >
            Cancel
          </Button>
        </div>
      )}
    </div>
  );
};

export default NewApplicationView;
