/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AddCircleOutline } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import {
  Modal,
  CircularProgress,
  Typography,
  LinearProgress,
} from "@material-ui/core";
import Notification from "../../Notification/Notification";
import axios from "../../../config";
import DomainList from "./DomainList";
import { createDomain, GetAllDomains } from "../../../redux/actions/tmail";
import { toast } from "helpers/apiRequests";
import appendTeamMemberHeader from "utils/appendTeamMemberHeader";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  mapper_creation: {
    position: "absolute",
    width: 400,
    margin: "auto",
    minWidth: "50%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #fff",
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const Domain = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [notificationData, setNotificationData] = useState({
    displayNotification: false,
    msg: "",
    severity: "success",
  });
  const domains = useSelector((state) => state.tmail.domains);
  const isLoading = useSelector((state) => state.tmail.loading);

  const [modalStyle] = useState(getModalStyle);
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState("");
  const [domain, setDomain] = useState("");
  const [region, setRegion] = useState("");
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [isDomainValid, setIsDomainValid] = useState(false);

  useEffect(() => {
    dispatch(GetAllDomains());
    getOrganizations();
  }, []);
  const token = "Bearer" + localStorage.getItem("chosen_token");
  let headers = {
    Authorization: token,
  };

  headers = appendTeamMemberHeader(headers);

  const changeRegion = (e) => {
    setRegion(e.target.value);
  };

  const getOrganizations = async () => {
    try {
      setLoading(true);
      let data = await axios.get(`/sync/podio/orgs/synced`, {
        headers,
      });
      setOrganizations(data.data);
      setLoading(false);
    } catch (e) {
      setNotificationData({
        displayNotification: true,
        msg: "Error retrieving organizations",
        severity: "error",
      });
    }
  };

  const handleOrganizationChange = async (event) => {
    setSelectedOrganization(event.target.value);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setOpen(false);
  };

  const changeIsLoadingSaveApp = () => setSaveLoading(false);

  const validateDomain = (domain) => {
    const result = domain.match(/^[a-z0-9-\.]+\.[a-z]{2,4}/);
    if (result) {
      setNotificationData({
        displayNotification: true,
        msg: "A valid Domain",
        severity: "success",
      });
      return true;
    }
    setNotificationData({
      displayNotification: true,
      msg: "Invalid Domain",
      severity: "error",
    });
    return false;
  };

  const handleDomainChange = (e) => {
    const value = e.target.value;
   
    setIsDomainValid(validateDomain(value));
    setDomain(value);
  };

  const submitDomain = (e) => {
    try {
      e.preventDefault();
      setSaveLoading(true);
      if (!selectedOrganization || !domain) {
        toast.notify(
          "Please fill all required fields and select an organization",
          { type: "info" }
        );
        setSaveLoading(false);
        return;
      } else if (!validateDomain(domain)) {
        toast.notify("Invalid Domain name", { type: "error" });
      }

      const payload = {
        org_id: selectedOrganization,
        domain,
        region: region,
      };
     
      dispatch(createDomain(payload, changeIsLoadingSaveApp, handleClose));
      // handleClose();
      setSelectedOrganization("")
      setDomain("");
      setRegion("");
    } catch (error) {
      setSaveLoading(false);
    }
  };

  const body = (
    <div style={modalStyle} className={classes.mapper_creation}>
      <Notification
        displayNotification={notificationData.displayNotification}
        severity={notificationData.severity}
        msg={notificationData.msg}
      />
      {loading ? (
        <center>
          <CircularProgress size={50} />
        </center>
      ) : (
        <form className="pt-2">
          <h3 className="mb-3">Create Domain</h3>
          <div className="form-group">
            <label htmlFor="organisation">Organization</label>

            <select
              onChange={handleOrganizationChange}
              value={selectedOrganization}
              className="form-control"
              id="organisation"
            >
              <option value="">Select Organization</option>

              {organizations.map((organization, index) => (
                <option key={index} value={organization.org_id}>
                  {organization.name}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="space">Domain Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="E.g domain.com"
              value={domain}
              onChange={handleDomainChange}
              pattern="./*"
            />
          </div>

          <div className="form-group">
            <label htmlFor="space">Select Region</label>
            <select
              name="smtp"
              id="smtp"
              className="form-control"
              onChange={changeRegion}
              value={region}
            >
              <option>Select Region</option>
              <option value="US">US</option>
              <option value="EU">EU</option>
            </select>
          </div>
          {!isDomainValid && domain !== "" ? (
            <center>
              <p className="text-danger">Invalid domain. E.g domain.com </p>
            </center>
          ) : null}
          <div className="d-flex justify-content-between mt-5">
            <button onClick={handleClose} className="sync__btn bg-danger">
              Cancel
            </button>
            <button
              onClick={submitDomain}
              className="sync__btn"
              disabled={!isDomainValid}
            >
              Create Domain
            </button>
          </div>
          {saveLoading ? (
            <center className="mt-3">
              <LinearProgress />
            </center>
          ) : null}
        </form>
      )}
    </div>
  );
  return (
    <section className="container p-4" style={{ backgroundColor: "#fff" }}>
      <div className="d-flex justify-content-between">
        <div className="">
          <h4>Sending Domains</h4>
        </div>
        <div className="">
          <button onClick={() => setOpen(true)} className="btn btn-dark">
            <AddCircleOutline /> Add New
          </button>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      <Typography component="div" style={{ backgroundColor: "#fff" }}>
        <div className="config-con-heading mt-5 mb-3">
          <div className="row">
            <div className="col-md-2">OrgId</div>
            <div className="col-md-2">OrgName</div>
            <div className="col-md-2">Domain</div>
            <div className="col-md-3">Status</div>
            <div className="col-md-3">Action</div>
          </div>
        </div>
      </Typography>
      <Typography component="div" style={{ backgroundColor: "#fff" }}>
        {isLoading ? (
          <center>
            <CircularProgress size={50} />{" "}
          </center>
        ) : domains.length > 0 ? (
          domains.map((domain) => {
            return <DomainList key={domain.id} item={domain} />;
          })
        ) : (
          <center>
            <Typography component="h4">No Domain Setup</Typography>
          </center>
        )}
      </Typography>
    </section>
  );
};

export default Domain;
