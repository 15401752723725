/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { authorisePodioPartner } from "../../../services/auth";
import { toast } from "helpers/apiRequests";
import error from "../../../assets/images/error.png";
import styled from "@emotion/styled";

const AuthorisePodioPartner = () => {
    const [loading, setLoading] = useState(false);
    const { search } = useLocation();
    const userId = new URLSearchParams(search).get('partnerId');
    const approvalStatus = new URLSearchParams(search).get('approvalStatus');
    const getData = async () => {
        const res = await axios.get('https://api.ipdata.co?api-key=b5459166ae9cc50ca5894eda2528c6bc53230e17e2f83bbc182124e2')
        
        return res.data;
    }
    const authorizeRequest = async () => {
        try {
            setLoading(true)
            const { ip } = await getData();
            let approval_status;
            let message;
            if (approvalStatus === "approve") {
                approval_status = true
                message = "You have been added as Podio Partner";
            }
            if (approvalStatus === "deny") {
                approval_status = false;
                message = "Your request to not be a Podio Partner is successful";
            }
            const payload = {
                user_id: userId,
                approval_status,
                approval_slug: approvalStatus,
                ip_address: ip
            }
           
            const res = await authorisePodioPartner(payload);
            setLoading(false)
            if (res.data || res.data.status === true) {
                toast.notify(message);
                setTimeout(()=>{
                    window.location.href = "/login";
                }, 2000)
                
            }
            // window.location.href = "/login";
            
        } catch (error) {
            setLoading(false)
            toast.notify("An Error Occurred. Pleas try again", { type: "error" });
            window.location.href = "/login";
        }

    }
    useEffect(() => {
        authorizeRequest()
        
    }, []);
    if (loading) {
        return (
          <div className="material_block">
            <svg
              className="spinner"
              width="65px"
              height="65px"
              viewBox="0 0 66 66"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                className="circle"
                fill="none"
                strokeWidth="6"
                strokeLinecap="round"
                cx="33"
                cy="33"
                r="30"
              ></circle>
            </svg>
          </div>
        );
      }
    return (
        <Wrapper>
          <React.Fragment>
            <img src={error} alt="reload" />
            <h1>Authorise To Podio Partner</h1>
          </React.Fragment>
        </Wrapper>
    )
}
const Wrapper = styled.div`
      /* background-color: red; */
      /* display: grid; */
      place-items: center;
      height: 100vh;
      grid-template-columns: 1fr;
      align-items:center;
      text-align:center;
      padding-top:100px;
      img{
        width:300px;
        margin-top:50px;
      }
      h1 {
        margin: 1rem auto;
        color: #8D2B0B;
        text-align:center;
      };
      button{
        color: #fff;
        background: #F7C948;
        border-radius: 6px;
        border: none;
        padding: 15px 30px;
      }
    `;

export default AuthorisePodioPartner