import { makeStyles } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CheckoutCardV2 from "./CheckoutCardV2";
import { formatNumber } from "utils/formatNumber";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  spaceItem: {
    height: "64px",
    width: "100%",
    backgroundColor: "#F7F7F7",
    marginBottom: "16px",
    padding: "23px 32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "40px",
    color: "#222",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    textTransform: "uppercase",
  },
  btn: {
    fontSize: "19px",
    color: "#E10000 !important",
    backgroundColor: "transparent",
    border: "none",
    height: "fit-content",
    width: "fit-content",
  },
  container: {
    display: "flex",
    gap: "73px",
    alignItems: "flex-start",
  },
  cartItems: {
    width: "100%",
    maxHeight: "600px",
  },
  checkoutBox: {
    width: "203px",
  },
  checkoutTitle: {
    color: "#25252D",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    letterSpacing: "-0.32px",
  },
  priceBox: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    borderTop: "1px solid #E0E0E0",
    borderBottom: "1px solid #E0E0E0",
    padding: "20px 0px",
    marginBottom: "5px",
  },
  price: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  priceTextStyle: {
    color: "#000",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    marginBottom: "0px",
  },
  btnContainer: {
    marginTop: "30px",
  },
  btnWhiteText: {
    backgroundColor: "#F0B429",
    borderRadius: "3px",
    textTransform: "capitalize",
    padding: "12px 32px",
    color: "#222 !important",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  tableBodyRow: {
    alignItems: "center",
    backgroundColor: "#fff",
    border: "none",
  },
  td: {
    border: "0px",
    borderBottom: "1px solid #E1E1E1",
    padding: "21px",
    color: "#626262",
    fontWeight: "400",
    fontSize: "16px",
  },
  checkInput: {
    width: "18px",
    height: "18px",
    borderRadius: "4px",
    appearance: "auto",
  },
  tableHeaderRow: {
    alignItems: "center",
    backgroundColor: "#F7F7F7",
  },
  th: {
    border: "0px",
    borderBottom: "1px solid #E1E1E1",
    padding: "21px",
    color: "#222",
    fontWeight: "600",
    fontSize: "14px",
  },
  [theme.breakpoints.down("sm")]: {
    checkInput: {
      marginBottom: "0px",
    },
  },
}));

const AppCheckoutV2 = ({
  setActiveScreen,
  data,
  sourceData,
  setData,
  submitData,
  loading,
}) => {
  const classes = useStyles();
  const user = useSelector((state) => state?.user?.authUser);

  const [selectedAppsIds, setSelectedAppsIds] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [responseData, setResponseData] = useState({
    apps_count: 0,
    unit_item_cost: 0.05,
    subtotal: 0,
    taxes_cost: 0,
    files_count: 0,
    items_count: 0,
  });

  useEffect(() => {
    cache(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cache = () => {
    if (data.length > 0) {
      const storedData = localStorage.getItem("responseData");
      if (storedData) {
        setResponseData(JSON.parse(storedData));
      }
    } else {
      localStorage.removeItem("responseData");
    }
  };

  useEffect(() => {
    localStorage.setItem("responseData", JSON.stringify(responseData));
  }, [responseData]);

  const handleSubmit = async () => {
    const response = await submitData();
    if (response && user.free_trial === true) {
      setActiveScreen("success");
    } else if (response && user.new_billing === true) {
      setActiveScreen("success");
    } else {
      setActiveScreen("payment");
    }
  };

  const markAll = () => {
    const newApps = sourceData.data?.map((el) => el.app_id);
    const collectivePrice = sourceData.data?.reduce((accumulator, el) => {
      return accumulator + el.items_cost;
    }, 0);

    const collectiveItems = sourceData.data?.reduce((accumulator, el) => {
      return accumulator + el.items_count;
    }, 0);

    const collectiveFiles = sourceData.data?.reduce((accumulator, el) => {
      return accumulator + el.item_files_count;
    }, 0);

    setSelectedAppsIds((prev) => {
      setResponseData((prevResData) => ({
        ...prevResData,
        subtotal: isAllSelected || prev.includes(newApps) ? 0 : collectivePrice,
        items_count:
          isAllSelected || prev.includes(newApps) ? 0 : collectiveItems,
        files_count:
          isAllSelected || prev.includes(newApps) ? 0 : collectiveFiles,
      }));
      return isAllSelected || prev.includes(newApps) ? [] : newApps;
    });
    setIsAllSelected((prev) => !prev);
  };

  const handleCheckBox = (appId, cost, files_count, items_count) => {
    if (appId) {
      setSelectedAppsIds((prevAppIds) => {
        if (prevAppIds.includes(appId)) {
          setResponseData((prev) => ({
            ...prev,
            subtotal: prev.subtotal - cost,
            files_count: prev.files_count - files_count,
            items_count: prev.items_count - items_count,
          }));
          return prevAppIds.filter((ele) => ele !== appId);
        } else {
          setResponseData((prev) => ({
            ...prev,
            subtotal: prev.subtotal + cost,
            files_count: prev.files_count + files_count,
            items_count: prev.items_count + items_count,
          }));
          return [...prevAppIds, appId];
        }
      });
    }
  };

  useEffect(() => {
    setSelectedAppsIds(data);
  }, [data]);

  useEffect(() => {
    if (selectedAppsIds.length > 0) {
      setData((prev) => ({ ...prev, source_app_ids: selectedAppsIds }));
    }
    setIsAllSelected(selectedAppsIds.length === sourceData.data.length);
    setResponseData((prev) => ({
      ...prev,
      apps_count: selectedAppsIds.length,
    }));
  }, [selectedAppsIds, setData, sourceData]);

  return (
    <div className={classes.container}>
      <div className={`${classes.cartItems} table-responsive`}>
        <table className={classes.table}>
          <thead>
            <tr className={classes.tableHeaderRow}>
              <th className={classes.th}>
                <input
                  type="checkbox"
                  onChange={markAll}
                  className={classes.checkInput}
                  checked={isAllSelected}
                />
              </th>
              <th className={classes.th}>App Id</th>
              <th className={classes.th}>Name</th>
              <th className={classes.th}>Organization</th>
              <th className={classes.th}>Workspace</th>
              <th className={classes.th}>Files</th>
              <th className={classes.th}>Items</th>
              {user.free_trial === true ? null : (
                <th className={classes.th}>Cost</th>
              )}
            </tr>
          </thead>
          <tbody>
            {sourceData.processing && (
              <tr>
                <td colSpan="7" className={classes.center}>
                  <CircularProgress size={24} />
                </td>
              </tr>
            )}
            {!sourceData.processing &&
              sourceData.data &&
              sourceData.data
                // .filter(item => data?.includes(item.app_id))
                .map((item) => (
                  <tr key={item.app_id} className={classes.tableBodyRow}>
                    <td className={classes.td}>
                      <input
                        type="checkbox"
                        className={classes.checkInput}
                        id={item.app_id}
                        name={item.app_id}
                        checked={selectedAppsIds.includes(item.app_id)}
                        onChange={() =>
                          handleCheckBox(
                            item.app_id,
                            item.files_cost + item.items_cost,
                            item.item_files_count,
                            item.items_count
                          )
                        }
                        value={item.app_id}
                      />
                    </td>
                    <td className={classes.td}>{item.app_id}</td>
                    <td className={classes.td}>{item.name}</td>
                    <td className={classes.td}>
                      {item.organization[0].org_name}
                    </td>
                    <td className={classes.td}>{item.space.name}</td>
                    <td className={classes.td}>{item.item_files_count}</td>
                    <td className={classes.td}>{item.items_count}</td>
                    {user.free_trial === true ? null : (
                      <td className={classes.td}>
                        ${formatNumber(item.files_cost + item.items_cost)}
                      </td>
                    )}
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
      <CheckoutCardV2
        responseData={responseData}
        onClick={handleSubmit}
        loading={loading}
        btnTitle="Proceed to Payment"
      />
    </div>
  );
};

export default AppCheckoutV2;
