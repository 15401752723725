/* eslint-disable no-unused-vars */
import React, { useState, useEffect, Fragment } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { connect, useDispatch } from "react-redux";
import Typography from "@material-ui/core/Typography";
// import ClearIcon from "@material-ui/icons/Clear";
import { useDrag } from "react-dnd";
import { ItemTypes } from "../../../../../constants/ItemTypes";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Popover from "@material-ui/core/Popover";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {
    updateFieldDetail,
} from "../../../../../redux/actions/appBuilder";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import CategoryIcon from '@material-ui/icons/Category';
import CustomButton from "../../../../../components/atoms/CustomButton";
import clsx from "clsx";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Tooltip from "@material-ui/core/Tooltip";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Button from "../../../../../components/atoms/Button";
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        fontFamily: "Mulish",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "24px",
        lineHeight: "30px",
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <Tooltip title="Close Modal">
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Tooltip>
            ) : null}
        </MuiDialogTitle>
    );
});

const useStyles = makeStyles((theme) => ({
    section: {
        marginTop: "25px",
        height: "120px",
        width: "100%",
        backgroundColor: "#fffffffa",
    },
    fieldPaper: {
        "&:hover $clearIndicatorDirty, & .Mui-focused $clearIndicatorDirty": {
            visibility: "visible"
        },
        backgroundColor: "#F7F7F7",
        border: "1px solid #B1B1B1",
        height: "70px",
    },
    optionSection: {
        "&:hover $clearIndicatorDirty, & .Mui-focused $clearIndicatorDirty": {
            visibility: "visible"
        },
        paddingTop: "10px",
    },
    popover: {
        width: "200px",
        padding: "10px",
        borderRadius: theme.shape.borderRadius,
    },
    checkboxLabel: {
        fontSize: "14px",
    },
    optionPopover: {
        width: "auto"
    },
    fieldPallete: {
        borderRadius: "3px",
    },
    clearIndicatorDirty: {},
    clearIndicator: {
        visibility: "hidden"
    },
    colorDDDDDD: {
        backgroundColor: "#DDD",
        color: "#6a6a6a",
        cursor: "pointer"
    },
    colorF7D1D0: {
        backgroundColor: "#F7D1D0",
        color: "#c12521",
        cursor: "pointer"
    },
    colorDCEBD8: {
        backgroundColor: "#DCEBD8",
        color: "#5a924b",
        cursor: "pointer"
    },
    colorD2E4EB: {
        backgroundColor: "#D2E4EB",
        color: "#427e95",
        cursor: "pointer"
    },
    colorF7F0C5: {
        backgroundColor: "#F7F0C5",
        color: "#bda61a",
        cursor: "pointer"
    },
    colorE1D8ED: {
        backgroundColor: "#E1D8ED",
        color: "#6a4799",
        cursor: "pointer"
    },
    colorFFD5C2: {
        backgroundColor: "#FFD5C2",
        color: "#dc4400"
    },
    colorD1F3EC: {
        backgroundColor: "#D1F3EC",
        color: "#2eb096",
        cursor: "pointer"
    },
    iconDDDDDD: {
        color: "#6a6a6a",
        cursor: "pointer"
    },
    iconF7D1D0: {
        color: "#c12521",
        cursor: "pointer"
    },
    iconDCEBD8: {
        color: "#5a924b",
        cursor: "pointer"
    },
    iconD2E4EB: {
        color: "#427e95",
        cursor: "pointer"
    },
    iconF7F0C5: {
        color: "#bda61a",
        cursor: "pointer"
    },
    iconE1D8ED: {
        color: "#6a4799",
        cursor: "pointer"
    },
    iconFFD5C2: {
        color: "#dc4400",
        cursor: "pointer"
    },
    iconD1F3EC: {
        color: "#2eb096",
        cursor: "pointer"
    },
}));

const DraggableFieldCategory = ({
    view,
    removeSelectedField,
    fieldId,
    sectionId,
    columnType,
    fieldDetails,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState(null);
    const [helpTextAnchorEl, setHelpTextAnchorEl] = useState(null);
    const [hoverOnField, setHoverOnField] = useState(false);
    const [categoryDialog, setCategoryDialog] = useState(false);

    const [newOptionName, setNewOptionName] = useState("");

    const [label, setLabel] = useState(
        fieldDetails?.config?.label ? fieldDetails.config.label : "Category"
    );

    const [requiredField, setRequired] = useState(
        fieldDetails?.config?.required
            ? fieldDetails.config.required
            : false
    );
    const [hiddenIfEmpty, setHiddenIfEmpty] = useState(
        fieldDetails?.config?.hidden
            ? fieldDetails.config.hidden
            : false
    );

    const [alwaysHidden, setAlwaysHidden] = useState(
        fieldDetails?.config?.hidden_create_view_edit ? fieldDetails.config.hidden_create_view_edit : false);

    const [status] = useState(
        fieldDetails?.status ? fieldDetails.status : "active"
    );

    const [defaultValue] = useState(
        fieldDetails?.config?.default_value ?
            fieldDetails.config.default_value :
            null
    );

    const [unique] = useState(
        fieldDetails?.config?.unique ?
            fieldDetails.config.unique :
            false
    )

    const [description, setDescription] = useState(
        fieldDetails?.config?.description ?
            fieldDetails.config.description :
            "Add Help Text"
    )

    const [settings] = useState(
        fieldDetails?.config?.settings ?
            fieldDetails.config.settings :
            {}
    )
    const [categoryOptions, setCategoryOptions] = useState(
        fieldDetails?.config?.settings?.options ?
            fieldDetails.config.settings.options :
            []
    )
    const [colorInCalendars, setColorInCalendars] = useState(
        fieldDetails?.config?.settings?.color_in_calendars ?
            fieldDetails.config.settings.color_in_calendars :
            false
    )
    const [display, setDisplay] = useState(
        fieldDetails?.config?.settings?.display ?
            fieldDetails.config.settings.display :
            "inline"
    )
    const [choice, setChoice] = useState(
        fieldDetails?.config?.settings?.multiple ?
            "multiple" :
            "single"
    )

    const [externalId] = useState(
        fieldDetails?.external_id
            ? fieldDetails.external_id : null
    );

    const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
        item: {
            label,
            type: ItemTypes.FIELD_CATEGORY,
            status,
            config: {
                label,
                required: requiredField,
                hidden: hiddenIfEmpty,
                default_value: defaultValue,
                description,
                settings: {
                    ...settings,
                    options: categoryOptions,
                    display,
                    multiple: choice === "multiple" ? true : false,
                    color_in_calendars: colorInCalendars
                },
                unique: unique,
                hidden_create_view_edit: alwaysHidden
            }
        },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));

    const openPopover = Boolean(anchorEl);
    const id = openPopover ? "simple-popover" : undefined;

    const openHelpPopover = Boolean(helpTextAnchorEl);
    const helpTextID = openHelpPopover ? "help-text-popover" : undefined;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleHelpTextPopoverClose = () => {
        setHelpTextAnchorEl(null)
    }

    const handleHelpTextPopOverClick = (event) => {
        setHelpTextAnchorEl(event.currentTarget)
    }

    const handleOpenCategoryDialog = () => {
        setCategoryDialog(true);
    };

    const handleCloseCategoryDialog = (event) => {
        setCategoryDialog(false)
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            const newOptions = [
                ...categoryOptions,
                {
                    status: "active",
                    text: newOptionName,
                    id: categoryOptions.length + 1,
                    color: "D2E4EB"
                }
            ];
            setCategoryOptions(newOptions)
            setNewOptionName("")
            return;
        }
    }


    const updateOption = (key, value) => {
        const newOptions = categoryOptions.map((option) => {
            if (option.id === key) {
                return { ...option, text: value }
            }
            return option;
        })
        setCategoryOptions(newOptions)
    }

    const updateOptionColor = (key, value) => {
        const newOptions = categoryOptions.map((option) => {
            if (option.id === key) {
                return { ...option, color: value }
            }
            return option;
        })
        setCategoryOptions(newOptions)
    }

    const removeOption = (key) => {
        const newOptions = categoryOptions.map((option) => {
            if (option.id === key) {
                return { ...option, status: "deleted" }
            }
            return option;
        })
        setCategoryOptions(newOptions)
    }
    const saveOptions = (e) => {
        e.preventDefault();
        const newOptions = [...categoryOptions];
        setCategoryOptions(newOptions)
        setCategoryDialog(false)
    }

    useEffect(() => {
        let config = {
            label,
            required: requiredField,
            hidden: hiddenIfEmpty,
            default_value: defaultValue,
            description,
            settings: {
                ...settings,
                options: categoryOptions,
                display,
                multiple: choice === "multiple" ? true : false,
                color_in_calendars: colorInCalendars
            },
            unique: unique,
            hidden_create_view_edit: alwaysHidden
        };
        let payload = {
            config,
            ...((externalId) && { external_id: externalId }),
            ...((status) && { status: status }),
            sectionId,
            fieldId,
            columnType,
            label
        };

        dispatch(updateFieldDetail(payload));
    }, [label, requiredField, hiddenIfEmpty, alwaysHidden, sectionId, fieldId, columnType, dispatch, defaultValue, description, settings, unique, categoryOptions, externalId, status, display, choice, colorInCalendars]);


    return (
        <Fragment>
            {view === "pallete" ? (
                <div className={classes.fieldPallete}>
                    <div
                        className="pb-2 pt-2"
                        ref={dragPreview}
                        style={{ opacity: isDragging ? 0.5 : 1 }}
                    >
                        <div className="row" ref={drag}>
                            <div className="col-md-12">
                                <Paper className={classes.fieldPaper}>
                                    <Typography className="pt-4 ml-3">
                                        <CategoryIcon size="small" />
                                        <span className="ml-3">Category</span>
                                    </Typography>
                                </Paper>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={classes.field}>
                    <div
                        className="pb-2 pt-2"
                        ref={dragPreview}
                        style={{ opacity: isDragging ? 0.5 : 1 }}
                    >
                        <div className="row">
                            <div className="col-md-12">
                                <Paper
                                    className={classes.fieldPaper}
                                    onMouseEnter={() => setHoverOnField(true)}
                                    onMouseLeave={() => setHoverOnField(false)}
                                >
                                    <div className="row ml-1 mt-2">
                                        <div className="col-md-9">
                                            <TextField
                                                style={{
                                                    backgroundColor: "white",
                                                    marginBottom: "10px",
                                                }}
                                                variant="outlined"
                                                autoFocus
                                                margin="dense"
                                                value={label}
                                                onChange={(e) => {
                                                    setLabel(e.target.value);
                                                }}
                                                id="name"
                                                type="text"
                                                size="small"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <CategoryIcon
                                                                style={{ cursor: "pointer" }}
                                                                onClick={handleClick}
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-3 mt-2">
                                            {/* <ClearIcon
                                                className={clsx(classes.clearIndicator, {
                                                    [classes.clearIndicatorDirty]: hoverOnField
                                                })}
                                                size="small"
                                                onClick={() =>
                                                    removeSelectedField(
                                                        fieldId,
                                                        sectionId,
                                                        columnType,
                                                        fieldDetails?.external_id
                                                    )
                                                }
                                                style={{
                                                    float: "right",
                                                    marginBottom: "10px",
                                                    marginRight: "5px",
                                                    fontSize: "20px",
                                                    fontWeight: "900",
                                                    cursor: "pointer",
                                                }}
                                            /> */}
                                        </div>
                                    </div>
                                </Paper>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <Popover
                id={id}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClose}
                classes={{
                    paper: classes.popover,
                }}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
            >
                <div className="" style={{ padding: "10px" }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={requiredField}
                                onChange={(e) => {
                                    setRequired(!requiredField);
                                    setHiddenIfEmpty(requiredField);
                                    setAlwaysHidden(requiredField);
                                }}
                                id="required_field"
                                name="required_field"
                            />
                        }
                        classes={{
                            label: classes.checkboxLabel,
                        }}
                        label="Required Field"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={hiddenIfEmpty}
                                onChange={(e) => {
                                    setHiddenIfEmpty(!hiddenIfEmpty);
                                    setRequired(hiddenIfEmpty);
                                    setAlwaysHidden(hiddenIfEmpty);
                                }}
                                id="hidden_if_empty"
                                name="hidden_if_empty"
                            />
                        }
                        classes={{
                            label: classes.checkboxLabel,
                        }}
                        label="Hidden if empty"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={alwaysHidden}
                                onChange={(e) => {
                                    setAlwaysHidden(!alwaysHidden);
                                    setHiddenIfEmpty(alwaysHidden);
                                    setRequired(alwaysHidden);
                                }}
                                id="always_hidden"
                                name="always_hidden"
                            />
                        }
                        classes={{
                            label: classes.checkboxLabel,
                        }}
                        label="Always Hidden"
                    />
                    <hr />
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={colorInCalendars}
                                onChange={(e) => {
                                    setColorInCalendars(!colorInCalendars);
                                }}
                                id="color_in_calendars"
                                name="color_in_calendars"
                            />
                        }
                        classes={{
                            label: classes.checkboxLabel,
                        }}
                        label="Color In Calendars"
                    />
                    <hr />
                    <RadioGroup
                        required={true}
                        aria-label="line_type"
                        id="line_type"
                        aria-describedby="Select line type"
                        name="line_type"
                        value={choice}
                        style={{ fontSize: "14px" }}
                        onChange={(e) => {
                            setChoice(e.target.value);
                        }}
                    >
                        <FormControlLabel
                            value="single"
                            aria-describedby="Single Choice"
                            control={<Radio color="primary" />}
                            classes={{
                                label: classes.checkboxLabel,
                            }}
                            label="Single Choice"
                        />
                        <FormControlLabel
                            value="multiple"
                            aria-describedby="Multiple Choice"
                            control={<Radio color="primary" />}
                            classes={{
                                label: classes.checkboxLabel,
                            }}
                            label="Multiple Choice"
                        />
                    </RadioGroup>
                    <hr />
                    <RadioGroup
                        required={true}
                        aria-label="line_type"
                        id="line_type"
                        aria-describedby="Select line type"
                        name="line_type"
                        value={display}
                        style={{ fontSize: "14px" }}
                        onChange={(e) => {
                            setDisplay(e.target.value);
                        }}
                    >
                        <FormControlLabel
                            value="inline"
                            aria-describedby="Inline"
                            control={<Radio color="primary" />}
                            classes={{
                                label: classes.checkboxLabel,
                            }}
                            label="Inline"
                        />
                        <FormControlLabel
                            value="list"
                            aria-describedby="list"
                            control={<Radio color="primary" />}
                            classes={{
                                label: classes.checkboxLabel,
                            }}
                            label="List"
                        />
                        <FormControlLabel
                            value="dropdown"
                            aria-describedby="Dropdown list"
                            control={<Radio color="primary" />}
                            classes={{
                                label: classes.checkboxLabel,
                            }}
                            label="Drop-down list"
                        />
                    </RadioGroup>
                    <hr />
                    <div onClick={handleOpenCategoryDialog} style={{ cursor: "pointer" }}>
                        <h6>Add Options</h6>
                    </div>
                    <hr />
                    <div onClick={handleHelpTextPopOverClick} style={{ cursor: "pointer" }}>
                        <h6>Add Help Text</h6>
                    </div>
                    <hr />
                </div>
            </Popover>
            <Popover
                id={helpTextID}
                open={openHelpPopover}
                anchorEl={helpTextAnchorEl}
                onClose={handleHelpTextPopoverClose}
                classes={{
                    paper: classes.optionPopover,
                }}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                style={{ width: "auto" }}
            >
                <div className="" style={{ padding: "10px" }}>
                    <TextField
                        label={"Description"}
                        multiline
                        style={{
                            paddingBottom: "10px"
                        }}
                        placeholder="Enter help text"
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                        fullWidth
                        type="text"
                        margin="none"
                    />
                    <br />
                    <CustomButton
                        variant="contained"
                        color="primary"
                        type="submit"
                        style={{ paddingLeft: "10px" }}
                        onClick={handleHelpTextPopoverClose}
                    >
                        Close
                    </CustomButton>
                </div>
            </Popover>
            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={categoryDialog}
                onClose={handleCloseCategoryDialog}
                aria-labelledby="form-dialog-title"
                className="dialog-box"
            >
                <DialogContent>
                    <div className="head">
                        <h3>Category</h3>
                        <DialogTitle
                            id="form-dialog-title"
                            onClose={handleCloseCategoryDialog}
                        ></DialogTitle>
                    </div>
                    <div>Add/Update Options</div>
                    <Divider className="divider" />
                    <Fragment>
                        <Grid container style={{ paddingBottom: "10px" }}>
                            <Grid item xs={12}>
                                {categoryOptions.map((option, key) => {
                                    if (option.status === "active") {
                                        return (
                                            <div
                                                key={key}
                                                className={classes.optionSection}
                                                onMouseEnter={() => setHoverOnField(true)}
                                                onMouseLeave={() => setHoverOnField(false)}
                                            >
                                                <TextField
                                                    placeholder="Enter a category option"
                                                    value={option.text}
                                                    onChange={e => updateOption(option.id, e.target.value)}
                                                    fullWidth
                                                    type="text"
                                                    margin="none"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <PopupState variant={'popover'} popupId={'app-popover'}>
                                                                    {(popupState) => (
                                                                        <div>
                                                                            <AssignmentReturnedIcon
                                                                                style={{
                                                                                    float: "right",
                                                                                    marginBottom: "10px",
                                                                                    marginRight: "5px",
                                                                                    fontSize: "20px",
                                                                                    fontWeight: "900",
                                                                                    cursor: "pointer"
                                                                                }}
                                                                                className={`${classes["icon" + option.color]}`}
                                                                                {...bindTrigger(popupState)}
                                                                            />
                                                                            <Popover
                                                                                {...bindPopover(popupState)}
                                                                                anchorOrigin={{
                                                                                    vertical: 'center',
                                                                                    horizontal: 'center',
                                                                                }}
                                                                                transformOrigin={{
                                                                                    vertical: 'top',
                                                                                    horizontal: 'right',
                                                                                }}
                                                                            >
                                                                                <div className="" style={{ padding: "10px" }}>
                                                                                    <ul className="list-group list-group-horizontal">
                                                                                        <li
                                                                                            className={`${classes.colorDDDDDD} list-group-item`}
                                                                                            onClick={() => updateOptionColor(option.id, "DDDDDD")}
                                                                                        ></li>
                                                                                        <li
                                                                                            className={`${classes.colorF7D1D0} list-group-item`}
                                                                                            onClick={() => updateOptionColor(option.id, "F7D1D0")}
                                                                                        ></li>
                                                                                        <li
                                                                                            className={`${classes.colorDCEBD8} list-group-item`}
                                                                                            onClick={() => updateOptionColor(option.id, "DCEBD8")}
                                                                                        ></li>
                                                                                        <li
                                                                                            className={`${classes.colorD2E4EB} list-group-item`}
                                                                                            onClick={() => updateOptionColor(option.id, "D2E4EB")}
                                                                                        ></li>
                                                                                        <li
                                                                                            className={`${classes.colorF7F0C5} list-group-item`}
                                                                                            onClick={() => updateOptionColor(option.id, "F7F0C5")}
                                                                                        ></li>
                                                                                        <li
                                                                                            className={`${classes.colorE1D8ED} list-group-item`}
                                                                                            onClick={() => updateOptionColor(option.id, "E1D8ED")}
                                                                                        ></li>
                                                                                        <li
                                                                                            className={`${classes.colorFFD5C2} list-group-item`}
                                                                                            onClick={() => updateOptionColor(option.id, "FFD5C2")}
                                                                                        ></li>
                                                                                        <li
                                                                                            className={`${classes.colorD1F3EC} list-group-item`}
                                                                                            onClick={() => updateOptionColor(option.id, "D1F3EC")}
                                                                                        ></li>
                                                                                    </ul>
                                                                                </div>
                                                                            </Popover>
                                                                        </div>

                                                                    )}
                                                                </PopupState>
                                                                {/* <ClearIcon
                                                                    className={clsx(classes.clearIndicator, {
                                                                        [classes.clearIndicatorDirty]: hoverOnField
                                                                    })}
                                                                    size="small"
                                                                    onClick={() => removeOption(option.id)}
                                                                    style={{
                                                                        float: "right",
                                                                        marginBottom: "10px",
                                                                        marginRight: "5px",
                                                                        fontSize: "20px",
                                                                        fontWeight: "900",
                                                                        cursor: "pointer",
                                                                    }}
                                                                /> */}
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </div>
                                        );
                                    }
                                    return "";
                                })}
                                <TextField
                                    placeholder="Enter a category option"
                                    value={newOptionName}
                                    onChange={(e) => setNewOptionName(e.target.value)}
                                    onKeyPress={(e) => handleKeyPress(e)}
                                    fullWidth
                                    type="text"
                                    margin="none"
                                    className={"pt-4"}
                                />
                            </Grid>
                        </Grid>
                    </Fragment>
                </DialogContent>
                <div className="actions">
                    <Button
                        tooltip={true}
                        tooltip_title="Close Category Modal"
                        tooltip_placement="bottom"
                        function={saveOptions}
                        style={{ background: `#F7C948`, color: `#626262` }}
                        text="Close"
                    />
                </div>
            </Dialog>
        </Fragment>
    );
};

const mapStateToProps = (state, ownProps) => ({
    fieldDetails: !ownProps.columnType && !ownProps.sectionId && !ownProps.fieldId
        ? {}
        : state.app_builder.appSections
            .find((section) => section.id === ownProps.sectionId)
            .columns[ownProps.columnType].find(
                (field) => field.field_id === ownProps.fieldId
            ),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DraggableFieldCategory);
