import React, { Fragment } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// import { useDispatch } from "react-redux";
// import { clearCurrent } from "../../redux/actions/connector";

const useStyles = makeStyles((theme) => ({
  InfoContainer: {
    display: "flex",
    fontSize: "small",
    justifyContent: "space-between",
    color: "black",
    borderBottom: "1px solid #F7F7F7",
    marginTop: "3%"
  },
}));
const NewConnectorDetails = ({ openModal, handleClose, responseData }) => {
  // const dispatch = useDispatch();
  const classes = useStyles();

  const closeModal = () => {
    handleClose();
    // dispatch(clearCurrent())
  };

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        open={openModal}
        onClose={handleClose}
        // hideBackdrop={true}
        disableBackdropClick={false}
        disableEscapeKeyDown={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="connector-modal"
      >
        <DialogContent>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {responseData?.data.map((response, index) => (
                <Fragment key={index}>
                  <Typography
                    style={{
                      backgroundColor: "#FFFAEB",
                      color: "#DE911D",
                      padding: "5px 20px",
                      borderRadius: "10px",
                      fontSize: "14px",
                    }}
                    className="mb-3"
                    gutterBottom
                  >
                    <h5 className="pt-2 pb-2" style={{ fontSize: "14px" }}>
                      Database has successfully been provisioned for you -{" "}
                      <span> {response?.db_driver.toUpperCase()} Driver</span>
                    </h5>
                  </Typography>
                  <Typography gutterBottom key={index}>
                    <Box className={classes.InfoContainer}>
                      <p>Connection String:</p>{" "}
                      <p style={{ textAlign: "right" }}>{response?.db_host}</p>
                    </Box>
                    <Box className={classes.InfoContainer}>
                      <p>Port:</p> <p>{response?.db_port}</p>
                    </Box>
                    <Box className={classes.InfoContainer}>
                      <p>User Id:</p> <p>{response?.user_id}</p>
                    </Box>
                    <Box className={classes.InfoContainer}>
                      <p>Database Name:</p> <p>{response?.db_name}</p>
                    </Box>
                    <Box className={classes.InfoContainer}>
                      <p>Database Username:</p> <p>{response?.db_username}</p>
                    </Box>
                    <Box className={classes.InfoContainer}>
                      <p>Database Password:</p> <p>{response?.db_password}</p>
                    </Box>
                  </Typography>
                </Fragment>
              ))}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              id="button-cancel"
              onClick={closeModal}
              color="primary"
              autoFocus
            >
              Close
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default NewConnectorDetails;
