/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import SideBarHandler from "./SideBarHandler";
import React, { useState, useEffect } from "react";
import "./styles.css";
//import { Tooltip } from "@material-ui/core";
// icons
import dashboard from "../../assets/icons/dashboard.svg";
import file from "../../assets/icons/file.svg";
import audit from "../../assets/icons/audit.svg";
// import target from "../../assets/icons/target.svg";
import connection from "../../assets/icons/connection.svg";
// import email from "../../assets/icons/file.svg";
import envelope from "../../assets/icons/envelope.svg";
import tmail_v3_dashboard_icon from "../../components/Tmail-V3/assets/dashboard.svg";
import tmail_v3_application_icon from "../../components/Tmail-V3/assets/application.svg";
import tmail_v3_domain_icon from "../../components/Tmail-V3/assets/globe.svg";
import tmail_v3_members_icon from "../../components/Tmail-V3/assets/users.svg";
import dashboardA from "../../assets/icons/dashboardA.svg";
import fileA from "../../assets/icons/fileA.svg";
import listA from "../../assets/icons/listA.svg";
import databaseA from "../../assets/icons/databaseA.svg";
import mailA from "../../assets/icons/mailA.svg";
import cloneIcon from "../../assets/icons/cloneIcon.svg";
import cloneIconA from "../../assets/icons/cloneIconA.svg";

import {
  TMAIL_DASHBOARDV2,
  TMAIL_DASHBOARD_APPSV2,
  DOMAINSV2,
  MEMBERSV2,
  CONNECTOR_BACKUP,
  ORPHAN_EMAIL,
  // MAPPER,
  CONNECTOR,
} from "../../containers/pagePath";
import PdcModal from "components/PodioDataCloneModal/PdcModal";
// import { TMAIL_V3_APPLICATION, TMAIL_V3_DASHBOARD, TMAIL_V3_DOMAIN, TMAIL_V3_MEMBERS } from "components/Tmail-V3/routes";

const isTeamMember = !!localStorage.getItem("team_member_uuid");

const MainNavigation = ({ user }) => {
  const [showLink, setShowLink] = useState(null);
  const userIds = [4992, 1129, 5386];
  const user_id = user.database.user_id;

  useEffect(() => {
    if (userIds.includes(user_id) || user.type === true) {
      setShowLink({
        id: "mail",
        icon: envelope,
        iconA: mailA,
        name: "Mail",
        disabled: isTeamMember,
        submenu: [
          {
            id: "dashboard",
            // icon: tmail_v3_dashboard_icon,
            name: "Dashboard",
            link: TMAIL_DASHBOARDV2,
            disabled: isTeamMember,
          },
          {
            id: "applications",
            // icon: tmail_v3_application_icon,
            name: "Applications",
            link: TMAIL_DASHBOARD_APPSV2,
            disabled: isTeamMember,
          },
          {
            id: "domains",
            // icon: tmail_v3_domain_icon,
            name: "Domains",
            link: DOMAINSV2,
            disabled: isTeamMember,
          },
          {
            id: "members",
            // icon: tmail_v3_members_icon,
            name: "Members",
            link: MEMBERSV2,
            disabled: isTeamMember,
          },
          // {
          //   id: "orphan",
          //   icon: eye,
          //   name: "Orphan Emails",
          //   link: ORPHAN_EMAIL,
          //   disabled: isTeamMember
          // }
        ],
      });
    }
  }, []);

  // let showLink = null

  const sidebarRoutes = [
    {
      id: "dashboard",
      icon: dashboard,
      iconA: dashboardA,
      name: "Dashboard",
      link: "/dashboard/sync",
      disabled: false,
    },
    {
      id: "haven",
      icon: file,
      iconA: fileA,
      name: "File Haven",
      link: "/dashboard/haven",
      disabled: isTeamMember,
    },
    {
      icon: audit,
      iconA: listA,
      name: "Audit",
      link: "/audit",
      disabled: false,
    },
    {
      icon: cloneIcon,
      iconA: cloneIconA,
      name: "Clone Podio Data",
      disabled: false,
    },

    {
      id: "mysql",
      icon: connection,
      iconA: databaseA,
      name: "Database Backup",
      disabled: isTeamMember,
      submenu: [
        {
          id: "connector",
          name: "Connectors",
          link: CONNECTOR,
          disabled: isTeamMember,
        },
        {
          id: "backup",
          name: "SQL Backup Progress",
          link: CONNECTOR_BACKUP,
          disabled: isTeamMember,
        },
      ],
    },

    showLink || showLink,
  ];

  return <SideBarHandler pages={sidebarRoutes} />;
};

export default MainNavigation;
