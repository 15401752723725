import React, { useEffect, useState, useRef, Fragment } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useParams, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { format, compareAsc } from "date-fns";
import { AgGridReact } from "ag-grid-react";
// import * as AllModules from "ag-grid-enterprise";
// import { ModuleRegistry } from "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import "./Grid.css";
import GridActions from "./jsxActions/GridActions";
import CheckboxRenderer from "./jsxActions/CheckboxRenderer";
import Switch from "./Switch";
import Endpoint from "services/Endpoint";
import { editItem } from "../../services/sync";
import DatePicker from "./jsxActions/DatePicker";
import CustomButton from "../../components/atoms/CustomButton";
import Swal from "sweetalert2";
import { removeFromSync, restoreToPodio } from "../../redux/actions/test";
import Link from "@material-ui/core/Link";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Tooltip from "@material-ui/core/Tooltip";
import {
  getSpace,
  getTableViews,
  addTableView,
  deleteTableView,
} from "../../services/sync";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Button from "../../components/atoms/Button";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { toast } from "helpers/apiRequests";
import BuildItemModal from "./BuildItemModal";
import RenderByPermission from "components/RenderByPermission";

const FormatData = require("../../services/FormatData");

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    fontFamily: "Mulish",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "30px",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
// ModuleRegistry.registerModules(Object.keys(AllModules));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles((theme) => ({
  link: {
    "&:hover": {
      color: "#fcaf3b",
      textDecoration: "none",
    },
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "30px",
    textDecoration: "none",
  },
  formControl: {
    // minWidth: 320,
  },
  select: {
    marginTop: "0.5rem",
  },
  button: {
    textAlign: "center",
    background: "#FFF3C4",
    borderRadius: "3px",
    color: "#DE911D",
    marginLeft: "15px",
    marginBottom: "10px",
  },
}));

const Items = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();
  const { match, org } = props;
  const [columnDef, setColumnDef] = useState(null);
  const [_columnDef, _setColumnDef] = useState(null);
  const [catField, setCatField] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [checked, setChecked] = useState(true);
  const [appName, setAppName] = useState("");
  const [appDetails, setAppDetails] = useState({});
  const [workspace, setWorkspace] = useState({});
  const [loading, setLoading] = useState(false);
  const [_rowGroup, setRowGroup] = useState(true);
  const [_refreshTable, setRefreshTable] = useState(false);
  const [ownerNamesArray, setownerNamesArray] = useState([]);
  const gridApi = useRef();
  const dispatch = useDispatch();
  const itemsSelected = useSelector((state) => state.liveData.selectedItems);
  const isDeleteSucceful = useSelector((state) => state.liveData.deleteSuccess);
  const user = useSelector((state) => state.user.authUser);
  const [isDeletePage, setisDeletePage] = useState(false);
  const [copyPublicLink, setCopyPublicLink] = useState(false);
  const context = { Items: () => refreshTable() };
  const [publicLink] = useState(
    `${window.location.origin}/public/form/${user.database.user_id}/${params.org_id}/${match.params.id}`
  );

  const [tableViewName, setTableViewName] = useState("");
  const [addNewView, setAddNewView] = useState(false);
  const [addNewTableViewLoader, setAddNewTableViewLoader] = useState(false);
  const [tableViews, setTableViews] = useState([]);
  const [openBuildItem, setOpenBuildItem] = useState(false);
  const [recordCount, setRecordCount] = useState("");
  // const [showButton, setShowButton] = useState(false);

  const handleClickOpen = () => {
    setOpenBuildItem(true);
  };

  const handleClickClose = () => {
    setOpenBuildItem(false);
  };

  const openNewTableViewDialog = () => {
    setTableViewName("");
    setAddNewView(true);
  };

  const closeNewTableViewDialog = () => {
    setAddNewView(false);
  };

  const refreshTable = () => {
    setRefreshTable(true);
    gridApi.current.refreshCells({ force: true });
  };

  useEffect(() => {
    (async () => {
      if (window.location.pathname.split("/")[2] !== "delete") {
        setisDeletePage(true);
      } else {
        setisDeletePage(false);
      }
      try {
        let query = `${params.org_id}/${params.id}`;
        let response = await getTableViews(query);
        if (response.data) setTableViews(response.data);
      } catch (err) {}
    })();
  }, [_refreshTable, params.org_id, params.id]);

  const defaultColDef = {
    editable: true,
    resizable: true,
    flex: 1,
    minWidth: 200,
    enableValue: true,
    enableRowGroup: user?.permissions?.data_interaction?.row_grouping
      ? _rowGroup
      : false,
    enablePivot: user?.permissions?.data_interaction?.pivot_reporting
      ? true
      : false,
    sortable: true,
    filter: "agTextColumnFilter",
    filterParams: {
      suppressAndOrCondition: true,
    },
    allowedAggFuncs: ["sum", "min", "max", "count", "avg"],
    floatingFilter: false,
    suppressHorizontalScroll: false,
  };

  const autoGroupColumnDef = { minWidth: 200 };
  const paginationPageSize = 100;
  const cacheBlockSize = 100;

  const handleChecked = (event) => {
    setChecked(event.target.checked);
    gridApi.current.refreshCells({ force: true });

    if (!checked) {
      props.history.push(`/dashboard/app/${params.org_id}/${params.id}`);
    } else {
      props.history.push(`/dashboard/delete/${params.org_id}/${params.id}`);
    }
  };

  const onCellValueChanged = (event) => {
    const check_type = event.colDef.field + "type_it";
    let type = event.data[check_type];
    let field_category_id = 0;

    if (type === "category" || typeof type === "undefined") {
      if (typeof event.data["_category_object"] !== "undefined") {
        const get_category = event.data._category_object.find(
          (element) => element.external_id === event.colDef.field
        );

        if (get_category) {
          type = "category";
          const fieldCategory = get_category.config.settings.options;

          field_category_id = fieldCategory.find(
            (element) => element.text === event.newValue
          );
        }
      } else {
        type = "text"; //Temp
      }
    }

    const data = {
      value: event.newValue,
      item_id: event.data._item_id,
      external_id: event.colDef.field,
      type,
      field_category_id: field_category_id !== 0 ? field_category_id.id : "",
    };

    if (event.newValue !== event.oldValue) {
      editItem(data)
        .then(() => {})
        .catch(() => {});
    }
  };

  let pathName = window.location.pathname.split("/");
  const initialCol = [
    {
      headerName: "Select",
      field: "registered",
      cellRenderer: "checkboxRenderer",
      editable: false,
      width: 50,
      hide: isDeletePage,
      suppressToolPanel: isDeletePage,
    },
    {
      headerName: "Action",
      field: "action",
      width: 200,
      enablePivot: false,
      editable: false,
      cellRenderer: "medalCellRenderer",
      columnGroupShow: "closed",
      checkboxSelection: true,
    },
  ];

  const handleSaveTableSettings = async () => {
    setAddNewTableViewLoader(true);
    let data = {
      name: tableViewName,
      org_id: params.org_id,
      app_id: params.id,
      columns: gridColumnApi.getColumnState(),
    };
    let newArr = [...tableViews, data];
    setTableViews((tableViews) => newArr);
    try {
      await addTableView(data);
    } catch (err) {
      console.log("Error", err);
    }
    setAddNewTableViewLoader(false);
  };
  const fetchWorkspace = async (space_id) => {
    let query = `?org_id=${params.org_id}&space_id=${space_id}`;
    let workspaceResponse = await getSpace(query);
    if (workspaceResponse.data) {
      setWorkspace(workspaceResponse.data);
    }
  };
  useEffect(() => {
    (async () => {
      setLoading(true);
      setRefreshTable(false);
      match.url.includes("delete") ? setChecked(false) : setChecked(true);
      let category_fields = [];
      let columnArray = initialCol.slice(); // Clone the initialCol array

      let _pathName = window.location.pathname.split("/");

      if (_pathName[2] === "delete") {
        columnArray.push({
          headerName: "Deleted On",
          field: "deleted_on.date",
          width: 200,
          pivot: user?.permissions?.data_interaction?.pivot_reporting
            ? true
            : false,
          hide: false,
          enableRowGroup: user?.permissions?.data_interaction?.row_grouping
            ? true
            : false,
          onCellValueChanged,
          filter: "agDateColumnFilter",
          floatingFilter: false,
          cellRenderer: (data) => {
            return data.value !== undefined
              ? format(new Date(data.value), "dd-MM-yyyy")
              : null;
          },
          filterParams: {
            suppressAndOrCondition: true,
            comparator: (filterLocalDateAtMidnight, cellValue) => {
              const cellDate = new Date(cellValue);
              const result = compareAsc(cellDate, filterLocalDateAtMidnight);
              return result;
            },
          },
        });
      }

      try {
        let response = await Endpoint.singleApp(
          match.params.id,
          match.params.org_id,
          user.database.database
        );

        const { data } = response.data;

        setAppName(response.data.data[0].config.name);
        setAppDetails(data[0]);
        await fetchWorkspace(parseInt(data[0].space_id));

        try {
          let ownerDetails = await Endpoint.getOwnerNames(
            data[0].space_id,
            match.params.org_id,
            user.database.database
          );
          const ownerNames = [];

          ownerDetails.data.data.map((userObj) => {
            if (userObj.role === "regular") {
              return ownerNames.push(userObj.profile.name);
            }

            return null;
          });
          setownerNamesArray([]);
          ownerNamesArray.push(...ownerNames);
        } catch (err) {
          setownerNamesArray([]);
        }

        response.data.data.map((item) => {
          return item.fields.map((item) => {
            if (item.type === "category") {
              item.label = item.label + " Ⓖ";
              category_fields.push(item);
            }
            if (item.status === "active")
              return columnArray.push(
                FormatData.FormatHeader(
                  item,
                  onCellValueChanged,
                  ownerNamesArray,
                  user
                )
              );
            return "";
          });
        });

        if (!response.data.data.some((item) => item.created_on)) {
          columnArray.push({
            headerName: "Created On",
            field: "created_on",
            width: 200,
            sortable: true,
            filter: true,
          });
        }

        if (!response.data.data.some((item) => item.item_id)) {
          columnArray.push({
            headerName: "Item ID",
            field: "item_id",
            width: 200,
            sortable: true,
            filter: true,
          });
        }

        if (!response.data.data.some((item) => item.last_event_on)) {
          columnArray.push({
            headerName: "Last Event On",
            field: "last_event_on",
            width: 200,
            sortable: true,
            filter: true,
          });
        }

        setColumnDef(columnArray);

        if (_pathName[2] === "delete") {
          _setColumnDef(_columnDef);
        }
      } catch (err) {}
      setCatField(category_fields);
      setLoading(false);
    })();

    // eslint-disable-next-line
  }, [match.params.id, match.params.org_id, user, match.url, _refreshTable]);

  const handleLoadTableView = (unique_id) => {
    let view = tableViews.find((view) => view.unique_id === unique_id);

    if (view?.columns !== null) {
      gridColumnApi.setColumnState(view.columns);
    }
    setAddNewView(false);
  };

  const handleDeleteTableView = async (unique_id) => {
    let arr = tableViews.filter((view) => view.unique_id !== unique_id);
    let data = {
      org_id: params.org_id,
      app_id: params.id,
      unique_id,
    };
    setTableViews(arr);
    try {
      await deleteTableView(data);
    } catch (err) {
      console.log("Error", err);
    }
  };

  useEffect(() => {
    if (copyPublicLink) {
      toast.notify("Link copied to clipboard", { type: "info" });
      setTimeout(setCopyPublicLink(!copyPublicLink), 10000);
    }
  }, [copyPublicLink]);

  const frameworks = {
    medalCellRenderer: GridActions,
    datePickerRender: DatePicker,
    checkboxRenderer: CheckboxRenderer,
  };

  //  You can learn more on server side rendering on this link

  const ServerSideDatasource = () => {
    return {
      getRows: async function (params) {
        let data = null;
        let filterModel = null;
        let sort = null;

        let query = "";

        if (params.request.rowGroupCols.length > 0) {
          const group_field = params.request.rowGroupCols[0].field;
          query = `${query}&group_field=${group_field}`;
        }

        if (
          Array.isArray(params.request.rowGroupCols) &&
          params.request.rowGroupCols.length > 0
        ) {
          const group_field = params.request.rowGroupCols[0].field;
          const columns =
            params.parentNode.beans.serverSideRowModel.columnModel.columnDefs;

          const column = columns.find(
            (element) => element.field === group_field
          );
          data = {
            group_field,
            ...(column && { type: column?.sync_type }),
          };
        }

        if (params.request.groupKeys.length > 0) {
          const group_value = params.request.groupKeys[0];
          const group_field = params.request.rowGroupCols[0].field;

          query = `${query}&group_field=${group_field}&group_value=${group_value.replace(
            "&",
            "andReplaced"
          )}`;
        }

        if (isDeletePage) {
          const tempcolumnDefs = columnDef;

          if (tempcolumnDefs) {
            tempcolumnDefs[0] = {
              ...tempcolumnDefs[0],
              hide: true,
              suppressToolPanel: true,
            };

            gridApi.current.setColumnDefs(tempcolumnDefs);
            gridApi.current.refreshCells({ force: true });
          }
        } else {
          const tempcolumnDefs = columnDef;

          if (tempcolumnDefs) {
            tempcolumnDefs[0] = {
              ...tempcolumnDefs[0],
              hide: false,
              suppressToolPanel: false,
            };

            gridApi.current.setColumnDefs(tempcolumnDefs);
            gridApi.current.refreshCells({ force: true });
          }
        }

        if (
          Array.isArray(params.request.groupKeys) &&
          params.request.groupKeys.length
        ) {
          const theType = params.parentNode.field + "type_it";

          data = {
            group_value: params.request.groupKeys[0],
            group_field: params.parentNode.field,
            type: params.parentNode.data[theType],
          };
        }

        if (Object.entries(params.request.filterModel).length > 0) {
          let search_key = Object.keys(params.request.filterModel).length;

          if (search_key === 1) {
            search_key = Object.keys(params.request.filterModel)[0];
          } else {
            search_key = Object.keys(params.request.filterModel)[
              Object.keys(params.request.filterModel).length - 1
            ];
          }

          const columnDefs = params?.parentNode?.columnController?.columnDefs;
          const def = columnDefs?.find(
            (element) => element.field === search_key
          );
          let field_type = def?.sync_type;
          const filterData = params.request.filterModel[search_key];

          if (field_type === "date") {
            const dateTo = filterData.dateTo;

            query = filterData.dateFrom + "&dateTo=" + dateTo;
          } else {
            query = filterData.filter;
          }
          filterModel = `&search_type=${field_type}&search_word=${query}&search_key=${search_key}&filter=${filterData.type}`;
        }

        let theRowCol = [];

        if (
          Array.isArray(params.request.rowGroupCols) &&
          params.request.rowGroupCols.length > 0
        ) {
          setRowGroup(false);
          /*---------------------- */
          // handles the hiding and showing of the actions
          const tempcolumnDefs = columnDef;

          if (tempcolumnDefs) {
            tempcolumnDefs[1] = {
              ...tempcolumnDefs[1],
              hide: true,
              suppressToolPanel: true,
            };

            gridApi.current.setColumnDefs(tempcolumnDefs);
            gridApi.current.refreshCells({ force: true });
            /*---------------------- */
            params.request.rowGroupCols.forEach((rowCol) => {
              theRowCol.push({
                field: rowCol.field,
                displayName: rowCol.displayName,
                type: rowCol.type,
              });
            });
          }
        } else {
          if (user) {
            setRowGroup(true);
          } else if (
            user?.permissions === null &&
            user.new_billing === false &&
            user.free_trial === false
          ) {
            setRowGroup(false);
            toast.notify(
              "Your current plan doesn't have access to row grouping, consider upgrading to an higher plan",
              { type: "error" }
            );
          }
          const tempcolumnDefs = columnDef;

          // handles the hiding and showing of the actions
          if (tempcolumnDefs) {
            tempcolumnDefs[1] = {
              ...tempcolumnDefs[1],
              hide: false,
              suppressToolPanel: false,
            };

            gridApi.current.setColumnDefs(tempcolumnDefs);
            gridApi.current.refreshCells({ force: true });
          }
        }

        if (params.request.sortModel.length > 0) {
          // sort = `&sort=${params.request.sortModel[0].sort}&colId=${params.request.sortModel[0].colId}`;
        }

        pathName = window.location.pathname.split("/");
        let showDelete = false;

        if (pathName[2] === "delete") {
          showDelete = true;
        }

        try {
          let response = await Endpoint.itemsUnderApps(
            showDelete,
            pathName[4],
            pathName[3],
            user.database.database,
            params.request.startRow,
            paginationPageSize,
            data,
            filterModel,
            sort
          );
          const mainData = response.data.data;
          const total_size = response.data.total_count;
          setRecordCount(total_size);

          const RowArray = [];
          let compiled;

          if (Array.isArray(mainData)) {
            mainData
              .map((item, i) => {
                const y = [];
                if (
                  item["deleted_from_podio"] &&
                  item["deleted_from_podio"] === "deleted/restored"
                ) {
                  return null;
                }
                if (showDelete) {
                  y["deleted_on"] = item.deleted_on;
                }

                if (Array.isArray(mainData[i].fields)) {
                  for (let b = 0; b < mainData[i].fields.length; b++) {
                    const formatedValue = FormatData.FormatData(item.fields[b]);

                    y[item.fields[b].external_id] =
                      typeof formatedValue === "string"
                        ? formatedValue.replace(/(<([^>]+)>)/gi, "")
                        : formatedValue;
                    y["_item_id"] = item.item_id;
                    y["_org_id"] = match.params.org_id;
                    y["revision_data"] = item.revision_data;
                    y["check_deletion"] = "0000";

                    if (
                      typeof item["deleted_from_podio"] === "undefined" ||
                      item["deleted_from_podio"] !== true
                    ) {
                      y["check_deletion"] = false;
                    } else {
                      y["check_deletion"] = item["deleted_from_podio"];
                    }

                    if (item.fields[b].type === "app") {
                      y["_app_id"] = item.fields[b].values[0].value.app.app_id;
                    }

                    const the_type = item.fields[b].external_id + "type_it";

                    y[the_type] = item.fields[b].type;
                    y["_field_index_"] = b;
                    y["_field_id_"] = item.fields[b].field_id;
                    y["item_link"] = item.link;
                    if (item.fields[b].type === "category")
                      y["_category_object"] = catField;
                  }

                  // Add the "created_on" field
                  y["created_on"] = item.created_on;
                  y["item_id"] = item.item_id;
                  y["last_event_on"] = item.last_event_on;
                  return RowArray.push(y);
                } else {
                  return RowArray.push(mainData[i].fields);
                }
              })
              .filter(Boolean);
            setTimeout(function () {
              let endResults = [];

              if (theRowCol.length > 0 && data === 0) {
                theRowCol.forEach((x) => {
                  RowArray.map((item) => {
                    const mainKey = x.field;
                    const val = item[mainKey];
                    const check = endResults.some(function (o) {
                      return o[mainKey] === val;
                    });

                    if (!check) {
                      endResults.push(item);
                    }

                    return null;
                  });
                });
                compiled = endResults;
              } else {
                compiled = RowArray;
              }

              //Sort
              if (params.request.sortModel.length > 0) {
                const columnId = params.request.sortModel[0].colId;
                const sortType = params.request.sortModel[0].sort;
                //TODO change sort from this to backend sorting.
                compiled.sort(function (a, b) {
                  if (
                    typeof a[columnId] !== "undefined" &&
                    typeof b[columnId] !== "undefined"
                  ) {
                    //Check if its a date
                    if (
                      FormatData.isDate(a[columnId]) &&
                      FormatData.isDate(b[columnId])
                    ) {
                      if (sortType === "asc") {
                        return new Date(a[columnId]) - new Date(b[columnId]);
                      } else if (sortType === "desc") {
                        return new Date(b[columnId]) - new Date(a[columnId]);
                      }
                    }

                    const A = a[columnId].toUpperCase(); // ignore upper and lowercase
                    const B = b[columnId].toUpperCase(); // ignore upper and lowercase

                    if (sortType === "asc") {
                      if (A < B) {
                        return -1;
                      }

                      if (A > B) {
                        return 1;
                      }
                    } else if (sortType === "desc") {
                      if (A < B) {
                        return 1;
                      }

                      if (A > B) {
                        return -1;
                      }
                    }
                  }

                  // values must be equal
                  return 0;
                });
              }
              // the first returns the data, the second ensures that you don't have extra columns in the grid

              params.successCallback(compiled, total_size);
            }, 200);
          } else if (mainData.options) {
            setTimeout(function () {
              let endResults = [];
              mainData.options.map((item) => {
                const the_type = mainData.external_id + "type_it";
                const type = mainData.external_id;

                endResults.push({
                  [the_type]: mainData.type,
                  _org_id: mainData.org_id,
                  _field_id_: mainData?.field_id,
                  [type]: item.text,
                });
                return null;
              });
              compiled = endResults;

              params.successCallback(compiled, total_size);
            }, 200);
          } else {
          }
        } catch (err) {
          params.failCallback();
        }
      },
    };
  };

  // const onSelectionChanged = (params) => {
  //   const selectedRows = params.api.getSelectedRows();
  //   const hasSelection = selectedRows.length > 0;
  //   setShowButton(hasSelection);
  //   console.log(selectedRows);
  //   let seletctedItemId = null;
  //   console.log(seletctedItemId);

  //   if (selectedRows._item_id) {
  //     seletctedItemId = selectedRows._item_id;
  //   }
  // };

  // const restoreItem = async (org, item_id) => {
  //   // setUpdatingItemLoader(true);

  //   try {
  //     let items = [];
  //     items.push(item_id);
  //     const data = {
  //       item_ids: JSON.stringify(items),
  //       org_id: org,
  //     };

  //     await Endpoint.restoreItem(data);
  //     setSnack({
  //       message: "Restored successfully",
  //       snackType: "success",
  //       isSnack: true,
  //       reload: true,
  //     });
  //   } catch (err) {
  //     setSnack({
  //       message: "Failed to restore, try again later!",
  //       snackType: "error",
  //       isSnack: true,
  //       reload: false,
  //     });
  //   }
  //   setUpdatingItemLoader(false);
  //   syncModalData({
  //     message: null,
  //     modal_type: null,
  //     modal_value: false,
  //     modal_data: {},
  //   });
  // };

  const deleteSelectedItems = () => {
    Swal.fire({
      title: "Delete Items",
      text: "Do you want to Delete selected items",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Delete Items",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.value) {
        dispatch(
          removeFromSync({
            org_id: match.params.org_id,
            item_ids: JSON.stringify(itemsSelected),
          })
        );
        toast.notify("Deleting Items....");
      }
    });
  };

  const restoreItems = () => {
    Swal.fire({
      title: "Restore Items",
      text: "Do you want to Restore selected items",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Restore Item",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.value) {
        dispatch(
          restoreToPodio({
            org_id: match.params.org_id,
            item_ids: JSON.stringify(itemsSelected),
          })
        );
        toast.notify("Restoring Items....");
      }
    });
  };

  useEffect(() => {
    if (isDeleteSucceful) {
    }
  }, [isDeleteSucceful, _refreshTable]);

  const onGridReady = (params) => {
    const theData = ServerSideDatasource();

    //https://stackoverflow.com/questions/60115939/how-to-access-ag-grid-api-in-react-function-component-usestate-hook

    gridApi.current = params.api;

    // <== to access the Actions on Grid
    params.api.setColumnDefs(columnDef);
    params.api.setServerSideDatasource(theData);
    setGridColumnApi(params.columnApi);
  };

  const rawDataUrl = `${process.env.REACT_APP_BASEURL}/sync/podio/raw/${
    match.params.org_id && user.database.database
  }/podio_spaces_${match.params.org_id}?email=${user.email}&secret_key=${
    user.raw_data_hash
  }`;

  if (loading) {
    return (
      <div className="material_block">
        <svg
          className="spinner"
          width="65px"
          height="65px"
          viewBox="0 0 66 66"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            className="circle"
            fill="#fff"
            strokeWidth="6"
            strokeLinecap="round"
            cx="33"
            cy="33"
            r="30"
          ></circle>
        </svg>
      </div>
    );
  }

  return (
    <div className="file__haven">
      <div style={{ margin: "0 1%" }} className="row mb-2">
        <div className="col-md-12 text-right">
          <BuildItemModal
            openModal={openBuildItem}
            handleClose={handleClickClose}
            orgId={match.params.org_id}
            appId={params.id}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Breadcrumbs
                className="float-left mt-30 mr-10 d-flex"
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                <Tooltip title="Current organization">
                  <Link
                    color="inherit"
                    href="#"
                    underline="none"
                    className={classes.link}
                    onClick={(e) =>
                      history.push(`/dashboard/workspaces/${params.org_id}`)
                    }
                  >
                    {org && org?.name ? org.name : ""}
                  </Link>
                </Tooltip>
                <Tooltip title="View workspace's apps">
                  <Link
                    color="inherit"
                    href="#"
                    underline="none"
                    className={classes.link}
                    onClick={(e) =>
                      history.push(
                        `/dashboard/organisation/${params.org_id}/workspaces/${appDetails.space_id}/apps`
                      )
                    }
                  >
                    {workspace && workspace?.name ? workspace.name : ""}
                  </Link>
                </Tooltip>
                <Tooltip title="Current app">
                  <Link
                    color="inherit"
                    href="#"
                    underline="none"
                    className={classes.link}
                  >
                    {appName ? (
                      <span className="">{appName} - Live Data</span>
                    ) : (
                      ""
                    )}
                  </Link>
                </Tooltip>
              </Breadcrumbs>
              <div className="mt-3">
                <h5 style={{ fontSize: "16px" }}>
                  Total Record Count : {recordCount}
                </h5>
              </div>
            </div>

            <RenderByPermission>
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  id="demo-simple-select-outlined"
                  displayEmpty
                  value=""
                  className={classes.select}
                >
                  <MenuItem value="">Select Option</MenuItem>

                  <MenuItem
                    value="addAnItem"
                    onClick={() => {
                      history.push(
                        `/dashboard/createitem/app/${match.params.id}/${match.params.org_id}`
                      );
                    }}
                  >
                    {"Add an Item"}
                  </MenuItem>
                  <MenuItem
                    value="getRawData"
                    onClick={() => window.open(rawDataUrl, "_blank")}
                  >
                    {"Get Raw Data"}
                  </MenuItem>
                  <MenuItem
                    value="copyToClipboard"
                    onClick={() => {
                      navigator.clipboard.writeText(publicLink);
                      setCopyPublicLink(true);
                    }}
                  >
                    Copy Public Form URL
                  </MenuItem>
                  <MenuItem
                    value="openNewView"
                    onClick={() => {
                      user?.type ||
                      user?.permissions?.data_interaction?.saved_views
                        ? openNewTableViewDialog()
                        : history.push(
                            `/dashboard/account?section=plans&message=unsupported-plan`
                          );
                    }}
                  >
                    Load/Save Table Current View
                  </MenuItem>
                  <MenuItem value="openNewView" onClick={handleClickOpen}>
                    Build Item References
                  </MenuItem>
                  <RenderByPermission>
                    <MenuItem
                      value="editAppBuilder"
                      disabled
                      title="Module is Disabled"
                      onClick={() => {}}
                    >
                      {"Edit App Builder"}
                    </MenuItem>
                  </RenderByPermission>
                </Select>
              </FormControl>
            </RenderByPermission>
          </div>
          <br />
        </div>

        <div
          style={{
            position: "relative",
            marginBottom: "-10%",
            marginleft: "10px",
            clear: "both",
            width: "fit-content",
          }}
        >
          <Switch handleChecked={handleChecked} checked={checked} />
          <br />
        </div>

        <br />
      </div>
      {!isDeletePage && (
        <div className="row mb-2" style={{ marginLeft: "0px" }}>
          {itemsSelected.length !== 0 && (
            <React.Fragment>
              <CustomButton
                tooltip="true"
                tooltip_title="Restore Selected"
                tooltip_placement="bottom"
                variant="contained"
                color="primary"
                style={{ backgroundColor: "green" }}
                className="float-right "
                onClick={restoreItems}
                disabled={itemsSelected.length === 0}
              >
                Restore Selected
              </CustomButton>
              <RenderByPermission allowTeamMemberAccess={false}>
                <CustomButton
                  tooltip="true"
                  tooltip_title="Delete Selected"
                  tooltip_placement="bottom"
                  variant="contained"
                  color="primary"
                  style={{ backgroundColor: "firebrick" }}
                  className="float-right ml-4"
                  onClick={deleteSelectedItems}
                >
                  Delete Selected
                </CustomButton>
              </RenderByPermission>
            </React.Fragment>
          )}
        </div>
      )}

      {/* {showButton && (
        <div className="d-flex justify-content-end mt-2">
          <button
            style={{
              padding: "8px 15px",
              borderRadius: "4px",
              color: "white",
              backgroundColor: "#F0B429",
              border: "none",
              margin: "0 2%",
              height: "45px",
            }}
          >
            Restore selected items
          </button>
        </div>
      )} */}

      <div
        className="ag-theme-balham-dark"
        style={{
          height: "1000px",
          width: "100%",
          padding: "20px",
        }}
      >
        <AgGridReact
          // modules={Object.keys(AllModules)}
          columnDefs={columnDef}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          paginationPageSize={paginationPageSize}
          cacheBlockSize={cacheBlockSize}
          onGridReady={onGridReady}
          // autoGroupColumnDef={autoGroupColumnDef}
          // columnDefs={isDeletePage ? columnDef : _columnDef}
          // onGridReady={onGridReady}
          frameworkComponents={frameworks}
          animateRows={true}
          debug={true}
          suppressPropertyNamesCheck={true}
          // cacheBlockSize={cacheBlockSize}
          enableCellTextSelection={true}
          groupMultiAutoColumn={true}
          enableRangeSelection={true}
          sideBar={true}
          suppressAggFuncInHeader={true}
          enableFilter={true}
          enableSorting={true}
          // defaultColDef={defaultColDef}
          pagination={true}
          // paginationPageSize={paginationPageSize}
          rowModelType={"serverSide"}
          rowGroupPanelShow="always"
          rowSelection="multiple"
          enableCharts={true}
          groupUseEntireRow={true}
          serverSideStoreType="partial"
          overlayLoadingTemplate={
            '<span className="ag-overlay-loading-center">Please wait while your rows are loading</span>'
          }
          overlayNoRowsTemplate={
            "<span style=\"padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;\">This is a custom 'no rows' overlay</span>"
          }
          context={context}
          // onSelectionChanged={onSelectionChanged}
        />
      </div>

      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={addNewView}
        onClose={closeNewTableViewDialog}
        aria-labelledby="form-dialog-title"
        className="dialog-box"
      >
        <DialogContent>
          <div className="head">
            <h3>Table View Settings</h3>
            <DialogTitle
              id="form-dialog-title"
              onClose={closeNewTableViewDialog}
            ></DialogTitle>
          </div>
          <div>Create View</div>
          <Divider className="divider" />
          <Fragment>
            <Grid container style={{ paddingTop: "5px" }}>
              <Grid item xs={4}>
                <label
                  htmlFor="table_view_name"
                  className={`${classes.required} label`}
                >
                  Table View Name
                </label>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  // disabled={updateAppSettingsLoading}
                  variant="outlined"
                  aria-describedby="Table View Name"
                  value={tableViewName}
                  onChange={(e) => setTableViewName(e.target.value)}
                  margin="normal"
                  id="table_view_name"
                  type="text"
                  size="small"
                  fullWidth
                />
              </Grid>
            </Grid>
            <div className="actions">
              <Button
                tooltip={true}
                tooltip_title="Close Table View Settings Modal"
                tooltip_placement="bottom"
                function={closeNewTableViewDialog}
                style={{ background: `#fff`, color: `#626262` }}
                text="Cancel"
              />
              <Button
                tooltip={true}
                tooltip_title="Update Table View  Settings"
                tooltip_placement="bottom"
                function={handleSaveTableSettings}
                disabled={addNewTableViewLoader}
                style={
                  addNewTableViewLoader
                    ? { background: `grey`, color: `#000` }
                    : { background: `#F7C948`, color: `#fff` }
                }
                text={addNewTableViewLoader ? "Adding new view..." : "Save"}
              />
              {addNewTableViewLoader && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
            {tableViews.length > 0 ? (
              <Fragment>
                <Divider />
                <br />
                <h6>Load View</h6>
                <br />
                <Grid container style={{ padding: "5px" }}>
                  {tableViews.map((view, index) => {
                    return (
                      <Fragment key={index}>
                        <Grid item xs={4}>
                          <label
                            htmlFor="table_view_name"
                            className={`${classes.required} label`}
                          >
                            {view.name}
                          </label>
                        </Grid>
                        <Grid item xs={8}>
                          <Button
                            tooltip="true"
                            tooltip_title="Load View"
                            tooltip_placement="bottom"
                            size="small"
                            function={() => handleLoadTableView(view.unique_id)}
                            style={{ background: `#F7C948`, color: `#fff` }}
                            text={"Load View"}
                          />
                          <RenderByPermission allowTeamMemberAccess={false}>
                            <Button
                              tooltip="true"
                              tooltip_title="Delete View"
                              tooltip_placement="bottom"
                              size="small"
                              function={() =>
                                handleDeleteTableView(view.unique_id)
                              }
                              style={{
                                background: `#F7C948`,
                                color: `#fff`,
                                marginLeft: `15px`,
                              }}
                              text={"Delete View"}
                            />
                          </RenderByPermission>
                        </Grid>
                      </Fragment>
                    );
                  })}
                </Grid>
              </Fragment>
            ) : (
              ""
            )}
          </Fragment>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  user: state.user.authUser,
  org: state?.sync?.orgs
    ? state.sync.orgs.find(
        (x) => x.org_id === parseInt(ownProps.match.params.org_id)
      )
    : {},
});

export default connect(mapStateToProps, null)(Items);
