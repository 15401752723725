/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "@emotion/styled";
import { useHistory } from "react-router-dom";
import { css } from "@emotion/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Tooltip from "@material-ui/core/Tooltip";
import ConfirmConnector from "../Tmail/components/Modals/ConfirmModal";

import {
  TMAIL_DASHBOARDV2,
  TMAIL_DASHBOARD_APPSV2,
  DOMAINSV2,
  MEMBERSV2,
  ORPHAN_EMAIL,
} from "../../containers/pagePath";
import {
  TMAIL_V3_APPLICATION,
  TMAIL_V3_DASHBOARD,
  TMAIL_V3_DOMAIN,
  TMAIL_V3_MEMBERS,
} from "components/Tmail-V3/routes";
import PdcModal from "components/PodioDataCloneModal/PdcModal";

const MenuItem = (props) => {
  const {
    page,
    isSubMenu = false,
    onClick,
    link,
    isActive = false,
    showArrow,
    subMenu,
  } = props;

  const history = useHistory();
  const { name, icon, iconA } = page;
  const [arrowDown, setArrowDown] = useState(true);

  const menuItemClickHandler = () => {
    setArrowDown(!arrowDown);
    if (page?.disabled) return;
    if (link && showArrow) {
      history.push(link);
    }
  };
  const [active, setActive] = useState(false);

  return (
    <MenuItemWrapper
      isActive={isActive}
      isSubMenu={isSubMenu}
      disabled={page.disabled}
      onClick={() => onClick(page)}
      active={active}
    >
      {icon ? (
        <img
          src={isActive ? iconA : icon}
          alt={name}
          className="img-fluid"
          style={{ maxWidth: "40px", marginLeft: "-10px" }}
        />
      ) : null}

      <div
        className={
          subMenu
            ? "d-flex sub_menu w-100 inner-item "
            : "d-flex justify-content-between w-100"
        }
        onClick={() => {
          setActive(!active);
          menuItemClickHandler();
        }}
      >
        <Tooltip title={name}>
          <span className="name-holder">{name}</span>
        </Tooltip>
        {showArrow && (
          <span className="arrow-down">
            {arrowDown ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          </span>
        )}
      </div>
    </MenuItemWrapper>
  );
};

const MenuItemWrapper = styled.span`
  font-size: 12px;
  display: flex;
  align-items: center;
  color: white;
  margin: 4% 0;
  border-radius: 0 24px 24px 0;
  padding: 4px 12px 4px 2.4rem;
  transition: 0.25s ease-in-out all;

  .name-holder {
    margin-left: 2rem;
    width: 70%;
    max-width: 123px;
    text-overflow: ellipsis !important;
    overflow: hidden;
    margin-top: 3px;
  }

  &:hover {
    cursor: pointer;
    transition: 0.25s ease-in-out all;
  }

  .arrow-down {
    transform: rotate(0deg);
    transition: 0.25s ease-in-out all;
  }

  ${(props) =>
    props?.disabled &&
    css`
      display: none;
    `}

  ${(props) =>
    props.isSubMenu &&
    css`
      ${"" /* background: #3b3b3b; */}
      margin-left: -13%;
      margin-top: -5px;
      margin-bottom: 10px;
    `}

  ${(props) =>
    props.isActive &&
    css`
      color: #f7c948;
      cursor: pointer;

      img {
        width: unset;
        height: unset;
      }
    `}
`;

const SubMenu = (props) => {
  const [visible, setVisible] = useState(false);
  const { icon, name, pages, link, onSubMenuClick } = props;

  const itemPage = {
    name,
    icon,
    link: "",
  };

  const currentLocation = window.location.pathname;

  return (
    <React.Fragment>
      {!props?.disabled ? (
        <MenuItem
          link={link}
          page={itemPage}
          showArrow
          onClick={() => setVisible(!visible)}
        />
      ) : null}

      <SubMenuWrapper visible={visible}>
        {pages.map((page, index) => {
          const { name, submenu } = page;
          let clickedLink = currentLocation === page.link;
          return submenu === undefined ? (
            <MenuItem
              isActive={clickedLink}
              page={page}
              link={link}
              key={index}
              isSubMenu={true}
              onClick={onSubMenuClick}
            />
          ) : (
            <SubMenu
              onSubMenuClick={onSubMenuClick}
              key={index}
              link={link}
              name={name}
              // icon={arrowDownRight}
              pages={submenu}
              isSubMenu={true}
            />
          );
        })}
      </SubMenuWrapper>
    </React.Fragment>
  );
};

const SubMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 0;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  margin-left: 14%;
  padding-left: -5%;

  img {
    width: 10px !important;
    height: auto;
    margin-left: 10px;
  }

  ${(props) =>
    props.visible &&
    css`
      max-height: 5000px;
      transition: max-height 0.25s ease-in;
      background: #121a27;
      .inner-item {
        padding-left: 10px;
      }
    `}
`;

const SideBarHandler = (props) => {
  const user = useSelector((state) => state.user.authUser);
  const { pages } = props;
  const history = useHistory();
  const [currentLocation, setcurrentLocation] = useState(
    window.location.pathname
  );
  const [openModal, setOpenModal] = useState(false);
  const [pageLink, setPageLink] = useState("");

  const closeModal = () => setOpenModal(false);
  const [openPdcModal, setOpenPdcModal] = React.useState(false);

  const menuItemClickHandler = (page) => {
    if (page.name.includes("Clone Podio Data")) {
      setOpenPdcModal(true);
    } else {
      setcurrentLocation(page.link);
      history.push(page.link);
    }
  };

  const handlePdcClose = () => {
    setOpenPdcModal(false);
  };

  const onSubmenuClicked = (page) => {
    if (
      page.link === TMAIL_V3_DASHBOARD ||
      page.link === TMAIL_V3_APPLICATION ||
      page.link === TMAIL_V3_DOMAIN ||
      page.link === TMAIL_V3_MEMBERS ||
      page.link === TMAIL_DASHBOARDV2 ||
      page.link === TMAIL_DASHBOARD_APPSV2 ||
      page.link === DOMAINSV2 ||
      page.link === MEMBERSV2 ||
      page.link === ORPHAN_EMAIL
    ) {
      if (
        user?.acknowledge_mail_billing === true &&
        user?.tmail_connection === true
      ) {
        history.push(page.link);
        return;
      }
      setPageLink(page.link);
      setcurrentLocation(page.link);
      setOpenModal(true);
    } else {
      setcurrentLocation(page.link);
      history.push(page.link);
    }
  };

  return (
    <div>
      {openPdcModal ? (
        <PdcModal open={openPdcModal} handleClose={handlePdcClose} />
      ) : (
        <div
          style={{ display: "flex", flexDirection: "column" }}
          className="items"
        >
          {pages.map((page, index) => {
            if (!page) {
              return null;
            }
            const { name, icon, submenu, link } = page;
            let clickedLink = currentLocation === page.link;
            return submenu === undefined ? (
              <MenuItem
                isActive={clickedLink}
                page={page}
                link={link}
                key={index}
                onClick={menuItemClickHandler}
              />
            ) : (
              <SubMenu
                onSubMenuClick={onSubmenuClicked}
                disabled={page?.disabled}
                key={name}
                link={link}
                name={name}
                icon={icon}
                pages={submenu}
              />
            );
          })}
        </div>
      )}

      <ConfirmConnector
        openModal={openModal}
        handleClose={closeModal}
        page={pageLink}
      />
    </div>
  );
};

export default SideBarHandler;
