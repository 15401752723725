import React from 'react';
import dayjs from "dayjs";
import './style.css';

const ActivityTotal = ({ activity, streams }) => {
    console.log(activity, streams);
    const formatDate = (value) => {
        return dayjs(value).format("MMM DD h:mm A")
    }
    const formatKey = (value) => {
        return value.charAt(0).toLocaleUpperCase() + value.slice(1);
    }
    const formatClientInfo = (value) => {
        const device = formatKey(value['device-type']);
        const os = value['client-os'];
        return `${device}/${os}`;
    }
    const formatLocation = (value) => {
        const city = formatKey(value['city']);
        const country = formatKey(value['country']);
        return `${city}/${country}`;
    }
    return (
        <div className="activity_container">
            <div>
                <p className='text-bold heading'> Activity Total</p>
            </div>
            {
                streams.length === 0 ? <h3>No Activity</h3> :
                    <table>
                        <tbody>
                            <tr class="row1">
                                <th>Activity</th>
                                <th>When</th>
                                <th>Who</th>
                                <th>Where</th>
                                <th>Client/Device</th>
                            </tr>
                            {
                                streams.map((data, index) => (
                                        <tr key={index} className="row1">
                                        <td>{formatKey(data.event)}</td>
                                        <td>{formatDate(new Date(data?.timestamp * 1000))}</td>
                                        <td>{data.recipient}</td>
                                        <td>{data.geolocation ? formatLocation(data.geolocation) : "---"}</td>
                                        <td>{data['client-info'] ? formatClientInfo(data['client-info']) : "---"}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
            }

            <div className='mt-4'>
                <p className='text-bold text-center heading'> Activity Stream</p>
            </div>

            <div className='bottom'>
                {
                    activity.length === 0 ? <h3>No Activity</h3> :
                        <>
                            {
                                activity.map((data, index) => (
                                    <center key={index}>
                                        <tr class="bottom_row">
                                            <td>Replied</td>
                                            <td>{formatDate(data?.timestamp)}</td>
                                            <td>{data.email}</td>
                                        </tr>
                                    </center>
                                ))
                            }
                        </>
                }
            </div>
        </div>
    )
}

export default ActivityTotal