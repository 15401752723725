import React from 'react';
import { Box, Dialog, DialogTitle, DialogActions } from '@material-ui/core';
import { IconButton, FormControlLabel, RadioGroup } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import styles from './ManageSignatures.module.scss';
import { SigneeCard, DraggableSignees } from '../SIgneeCard';
import useAsync from 'components/templates/print-v2/hooks/useAsync';
import { fetchSignerFields } from 'components/templates/print-v2/printUtils/requests';
import Form from './Form';
import ThemedRadio from 'components/templates/print-v2/components/ThemedRadio';
import { usePrintData } from 'components/templates/print-v2/context/PrintProvider';

const alphabets = 'abcdefghijklmnopqrstuvwxyz';
const defaultFormState = {
  name: { name: '', valueObject: { label: '', value: '' } },
  email: { name: '', valueObject: { label: '', value: '' } },
  message: { name: '', value: '' },
};

function populateField(value) {
  const data = {};
  data.field_id = value.valueObject.value;
  data.label = value.valueObject.label;
  return data;
}

const transformSignerFieldOptions = (val) => {
  return (i) => {
    const label = val ? `${val} > ${i.name}` : i.name;
    return { label: label, value: i.id, external_id: i.external_id };
  };
};
const transformSignerAppFieldOptions = (i) => ({ label: i.name, value: i.app_id, external_id: i.external_id, field_id: i.field_id });

const ManageSignatures = (props) => {
  const { open, handleClose, signees, setSignees, templateId, executeSave, saveStatus } = props;
  const { template, setSelectedSigner } = usePrintData();
  const [formValues, setFormValues] = React.useState(defaultFormState);
  const [isSigningSquential, setIsSigningSquential] = React.useState(false);
  const [referencedApps, setReferencedApps] = React.useState([]);

  function handleSigningSequence(event) {
    const { value } = event.target;
    setIsSigningSquential(JSON.parse(value));
  }

  const [selectedApp, setSelectedApp] = React.useState({ label: '', value: '' });

  const templatePodioMetadata = template?.podio_options?.metadata;
  const {
    execute,
    value: signerFieldsOptions,
    status: fieldOptionsStatus,
  } = useAsync(() => {
    const app_id = templatePodioMetadata?.app?.app_id;

    return fetchSignerFields({ app_id, org_id: templatePodioMetadata?.organization?.org_id })
      .then((res) => {
        setReferencedApps(() => res?.data?.app_fields?.map(transformSignerAppFieldOptions));

        return {
          text_fields: res?.data?.text_fields?.map(transformSignerFieldOptions(templatePodioMetadata?.app?.name)) ?? '',
          email_fields: res?.data?.email_fields?.map(transformSignerFieldOptions(templatePodioMetadata?.app?.name)) ?? '',
        };
      })
      .catch(console.error);
  }, false);

  React.useEffect(() => {
    if (templatePodioMetadata?.app?.app_id && templatePodioMetadata?.organization?.org_id) {
      execute();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templatePodioMetadata?.app?.app_id, templatePodioMetadata?.organization?.org_id]);

  function removeSigner(value) {
    setSelectedSigner(null);
    setSignees((prev) => prev.filter((x) => x.uuid !== value.uuid));
  }

  function save() {
    const payload = {
      template_id: templateId,
      signer_sequencing: isSigningSquential,
    };

    const signers = signees.map((i, index) => {
      return {
        app_id: i.app_id,
        item_id: i.item_id,
        field_app_location: i.field_app_location,
        uuid: i.uuid,
        name: populateField(i.name),
        email: populateField(i.email),
        message: i?.message?.value ?? '',
        order: alphabets[index],
      };
    });
    payload.signers = signers;
    executeSave(payload);
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      className={styles.override}
      maxWidth='lg'>
      <Box className={styles.container}>
        <DialogTitle>
          <div className={styles.titleContainer}>
            <h6> Manage Signers</h6>
          </div>
        </DialogTitle>
        <DialogActions style={{ position: 'absolute', right: 0, top: 0 }}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <div className={styles.contentContainer}>
          <div className='d-flex align-items-center'>
            <label className='mr-3'>Signing Order:</label>
            <RadioGroup
              aria-label='isSigningSquential-list-type'
              id='isSigningSquential'
              name='isSigningSquential'
              value={isSigningSquential}
              onChange={handleSigningSequence}
              className='flex-row'>
              <FormControlLabel
                value={false}
                control={<ThemedRadio size='small' />}
                label={<p style={{ fontSize: '13px', marginBottom: 0 }}>Non Sequential</p>}
              />
              <FormControlLabel
                value={true}
                control={<ThemedRadio size='small' />}
                label=<p style={{ fontSize: '13px', marginBottom: 0 }}>Sequential</p>
              />
            </RadioGroup>
          </div>
          <Form
            {...{
              formValues,
              setFormValues,
              setSignees,
              signerFieldsOptions,
              fieldOptionsStatus,
              defaultFormState,
              selectedApp,
              setSelectedApp,
              referencedApps,
            }}
          />

          {isSigningSquential ? (
            <DraggableSignees signees={signees} setSignees={setSignees} />
          ) : (
            signees.map((signer) => <SigneeCard key={signer.uuid} {...{ signer, removeSigner }} />)
          )}

          <div className={styles.buttonContainer}>
            <button className={`btn ${styles.blueButton}`} onClick={save} disabled={saveStatus === 'pending'}>
              {saveStatus === 'pending' ? 'Saving...' : 'Save & Proceed'}
            </button>
          </div>
        </div>
      </Box>
    </Dialog>
  );
};

export default ManageSignatures;
