import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import hookIcon from "../../assets/icons/hookIcon.svg";
import BackButton from "components/atoms/BackButton";

const Summary = ({ loading, dailyHooks, monthlyHooks, totalHooks }) => {

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div>
      <div>
        <BackButton />
      </div>

      <div style={{margin: "2% 0"}}>
        <h5 className="hookName">
          <span style={{ color: "#F0B429" }}>Audit</span> {`>`} Hooks
        </h5>
      </div>
      <div className="audit-tabs">
        <div style={{ borderRadius: "12px 0 0 12px" }} className="item item-first">
          <div className="image">
            <img src={hookIcon} alt="apps" />
          </div>
          <div className="detailsCont">
            <div className="label">Daily Hook</div>
            <div className="value">
              {loading ? <CircularProgress /> : numberWithCommas(dailyHooks)}
            </div>
          </div>
        </div>

        <div className="item item-middile">
          <div className="image">
            <img src={hookIcon} alt="apps" />
          </div>
          <div className="detailsCont">
            <div className="label">Monthly Hooks</div>
            <div className="value">
              {loading ? <CircularProgress /> : numberWithCommas(monthlyHooks)}
            </div>
          </div>
        </div>

        <div className="item item-middile">
          <div className="image">
            <img src={hookIcon} alt="apps" />
          </div>
          <div className="detailsCont">
            <div className="label">Yearly Hooks</div>
            <div className="value">
              {loading ? <CircularProgress /> : numberWithCommas(totalHooks)}
            </div>
          </div>
        </div>

        <div style={{ borderRadius: "0 12px 12px 0" }} className="item item-last">
          <div className="image">
            <img src={hookIcon} alt="apps" />
          </div>
          <div className="detailsCont">
            <div className="label">Total Hooks</div>
            <div className="value">
              {loading ? <CircularProgress /> : numberWithCommas(totalHooks)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
