import React, { useState } from "react";
import Error from "../../../containers/Mysql/components/Error/Error";
import AlertModal from "./Modals/AlertModal";
import CreateModal from "./Modals/CreateModal";
import DeleteEmail from "./Modals/DeleteEmail";
import ViewDefaultEmail from "./Modals/ViewDefaultEmail";
import EditModal from "./Modals/EditModal";

const DefaultEmails = ({ emails, domains, appId }) => {
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [emailId, setEmailId] = useState();
  const [selectedEmail, setSelectedEmail] = useState();
  const [openAlert, setOpenAlert] = useState(false);

  const handleOpen = () => {
    if (domains.length === 0) {
      setOpenAlert(true);
    } else {
      setOpen(true);
    }
  };
  const updatedSeleected = () => setSelectedEmail(null);
  const closeModal = () => {
    setOpenAlert(false);
  };
  const handleOpenDelete = (id) => {
    setEmailId(id);
    setOpenDelete(true);
  };
  const handleOpenView = (item) => {
    setSelectedEmail(item);
    setOpenView(true);
  };
  const handleOpenEdit = (item) => {
    setSelectedEmail(item);
    setOpenEdit(true);
  };
  const handleClose = () => setOpen(false);
  const handleCloseEdit = () => setOpenEdit(false);
  const handleCloseDelete = () => setOpenDelete(false);
  const handleCloseView = () => setOpenView(false);
  const msg = "No domain setup for this account. proceed to setup domain";
  const redirectUrl = "/dashboard/tmail/domains";
  const redirect = "Redirect to domain";
  return (
    <div className="row">
      <div className="col-md-10">
        <AlertModal
          message={msg}
          openModal={openAlert}
          handleClose={closeModal}
          url={redirectUrl}
          redirect={redirect}
        />
        <CreateModal
          openModal={open}
          handleClose={handleClose}
          domains={domains}
          updatedSeleected={updatedSeleected}
          appId={appId}
          item={selectedEmail}
        />
        <EditModal
          openModal={openEdit}
          handleClose={handleCloseEdit}
          domains={domains}
          updatedSeleected={updatedSeleected}
          appId={appId}
          item={selectedEmail}
        />
        <DeleteEmail
          openModal={openDelete}
          handleClose={handleCloseDelete}
          item={emailId}
        />
        <ViewDefaultEmail
          openModal={openView}
          handleClose={handleCloseView}
          item={selectedEmail}
        />
        <div className="table-responsive">
          {emails.length > 0 ? (
            <button
              className="map-data-button mb-2"
              onClick={handleOpen}
              style={{
                color: "#de911d",
              }}
            >
              Add New Email
            </button>
          ) : null}

          {emails.length > 0 ? (
            <div className="table-responsive">
              <table style={{ width: "80%" }} className="table table-bordered">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>User Name</th>
                    <th>Name</th>
                    <th>Email</th>
                    {/* <th>Smtp status</th> */}
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {emails.map((email, i) => {
                    return (
                      <tr key={email.id}>
                        <td>{i + 1}</td>
                        <td>{email.username}</td>
                        <td>{email.name}</td>
                        <td>{email.email}</td>
                        {/* <td>{email.create_with_smtp === 1 ? "Yes" : "No"}</td> */}
                        <td>{email.status}</td>
                        <td>
                          <i
                            className="fas fa-eye text-info"
                            style={{
                              cursor: "pointer",
                              fontSize: "17px",
                            }}
                            onClick={() => handleOpenView(email)}
                          ></i>
                          <i
                            className="fas fa-edit text-primary ml-2"
                            style={{
                              cursor: "pointer",
                              fontSize: "17px",
                            }}
                            onClick={() => handleOpenEdit(email)}
                          ></i>
                          <i
                            className="fas fa-trash-alt text-danger ml-2"
                            style={{
                              cursor: "pointer",
                              fontSize: "17px",
                            }}
                            onClick={() => handleOpenDelete(email.id)}
                          ></i>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <Error
              errorMsg="No Emails Found"
              errorAction="Setup Default Emails"
              onClick={() => {
                handleOpen();
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DefaultEmails;
