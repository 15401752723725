import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TemplateFields from '../fields/TemplateFields';
import DocumentFields from '../fields/DocumentFields';
import SignatureFields from '../fields/SignatureFields/SignatureFields';
import CustomTooltip from 'components/templates/print-v2/components/CustomTooltip';
import { usePrintData } from 'components/templates/print-v2/context/PrintProvider';

function mutateStyleDisplay(element, property, value) {
  if (element) {
    return element.forEach((el) => (el.style[property] = value));
  }
}

function TabLabel({ tooltipInfoLabel = '', tabLabel = '' }) {
  return (
    <CustomTooltip title={tooltipInfoLabel}>
      <span>{tabLabel}</span>
    </CustomTooltip>
  );
}

function RightSidebarTabs(props) {
  const classes = useStyles();
  const { value, setValue } = props;
  const { template } = usePrintData();

  const { documentFields } = usePrintData();

  function handleChange(_, newValue) {
    setValue(newValue);
  }

  const pageNumber = document.querySelectorAll('.page_number');

  React.useEffect(() => {
    if (documentFields.pageNumberAlign === 'right') {
      mutateStyleDisplay(pageNumber, 'textAlign', 'right');
    } else if (documentFields.pageNumberAlign === 'center') {
      mutateStyleDisplay(pageNumber, 'textAlign', 'center');
    } else {
      mutateStyleDisplay(pageNumber, 'textAlign', 'left');
    }
  }, [pageNumber, documentFields.pageNumberAlign]);

  const pageNumberHeader = document.querySelectorAll('.page_number_header');
  const pageNumberFooter = document.querySelectorAll('.page_number_footer');

  React.useEffect(() => {
    if (documentFields.pageNumberPosition === 'header') {
      mutateStyleDisplay(pageNumberHeader, 'display', 'block');
      mutateStyleDisplay(pageNumberFooter, 'display', 'none');
    } else if (documentFields.pageNumberPosition === 'footer') {
      mutateStyleDisplay(pageNumberHeader, 'display', 'none');
      mutateStyleDisplay(pageNumberFooter, 'display', 'block');
    } else if (documentFields.pageNumberPosition === 'header and footer') {
      mutateStyleDisplay(pageNumberHeader, 'display', 'block');
      mutateStyleDisplay(pageNumberFooter, 'display', 'block');
    } else {
      mutateStyleDisplay(pageNumberHeader, 'display', 'none');
      mutateStyleDisplay(pageNumberFooter, 'display', 'none');
    }
  }, [pageNumberFooter, pageNumberHeader, documentFields.pageNumberPosition]);

  return (
    <div className={classes.root}>
      <Tabs value={value} onChange={handleChange} classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}>
        <Tab
          disableRipple
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
          label={<TabLabel tooltipInfoLabel='Template settings' tabLabel='Template' />}
        />
        <Tab
          disableRipple
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
          label={<TabLabel tooltipInfoLabel='Document settings' tabLabel='Document' />}
        />
        <Tab
          disableRipple
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
          label={<TabLabel tooltipInfoLabel='Signature settings' tabLabel='Signature' />}
        />
      </Tabs>
      {value === 0 && <TemplateFields />}
      {value === 1 && <DocumentFields />}
      {value === 2 && (
        <SignatureFields
          signatureData={template?.signature}
          refreshTemplate={props.refreshTemplate}
          fetchTemplateStatus={props.fetchTemplateStatus}
          savingTemplate={props.savingTemplate}
        />
      )}
    </div>
  );
}

export default RightSidebarTabs;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabsRoot: {
    borderBottom: 'none',
    width: '100%',
  },
  tabsIndicator: {
    backgroundColor: '#fff',
  },
  tabRoot: {
    textTransform: 'initial',
    minWidth: 72,
    fontWeight: 400,
    fontSize: '16px',
    backgroundColor: '#E1E1E1',
    width: '33.3%',
    fontFamily: ['Mulish', 'sans-serif'].join(','),
    '&$tabSelected': {
      backgroundColor: '#FFF',
    },
    '&:focus': {
      color: '#000000',
    },
  },
  tabSelected: {},
}));
