import React from "react";
import { getTeamMembers } from "../../redux/actions/user";
import { useDispatch } from "react-redux";
import Axios from "../../config";
import Button from "@material-ui/core/Button";
import RenderByPermission from "components/RenderByPermission";
import DeleteTeamMember from "./DeleteTeamMember/DeleteTeamMember";
import { toast } from "../../helpers/apiRequests";

const TeamsList = (props) => {
  const { setViewSelectedTeamMember, organizations, setSelectedMember } = props;
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const cancelInvitation = () => {
    setLoading(true);
    Axios.post("/account/team-members/delete", { member_id: props.id })
      .then(() => {
        toast.notify("Team Member Deleted Successfully");
        handleClose();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  let role = "";
  if (props.role.role === "Basic Role") {
    role = "Partner";
  } else if ((role = "Team Member Role")) {
    role = "Team Member";
  } else {
    role = props.role.role;
  }

  function handleClose() {
    dispatch(getTeamMembers());
    setDeleteOpen(false);
  }

  const status = props?.status;

  return (
    <div className="item">
      {deleteOpen ? (
        <DeleteTeamMember
          open={deleteOpen}
          handleClose={handleClose}
          templateId={props.id}
          execute={cancelInvitation}
          loading={loading}
        />
      ) : null}

      <div
        className="image"
        style={{
          backgroundImage: `url(${props.image})`,
          backgroundSize: `cover`,
          backgroundPosition: `center`,
        }}
      ></div>

      <div className="text-container">
        <div className="name">
          {props.name} {props.role === "Admin" ? "(You)" : ""}
        </div>
        <div className="email">{props.email}</div>
        <div className="d-flex align-items-center justify-content-center mt-2 mb-2">
          <p
            style={{ marginBottom: "0" }}
            className={`member-status ${status}`}
          >
            {role}
          </p>
          {status ? (
            <span className={`pending-invite ${status}`}>{status}</span>
          ) : null}
        </div>

        <div className="user-actions">
          <Button
            size="small"
            variant="outlined"
            disabled={props.isLoading}
            onClick={() => {
              setViewSelectedTeamMember(true);
              setSelectedMember({
                organizations,
                member_id: props.id,
                email: props.email,
                name: props.name,
                role: props.role,
              });
            }}
          >
            View Orgs
          </Button>
          <RenderByPermission allowTeamMemberAccess={false}>
            <Button
              size="small"
              variant="outlined"
              style={{ borderColor: "red", color: "red" }}
              onClick={() => setDeleteOpen(true)}
            >
              Remove
            </Button>
          </RenderByPermission>
        </div>
      </div>
    </div>
  );
};

export default TeamsList;
