import React from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Typography,
} from "@material-ui/core";
import dayjs from "dayjs";

const OrphanModal = ({ openModal, handleClose, email }) => {

    const formatDate = (value) => {
        return dayjs(value).format("dddd D, MMMM YYYY, h:mm A")
    }

    return (
        <div>
            <Dialog
                open={openModal}
                onClose={handleClose}
                // hideBackdrop={true}
                disableEscapeKeyDown={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <Box style={{ padding: "20px", width: "500px" }}>
                        <Typography id="modal-modal-title" variant="h6" component="p" className="mb-3">
                            Orphan Details
                        </Typography>

                        <form>
                            <div className="form-row" >
                                <div className="form-group col-md-3">
                                    <label
                                        style={{
                                            fontWeight: "normal",
                                            fontSize: "18px",
                                            marginTop: "5px",
                                        }}
                                        className='text-bold'
                                    >
                                        From:
                                    </label>
                                </div>
                                <div className="form-group col-md-9">
                                    <input
                                        type="text"
                                        value={email?.From}
                                        className="form-control"
                                        readOnly
                                    />
                                </div>
                            </div>


                            <div className="form-row" >
                                <div className="form-group col-md-3">
                                    <label
                                        style={{
                                            fontWeight: "normal",
                                            fontSize: "18px",
                                            marginTop: "5px",
                                        }}
                                        className='text-bold'
                                    >
                                        To:
                                    </label>
                                </div>
                                <div className="form-group col-md-9">
                                    <input
                                        type="text"
                                        value={email?.recipient}
                                        className="form-control"
                                        readOnly
                                    />
                                </div>
                            </div>


                            <div className="form-row" >
                                <div className="form-group col-md-3">
                                    <label
                                        style={{
                                            fontWeight: "normal",
                                            fontSize: "18px",
                                            marginTop: "5px",
                                        }}
                                        className='text-bold'
                                    >
                                        Date:
                                    </label>
                                </div>
                                <div className="form-group col-md-9">
                                    <input
                                        type="text"
                                        value={formatDate(email?.Date)}
                                        className="form-control"
                                        readOnly
                                    />
                                </div>
                            </div>

                            <div className="form-row" >
                                <div className="form-group col-md-3">
                                    <label
                                        style={{
                                            fontWeight: "normal",
                                            fontSize: "18px",
                                            marginTop: "5px",
                                        }}
                                        className='text-bold'
                                    >
                                        Body:
                                    </label>
                                </div>
                                <div className="form-group col-md-9">
                                    <textarea
                                        className="form-control"
                                        rows="8"
                                        cols="10"
                                        readOnly
                                        defaultValue={email ? email['body-plain'] : ""}
                                    >  
                                    </textarea>
                                </div>
                            </div>
                        </form>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button id="button-cancel" className="mr-4 " onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default OrphanModal;
