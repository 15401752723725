import React, { useState, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { useParams } from "react-router-dom";
import { useDrag, useDrop } from "react-dnd";
import {
  ItemTypes,
  supportedInputFields,
} from "../../../../../constants/ItemTypes";
import { connect, useDispatch } from "react-redux";
import {
  addField,
  removeField,
  updateFieldDetail,
  updateSectionDetail,
} from "../../../../../redux/actions/appBuilder";
import {
  deleteAppField,
  updateAppSection
} from "../../../../../services/sync"
import Grid from "@material-ui/core/Grid";
import DraggableFieldText from "../Fields/DraggableFieldText";
import DraggableFieldCalculation from "../Fields/DraggableFieldCalculation";
import DraggableFieldCategory from "../Fields/DraggableFieldCategory";
import DraggableFieldProgress from "../Fields/DraggableFieldProgress";
import DraggableFieldLocation from "../Fields/DraggableFieldLocation";
import DraggableFieldMember from "../Fields/DraggableFieldMember";
import DraggableFieldDate from "../Fields/DraggableFieldDate";
import DraggableFieldDuration from "../Fields/DraggableFieldDuration";
import DraggableFieldEmail from "../Fields/DraggableFieldEmail";
import DraggableFieldImage from "../Fields/DraggableFieldImage";
import DraggableFieldLink from "../Fields/DraggableFieldLink";
import DraggableFieldRelationship from "../Fields/DraggableFieldRelationship";
import DraggableFieldNumber from "../Fields/DraggableFieldNumber";
import DraggableFieldMoney from "../Fields/DraggableFieldMoney";
import DraggableFieldPhone from "../Fields/DraggableFieldPhone";
// import DeleteIcon from "@material-ui/icons/Delete";
import ContentEditable from "../../../../../components/WrappedContentEditable"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "10px",
  },
  section: {
    marginTop: "25px",
    width: "100%",
    backgroundColor: "#fffffffa",
    cursor: "pointer",
    borderRadius: "3px",
  },
  sectionLayout: {
    marginTop: "10px",
    background: "0 0",
    border: "1px dashed #c9d1dd",
    padding: "30px 30px",
    width: "inherit",
  },
  paper: {
    textAlign: "center",
    color: "#ffffff",
    width: "100%",
    backgroundColor: "#F7F7F7",
    border: "1px dashed #B1B1B1",
    height: "100px",
    boxShadow: "none",
  },
  canvasPaper: {
    textAlign: "center",
    color: "#292727",
    backgroundColor: "#F7F7F7",
    border: "1px solid #B1B1B1",
    height: "100px",
  },
}));

const GridLayoutC4 = ({ fields, type, sectionId, appBuilderPayload}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: supportedInputFields,
    drop: (item, monitor) => dragHandler(item),

    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  }));

  const dragHandler = (field) => {

    field.sectionId = sectionId;
    field.columnType = type;
    dispatch(addField(field));
  };

  const removeSelectedField = async (fieldId, section_id, columnType, externalId = null) => {
    let payload = { sectionId: section_id, fieldId, columnType };

    dispatch(removeField(payload));
    if (
      !externalId || 
      !appBuilderPayload?.unique_hash || 
      !appBuilderPayload?.appSections
    ) {
    return ;
  }
  try {
      let data = {
        org_id: params.org_id,
        app_id: params.app_id,
        field_id: fieldId,
      }
      let response = await deleteAppField(data); 
      
      if (response) {
        let payload = {
          status: "deleted",
          sectionId, 
          fieldId, 
          columnType, 
        };
        dispatch(updateFieldDetail(payload));
        data.unique_hash = appBuilderPayload.unique_hash;
        data.name = appBuilderPayload?.name ? appBuilderPayload.name : "New App";
        data.data = appBuilderPayload.appSections
        await updateAppSection(data);
      }
  } catch (err) {
  }
  };

  const isActive = canDrop && isOver;

  return (
    <Grid item xs={3} ref={drop}>
      {fields === undefined ||fields.length <= 0 ? (
        <Paper className={classes.canvasPaper}>
          {" "}
          {isActive ? "Release to drop" : "Drag item here"}
        </Paper>
      ) : (
        fields.map((field, key) => {
          if (field.type === ItemTypes.FIELD_TEXT) {
            return (
              <DraggableFieldText
                key={field.field_id}
                view="drawing_canvas"
                fieldId={field.field_id}
                sectionId={sectionId}
                columnType={field.column_type}
                removeSelectedField={removeSelectedField}
               
              />
            );
          }

          if (field.type === ItemTypes.FIELD_CALCULATION) {
            return (
              <DraggableFieldCalculation
                key={field.field_id}
                view="drawing_canvas"
                fieldId={field.field_id}
                sectionId={sectionId}
                columnType={field.column_type}
                removeSelectedField={removeSelectedField}
               
              />
            );
          }

          if (field.type === ItemTypes.FIELD_CATEGORY) {
            return (
              <DraggableFieldCategory
                key={field.field_id}
                view="drawing_canvas"
                fieldId={field.field_id}
                sectionId={sectionId}
                columnType={field.column_type}
                removeSelectedField={removeSelectedField}
               
              />
            );
          }

          if (field.type === ItemTypes.FIELD_PROGRESS) {
            return (
              <DraggableFieldProgress
                key={field.field_id}
                view="drawing_canvas"
                fieldId={field.field_id}
                sectionId={sectionId}
                columnType={field.column_type}
                removeSelectedField={removeSelectedField}
               
              />
            );
          }

          if (field.type === ItemTypes.FIELD_LOCATION) {
            return (
              <DraggableFieldLocation
                key={field.field_id}
                view="drawing_canvas"
                fieldId={field.field_id}
                sectionId={sectionId}
                columnType={field.column_type}
                removeSelectedField={removeSelectedField}
               
              />
            );
          }

          if (field.type === ItemTypes.FIELD_MEMBER) {
            return (
              <DraggableFieldMember
                key={field.field_id}
                view="drawing_canvas"
                fieldId={field.field_id}
                sectionId={sectionId}
                columnType={field.column_type}
                removeSelectedField={removeSelectedField}
               
              />
            );
          }

          if (field.type === ItemTypes.FIELD_DURATION) {
            return (
              <DraggableFieldDuration
                key={field.field_id}
                view="drawing_canvas"
                fieldId={field.field_id}
                sectionId={sectionId}
                columnType={field.column_type}
                removeSelectedField={removeSelectedField}
               
              />
            );
          }

          if (field.type === ItemTypes.FIELD_DATE) {
            return (
              <DraggableFieldDate
                key={field.field_id}
                view="drawing_canvas"
                fieldId={field.field_id}
                sectionId={sectionId}
                columnType={field.column_type}
                removeSelectedField={removeSelectedField}
               
              />
            );
          }

          if (field.type === ItemTypes.FIELD_EMAIL) {
            return (
              <DraggableFieldEmail
                key={field.field_id}
                view="drawing_canvas"
                fieldId={field.field_id}
                sectionId={sectionId}
                columnType={field.column_type}
                removeSelectedField={removeSelectedField}
               
              />
            );
          }

          if (field.type === ItemTypes.FIELD_IMAGE) {
            return (
              <DraggableFieldImage
                key={field.field_id}
                view="drawing_canvas"
                fieldId={field.field_id}
                sectionId={sectionId}
                columnType={field.column_type}
                removeSelectedField={removeSelectedField}
               
              />
            );
          }

          if (field.type === ItemTypes.FIELD_LINK) {
            return (
              <DraggableFieldLink
                key={field.field_id}
                view="drawing_canvas"
                fieldId={field.field_id}
                sectionId={sectionId}
                columnType={field.column_type}
                removeSelectedField={removeSelectedField}
               
              />
            );
          }

          if (field.type === ItemTypes.FIELD_RELATIONSHIP) {
            return (
              <DraggableFieldRelationship
                key={field.field_id}
                view="drawing_canvas"
                fieldId={field.field_id}
                sectionId={sectionId}
                columnType={field.column_type}
                removeSelectedField={removeSelectedField}
               
              />
            );
          }

          if (field.type === ItemTypes.FIELD_NUMBER) {
            return (
              <DraggableFieldNumber
                key={field.field_id}
                view="drawing_canvas"
                fieldId={field.field_id}
                sectionId={sectionId}
                columnType={field.column_type}
                removeSelectedField={removeSelectedField}
               
              />
            );
          }

          if (field.type === ItemTypes.FIELD_MONEY) {
            return (
              <DraggableFieldMoney
                key={field.field_id}
                view="drawing_canvas"
                fieldId={field.field_id}
                sectionId={sectionId}
                columnType={field.column_type}
                removeSelectedField={removeSelectedField}
               
              />
            );
          }

          if (field.type === ItemTypes.FIELD_PHONE) {
            return (
              <DraggableFieldPhone
                key={field.field_id}
                view="drawing_canvas"
                fieldId={field.field_id}
                sectionId={sectionId}
                columnType={field.column_type}
                removeSelectedField={removeSelectedField}
               
              />
            );
          }

          return <div key={key}></div>;
        })
      )}
    </Grid>
  );
};

const DraggableSectionR1C4 = ({
  appFields,
  view,
  handleRemoveSection,
  sectionId,appBuilderPayload,
}) => {
  const classes = useStyles();
  const sectionDispatch = useDispatch();
  const [name, setName] = useState(
     appFields?.section_details?.name ? 
      appFields.section_details.name :
      "Layout 1-4"
    );

    const handleUpdateName = (value) => {
      let newName = value.target.value;
      setName(newName)
       let data = { name: newName }
      let payload = { sectionId, section_details: data};
      sectionDispatch(updateSectionDetail(payload))
    }
  
  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    item: { name: "SectionR1C4", type: ItemTypes.SECTION_R1C4 },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <Fragment>
      {view === "pallete" ? (
        <div className={classes.section}>
          <div
            className="pb-2 pt-2"
            ref={dragPreview}
            style={{ opacity: isDragging ? 0.5 : 1, padding: "10px 10px" }}
          >
            <div className="row mt-3 mx-0" ref={drag}>
              <div className="col-md-3">
                <Paper className={classes.paper}></Paper>
              </div>
              <div className="col-md-3">
                <Paper className={classes.paper}></Paper>
              </div>
              <div className="col-md-3">
                <Paper className={classes.paper}></Paper>
              </div>
              <div className="col-md-3">
                <Paper className={classes.paper}></Paper>
              </div>
            </div>
          </div>
          <p className="text-center align-bottom caption">Four Column Section</p>
        </div>
      ) : (
        <Grid
          container
          className={classes.sectionLayout}
          direction="row"
          style={{ width: "inherit" }}
          spacing={1}
        >
          <Grid item xs={12}>
            <span>
            <ContentEditable style={{ float: "left", fontSize: "20px", cursor: "pointer" }}  html={name} onChange={handleUpdateName} />
              {/* <DeleteIcon
                style={{
                  float: "right",
                  marginBottom: "10px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleRemoveSection(sectionId);
                }}
              /> */}
            </span>
          </Grid>
          <GridLayoutC4
            fields={appFields?.columns?.first_column}
            type="first_column"
            sectionId={sectionId} 
            appBuilderPayload={appBuilderPayload}
          />
          <GridLayoutC4
            fields={appFields?.columns?.second_column}
            type="second_column"
            sectionId={sectionId} 
            appBuilderPayload={appBuilderPayload}
          />
          <GridLayoutC4
            fields={appFields?.columns?.third_column}
            type="third_column"
            sectionId={sectionId} 
            appBuilderPayload={appBuilderPayload}
          />
          <GridLayoutC4
            fields={appFields?.columns?.fourth_column}
            type="fourth_column"
            sectionId={sectionId} 
            appBuilderPayload={appBuilderPayload}
          />
        </Grid>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state, ownProps) => ({
  appFields: state.app_builder.appSections.filter(
    (section) => ownProps.sectionId === section.id
  )[0],
  appBuilderPayload: state.app_builder
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DraggableSectionR1C4);
