export const ADD_APP = "ADD_APP";
export const GET_APP = "GET_APP";
export const DELETE_APP = "DELETE_APP";
export const ERROR = "ERROR";
export const ADD_DOMAIN = "ADD_DOMAIN";
export const UPDATE_APP = "UPDATE_APP";
export const GET_DOMAIN = "GET_DOMAIN";
export const DELETE_DOMAIN = "DELETE_DOMAIN";
export const RESYNC_APP = "RESYNC_APP";
export const VERIFY_DOMAIN = "VERIFY_DOMAIN";
export const ADD_SIGNATURE = "ADD_SIGNATURE";
export const GET_SIGNATURE = "GET_SIGNATURE";
export const DELETE_SIGNATURE = "DELETE_SIGNATURE";
export const ADD_EMAIL = "ADD_EMAIL";
export const UPDATE_EMAIL = "UPDATE_EMAIL";
export const SAVE_AUTOREPLY = "SAVE_AUTOREPLY";
export const TEST_AUTOREPLY = "TEST_AUTOREPLY";
export const GET_EMAIL = "GET_EMAIL";
export const DELETE_EMAIL = "DELETE_EMAIL";
export const ADD_MEMBER = "ADD_MEMBER";
export const GET_MEMBERS = "GET_MEMBERS";
export const GET_SPACE_MEMBERS = "GET_SPACE_MEMBERS";
export const DELETE_MEMBER = "DELETE_MEMBER";