import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import { DOMAINSV2 } from '../../../../containers/pagePath';


const DomainCard = ({domains}) => {
    const verified = domains.filter(domain => domain.verification_status === "active");
    const unverified = domains.filter(domain => domain.verification_status === "inactive");
    return (
        <Card>
            <CardHeader title="Domain" />
            <Link to={DOMAINSV2} style={{ textDecoration: "none" }}>
            <CardContent>
                <ul className="list-group">
                    <li className="list-group-item">Total Domains: {domains.length}</li>
                    <li className="list-group-item">Verified Domains: {verified.length}</li>
                    <li className="list-group-item">Unverified Domains: {unverified.length}</li>
                </ul>
            </CardContent>
            </Link>
        </Card>
    )
}

export default DomainCard