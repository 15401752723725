import React from 'react'
import error from "../assets/images/error.png";
import styled from "@emotion/styled";
import {Link} from 'react-router-dom'

function NotFound() {
    const Wrapper = styled.div`
      /* background-color: red; */
      /* display: grid; */
      place-items: center;
      height: 100vh;
      grid-template-columns: 1fr;
      align-items:center;
      text-align:center;
      padding-top:100px;
      img{
        width:300px;
        margin-top:50px;
      }
      h1 {
        margin: 1rem auto;
        color: #8D2B0B;
        text-align:center;
      };
      button{
        color: #fff;
        background: #F7C948;
        border-radius: 6px;
        border: none;
        padding: 15px 30px;
      }
    `;

    return (
        <Wrapper>
          <React.Fragment>
            <img src={error} alt="reload" />
            <h1>Page not found</h1>
            <Link to='/'>
                <button className="reload">Go back to home page</button>
            </Link>
          </React.Fragment>
        </Wrapper>
    )
}

export default NotFound
