import React from 'react';
import styles from './fields.module.scss';
import InfoIcon from '@material-ui/icons/Info';

import { DimensionField, PaperField, CustomSlider } from './fields';
import CustomTooltip from 'components/templates/print-v2/components/CustomTooltip';
import { usePrintData } from 'components/templates/print-v2/context/PrintProvider';

export default function DocumentFields() {
  const { documentFields, setDocumentFields } = usePrintData();

  function handleChange(e) {
    const { name, value } = e.target;
    setDocumentFields((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  function handleOrientation(e) {
    handleChange(e);
    return document.getElementById('document-editor').setAttribute('orientation', e.target.value.toLowerCase());
  }

  function handleEditorSize(e) {
    handleChange(e);
    return document.getElementById('document-editor').setAttribute('size', e.target.value);
  }

  const [sliderValue, setSliderValue] = React.useState(100);
  const handleScale = (_event, newValue) => {
    setSliderValue(() => {
      return newValue;
    });
    return (document.getElementById('document-editor-scale').style = `transform:scale(${newValue / 100});`);
  };

  return (
    <div className={styles.document_fields_container}>
      <div className={styles.paperFields}>
        <PaperField
          name='orientation'
          label='Orientation'
          options={['portrait', 'landscape']}
          onChange={handleOrientation}
          value={documentFields?.orientation}
        />
        <PaperField name='size' label='Size' options={['A3', 'A4', 'A5']} onChange={handleEditorSize} value={documentFields?.size} />
      </div>
      <hr />
      <p className='mt-3 font-weight-medium'>Section Height</p>
      <div className={styles.grid_group}>
        <DimensionField name='headerHeight' value={documentFields.headerHeight} label='Header' onChange={handleChange} min={20} step={10} />
        <DimensionField name='footerHeight' value={documentFields.footerHeight} label='Footer' onChange={handleChange} min={20} step={10} />
      </div>
      <hr />
      <p className='mt-3 font-weight-medium'>Page Padding</p>
      <div className={styles.grid_group}>
        <DimensionField name='marginTop' value={documentFields.marginTop} label='Top' onChange={handleChange} />
        <DimensionField name='marginBottom' value={documentFields.marginBottom} label='Bottom' onChange={handleChange} />
        <DimensionField name='marginLeft' value={documentFields.marginLeft} label='Left' onChange={handleChange} />
        <DimensionField name='marginRight' value={documentFields.marginRight} label='Right' onChange={handleChange} />
      </div>
      <hr />
      <div className={styles.scale_container}>
        <label htmlFor='scale'>Scale</label>
        <CustomSlider
          value={sliderValue}
          onChange={handleScale}
          id='scale'
          aria-labelledby='discrete-slider-custom'
          step={5}
          valueLabelDisplay='auto'
          min={1}
          max={200}
        />
      </div>

      <div className={`${styles.paperFields} mt-2`}>
        <p className='mt-2 font-weight-medium'>Page Number</p>
        <PaperField
          name='pageNumberPosition'
          label='position'
          options={['none', 'header', 'footer', 'header and footer']}
          onChange={handleChange}
          value={documentFields.pageNumberPosition}
        />
        <PaperField
          name='pageNumberAlign'
          label='align'
          options={['none', 'left', 'center', 'right']}
          onChange={handleChange}
          value={documentFields.pageNumberAlign}
        />
      </div>
      <hr />
      <fieldset className='mt-3 d-flex flex-column'>
        <label htmlFor='title' className={`${styles.label} mb-1`}>
          Empty Podio fields value
          <CustomTooltip title='Specify what value should be used in case of empty podio fields' placement='top'>
            <InfoIcon style={{ fontSize: '14px', marginLeft: '5px', color: '#28a745' }} />
          </CustomTooltip>
        </label>
        <input
          type='text'
          id='title'
          name='value_when_empty'
          placeholder='Empty variable value'
          value={documentFields?.value_when_empty}
          onChange={handleChange}
        />
      </fieldset>
      <fieldset className='mt-3 d-flex flex-column'>
        <label htmlFor='title' className={`${styles.label} mb-1`}>
          Podio fields error value
          <CustomTooltip title='Specify what value should be used in case of error' placement='top'>
            <InfoIcon style={{ fontSize: '14px', marginLeft: '5px', color: '#28a745' }} />
          </CustomTooltip>
        </label>
        <input
          type='text'
          id='title'
          name='value_when_errored'
          placeholder='Error variable value'
          value={documentFields?.value_when_errored}
          onChange={handleChange}
        />
      </fieldset>
    </div>
  );
}
