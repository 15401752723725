import { React, useEffect, Fragment } from "react";
import { Grid, ThemeProvider, Box } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { ItemTypes } from "../../../../constants/ItemTypes";
import TextField from "../../../../components/atoms/TextInput";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { connect, useDispatch } from "react-redux";
import { addItem, addValue } from "../../../../redux/actions/appItems";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  select: {
    width: "100px",
  },
  input: {
    border: `1px solid #ccc`,
    outline: `1px solid transparent`,
    "&$focused": {
      border: `1px solid #ccc`,
      outline: `1px solid #ccc`,
    },
  },

  textField: {
    border: "2px solid #ccc",
  },

  create_rate_cont: {
    display: "flex",
    width: "100%",
    alignItems: "center",
  },

  label: {
    width: "20%",
  },
  label2: {
    width: "20%",
  },

  "@media screen and (max-width: 768px)": {
    inputM: {
      width: "100%",
    },
    create_rate_cont: {
      flexDirection: "column",
      width: "100%",
    },
    label: {
      width: "100%",
    },
    label2: {
        width: "100%",
      },
  },
}));

const muiTheme = createTheme({
  overrides: {
    MuiTextField: {
      root: {
        "&$disabled": {
          "& fieldset.MuiOutlinedInput-notchedOutline": {
            borderColor: "#ccc",
            border: "2px solid #ccc",
            background: "grey",
          },
        },
      },
    },
    MuiInput: {
      root: {
        border: `1px solid #ccc`,

        outline: `1px solid #ccc`,

        "&$focused": {
          border: `1px solid #ccc`,

          outline: `1px solid #ccc`,
        },
      },

      // we don't need `focused: {}` with overrides
    },
  },
});

const GridLayoutC1 = (props) => {
  const { fields, values, externalIds, types } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const jsxFields = [];

  const handleFieldChange = (value) => {
    let payload = {
      name: value.target.name,
      value: value.target.value,
    };

    dispatch(addValue(payload));
  };

  const handleMoneyFieldChange = (event) => {
    let payload = {
      name: event.target.name,
      value: { value: parseInt(event.target.value) },
    };

    dispatch(addValue(payload));
  };

  const handleSelectCurrency = (event) => {
    let payload = {
      name: event.target.name,
      value: { currency: event.target.value },
    };
    dispatch(addValue(payload));
  };

  const handleObjectFieldChange = (event) => {
    let payload = {
      name: event.target.name,
      value: { value: event.target.value },
    };

    dispatch(addValue(payload));
  };

  const handleObjectSelectCurrency = (event) => {
    let payload = {
      name: event.target.name,
      value: { type: event.target.value },
    };
    dispatch(addValue(payload));
  };

  fields.forEach((field, key) => {
    const field_name = field.external_id;

    if (field.status === "deleted") {
      return field;
    }

    let categories = [];

    if (field.type === ItemTypes.FIELD_CATEGORY) {
      let catText = [];
      field.config.settings.options.forEach((element) => {
        if (!catText.includes(element.text)) {
          catText.push(element.text);
          return categories.push(
            <MenuItem key={element.id} value={element.id}>
              {element.text}
            </MenuItem>
          );
        }
      });
    }

    let currencies = [];
    if (field.type === ItemTypes.FIELD_MONEY) {
      let moneyCurrency = [];
      field.config.settings.allowed_currencies.forEach((element, index) => {
        if (!moneyCurrency.includes(element)) {
          moneyCurrency.push(element);
          return currencies.push(
            <option key={index} value={element}>
              {element}
            </option>
          );
        }
      });
    }
    if (externalIds && !externalIds.includes(field_name)) {
      externalIds.push(field_name);
      types.push(field.type);
    }

    if (field.type === ItemTypes.FIELD_TEXT) {
      return jsxFields.push(
        <div className={classes.create_rate_cont}>
          <Box className={classes.label} key={field.field_id}>
            <InputLabel className="font-weight-bold">
              {field.config.label}
            </InputLabel>
          </Box>
          <Box
            className="bg-white w-100"
            style={{ padding: "10px 10px" }}
            key={field.field_id}
          >
            <ThemeProvider theme={muiTheme}>
              <TextField
                id="standard-full-width"
                style={{
                  border: "2px solid #ccc",
                }}
                className={classes.input}
                required={field.config.required ? field.config.required : false}
                placeholder={field.config.label}
                variant="outlined"
                fullWidth
                type="text"
                margin="none"
                name={field_name}
                value={values[field_name] ? values[field_name] : ""}
                onChange={handleFieldChange}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  classes: {
                    root: classes.input,
                    focused: classes.focused,
                  },
                  disableUnderline: true, // remove the underline
                }}
              />
            </ThemeProvider>
          </Box>
        </div>
      );
    }

    if (field.type === ItemTypes.FIELD_TAGS) {
      return jsxFields.push(
        <Fragment>
          <Grid item xs={2} key={field.field_id}>
            <InputLabel className="mt-5 font-weight-bold">
              {field.config.label}
            </InputLabel>
          </Grid>
          <Grid
            item
            xs={10}
            className="card-body bg-white"
            style={{ padding: "10px 10px" }}
            key={field.field_id}
          >
            <TextField
              id="standard-full-width"
              required={field.config.required ? field.config.required : false}
              placeholder={field.config.label}
              fullWidth
              type="text"
              margin="none"
              name={field_name}
              value={values[field_name] ? values[field_name] : ""}
              onChange={handleFieldChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Fragment>
      );
    }

    // if (field.type === ItemTypes.FIELD_CALCULATION) {
    //     return jsxFields.push(
    //         <Grid item xs={12} style={{ padding: "10px 10px" }} key={field.field_id}>
    //             <TextField
    //                 id="standard-full-width"
    //
    //                 required={field.config.required ? field.config.required : false}
    //                 placeholder={field.config.label}
    //                 fullWidth
    //                 type={field.type}
    //                 margin="none"
    //                 name={field_name}
    //                 value={values[field_name] ? values[field_name] : ''}
    //                 onChange={handleFieldChange}
    //                 InputLabelProps={{
    //                     shrink: true,
    //                 }}
    //             />
    //         </Grid>
    //     );
    // }

    if (field.type === ItemTypes.FIELD_CATEGORY) {
      return jsxFields.push(
        <Fragment>
          <Grid item xs={2} key={field.field_id}>
            <InputLabel className="mt-3 font-weight-bold">
              {field.config.label}
            </InputLabel>
          </Grid>
          <Grid
            item
            xs={10}
            className="card-body bg-white"
            style={{ padding: "10px 10px" }}
            key={field.field_id}
          >
            {/* <InputLabel id="demo-simple-select-label">{field.config.label}</InputLabel> */}
            <Select
              fullWidth
              style={{ paddingLeft: "17px" }}
              required={field.config.required ? field.config.required : false}
              margin="none"
              name={field_name}
              value={values[field_name] ? values[field_name] : ""}
              onChange={handleFieldChange}
            >
              <MenuItem value="">- Select Option -</MenuItem>
              {categories}
            </Select>
          </Grid>
        </Fragment>
      );
    }

    if (field.type === ItemTypes.FIELD_PROGRESS) {
      return jsxFields.push(
        <Fragment>
          <Grid item xs={2} key={field.field_id}>
            <InputLabel className="mt-5 font-weight-bold">
              {field.config.label}
            </InputLabel>
          </Grid>
          <Grid
            item
            xs={10}
            className="card-body bg-white"
            style={{ padding: "10px 10px" }}
            key={field.field_id}
          >
            <TextField
              id="standard-full-width"
              required={field.config.required ? field.config.required : false}
              placeholder="Progress: 0 - 100"
              fullWidth
              type="number"
              min="0"
              max="100"
              margin="none"
              name={field_name}
              value={values[field_name] ? parseInt(values[field_name]) : ""}
              onChange={handleFieldChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Fragment>
      );
    }

    if (field.type === ItemTypes.FIELD_LOCATION) {
      return jsxFields.push(
        <Fragment>
          <Grid item xs={2} key={field.field_id}>
            <InputLabel className="mt-5 font-weight-bold">
              {field.config.label}
            </InputLabel>
          </Grid>
          <Grid
            item
            xs={10}
            className="card-body bg-white"
            style={{ padding: "10px 10px" }}
            key={field.field_id}
          >
            <TextField
              id="standard-full-width"
              required={field.config.required ? field.config.required : false}
              placeholder={field.config.label}
              fullWidth
              type="text"
              margin="none"
              name={field_name}
              value={values[field_name] ? values[field_name] : ""}
              onChange={handleFieldChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Fragment>
      );
    }

    if (field.type === ItemTypes.FIELD_MEMBER) {
      return jsxFields.push(
        <Fragment>
          <Grid item xs={2} key={field.field_id}>
            <InputLabel className="mt-5 font-weight-bold">
              {field.config.label}
            </InputLabel>
          </Grid>
          <Grid
            item
            xs={10}
            className="card-body bg-white"
            style={{ padding: "10px 10px" }}
            key={field.field_id}
          >
            <TextField
              id="standard-full-width"
              required={field.config.required ? field.config.required : false}
              placeholder={field.config.label}
              fullWidth
              type="text"
              margin="none"
              name={field_name}
              value={values[field_name] ? values[field_name] : ""}
              onChange={handleFieldChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Fragment>
      );
    }

    if (field.type === ItemTypes.FIELD_CONTACT) {
      return jsxFields.push(
        <Fragment>
          <Grid item xs={2} key={field.field_id}>
            <InputLabel className="mt-5 font-weight-bold">
              {field.config.label}
            </InputLabel>
          </Grid>
          <Grid
            item
            xs={10}
            className="card-body bg-white"
            style={{ padding: "10px 10px" }}
            key={field.field_id}
          >
            <TextField
              id="standard-full-width"
              required={field.config.required ? field.config.required : false}
              placeholder={field.config.label}
              fullWidth
              type="text"
              margin="none"
              name={field_name}
              value={values[field_name] ? values[field_name] : ""}
              onChange={handleFieldChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Fragment>
      );
    }

    if (field.type === ItemTypes.FIELD_DURATION) {
      return jsxFields.push(
        <Fragment>
          <Grid item xs={2} key={field.field_id}>
            <InputLabel className="mt-5 font-weight-bold">
              {field.config.label}
            </InputLabel>
          </Grid>
          <Grid
            item
            xs={10}
            className="card-body bg-white"
            style={{ padding: "10px 10px" }}
            key={field.field_id}
          >
            <TextField
              id="standard-full-width"
              required={field.config.required ? field.config.required : false}
              placeholder="Duration in seconds"
              fullWidth
              type="number"
              margin="none"
              name={field_name}
              value={values[field_name] ? values[field_name] : ""}
              onChange={handleFieldChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Fragment>
      );
    }

    if (field.type === ItemTypes.FIELD_DATE) {
      return jsxFields.push(
        <Fragment>
          <Grid item xs={2} key={field.field_id}>
            <InputLabel className="mt-5 font-weight-bold">
              {field.config.label}
            </InputLabel>
          </Grid>
          <Grid
            item
            xs={10}
            className="card-body bg-white"
            style={{ padding: "10px 10px" }}
            key={field.field_id}
          >
            <TextField
              id="standard-full-width"
              required={field.config.required ? field.config.required : false}
              placeholder={field.config.label}
              fullWidth
              type="date"
              margin="none"
              name={field_name}
              value={values[field_name] ? values[field_name] : ""}
              onChange={handleFieldChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Fragment>
      );
    }

    if (field.type === ItemTypes.FIELD_EMAIL) {
      return jsxFields.push(
        <Fragment>
          <Grid item xs={2} key={field.field_id}>
            <InputLabel className="mt-5 font-weight-bold">
              {field.config.label}
            </InputLabel>
          </Grid>
          <Grid
            item
            xs={10}
            className="card-body bg-white"
            style={{ padding: "10px 10px" }}
            key={field.field_id}
          >
            <TextField
              id="standard-full-width"
              required={field.config.required ? field.config.required : false}
              placeholder={field.config.label}
              fullWidth
              type="email"
              margin="none"
              maxLength="254"
              name={field_name}
              value={values[field_name]?.value ? values[field_name].value : ""}
              onChange={handleObjectFieldChange}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Select
                      native
                      fullWidth
                      required={
                        field.config.required ? field.config.required : false
                      }
                      margin="none"
                      name={field_name}
                      value={
                        values[field_name]?.type ? values[field_name].type : ""
                      }
                      onChange={handleObjectSelectCurrency}
                      classes={{ select: classes.select }}
                    >
                      <option value="">- Select Type - </option>
                      <option value="home">Home</option>
                      <option value="work">Work</option>
                      <option value="other">Other</option>
                    </Select>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Fragment>
      );
    }

    if (field.type === ItemTypes.FIELD_IMAGE) {
      return jsxFields.push(
        <Fragment>
          <Grid item xs={2} key={field.field_id}>
            <InputLabel className="mt-5 font-weight-bold">
              {field.config.label}
            </InputLabel>
          </Grid>
          <Grid
            item
            xs={10}
            className="card-body bg-white"
            style={{ padding: "10px 10px" }}
            key={field.field_id}
          >
            <TextField
              id="standard-full-width"
              required={field.config.required ? field.config.required : false}
              placeholder="Image Url"
              fullWidth
              type="text"
              margin="none"
              name={field_name}
              value={values[field_name] ? values[field_name] : ""}
              onChange={handleFieldChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Fragment>
      );
    }

    if (field.type === ItemTypes.FIELD_LINK) {
      return jsxFields.push(
        <Fragment>
          <Grid item xs={2} key={field.field_id}>
            <InputLabel className="mt-5 font-weight-bold">
              {field.config.label}
            </InputLabel>
          </Grid>
          <Grid
            item
            xs={10}
            className="card-body bg-white"
            style={{ padding: "10px 10px" }}
            key={field.field_id}
          >
            <TextField
              id="standard-full-width"
              required={field.config.required ? field.config.required : false}
              placeholder={field.config.label}
              fullWidth
              type="url"
              margin="none"
              name={field_name}
              value={values[field_name] ? values[field_name] : ""}
              onChange={handleFieldChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Fragment>
      );
    }

    if (field.type === ItemTypes.FIELD_RELATIONSHIP) {
      return jsxFields.push(
        <Fragment>
          <Grid item xs={2} key={field.field_id}>
            <InputLabel className="mt-5 font-weight-bold">
              {field.config.label}
            </InputLabel>
          </Grid>
          <Grid
            item
            xs={10}
            className="card-body bg-white"
            style={{ padding: "10px 10px" }}
            key={field.field_id}
          >
            <TextField
              id="standard-full-width"
              required={field.config.required ? field.config.required : false}
              placeholder={field.config.label}
              fullWidth
              type={field.type}
              margin="none"
              name={field_name}
              value={values[field_name] ? values[field_name] : ""}
              onChange={handleFieldChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Fragment>
      );
    }

    if (field.type === ItemTypes.FIELD_NUMBER) {
      return jsxFields.push(
        <div className={classes.create_rate_cont}>
          <Box className={classes.label2} key={field.field_id}>
            <InputLabel className="font-weight-bold">
              {field.config.label}
            </InputLabel>
          </Box>
          <Box
            className="card-body bg-white w-100"
            style={{ padding: "10px 10px" }}
            key={field.field_id}
          >
            <TextField
              id="standard-full-width"
              required={field.config.required ? field.config.required : false}
              placeholder={field.config.label}
              fullWidth
              type="number"
              margin="none"
              name={field_name}
              value={values[field_name] ? values[field_name] : ""}
              onChange={handleFieldChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        </div>
      );
    }

    if (field.type === ItemTypes.FIELD_MONEY) {
      return jsxFields.push(
        <Fragment>
          <Grid item xs={2} key={field.field_id}>
            <InputLabel className="mt-5 font-weight-bold">
              {field.config.label}
            </InputLabel>
          </Grid>
          <Grid
            item
            xs={10}
            className="card-body bg-white"
            style={{ padding: "10px 10px" }}
            key={field.field_id}
          >
            <TextField
              id="standard-full-width"
              required={field.config.required ? field.config.required : false}
              placeholder={field.config.label}
              fullWidth
              type="number"
              margin="none"
              name={field_name}
              value={values[field_name]?.value ? values[field_name].value : ""}
              onChange={handleMoneyFieldChange}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Select
                      native
                      fullWidth
                      required={
                        field.config.required ? field.config.required : false
                      }
                      margin="none"
                      name={field_name}
                      value={
                        values[field_name]?.currency
                          ? values[field_name].currency
                          : ""
                      }
                      onChange={handleSelectCurrency}
                      classes={{ select: classes.select }}
                    >
                      <option value="">- Select Option -</option>
                      {currencies}
                    </Select>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Fragment>
      );
    }

    if (field.type === ItemTypes.FIELD_PHONE) {
      return jsxFields.push(
        <Fragment>
          <Grid item xs={2} key={field.field_id}>
            <InputLabel className="mt-5 font-weight-bold">
              {field.config.label}
            </InputLabel>
          </Grid>
          <Grid
            item
            xs={10}
            className="card-body bg-white"
            style={{ padding: "10px 10px" }}
            key={field.field_id}
          >
            <TextField
              id="standard-full-width"
              required={field.config.required ? field.config.required : false}
              placeholder={field.config.label}
              fullWidth
              type="tel"
              margin="none"
              maxLength="50"
              name={field_name}
              value={values[field_name]?.value ? values[field_name].value : ""}
              onChange={handleObjectFieldChange}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Select
                      native
                      fullWidth
                      required={
                        field.config.required ? field.config.required : false
                      }
                      margin="none"
                      name={field_name}
                      value={
                        values[field_name]?.type ? values[field_name].type : ""
                      }
                      onChange={handleObjectSelectCurrency}
                      classes={{ select: classes.select }}
                    >
                      <option value="">- Select Type - </option>
                      <option value="home">Home</option>
                      <option value="work">Work</option>
                      <option value="mobile">Mobile</option>
                      <option value="main">main</option>
                      <option value="work_fax">Work Fax</option>
                      <option value="private_fax">Private Fax</option>
                      <option value="other">Other</option>
                    </Select>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Fragment>
      );
    }
    return <div key={key}></div>;
  });

  useEffect(() => {
    let isCurrent = true;
    if (isCurrent) {
      let payload = {
        externalIds,
        types,
      };
      if (externalIds && types) {
        dispatch(addItem(payload));
      }
    }

    return () => {
      isCurrent = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid item xs={12}>
      <Grid container direction="row" style={{ width: "inherit" }} spacing={1}>
        {jsxFields}
      </Grid>
    </Grid>
  );
};

const SectionR1C1 = (props) => {
  const { columns, values } = props;

  return (
    <Fragment>
      <Grid
        container
        direction="row"
        style={{ width: "inherit", padding: "10px 10px" }}
        spacing={1}
        alignContent="center"
        justifyContent="center"
      >
        <GridLayoutC1 fields={columns.first_column} values={values} />
      </Grid>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  externalIds: state.app_items.externalIds,
  types: state.app_items.types,
  values: state.app_items.values,
});

export default connect(mapStateToProps, null)(SectionR1C1);
