/* eslint-disable react-hooks/exhaustive-deps */
import { AgGridReact } from "ag-grid-react";
// import * as AllModules from "ag-grid-enterprise";
// import { ModuleRegistry } from "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import WorkFlowActionsRender from "../AuditGrids/actions/WorkFlowActionRender";
import React, { useEffect, useState } from "react";
import { GetWorkflow } from "../../../redux/actions/connector";
import { useDispatch } from "react-redux";
import Axios from "config";

// ModuleRegistry.registerModules(Object.keys(AllModules));
const BackedupWorkflow = ({ org_id, user }) => {
  const dispatch = useDispatch();

  const columns = [
    {
      headerName: "Space Name",
      field: "space_name",
      filter: "agTextColumnFilter",
      rowGroup: true,
      hide: true,
    },

    {
      headerName: "Space ID",
      field: "space_id",
      filter: "agNumberColumnFilter",
    },
    {
      headerName: "Flow Count",
      // hide: false,
      field: "file_count",
      filter: "agNumberColumnFilter",
    },
    {
      headerName: "FIle Size",
      field: "file_size",
      filter: "agNumberColumnFilter",
    },

    {
      headerName: "Flow Name",
      field: "flow_name",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Flow ID",
      field: "flow_id",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "App Name",
      field: "app_name",
      filter: "agNumberColumnFilter",
    },
    {
      headerName: "App ID",
      field: "app_id",
      filter: "agNumberColumnFilter",
    },
    {
      headerName: "Description",
      field: "description",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Steps",
      field: "steps",
      filter: "agNumberColumnFilter",
    },

    {
      headerName: "Created At",
      field: "created_at",
      filter: "agNumberColumnFilter",
    },

    {
      headerName: "Updated At",
      field: "updated_at",
      filter: "agNumberColumnFilter",
    },

    { headerName: "Action", field: "actions", cellRenderer: "actionsRenderer" },
  ];

  useEffect(() => {
    dispatch(GetWorkflow(org_id));
  }, [org_id]);

  const defaultColDef = {
    flex: 1,
    minWidth: 100,
    filter: true,
    sortable: true,
    resizable: true,
  };
  const rowModelType = "serverSide";

  const frameworks = {
    actionsRenderer: WorkFlowActionsRender,
  };

  const ServerSideDatasource = (orgId) => {
    let data = null;

    const getRows = async (params) => {
      let group = "";

      if (
        Array.isArray(params.request.groupKeys) &&
        params.request.groupKeys.length
      ) {
        group = `space_id=${params.parentNode.data.space_id}`;
      }

      if (!data) {
        try {
          const response = await Axios.get(`/workflows/list?org_id=${orgId}`);
          data = response.data;
        } catch (error) {
          console.error(error);
        }
      }

      if (group === "") {
        const rowData = [];
        data?.map((item, i) => {
          return rowData.push({
            space_name: item.space_name,
            flow_name: "--",
            space_id: item.space_id,
            file_count: item.file_count,
            file_size: item.file_size,
            updated_at: item.updated_at,
            created_at: item.created_at,
            steps: "--",
            flow_id: "",
            description: "--",
            app_name: "--",
            org_id: orgId,
          });
        });

        if (rowData && rowData.length > 0) {
          params.successCallback(rowData, rowData.length);
        } else {
          params.successCallback([], 0);
        }
      }

      if (group === `space_id=${params.parentNode?.data?.space_id}`) {
        const space = data.find(
          (item) => item.space_id === params.parentNode.data.space_id
        );
        const flows = space?.flows;
        const rowData = flows?.map((item) => ({
          flow_name: item.name,
          app_id: item.podio_app_id,
          space_id: space.space_id,
          description: item.description,
          app_name: item.podio_app_name,
          steps: item.steps,
          flow_id: item.flow_id,
          org_id: orgId,
          flow_count: item.flow_count,
        }));

        if (rowData && rowData.length > 0) {
          params.successCallback(rowData, rowData.length);
        } else {
          params.successCallback([], 0);
        }
      }
    };

    return { getRows };
  };

  // const ServerSideDatasource = () => {
  //   return {
  //     getRows: function (params) {
  //       let group = "";
  //       let query = "";

  //       if (
  //         Array.isArray(params.request.groupKeys) &&
  //         params.request.groupKeys.length
  //       ) {
  //         group = `space_id=${params.parentNode.data.space_id}`;
  //         query = `${query}&${group}`;
  //       }

  //       Axios.get(`/workflows/list?org_id=${org_id}${query}`)
  //         .then((response) => {
  //           if (group === "") {
  //             const rowData = [];
  //             response.data.map((item, i) => {
  //               return rowData.push({
  //                 space_name: item.space_name,
  //                 flow_name: "--",
  //                 space_id: item.space_id,
  //                 file_count: item.file_count,
  //                 file_size: item.file_size,
  //                 updated_at: item.updated_at,
  //                 created_at: item.created_at,
  //                 steps: "--",
  //                 flow_id: "",
  //                 description: "--",
  //                 app_name: "--",
  //                 org_id: org_id,
  //               });
  //             });

  //             setTimeout(function () {
  //               params.successCallback(rowData, rowData.length);
  //             }, 200);
  //           } else {
  //             const space = response.data.find(
  //               (item) => item.space_id === params.parentNode.data.space_id
  //             );
  //             const flows = space.flows;
  //             const rowData = [];
  //             flows.map((item) => {
  //               return rowData.push({
  //                 flow_name: item.name,
  //                 app_id: item.podio_app_id,
  //                 space_id: space.space_id,
  //                 description: item.description,
  //                 app_name: item.podio_app_name,
  //                 steps: item.steps,
  //                 flow_id: item.flow_id,
  //                 org_id: org_id,
  //                 flow_count: item.flow_count,
  //               });
  //             });
  //             setTimeout(function () {
  //               params.successCallback(rowData, rowData.length);
  //             }, 200);
  //           }
  //         })
  //         .catch(() => {
  //           params.failCallback();
  //         });
  //     },
  //   };
  // };

  const [gridApi, setGridApi] = useState(null);
  const [initialRender, setInitialRender] = useState(false);

  const onGridReady = (params) => {
    const datasource = ServerSideDatasource(org_id);
    setGridApi(params.api);

    params.api.setServerSideDatasource(datasource);
    setInitialRender(true);
  };

  const refreshCache = () => {
    if (initialRender) {
      const datasource = ServerSideDatasource();
      gridApi.setServerSideDatasource(datasource);

      setInitialRender(true);
    }
  };
  useEffect(() => {
    refreshCache();
  }, [org_id, user]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className="ag-theme-balham-dark"
      style={{
        height: "800px",
        width: "100%",
      }}
    >
      <AgGridReact
        // modules={Object.keys(AllModules)}
        columnDefs={columns}
        onGridReady={onGridReady}
        frameworkComponents={frameworks}
        animateRows={true}
        debug={true}
        enableRangeSelection={true}
        sideBar={true}
        suppressAggFuncInHeader={true}
        enableFilter={true}
        enableSorting={true}
        defaultColDef={defaultColDef}
        rowModelType={rowModelType}
        rowGroupPanelShow={
          user?.permissions?.data_interaction?.row_grouping ? "always" : "never"
        }
        rowSelection="multiple"
      ></AgGridReact>
    </div>
  );
};

export default BackedupWorkflow;
