import React, { useState } from "react";
import styled from "@emotion/styled";
import { useHistory } from "react-router-dom";
import { css } from "@emotion/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import grid from "../../../../../assets/icons/grid.svg";
import arrowDownRight from "../../../../../assets/icons/arrow-down-right.svg";


const MenuItem = (props) => {
  const {
    page,
    isSubMenu = false,
    onClick,
    link,
    isActive = false,
    showArrow,
    subMenu,
  } = props;
  const history = useHistory();
  const { name, icon } = page;

  const menuItemClickHandler = () => {
    if (link && showArrow) {
      history.push(link);
    }
  };
  const [active, setActive] = useState(false);

  return (
    <MenuItem.Wrapper
      isActive={isActive}
      isSubMenu={isSubMenu}
      onClick={() => onClick(page)}
      active={active}
    >
      {subMenu === "submenu" ? (
        ""
      ) : (
        <img
          src={!icon || icon === "dashboard" ? grid : icon}
          alt={name}
          className="img-fluid"
        />
      )}

      <div
        className={
          subMenu
            ? "d-flex justify-content-between w-100 inner-item"
            : "d-flex justify-content-between w-100"
        }
        onClick={() => setActive(!active)}
      >
        <span className="name-holder" onClick={menuItemClickHandler}>
          {name}
        </span>
        {showArrow && (
          <span className="arrow-down">
            <KeyboardArrowDownIcon />
          </span>
        )}
      </div>
    </MenuItem.Wrapper>
  );
};

MenuItem.Wrapper = styled.span`
  font-size: 12px;
  display: flex;
  align-items: center;
  color: white;
  // margin-bottom: 13px;
  border-radius: 0 24px 24px 0;
  padding: 8px 12px 8px 2.4rem;
  transition: 0.25s ease-in-out all;
  .name-holder {
    margin-left: 2rem;
    width: 70%;
    max-width: 70%;
    margin-left: 2rem;
    text-overflow: ellipsis !important;
    overflow: hidden;
    margin-top: 3px;
  }
  .show-arrow {
    display: none;
  }
  &:hover {
    background-color: #f7c948;
    cursor: pointer;
    transition: 0.25s ease-in-out all;
  }
  .arrow-down {
    transform: rotate(0deg);
    transition: 0.25s ease-in-out all;
  }

  ${(props) =>
    props.isSubMenu &&
    css`
      background: #3b3b3b;
      margin-left: 20px;
    `}

  ${(props) =>
    props.isActive &&
    css`
      background-color: #f7c948;
      cursor: pointer;
    `}

  ${(props) =>
    props.active &&
    css`
      background: #464646;
      .arrow-down {
        transform: rotate(180deg);
        transition: 0.25s ease-in-out all;
      }
    `}

  ${(props) =>
    props.subMenu &&
    css`
      background: #3b3b3b;
      cursor: pointer;
    `}
`;

const SubMenu = (props) => {
  const [visible, setVisible] = useState(false);
  const { icon, name, pages, link, onSubMenuClick } = props;

  const itemPage = {
    name,
    icon,
    link: "",
  };

  const currentLocation = window.location.pathname;

  return (
    <React.Fragment>
      <MenuItem
        link={link}
        page={itemPage}
        showArrow
        onClick={() => setVisible(!visible)}
      />

      <SubMenu.Wrapper visible={visible}>
        {pages.map((page, index) => {
          const { name, submenu } = page;

          return submenu === undefined ? (
            <MenuItem
              isActive={currentLocation.includes(page.link)}
              page={page}
              link={link}
              key={index}
              subMenu={"submenu"}
              onClick={onSubMenuClick}
            />
          ) : (
            <SubMenu
              onSubMenuClick={onSubMenuClick}
              key={index}
              link={link}
              name={name}
              icon={arrowDownRight}
              pages={submenu}
            />
          );
        })}
      </SubMenu.Wrapper>
    </React.Fragment>
  );
};

SubMenu.Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 0;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
  img {
    display: none;
  }
  ${(props) =>
    props.visible &&
    css`
      max-height: 5000px;
      transition: max-height 0.25s ease-in;
      background: #464646;
      .inner-item {
        padding-left: 20px;
      }
    `}
`;

const SideBarHandler = (props) => {
  const { pages } = props;
  const history = useHistory();
  const [currentLocation, setcurrentLocation] = useState(
    window.location.pathname
  );

  const menuItemClickHandler = (page) => {
    setcurrentLocation(page.link);
    history.push(page.link);
  };

  return (
    <div>
      <div className="items">
        {pages.map((page, index) => {
          const { name, icon, submenu, link } = page;

          return submenu === undefined ? (
            <MenuItem
              isActive={currentLocation.includes(link)}
              page={page}
              link={link}
              key={index}
              onClick={menuItemClickHandler}
            />
          ) : (
            <SubMenu
              onSubMenuClick={menuItemClickHandler}
              key={name}
              link={link}
              name={name}
              icon={icon}
              pages={submenu}
            />
          );
        })}
      </div>
    </div>
  );
};

export default SideBarHandler;
