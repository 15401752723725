import React, { useState } from "react";
import "./ActionsRenderer.css";
import Confirm from "../../../../components/Modal/Confirm";
import {
  deleteApp,
  deleteSpace,
  checkSpace,
  syncApps,
  syncSpaces,
  restoreApp,
  restoreSpace,
  refreshItems,
} from "../../../../services/sync";
import { toast } from "helpers/apiRequests";
// import CreateNewDataMapModal from "../../../../components/Modal/Connector/CreateNewDataMapModal";
import { Refresh } from "@material-ui/icons";
import CreateNewDataMapModalV2 from "../../../../components/Modal/Connector/CreateNewDataMapModalV2";
import { useSelector } from "react-redux";
import CredentialModal from "../../../../components/Modal/CredentialModal";
import RenderByPermission from "components/RenderByPermission";

const ActionsRenderer = (props) => {
  const initial = {
    message: null,
    modal_type: null,
    title: "",
    modal_value: false,
    modal_data: {},
    popup: false,
  };
  const [syncModal, syncModalData] = useState(initial);
  const [preLoader, setPreLoader] = useState(false);
  const [openItemModal, setOpenItemModal] = useState(false);
  const [fields, setFields] = useState({});
  const connectors = useSelector((state) => state.connector.database);
  const [open, setOpen] = useState(false);
  const [selectedConnector, setSelectedConnector] = useState(null);
  //const [message, setMessage] = useState(null);

  //const [message, setMessage] = useState(null);

  //Mysql Connector
  const handleClickOpenItemModal = () => {
    setOpenItemModal(true);
    // setFields(data);
  };

  const handleOpenModal = (data) => {
    setOpen(true);
    setFields(data);
  };

  const handleCloseItemModal = () => {
    setOpenItemModal(false);
    setFields({});
  };
  //Action
  const handleSubmit = async (type) => {
    let data;
    setPreLoader(true);
    if (type === "syncapp") {
      try {
        data = {
          org_id: syncModal.modal_data.org,
          app_id: syncModal.modal_data.app,
        };

        await syncApps(`?org_id=${data.org_id}&app_ids=${data.app_id}`);
        toast.notify("App syncing has started successfully!");
      } catch (e) {}
      setPreLoader(false);
      syncModalData(initial);
    }
    if (type === "refreshItem") {
      try {
        data = {
          org_id: syncModal.modal_data.org,
          app_id: syncModal.modal_data.app,
        };

        await refreshItems(`?org_id=${data.org_id}&app_id=${data.app_id}`);
        toast.notify("App Item cued successfully");
      } catch (e) {}
      setPreLoader(false);
      syncModalData(initial);
    }

    if (type === "syncspace") {
      try {
        data = {
          org_id: syncModal.modal_data.org,
          space_id: syncModal.modal_data.space,
        };

        syncSpaces(`?org_id=${data.org_id}&space_ids=${data.space_id}`);
        toast.notify("Space synced successfully");
      } catch (e) {}
      setPreLoader(false);
      syncModalData(initial);
    }

    if (type === "restorespace") {
      data = {
        org_id: syncModal.modal_data.org,
        space_id: syncModal.modal_data.space,
      };
      try {
        await restoreSpace(data);
        toast.notify("Space restored successfully");

        setPreLoader(false);
        syncModalData(initial);
        window.document.getElementById("refreshThisGrid").click();
      } catch {
        setPreLoader(false);
        syncModalData(initial);
      }
    }

    if (type === "restoreapp") {
      data = {
        source_org_id: syncModal.modal_data.org,
        source_space_id: syncModal.modal_data.space,
        destination_org_id: syncModal.modal_data.org,
        destination_space_id: syncModal.modal_data.space,
        source_app_ids: [syncModal.modal_data.app],
        // restore_items_too: true,
      };
   
      try {
        await restoreApp(data);
        toast.notify("App restored successfully");

        setPreLoader(false);
        syncModalData(initial);
        window.document.getElementById("refreshThisGrid").click();
      } catch {
        setPreLoader(false);
        syncModalData(initial);
      }
    }

    if (type === "deleteapp") {
      try {
        data = {
          org_id: syncModal.modal_data.org,
          app_id: syncModal.modal_data.app,
        };
        await deleteApp(`?org_id=${data.org_id}&app_id=${data.app_id}`);
        toast.notify(
          "This app and its items were deleted successfully, refresh the grid to see results."
        );
        setPreLoader(false);
        syncModalData(initial);
        window.document.getElementById("refreshThisGrid").click();
      } catch (e) {
        setPreLoader(false);
        syncModalData(initial);
      }
    }
    //Mysql
    if (type === "mysqlbackupapp") {
      try {
        alert("Hey, MySQL Here !!!");
        setPreLoader(false);
        syncModalData(initial);
        //window.document.getElementById("refreshThisGrid").click();
      } catch (e) {
        setPreLoader(false);
        syncModalData(initial);
      }
    }

    if (type === "deletespace") {
      try {
        data = {
          org_id: syncModal.modal_data.org,
          space_id: syncModal.modal_data.space,
        };
        deleteSpace(`?org_id=${data.org_id}&space_id=${data.space_id}`);

        toast.notify(
          "This space and its apps/items were deleted successfully, refresh the grid to see results"
        );
        setPreLoader(false);
        syncModalData(initial);
        window.document.getElementById("refreshThisGrid").click();
      } catch (e) {
        setPreLoader(false);
        syncModalData(initial);
      }
    }

    if (type === "checkspace") {
      try {
        data = {
          org_id: syncModal.modal_data.org,
          space_id: syncModal.modal_data.space,
        };
        let response = await checkSpace(
          `?org_id=${data.org_id}&space_id=${data.space_id}`
        );
        if (response?.message) {
          toast.notify(response.message);
        }
        setPreLoader(false);
        syncModalData(initial);
        // window.document.getElementById("refreshThisGrid").click();
      } catch (e) {
        setPreLoader(false);
        syncModalData(initial);
      }
    }
  };

  //Call the modals
  const syncAppPopup = (org, app) => {
    syncModalData({
      message: "Do you want to Resync this App?",
      modal_type: "syncapp",
      title: "Sync App",
      modal_value: true,
      modal_data: { org, app },
      popup: true,
    });
  };

  //Call the modals
  const refreshItemCount = (org, app) => {
    syncModalData({
      message: "Do you want to refresh the count of this app items?",
      modal_type: "refreshItem",
      title: "Refresh Item Count",
      modal_value: true,
      modal_data: { org, app },
      popup: true,
    });
  };

  const syncSpacePopup = (org, space) => {
    syncModalData({
      message: "Do you want to cue this space?",
      modal_type: "syncspace",
      title: "Sync Space",
      modal_value: true,
      modal_data: { org, space },
      popup: true,
    });
  };

  const restoreSpacePopup = (org, space) => {
    syncModalData({
      message: "Do you want to restore this space?",
      modal_type: "restorespace",
      title: "Restore Space",
      modal_value: true,
      modal_data: { org, space },
      popup: true,
    });
  };

  const restoreAppPopup = (org, app, space) => {
    syncModalData({
      message: "Do you want to restore this app?",
      modal_type: "restoreapp",
      title: "Restore app",
      modal_value: true,
      modal_data: { org, app, space },
      popup: true,
    });
  };

  const deleteAppPopup = (org, app) => {
    syncModalData({
      message:
        "Do you want to delete this app and all its data from our system?",
      modal_type: "deleteapp",
      title: "Delete app",
      modal_value: true,
      modal_data: { org, app },
      popup: true,
    });
  };
  const deleteSpacePopup = (org, space) => {
    syncModalData({
      message:
        "Do you want to delete this space and all its data from our system?",
      modal_type: "deletespace",
      title: "Delete space",
      modal_value: true,
      modal_data: { org, space },
      popup: true,
    });
  };

  const checkSpacePopup = (org, space) => {
    syncModalData({
      message: "Do you want to check this space?",
      modal_type: "checkspace",
      title: "Check space",
      modal_value: true,
      modal_data: { org, space },
      popup: true,
    });
  };

  const changeSelectedConncector = (val) => {
    setSelectedConnector(val);
  };
  const closeCredentialModal = () => {
    setOpen(false);
  };

  //Action Display
  const dataInGroup =
    typeof props.node["childrenAfterGroup"] !== "undefined"
      ? props.node.childrenAfterGroup[0].data
      : {};
  const data = typeof props["data"] !== "undefined" ? props["data"] : null;

  const toggleModal = () => {
    syncModalData(initial);
  };

  return (
    <>
      <CredentialModal
        openModal={open}
        handleClose={closeCredentialModal}
        connector={selectedConnector}
        changeConnector={changeSelectedConncector}
        connectors={connectors}
        saveMapper={handleClickOpenItemModal}
      />
      <Confirm
        className="confirm-modal-container"
        openModal={syncModal.modal_value}
        message={syncModal.message}
        action={() => handleSubmit(syncModal.modal_type)}
        title={syncModal.title}
        toggleModal={toggleModal}
        modalLoader={preLoader}
      />
      <CreateNewDataMapModalV2
        openItemModal={openItemModal}
        handleCloseItemModal={handleCloseItemModal}
        selectedApp={fields}
        isConnector={false}
        connectorId={selectedConnector}
      />
      {data.point === "app" ? (
        <>
          {data.deleted_from_podio === false ? (
            <i
              className="fa fa-sync-alt ag-grid-data-list"
              style={{
                fontSize: "1.50em",
                marginRight: "10px",
                cursor: "pointer",
              }}
              title="Resync App"
              onClick={() => syncAppPopup(data.org_id, data.app_id)}
            ></i>
          ) : (
            <i
              className="fa fa-trash-restore-alt ag-grid-data-list"
              style={{
                fontSize: "1.50em",
                marginRight: "10px",
                cursor: "pointer",
              }}
              title="Restore App"
              onClick={() =>
                restoreAppPopup(data.org_id, data.app_id, data.space_id)
              }
            ></i>
          )}
          <RenderByPermission allowTeamMemberAccess={true}>
            <i
              className="fa fa-trash"
              style={{
                fontSize: "1.50em",
                marginRight: "10px",
                cursor: "pointer",
              }}
              title="Delete App"
              onClick={() => deleteAppPopup(data.org_id, data.app_id)}
            ></i>
          </RenderByPermission>
          <i
            className="fa fa-database"
            style={{
              fontSize: "1.50em",
              marginRight: "10px",
              cursor: "pointer",
            }}
            title="MySQL Backup Sync"
            onClick={() => {
              // handleClickOpenItemModal(data);
              handleOpenModal(data);
            }}
          ></i>
          {/* <IconButton
            onClick={() => syncAppPopup(data.org_id, data.app_id)}
            title="Refresh Item Count"
            style={{
              background: "none",
              color: "#fff",
              padding: "0",
              fontSize: "1.50em",
              marginRight: "10px",
              marginTop: "0px",
              cursor: "pointer",
            }}
          > */}
          <i
            // className="fa-solid fa-rotate-right"
            onClick={() => refreshItemCount(data.org_id, data.app_id)}
            title="Refresh Item Count"
            style={{
              background: "none",
              color: "#fff",
              fontSize: "20px",
              marginRight: "10px",
              cursor: "pointer",
            }}
          >
            <Refresh />
          </i>
          {/* </IconButton> */}
        </>
      ) : (
        <>
          {data.deleted_space_from_podio === false ||
          dataInGroup.deleted_space_from_podio === false ? (
            <i
              className="fa fa-sync-alt"
              style={{
                fontSize: "1.50em",
                marginRight: "10px",
                cursor: "pointer",
              }}
              title="Resync Space"
              onClick={() => syncSpacePopup(data.org_id, data.space_id)}
            ></i>
          ) : (
            <i
              className="fa fa-trash-restore-alt"
              style={{
                fontSize: "1.50em",
                marginRight: "10px",
                cursor: "pointer",
              }}
              title="Restore Space"
              onClick={() => restoreSpacePopup(data.org_id, data.space_id)}
            ></i>
          )}
          <RenderByPermission allowTeamMemberAccess={false}>
            <i
              className="fa fa-trash"
              style={{
                fontSize: "1.50em",
                marginRight: "10px",
                cursor: "pointer",
              }}
              title="Delete Space"
              onClick={() => {
                deleteSpacePopup(data.org_id, data.space_id);
              }}
            ></i>
          </RenderByPermission>
          <i
            className="fa fa-check-double"
            style={{
              fontSize: "1.50em",
              marginRight: "10px",
              cursor: "pointer",
            }}
            title="Check Space"
            onClick={() => {
              checkSpacePopup(data.org_id, data.space_id);
            }}
          ></i>
        </>
      )}
    </>
  );
};

export default ActionsRenderer;
