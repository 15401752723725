/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import axios from "../../config";
import Notification from "../Notification/Notification";
import { useSelector } from "react-redux";
import "./SelectAppForMapperCreation.css";
import appendTeamMemberHeader from "utils/appendTeamMemberHeader";
import cancel from "../../assets/icons/cancel.svg";

export const getModalStyle = function () {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};

const useStyles = makeStyles((theme) => ({
  mapper_creation: {
    position: "absolute",
    width: 400,
    margin: "auto",
    minWidth: "30%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #fff",
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "3%"
  },
  headerText: {
    color: "black",
  },
  label: {
    marginBottom: "10px"
  }
}));

export default function SelectAppForMapperCreationV3({
  setFields,
  setOpenItemModal,
  fields,
  selectedOrg,
  mapperExists,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [notificationData, setNotificationData] = useState({
    displayNotification: false,
    msg: "",
    severity: "success",
  });

  const [modalStyle] = useState(getModalStyle);
  // const [organizations, setOrganizations] = useState([]);
  const [apps, setApps] = useState([]);
  const [selectedApp, setSelectedApp] = useState("");
  const [selectedOrganization, setSelectedOrganization] = useState("");
  const [organization, setOrganization] = useState(null);
  const [selectedSpace, setSelectedSpace] = useState("");
  const [spaces, setSpaces] = useState([]);
  const [loading, setLoading] = useState({
    apps: false,
    spaces: false,
  });
  const orgs = useSelector((state) => state.sync.orgs);

  const handleOpen = () => {
    setOpen(true);
  };
  // useEffect(() => {
  //   getOrganizations();
  // }, []);

  const token = "Bearer" + localStorage.getItem("chosen_token");
  let headers = {
    Authorization: token,
  };

  headers = appendTeamMemberHeader(headers);

  useEffect(() => {
    if (selectedOrg) {
      setSelectedOrganization(selectedOrg);
      const org = orgs.find((where) => where.org_id === Number(selectedOrg));
      setOrganization(org);
      getSpaces(selectedOrg);
    }
  }, []);

  const getSpaces = async (orgId) => {
    try {
      setLoading({
        apps: false,
        spaces: true,
      });
      let res = await axios.get(`/sync/podio/audit/spaces?org_id=${orgId}`, {
        headers,
      });
      setSpaces(res.data);
      setLoading({
        apps: false,
        spaces: false,
      });
    } catch (e) {
      setNotificationData({
        displayNotification: true,
        msg: "Error retrieving spaces",
        severity: "error",
      });
    }
  };

  const getApps = async (spaceId) => {
    try {
      setLoading({
        apps: true,
        spaces: false,
      });
      let data = await axios.get(
        `/sync/podio/audit/apps?space_id=${spaceId}&org_id=${selectedOrganization}`,
        {
          headers,
        }
      );
      setApps(data.data);
      setLoading({
        apps: false,
        spaces: false,
      });
    } catch (e) {
      setNotificationData({
        displayNotification: true,
        msg: "Error retrieving apps",
        severity: "error",
      });
    }
  };

  const handleSpacesChange = async (event) => {
    setApps([]);
    setSelectedSpace(event.target.value);

    await getApps(event.target.value);
  };

  const handleAppChange = async (event) => {
    setSelectedApp(event.target.value);

    const currentApp = apps.find((app) => {
      return app.app_id === Number(event.target.value);
    });
    currentApp.org_id = selectedOrganization;

    setFields(currentApp);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setSelectedApp("");
    setSelectedSpace("");
    setOpen(false);
  };

  const isValid = mapperExists || Object.entries(fields).length === 0

  const body = (
    <div style={modalStyle} className={classes.mapper_creation}>
      <Notification
        displayNotification={notificationData.displayNotification}
        severity={notificationData.severity}
        msg={notificationData.msg}
      />
      <form className="pt-2">
        <Typography variant="h6">
          <div className={classes.modalHeader}>
            <h5 className={classes.headerText}>Create Mapper</h5>
            <div>
              <img onClick={handleClose} alt="cancel" src={cancel} />
            </div>
          </div>
        </Typography>

        <div className="form-group">
          <label className={classes.label} htmlFor="organisation">Organization</label>
          <input
            type="text"
            className="form-control"
            value={organization?.name}
            readOnly
          />
        </div>
        <div className="form-group">
          <label className={classes.label} htmlFor="space">Space</label>
          {loading.spaces ? (
            <div
              className="spinner-border ml-2 spinner-border-sm text-info"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            ""
          )}

          <select
            onChange={handleSpacesChange}
            value={selectedSpace}
            className="form-control"
            id="space"
          >
            <option value="">Select Space</option>
            {spaces.map((space, index) => (
              <option key={index} value={space.space_id}>
                {space.space_name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label className={classes.label} htmlFor="app">App</label>
          {loading.apps ? (
            <div
              className="spinner-border ml-2 spinner-border-sm text-info"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            ""
          )}
          <select
            onChange={handleAppChange}
            value={selectedApp}
            className="form-control"
            id="app"
          >
            <option value="">Select App</option>
            {apps.map((app, index) => {
              return (
                <option key={index} value={app.app_id}>
                  {app.app}
                </option>
              );
            })}
          </select>
        </div>
        {
          mapperExists && Object.entries(fields).length !== 0 ?
            <p style={{ fontSize: "12px", color: "#FF0000" }}>A mapper exists for this app already!</p>
            :
            null
        }

        <div className="d-flex justify-content-end mt-5">
          <button
            disabled={isValid}
            onClick={() => {
              handleClose();
              setOpenItemModal(true);
            }}
            style={{
              backgroundColor: isValid ? "#d3d3d3" : "#de911d",
              color: isValid ? "#a9a9a9" : "#FFFFFF",
              border: "none",
              cursor: isValid ? "not-allowed" : "pointer",
            }}
            className="sync__btn"
          >
            Create Mapper
          </button>
        </div>
      </form>
    </div>
  );
  return (
    <div>
      <button className="sync__btn" type="button" onClick={handleOpen}>
        Create Mapper
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
