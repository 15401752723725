import React, {useState} from 'react';
import classes from './styles.module.css';
import TextEditor from './TextEditor';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from "helpers/apiRequests";
import { useParams } from 'react-router-dom'

const Message = () => {
  const user = useSelector((state) => state.user.authUser);
  const {id } = useParams();

  const [inputData, setInputData] = useState({
    name: user.name,
    fromEmail: user.email,
    toEmail: '',
    ccEmail: '',
    subject: '',
  });
  const [message, setMessage] = useState("")

  const { name, fromEmail, toEmail, ccEmail, subject} = inputData;

  const changeHandler = (e) =>{
    setInputData({...inputData, [e.target.name]: e.target.value});
  }

  const messageChangeHandler = (value) =>{
    setMessage(value)
  }

  const submitHandler = async() =>{

    try {
      const payload = {
        email: fromEmail,
        toEmail,
        subject,
        name,
        message,
        itemId:id,
        userId: user.active_plan.user_id
      }
      const config = {
        headers: {
          "Authorization": "Bearer "+user.access_token
        }
      }
    
      const res = await axios.post('https://test-api.thatapp.io/api/v2/sync/podio/tmail', payload, config);
      console.log(res.data);
      toast.notify("Mail Sent");
    } catch (error) {
      toast.notify("Error Sending Mail", { type: "error" });
    }
  }

  return (
    <div className={classes.con}>
        <div className={classes.colDm}>
            <div className={classes.cdHeader}>
              <h6>Compose Email</h6>
              <button onClick={submitHandler}><i className="fa fa-paper-plane" aria-hidden="true"></i> Send</button>
            </div>
            <div className={classes.fromCon}>
                <div className={classes.from}>
                  <label htmlFor="from">From:</label>
                  <input 
                  type="text" 
                  name="name" 
                  value={name}
                  onChange={changeHandler} 
                  />
                  <input 
                  type="text" 
                  name="fromEmail" 
                  value={fromEmail}
                  disabled
                  />
                </div>
                <div className={classes.to}>
                  <label htmlFor="to">To:</label>
                  <input 
                    type="text" 
                    name="toEmail"
                    value={toEmail}
                    onChange={changeHandler}
                  />
                </div>
                <div className={classes.to}>
                  <label htmlFor="cc">CC:</label>
                  <input 
                    type="text" 
                    name="ccEmail"
                    value={ccEmail}
                    onChange={changeHandler}
                  />
                </div>
                <div className={classes.to}>
                  <label htmlFor="subject">Subject:</label>
                  <input 
                    type="text" 
                    name="subject"
                    value={subject}
                    onChange={changeHandler}
                  />
                </div>
               
                <div className={classes.ck}>
                  <TextEditor change={messageChangeHandler} />
                  <div className={`form-group float-right mt-2 ${classes.sign}`}>
                    <label htmlFor="signature">Signature: </label>
                    <select name="signature" id="sign">
                      <option value="none">None</option>
                      <option value="signature">Configure Signatures</option>
                    </select>
                  </div>
                </div>
              
            </div>
          </div>
        
    </div>
  )
}

export default Message