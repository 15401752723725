import React from 'react'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { AiFillTool } from "react-icons/ai";
import { MdMessage } from "react-icons/md";
import { IoIosSync } from "react-icons/io";
import { FaTrash } from "react-icons/fa";
import project from '../assets/project.svg'
import DeleteModal from './DeleteModal';
import ResyncModal from './ResyncModal';
import { useHistory } from "react-router";
import Tooltip from '@material-ui/core/Tooltip';
import { TMAIL_V3_APPLICATION_SETTING } from '../routes';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);



const useStyles = makeStyles((theme) => ({
  projectContainer: {
    width: '65px',
    height: '65px',
    backgroundColor: '#F0B429',
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '18px'
  },
  cardTitle: {
    color: "#F0B429",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
    marginBottom: '7px'
  },
  btnWarning: {
    padding: '0px',
    backgroundColor: 'transparent',
    width: "40px",
    height: "40px",
    borderRadius: "5px",
    color: "#F0B429",
    fontSize: '20px',
    border: "1px solid rgba(247, 201, 72, 0.40)",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const ApplicationCard = ({ app }) => {
  const classes = useStyles();
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [resyncModal, setResyncModal] = React.useState(false);
  const [payloadData, setPayloadData] = React.useState(null);
  const history = useHistory();

  const openDeleteModal = (payload) => {
    setPayloadData({
      id: payload
    })
    setDeleteModal(prev => !prev);
  };

  const closeDeleteModal = () => {
    setPayloadData(null)
    setDeleteModal(prev => !prev);
  };

  const openResyncModal = (payload) => {
    setPayloadData(payload)
    setResyncModal(prev => !prev);
  };

  const closeResyncModal = () => {
    setPayloadData(null)
    setResyncModal(prev => !prev);
  };

  const gotoSetting = () => {
    const url = TMAIL_V3_APPLICATION_SETTING.replace(':id', app.id)
    history.push(url)
  }


  return (
    <>
      <div className="card w-100 m-0 mb-4 shadow-sm border-0 rounded-0">
        <div className="card-body px-0 py-4">
          <div className="d-flex">
            <div className='flex-grow-1 py-4 text-center'>
              <div className={classes.projectContainer + ' mx-auto'}>
                <img src={project} alt="" />
              </div>

              <h5 className={classes.cardTitle}>{app.app_name}</h5>
              <p className='mb-0'>{app.items} {app.items < 1 ? "Item" : "Items"}</p>
              {/* <p>test@thatapp.io</p> */}
            </div>
            <ul className='mb-0 pr-5 d-flex flex-column justify-content-between list-unstyled d flex-shrink-0'>
              <li className=''>
                <LightTooltip title="Configuration Settings">
                  <button type='button' onClick={gotoSetting} className={classes.btnWarning}>
                    <AiFillTool />
                  </button>
                </LightTooltip>
              </li>
              <li className=''>
                <LightTooltip title="Moderate Email Comments">
                  <button 
                    type='button'
                    onClick={() => {
                      history.push(`/dashboard/tmail/comment-moderate/${app.id}`);
                    }}
                    className={classes.btnWarning}>
                    <MdMessage />
                  </button>
                </LightTooltip>
              </li>
              <li className=''>
                <LightTooltip title="Reload all Podio items">
                  <button 
                    type='button'
                    onClick={() => openResyncModal({ 
                      id: app.id,
                      org_id: app.org_id,
                      app_id: app.app_id
                    })}
                    className={classes.btnWarning}>
                    <IoIosSync />
                  </button>
                </LightTooltip>
              </li>
              <li className=''>
                <LightTooltip title="Delete">
                  <button 
                    type='button'
                    onClick={() => openDeleteModal(app.id)}
                    className={classes.btnWarning}>
                    <FaTrash />
                  </button>
                </LightTooltip>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <DeleteModal 
        open={deleteModal}
        payload={payloadData}
        onClose={closeDeleteModal}
      />

      <ResyncModal 
        open={resyncModal}
        payload={payloadData}
        onClose={closeResyncModal}
      />
    </>
  )
}

export default ApplicationCard