import * as actionTypes from "../constants/tmail";
import axios from "../../config";
import Swal from "sweetalert2";
import { toast } from "helpers/apiRequests";

export const setApp = (payload) => {
  return {
    type: actionTypes.ADD_APP,
    payload,
  };
};

export const updateApp = (payload) => {
  return {
    type: actionTypes.UPDATE_APP,
    payload,
  };
};

export const setDomain = (payload) => {
  return {
    type: actionTypes.ADD_DOMAIN,
    payload,
  };
};

export const verifyDomain = (payload) => {
  return {
    type: actionTypes.VERIFY_DOMAIN,
    payload,
  };
};

export const setError = () => {
  return {
    type: actionTypes.ERROR,
  };
};

export const GetApp = (payload) => {
  return {
    type: actionTypes.GET_APP,
    payload,
  };
};

export const GetSignature = (payload) => {
  return {
    type: actionTypes.GET_SIGNATURE,
    payload,
  };
};

export const addSignature = (payload) => {
  return {
    type: actionTypes.ADD_SIGNATURE,
    payload,
  };
};

export const GetDomain = (payload) => {
  return {
    type: actionTypes.GET_DOMAIN,
    payload,
  };
};

export const deleteApp = (payload) => {
  return {
    type: actionTypes.DELETE_APP,
    payload,
  };
};

export const deleteDomain = (payload) => {
  return {
    type: actionTypes.DELETE_DOMAIN,
    payload,
  };
};

export const deleteSignature = (payload) => {
  return {
    type: actionTypes.DELETE_SIGNATURE,
    payload,
  };
};

export const resync = () => {
  return {
    type: actionTypes.RESYNC_APP,
  };
};

export const deleteEmail = (payload) => {
  return {
    type: actionTypes.DELETE_EMAIL,
    payload,
  };
};

export const GetEmails = (payload) => {
  return {
    type: actionTypes.GET_EMAIL,
    payload,
  };
};
export const autoReply = (payload) => {
  return {
    type: actionTypes.SAVE_AUTOREPLY,
    payload,
  };
};

export const testAutoReply = (payload) => {
  return {
    type: actionTypes.TEST_AUTOREPLY,
    payload,
  };
};
export const addEmail = (payload) => {
  return {
    type: actionTypes.ADD_EMAIL,
    payload,
  };
};

export const updateEmail = (payload) => {
  return {
    type: actionTypes.UPDATE_EMAIL,
    payload,
  };
};

export const deleteMember = (payload) => {
  return {
    type: actionTypes.DELETE_MEMBER,
    payload,
  };
};

export const GetMembers = (payload) => {
  return {
    type: actionTypes.GET_MEMBERS,
    payload,
  };
};

export const GetSpaceMembers = (payload) => {
  return {
    type: actionTypes.GET_SPACE_MEMBERS,
    payload,
  };
};

export const addMember = (payload) => {
  return {
    type: actionTypes.ADD_MEMBER,
    payload,
  };
};

export const GetAllApps = () => {
  return async (dispatch) => {
    try {
      const url = "/tmail/connected/apps";
      const res = await axios.get(url);
    
      dispatch(GetApp(res.data));
    } catch (error) {
      toast.notify(error.response.message, { type: "error" });
    }
  };
};

export const GetAllDomains = () => {
  return async (dispatch) => {
    try {
      const url = "/tmail/domains";
      const res = await axios.get(url);
      dispatch(GetDomain(res.data));
    } catch (error) {
      dispatch(setError());
      toast.notify(error.response.message, { type: "error" });
    }
  };
};

export const addApp = (payload, history, changeIsLoadingRefApp) => {
  return async (dispatch) => {
    try {
      const url = "/tmail/connect/app";
      const res = await axios.post(url, payload);

    
      dispatch(setApp(res.data));
      changeIsLoadingRefApp();
      Swal.fire({
        title: "App Connected",
        icon: "success",
        text: "Successfully connected app",
      }).then(() => {
        history.push("/dashboard/tmail/apps");
      });
    } catch (error) {
      changeIsLoadingRefApp();
      dispatch(setError());
      toast.notify("Unable to connect app." + error.response.message, {
        type: "error",
      });
    }
  };
};

export const configureUpdateApp = (payload, changeIsLoadingSaveApp) => {
  return async (dispatch) => {
    try {
      const url = "/tmail/update-connected/app";
      const res = await axios.post(url, payload);
      dispatch(updateApp(res.data));
      changeIsLoadingSaveApp();
      toast.notify(res.message);
    } catch (error) {
      dispatch(setError());
      changeIsLoadingSaveApp();
      toast.notify(error.response.message, { type: "error" });
    }
  };
};

export const saveAutoReplyEmail = (payload, changeIsLoadingSaveApp) => {
  return async (dispatch) => {
    try {
    
      const url = "/tmail/app/autoreply/save";
      const res = await axios.post(url, payload);
     
      changeIsLoadingSaveApp();
      toast.notify("Auto reply settings saved successfully");
      dispatch(autoReply(res.data));
    } catch (error) {
      dispatch(setError());
      toast.notify(error.response.message, { type: "error" });
    }
  };
};

export const testAutoReplyEmail = (payload, changeIsLoadingSaveApp) => {
  return async (dispatch) => {
    try {
      const url = "/tmail/app/autoreply/test";
      const res = await axios.post(url, payload);
     
      changeIsLoadingSaveApp();
      toast.notify(res.message);

      dispatch(testAutoReply(res.data));
    } catch (error) {
      dispatch(setError());
      toast.notify(error.response.message, { type: "error" });
    }
  };
};

export const createDomain = (payload, changeIsLoadingSaveApp, handleClose) => {
  return async (dispatch) => {
    try {
      const url = "/tmail/add/domain";
      const res = await axios.post(url, payload);
      dispatch(setDomain(res.data));
      changeIsLoadingSaveApp();
      handleClose();
      toast.notify("Domain name created successfully");
    } catch (error) {
      dispatch(setError());
      changeIsLoadingSaveApp();
      handleClose();
      toast.notify(error.response.message, { type: "error" });
    }
  };
};

export const verifyDomainName = (id, changeIsLoadingSaveApp, handleClose) => {
  return async (dispatch) => {
    try {
      const url = "tmail/refresh/domain?id=" + id;
      const res = await axios.get(url);
      const payload = {
        data: res.data,
        id,
      };
      dispatch(verifyDomain(payload));
      changeIsLoadingSaveApp();
      handleClose();

      toast.notify(res.message);
    } catch (error) {
      dispatch(setError());
      changeIsLoadingSaveApp();
      handleClose();
      toast.notify(error.response.message, { type: "error" });
    }
  };
};

export const deleteConnectedApp = (payload) => {
  return async (dispatch) => {
    try {
      const url = "/tmail/delete-connected/app";
      await axios.post(url, payload);
     
      dispatch(deleteApp(payload.id));
      Swal.fire({
        title: "App disconnected",
        icon: "success",
        text: "Successfully disconnected app",
      });
    } catch (error) {
      dispatch(setError());
      toast.notify("Unable to diconnect app", { type: "error" });
    }
  };
};

export const deleteConnectedDomain = (payload, stopLoading) => {
  return async (dispatch) => {
    try {
      const url = "/tmail/delete/domain";
      await axios.post(url, payload);
     
      dispatch(deleteDomain(payload.id));
      stopLoading();
      Swal.fire({
        title: "Domain Deleted",
        icon: "success",
        text: "Domain has been deleted Successfully",
      });
    } catch (error) {
      dispatch(setError());
      stopLoading();
      toast.notify(error.response.message, { type: "error" });
    }
  };
};

export const resyncApp = (payload) => {
  return async (dispatch) => {
    try {
      const url = "/tmail/resync/app";
      await axios.post(url, payload);
     
      dispatch(resync());
      Swal.fire({
        title: "App Sync started",
        icon: "success",
        text: "App Sync has been dispatched",
      });
    } catch (error) {
      dispatch(setError());
      toast.notify(error.response.message, { type: "error" });
    }
  };
};

export const GetAllSignatures = () => {
  return async (dispatch) => {
    try {
      const url = "/tmail/signatures";
      const res = await axios.get(url);
      return res;
    } catch (error) {
      toast.notify(error.response.message, { type: "error" });
    }
  };
};

export const deleteConnectedSignature = (id) => {
  return async (dispatch) => {
    try {
      const url = "/tmail/signatures/" + id;
      const res = await axios.delete(url);
     
      // dispatch(deleteSignature(payload.id));
      // Swal.fire({
      //     title: "Signature Deleted",
      //     icon: "success",
      //     text: "Successfully delete signature"
      // })
      toast.notify(res.message);
    } catch (error) {
      // dispatch(setError());
      toast.notify(error.response.message, { type: "error" });
    }
  };
};

export const createSignature = (payload) => {
  return async (dispatch) => {
    try {
      const url = "/tmail/signatures";
      const res = await axios.post(url, payload);
      return res;
    
      // dispatch(addSignature(payload));
      // Swal.fire({
      //     title: "Success",
      //     icon: "success",
      //     text: "New signature configured"
      // })
    } catch (error) {
      dispatch(setError());
      toast.notify(error.response.message, { type: "error" });
    }
  };
};

export const GetAllDefaultEmails = () => {
  return async (dispatch) => {
    try {
      const url = "/tmail/default-emails/list";
      const res = await axios.get(url);
     
      dispatch(GetEmails(res.data));
    } catch (error) {
      dispatch(setError());
      toast.notify(error.response.message, { type: "error" });
    }
  };
};

export const deleteDefaultEmail = (id, closeModal) => {
  return async (dispatch) => {
    try {
      const url = "/tmail/default-emails/delete";
      const payload = {
        email_id: id,
      };
      await axios.post(url, payload);
      dispatch(deleteEmail(id));
      closeModal();
      Swal.fire({
        title: "Email Deleted",
        icon: "success",
        text: "Successfully delete email",
      });
    } catch (error) {
      dispatch(setError());
      closeModal();
      toast.notify(error.response.message, { type: "error" });
    }
  };
};

export const createDefaultEmail = (
  payload,
  changeIsLoadingSaveApp,
  closeModal
) => {
  return async (dispatch) => {
    try {
      const url = "/tmail/default-emails/save";
      const res = await axios.post(url, payload);
      
      if (payload.tmail_email_id !== "") {
       
        dispatch(updateEmail(res.data));
      } else {
        dispatch(addEmail(res.data));
      }

      changeIsLoadingSaveApp();
      closeModal();
      toast.notify("Default email added successfully");
    } catch (error) {
      dispatch(setError());
      changeIsLoadingSaveApp();
      closeModal();
      toast.notify(error.response.message, { type: "error" });
    }
  };
};

export const GetAllMembers = (appId) => {
  return async (dispatch) => {
    try {
      const url = `/tmail/members/list?tmail_app_id=${appId}`;
      const res = await axios.get(url);
    
      dispatch(GetMembers(res.data));
    } catch (error) {
      dispatch(setError());
      toast.notify(error.response.message, { type: "error" });
    }
  };
};

export const GetAllSpaceMembers = (appId) => {
  return async (dispatch) => {
    try {
      const url = `/sync/podio/space-members/list?space_id=${appId}`;
      const res = await axios.get(url);
    
      dispatch(GetSpaceMembers(res.data.members));
    } catch (error) {
      dispatch(setError());
      toast.notify(error.response.message, { type: "error" });
    }
  };
};

export const deleteAppMember = (payload, closeModal, changeLoading) => {
  return async (dispatch) => {
    try {
      const url = "/tmail/members/delete";

      await axios.post(url, payload);
      dispatch(deleteMember(payload.id));
      changeLoading();
      closeModal();
      Swal.fire({
        title: "Member Deleted",
        icon: "success",
        text: "Successfully delete member",
      });
    } catch (error) {
      dispatch(setError());
      closeModal();
      changeLoading();
      toast.notify(error.response.message, { type: "error" });
    }
  };
};

export const createMember = (payload, changeIsLoadingSaveApp, closeModal) => {
  return async (dispatch) => {
    try {
      const url = "/tmail/members/create";
      const res = await axios.post(url, payload);
     
      dispatch(addMember(res.data));
      changeIsLoadingSaveApp();

      toast.notify("Members added successfully");
      closeModal();
    } catch (error) {
      dispatch(setError());
      changeIsLoadingSaveApp();
      toast.notify(error.response.message, { type: "error" });
    }
  };
};

export const createMemberV2 = (payload, changeIsLoadingSaveApp) => {
  return async (dispatch) => {
    try {
      const url = "/tmail/members/create";
      const res = await axios.post(url, payload);
     
      dispatch(addMember(res.data));

      changeIsLoadingSaveApp();

      toast.notify("Members added successfully");
    } catch (error) {
      dispatch(setError());
      changeIsLoadingSaveApp();

      toast.notify(error.response.message, { type: "error" });
    }
  };
};
