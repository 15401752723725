import React, { useState, useEffect } from 'react';
import TextInput from '../../components/atoms/TextInput'
import CustomButton from "../../containers/Website/widgets/buttons/Button";
import { saveCard } from "../../services/sync";
import { toast } from "helpers/apiRequests";
import $ from "jquery";

function changeImpactedElement(tagId, removeClass, addClass) {
    removeClass = removeClass || "";
    addClass = addClass || "";
    $("[data-bluesnap=" + tagId + "]")
        .removeClass(removeClass)
        .addClass(addClass);
}

const bluesnapSetUp = (user) => {

    const script = document.createElement("script");
    script.src = "https://bluesnap.com/web-sdk/4/bluesnap.js"
    // cardUrl: object that stores card type code (received from BlueSnap) and associated card image URL
    const cardUrl = {
        "AMEX": "https://files.readme.io/97e7acc-Amex.png",
        "DINERS": "https://files.readme.io/8c73810-Diners_Club.png",
        "DISCOVER": "https://files.readme.io/caea86d-Discover.png",
        "JCB": "https://files.readme.io/e076aed-JCB.png",
        "MASTERCARD": "https://files.readme.io/5b7b3de-Mastercard.png",
        "VISA": "https://files.readme.io/9018c4f-Visa.png"
    };



    const bsObj = {
        //insert your Hosted Payment Fields token
        token: user.bluesnap_token,
        onFieldEventHandler: {
            onFocus: function (tagId) {
                // Handle focus
                changeImpactedElement(tagId, "", "hosted-field-focus");
            },
            onBlur: function (tagId) {
                // Handle blur
                changeImpactedElement(tagId, "hosted-field-focus");
            },
            onError: function (tagId, errorCode, errorDescription) {
                // Handle a change in validation by displaying help text
                $("#" + tagId + "-help").removeClass('helper-text-green').text(errorDescription);
            },
            onType: function (tagId, cardType, cardData) {
                // get card type from cardType and display card image
                $("#card-logo > img").attr("src", cardUrl[cardType]);
                if (null != cardData) {
                    $("#" + tagId + "-help").addClass('helper-text-green').text("Okay.");
                }
            },
            onValid: function (tagId) {
                // Handle a change in validation by removing any help text
                $("#" + tagId + "-help").text("");
            }
        },
        style: {
            // Styling all inputs
            "input": {
                "font-size": "14px",
                "font-family":
                    "RobotoDraft,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif",
                "line-height": "1.42857143",
                "color": "#555"
            },
            // Styling input state
            ":focus": {
                "color": "#555"
            }
        },
        ccnPlaceHolder: "1234 5678 9012 3456",
        cvvPlaceHolder: "123",
        expPlaceHolder: "MM / YY"
    }
    script.onload = () => {
        window.bluesnap.hostedPaymentFieldsCreate(bsObj);
    }

    document.body.appendChild(script);
}

const NewUserCard = ({ user, loadCards, setCreateNewCard }) => {
    const [createCardLoading, setCreateCardLoading] = useState(false);
    const [cardName, setCardName] = useState("");


    useEffect(() => {
        bluesnapSetUp(user)
    }, [user]);


    /* Calling bluesnap.submitCredentials: function that submits card data to
    BlueSnap and calls input function with card data object if submission was successful */
    const submitCard = (e) => {
        e.preventDefault();
        window.bluesnap.hostedPaymentFieldsSubmitData(
            function (callback) {
                if (null != callback.error) {
                    const errorArray = callback.error;
                    for (const i in errorArray) {
                        $("#" + errorArray[i].tagId + "-help").text(errorArray[i].errorCode + " - " + errorArray[i].errorDescription);
                    }
                } else {
                    setCreateCardLoading(true);
                    const cardData = callback.cardData;
                    const name = cardName;
                    saveCard({
                        name,
                        email: user.email,
                        bluesnap_token: user.bluesnap_token,
                        last_four_digit: cardData.last4Digits,
                        card_brand: cardData.ccType,
                        card_expire: cardData.exp
                    }).then(res => {
                        const response = JSON.parse(res.data);
                        if (typeof response['message'] !== "undefined") {
                            if (Array.isArray(response['message'])) {
                                toast.notify(response.message[0].description);
                                setCreateCardLoading(false);
                                return false;
                            }
                        }
                        toast.notify("Card saved successfully!");
                        loadCards();
                        setCreateCardLoading(false);
                    }).catch(error => {
                        toast.notify("Failed to add card!", { type: "error" });
                        setCreateCardLoading(false);
                    })
                }
            }
        );
    }

    return (
        <div className="card2 payment-method">
            <div className="bold item">Payment Method</div>
            <div className="item">
                <TextInput
                    type="text"
                    placeholder=""
                    label="Name on card"
                    value={cardName}
                    id="cardholder-name"
                    disabled={createCardLoading}
                    onChange={(e) => setCardName(e.target.value)}
                    name="card_name"
                    required={true}
                    styling="font-12 card-payment-input"
                />
            </div>
            <div className="item col-md-12 row mt-2">
                <div className="col-md-10 card-number-section">
                    <label htmlFor="card-number">Card Number</label>
                    <div className="payment-textfield" id="card-number" data-bluesnap="ccn"></div>
                    <span className="helper-text ml-2" id="ccn-help"></span>
                </div>

                <div id="card-logo" className="col-md-2">
                    <img src="https://files.readme.io/d1a25b4-generic-card.png" height="20px" alt="card" />
                </div>
            </div>
            <div className="item double-item">
                <div>
                    <label htmlFor="exp-date">Exp. (MM/YY)</label>
                    <div className="payment-textfield" id="exp-date" data-bluesnap="exp" />
                    <span className="helper-text" id="exp-help" />
                </div>
                <div className="div-inner">
                    <label htmlFor="cvv">CVV</label>
                    <div className="payment-textfield" id="card-number" data-bluesnap="cvv" />
                    <span className="helper-text" id="cvv-help" />
                </div>
            </div>
            <div className="item">
                <CustomButton
                    styling="bg-bd2-2 font-12 button-4"
                    function={submitCard}
                    text={createCardLoading ? "PLEASE WAIT..." : "Save"}
                    disabled={createCardLoading}
                />
                <CustomButton
                    text="Cancel"
                    styling="bg-bd6-6 font-12 button-4"
                    function={() => setCreateNewCard(false)}
                />
            </div>
        </div>
    );
}

export default NewUserCard;