import React, { useState } from 'react'
// components
import Navigation from '../components/Navigation'
import Footer from '../components/Footer'
// widgets
// import ButtonImage from '../widgets/buttons/ButtonImage'
import IntegrationsTab from '../widgets/IntegrationsTab'
import Button from '../widgets/buttons/Button'
// style
import './styles/integrations.scss'
// illustrations
import integrations from '../illustrations/integrations.svg'
// icons
// import button_arrow from '../icons/arrow-bottom.svg'
// import arrow_right from '../icons/arrow-right2.svg'

import podio from '../icons/podio.svg'
import json from '../icons/json.svg'
// import teletrac from '../icons/teletrac.svg'
// import wrike from '../icons/wrike.svg'
import salesforce from '../icons/salesforce.svg'
import shopify from '../icons/shopify.svg'
import pipdrive from '../icons/pipdrive.svg'
import microsoftCRM from '../icons/microsoft-crm.svg'
import salessphere from '../icons/salessphere.svg'
import sugarCRM from '../icons/sugar-crm.svg'
import microsoftNAV from '../icons/microsoft-nav.svg'
import netsuite from '../icons/netsuite.svg'
import sap from '../icons/sap.svg'
import sap2 from '../icons/sap2.svg'
import amazon from '../icons/amazon.svg'
import appdirect from '../icons/appdirect.svg'
import bazaarvoice from '../icons/bazaarvoice.svg'
import channelAdvisor from '../icons/channel-advisor.svg'
import commerceTools from '../icons/commerce-tools.svg'
import demand from '../icons/demand.svg'
import dun from '../icons/dun.svg'
import ebay from '../icons/ebay.svg'
import google from '../icons/google2.svg'
import lightspeed from '../icons/lightspeed.svg'
import magneto from '../icons/magneto.svg'
import oracle from '../icons/oracle.svg'
import shopware from '../icons/shopware.svg'
import walmart from '../icons/walmart.svg'
import goTo from '../icons/goTo.svg'
import mailChimp from '../icons/mailChimp.svg'
import mandrilla from '../icons/mandrilla.svg'
import marketo from '../icons/marketo.svg'
import sdl from '../icons/sdl.svg'
import twitter from '../icons/twitter.svg'
import zoho from '../icons/zoho.svg'
import exact from '../icons/exact.svg'
import quickbooks from '../icons/quickbooks.svg'
import stripe from '../icons/stripe.svg'
import allmysms from '../icons/allmysms.svg'
import aws from '../icons/aws.svg'
import googleTranslate from '../icons/googleTranslate.svg'
import keenio from '../icons/keenio.svg'
import googleSpreadsheets from '../icons/googleSpreadsheets.svg'
import microsoftDrive from '../icons/microsoftDrive.svg'
import outlook from '../icons/outlook.svg'
import bigQuery from '../icons/bigQuery.svg'
import JDBC from '../icons/JDBC.svg'
import mongoDB from '../icons/mongoDB.svg'
import msSQL from '../icons/msSQL.svg'
import postgreSQL from '../icons/postgreSQL.svg'
import AMQP from '../icons/AMQP.svg'
import AWS2 from '../icons/AWS2.svg'
import AWS3 from '../icons/AWS3.svg'
import CSV from '../icons/CSV.svg'
import edifact from '../icons/edifact.svg'
import googlePub from '../icons/googlePub.svg'
import ldap from '../icons/ldap.svg'
import ODATA from '../icons/ODATA.svg'
import pub from '../icons/pub.svg'
import SFTP from '../icons/SFTP.svg'
import SOAP from '../icons/SOAP.svg'
import XML from '../icons/XML.svg'
import batch from '../icons/batch.svg'
import code from '../icons/code.svg'
import configuration from '../icons/configuration.svg'
import email from '../icons/email.svg'
import filter from '../icons/filter.svg'
import jsonata from '../icons/jsonata.svg'
import storage from '../icons/storage.svg'
import lookup from '../icons/lookup.svg'
import mapper from '../icons/mapper.svg'
import openAPI from '../icons/openAPI.svg'
import petstore from '../icons/petstore.svg'
import request from '../icons/request.svg'
import rest from '../icons/rest.svg'
import router from '../icons/router.svg'
import simple from '../icons/simple.svg'
import splitter from '../icons/splitter.svg'
import timer from '../icons/timer.svg'
import utility from '../icons/utility.svg'
import webhook from '../icons/webhook.svg'
import zip from '../icons/zip.svg'
// data
const syncData = [
    {
        name: "Podio",
        image: podio,
        available: true
    },
    {
        name: 'MySQL',
        image: 'https://www.mysql.com/common/logos/logo-mysql-170x115.png',
        available: true
    },
    {
        name: 'MongoDB',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjI_x00XRBoYsPMThAIHxhDbJOOqE4XYGmTW7XiOC-SA&s',
        available: true
    },
    {
        name: 'PostgreSQL',
        image: 'https://www.vectorlogo.zone/logos/postgresql/postgresql-icon.svg',
        available: true
    },
    {
        name: 'MS SQL',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcumx9zozn1jRcDSwGznsWWp_PXI22hN99CI9CvIjV&s',
        available: true
    },
    {
        name: "JSON",
        image: json,
        available: false
    },
    {
        name: 'RightSignature',
        image: 'https://media.tekpon.com/2023/08/RightSignature-Favicon.webp',
        available: true
    },
    {
        name: 'ShareFile',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqpj7K8KPG_lxpa8UetufaC6Vy1qD8oBrqbeqvk8U&s',
        available: true
    },
    // {
    //     name: "Teletrac",
    //     image: teletrac,
    //     available: false
    // },
    // {
    //     name: "Shopify",
    //     image: shopify,
    //     available: false
    // },
    // {
    //     name: "Salesforce",
    //     image: salesforce,
    //     available: false
    // },
    // {
    //     name: "Wrike",
    //     image: wrike,
    //     available: false
    // },
]

const printData = [
    {
        name: "Podio",
        image: podio,
        available: true
    },
    // {
    //     name: "Teletrac",
    //     image: teletrac,
    //     available: false
    // },
    // {
    //     name: "Shopify",
    //     image: shopify,
    //     available: false
    // },
    // {
    //     name: "Salesforce",
    //     image: salesforce,
    //     available: false
    // },
    // {
    //     name: "Wrike",
    //     image: wrike,
    //     available: false
    // },
    {
        name: 'RightSignature',
        image: 'https://media.tekpon.com/2023/08/RightSignature-Favicon.webp',
        available: true
    },
    {
        name: 'ShareFile',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqpj7K8KPG_lxpa8UetufaC6Vy1qD8oBrqbeqvk8U&s',
        available: true
    },
    {
        name: "JSON",
        image: json,
        available: false
    },
]

const avaData = [
    {
        name: "Microsoft CRM",
        image: microsoftCRM,
        available: true,
        link: "https://docs.thatapp.io/components/msdynamics-crm/index.html"
    },
    {
        name: "Pipdrive",
        image: pipdrive,
        available: true,
        link: "https://docs.thatapp.io/components/pipedrive/index.html"
    },
    {
        name: "Salesforce-CQP",
        image: salesforce,
        available: true,
        link: "https://docs.thatapp.io/components/salesforce-cpq/index.html"
    },
    {
        name: "Salesforce",
        image: salesforce,
        available: true,
        link: "https://docs.thatapp.io/components/salesforce/index.html"
    },
    {
        name: "SalesSphere",
        image: salessphere,
        available: true,
        link: "https://docs.thatapp.io/components/salesphere/index.html"
    },
    {
        name: "Sugar CRM",
        image: sugarCRM,
        available: true,
        link: "https://docs.thatapp.io/components/sugarcrm/index.html"
    },
    {
        name: "Microsoft NAV OData",
        image: microsoftNAV,
        available: true,
        link: "https://docs.thatapp.io/components/microsoft-dynamics-nav-odata/index.html"
    },
    {
        name: "Microsoft NAV",
        image: microsoftNAV,
        available: true,
        link: "https://docs.thatapp.io/components/microsoft-dynamics-nav/index.html"
    },
    {
        name: "NetSuite",
        image: netsuite,
        available: true,
        link: "https://docs.thatapp.io/components/netsuite/index.html"
    },
    {
        name: "SAP ByDesign",
        image: sap,
        available: true,
        link: "https://docs.thatapp.io/components/sap-bydesign/index.html"
    },
    {
        name: "SAP ECC (R/3, ERP)",
        image: sap2,
        available: true,
        link: "https://docs.thatapp.io/components/sap-r3/index.html"
    },
    {
        name: "Amazon MWS",
        image: amazon,
        available: true,
        link: "https://docs.thatapp.io/components/amazon-mws/index.html"
    },
    {
        name: "AppDirect",
        image: appdirect,
        available: true,
        link: "https://docs.thatapp.io/components/appdirect/index.html"
    },
    {
        name: "Bazaarvoice",
        image: bazaarvoice,
        available: true,
        link: "https://docs.thatapp.io/components/bazaarvoice/index.html"
    },
    {
        name: "Channel Advisor",
        image: channelAdvisor,
        available: true,
        link: "https://docs.thatapp.io/components/channelgitisor/index.html"
    },
    {
        name: "Commerce Tools",
        image: commerceTools,
        available: true,
        link: "https://docs.thatapp.io/components/commercetools/index.html"
    },
    {
        name: "Demand Ware",
        image: demand,
        available: true,
        link: "https://docs.thatapp.io/components/demandware/index.html"
    },
    {
        name: "Dun and Broadstreet",
        image: dun,
        available: true,
        link: "https://docs.thatapp.io/components/dun-and-bradstreet/index.html"
    },
    {
        name: "Ebay",
        image: ebay,
        available: true,
        link: "https://docs.thatapp.io/components/ebay/index.html"
    },
    {
        name: "Google Shopping",
        image: google,
        available: true,
        link: "https://docs.thatapp.io/components/google-shopping/index.html"
    },
    {
        name: "Lightspeed-Ecom",
        image: lightspeed,
        available: true,
        link: "https://docs.thatapp.io/components/lightspeed-ecom/index.html"
    },
    {
        name: "Lightspeed-Retail",
        image: lightspeed,
        available: true,
        link: "https://docs.thatapp.io/components/lightspeed-retail/index.html"
    },
    {
        name: "Magneto 1",
        image: magneto,
        available: true,
        link: "https://docs.thatapp.io/components/magento1/index.html"
    },
    {
        name: "Magneto 2",
        image: magneto,
        available: true,
        link: "https://docs.thatapp.io/components/magento2/index.html"
    },
    {
        name: "Oracle E-Business Suite",
        image: oracle,
        available: true,
        link: "https://docs.thatapp.io/components/oracle-ebs/index.html"
    },
    {
        name: "Shopify Admin",
        image: shopify,
        available: true,
        link: "https://docs.thatapp.io/components/shopify-admin/index.html"
    },
    {
        name: "Shopware",
        image: shopware,
        available: true,
        link: "https://docs.thatapp.io/components/shopware/index.html"
    },
    {
        name: "Walmart SC",
        image: walmart,
        available: true,
        link: "https://docs.thatapp.io/components/walmart-sc/index.html"
    },
    {
        name: "Go-to Webinar",
        image: goTo,
        available: true,
        link: "https://docs.thatapp.io/components/goto-webinar/index.html"
    },
    {
        name: "MailChimp",
        image: mailChimp,
        available: true,
        link: "https://docs.thatapp.io/components/mailchimp/index.html"
    },
    {
        name: "Mandrilla App",
        image: mandrilla,
        available: true,
        link: "https://docs.thatapp.io/components/mandrillapp/index.html"
    },
    {
        name: "Marketo",
        image: marketo,
        available: true,
        link: "https://docs.thatapp.io/components/marketo/index.html"
    },
    {
        name: "SDL-Translate",
        image: sdl,
        available: true,
        link: "https://docs.thatapp.io/components/sdl-translate/index.html"
    },
    {
        name: "Twitter",
        image: twitter,
        available: true,
        link: "https://docs.thatapp.io/components/twitter/index.html"
    },
    {
        name: "Zoho Subscriptions",
        image: zoho,
        available: true,
        link: "https://docs.thatapp.io/components/zoho/index.html"
    },
    {
        name: "Exact Online",
        image: exact,
        available: true,
        link: "https://docs.thatapp.io/components/exact-online/index.html"
    },
    {
        name: "SDL-Translate",
        image: sdl,
        available: true,
        link: "https://docs.thatapp.io/components/sdl-translate/index.html"
    },
    {
        name: "Quickbooks",
        image: quickbooks,
        available: true,
        link: "https://docs.thatapp.io/components/quickbooks/index.html"
    },
    {
        name: "Stripe",
        image: stripe,
        available: true,
        link: "https://docs.thatapp.io/components/stripe/index.html"
    },
    {
        name: "Allmysms",
        image: allmysms,
        available: true,
        link: "https://docs.thatapp.io/components/allmysms/index.html"
    },
    {
        name: "AWS Lambda",
        image: aws,
        available: true,
        link: "https://docs.thatapp.io/components/aws-lambda/index.html"
    },
    {
        name: "Google Translate",
        image: googleTranslate,
        available: true,
        link: "https://docs.thatapp.io/components/google-translate/index.html"
    },
    {
        name: "JDE E Orchestrator",
        image: oracle,
        available: true,
        link: "https://docs.thatapp.io/components/jde-e1/index.html"
    },
    {
        name: "Keenio",
        image: keenio,
        available: true,
        link: "https://docs.thatapp.io/components/keenio/index.html"
    },
    {
        name: "Google Spreadsheets",
        image: googleSpreadsheets,
        available: true,
        link: "https://docs.thatapp.io/components/gspreadsheet/index.html"
    },
    {
        name: "Microsoft OneDrive",
        image: microsoftDrive,
        available: true,
        link: "https://docs.thatapp.io/components/onedrive/index.html"
    },
    {
        name: "Outlook",
        image: outlook,
        available: true,
        link: "https://docs.thatapp.io/components/outlook/index.html"
    },
    {
        name: "Google BigQuery",
        image: bigQuery,
        available: true,
        link: "https://docs.thatapp.io/components/google-bigquery/index.html"
    },
    {
        name: "JDBC",
        image: JDBC,
        available: true,
        link: "https://docs.thatapp.io/components/jdbc/index.html"
    },
    {
        name: "MongoDB",
        image: mongoDB,
        available: true,
        link: "https://docs.thatapp.io/components/mongodb/index.html"
    },
    {
        name: "MsSQL",
        image: msSQL,
        available: true,
        link: "https://docs.thatapp.io/components/mssql/index.html"
    },
    {
        name: "PostgreSQL",
        image: postgreSQL,
        available: true,
        link: "https://docs.thatapp.io/components/postgresql/index.html"
    },
    {
        name: "AMQP",
        image: AMQP,
        available: true,
        link: "https://docs.thatapp.io/components/amqp/index.html"
    },
    {
        name: "AWS S3",
        image: AWS2,
        available: true,
        link: "https://docs.thatapp.io/components/aws-s3/index.html"
    },
    {
        name: "AWS SNS",
        image: AWS3,
        available: true,
        link: "https://docs.thatapp.io/components/aws-sns/index.html"
    },
    {
        name: "CSV",
        image: CSV,
        available: true,
        link: "https://docs.thatapp.io/components/csv/index.html"
    },
    {
        name: "Edifact-parser",
        image: edifact,
        available: true,
        link: "https://docs.thatapp.io/components/edifact-parser/index.html"
    },
    {
        name: "Google Pub Sub",
        image: googlePub,
        available: true,
        link: "https://docs.thatapp.io/components/google-pubsub/index.html"
    },
    {
        name: "Ldap",
        image: ldap,
        available: true,
        link: "https://docs.thatapp.io/components/ldap/index.html"
    },
    {
        name: "ODATA",
        image: ODATA,
        available: true,
        link: "https://docs.thatapp.io/components/odata/index.html"
    },
    {
        name: "Pub-Sub",
        image: pub,
        available: true,
        link: "https://docs.thatapp.io/components/pub-sub/index.html"
    },
    {
        name: "SFTP",
        image: SFTP,
        available: true,
        link: "https://docs.thatapp.io/components/sftp/index.html"
    },
    {
        name: "SOAP",
        image: SOAP,
        available: true,
        link: "https://docs.thatapp.io/components/soap/index.html"
    },
    {
        name: "XML",
        image: XML,
        available: true,
        link: "https://docs.thatapp.io/components/xml/index.html"
    },
    {
        name: "Batch",
        image: batch,
        available: true,
        link: "https://docs.thatapp.io/components/batch/index.html"
    },
    {
        name: "Code",
        image: code,
        available: true,
        link: "https://docs.thatapp.io/components/code/index.html"
    },
    {
        name: "Configuration",
        image: configuration,
        available: true,
        link: "https://docs.thatapp.io/components/configuration/index.html"
    },
    {
        name: "Email",
        image: email,
        available: true,
        link: "https://docs.thatapp.io/components/email/index.html"
    },
    {
        name: "Filter",
        image: filter,
        available: true,
        link: "https://docs.thatapp.io/components/filter/index.html"
    },
    {
        name: "Jsonata",
        image: jsonata,
        available: true,
        link: "https://docs.thatapp.io/components/jsonata/index.html"
    },
    {
        name: "Simple Storage",
        image: storage,
        available: true,
        link: "https://docs.thatapp.io/components/key-value/index.html"
    },
    {
        name: "Lookup Table",
        image: lookup,
        available: true,
        link: "https://docs.thatapp.io/components/lookup-table/index.html"
    },
    {
        name: "Mapper",
        image: mapper,
        available: true,
        link: "https://docs.thatapp.io/components/mapper/index.html"
    },
    {
        name: "Open API",
        image: openAPI,
        available: true,
        link: "https://docs.thatapp.io/components/open-api/index.html"
    },
    {
        name: "Petstore-nodejs",
        image: petstore,
        available: true,
        link: "https://docs.thatapp.io/components/petstore-nodejs/index.html"
    },
    {
        name: "Request-reply",
        image: request,
        available: true,
        link: "https://docs.thatapp.io/components/request-reply/index.html"
    },
    {
        name: "REST API",
        image: rest,
        available: true,
        link: "https://docs.thatapp.io/components/rest-api/index.html"
    },
    {
        name: "Router",
        image: router,
        available: true,
        link: "https://docs.thatapp.io/components/router/index.html"
    },
    {
        name: "Simple-trigger",
        image: simple,
        available: true,
        link: "https://docs.thatapp.io/components/simple-trigger/index.html"
    },
    {
        name: "Splitter",
        image: splitter,
        available: true,
        link: "https://docs.thatapp.io/components/splitter/index.html"
    },
    {
        name: "Timer",
        image: timer,
        available: true,
        link: "https://docs.thatapp.io/components/timer/index.html"
    },
    {
        name: "Utility",
        image: utility,
        available: true,
        link: "https://docs.thatapp.io/components/utility/index.html"
    },
    {
        name: "Webhook",
        image: webhook,
        available: true,
        link: "https://docs.thatapp.io/components/webhook/index.html"
    },
    {
        name: "ZIP/UnZIP",
        image: zip,
        available: true,
        link: "https://docs.thatapp.io/components/zip/index.html"
    },
]

const Integrations = () => {
    const [active, setActive] = useState("sync")
    const [syncArray, setSyncArray] = useState(false)
    const [printArray, setPrintArray] = useState(false)
    const [avaArray, setAvaArray] = useState(false)
    return <div className="integrations">
        <Navigation btnBg="bg-bd1-1" color="color-1" />
        <section className="landing bg-2 double-container">
            <div>
                <div className="font-48 color-1">With app integrations, you get more control</div>
                <div className="color-black-2">Gain a refreshing perspective of your business processes and strategic initiatives through the eyes of our skilled workflow architects and system integrators. </div>
                {/* <ButtonImage 
                    text="See all Features"
                    img={button_arrow}
                    styling="bg-bd0-none color-1 button-3"
                /> */}
            </div>
            <div><img src={integrations} alt="integrations" /></div>
        </section>
        <section className="features">
            <div>
                <div className="head">
                    <div className="font-32 bolder">Features to automate your workflow seamlessly</div>
                    <div className="color-black-2 subtitle">It's our job to ensure that you're never lost in the clouds. Gain a refreshing perspective of your business processes and strategic initiatives.</div>
                    <div className="integrations-navigation font-18 color-5">
                        <span className={active === "sync" && "active"} 
                            onClick={() => setActive("sync")}
                        >SYNC</span>
                        <span className={active === "ava" && "active"} 
                            onClick={() => setActive("ava")}
                        >AVA</span>
                        <span className={active === "print" && "active"} 
                            onClick={() => setActive("print")}
                        >PRINT</span>
                    </div>
                </div>

                {active === "sync" && <div>
                    <div className="quadruple-container">
                        {
                            syncData.length > 12 && !syncArray 
                            ? syncData.slice(0, 12).map(item => {
                                return <IntegrationsTab 
                                    image={item.image}
                                    name={item.name}
                                    available={item.available}
                                />
                            })
                            : syncData.map(item => {
                                return <IntegrationsTab 
                                    image={item.image}
                                    name={item.name}
                                    available={item.available}
                                />
                            })
                        }
                    </div>
                    {
                        syncData.length > 12 && <div className="button-container">
                            <span className="link22" onClick={() => setSyncArray(!syncArray)}>
                                <Button 
                                    text={syncArray ? "See Less" : "See More"}
                                    styling="bg-bd2-none"
                                />
                            </span>
                        </div>
                    }
                </div>}

                {active === "print" && <div>
                    <div className="quadruple-container">
                        {
                            printData.length > 12 && !printArray 
                            ? printData.slice(0, 12).map(item => {
                                return <IntegrationsTab 
                                    image={item.image}
                                    name={item.name}
                                    available={item.available}
                                />
                            })
                            : printData.map(item => {
                                return <IntegrationsTab 
                                    image={item.image}
                                    name={item.name}
                                    available={item.available}
                                />
                            })
                        }
                    </div>
                    {
                        printData.length > 12 && <div className="button-container">
                            <span className="link22" onClick={() => setPrintArray(!printArray)}>
                                <Button 
                                    text={printArray ? "See Less" : "See More"}
                                    styling="bg-bd2-none"
                                />
                            </span>
                        </div>
                    }
                </div>}

                {active === "ava" && <div>
                    <div className="quadruple-container">
                        {
                            avaData.length > 12 && !avaArray 
                            ? avaData.slice(0, 12).map(item => {
                                return <a className="link22 link222" href={item.link} target="_blank" rel="noopener noreferrer">
                                    <IntegrationsTab 
                                        image={item.image}
                                        name={item.name}
                                        available={item.available}
                                    />
                                </a>
                            })
                            : avaData.map(item => {
                                return  <a className="link22 link222" href={item.link} target="_blank" rel="noopener noreferrer">
                                    <IntegrationsTab 
                                        image={item.image}
                                        name={item.name}
                                        available={item.available}
                                    />
                                </a>
                            })
                        }
                    </div>
                    {
                        avaData.length > 12 && <div className="button-container">
                            <span className="link22" onClick={() => setAvaArray(!avaArray)}>
                                <Button 
                                    text={avaArray ? "See Less" : "See More"}
                                    styling="bg-bd2-none"
                                />
                            </span>
                        </div>
                    }
                </div>}
            </div>
        </section>
        <Footer bg="bg-3" color="color-1" />
    </div>
}

export default Integrations