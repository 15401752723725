import "./ConfirmModal.css";
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
// import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import Button from "../atoms/Button";
import cancel from "../../assets/icons/cancel.svg";
import info from "../../assets/icons/info.svg";
import redC from "../../assets/icons/redCaution.svg";

const useStyles = makeStyles(() => ({
  dFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "1.5rem",
  },
  button: {
    marginTop: 20,
    background: "#2871B6",
    color: "#fff",
    border: "none",
    width: "100%",
    padding: "10px 30px",
    borderRadius: "4px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#2871B6",
    },
  },
  cancel: {
    color: "#2871B6",
    marginTop: 10,
    cursor: "pointer",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },

  modalHeader: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "5% 5% 0 5%",
  },
  headerText: {
    color: "black",
  },
  btnContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  btnDesc: {
    color: "#DE911D",
    marginTop: "3%",
  },
}));

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function CustomizedDialogs({
  openModal,
  closeModal,
  title,
  message,
  action,
  toggleModal,
  modalLoader,
}) {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={openModal}
        onClose={closeModal}
      >
        <div className={classes.modalHeader}>
          <div>
            <img onClick={toggleModal} alt="cancel" src={cancel} />
          </div>
        </div>

        <div className="text-center mt-3 mb-3">
          {message && message.includes("Delete") ? (
            <img alt="info" src={redC} />
          ) : (
            <img alt="info" src={info} />
          )}
        </div>
        <DialogContent
          style={{ justifyContent: "center", display: "flex", padding: "0" }}
        >
          <p
            style={{
              fontSize: "14px",
              fontWeight: "600",
              color: "black",
              width: "350px",
              textAlign: "center",
              marginBottom: "0",
            }}
          >
            {title}
          </p>
        </DialogContent>
        <DialogContent style={{ justifyContent: "center", display: "flex" }}>
          <p
            style={{
              fontSize: "14px",
              fontWeight: "400",
              color: "black",
              width: "80%",
              textAlign: "center",
              marginBottom: "0",
            }}
          >
            {message}
          </p>
        </DialogContent>

        <DialogActions>
          <div
            style={{ textAlign: "center", width: "100%" }}
            className="mt-2 pb-4"
          >
            <Button
              variant="outlined"
              style={
                message && message.includes("Delete")
                  ? { backgroundColor: "#E12D39", color: "#fff" }
                  : { backgroundColor: "#F0B429", color: "#fff" }
              }
              // style={
              //   modalLoader
              //     ? { background: `grey`, color: `#000` }
              //     : { background: `#F7C948`, color: `#fff` }
              // }
              className={classes.button}
              function={() => action()}
              text={modalLoader ? "Please Wait..." : "Proceed"}
            />
          </div>
          {/* {modalLoader && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )} */}
        </DialogActions>
      </Dialog>
    </div>
  );
}
