/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { AddCircleOutline } from '@material-ui/icons'
import IconList from './IconList';
import ShareImg from "../../../assets/images/shareIcon.png";
import { ADD_APP_V2 } from "../../../containers/pagePath";
import { GetAllApps } from "../../../redux/actions/tmail";
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    backButton: {
        backgroundColor: "#f7c948",
        boxShadow: "0px 3px 6px #00000029",
        padding: "1vh 1vw",
        outline: "none",
        textDecoration: "none",
        border: "none",
        borderRadius: "6px",
        color: "white",
        marginBottom: "1%"
      }
}));

const Apps = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(GetAllApps())
    }, []);
    const user = useSelector((state) => state.user.authUser);
    const apps = useSelector((state) => state.tmail.apps);
    const imgContainer = {
        borderRadius: "50%",
        background: "#FA5600",
        width: "70px",
        height: "70px",
        paddingTop: "10px",
        textAlign: "center",
        margin: "auto"
    };

    const handleBackButtonClick = () => {
        window.history.back();
      };
    return (
        <div className='mt-4'>
        <div>
        <button onClick={handleBackButtonClick} className={classes.backButton}>
          Back
        </button>
      </div>
            <div className="d-flex justify-content-between">
                <div className="">
                    <h4>Connected Podio Apps</h4>
                </div>
                <div className="">
                    <Link to={ADD_APP_V2} className="btn btn-dark"><AddCircleOutline />  Add New</Link>
                </div>
            </div>
            <div className="row mt-4 justify-content-center">
                <div className="col-md-12">
                    <div className="alert alert-primary" role="alert">
                        This is a primary alert—check it out!
                    </div>
                </div>
            </div>
            <div className="row mt-5">
                {
                    apps.length > 0 ? apps.map(app => {
                        return (
                            <div className="col-md-4" key={app.app_id}>
                                <div className="card">
                                    <div className="row">
                                        <div className="col-md-8">
                                            <div
                                                className="applist-app-container relative text-center pt-4 rounded"
                                            >
                                                <center style={imgContainer}>
                                                    {/* <Share className='p-2vh2' /> */}
                                                    <img src={ShareImg} width={50} alt="" />

                                                </center>
                                                <h5 className="mt-2 text-muted">{app.app_name}</h5>
                                                <span className="d-block" style={{color: "#FA5600"}}>{app.totalItem} {app.totalItem < 1 ? "item" : "items"}</span>
                                                <span className="d-block mb-2 text-muted">test@thatapp.io</span>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <IconList app={app} />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )
                    }) : <h3 className="text-center">No Podio App Connected</h3>
                }

            </div>
        </div>
    )
}

export default Apps