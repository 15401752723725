const host = process.env.REACT_APP_BASEURL;
const baseUrl = process.env.REACT_APP_BASEURL;
const firebaseService = "https://thatapp-c5162.firebaseio.com/";
const Routes = {
  podioAccounts: host + "/sync/podio/orgs/all?token=", //append the token
  synced: host + "/sync/podio/orgs/synced?token=",
  unsynced: host + "/sync/podio/orgs/unsynced?token=", //append token
  syncAnOrganization: host + "/sync/podio/org?token=", //append the token and org id
  getSyncingStatusFromFireBase: firebaseService, //append the org id
};

export { baseUrl, Routes };
