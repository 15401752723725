import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import styles from './fields.module.scss';
import Switch from '@material-ui/core/Switch';

export const CustomSlider = withStyles({
  root: {
    color: '#28a745',
    height: 5,
  },
  thumb: {
    height: 15,
    width: 15,
    border: '2px solid currentColor',
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 5,
    borderRadius: 4,
  },
  rail: {
    height: 5,
    borderRadius: 4,
  },
})(Slider);

export const CustomSwitch = withStyles({
  root: {},
  rail: {
    height: 5,
    borderRadius: 4,
  },
})(Switch);

export function DimensionField({ name, dimension = 'PX', label, type = 'number', defaultValue = 10, ...props }) {
  return (
    <fieldset className={styles.dimensionFields}>
      <label htmlFor={name} className={styles.dimensionFields.label}>
        {label}
      </label>
      <span>
        <input className={styles.dimensionFields.input} type={type} name={name} id={name} {...props} />
        <p>{dimension}</p>
      </span>
    </fieldset>
  );
}

export function PaperField({ name, options, label, ...props }) {
  return (
    <fieldset>
      <label htmlFor={name} className={styles.label}>
        {label}
      </label>
      <select className={styles.select} name={name} id={name} {...props}>
        {options.map((option) => (
          <option value={option} key={option}>
            &nbsp;&nbsp;&nbsp;{option}
          </option>
        ))}
      </select>
    </fieldset>
  );
}

export function Select({ options, handleChange, label = '', placeHolder, value, disabled }) {
  function onChange(e) {
    handleChange(e);
  }
  return (
    <fieldset className={styles.items_select}>
      <label htmlFor={label}>{label}</label>
      <select name='item' id='item-select' className={styles.select} onChange={onChange} value={value} disabled={disabled}>
        <option value=''>{placeHolder}</option>
        {options?.length > 0
          ? options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))
          : null}
      </select>
    </fieldset>
  );
}
