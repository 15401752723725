import { Button } from "@material-ui/core";
import React, { useState } from "react";
import ModulesLayout from "../common/ModulesLayout";
import { makeStyles } from "@material-ui/core/styles";
import AppToWorkModal from "../modals/AppToWorkModal";
import transferFiles from "../../../assets/images/Transfer-files-amico.svg";
// import AppList from "../components/AppList";
import { useSelector } from "react-redux";
import { toast } from "helpers/apiRequests";
import { processApps } from "../services/clone";
import Cart from "./Cart";

const useStyles = makeStyles((theme) => ({
  layoutContainer: {
    margin: "111px auto",
    textAlign: "center",
    maxWidth: "868px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  btn: {
    height: "44px",
    width: "234px",
    backgroundColor: "#F0B429",
    borderRadius: "3px",
    textTransform: "capitalize",
  },
  title: {
    fontSize: "24px",
    fontWeight: "600",
    fontStyle: "normal",
    lineHeight: "normal",
    color: "#3B3B3B",
    marginBottom: "10px",
  },
  text: {
    color: "#626262",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "25px",
    marginBottom: "42px",
  },
  border: {
    width: "100%",
    height: "1px",
    background: "#E1E1E1",
    margin: "32px 0",
  },
  th: {
    border: "0px",
    borderBottom: "1px solid #E1E1E1",
    paddingTop: "21px",
    paddingBottom: "21px",
    color: "#222",
    fontWeight: "600",
    fontSize: "14px",
  },
  td: {
    border: "0px",
    borderBottom: "1px solid #E1E1E1",
    paddingTop: "21px",
    paddingBottom: "21px",
    color: "#626262",
    fontWeight: "400",
    fontSize: "16px",
  },
  tableHeaderRow: {
    alignItems: "center",
    backgroundColor: "#F7F7F7",
  },
  tableBodyRow: {
    alignItems: "center",
    backgroundColor: "#fff",
    border: "none",
  },
  checkInput: {
    width: "18px",
    height: "18px",
    borderRadius: "4px",
    appearance: "auto",
  },
  appLayoutContainer: {
    width: "1070px",
    margin: "0px auto",
    marginTop: "111px",
    marginBottom: "111px",
    textAlign: "center",
  },
  btnWhiteText: {
    backgroundColor: "#F0B429",
    color: "#fff !important",
    borderRadius: "3px",
    textTransform: "capitalize",
    padding: "12px 32px",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "normal",
  },
  btnCancel: {
    color: "#626262",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    padding: "0px",
    background: "transparent",
    border: "none",
  },
  btnGroup: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: "78px",
    gap: "32px",
  },
  logBtn: {
    height: "44px",
    width: "170px",
    backgroundColor: "#FFF3C4",
    borderRadius: "3px",
    textTransform: "capitalize",
    color: "#DE911D !important",
    fontSize: "16px",
    fontWeight: "600",
    position: "absolute",
    top: "111px",
    right: "71px",
  },
  [theme.breakpoints.down("md")]: {
    appLayoutContainer: {
      width: "100%",
    },
  },
  [theme.breakpoints.down("sm")]: {
    checkInput: {
      marginBottom: "0px",
    },
  },
}));

const initialState = {
  source_org_id: "",
  source_space_id: "",
  destination_org_id: "",
  destination_space_id: "",
  source_app_ids: [],
};

const AppToWork = ({ showActivities }) => {
  const classes = useStyles();
  const { sourceApps } = useSelector((state) => state.clone);
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ ...initialState });
  const [responseData, setResponseData] = useState(null);
  const [orgNameInfo, setOrgNameInfo] = useState("");


  const handleClose = () => {
    setOpen(false);
  };

  const goToNext = (orgInfoData) => {
    if (!data.source_org_id || data.source_org_id === "") {
      toast.notify("Please select the source organisation", { type: "error" });
    }

    if (!data.source_space_id || data.source_space_id === "") {
      toast.notify("Please select the source workspace", { type: "error" });
    }

    if (!data.destination_org_id || data.destination_org_id === "") {
      toast.notify("Please select the destination organisation", {
        type: "error",
      });
    }

    if (!data.destination_space_id || data.destination_space_id === "") {
      toast.notify("Please select the destination workspace", {
        type: "error",
      });
    }

    setOrgNameInfo(orgInfoData);

    if (
      data.source_org_id &&
      data.destination_org_id &&
      data.source_space_id &&
      data.destination_space_id
    ) {
      setOpen(false);
      setStep(2);
    }
  };

  const submitData = async () => {
    if (data.source_app_ids && data.source_app_ids.length < 1) {
      toast.notify("Please select the apps you want to copy", {
        type: "error",
      });
    } else {
      setLoading(true);

      try {
        const response = await processApps(data);
        if (response.success) {
          setResponseData(response.data);
          toast.notify(response.message, { type: "success" });
          return true
        }
      } catch (ex) {
      
      } finally {
        // setData(initialState);
        setLoading(false);
        // setStep(3);
        // showActivities()
      }
      return false
    }
  };

  return (
    <ModulesLayout>
      {step === 1 && (
        <div className={classes.layoutContainer}>
          <Button
            variant="contained"
            color="primary"
            disableElevation={true}
            onClick={showActivities}
            className={classes.logBtn}
          >
            Activity Log
          </Button>
          <img src={transferFiles} width="306" alt="" />
          <h4 className={classes.title}>
            Copy Data from Apps to another space
          </h4>
          <p className={classes.text}>
            Pick one or more applications from a specific space, then
            proceed to copy and transplant them into an destination space of
            your choice. This method enables the efficient extraction,
            replication, and placement of selected applications
          </p>
          <Button
            variant="contained"
            color="primary"
            disableElevation={true}
            className={classes.btn}
            onClick={() => setOpen(true)}
          >
            Copy Data
          </Button>
        </div>
      )}

      {/* {step === 2 && <AppList apps={sourceApps} {...{setStep, data, setData, submitData, loading}} />} */}
      {step === 2 && <Cart module='app-to-work' data={data.source_app_ids} {...{setData, responseData, submitData, showActivities, setStep, loading, orgInfoData: orgNameInfo}} sourceData={sourceApps} />}

      <AppToWorkModal {...{ data, setData, open, handleClose, goToNext }} />
    </ModulesLayout>
  );
};

export default AppToWork;
