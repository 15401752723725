import React from 'react';

import useAsync from 'components/templates/print-v2/hooks/useAsync';
import useTemplateDetails from 'components/templates/print-v2/hooks/useTemplateDetails';
import ExpansionGroup from '../fields/ExpansionGroup';
import { Select } from '../fields/fields';
import CreateList from './CreateList/CreateList';
import CreateTable from './CreateTable/CreateTable';
import CustomAlert from '../../../components/CustomAlert';
import { usePrintData } from 'components/templates/print-v2/context/PrintProvider';

import styles from './LeftSideBar.module.scss';
import { usePrintLoadingContext } from 'context/LoadingContext/PrintLoadingContext';
import { ThemedAsyncReactSelect } from 'components/templates/print-v2/components/ThemedReactSelect';

import { getIncomingRelationshipsVariables, getItems, getTemplateVariables, updateTemplate } from '../../../printUtils/requests';
import { toast } from 'helpers/apiRequests';

const LeftSideBar = (props) => {
  const { orgsData, spaceData, appData, selectedOrganization, selectedWorkspace, selectedApplication, handleOrgs, handleApps, selectApp } =
    useTemplateDetails();
  const { template, selectedItem, setSelectedItem } = usePrintData();

  const template_id = React.useMemo(() => template?.id ?? null, [template?.id]);
  const app_id = React.useMemo(() => template?.podio_options?.app_id ?? null, [template?.podio_options?.app_id]);

  const [showTemplateDetails, setshowTemplateDetails] = React.useState(false);
  const [templateDetails, setTemplateDetails] = React.useState({
    organization: '',
    workspace: '',
    app: '',
  });

  React.useEffect(() => {
    if (template) {
      const metadata = template?.podio_options?.metadata;

      setTemplateDetails({
        organization: metadata?.organization?.name,
        workspace: metadata?.workspace?.name,
        app: metadata?.app?.name,
      });
    }
  }, [template]);

  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    if (app_id) {
      getItems({ app_id, updater: setItems });
    }
  }, [app_id]);

  function handleItemSearch(query) {
    if (app_id) {
      return getItems({ app_id, queryString: query });
    }
  }

  const [variables, setVariables] = React.useState([]);

  function fetchVariables() {
    const payload = {
      template_id: template_id,
    };

    if (selectedItem?.value) {
      payload.item_id = selectedItem?.value;
    }

    return getTemplateVariables(payload, setVariables);
  }

  const { execute, status } = useAsync(fetchVariables, false);
  const { setIsLoading } = usePrintLoadingContext();

  React.useEffect(() => {
    if (status === 'pending') {
      setIsLoading(true);
    } else if (status === 'success') {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const [incomingRelationships, setIncomingRelationships] = React.useState([
    {
      label: 'Incoming Relationships',
      items: [],
      hint: 'To get a list of all Incoming Relationships, please, select an item first',
    },
  ]);

  function fetchIncomingRelationships() {
    return getIncomingRelationshipsVariables(selectedItem?.value).then((res) => {
      setIncomingRelationships((previousValues) => {
        const newValues = [...previousValues];
        newValues[0].items = res?.data ?? newValues[0].items;
        return newValues;
      });
    });
  }

  const { execute: triggerIncomingRelationships, status: incomingRelStatus } = useAsync(fetchIncomingRelationships, false);

  // fetch variables initially when template is loaded
  React.useEffect(() => {
    if (template_id) {
      execute();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template_id]);

  // refresh variables if an item is selected
  React.useEffect(() => {
    if (selectedItem?.value) {
      execute();
      triggerIncomingRelationships();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem?.value]);

  const [loading, setLoading] = React.useState(false);
  function handleTemplateUpdate() {
    setLoading(true);
    updateTemplate({ template_id: template_id, app_id: selectedApplication })
      .then(() => {
        toast.notify('Successfully updated Template Config');
        props.refreshTemplate();
      })
      .finally(() => setLoading(false));
  }

  const [currentView, setCurrentView] = React.useState('variables');
  const [dataToInsert, setDataToInsert] = React.useState(undefined);

  return (
    <aside className={styles.left_container}>
      {currentView === 'variables' ? (
        <>
          <div className={styles.organizations}>
            {templateDetails?.organization ? (
              <button
                onClick={() => setshowTemplateDetails(!showTemplateDetails)}
                className={
                  styles.organizations_toggle_button
                }>{`${templateDetails.organization} / ${templateDetails.workspace} / ${templateDetails.app}`}</button>
            ) : (
              <button className={styles.organizations_toggle_button}></button>
            )}

            {showTemplateDetails && (
              <div className={styles.organizations_config_wrapper}>
                <Select
                  value={selectedOrganization}
                  options={orgsData}
                  handleChange={handleOrgs}
                  label='Organizations'
                  placeHolder='Select an Organization'
                  disabled={!orgsData?.length > 0}
                />
                <Select
                  value={selectedWorkspace}
                  options={spaceData}
                  handleChange={handleApps}
                  label='Workspaces'
                  placeHolder='Select a workspace'
                  disabled={!spaceData?.length > 0}
                />
                <Select
                  value={selectedApplication}
                  options={appData}
                  handleChange={selectApp}
                  label='Apps'
                  placeHolder='Select an App'
                  disabled={!appData?.length > 0}
                />

                <button className={`${styles.change_button} btn-success`} onClick={handleTemplateUpdate} disabled={loading || !selectedApplication}>
                  {loading ? 'Submitting...' : 'Submit'}
                </button>
              </div>
            )}

            <fieldset className='d-flex flex-column mb-3'>
              <label htmlFor='variables' className={`${styles.label} mb-2`}>
                Items
              </label>
              <ThemedAsyncReactSelect
                name='items'
                placeHolder='Select an Item'
                isDisabled={!app_id}
                value={selectedItem ?? ''}
                defaultOptions={items}
                cacheOptions
                onChange={(val) => {
                  return setSelectedItem(val);
                }}
                loadOptions={handleItemSearch}
              />
            </fieldset>
            {!selectedItem?.value ? <CustomAlert title='Select an item to enable all template output options' /> : null}
          </div>

          <div className={styles.variables}>
            <p className='font-weight-medium'>Variables</p>
            {status === 'pending' && <p className={styles.no_variables}>Fetching variables... </p>}

            {status === 'success' && <ExpansionGroup data={variables} toggleView={setCurrentView} setDataToInsert={setDataToInsert} />}

            {incomingRelStatus === 'pending' ? (
              <p className={styles.no_variables}>Fetching incoming relationships... </p>
            ) : (
              <ExpansionGroup data={incomingRelationships} toggleView={setCurrentView} setDataToInsert={setDataToInsert} />
            )}
          </div>
        </>
      ) : currentView === 'list' ? (
        <CreateList setCurrentView={setCurrentView} dataToInsert={dataToInsert} setDataToInsert={setDataToInsert} />
      ) : currentView === 'table' ? (
        <CreateTable setCurrentView={setCurrentView} dataToInsert={dataToInsert} setDataToInsert={setDataToInsert} />
      ) : null}
    </aside>
  );
};

export default LeftSideBar;
