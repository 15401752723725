/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import { AgGridReact } from "ag-grid-react";
// import * as AllModules from "ag-grid-enterprise";
// import { ModuleRegistry } from "ag-grid-enterprise";
import ConnectorAction from "./components/ConnectorAction";
import dayjs from "dayjs";

// ModuleRegistry.registerModules(Object.keys(AllModules));
const ConnectorGrid = (props) => {
    const { connectors } = props;
    const filterParams = {
        filterOptions: ["contains", "notContains"],
        debounceMs: 0,
        caseSensitive: false,
        suppressAndOrCondition: true,
    };


    const formatDate = (date) => {
        const value = date.value
        return dayjs(value).format("MM/DD/YYYY")
    }
    const driverFormater = (field) => {
        return field.value.toUpperCase();
    }

    const [users] = useState({
        columns: [
            {
                headerName: "Id",
                field: "id",
                filter: "agTextColumnFilter",
                filterParams,
                suppressSizeToFit: true,
                maxWidth: 60,
            },
            {
                headerName: "Org Name",
                field: "org_name",
                filter: "agTextColumnFilter",
                filterParams
            },
            {
                headerName: "Driver",
                field: "db_driver",
                valueFormatter: driverFormater
            },
            {
                headerName: "DB Name",
                field: "db_name",
                suppressSizeToFit: true,
                minWidth: 200,
            },
            {
                headerName: "Date",
                field: "created_at",
                valueFormatter: formatDate
            },
            {
                headerName: "Actions",
                field: "actions",
                cellRenderer: "imitateAction"
            },
        ],
        data: [],
    });


    const defaultColDef = {
        flex: 1,
        minWidth: 100,
        filter: true,
        sortable: true,
        resizable: true,
    };
    const autoGroupColumnDef = { minWidth: 200 };
    const frameworks = {
        imitateAction: ConnectorAction
    };
    const paginationPageSize = 100;
    const cacheBlockSize = 100;

    return (
        <div className="pb-5">
            {/* <div className="d-flex">
            <h5>Configured Connections</h5>
            </div> */}
            <div
                className="ag-theme-balham-dark"
                style={{
                    height: "650px",
                }}
            >
                <AgGridReact
                    // modules={Object.keys(AllModules)}
                    rowData={connectors}
                    columnDefs={users.columns}
                    defaultColDef={defaultColDef}
                    autoGroupColumnDef={autoGroupColumnDef}
                    frameworkComponents={frameworks}
                    enableRangeSelection={true}
                    animateRows={true}
                    rowGroupPanelShow="always"
                    rowSelection="multiple"
                    pagination={true}
                    paginationPageSize={paginationPageSize}
                    cacheBlockSize={cacheBlockSize}
                    debug={true}
                    sideBar={true}
                    enableCellTextSelection={true}
                    suppressAggFuncInHeader={true}
                    enableFilter={true}
                    enableSorting={true}
                >
                </AgGridReact>
            </div>
        </div>
    );
};

export default ConnectorGrid;
