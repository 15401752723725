import React, { useState, useEffect } from 'react';
import Axios from "config";
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
// import { useSelector } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import { createTheme } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import useMediaQuery from "@mui/material/useMediaQuery";

import $ from 'jquery';
import Endpoint from './Endpoint';
import * as d3 from 'd3';
import './CalcTree.css';
import './Iris.scss';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import TreeData from './TreeData';
// import { useHistory } from 'react-router-dom';
import { CustomSlider } from './components/Slider';
import Dropdown from 'components/atoms/Dropdown';
import { toast } from "helpers/apiRequests";
import MultiSelect from 'components/atoms/MultiSelect';

const transformOrganizations = (data) => ({ value: data.org_id, label: `${data.org_name} - ${data.org_id}` })

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  hide: {
    display: 'none',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    background: 'white',
  },
}));


const Iris = () => {
  // const history = useHistory();
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [openMobile, setOpenMobile] = React.useState(false);
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(!open);
    setOpenMobile(!openMobile);
  };

  // const accountPlan = useSelector((state) => state.user.authUser.active_plan);
  
  const accessToken = localStorage.getItem('chosen_token');

  const [data, setData] = useState([]);
  const [workspaceData, setWorkspaceData] = useState([]);
  const [organisation, setOrganisation] = useState('');
  const [workspaces, setWorkspaces] = useState([]);
  const [diagram, setDiagram] = useState('');
  const [report, setReport] = useState('');
  const [excludedApps, setExcludedApps] = useState([]);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BASEURL}/iris/get-org-list?token=${accessToken}`
    )
      .then((res) => res.json())
      .then((result) => {
        setData(result.data);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (organisation) {
      fetch(`${process.env.REACT_APP_BASEURL}/clone/general/workspaces/${organisation}/index?token=${accessToken}`)
        .then((res) => res.json())
        .then((result) => {
          setWorkspaceData(result.data);
        });
    }
    // eslint-disable-next-line
  }, [organisation]);

  useEffect(() => {
    if (report.length > 0 && diagram.length > 0 && organisation.length > 0) {
      fetch(
        `${process.env.REACT_APP_BASEURL}/iris/get-edge-bundle-data?token=${accessToken}&type=${report}&org_id=${organisation}`
      ).then((res) => res.json());
    }
  }, [accessToken, organisation, report, diagram]);

  // TODO solve the unused variables issuse
  const [expanded, setExpanded] = useState(false);
  const [selectedType] = useState('');
  const [, setOrgs] = useState([]);
  const [reportTypes] = useState([
    { label: 'App Relationship', value: 'Relationships' },
    { label: 'Calculation Dependencies', value: 'Calculations' },
  ]);
  const [url, setUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [diagramTypes] = useState([
    { label: 'Edge Bundling', value: 'bundling' },
    { label: 'Radial', value: 'radial' },
  ]);
  const chosen_type = diagram;
  const [currentOpenSpace] = React.useState([]);
  const [currentOpenApp] = React.useState([]);
  const [spacesData, setSpacesData] = React.useState(null);
  const [, setSettingOpen] = React.useState(false);
  const [treeData, setTreeData] = React.useState({ status: false, data: {} });

  //Initial control settings for Iris
  let _innerRadius = 300;
  let _size = 360;
  let svgHeight = 800;
  let svgWidth = 800;
  const [_zoom, setZoom] = useState(1);
  const [_diameter, setDiameter] = useState(1);
  const [irisData, setIrisData] = useState(null);
  const [rotation, setRotation] = useState(0);
  const [vertical, setVertical] = useState(0);

  const [horizontal, setHorizontal] = useState(0);

  const [hideSettings, setHideSettings] = useState(false);

  const [tension, setTension] = useState(0);
  const [legend, setLegend] = useState(null);

  function clearDiv() {
    document.getElementById('theMap').innerHTML = '';
    $(document).ready(function () {
      $('#theMap').append(
        '<div id="theBody"><div id="map" className="svg-container"></div></div>'
      );
    });
    let thediv = document.createElement('div');

    thediv.setAttribute('class', 'svg-container');
    thediv.setAttribute('id', 'map');
    document.getElementById('theMap').appendChild(thediv);
  }

  useEffect(() => {
    clearDiv();

    if (
      irisData !== null &&
      irisData.length > 0 &&
      selectedType.value !== null
    ) {
      let classes = [];

      irisData.forEach((ele) => {
        const _node = ele.name.split('.');
        let thenode = standardizeText(_node[1]).replace(
          new RegExp(' ', 'g'),
          '_'
        );

        if (!currentOpenSpace.includes(thenode)) {
          classes.push(ele);
        }
      });

      const div = d3
        .select('#theBody')
        .append('div')
        .attr('class', 'tooltip')
        .style('opacity', 0);

      const diameter = _diameter,
        radius = diameter / 2,
        innerRadius = radius - _innerRadius;

      const color = d3.scaleOrdinal(d3.schemeCategory10);

      const cluster = d3
        .cluster()
        .separation(function (a, b) {
          return a.parent === b.parent ? 1 : 5;
        })
        .size([_size, innerRadius]);

      const line = d3
        .lineRadial()
        .curve(d3.curveBundle.beta(tension))
        .radius(function (d) {
          return d.y;
        })
        .angle(function (d) {
          return (d.x / 180) * Math.PI;
        });

      const zoom = d3.zoom().on('zoom', zoomed);

      function zoomed() {
       
        svg.attr('transform', d3.event.transform);
      }

      const svg = d3
        .select('#map')
        .append('svg')
        .attr('preserveAspectRatio', 'xMinYMin meet')
        .attr('viewBox', '-400 -400 800 800')
        .attr('id', 'theSvgId')
        .classed('svg-content', true);

      svg
        .attr('height', svgHeight)
        .attr('width', svgWidth)
        .call(zoom)
        .append('g')
        .attr('transform', `translate(${radius},${radius})`);
      document.getElementById('theSvgId').style.transform =
        'rotate(' + rotation + 'deg) scale(' + _zoom + ')';
      let link = svg.append('g').selectAll('.link');
      let node = svg.append('g').selectAll('.node');

      setLoading(false);

      const root = packageHierarchy(classes).sum(function (d) {
        return d.size;
      });

      cluster(root);

      const x = packageImports(root.leaves());

      const arr = [],
        arr2 = [];

      for (let i = 0; i < x.length; i++) {
        arr.push(x[i][0].data.key);
        arr2.push(x[i][x[i].length - 1].data.key);
      }

      link = link
        .data(x)
        .enter()
        // .filter(function (d) {
        //   let node = d[0].data.name;
        //   node = node.split('.');
        //   let space = standardizeText(node[1]).replace(
        //     new RegExp(' ', 'g'),
        //     '_'
        //   );
        //   // return true;
       
        //   return !excludedApps.includes(space);
        // })
        .append('path')
        .each(function (d) {
          d.source = d[0];
          d.target = d[d.length - 1];
        })
        .attr('class', 'link')
        .style('stroke', function (d) {
          let node = d[0].data.name;

          node = node.split('.');

          let space = standardizeText(node[1]).replace(
            new RegExp(' ', 'g'),
            '_'
          );

         

          if (selectedType.value === 'Calculations') {
            if (!CheckKey(d[0].data.key.replace('---', ' ')))
              return color(space);
          } else {
            if (!CheckKey(d[0].data.key.split('---')[1])) return color(space);
          }
        })
        .attr('d', line);

      node = node.data(root.leaves());

      node
        .enter()
        .filter(function (d) {
          let node = d.data.name;

          node = node.split('.');
          let space = standardizeText(node[1]).replace(
            new RegExp(' ', 'g'),
            '_'
          );
         
          return !excludedApps.includes(space);
        })
        .append('a')
        .attr('class', 'node')
        .attr('dy', '0.31em')
        .append('text')
        .attr('class', 'node')
        .attr('dy', '0.31em')
        .attr('transform', function (d) {
          return (
            'rotate(' +
            (d.x - 90) +
            ')translate(' +
            (d.y + 8) +
            ',0)' +
            (d.x < 180 ? '' : 'rotate(180)')
          );
        })
        .attr('text-anchor', function (d) {
          return d.x < 180 ? 'start' : 'end';
        })
        .append('a')
        .text(function (d) {
          if (selectedType.value === 'Calculations') {
            if (!CheckKey(d.data.key.replace('---', ' ')))
              return d.data.key.replace('---', ' ');
          } else if (selectedType.value === 'Relationships') {
            if (!CheckKey(d.data.key.split('---')[1]))
              return d.data.key.split('---')[1];
          } else {
            if (!CheckKey(d.data.key.split('---')[1]))
              return d.data.key.split('---')[1];
          }
        })
        .style('fill', function (d) {
          let node = d.data.name;

          node = node.split('.');
          let space = standardizeText(node[1]).replace(
            new RegExp(' ', 'g'),
            '_'
          );
         

          return color(space);
        })
        .on('mouseover', mouseovered)
        .on('mouseout', mouseouted)
        .on('click', performClick);

      /**
       * @return {boolean}
       */
      function CheckKey(key) {
        let x = false;

        currentOpenApp.forEach((e) => {
          let lastIndex = e.lastIndexOf('_');
          let str = e.substring(0, lastIndex);

          str = str.replace('_', ' ');

          if (str === key) {
            x = true;
          }
        });

        return x;
      }

      function mouseovered(d) {
        if (report === 'Calculations') {
          d3.select(this).style('cursor', 'pointer');
        }

        node.each(function (n) {
          n.target = n.source = false;
        });

        link
          .classed('link--target', function (l) {
            if (l.target === d) return (l.source.source = true);
          })
          .classed('link--source', function (l) {
            if (l.source === d) return (l.target.target = true);
          })
          .filter(function (l) {
            return l.target === d || l.source === d;
          })
          .raise();

        node
          .classed('node--target', function (n) {
            return n.target;
          })
          .classed('node--source', function (n) {
            return n.source;
          });

        div.transition().duration(200).style('opacity', 0.9);
        div
          .html(d.data.key + '<br/>')
          .style('left', d3.event.pageX + 'px')
          .style('top', d3.event.pageY - 28 + 'px');
      }

      function mouseouted() {
        if (report === 'Calculations') {
          d3.select(this).style('cursor', 'default');

          link.classed('link--target', false).classed('link--source', false);

          node.classed('node--target', false).classed('node--source', false);
          div.transition().duration(500).style('opacity', 0.9);
        }
      }

      function performClick(d) {
        if (report === 'Calculations') {
          setTreeData({ status: true, data: d });
        }
      }

      // Lazily construct the package hierarchy from class names.
      function packageHierarchy(classes) {
        let map = {};

        function find(name, data) {
          let node = map[name],
            i;

          if (!node) {
            node = map[name] = data || { name: name, children: [] };

            if (name.length) {
              node.parent = find(
                name.substring(0, (i = name.lastIndexOf('.')))
              );
              node.parent.children.push(node);
              node.key = name.substring(i + 1);
            }
          }

          return node;
        }

        classes.forEach(function (d) {
          find(d.name, d);
        });

        return d3.hierarchy(map['']);
      }

      // Return a list of imports for the given array of nodes.
      function packageImports(nodes) {
        let map = {};
        let imports = [];

        // Compute a map from name to node.
        nodes.forEach(function (d) {
          map[d.data.name] = d;
        });

        // For each import, construct a link from the source to target node.
        nodes.forEach(function (d) {
          if (d.data.imports)
            d.data.imports.forEach(function (i) {
              if (
                typeof map[i] !== 'undefined' &&
                typeof map[d.data.name] !== 'undefined'
              )
                imports.push(map[d.data.name].path(map[i]));
            });
        });

        return imports;
      }
      function updateExcludedApps(app) {
        /**
         * Tracks exluded apps and toggles between removing or adding new app to exluded list
         */

        let currentApps = [...excludedApps];

        if (currentApps.includes(app)) {
          currentApps = currentApps.filter((currentApp) => {
            return currentApp !== app;
          });
        } else {
          currentApps.push(app);
        }
        currentApps = [...new Set(currentApps)];

       
        setExcludedApps(currentApps);
      }

      function standardizeText(label) {
        if (selectedType.value === 'Calculations') {
          let key = label.split('---');
          let result = '';

          if (key[1]) {
            result += key[1];
          }

          result += ' ';

          if (key[0]) {
            result += key[0];
          }

          return result;
        }

        let key = label.split('---');
        let result = '';

        if (key[1]) {
          result += key[1];
        }

        result += ' ';

        if (key[0]) {
          result += key[0];
        }

        return result;
      }

      let domain = color.domain();

      let theLegend = [];
      let _legendJsx = [];

      for (let i = 0; i < domain.length; i++) {
        theLegend.push({ label: domain[i], color: color(domain[i]) });
      }

     

      for (let i = 0; i < theLegend.length; i++) {
        const each_legend = theLegend[i];
        // push the tr to array, the key is important

        _legendJsx.push(
          <div key={i}>
            <div
              className={
                excludedApps.includes(each_legend.label)
                  ? 'iris-strikethrough'
                  : ''
              }
              onClick={() => updateExcludedApps(each_legend.label)}
            >
              <svg width='8' height='8'>
                <rect width='100%' height='100%' fill={each_legend.color} />
              </svg>
              &nbsp;&nbsp; {each_legend.label}
            </div>
          </div>
        );
      }

      console.log(_legendJsx);
      setLegend(_legendJsx);
    }
    setLoading(false);
  }, [
    rotation,
    irisData,
    _diameter,
    report,
    _innerRadius,
    _size,
    classes,
    svgHeight,
    excludedApps,
    svgWidth,
    selectedType.value,
    _zoom,
    tension,
    spacesData,
    currentOpenSpace,
    currentOpenApp,
  ]);

  useEffect(() => {
    if (document.getElementById('theSvgId') !== null) {
      document.getElementById('theSvgId').style.transformOrigin =
        'center center';
      document.getElementById('theSvgId').style.transform =
        'rotate(' + rotation + 'deg) scale(' + _zoom + ')';

      document.getElementById('theSvgId').style.top = vertical + 'px';
      document.getElementById('theSvgId').style.left = horizontal + 'px';
    }
  }, [rotation, _zoom, vertical, horizontal]);

  const loadEdgeBundling = (classes) => {
    let nodeCount = classes.length;
    let circumferenceMin = 2000;
    let circumferenceBloater = 10;

    let diameter =
      (circumferenceMin + nodeCount * circumferenceBloater) / Math.PI;
    setDiameter(diameter);
    setIrisData(classes);
  };

  const handleEdgeBundling = async () => {
    const _url = `${process.env.REACT_APP_BASEURL}/iris/get-edge-bundle-data?token=${accessToken}&type=${report}&org_id=${organisation}`;

    setUrl(_url);
    let classes = [];

    await fetch(_url)
      .then((res) =>
        res.json().then((result) => {
          if (!result.status && result.message) {
            toast.notify(result.message);
            classes = [];
          } else {
            classes = result;
          }
          performSpaceToState(classes);
          loadEdgeBundling(classes);
        })
      )
      .catch(() => {
        return false;
      });

    setHideSettings(false);
  };

  const handleRadial = async () => {
    const workspaceParams = workspaces.map(workspaceId => `space_ids[]=${workspaceId}`).join('&');
    const _url = `${process.env.REACT_APP_BASEURL}/iris/radial/get-edge-bundle-data?type=${report}&org_id=${organisation}&${workspaceParams}`;
    // const _url = `${process.env.REACT_APP_BASEURL}/iris/radial/get-edge-bundle-data?type=${report}&org_id=${organisation}&space_ids[]=${workspaces.join(',')}`;
    setUrl(_url);
    setHideSettings(true);
    let radial_data = [];

    await Axios.get(_url)
      .then((result) => {
          radial_data = result;
          setLoading(false);
        })
      .catch((err) => {
        console.error(err)
        return false;
      });
    let zoom = d3.zoom().on('zoom', () => {
      svg.attr('transform', d3.event.transform);
    });
    let svg = d3
      .select('#map')
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr('viewBox', '-400 -400 800 800');
    let width = +svg.attr('width');

    let g = svg.call(zoom).append('g');

    let cluster = d3.cluster().size([_size, width / 2 - 120]);

    let root = d3.hierarchy(radial_data);
    cluster(root);

    const color = d3.scaleOrdinal(d3.schemeCategory10);

    g.selectAll('.radial_node_link')
      .data(root.descendants().slice(1))
      .enter()
      .filter(function (d) {
        return !!d.data.app;
      })
      .append('path')
      .attr('class', 'radial_node_link')
      .style('stroke', function (d) {
        return color(d.data.external_id);
      })
      .attr('d', (d) => {
        return (
          'M' +
          project(d.x, d.y) +
          'C' +
          project(d.x, (d.y + d.parent.y) / 2) +
          ' ' +
          project(d.parent.x, (d.y + d.parent.y) / 2) +
          ' ' +
          project(d.parent.x, d.parent.y)
        );
      });

    let node = g
      .selectAll('.radial_node')
      .data(root.descendants())
      .enter()
      .filter(function (d) {
        return !!d.data.app;
      })

      .append('g')
      .attr('class', (d) => {
        return (
          'radial_node' +
          (d.children ? ' radial_node--internal' : ' radial_node--leaf')
        );
      })
      .style('fill', function (d) {
        return color(d.data.external_id);
      })
      .attr('transform', (d) => {
        return 'translate(' + project(d.x, d.y) + ')';
      });

    node.append('circle').attr('r', 2.2);

    node
      .append('text')
      .attr('dy', '0.31em')
      .attr('x', (d) => {
        if (d.x < 180 && !d.children) {
          return 6;
        }
        return -6;
        // return d.x < 180 === (!d.children ? 6 : -6);
      })
      .style('text-anchor', (d) => {
        if (d.x < 180 && !d.children) {
          return 'start';
        }
        return 'end';
        // return d.x < 180 === (!d.children ? 'start' : 'end');
      })
      .attr('transform', (d) => {
        return 'rotate(' + (d.x < 180 ? d.x - 90 : d.x + 90) + ')';
      })
      .text((d) => d.data.app);

    function project(x, y) {
      let angle = ((x - 90) / 180) * Math.PI,
        radius = y;
      return [radius * Math.cos(angle), radius * Math.sin(angle)];
    }
  };

  useEffect(() => {
    Endpoint.getIrisOrgs()
      .then((res) => {
        let org = [];

        res.data.data.map((item) => {
          return org.push({ org_name: item.org_name, org_id: item.org_id });
        });
        setOrgs(org);
      })
      .catch(() => {});
  }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleGenerate = async () => {
    setSettingOpen(true);
    setExpanded('panel4');
    setLoading(true);
    document.getElementById('theMap').innerHTML = '';

    if (chosen_type === 'radial') {
      //Create div with id map and svg too
      $(function () {
        let width_ =
          document.getElementsByClassName('inner-content')[0].offsetWidth;
        $('#theMap').append(
          '<svg width="' +
            width_ +
            '" height="750" id="map" className="svg-container" />'
        );
      });
      setLoading(true);
      return handleRadial();
    } else if (chosen_type === 'bundling') {
      return handleEdgeBundling();
    }
  };

  const performSpaceToState = (classes) => {
    const spaces = [];

    if (selectedType.value === 'Calculations') {
      for (let i = 0; i < classes.length; i++) {
        let node = classes[i].name;

        node = node.split('.');
        let space = standardizeText(node[1]).replace(new RegExp(' ', 'g'), '_');
        let app = standardizeText(node[2]).replace(new RegExp(' ', 'g'), '_');
        let field = standardizeText(node[3]).replace(new RegExp(' ', 'g'), '_');

        //space
        insertSpace(space);
        //apps
        insertApp(space, app);
        //fields
        insertField(field, app, space);
      }
    }

    if (selectedType.value === 'Relationships') {
      for (let i = 0; i < classes.length; i++) {
        let node = classes[i].name;

        node = node.split('.');
        let space = standardizeText(node[1]).replace(new RegExp(' ', 'g'), '_');
        let app = standardizeText(node[2]).replace(new RegExp(' ', 'g'), '_');

        //space
        insertSpace(space);
        //apps
        insertApp(space, app);
      }
    }

    function insertSpace(space) {
      let found = false;

      for (let s = 0; s < spaces.length; s++) {
        if (spaces[s].name === space) {
          found = true;
          break;
        }
      }

      if (!found) {
        spaces.push({ name: space, apps: [] });
      }
    }

    function insertApp(space, app) {
      for (let s = 0; s < spaces.length; s++) {
        if (spaces[s].name === space) {
          let apps = spaces[s].apps;
          let found = false;

          for (let a = 0; a < apps.length; a++) {
            if (apps[a].name === app) {
              found = true;
              break;
            }
          }

          if (!found) {
            spaces[s].apps.push({ name: app, fields: [] });
          }
        }
      }
    }

    function insertField(field, app, space) {
      for (let s = 0; s < spaces.length; s++) {
        if (spaces[s].name === space) {
          let apps = spaces[s].apps;

          for (let a = 0; a < apps.length; a++) {
            if (apps[a].name === app) {
              let fields = spaces[s].apps[a];
              let found = false;

              for (let f = 0; f < fields.length; f++) {
                if (field[f].name === field) {
                  found = true;
                  break;
                }
              }

              if (!found) {
                spaces[s].apps[a].fields.push({ name: field });
              }
            }
          }
        }
      }
    }

    setSpacesData(spaces);

    function standardizeText(label) {
      if (selectedType.value === 'Calculations') {
        let key = label.split('---');
        let result = '';

        if (key[1]) {
          result += key[1];
        }

        result += ' ';

        if (key[0]) {
          result += key[0];
        }

        return result;
      }

      let key = label.split('---');
      let result = '';

      if (key[1]) {
        result += key[1];
      }

      result += ' ';

      if (key[0]) {
        result += key[0];
      }

      return result;
    }
  };

  const handleTreeClose = () => {
    setTreeData({ status: false, data: {} });
  };

  const organizations = data.map(transformOrganizations)
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header baropen={open} handleDrawerOpen={handleDrawerOpen} />
      {isMobile ? (
          <Sidebar open={openMobile} handleDrawerOpen={handleDrawerOpen} />
        ) : (
          <Sidebar open={open} handleDrawerOpen={handleDrawerOpen} />
        )}
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div className='iris'>
          <div className='iris-tabs'>
            <div className='item'>
              <label className='label'>Organisation</label>
              <Dropdown
                className="item-select"
                value={organisation}
                function={(e) => setOrganisation(e.target.value)}
                list={organizations}
                disabled={!organizations?.length > 0}
              />
            </div>

            <div className="item">
              <label className='label'>Workspaces</label>
              <MultiSelect
                className="item-select"
                values={workspaces}
                setValues={setWorkspaces}
                label="name"
                value="space_id"
                list={workspaceData}
                disabled={!workspaceData?.length > 0}
              />
            </div>

            <div className='item'>
              <label className='label'>Diagram Type</label>
              <Dropdown
                className="item-select"
                value={diagram}
                function={(e) => setDiagram(e.target.value)}
                list={diagramTypes}
                disabled={!diagramTypes?.length > 0}
              />
            </div>

            <div className='item'>
              <label className='label'>Report Type</label>
              <Dropdown
                className="item-select"
                value={report}
                function={(e) => setReport(e.target.value)}
                list={reportTypes}
                disabled={!reportTypes?.length > 0}
              />
            </div>

            <div className='item'>
              <div className='label'></div>
              <button
                disabled={loading || !report || !organisation || !diagram}
                onClick={() => {
                  setUrl(url);
                  return handleGenerate();
                }}
              >
                {loading ? (
                  <CircularProgress
                    color='inherit'
                    style={{ width: '20px', height: '20px' }}
                  />
                ) : (
                  'Generate'
                )}
              </button>
            </div>
          </div>

          <div className='iris-container'>
            <div className='content'>
              <div className='inner-content'>
                <div id='theMap' className='Map svg-element'></div>
                {loading && (
                  <div id='loader-holder'>
                    <LinearProgress
                      style={{
                        marginTop: '2rem',
                        textAlign: 'center',
                        width: '50%',
                      }}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className='filter'>
              <div className='tab-container'>
                <div className='title'>Settings</div>
                {hideSettings ? (
                  <div className=''>
                    Below are instructions on how to view the radial chart
                    properly:
                    <ol>
                      <li>To zoom in, scroll up on the chart</li>
                      <li>To zoom out, scroll down on the chart</li>
                      <li>
                        To navigate horizontally and vertically, drag around
                        with mouse
                      </li>
                    </ol>
                  </div>
                ) : (
                  <div className='sliders'>
                    <div className='slider-container'>
                      <label className='label'>Rotation</label>
                        <CustomSlider 
                           valueLabelDisplay='auto'
                           id='rotation' 
                           min={0.1}
                           max={300}
                           onChange={(e, value) => {
                             setRotation(value);
                           }}
                           step={1}
                           value={rotation}
                        />                                                     
                    </div>
                    <div className='slider-container'>
                      <label className='label'>Zoom</label>                     
                        <CustomSlider
                          valueLabelDisplay='auto'
                          id='_zoom' 
                          min={1}
                          max={4}
                          onChange={(e, value) => {
                            setZoom(value);
                          }}
                          step={0.01}
                          value={_zoom}
                        />                    
                    </div>
                    <div className='slider-container'>
                      <label className='label'>Diameter</label>                     
                        <CustomSlider
                          valueLabelDisplay='auto'
                          id='_diameter' 
                          min={0.1}
                          max={2500}
                          onChange={(e, value) => {
                            setDiameter(value);
                          }}
                          step={0.01}
                          value={_diameter}
                        />                    
                    </div>
                    <div className='slider-container'>
                      <label className='label'>Tension</label>                     
                        <CustomSlider
                          valueLabelDisplay='auto'
                          id='tension' 
                          min={0.1}
                          max={9.0}
                          onChange={(e, value) => {
                            setTension(value);
                          }}
                          step={0.01}
                          value={tension}
                        />                    
                    </div>
                    <div className='slider-container'>
                      <label className='label'>Vertical</label>                     
                        <CustomSlider
                          valueLabelDisplay='auto'
                          id='vertical' 
                          min={0}
                          max={50}
                          onChange={(e, value) => {
                            setVertical(value);
                          }}
                          step={0.01}
                          value={vertical}
                        />                    
                    </div>
                    <div className='slider-container'>
                      <label className='label'>Horizontal</label>                     
                        <CustomSlider
                          valueLabelDisplay='auto'
                          id='horizontal' 
                          min={0}
                          max={420}
                          onChange={(e, value) => {
                            setHorizontal(value);
                          }}
                          step={0.01}
                          value={horizontal}
                        />                    
                    </div>
                  </div>
                )}
              </div>

              <div className='filter-container tab-container'>
                <div className=''>
                  {irisData !== null ? (
                    <Accordion
                      expanded={expanded === 'panel5'}
                      onChange={handleChange('panel5')}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel2bh-content'
                        id='panel2bh-header'
                        className={classes.collapseHeading}
                      >
                        <Typography className={classes.heading}>
                          Color Legend
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className='panel-body'>{legend}</div>
                      </AccordionDetails>
                    </Accordion>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {treeData.status && (
        <TreeData
          data={treeData.data}
          setTreeData={setTreeData}
          org={organisation}
          open={treeData.status}
          handleTreeClose={handleTreeClose}
        />
      )}
    </div>
  );
};

export default withRouter(Iris);
