import { Box, Card, CardContent, CardHeader, Typography } from '@material-ui/core'
import React from 'react'

const AnalysisCard = ({ total, title }) => {
    return (
        <Card>
            <Box width={200}>
                <center>
                    <CardHeader title={title} />
                </center>
                <CardContent>
                    <center>
                        <Typography variant='h6'>
                            {total}
                        </Typography>
                    </center>
                </CardContent>
            </Box>
        </Card>
    )
}

export default AnalysisCard