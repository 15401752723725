import React from 'react';
import Dialog from "@material-ui/core/Dialog";
// import Button from "@material-ui/core/Button";
// import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from '@material-ui/core/Slide';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import BookIcon from '@material-ui/icons/Book';
import AllOutIcon from '@material-ui/icons/AllOut';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
// import "./d3-tip";
import {Alert} from "@material-ui/lab";
import "./Tree.css";
import Divider from "@material-ui/core/Divider";
import Box from '@material-ui/core/Box';
import * as d3 from "d3";
import appendTeamMemberHeader from 'utils/appendTeamMemberHeader';

const token = "Bearer" + localStorage.getItem("chosen_token");
let headers = {
    authorization: token,
}

headers = appendTeamMemberHeader(headers)

const TreeData = (props) => {
    const {data, org, open, handleTreeClose} = props;
    const [loading, setLoading] = React.useState(true);
    const [legend, setLLegend] = React.useState([]);
    const [calcData, setCalcData] = React.useState([]);
    const [error, setError] = React.useState(null);
    const [selectedScript, setSelectedScript] = React.useState('Click on a calculation field first.');
    const [selectedScriptLabel, setSelectedScriptLabel] = React.useState('...');
    let showScript = 1;

    React.useEffect(() => {
        const field = data.data.key.replace( /^\D+/g, '');
        const _url = process.env.REACT_APP_BASEURL + "/iris/genesis/get-calc-tree-data/"+org+"/"+field;
        fetch(_url, {
            headers
        }).then((res) => res.json().then(result => {
            if(typeof result.script !== "undefined"){
                if(result.script)
                {
                    setSelectedScript(result.script);
                    setSelectedScriptLabel("this app");
                }
                setCalcData(result);
            }else{
                setError("No Children Found!");
            }
        }))
    }, [data, org]);

    React.useEffect(() => {
        const color = d3.scaleOrdinal(d3.schemeCategory10);
        // Set the dimensions and margins of the diagram
        let margin = {top: 20, right: 90, bottom: 30, left: 90},
            width = 2000 - margin.left - margin.right,
            height = 500 - margin.top - margin.bottom;
        // append the svg object to the body of the page
        // appends a 'group' element to 'svg'
        // moves the 'group' element to the top left margin
        let svg = d3.select("#calc-tree-inserter").append("svg")
            .attr("width", width + margin.right + margin.left)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", "translate("
                + margin.left + "," + margin.top + ")");

        let i = 0,
            duration = 750,
            root;

        // declares a tree layout and assigns the size
        let treemap = d3.tree().size([height, width]);
        // Assigns parent, children, height, depth
        root = d3.hierarchy(calcData, function(d) { return d.children; });
        root.x0 = height / 2;
        root.y0 = 0;

        if(typeof  root.children === 'undefined')
        {
            setLoading(false);
        }else{
            // Collapse after the second level
            root.children.forEach(collapse);
            update(root);
            setLoading(false);
        }

        // Collapse the node and all it's children
        function collapse(d) {
            if(d.children) {
                d._children = d.children
                d._children.forEach(collapse)
                d.children = null
            }
        }
        function update(source) {

            // Assigns the x and y position for the nodes
            let treeData = treemap(root);

            // Compute the new tree layout.
            let nodes = treeData.descendants(),
                links = treeData.descendants().slice(1);

            // Normalize for fixed-depth.
            nodes.forEach(function(d){ d.y = d.depth * 180});

            // ****************** Nodes section ***************************

            // Update the nodes...
            let node = svg.selectAll('g.node')
                .data(nodes, function(d) {return d.id || (d.id = ++i); });

            // Enter any new modes at the parent's previous position.
            let nodeEnter = node.enter().append('g')
                .attr('class', 'node')
                .attr("transform", function(d) {
                    return "translate(" + source.y0 + "," + source.x0 + ")";
                })
                .on('click', click);

            // Add Circle for the nodes
            nodeEnter.append('circle')
                .attr('class', 'node')
                .attr('r', 1e-6)
                .style("fill", function(d) {
                    return d._children ? "" : color(d.app);
                })
                .style("stroke", function(d) {
                    return color(d.app);
                });

            setLegend();
            function setLegend(){
                let domain = color.domain();
                for(let i = 0; i < domain.length; i++){
                    if(!legendExists(legend, domain[i])){
                        setLLegend([
                            ...legend,
                            {label:domain[i], color:color(domain[i])}
                        ]);
                    }
                }
                function legendExists(legends, label){
                    let found = false;
                    for(let i = 0; i < legends.length; i++) {
                        if (legends[i].label === label) {
                            found = true;
                            break;
                        }
                    }
                    return found;
                }
            }

            // Add labels for the nodes
            nodeEnter.append('text')
                .attr("dy", ".35em")
                .attr("x", function(d) {
                    return d.children || d._children ? -13 : 13;
                })
                .attr("text-anchor", function(d) {
                    return d.children || d._children ? "end" : "start";
                })
                .text(function(d) { return d.data.name; });

            // UPDATE
            let nodeUpdate = nodeEnter.merge(node);

            // Transition to the proper position for the node
            nodeUpdate.transition()
                .duration(duration)
                .attr("transform", function(d) {
                    return "translate(" + d.y + "," + d.x + ")";
                });

            // Update the node attributes and style
            nodeUpdate.select('circle.node')
                .attr('r', 10)
                .style("fill", function(d) {
                    return d._children ? "lightsteelblue" : "#fff";
                })
                .attr('cursor', 'pointer');


            // Remove any exiting nodes
            let nodeExit = node.exit().transition()
                .duration(duration)
                .attr("transform", function(d) {
                    return "translate(" + source.y + "," + source.x + ")";
                })
                .remove();

            // On exit reduce the node circles size to 0
            nodeExit.select('circle')
                .attr('r', 1e-6);

            // On exit reduce the opacity of text labels
            nodeExit.select('text')
                .style('fill-opacity', 1e-6);

            // ****************** links section ***************************

            // Update the links...
            let link = svg.selectAll('path.link')
                .data(links, function(d) { return d.id; });

            // Enter any new links at the parent's previous position.
            let linkEnter = link.enter().insert('path', "g")
                .attr("class", "link")
                .attr('d', function(d){
                    let o = {x: source.x0, y: source.y0}
                    return diagonal(o, o)
                });

            // UPDATE
            let linkUpdate = linkEnter.merge(link);

            // Transition back to the parent element position  
            linkUpdate.transition()
                .duration(duration)
                .attr('d', function(d){ return diagonal(d, d.parent) });

            // Remove any exiting links
            // let linkExit = link.exit().transition()
            //     .duration(duration)
            //     .attr('d', function(d) {
            //         let o = {x: source.x, y: source.y}
            //         return diagonal(o, o)
            //     })
            //     .remove();

            // Store the old positions for transition.
            nodes.forEach(function(d){
                d.x0 = d.x;
                d.y0 = d.y;
            });

            // Creates a curved (diagonal) path from parent to the child nodes
            function diagonal(s, d) {

                return `M ${s.y} ${s.x}
            C ${(s.y + d.y) / 2} ${s.x},
              ${(s.y + d.y) / 2} ${d.x},
              ${d.y} ${d.x}`
            }

            // Toggle children on click.
            function click(d) {
                if (d.children) {
                    d._children = d.children;
                    d.children = null;
                } else {
                    d.children = d._children;
                    d._children = null;
                }

                if(d.script){
                    setSelectedScript(d.script);
                    setSelectedScriptLabel(d.name);
                }


                update(d);
            }
        }

    }, [calcData, data, legend]);

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleTreeClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            fullWidth={true}
            maxWidth = {'xl'}
        >
            {/*<DialogTitle id="alert-dialog-slide-title">{"Use Google's location service?"}</DialogTitle>*/}
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <div style={{height: '90vh'}}>

                            <div style={{float: 'left'}}>
                                <span style={{marginTop: '-100px'}}>IRIS Calculation Analysis</span>
                            </div>

                            <div style={{float: 'right'}}>
                                <Tooltip title="Show calculation script" ><IconButton aria-label="Restore Item" onClick={() => {
                                    showScript = 0;
                                }}><BookIcon /></IconButton></Tooltip>
                                <Tooltip title="Expand all nodes" ><IconButton aria-label="Restore Item" onClick={() => console.log('Expand all nodes!')}><AllOutIcon /></IconButton></Tooltip>
                                <Tooltip title="Collapse the tree" ><IconButton aria-label="Restore Item" onClick={() => console.log('Collapse the tree!')}><KeyboardBackspaceIcon /></IconButton></Tooltip>
                            </div>

                        <br/><br/><br/>
                        <Divider />
                        <div>

                            {
                                showScript === 1 ?
                                    <div style={{float: 'right'}}>
                                        <Box
                                            boxShadow={3}
                                            bgcolor="background.paper"
                                            m={1}
                                            p={1}
                                            style={{ width: '60rem' }}
                                        >
                                            <h3>Script for {selectedScriptLabel}</h3>
                                            <Divider />

                                                {selectedScript}

                                        </Box>
                                    </div>
                                    :
                                    ''
                            }
                        </div>
                        <br/><br/><br/>
                        {
                            error ? <Alert severity='error'>{error}</Alert> : ''
                        }
                        {
                            loading ? <div className="flex flex-1 flex-col items-center justify-center">
                                <LinearProgress className="w-xs" color="secondary"/></div> : ''
                        }

                        <div id="calc-tree-inserter">
                        </div>

                    </div>

                </DialogContentText>
            </DialogContent>
            {/*<DialogActions>*/}
            {/*    <Button onClick={handleTreeClose} color="primary">*/}
            {/*        Disagree*/}
            {/*    </Button>*/}
            {/*    <Button onClick={handleTreeClose} color="primary">*/}
            {/*        Agree*/}
            {/*    </Button>*/}
            {/*</DialogActions>*/}
        </Dialog>
    );
};

export default TreeData;
