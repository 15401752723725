import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import EyeIcon from "../../assets/icons/eye-icon.svg";
import EditIcon from "../../assets/icons/edit-icon.svg";
import emptyState from "../../assets/icons/emptyState.svg";
// import DeleteIcon from "../../assets/icons/trash-icon.svg";
import Popover from "@material-ui/core/Popover";
import workspaceLogo from "../../assets/icons/workspaceLogo.svg";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import RenderByPermission from "components/RenderByPermission";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 205,
    display: "inline-block",
    cursor: "pointer",
  },
  title: {
    fontSize: 14,
  },
  cardTitle: {
    ...theme.typography.subtitle1,
    fontSize: "18px",
    fontWeight: "600",
  },
  cardDesc: {
    ...theme.typography.subtitle1,
    fontSize: "14px",
    fontWeight: "400",
  },
  cardCaption: {
    ...theme.typography.caption,
    textAlign: "center",
    fontSize: "10px",
    bottom: 0,
  },
  cardHeader: {
    paddingBottom: "0px",
    width: "100%",
  },
  cardContent: {
    paddingTop: "0px",
    textAlign: "center",
  },
  cardAction: {
    textAlign: "center",
  },
  popover: {
    width: "200px",
    padding: "10px",
    borderRadius: theme.shape.borderRadius,
  },
  cont: {
    display: "flex",
    boxShadow: "0px 1px 14px 0px #00000014",
    margin: "10px",
    flexBasis: "23%",
    borderRadius: "8px",
    maxWidth: "23%",
  },

  "@media screen and (max-width: 1000px)": {
    cont: {
      flexBasis: "100%",
      maxWidth: "100%",
    },
  },
}));

const WorkspaceCardView = ({
  workspaces,
  openEditDialog,
  openDeleteDialog,
  handleSelectedWorkspace,
  openCreateDialog
}) => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();
  const isMobile = useMediaQuery("(max-width:600px)");

  const viewApp = (space_id) => {
    let link = `/dashboard/organisation/${params.id}/workspaces/${space_id}/apps`;

    history.push(link);
  };

  return (
    <Grid container alignItems="stretch">
      {workspaces.length > 0 ? (
        workspaces.map((workspace, index) => {
          if (workspace.display === true) {
            return (
              <Grid item xs={3} className={classes.cont} key={index}>
                {isMobile ? (
                  <Card
                    style={{
                      justifyContent: "space-between",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      paddingTop: "3%",
                    }}
                    onClick={() => {
                      handleSelectedWorkspace(workspace.space_id);
                    }}
                    onDoubleClick={() => viewApp(workspace.space_id)}
                    className={classes.root}
                    key={index}
                  >
                    <div
                      style={{
                        width: "15%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginLeft: "4%",
                      }}
                    >
                      <img
                        src={workspaceLogo}
                        className="pb-2"
                        alt="Workspace logo"
                      />
                    </div>

                    <CardContent
                      style={{ textAlign: "left", width: "85%" }}
                      className={classes.cardContent}
                    >
                      <Typography variant="h6" className={classes.cardDesc}>
                        {workspace.name}
                      </Typography>
                      <Typography variant="h6" className={classes.cardTitle}>
                        {workspace.space_id}
                      </Typography>
                    </CardContent>

                    <PopupState
                      variant={"popover"}
                      popupId={"workspace-popover"}
                    >
                      {(popupState) => (
                        <div
                          style={{
                            width: "15%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <IconButton
                            aria-label="settings"
                            style={{ cursor: "pointer" }}
                            {...bindTrigger(popupState)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Popover
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                              vertical: "center",
                              horizontal: "center",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <div className="" style={{ padding: "10px" }}>
                              <div
                                style={{
                                  padding: "5px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  let link = `/dashboard/organisation/${params.id}/workspaces/${workspace.space_id}/apps`;

                                  history.push(link);
                                }}
                              >
                                <span>
                                  {" "}
                                  <img src={EyeIcon} alt="icon" />
                                  {"  "} View Apps
                                </span>
                              </div>
                              <RenderByPermission>
                                <div
                                  style={{
                                    padding: "5px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    handleSelectedWorkspace(workspace.space_id);
                                    openEditDialog(workspace.space_id);
                                  }}
                                >
                                  <img src={EditIcon} alt="icon" />
                                  {"  "} Edit Workspace
                                </div>
                              </RenderByPermission>

                              {/* <div
                                style={{ padding: "5px", cursor: "pointer" }}
                                onClick={() => {
                                  handleSelectedWorkspace(workspace.space_id)
                                  openDeleteDialog()
                                }}
                              >
                                <img src={DeleteIcon} alt="icon" />{"  "} Delete Workspace
                              </div> */}
                            </div>
                          </Popover>
                        </div>
                      )}
                    </PopupState>
                  </Card>
                ) : (
                  <Card
                    style={{
                      justifyContent: "space-between",
                      flexDirection: "column",
                      width: "100%",
                    }}
                    onClick={() => {
                      handleSelectedWorkspace(workspace.space_id);
                    }}
                    onDoubleClick={() => viewApp(workspace.space_id)}
                    className={classes.root}
                    key={index}
                  >
                    <CardHeader
                      className={classes.cardHeader}
                      action={
                        <PopupState
                          variant={"popover"}
                          popupId={"workspace-popover"}
                        >
                          {(popupState) => (
                            <div
                              style={{
                                width: "18vw",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={workspaceLogo}
                                className="pb-2"
                                alt="Workspace logo"
                              />
                              <IconButton
                                aria-label="settings"
                                style={{ cursor: "pointer" }}
                                {...bindTrigger(popupState)}
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <Popover
                                {...bindPopover(popupState)}
                                anchorOrigin={{
                                  vertical: "center",
                                  horizontal: "center",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                              >
                                <div className="" style={{ padding: "10px" }}>
                                  <div
                                    style={{
                                      padding: "5px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      let link = `/dashboard/organisation/${params.id}/workspaces/${workspace.space_id}/apps`;

                                      history.push(link);
                                    }}
                                  >
                                    <span>
                                      {" "}
                                      <img src={EyeIcon} alt="icon" />
                                      {"  "} View Apps
                                    </span>
                                  </div>
                                  <RenderByPermission>
                                    <div
                                      style={{
                                        padding: "5px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        handleSelectedWorkspace(
                                          workspace.space_id
                                        );
                                        openEditDialog(workspace.space_id);
                                      }}
                                    >
                                      <img src={EditIcon} alt="icon" />
                                      {"  "} Edit Workspace
                                    </div>
                                  </RenderByPermission>
                                  {/* <div
                                style={{ padding: "5px", cursor: "pointer" }}
                                onClick={() => {
                                  handleSelectedWorkspace(workspace.space_id)
                                  openDeleteDialog()
                                }}
                              >
                                <img src={DeleteIcon} alt="icon" />{"  "} Delete Workspace
                              </div> */}
                                </div>
                              </Popover>
                            </div>
                          )}
                        </PopupState>
                      }
                    />

                    <CardContent
                      style={{ textAlign: "left" }}
                      className={classes.cardContent}
                    >
                      <Typography variant="h6" className={classes.cardDesc}>
                        {workspace.name}
                      </Typography>
                      <Typography variant="h6" className={classes.cardTitle}>
                        {workspace.space_id}
                      </Typography>
                    </CardContent>
                  </Card>
                )}
              </Grid>
            );
          }
          return "";
        })
      ) : (
        <div className="col-md-12 pt-4 text-center justify-content-center align-items-center mb-5">
          <img src={emptyState} alt="" />
          <p className="mb-0">There are no space in this organisation</p>
          <p>You can add a space by clicking the button below</p>
          <button
            style={{
              border: "none",
              backgroundColor: "#F0B429",
              width: "188px",
              height: "45px",
              color: "white"
            }}
            onClick={openCreateDialog}
          >
            Add New Space
          </button>
        </div>
      )}
    </Grid>
  );
};

export default WorkspaceCardView;
