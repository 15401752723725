/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Dialog, DialogContent, LinearProgress } from "@material-ui/core";
import { toast } from "helpers/apiRequests";
import { createMember } from "../../../../redux/actions/tmail";
import { useDispatch } from "react-redux";
import cancelIcon from "../../../../assets/icons/cancel.svg";
import Select from "react-select";

const CreateMemberModal = ({ openModal, handleClose, spaceMembers, appId }) => {
  const dispatch = useDispatch();
  const [selectedMember, setSelectedMember] = useState([]);
  const [role, setRole] = useState();
  const [saveLoading, setSaveLoading] = useState(false);

  const handleClose_ = () => {
    handleClose();
  };

  const handleRoleChange = (e) => {
    const val = e.target.value;
    setRole(val);
    const members = [...selectedMember];
    const newMembers = members.map((member) => {
      member.role = val;
      return member;
    });
    setSelectedMember(newMembers);
  };

  const changeIsLoadingSaveApp = () => {
    setSaveLoading(false);
  };

  const memberChangeHandler = (selectedOptions) => {
    selectedOptions.forEach((option) => {
      const selectedMember = spaceMembers.find(
        (member) => member.user_id === parseInt(option.value)
      );
      setSelectedMember((prevSelectedMembers) => [
        ...prevSelectedMembers,
        selectedMember,
      ]);
    });
  };

  const createNewMember = (e) => {
    e.preventDefault();
    setSaveLoading(true);
    if (!selectedMember) {
      toast.notify("Please select a workspace member", { type: "info" });
      setSaveLoading(false);
      return;
    } else if (role === "") {
      toast.notify("Please select a role for the members selected", {
        type: "info",
      });
      setSaveLoading(false);
      return;
    }

    const payload = {
      tmail_app_id: appId,
      podio_users: selectedMember,
      role: role,
    };
    dispatch(createMember(payload, changeIsLoadingSaveApp, handleClose_));
    setSelectedMember([]);
  };

  const optionsSelect =
    spaceMembers.length > 0
      ? spaceMembers.map((space) => {
          return {
            value: `${space.user_id}`,
            label: `${space.name}`,
          };
        })
      : null;

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={handleClose}
        disableEscapeKeyDown={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="connector-modal"
        maxWidth="md"
      >
        <div style={{ width: "700px" }}>
          <DialogContent>
            <DialogContent>
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h5 className="">Add Workspace Member</h5>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={handleClose_}
                  src={cancelIcon}
                  alt=""
                />
              </div>
              <div className="row justify-content-center">
                <div className="col-md-12">
                  <div className="">
                    <div className="form-group ">
                      <label
                        style={{
                          fontWeight: "normal",
                          fontSize: "15px",
                        }}
                        className="mb-2"
                      >
                        Select Members
                      </label>

                      <Select
                        isMulti
                        closeMenuOnSelect={false}
                        value={selectedMember ? selectedMember.user_id : ""}
                        onChange={memberChangeHandler}
                        options={optionsSelect}
                      />
                    </div>
                  </div>

                  <div className="">
                    <div className="form-group ">
                      <label
                        className="mb-2"
                        style={{
                          fontWeight: "normal",
                          fontSize: "15px",
                        }}
                      >
                        Assign Role:
                      </label>
                      <select
                        className="form-control"
                        value={role}
                        onChange={handleRoleChange}
                        style={{ height: "50px" }}
                      >
                        <option>Select Role</option>
                        <option value="admin">Admin</option>
                        <option value="member">Member</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-2">
                {saveLoading ? (
                  <center className="mt-3">
                    <LinearProgress />
                  </center>
                ) : null}
              </div>
              <div className="d-flex justify-content-end">
                <div className="mb-2">
                  <button
                    type="button"
                    onClick={createNewMember}
                    style={{
                      color: "#fff",
                      background: "#F7C948",
                    }}
                    className="btn btn-default"
                  >
                    Add members
                  </button>
                </div>
              </div>
            </DialogContent>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
};
export default CreateMemberModal;
