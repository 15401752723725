import React from 'react';
import Header from './Header/Header';
import './styles.scss';
import Footer from './Footer';

const PrintDashboardTemplate = (props) => {
  return (
    <div className='printDashboardTemplate_container'>
      <Header />
      <div className='mother-container'>
        {props.children}
        <Footer />
      </div>
    </div>
  );
};

export default PrintDashboardTemplate;
