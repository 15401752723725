import React, { useState } from "react";
import { LinearProgress } from "@material-ui/core";
import TestAutoReply from "./TestAutoReply";
import { makeStyles } from "@material-ui/core/styles";
import infoIcon from "../../../assets/icons/autoInfo.svg";
import cancelIcon from "../../../assets/icons/cancelGrey.svg";
// import Editor from "./CkEditor";
import TinyEditor from "containers/Tmail/Tmessage/TinyEditor";

const useStyles = makeStyles((theme) => ({
  info: {
    display: "flex",
    border: "1px solid #F0B429",
    padding: "10px",
    borderRadius: "8px",
    alignItems: "center",
    marginBottom: "3%",
    width: "1000px",
    marginTop: "-1%",
    backgroundColor: "#FFFAEB",
  },
  text: {
    color: "#DE911D",
    fontSize: "16px",
    fontWeight: "600",
  },

  textDesc: {
    fontSize: "13px",
    fontWeight: "500",
  },
  "@media screen and (max-width: 768px)": {
    form: {
      width: "85vw",
    },
  },
}));

const AutoReply = (props) => {
  const classes = useStyles();
  const [showInfo, setShowInfo] = useState(true);
  const {
    saveLoading,
    handleSubmit,
    handleTestSubmit,
    changeFromEmail,
    changeReplyTo,
    changeEmailSubject,
    changeSelectedStatus,
    fromEmail,
    replyTo,
    emailSubject,
    selectedStatus,
    changeReplyMessage,
    replyMessage,
    changeTestEmail,
    testEmail,
    formFilled,
    openAlert,
    handleClickOpen,
    closeAlert,
  } = props;

  const handleInfoShow = () => {
    setShowInfo(false);
  };

  return (
    <div style={{ width: "100%", marginLeft: "5px" }} className="row">
      <div>
        <div>
          {showInfo && (
            <div className={classes.info}>
              <div className="d-flex align-items-start">
                <img src={infoIcon} alt="" />
                <div style={{ marginLeft: "2%", width: "90%" }}>
                  <h5 className={classes.text}> How it Works</h5>
                  <p className={classes.textDesc}>
                    Our auto-reply feature activates when you respond to a
                    message sent through our Draft-Compose Email Interface,
                    which only supports emails configured within the Podio app.
                    This setup ensures that the app information and item record
                    can be easily included directly in your email reply.
                  </p>
                </div>
              </div>
              <div
                style={{
                  borderLeft: "1px solid #E1E1E1",
                  width: "10%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <img
                  style={{ cursor: "pointer" }}
                  onClick={handleInfoShow}
                  src={cancelIcon}
                  width={30}
                  alt=""
                />
              </div>
            </div>
          )}
        </div>
        <form className={classes.form}>
          <div className="d-flex">
            <div style={{ width: "485px" }} className="mr-4">
              <label className="mb-2" htmlFor="DomainId">
                From <span className="text-danger">*</span>
              </label>
              <input
                className="form-control"
                value={fromEmail}
                onChange={(e) => changeFromEmail(e.target.value)}
                placeholder="Enter Response"
              />
            </div>

            <div style={{ width: "485px" }}>
              <label className="mb-2" htmlFor="DomainId">
                Reply to
              </label>
              <input
                className="form-control"
                value={replyTo}
                onChange={(e) => changeReplyTo(e.target.value)}
                placeholder="Enter Response"
              />
            </div>
          </div>

          <div className="d-flex mt-3">
            <div style={{ width: "485px" }} className="mr-4">
              <label className="mb-2" htmlFor="DomainId">
                Subject
              </label>
              <input
                className="form-control"
                value={emailSubject}
                onChange={(e) => changeEmailSubject(e.target.value)}
                placeholder="Enter Response"
              />
            </div>

            <div style={{ width: "485px" }}>
              <label className="mb-2" htmlFor="DomainId">
                Status <span className="text-danger">*</span>
              </label>
              <select
                className="form-control"
                value={selectedStatus}
                onChange={(e) => changeSelectedStatus(e.target.value)}
                placeholder="Enter Response"
              >
                <option>Active</option>
                <option>In Active</option>
              </select>
            </div>
          </div>

          <div className="d-flex mt-3">
            <div style={{ width: "1000px" }}>
              <label className="mb-2" htmlFor="DomainId">
                Message <span className="text-danger">*</span>
              </label>
              <TinyEditor
                className="form-control"
                value={replyMessage}
                onChange={changeReplyMessage}
                placeholder="Enter Response"
                style={{ height: "221px" }}
              />

              {/* <Editor
                name="description"
                onChange={(data) => {
                  setData(data);
                }}
                editorLoaded={editorLoaded}
              /> */}
            </div>
          </div>

          <div style={{ textAlign: "right", marginTop: "10%" }}>
            <button
              className="btn btn-default mr-2 "
              onClick={handleClickOpen}
              disabled={!formFilled}
              style={
                formFilled
                  ? {
                      color: "#F0B429",
                      background: "#fff",
                      width: "156px",
                      height: "45px",
                      border: "1px solid #F0B429",
                    }
                  : {
                      color: "#B1B1B1",
                      background: "#fff",
                      width: "156px",
                      height: "45px",
                      border: "1px solid #B1B1B1",
                    }
              }
            >
              Test Auto Reply
            </button>

            <button
              className="btn btn-default mr-2 "
              onClick={handleSubmit}
              style={{
                color: "#fff",
                background: "#F7C948",
                width: "156px",
                height: "45px",
              }}
            >
              {formFilled ? "Update" : "Save"}
            </button>

            <div className="mt-2">
              {saveLoading ? (
                <center className="mt-3">
                  <LinearProgress />
                </center>
              ) : null}
            </div>
          </div>
        </form>
        <TestAutoReply
          testEmail={testEmail}
          handleTestSubmit={handleTestSubmit}
          changeTestEmail={changeTestEmail}
          openModal={openAlert}
          handleClose={closeAlert}
        />
      </div>
    </div>
  );
};

export default AutoReply;
