import React, { useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Typography,
    CircularProgress
} from "@material-ui/core";

// import "./style.css";
import { toast } from "helpers/apiRequests";
import Endpoint from "services/Endpoint";

const BuildItemModal = ({ openModal, handleClose,orgId, appId }) => {
    const [loading, setLoading] = useState(false);

    

    const buildItemReference = async () => {
        try {
            setLoading(true);
            const data = {
                org_id: orgId,
                app_id: appId
            };
           
            
            const res = await Endpoint.buildItemReference(data);
            console.log(res.data);
            setLoading(false);
            handleClose();
            toast.notify("Item Referencing has successfully been dispatched");
        } catch (error) {
          toast.notify("Unable to dispatch Item Reference", { type: "error" });
        }
      }
    
    



    return (
        <div>
            {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
            <Dialog
                open={openModal}
                onClose={handleClose}
                // hideBackdrop={true}
                disableBackdropClick={false}
                disableEscapeKeyDown={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                id="connector-modal"
            >
                <DialogContent>
                    {
                        !loading ? <>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Typography gutterBottom>
                                <p>You are about to build item references.<br />
                                Would you like to proceed?</p>
                            </Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button id="button-cancel" disabled={loading} onClick={handleClose} color="primary">No
                        </Button>
                            <Button
                                onClick={buildItemReference}
                                color="primary"
                                autoFocus
                            >
                                Yes
                            </Button>
                    </DialogActions>
                        </> : <CircularProgress size={24} />
                    }
                    
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default BuildItemModal;
