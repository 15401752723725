import { LinearProgress } from '@material-ui/core';
import React from 'react';


const ExtendedAppField = (props) => {
  const {
    fullNameFields,
    uniqueFields,
    uniqueOption,
    fullOption,
    changeUniqueSelector,
    changeFullSelector,
    changeUniqueRefApp,
    changeFullRefApp,
    orphLoading,
    uniqueRefApp,
    fullRefApp,
    fullContactField,
    uniqueContactField,
    changeUniqueContactField,
    changeFullContactField,
  } = props;

  const UniqueEmailOptions = uniqueFields.calculation.length === 0 ? null : uniqueFields.calculation.map(subFd => (
    <option key={subFd.id} value={subFd.id}>{subFd.name}</option>
  ));
  const UniqueAppOptions = uniqueFields.app_fields.length === 0 ? null : uniqueFields.app_fields.map(subFd => (
    <option key={subFd.app_id} value={subFd.app_id}>{subFd.name}</option>
  ));
  const FullEmailOptions = fullNameFields.calculation.length === 0 ? null : fullNameFields.calculation.map(subFd => (
    <option key={subFd.id} value={subFd.id}>{subFd.name}</option>
  ));
  const FullAppOptions = fullNameFields.app_fields.length === 0 ? null : fullNameFields.app_fields.map(subFd => (
    <option key={subFd.app_id} value={subFd.app_id}>{subFd.name}</option>
  ));

  return (
    <>
      <div class="form-group mb-4 mt-2">
        <label for="">Where is the recipients UniqueID stored?</label>
        <select
          class="form-control"
          value={uniqueOption}
          onChange={(e) => changeUniqueSelector(e.target.value)}
        >
          <option value="one">not applicable - I will manually add recipients UniqueID to each eMail.</option>
          <option value="three">in a single-line text or calculation field within this application.</option>
          <option value="four">in a single-line text field or calculation field within a referenced application.</option>
        </select>
      </div>
      {
        uniqueOption === "three" ? <div class="form-group mb-4 mt-4">
          <label for="fieldEmail">Select the field that contains the recipients UniqueID.</label>
          <select
            class="form-control"
            value={uniqueContactField}
            onChange={(e) => changeUniqueContactField(e.target.value)}
          >
            <option>Select</option>
            {UniqueEmailOptions}
          </select>
        </div> : null
      }
      {
        uniqueOption === "four" ?
          <>
            <div className="form-group mb-4 mt-4">
              <label htmlFor="refApp">Select Referenced App</label>
              <select
                className='form-control'
                value={uniqueRefApp}
                onChange={(e) => changeUniqueRefApp(e.target.value)}
              >
                <option>Select</option>
                {UniqueAppOptions}
              </select>
            </div>
            {orphLoading.unique ? <LinearProgress /> :
              <div className="form-group mb-4 mt-4">
                <label htmlFor="fieldEmail">Select Field that contains Email Address(es)</label>
                <select
                  className='form-control'
                  value={uniqueContactField}
                  onChange={(e) => changeUniqueContactField(e.target.value)}
                >
                  <option>Select</option>

                  {UniqueEmailOptions}
                </select>
              </div>
            }

          </> : null
      }
      <div class="form-group mb-4 mt-2">
        <label for="">Where is the recipients full name stored?</label>
        <select
          class="form-control"
          value={fullOption}
          onChange={(e) => changeFullSelector(e.target.value)}
        >
          <option value="one">not applicable - I will manually add the recipients name to each eMail.</option>
          <option value="three">in a single-line text field or calculation field within this application.</option>
          <option value="four">in a single-line text field or calculation field within a referenced application.</option>
        </select>
      </div>
      {
        fullOption === "three" ? <div class="form-group mb-4 mt-4">
          <label for="fieldEmail">Select the field that contains the recipients full name.</label>
          <select
            class="form-control"
            value={fullContactField}
            onChange={(e) => changeFullContactField(e.target.value)}
          >
            <option>Select</option>
            {FullEmailOptions}
          </select>
        </div> : null
      }
      {
        fullOption === "four" ?
          <>
            <div className="form-group mb-4 mt-4">
              <label htmlFor="refApp">Select Referenced App</label>
              <select
                className='form-control'
                value={fullRefApp}
                onChange={(e) => changeFullRefApp(e.target.value)}
              >
                <option>Select</option>
                {FullAppOptions}
              </select>
            </div>
            {orphLoading.full ? <LinearProgress /> :
              <div className="form-group mb-4 mt-4">
                <label htmlFor="fieldEmail">Select Field that contains Email Address(es)</label>
                <select
                  className='form-control'
                  value={fullContactField}
                  onChange={(e) => changeFullContactField(e.target.value)}
                >
                  <option>Select</option>

                  {FullEmailOptions}
                </select>
              </div>
            }

          </> : null
      }
    </>
  )
}

export default ExtendedAppField