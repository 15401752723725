import React from 'react';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';

import useAsync from 'components/templates/print-v2/hooks/useAsync';
import { toast } from 'helpers/apiRequests';

import { usePrintData } from 'components/templates/print-v2/context/PrintProvider';
import ManageSignatures from './ManageSignatures/ManageSignatures';
import SelectSignees from './SelectSignees';
import CustomAlert from '../../../../components/CustomAlert';
import ViewSignedDocument from './ViewSignedDocument/ViewSignedDocument';
import { disconnectRightSignatureUser, uploadSignedDocToPodio, inviteSigners } from 'components/templates/print-v2/printUtils/requests';

import styles from './SignatureFields.module.scss';
import ConfirmUploadForSigning from './ConfirmUploadForSigning/ConfirmUploadForSigning';

const RIGHT_SIGNATURE_CLIENT_ID = process.env.REACT_APP_RIGHT_SIGNATURE_CLIENT_ID;

const SignatureFields = (props) => {
  const { selectedSigner, selectedItem, template } = usePrintData();
  const [connected, setConnected] = React.useState(false);
  const [manageOpen, setManageOpen] = React.useState(false);
  const [signees, setSignees] = React.useState([]);
  const [processing, setProcessing] = React.useState(false);
  const [viewSignedDocument, setViewSignedDocument] = React.useState(false);
  const [isConfirmUploadOpen, setIsConfirmUploadOpen] = React.useState(false);

  const templateId = template?.id;

  const handleClose = () => {
    setManageOpen(false);
    props.refreshTemplate();
  };

  const isSignatureAuthenticated = useSelector((state) => state?.user?.print_data?.signature_authentication);

  React.useEffect(() => {
    if (isSignatureAuthenticated === true) {
      setConnected(true);
    } else {
      setConnected(false);
    }
  }, [isSignatureAuthenticated]);

  React.useEffect(() => {
    if (props.signatureData && props?.signatureData?.signers?.length > 0) {
      setSignees(() => {
        return props?.signatureData?.signers.map(parseFetchedSigner);
      });
    }
  }, [props.signatureData]);

  function toggleAuthentication() {
    setProcessing(true);
    const REDIRECT_URI = `${window.location.origin}/print-data/signature/auth?template_id=${templateId}`;
    if (!connected) {
      window.open(
        `https://api.rightsignature.com/oauth/authorize?client_id=${RIGHT_SIGNATURE_CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=read write`,
        `_self`
      );
    } else {
      disconnectRightSignatureUser()
        .then(() => {
          setConnected(false);
          toast.notify('Disconnected Sharefile Successfully');
        })
        .catch(console.error)
        .finally(() => setProcessing(false));
    }
  }

  const { status: uploadToPodioStatus, execute } = useAsync(() => {
    const payload = {
      template_id: templateId,
      item_id: selectedItem?.value,
    };

    return uploadSignedDocToPodio(payload).then(() => toast.notify('Successfully Uploaded Document to Podio'));
  }, false);

  function handleUploadToPodio() {
    return execute();
  }

  const { execute: executeSave, status: saveStatus } = useAsync((payload) => {
    return inviteSigners(payload).then(() => {
      toast.notify('Signers Saved Successfully');
      handleClose();
    });
  }, false);

  const hasSignees = signees.length > 0;

  return (
    <>
      {isConfirmUploadOpen && (
        <ConfirmUploadForSigning
          template_id={templateId}
          open={isConfirmUploadOpen}
          handleClose={() => setIsConfirmUploadOpen(false)}
          item_id={selectedItem?.value}
        />
      )}

      {viewSignedDocument && (
        <ViewSignedDocument
          template_id={templateId}
          open={viewSignedDocument}
          handleClose={() => setViewSignedDocument(false)}
          item_id={selectedItem?.value}
        />
      )}

      {manageOpen && <ManageSignatures {...{ open: manageOpen, handleClose, signees, setSignees, templateId, executeSave, saveStatus }} />}

      <div className={styles.container}>
        <Box className='mb-3'>
          <button
            type='button'
            className={`${!connected ? styles.blueButton_outline : styles.redButton_outline} w-100`}
            disabled={!templateId || processing}
            onClick={toggleAuthentication}>
            {connected ? 'Disconnect RightSignature' : 'Connect RightSignature'}
          </button>
        </Box>

        {connected ? (
          <>
            <div className={styles.actionWrapper}>
              <p>{signees.length} Signer(s) added</p>
              <button
                type='submit' //we set type="submit" because we want the template to be saved before the modal is opened
                className={styles.manageButton}
                onClick={() => setManageOpen(true)}
                disabled={props.fetchTemplateStatus === 'pending'}>
                {hasSignees ? 'Manage' : 'Add'}
              </button>
            </div>
            {hasSignees ? <SelectSignees {...{ signees }} /> : null}
            {!selectedSigner ? (
              <Box className='mb-2 mt-1'>
                <CustomAlert title='Select a signer to enable all signature functions' />{' '}
              </Box>
            ) : null}

            {selectedSigner ? (
              <div className={styles.signeeActionsWrapper}>
                {actions.map((action, index) => (
                  <Field label={action.label} value={action.value} alphabetKey={selectedSigner?.value} key={index} disabled={!selectedSigner} />
                ))}
              </div>
            ) : null}

            <div className={styles.buttons_container}>
              <button type='submit' className='btn btn-success' disabled={props.savingTemplate}>
                {props.savingTemplate ? 'Saving...' : 'Save'}
              </button>

              <button
                type='button'
                className={`btn btn-success`}
                disabled={!templateId || !selectedItem?.value || processing}
                onClick={() => setIsConfirmUploadOpen(true)}>
                Upload for Signing
              </button>
              <button
                type='button'
                className={`btn ${styles.blueButton}`}
                disabled={!templateId || processing || !selectedItem?.value}
                onClick={() => setViewSignedDocument(true)}>
                Retrieve Signed Document
              </button>
              <button
                type='button'
                className={`btn ${styles.blueButton}`}
                disabled={!templateId || !selectedItem?.value || processing || uploadToPodioStatus === 'pending'}
                onClick={handleUploadToPodio}>
                {uploadToPodioStatus === 'pending' ? 'Uploading...' : 'Upload to Podio'}
              </button>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default SignatureFields;

const actions = [
  { label: 'Signature', value: 's' },
  { label: 'Initials', value: 'i' },
  { label: 'Text field', value: 't' },
  { label: 'Date field', value: 'd' },
  { label: 'Checkbox', value: 'c' },
];

function Field({ label, value, alphabetKey, disabled }) {
  function handleClick() {
    if (window.CKEDITOR) {
      const editor = window.CKEDITOR;
      const selection = editor.model.document.selection;
      const position = selection.getFirstPosition();

      window.CKEDITOR.model.change((writer) => {
        return writer.insertText(`[ ${value}:${alphabetKey}:r ]`, position);
      });

      // hack to prevent variable being pasted multiple times witn enter btn is pressed
      document.activeElement.blur();
    }
  }

  return (
    <Box className='mt-2 mb-2'>
      <button className={styles.field} type='button' onClick={handleClick} disabled={disabled}>
        {label}
      </button>
    </Box>
  );
}

function parseFetchedSigner(data) {
  const message = {
    value: data.message,
  };

  const email = {
    valueObject: {
      label: data.email.label,
      value: data.email.field_id,
      external_id: '',
    },
    name: 'email',
  };

  const name = {
    valueObject: {
      label: data.name.label,
      value: data.name.field_id,
      external_id: '',
    },
    name: 'name',
  };

  return {
    uuid: data.uuid,
    app_id: data.app_id,
    item_id: data.item_id,
    field_app_location: data.field_app_location,
    order: data.order,
    message,
    email,
    name,
  };
}
