import "date-fns";
import React from "react";
import TextField from "@material-ui/core/TextField";
import styled from "@emotion/styled";
import { editItem } from "../../../services/sync";

const MaterialUIPickers = (props) => {
  const handleChange = (event) => {
    if (props.value !== event.target.value) {
      const data = {
        value: event.target.value,
        item_id: props.data._item_id,
        external_id: props.colDef.field,
        type: "date",
      };

      editItem(data)
        .then(() => {})
        .catch(() => {});
    }
  };

  return (
    <React.Fragment>
      {props.value === undefined ? (
        <div></div>
      ) : (
        <MaterialUIPickers.Wrapper>
          <TextField
            id="date"
            type="date"
            defaultValue={props.value}
            onChange={handleChange}
            style={{ color: "white" }}
            InputLabelProps={{
              shrink: true,
              color: "white",
            }}
          />
        </MaterialUIPickers.Wrapper>
      )}
    </React.Fragment>
  );
};

MaterialUIPickers.Wrapper = styled.div`
  .MuiInputBase-root,
  .MuiInput-root,
  .MuiInput-underline,
  .MuiInputBase-formControl,
  .MuiInput-formControl {
    color: #f5f5f5;
    font-size: 12px;
  }
`;

export default MaterialUIPickers;
