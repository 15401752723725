import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';


const useStyles = makeStyles((theme) => ({
  title: {
    color: "#263238",
    fontSize: "25px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal"
  },
  tab: {
    padding: 0
  }
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const AppSetting = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className=''>
      <h4 className={`${classes.title} mb-4`}>Configure App Projects</h4>

      <Tabs value={value} onChange={handleChange} indicatorColor="primary" aria-label="simple-tabs-example">
        <Tab label="Item One" className={classes.tab} {...a11yProps(0)} />
        <Tab label="Item Two" className={classes.tab} {...a11yProps(1)} />
        <Tab label="Item Three" className={classes.tab} {...a11yProps(2)} />
      </Tabs>
    </div>
  )
}

export default AppSetting;