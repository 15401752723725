import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import RevisionIcon from "@material-ui/icons/AssignmentInd";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "../../../components/atoms/Button";
import CardMedia from "@material-ui/core/CardMedia";
import Endpoint from "services/Endpoint";
import { Alert } from "@material-ui/lab";
import VisibilityIcon from "@material-ui/icons/Visibility";
import RestoreIcon from "@material-ui/icons/Restore";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import "./style.css";
import RenderByPermission from "components/RenderByPermission";
import cancel from "../../../assets/icons/cancel.svg";
// import { useDispatch } from "react-redux";
// import { addItem, removeItem } from "../../../redux/actions/selectedItemSlice";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    marginRight: theme.spacing(1),
    textAlign: "center",
    float: "right",
    background: "#FFF3C4",
    borderRadius: "3px",
    color: "#DE911D",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const GridActions = (props) => {
  const classes = useStyles();
  // const dispatch = useDispatch();

  const [spaceSync, syncModalData] = useState({
    message: null,
    modal_type: null,
    modal_value: false,
    modal_data: {},
  });
  const [snack, setSnack] = useState({
    message: null,
    snackType: null,
    isSnack: false,
    reload: false,
  });

  const [updatingItemLoader, setUpdatingItemLoader] = useState(false);
  //Action Display
  const data = typeof props["data"] !== "undefined" ? props["data"] : null;

  //opens the modal for confirmation to delete item
  const deleteItemModal = (org, item_id) => {
    syncModalData({
      message: "Do you want to remove this item from Podio?",
      modal_type: "remove_item",
      modal_value: true,
      modal_data: { org, item_id },
    });
  };

  const restoreItemModal = (org, item_id) => {
    syncModalData({
      message: "Do you want to restore this item to Podio?",
      modal_type: "restore_item",
      modal_value: true,
      modal_data: { org, item_id },
    });
  };

  const deleteItem = async (org, item_id) => {
    setUpdatingItemLoader(true);
    const data = {
      item_id,
      org_id: org,
    };
    try {
      await Endpoint.deleteItem(data);
      setSnack({
        message: "Deleted successfully",
        snackType: "success",
        isSnack: true,
        reload: true,
      });
    } catch (err) {
      setSnack({
        message: "Failed to delete, try again later!",
        snackType: "error",
        isSnack: true,
        reload: false,
      });
    }
    setUpdatingItemLoader(false);
    syncModalData({
      message: null,
      modal_type: null,
      modal_value: false,
      modal_data: {},
    });
  };

  const restoreItem = async (org, item_id) => {
    setUpdatingItemLoader(true);

    try {
      let items = [];
      items.push(item_id);
      const data = {
        item_ids: JSON.stringify(items),
        org_id: org,
      };

      await Endpoint.restoreItem(data);
      setSnack({
        message: "Restored successfully",
        snackType: "success",
        isSnack: true,
        reload: true,
      });
    } catch (err) {
      setSnack({
        message: "Failed to restore, try again later!",
        snackType: "error",
        isSnack: true,
        reload: false,
      });
    }
    setUpdatingItemLoader(false);
    syncModalData({
      message: null,
      modal_type: null,
      modal_value: false,
      modal_data: {},
    });
  };

  const confirm_handleClose = () => {
    syncModalData({
      message: null,
      modal_type: null,
      modal_value: false,
      modal_data: {},
    });
  };

  const handleClose = () => {
    if (snack.reload) {
      props.context.Items();
    }
    setSnack({ message: "", snackType: "info", isSnack: false, reload: false });
  };

  // revision data
  const [revisionData, setRevisionData] = React.useState({
    message: null,
    modal_type: null,
    modal_value: false,
    modal_data: {},
  });

  const revisionModal = (org, name, date, image) => {
    setRevisionData({
      name: name,
      date: date,
      image: image,
      modal_type: "remove_item",
      modal_value: true,
      modal_data: { org, name, date, image },
    });
  };

  const closeRevisionData = () => {
    setRevisionData({
      message: null,
      modal_type: null,
      modal_value: false,
      modal_data: {},
    });
  };

  if (!data) {
    return <React.Fragment></React.Fragment>;
  }

  // const handleCheckboxChange = (itemId, orgId, isChecked) => {
  //   const selectedItem = { _org_id: orgId, _item_id: itemId };
  //   if (isChecked) {
  //     dispatch(addItem(selectedItem));
  //     console.log(selectedItem);
  //   } else {
  //     dispatch(removeItem(itemId));
  //     console.log(selectedItem);
  //   }
  // };

  let revision_data_list = null;

  if (data.revision_data) {
    revision_data_list = data.revision_data
      .slice(0)
      .reverse()
      .map((item, list) => {
        const revision_data_date = item.created_on;
        const revision_date = revision_data_date.substring(0, 10);
        const revision_time = revision_data_date.substring(
          revision_data_date.length - 8,
          revision_data_date.length
        );

        return (
          <div className="review-cards" key={list}>
            <CardMedia
              component="img"
              alt="User Image"
              image={
                item.created_by.image.length < 1
                  ? "https://www.gnd.center/bpm/resources/img/avatar_placeholder_small.png"
                  : item.created_by.image.thumbnail_link
              }
              title="User Image"
              className="cardMedia"
            />
            <div className="revision-info">
              <div id="form-dialog-title" className="revision-modal-name">
                {item.created_by.name}
              </div>
              <DialogContent className="revision-time-stamp">
                {
                  <DialogContentText>
                    Created on <b>{revision_date}</b> at <b>{revision_time}</b>
                  </DialogContentText>
                }
              </DialogContent>
            </div>
          </div>
        );
      });
    // end revision data
  }

  return (
    <>
      {data !== null && data.check_deletion === true ? (
        <React.Fragment>
          <Tooltip title="Restore Item" style={{ marginTop: "-10px" }}>
            <IconButton
              aria-label="Restore Item"
              onClick={() => restoreItemModal(data._org_id, data._item_id)}
            >
              <RestoreIcon style={{ color: "#FFF" }} />
            </IconButton>
          </Tooltip>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <RenderByPermission allowTeamMemberAccess={false}>
            <Tooltip title="Delete from Podio" style={{ marginTop: "-10px" }}>
              <IconButton
                onClick={() => deleteItemModal(data._org_id, data._item_id)}
                aria-label="Delete from Podio"
              >
                <DeleteIcon style={{ color: "#d11a2a" }} />
              </IconButton>
            </Tooltip>
          </RenderByPermission>
          <Tooltip title="View Item on Podio" style={{ marginTop: "-10px" }}>
            <IconButton
              aria-label="View Item on Podio"
              onClick={() => {
                window.open(data.item_link, "_blank");
              }}
            >
              <VisibilityIcon style={{ color: "#4cbcd9" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="View Revision" style={{ marginTop: "-10px" }}>
            <IconButton
              onClick={() => {
                if (data.revision_data) {
                  revisionModal(
                    data.revision_data,
                    data.revision_data[0].created_by.name,
                    data.revision_data[0].created_on,
                    data.revision_data[0].created_by.image.thumbnail_link
                  );
                } else {
                  setSnack({
                    message: "There is no revision for this item.",
                    snackType: "error",
                    isSnack: true,
                    reload: false,
                  });
                }
              }}
              aria-label="View Revision"
            >
              <RevisionIcon style={{ color: "#4cbcd9" }} />
            </IconButton>
          </Tooltip>
        </React.Fragment>
      )}
      <Dialog
        open={snack.isSnack}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <Alert onClose={handleClose} severity={snack.snackType}>
          {snack.message}
        </Alert>
      </Dialog>
      <Dialog
        open={spaceSync.modal_value}
        onClose={confirm_handleClose}
        aria-labelledby="form-dialog-title"
      >
        <div
          style={{ width: "500px" }}
          className="d-flex align-items-center justify-content-between"
        >
          {data.check_deletion === true ? (
            <DialogTitle id="form-dialog-title">Restore Item</DialogTitle>
          ) : (
            <DialogTitle id="form-dialog-title">Delete from Podio</DialogTitle>
          )}
          <img
            style={{ marginRight: "4%" }}
            onClick={confirm_handleClose}
            src={cancel}
            alt=""
          />
        </div>

        <DialogContent style={{ textAlign: "center" }}>
          <p className="mb-0" style={{ fontSize: "16px" }}>
            {spaceSync.message}
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            style={
              updatingItemLoader
                ? {
                    background: `grey`,
                    color: `#000, marginRight: "3%", marginBotton: "2%"`,
                  }
                : {
                    background: `#F7C948`,
                    color: `#fff`,
                    marginRight: "3%",
                    marginBotton: "2%",
                  }
            }
            function={
              spaceSync.modal_type === "remove_item"
                ? () =>
                    deleteItem(
                      spaceSync.modal_data.org,
                      spaceSync.modal_data.item_id
                    )
                : () =>
                    restoreItem(
                      spaceSync.modal_data.org,
                      spaceSync.modal_data.item_id
                    )
            }
            text={updatingItemLoader ? "PLEASE WAIT..." : "Yes"}
          />
          {updatingItemLoader && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={revisionData.modal_value}
        onClose={closeRevisionData}
        aria-labelledby="form-dialog-title"
        className="revisionData MuiDialog-paperWidthLg"
      >
        <h1 className="revision-modal-header">Review</h1>
        <div className="review-container">
          {revision_data_list && revision_data_list}
        </div>
        <DialogActions>
          <Button function={closeRevisionData} color="primary" text={"CLOSE"} />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GridActions;
