import React from 'react'
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  bg: {
    borderRadius: '3px',
    background: 'rgba(20, 109, 243, 0.10)',
  },
  text: {
    color: "#146DF3",
    fontFamily: 'Mulish',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal'
  }
}));

const AlertDiv = ({ message, marginBottom }) => {
  const classes = useStyles();

  return (
    <div className={"alert alert-primary border-0 " + (marginBottom || "mb-5") + ' ' + classes.bg + ' ' + classes.text} role="alert">
      {message}
    </div>
  )
}

export default AlertDiv
