import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  CircularProgress,
} from "@material-ui/core";

import "./style.css";
import { toast } from "helpers/apiRequests";
import Endpoint from "services/Endpoint";
import cancel from "../../../assets/icons/cancel.svg";

const ResyncMysqlV2 = ({ openModal, handleClose, itemData, user }) => {
  const [loading, setLoading] = useState(false);

  const resyncMysql = async () => {
    try {
      setLoading(true);
      const data = {
        org_id: itemData.org_id,
        app_id: itemData.app_id,
        user_id: user.database.user_id,
        credential_ids: [itemData.credential_id],
      };

      const res = await Endpoint.resyncMysql(data);
      console.log(res.data);
      setLoading(false);
      handleClose();
      toast.notify("Your Mysql Resync has started");
      //   reloadUser();
    } catch (error) {
      toast.notify("Unable to sync table", { type: "error" });
    }
  };

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        open={openModal}
        onClose={handleClose}
        // hideBackdrop={true}
        disableBackdropClick={false}
        disableEscapeKeyDown={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="connector-modal"
      >
        <DialogContent>
          {!loading ? (
            <>
              <DialogContent>
                <div className="d-flex align-items-center justify-content-between w-100 mt-2 mb-5">
                  <h5 className="mapper_field_text">Sync Mapper</h5>
                  <div>
                    <img onClick={handleClose} src={cancel} alt="" />
                  </div>
                </div>
                <DialogContentText id="alert-dialog-description">
                  <Typography gutterBottom>
                    <p>
                      You are about to Sync a MySQL Mapper.
                      <br />
                      Would you like to proceed?
                    </p>
                  </Typography>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  id="button-cancel"
                  disabled={loading}
                  onClick={handleClose}
                  color="primary"
                >
                  No
                </Button>
                <Button onClick={resyncMysql} color="primary" autoFocus>
                  Yes
                </Button>
              </DialogActions>
            </>
          ) : (
            <CircularProgress size={24} />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ResyncMysqlV2;
