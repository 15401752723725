import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import Axios from '../../config';

function refreshInvoices() {
  return Axios.get('/refresh/invoices');
}

const InvoiceAlertModal = ({ openModal, handleClose }) => {
  const [loading, setLoading] = React.useState(false);

  function handleRefresh() {
    setLoading(true);

    return refreshInvoices()
      .then(() => {
        window.location.reload();
        handleClose();
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            openModal(false);
          }
        }}
        // hideBackdrop={true}
        disableEscapeKeyDown={true}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        id='connector-modal'>
        <DialogContent>
          <DialogContent>
            <div id='alert-dialog-description'>
              <div>
                <h5 className='mb-4'>Are you sure you want to refresh Invoices?</h5>
                <div className='d-flex justify-content-end mt-5'>
                  <button
                    type='button'
                    className='btn btn-light mr-2'
                    style={{
                      color: '#000',
                      background: 'transparent',
                      border: '1px solid grey',
                      fontSize: '.9rem',
                    }}
                    onClick={handleClose}
                    disabled={loading}>
                    Cancel
                  </button>

                  <button
                    type='button'
                    className='btn btn-warning text-white'
                    style={{ fontSize: '.9rem' }}
                    onClick={handleRefresh}
                    disabled={loading}>
                    {loading ? 'Refreshing ...' : 'Refresh'}
                  </button>
                </div>
              </div>
            </div>
          </DialogContent>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default InvoiceAlertModal;
