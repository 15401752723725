import React from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Typography,
} from "@material-ui/core";


const EmailStatusModal = ({ openModal, handleClose, statistic, title }) => {


    return (
        <div>
            <Dialog
                open={openModal}
                onClose={handleClose}
                // hideBackdrop={true}
                disableEscapeKeyDown={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <Box style={{ padding: "20px", width: "500px" }}>
                        <Typography id="modal-modal-title" variant="h6" component="p" className="mb-3">
                            {title} Status
                        </Typography>
                        <ul className="list-group">
                            <li className="list-group-item">
                                Clicked
                                <span className="float-right">{statistic ? statistic.clicked : 0}</span>
                            </li>
                            <li className="list-group-item">
                                Complained
                                <span className="float-right">{statistic ? statistic?.complained : 0}</span>
                            </li>
                            <li className="list-group-item">
                                Delivered
                                <span className="float-right">{statistic ? statistic?.delivered : 0}</span>
                            </li>
                            <li className="list-group-item">
                                Opened
                                <span className="float-right">{statistic ? statistic?.opened : 0}</span>
                            </li>
                            <li className="list-group-item">
                                Permanent Fail
                                <span className="float-right">{statistic ? statistic?.permanent_fail : 0}</span>
                            </li>
                            <li className="list-group-item">
                                Temporary Fail
                                <span className="float-right">{statistic ? statistic?.temporary_fail : 0}</span>
                            </li>
                            <li className="list-group-item">
                                Unsubscribed
                                <span className="float-right">{statistic ? statistic?.unsubscribed : 0}</span>
                            </li>
                        </ul>
                    </Box>
                </DialogContent>
                <DialogActions>
                        <Button id="button-cancel" className="mr-4 " onClick={handleClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
            </Dialog>
        </div>
    );
};

export default EmailStatusModal;
