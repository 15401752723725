import React, { useState } from "react";
import "./ActionsRenderer.css";
import Confirm from "../../../../components/Modal/Confirm";
import PreviewWorkflowModal from "components/Modal/Connector/PreviewWorkflowModal";
import { downloadFlow, previewFlow } from "../../../../services/sync";
import CreateNewDataMapModalV2 from "../../../../components/Modal/Connector/CreateNewDataMapModalV2";
import { useSelector } from "react-redux";
import CredentialModal from "../../../../components/Modal/CredentialModal";


const WorkFlowActionsRender = (props) => {
  const initial = {
    message: null,
    modal_type: null,
    title: "",
    modal_value: false,
    modal_data: {},
    popup: false,
  };
  const [syncModal, syncModalData] = useState(initial);
  const [preLoader, setPreLoader] = useState(false);
  const [openItemModal, setOpenItemModal] = useState(false);
  const [fields, setFields] = useState({});
  const connectors = useSelector((state) => state.connector.database);
  const [open, setOpen] = useState(false);
  const [selectedConnector, setSelectedConnector] = useState(null);
  const [responseData, setResponseData] = useState(null);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);

  //Mysql Connector
  const handleClickOpenItemModal = () => {
    setOpenItemModal(true);
  };

  const handleCloseItemModal = () => {
    setOpenItemModal(false);
    setFields({});
  };
  //Action
  const handleSubmit = async (type) => {
    let data;
    setPreLoader(true);

    if (type === "previewFlow") {
      data = {
        org_id: syncModal.modal_data.orgIdString,
        space_id: syncModal.modal_data.spaceIdString,
        flow_id: syncModal.modal_data.flowIdString,
      };
      try {
        let response = await previewFlow(data);
        console.log(response.data.content);
        setResponseData(response.data.content);
        setPreviewModalOpen(true);
        setPreLoader(false);
        syncModalData(initial);
        // window.document.getElementById("refreshThisGrid").click();
      } catch {
        setPreLoader(false);
        syncModalData(initial);
      }
    }

    if (type === "downloadflow") {
      data = {
        org_id: syncModal.modal_data.orgIdString,
        space_id: syncModal.modal_data.spaceIdString,
        flow_id: syncModal.modal_data.flowIdString,
      };
      try {
        await downloadFlow(data);
        // const url = window.URL.createObjectURL(new Blob([response.data]));
        // const a = document.createElement("a");
        // a.href = url;
        // a.download = "workflow.xml";
        // a.click();
        // window.URL.revokeObjectURL(url);

        setPreLoader(false);
        syncModalData(initial);
        // window.document.getElementById("refreshThisGrid").click();
      } catch (e) {
        console.error("Error:", e);
        setPreLoader(false);
        syncModalData(initial);
      }
    }
  };

  const previewFlowPopup = (org, space, flow) => {
    const orgIdString = org.toString();
    const spaceIdString = space.toString();
    const flowIdString = flow.toString();
    syncModalData({
      message: "Do you want to preview this flow?",
      modal_type: "previewFlow",
      title: "Preview Flow",
      modal_value: true,
      modal_data: { orgIdString, spaceIdString, flowIdString },
      popup: true,
    });
  };

  const downloadFlowPopup = (org, space, flow) => {
    console.log(org, space, flow);
    const orgIdString = org?.toString();
    const spaceIdString = space?.toString();
    const flowIdString = flow?.toString();
    syncModalData({
      message: "Do you want to download this workflow?",
      modal_type: "downloadflow",
      title: "Download Workflow",
      modal_value: true,
      modal_data: { orgIdString, spaceIdString, flowIdString },
      popup: true,
    });
  };

  const changeSelectedConncector = (val) => {
    setSelectedConnector(val);
  };
  const closeCredentialModal = () => {
    setOpen(false);
  };

  const data = typeof props["data"] !== "undefined" ? props["data"] : null;
  //   console.log("workflow data", data);

  const toggleModal = () => {
    syncModalData(initial);
  };

  return (
    <>
      <CredentialModal
        openModal={open}
        handleClose={closeCredentialModal}
        connector={selectedConnector}
        changeConnector={changeSelectedConncector}
        connectors={connectors}
        saveMapper={handleClickOpenItemModal}
      />
      <Confirm
        className="confirm-modal-container"
        openModal={syncModal.modal_value}
        message={syncModal.message}
        action={() => handleSubmit(syncModal.modal_type)}
        title={syncModal.title}
        toggleModal={toggleModal}
        modalLoader={preLoader}
      />

      <PreviewWorkflowModal
        className="confirm-modal-container"
        openModal={previewModalOpen}
        toggleModal={() => setPreviewModalOpen(false)}
        modalLoader={preLoader}
        responseData={responseData}
        flowId={data.flow_id}
      />

      <CreateNewDataMapModalV2
        openItemModal={openItemModal}
        handleCloseItemModal={handleCloseItemModal}
        selectedApp={fields}
        isConnector={false}
        connectorId={selectedConnector}
      />

      <>
        {data.file_count ? null : (
          <i
            className="fa fa-eye"
            style={{
              fontSize: "1.50em",
              marginRight: "10px",
              cursor: "pointer",
            }}
            title="Preview"
            onClick={() =>
              previewFlowPopup(data.org_id, data.space_id, data.flow_id)
            }
          ></i>
        )}

        <i
          className="fa fa-download"
          style={{
            fontSize: "1.50em",
            marginRight: "10px",
            cursor: "pointer",
          }}
          title="Download"
          onClick={() => {
            downloadFlowPopup(data.org_id, data.space_id, data.flow_id);
          }}
        ></i>
      </>
    </>
  );
};

export default WorkFlowActionsRender;
