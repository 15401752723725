import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PersonIcon from '@material-ui/icons/Person';
import PersonAdd from '@material-ui/icons/PersonAdd';
import Swal from "sweetalert2";
import Endpoint from './Endpoint';
import { toast } from "helpers/apiRequests";
import Spinner from "./Loader/Spinner";
// import { Button, CircularProgress, } from '@material-ui/core';

const ImitateAction = (props) => {
  const [loading, setLoading] = React.useState(false);
  //Action Display

  const makePodioPartner = (user) => {
    try {
      const x = user.user_id_extension;
    const user_id = user.id

    if (x.toLowerCase() === "not connected") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text:
          "Podio is not connected for this user, you can't make them a podio partner.",
      });
      return false;
    } else {
      Swal.fire({
        icon: "warning",
        title: "Make Podio Partner",
        text: "Do you want to make this user a Podio Partner?",
        showCancelButton: true,
        confirmButtonColor: '#5CB85D',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: "Cancel",
      }).then(async (result) => {
        
        if (result.value) {
          const payload = {
            user_id
          }
          // Swal.fire({
          //   html: <div class="loader">Loading...</div>,
          //   timer: 3000
          // })
          setLoading(true)
          const res = await Endpoint.makePodioPartner(payload);
          setLoading(false)
          Swal.fire({
            title: "User added as Podio Partner",
            icon: res.data.status === true ? "success" : "error",
            text: res.data.message,
          }).then(() => {
            if(res.data.status ===  true){
              window.location.reload();

            }
          })
        }
      })
    }
    } catch (error) {
      setLoading(false)
      toast.notify(
        "Unable to add user as a Podio Partner. Pleas try again", { type: "error" });
    }
  }
  const data = typeof props["data"] !== "undefined" ? props["data"] : null;
  if (loading) {
    <Spinner />
  }

  return (
    <>
      {data !== null ? (
        <>
          <Tooltip title="Imitate User" style={{ marginTop: "-10px" }}>
            <IconButton
              aria-label="Imitate User"
              onClick={() => {
                const x = data.user_id_extension;

                if (x.toLowerCase() === "not connected") {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text:
                      "Podio is not connected for this user, you can't imitate their account at this time.",
                  });
                  return false;
                } else {
                  window.location.href = `/imitate-account?imitate_user=true&task=${data.id}`;
                }
              }}
            >
              <VisibilityIcon style={{ color: "#FFF" }} />
            </IconButton>
          </Tooltip>
          {
            !data.user_is_podio_partner ?
              <Tooltip title="Make Podio Partner" style={{ marginTop: "-10px" }}>
                <IconButton
                  onClick={() => makePodioPartner(data)}
                  aria-label="Make Podio Partner"
                >
                  <PersonAdd style={{ color: "#FFF" }} />
                </IconButton>
              </Tooltip>
              :
              <Tooltip title="Podio Partner">
                <IconButton
                  // onClick={() => makePodioPartner(data)}
                  disabled={true}
                  className="btn btn-secondary btn-sm"

                >
                  <PersonIcon style={{color: "rgb(247,201,72)"}} />
                </IconButton>
              </Tooltip>

          }

        </>
      ) : (
        ""
      )}
    </>
  );
};

export default ImitateAction;
