import React, { useState, useEffect } from "react";
import thatappLogo from "../../assets/icons/thatapplogo.svg";
import radioChecked from "../../assets/icons/radioIconChecked.svg";
import "./NewBilling.css";
import { CircularProgress } from "@mui/material";
import { initiateTrial } from "services/sync";
import toaster from "toasted-notes";
import { useSelector, useDispatch } from "react-redux";
// import TextField from "@material-ui/core/TextField";
import { toast } from "react-toastify";
import { getMe } from "services/auth";
import { useHistory } from "react-router-dom";
// import axios from "axios";
import { logout } from "redux/actions/auth";
// import { setUser } from "redux/actions/user";
import AddCardComponent from "containers/UserInfo/AddCardComponent";
import ArrowUp from "../../assets/icons/arrow-up.svg"
import ArrowDown from "../../assets/icons/arrow-down.svg"
import { setUser } from 'redux/actions/user';
const NewBilling = ({ userUpgrade, newPlanDetails, activePlan }) => {

  //No need for window routing

  const [selectedOption, setSelectedOption] = useState("option1");
  const [isChecked, setIsChecked] = useState(false);
  const [activateBilling, setActivateBilling] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [showPrices, setShowPrices] = useState(false)
  const user = useSelector((state) => state?.user?.authUser);
  // const [cardholderName, setCardholderName] = useState("");
  // const [cardNumber, setCardNumber] = useState("");
  // const [expDate, setExpDate] = useState("");
  // const [cvv, setCvv] = useState("");
  const [bluesnapToken, setBlueSnapToken] = useState("");
  // const [newVersionPlan, setNewVersionPlan] = useState(null);
  // const [cardBrand, setCardBrand] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  const updateBlueSnapToken = React.useCallback(async () => {
    function getProfile() {
      getMe()
        .then((me) => {
          setBlueSnapToken(me.data.bluesnap_token);
          dispatch(setUser(me.data));
        })
        .catch((e) => {
          toast.notify("An error occurred, try refreshing this page", {
            type: "error",
          });
        });
    }
    await getProfile();
  }, [dispatch]);

  React.useEffect(() => {
    updateBlueSnapToken();
  }, [updateBlueSnapToken]);

  // const userEmail = user?.user?.email;

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const handleRadioClick = (e) => {
    if (selectedOption === e.target.value) {
      setSelectedOption(null);
    } else {
      handleRadioChange(e);
    }
  };

  const processPayment = async () => {
    if (isChecked === false) {
      return;
    }

    try {
      setIsloading(true);
      if (isChecked === true) {
        let payload = {
          token: bluesnapToken,
        };
        await initiateTrial(payload);
        //update token or call the me-endpoint.

        getMe()
          .then((me) => {
            dispatch(setUser(me.data));
          });


        toaster.notify("15 days trial period activated successfully.", {
          duration: "4000",
          position: "top",
        });
        setIsloading(false);
        history.push("/connect");
      }
    } catch (e) {
      setIsloading(false);
    }
    setIsloading(false);
  };

  // const initiateSubscription = async () => {
  //   try {
  //     let payload = {
  //       plan_id: newPlanDetails[0].id,
  //     };
  //     await initiateSub(payload);
  //     history.push("/dashboard/sync");
  //   } catch (e) {
  //     setIsloading(false);
  //   }
  // };

  // const formatExpirationDate = (value) => {
  //   const cleaned = value.replace(/\D+/g, "");
  //   const match = cleaned.match(/^(\d{0,2})(\d{0,4})$/);

  //   if (match) {
  //     const month = match[1];
  //     const year = match[2];
  //     return `${month}${year ? "/" + year : ""}`;
  //   }

  //   return value;
  // };

  // const detectCardBrand = (number) => {
  //   const patterns = {
  //     visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
  //     mastercard: /^5[1-5][0-9]{14}$/,
  //     amex: /^3[47][0-9]{13}$/,
  //     discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
  //     diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
  //     jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
  //   };

  //   if (patterns.visa.test(number)) return "VISA";
  //   if (patterns.mastercard.test(number)) return "MASTERCARD";
  //   if (patterns.amex.test(number)) return "AMEX";
  //   if (patterns.discover.test(number)) return "DISCOVER";
  //   if (patterns.diners.test(number)) return "DINERS CLUB";
  //   if (patterns.jcb.test(number)) return "JCB";

  //   return "";
  // };

  useEffect(() => {
    if (activePlan !== null) {
      setSelectedOption("option2");
    }
  }, [activePlan]);

  // function getFutureDate() {
  //   const currentDate = new Date();
  //   currentDate.setDate(currentDate.getDate() + 15);
  //   const options = { year: "numeric", month: "long", day: "numeric" };
  //   const formattedDate = currentDate.toLocaleDateString("en-US", options);
  //   return formattedDate;
  // }
  // const futureDate = getFutureDate();

  // const [isTokenFetched, setIsTokenFetched] = useState(false);

  // useEffect(() => {
  //   if (cardNumber.length === 16) {
  //     const cardBrandDetected = detectCardBrand(cardNumber);
  //     setCardBrand(cardBrandDetected);

  //     const fetchToken = async () => {
  //       try {
  //         await axios.put(
  //           `https://ws.bluesnap.com/services/2/payment-fields-tokens/${bluesnapToken}`,
  //           {
  //             ccNumber: cardNumber,
  //           }
  //         );
  //         setIsTokenFetched(true);
  //         if (isTokenFetched) {
  //           updateBlueSnapToken();
  //         }
  //       } catch (error) {
  //         toaster.notify(error, {
  //           duration: "4000",
  //           position: "top",
  //         });
  //       }
  //     };
  //     fetchToken();
  //   }
  // }, [cardNumber, bluesnapToken, isTokenFetched, updateBlueSnapToken]);

  // useEffect(() => {
  //   if (cvv.length === 3 && expDate.length === 7) {
  //     const fetchToken = async () => {
  //       try {
  //         await axios.put(
  //           `https://ws.bluesnap.com/services/2/payment-fields-tokens/${bluesnapToken}`,
  //           {
  //             cvv: cvv,
  //             expDate: expDate,
  //           }
  //         );
  //         setIsTokenFetched(true);
  //         if (isTokenFetched) {
  //           updateBlueSnapToken();
  //         }
  //       } catch (error) {
  //         toaster.notify(error, {
  //           duration: "4000",
  //           position: "top",
  //         });
  //       }
  //     };

  //     fetchToken();
  //   }
  // }, [expDate, cvv, bluesnapToken, updateBlueSnapToken, isTokenFetched]);

  // const submitCard = () => {
  //   if (cvv.length !== 3) {
  //     toaster.notify("CVV Number must be 3 digits long!", {
  //       duration: "4000",
  //       position: "top",
  //     });
  //     return;
  //   }

  //   if (expDate.length !== 7) {
  //     toaster.notify(
  //       "Expiry date must be 6 digits long and in this format (MM/YYYY)!",
  //       {
  //         duration: "4000",
  //         position: "top",
  //       }
  //     );
  //     return;
  //   }

  //   if (cardNumber.length !== 16) {
  //     toaster.notify("Card number must be 16 digits long!", {
  //       duration: "4000",
  //       position: "top",
  //     });
  //     return;
  //   }

  //   setIsloading(true);
  //   if (cardholderName === "" || cardNumber === "" || !expDate || !cvv) {
  //     setIsloading(false);
  //     toaster.notify("Please fill out all card details!", {
  //       duration: "4000",
  //       position: "top",
  //     });
  //     return;
  //   }

  //   const lastFourDigits = cardNumber.slice(-4);
  //   const cardData = {
  //     name: cardholderName,
  //     email: userEmail,
  //     last_four_digit: lastFourDigits,
  //     card_expire: expDate,
  //     cvv: cvv,
  //     bluesnap_token: bluesnapToken,
  //     card_brand: cardBrand,
  //   };
  //   saveCard(cardData)
  //     .then((res) => {
  //       const response = res;
  //       if (response.message) {
  //         toaster.notify(response?.message || "Card saved successfully!", {
  //           duration: "4000",
  //           position: "top",
  //         });
  //       }
  //       setIsloading(false);
  //       initiateSubscription();
  //       setIsTokenFetched(true);
  //       if (isTokenFetched) {
  //         updateBlueSnapToken();
  //       }
  //       setCardholderName("");
  //       setExpDate("");
  //       setCvv("");
  //       setCardNumber("");
  //     })
  //     .catch((error) => {
  //       toaster.notify("Failed to add card!", {
  //         duration: "4000",
  //         position: "top",
  //       });
  //       setIsloading(false);
  //       setCardholderName("");
  //       setExpDate("");
  //       setCvv("");
  //       setCardNumber("");
  //       return;
  //     });
  // };

  const toggleShowPrices = () => {
    setShowPrices(prev => !prev)
  }

  return (
    <div className="billing_cont">
      <div className="d-flex align-items-center justify-content-between">
        <img
          onClick={() => {
            history.push("/");
          }}
          src={thatappLogo}
          alt="logo"
        />
        {user.new_user === false ? (
          <button
            style={{
              border: "1px solid #F0B429",
              color: "#F0B429",
              borderRadius: "4px",
              backgroundColor: "#fff",
            }}
            onClick={() => {
              window.history.back();
            }}
          >
            Back
          </button>
        ) : (
          <button
            style={{
              border: "1px solid #F0B429",
              color: "#F0B429",
              borderRadius: "4px",
              backgroundColor: "#fff",
            }}
            onClick={() => {
              dispatch(logout());
              history.push("/login");
            }}
          >
            Logout
          </button>
        )}
      </div>

      {activateBilling ? (
        <div className="billing_content_cont mt-4">
          <div className="billing_content">
            <div className="billing_header">
              <h4 style={{ color: "#000", fontWeight: "bold" }}>
                Enter Your Payment Details to <br /> Activate Billing
              </h4>
              <p className="mt-2">
                Finalize your subscription and unlock full access to backup and
                sync services.
              </p>
              <hr className="billing__hr" />
            </div>
            {/* <div className="billing_plan-v2 mt-4">
              <p>By subscribing, you agree to the following:</p>
              <ul className="ml-3">
                <li>
                  You will be charged ${newPlanDetails[0]?.price} per month per
                  organisation, whether you reach the 1 million items limit or
                  not.
                </li>

                <li>
                  The ${newPlanDetails[0]?.price} is the minimum charge,
                  regardless of your usage during the month
                </li>

                <li>
                  Any additional services, such as emails, cloning, or database
                  syncing, will be charged separately based on your usage.
                </li>
              </ul>
            </div> */}

            {/* <div style={{ width: "100%" }} className="services__title__cont">
              <div className="d-flex align-items-center justify-content-between">
                <h4
                  style={{ fontSize: "16px", fontWeight: "600" }}
                  id="transition-modal-title"
                >
                  Payment information:
                </h4>
              </div>

              <AddCardComponent
                setIsloading={setIsloading}
                isLoading={isLoading}
                plainId={newPlanDetails[0].id}
              />
            </div> */}

            <div
              className="billing__button__cont "
            // style={isLoading ? { marginTop: "0" } : { marginTop: "-50px" }}
            >
              <button
                className="generate_btn"
                style={{
                  backgroundColor: "#fff",
                  border: "1px solid black",
                  color: "#000",
                }}
                onClick={() => history.goBack()}
              >
                Cancel
              </button>
              <div className="billing__btn">
                {isLoading && activateBilling === false ? (
                  <div className="mt-3 flex justify-content-end">
                    <button className="generate_btn">
                      <CircularProgress sx={{ color: "white" }} size={20} />
                    </button>
                  </div>
                ) : (
                  <div className="d-flex justify-content-end">
                    {activateBilling ? null : (
                      <button
                        disable={
                          isChecked === false && !activateBilling ? true : false
                        }
                        style={
                          isChecked === false && !activateBilling
                            ? { backgroundColor: "#CDD0D5" }
                            : { backgroundColor: "#F2B11B" }
                        }
                        className="generate_btn"
                      >
                        {selectedOption === "option1"
                          ? "Activate 15-day trial"
                          : "Proceed to billing"}
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="billing_content_cont mt-6">
          <div className="billing_content">
            <div className="billing_header">
              <h4 style={{ color: "#000", fontWeight: "800" }}>
                Start Backing Up and Syncing <br /> Your Data Today
              </h4>
              <p style={{ color: "#7E7E7E" }} className="mt-2 mb-0">
                Subscribe now to unlock full data synchronization features
              </p>
              <hr className="billing_hr" />
            </div>
            <div className="billing_plan">
              <p className="mb-0">Plan</p>
              {selectedOption === "option1" ?
                <h4 style={{ fontWeight: "bold", margin: "1% 0" }}>
                  $0
                </h4>
                :
                <h4 style={{ fontWeight: "bold", margin: "1% 0" }}>
                  ${newPlanDetails[0]?.price}/month per Organisation{" "}
                </h4>}
              {selectedOption === "option1" ? <p className="mb-0">Includes 250 items per application for 15 days</p> : <p className="mb-0">Includes backup of up to 1 million records</p>}
            </div>

            {
              selectedOption === "option2" ?
                <div className="services__title__cont">
                  <section style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div>
                      <p
                        style={{
                          color: "#000",
                          fontWeight: "bold",
                        }}
                        className="mb-0"
                      >
                        Additional Services Pricing
                      </p>
                      <p
                        style={{ color: "#7E7E7E", fontSize: "14px" }}
                        className="mb-0"
                      >
                        Expand your data protection with these add-ons:
                      </p>
                    </div>
                    {
                      showPrices ?
                        <img style={{ cursor: "pointer" }} onClick={toggleShowPrices} src={ArrowUp} alt="arrow_icon-up" />
                        :
                        <img style={{ cursor: "pointer" }} onClick={toggleShowPrices} src={ArrowDown} alt="arrow-icon-down" />
                    }
                  </section>
                  {
                    showPrices ?
                      <div
                        style={{
                          backgroundColor: "#FBFCFC",
                          padding: "1% 3%",
                          borderRadius: "12px",
                          marginTop: "3%",
                          border: "1px solid #E5E5E5",
                        }}
                      >
                        <div className="billing__pricing__cont mt-3">
                          {newPlanDetails[0]?.feature_add_ons?.map((item, index) => {
                            return (
                              <div key={index} className="billing_plan_feature">
                                <p className="mb-0">{item.title}</p>
                                <p className="mb-0">
                                  {" "}
                                  <span className="billing_amt">
                                    ${item.value}
                                  </span>{" "}
                                  {item.title === "Clone" ? "/record" : "/month"}
                                </p>
                                {item.title === "Print" ? (
                                  <p className="mb-0" style={{ color: "#F2B11B" }}>
                                    {" "}
                                    Per 1000 print
                                  </p>
                                ) : null}
                              </div>
                            );
                          })}
                        </div>
                        <div className="services__title__cont mb-3">
                          <p
                            style={{ color: "#000", fontWeight: "700" }}
                            className="mb-0"
                          >
                            Database Types:
                          </p>
                          <div
                            style={{ marginTop: "12px" }}
                            className="billing__feature__price__cont "
                          >
                            <div className="billing_base">
                              <p className="mb-0">MySQL</p>
                              <h4 style={{ fontWeight: "bold" }}>$10.00</h4>
                            </div>
                            <div className="billing_base">
                              <p className="mb-0">PostgreSQL</p>
                              <h5 style={{ fontWeight: "bold" }}>$10.00</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      :
                      null
                  }
                </div>
                :
                null
            }

            <hr className="billing_trial_hr" />

            {activePlan === null && (
              <div className="billing__radio__cont services__title__cont">
                <label className="d-flex align-items-center ">
                  {selectedOption === "option1" ? (
                    <img
                      onClick={() => setIsChecked(false)}
                      src={radioChecked}
                      alt=""
                    />
                  ) : (
                    <input
                      style={{
                        appearance: "auto",
                        width: "20px",
                        height: "20px",
                        accentColor: "#F7C948",
                      }}
                      value="option1"
                      checked={selectedOption === "option1"}
                      onChange={handleRadioChange}
                      onClick={handleRadioClick}
                      type="radio"
                    />
                  )}

                  <p className="ml-2 mb-0">Proceed to 15-day trial</p>
                </label>
                <label className="d-flex align-items-center">
                  {selectedOption === "option2" ? (
                    <img
                      onClick={() => setIsChecked(false)}
                      src={radioChecked}
                      alt=""
                    />
                  ) : (
                    <input
                      style={{
                        appearance: "auto",
                        width: "20px",
                        accentColor: "#F7C948",
                      }}
                      value="option2"
                      onChange={handleRadioChange}
                      checked={selectedOption === "option2"}
                      onClick={handleRadioClick}
                      type="radio"
                    />
                  )}

                  <p className="ml-2 mb-0">Proceed to Subscribe</p>
                </label>
              </div>
            )}
            {selectedOption === "option1" ? (
              <div className="billing_info mt-4">
                <div>
                  <p className="option_head mb-0">Not ready to subscribe?</p>
                  <p className="mb-0">
                    By activating the 15-day free trial, you can store up to 250 items per application.
                    To continue backing up and syncing your data after the trial ends, a subscription will be required.
                    If payment isn&apos;t made, all trial data will be deleted
                  </p>
                </div>
              </div>
            ) :
              <section>
                <div className="billing_plan-v2 mt-4">
                  <p>By subscribing, you agree to the following:</p>
                  <ul className="ml-3">
                    <li>
                      You will be charged ${newPlanDetails[0]?.price} per month per
                      organisation, whether you reach the 1 million items limit or
                      not.
                    </li>

                    <li>
                      The ${newPlanDetails[0]?.price} is the minimum charge,
                      regardless of your usage during the month
                    </li>

                    <li>
                      Any additional services, such as emails, cloning, or database
                      syncing, will be charged separately based on your usage.
                    </li>
                  </ul>
                </div>
                <div style={{ width: "100%" }} className="services__title__cont">
                  <div className="d-flex align-items-center justify-content-between">
                    <h4
                      style={{ fontSize: "16px", fontWeight: "600" }}
                      id="transition-modal-title"
                    >
                      Payment information:
                    </h4>
                  </div>

                  <AddCardComponent
                    isChecked={isChecked}
                    setIsloading={setIsloading}
                    isLoading={isLoading}
                    plainId={newPlanDetails[0].id}
                    handleCheckboxChange={handleCheckboxChange}
                  />
                </div>
              </section>
            }

            {
              selectedOption === "option1" ?
                <label className="mt-4 d-flex align-items-start mb-5">
                  <input
                    style={{
                      appearance: "auto",
                      width: "24px",
                      marginTop: "5px",
                      height: "auto",
                      accentColor: "#F7C948",
                    }}
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <p className="mb-0 ml-2">
                    By checking this box, you are agreeing to our{" "}
                    <a
                      style={{ color: "#F7C948" }}
                      href="https://help.thatapp.io/en/articles/9392123-terms-of-service"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms of service
                    </a>
                    ,{" "}
                    <a
                      style={{ color: "#F7C948" }}
                      href="/pricing"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Pricing
                    </a>{" "}
                    and{" "}
                    <a
                      style={{ color: "#F7C948" }}
                      href="https://help.thatapp.io/en/articles/108584-privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy{" "}
                    </a>
                  </p>
                </label>
                :
                null
            }

            <div className="billing__button__cont">
              {
                selectedOption === "option1" ?
                  <button
                    className="generate_btn"
                    style={{
                      backgroundColor: "#fff",
                      border: "1px solid black",
                      color: "#000",
                    }}
                    onClick={() => history.goBack()}
                  >
                    Cancel
                  </button>
                  :
                  null
              }

              {
                selectedOption === "option1" ?
                  <div className="billing__btn">
                    {isLoading ?
                      <button className="generate_btn">
                        <CircularProgress sx={{ color: "white" }} size={20} />
                      </button>
                      :
                      selectedOption === "option1" ?
                        <button
                          disable={isChecked === false ? true : false}
                          onClick={() => {
                            if (selectedOption === "option1") {
                              processPayment();
                            } else {
                              if (isChecked === false) {
                                return;
                              } else {
                                setActivateBilling(true);
                              }
                            }
                          }}
                          style={
                            isChecked === false
                              ? { backgroundColor: "#CDD0D5" }
                              : { backgroundColor: "#F2B11B" }
                          }
                          className="generate_btn "
                        >
                          {selectedOption === "option1"
                            ? "Activate 15-day trial"
                            : "Proceed to billing"}
                        </button>
                        :
                        null
                    }
                  </div>
                  :
                  null
              }
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewBilling;
