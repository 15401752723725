import React, { useState } from "react";
import { useSelector } from "react-redux";
import axios from "../../../config";
import { LinearProgress } from "@material-ui/core";
import MembersTable from "./MembersTable";


const Members = () => {
  const [loading, setLoading] = useState({
    space: false,
    member: false,
  });
  const [spaces, setSpaces] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState("");
  const [selectedSpace, setSelectedSpace] = useState("");
  const orgs = useSelector((state) => state.sync.orgs);
  const orgOptions =
    orgs.length > 0
      ? orgs.map((org) => {
          return (
            <option key={org.org_id} value={org.org_id}>
              {org.name}
            </option>
          );
        })
      : null;

  const changeOrganisation = (e) => {
    const org = e.target.value;
    setSelectedOrg(org);
    getSpaces(org);
  };

  const changeSpace = (e) => {
    const space = e.target.value;
    setSelectedSpace(space);
  };

  // const changeSpace = (val) => {
  //   setSelectedSpace(val.value);
  // };

  const getSpaces = async (orgId) => {
    try {
      setLoading({
        ...loading,
        space: true,
      });
      let res = await axios.get(`/sync/podio/audit/spaces?org_id=${orgId}`);
      setSpaces(res.data);
      setLoading({
        ...loading,
        space: false,
      });
    } catch (e) {
      setLoading({
        ...loading,
        space: false,
      });
    }
  };

  const spaceOptions =
    spaces.length > 0
      ? spaces.map((space) => {
          return (
            <option key={space.space_id} value={space.space_id}>
              {space.space_name}
            </option>
          );
        })
      : [];

  return (
    <div>
      <div className="">
        <h5 className="mb-4 mt-4">Workspace Members</h5>
        <h5 style={{ fontSize: "18px" }}>
          To access workspace members, select an organization and the workspace
        </h5>

        <div className="d-flex mt-4">
          <div style={{ width: "30%", marginRight: "1%" }}>
            <label className="mb-2" htmlFor="org">
              Select Organization
            </label>
            <select
              name="org"
              id="org"
              className="form-control"
              value={selectedOrg}
              onChange={changeOrganisation}
              style={{ appearance: "auto" }}
            >
              <option>Select...</option>
              {orgOptions}
            </select>
          </div>

          <div style={{ width: "30%" }}>
            <label className="mb-2" htmlFor="space">
              Select Workspace
            </label>
            <select
              name="org"
              id="org"
              className="form-control"
              value={selectedSpace}
              // onChange={changeOrganisation}
              onChange={changeSpace}
              style={{ appearance: "auto" }}
            >
              <option>Select...</option>
              {spaceOptions}
            </select>
          </div>
        </div>

        {/* <div className="d-flex">
          <div className="form-group">
            <select
              className="form-control"
              value={selectedOrg}
              onChange={changeOrganisation}
              // style={{ height: "52px" }}
              placeholder="Select An Organization"
              options={orgOptions}
            />
          </div>

          <div className="form-group">
            <label className="mb-2" htmlFor="space">
              Select Workspace
            </label>
            <select
              placeholder="Select a Space"
              options={spaceOptions}
              handleChange={changeSpace}
              isMulti={false}
            />
          </div>
        </div> */}

        <div className="mt-3">
          {loading.space ? <LinearProgress className="mb-4" /> : null}

          {selectedSpace ? <MembersTable spaceId={selectedSpace} /> : null}
        </div>
      </div>
    </div>
  );
};

export default Members;
