import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import Grid from "@material-ui/core/Grid";
import "./styles.css";
import RenderByPermission from "components/RenderByPermission";
import { formatNumberWithKAndM } from "utils/formatNumberWithKAndM";
import { Tooltip } from "@material-ui/core";
// import { formatNumber } from "utils/formatNumber";
import dashIcon from "../../assets/icons/dashIcon.svg";
import upndown from "../../assets/icons/upndown.svg";
import cancel from "../../assets/icons/cancel.svg";
import infoIcon from "../../assets/icons/info.svg";
import CountdownTimer from "./CountdownTimer";
// import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getMe } from "services/auth";
import { toast } from "react-toastify";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  isTrialCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#FFFBF1",
    border: "1px solid #F2B11B",
    padding: "2%",
    borderRadius: "10px",
    marginTop: "2%",
  },
  isBillingCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#F9FDFF",
    border: "1px solid #5ED0FA",
    padding: "2%",
    borderRadius: "10px",
    marginTop: "2%",
  },
  heading: {
    fontWeight: "bold",
    fontSize: "16px",
    color: "#F2B11B",
    marginBottom: "0",
  },
  headingBilling: {
    fontWeight: "bold",
    fontSize: "16px",
    color: "#5ED0FA",
    marginBottom: "0",
  },
  btn: {
    border: "1px solid #F2B11B",
    color: "#F2B11B",
    padding: "10px 25px",
    borderRadius: "5px",
    fontWeight: "bold",
    fontSize: "16px",
    backgroundColor: "#FFFBF1",
  },
  btnBilling: {
    border: "1px solid #5ED0FA",
    color: "#5ED0FA",
    padding: "10px 25px",
    borderRadius: "5px",
    fontWeight: "bold",
    fontSize: "16px",
    backgroundColor: "#F9FDFF",
  },
  timerTime: {
    fontWeight: "800",
    fontSize: "20px",
    color: "#F2B11B",
    backgroundColor: "#FFF8E9",
    padding: "10px 15px",
    borderRadius: "10px",
  },
  timeCont: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  indct: {
    fontSize: "12px",
    color: "#010E20",
  },
  descInfo: {
    border: "1px solid #E5E5E5",
    backgroundColor: "#FBFCFC",
    marginTop: "2%",
    padding: "3%",
    borderRadius: "10px",
    textAlign: "left",
  },
}));

// synced,
const Summary = ({
  spaceCount,
  appCount,
  flowCount,
  workflowCount,
  itemsCount,
  click,
  orgs,
}) => {
  const classes = useStyles();
  const [trialTimer, setTimerTrial] = useState(false);
  const [billingProceed, setBillingProceed] = useState(false);
  // const free_trial = useSelector((state) => state.user.authUser.free_trial);
  // const active_plan = useSelector((state) => state.user.authUser.active_plan);
  // const new_billing = useSelector((state) => state.user.authUser.new_billing);
  const history = useHistory();
  const [trialDays, setTrialDays] = useState("");
  const [userData, setUserData] = useState("");
  const [, setUserUpgrade] = useState(false);
  const [showUserMessage, setShowUserMessage] = useState(false);

  useEffect(() => {
    if (trialDays <= 5) {
      setTimerTrial(true);
    } else {
      setTimerTrial(false);
    }
  }, [trialDays]);

  useEffect(() => {
    if (
      userData?.free_trial === false &&
      userData?.active_plan === null &&
      userData?.new_billing === false &&
      userData?.previous_plan === null
    ) {
      setShowUserMessage(true);
      setBillingProceed(true);
    }
  }, [
    userData?.free_trial,
    userData?.active_plan,
    userData?.new_billing,
    userData?.previous_plan,
  ]);

  const updateBlueSnapToken = React.useCallback(async () => {
    function getProfile() {
      getMe()
        .then((me) => {
          setUserData(me.data);
        })
        .catch((e) => {
          toast.notify("An error occurred, try refreshing this page", {
            type: "error",
          });
        });
    }
    await getProfile();
  }, []);

  React.useEffect(() => {
    updateBlueSnapToken();
  }, [updateBlueSnapToken]);

  const handleClick = () => {
    setUserUpgrade(true);
    history.push({
      pathname: "/service",
      state: { userUpgrade: true },
    });
  };

  return (
    <div className={`${classes.root}`}>
      {showUserMessage && (
        <div className="modal_bg">
          <div className="modal_cont_conf">
            <div className="d-flex justify-content-between align-item-center">
              <img width="10%" src={infoIcon} alt="" />
              <img
                onClick={() => {
                  setShowUserMessage(false);
                }}
                src={cancel}
                alt=""
              />
            </div>
            <div style={{ textAlign: "center" }}>
              <h4 className="mt-2">Activate billing</h4>
              <p className="mt-2">
                You are currently not on our billing plan or free trial click on
                the button below to activate billing.
              </p>
            </div>
            <div className="d-flex justify-content-center align-items-center mt-3">
              <button
                style={{ width: "100%", marginTop: "5%" }}
                onClick={() => {
                  history.push({
                    pathname: "/service",
                    state: { userUpgrade: true },
                  });
                }}
                className="generate_btn"
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      )}

      {trialTimer && (
        <div className="modal_bg">
          <div className="modal_cont">
            <div className="d-flex justify-content-end">
              <img
                onClick={() => setTimerTrial(false)}
                width="7%"
                src={cancel}
                alt=""
              />
            </div>

            <div style={{ textAlign: "center", marginBottom: "5%" }}>
              <p className="mb-4 bold">Time remaining</p>

              <CountdownTimer setTrialDays={setTrialDays} />

              <div className={classes.descInfo}>
                <p style={{ color: "#626262", fontSize: "12px" }}>
                  Don’t miss out on your chance to keep your data safe and
                  secure! If you don’t upgrade by the end of your trial, all
                  your data will be permanently deleted from our database.
                </p>
              </div>

              <button
                style={{
                  width: "100%",
                  height: "50px",
                  backgroundColor: "#f0b429",
                  border: "none",
                  marginTop: "5%",
                  color: "white",
                  borderRadius: "4px",
                }}
                onClick={handleClick}
              >
                Upgrade now
              </button>
            </div>
          </div>
        </div>
      )}

      <div
        className="d-flex justify-content-between"
        style={{ alignItems: "center" }}
      >
        <h5
          className="dashboard_title"
          style={{ margin: "0", fontWeight: "bold" }}
        >
          {" "}
          Dashboard{" "}
        </h5>
        <RenderByPermission allowTeamMemberAccess={false}>
          <button
            id="syncAnOrgButton"
            className={orgs?.length < 1 ? "sync__btn jiggle" : "sync__btn"}
            onClick={click}
            style={{ marginBottom: "0" }}
          >
            Sync an Organisation
          </button>
        </RenderByPermission>
      </div>

      {userData.free_trial === true && userData.active_plan === null ? (
        <div className={classes.isTrialCont}>
          <div>
            <p className={classes.heading}>
              You are currently on a 15-day trial plan
            </p>
            <p style={{ marginBottom: "0" }}>
              To back-up up to 1 million records, upgrade now for uninterrupted
              access to our premium features
            </p>
          </div>

          <div>
            <button onClick={handleClick} className={classes.btn}>
              Upgrade
            </button>
          </div>
        </div>
      ) : null}

      {billingProceed && (
        <div className={classes.isBillingCont}>
          <div>
            <p className={classes.headingBilling}>Activate Billing</p>
            <p style={{ marginBottom: "0" }}>
              You currently do not have an active plan on your account. click
              the button to set up a plan
            </p>
          </div>

          <div>
            <button onClick={handleClick} className={classes.btnBilling}>
              Upgrade
            </button>
          </div>
        </div>
      )}

      <div className="summary-tabs mt-5">
        <div
          className="item item-first"
          style={{ borderRadius: "12px 0 0 12px" }}
        >
          <div className="image">
            <img src={dashIcon} alt="users" />
          </div>

          <div className="detailsCont">
            <div
              style={{ fontSize: "14px", color: "#344051", height: "40px" }}
              className="label"
            >
              All Organisations
            </div>
            <Tooltip
              title={`${formatNumberWithKAndM(
                orgs?.length ?? 0
              )} Organisations`}
            >
              <div
                style={{ fontSize: "32px", fontWeight: "800" }}
                className="value"
              >
                {formatNumberWithKAndM(orgs?.length ?? 0)}
              </div>
            </Tooltip>
            <div
              style={{
                backgroundColor: "#E3F8FF",
                borderRadius: "10px",
                padding: "0 7px",
                width: "fit-content",
              }}
              className="d-flex align-items-center"
            >
              <img
                style={{ height: "18px" }}
                width="10px"
                className="m-0 mr-1"
                alt="drop"
                src={upndown}
              />
              <p style={{ fontSize: "10px", color: "#1992D4" }} className="m-0">
                Synced Organisations
              </p>
            </div>
          </div>
        </div>

        <div className="item item-middile">
          <div className="image">
            <img src={dashIcon} alt="spaces" />
          </div>
          <div className="detailsCont">
            <div
              style={{ fontSize: "14px", color: "#344051", height: "40px" }}
              className="label"
            >
              Spaces across all Organisations
            </div>
            <Tooltip
              title={`${formatNumberWithKAndM(
                spaceCount ?? 0
              )} Spaces across all Organisations`}
            >
              <div className="value">
                {formatNumberWithKAndM(spaceCount ?? 0)}
              </div>
            </Tooltip>
            <div
              style={{
                backgroundColor: "#E3F8FF",
                borderRadius: "10px",
                padding: "0 7px",
                width: "fit-content",
              }}
              className="d-flex align-items-center"
            >
              <img
                style={{ height: "18px" }}
                width="10px"
                className="m-0 mr-1"
                alt="drop"
                src={upndown}
              />
              <p style={{ fontSize: "10px", color: "#1992D4" }} className="m-0">
                All Spaces
              </p>
            </div>
          </div>
        </div>

        <div className="item item-middile">
          <div className="image">
            <img src={dashIcon} alt="apps" />
          </div>
          <div className="detailsCont">
            <div
              style={{ fontSize: "14px", color: "#344051", height: "40px" }}
              className="label"
            >
              Apps across all Spaces
            </div>
            <Tooltip title={`${appCount ?? 0} Apps across all Spaces`}>
              <div className="value">
                {formatNumberWithKAndM(appCount ?? 0)}
              </div>
            </Tooltip>
            <div
              style={{
                backgroundColor: "#E3F8FF",
                borderRadius: "10px",
                padding: "0 7px",
                width: "fit-content",
              }}
              className="d-flex align-items-center"
            >
              <img
                style={{ height: "18px" }}
                width="10px"
                className="m-0 mr-1"
                alt="drop"
                src={upndown}
              />
              <p style={{ fontSize: "10px", color: "#1992D4" }} className="m-0">
                Total Apps
              </p>
            </div>
          </div>
        </div>

        <div className="item item-middile">
          <div className="image">
            <img src={dashIcon} alt="items" />
          </div>
          <div className="detailsCont">
            <div
              style={{ fontSize: "14px", color: "#344051", height: "40px" }}
              className="label"
            >
              Items across all Apps
            </div>
            <Tooltip title={`${itemsCount ?? 0} Items across all Apps`}>
              <div className="value">
                {formatNumberWithKAndM(itemsCount ?? 0)}
              </div>
            </Tooltip>
            <div
              style={{
                backgroundColor: "#E3F8FF",
                borderRadius: "10px",
                padding: "0 7px",
                width: "fit-content",
              }}
              className="d-flex align-items-center"
            >
              <img
                style={{ height: "18px" }}
                width="10px"
                className="m-0 mr-1"
                alt="drop"
                src={upndown}
              />
              <p style={{ fontSize: "10px", color: "#1992D4" }} className="m-0">
                Total Items
              </p>
            </div>
          </div>
        </div>

        <div
          style={{ borderRadius: "0 12px 12px 0" }}
          className="item item-last"
        >
          <div className="image">
            <img src={dashIcon} alt="apps" />
          </div>
          <div className="detailsCont">
            <div
              style={{ fontSize: "14px", color: "#344051", height: "40px" }}
              className="label"
            >
              Flows across all Organisations
            </div>
            <Tooltip
              title={`${flowCount ?? 0} Flows across all Organisations`}
            >
              <div className="value">
                {formatNumberWithKAndM(flowCount ?? 0)}
              </div>
            </Tooltip>
            <div
              style={{
                backgroundColor: "#E3F8FF",
                borderRadius: "10px",
                padding: "0 7px",
                width: "fit-content",
              }}
              className="d-flex align-items-center"
            >
              <img
                style={{ height: "18px" }}
                width="10px"
                className="m-0 mr-1"
                alt="drop"
                src={upndown}
              />
              <p style={{ fontSize: "10px", color: "#1992D4" }} className="m-0">
                Total Flows
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
