import React from "react";
// import AlertDiv from '../components/AlertDiv'
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { FaPlusCircle } from "react-icons/fa";
import ApplicationCard from "../components/ApplicationCard";
import { TMAIL_V3_ADD_APPLICATION } from "../routes";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { GetAllApps } from "redux/actions/tmail";

const useStyles = makeStyles((theme) => ({
  btn: {
    height: "44px",
    width: "185px",
    backgroundColor: "#F0B429",
    borderRadius: "3px",
    textTransform: "capitalize",
  },

  backButton: {
    backgroundColor: "#f7c948",
    boxShadow: "0px 3px 6px #00000029",
    padding: "1vh 1vw",
    outline: "none",
    textDecoration: "none",
    border: "none",
    borderRadius: "6px",
    color: "white",
    marginBottom: "1%"
  },
}));

const ApplicationView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const apps = useSelector((state) => state.tmail.apps);
  const isLoading = useSelector((state) => state.tmail.loading);

  useEffect(() => {
    dispatch(GetAllApps());
  }, [dispatch]);

  if (isLoading) {
    return (
      <div
        style={{ height: "100vh" }}
        className="d-flex justify-content-center align-items-center w-100"
      >
        <CircularProgress size={50} />
      </div>
    );
  }

  const handleBackButtonClick = () => {
    window.history.back();
  };

  return (
    <div>
      <div>
        <button onClick={handleBackButtonClick} className={classes.backButton}>
          Back
        </button>
      </div>
      <div className="d-flex align-items-center justify-content-between mb-5">
        <h5 className="">Connected Podio Apps</h5>
        <Link to={TMAIL_V3_ADD_APPLICATION}>
          <Button
            variant="contained"
            color="primary"
            disableElevation={true}
            className={classes.btn}
          >
            <FaPlusCircle />
            &nbsp; Add New
          </Button>
        </Link>
      </div>

      {/* <AlertDiv message="You have reached your credit limit, kindly subscribe now.  Subscribe" /> */}

      <div className="row">
        {apps.length > 0 ? (
          apps.map((app) => (
            <div className="col-4" key={app.id}>
              <ApplicationCard {...{ app }} />
            </div>
          ))
        ) : (
          <div className="text-muted text-center font-weight-bold">
            No apps found in database
          </div>
        )}
      </div>
    </div>
  );
};

export default ApplicationView;
