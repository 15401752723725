import * as actionType from '../constants/mapper';

const initialState = {
    mappers: [],
    loading: true,
    error: {},
}

const MapperReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case actionType.GET_MAPPERS:
            console.log("PAYLOAD", payload);
            return {
                ...state,
                mappers: payload,
                loading: false
            }
        case actionType.ADD_MAPPER:
            return {
                ...state,
                mappers: state.mappers.concat(payload),
                loading: false
            }
        case actionType.DELETE_MAPPER:
            return {
                ...state,
                mappers: state.mappers.filter(map => map.app_id !== payload.app_id),
                loading: false
            }
        case actionType.ERROR:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        default: return state;
    }

}

export default MapperReducer;