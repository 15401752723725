import { Box } from '@material-ui/core'
import React from 'react'
import LeftColumn from './LeftColumn'
import RightColumn from './RightColumn'

const ContentWrapper = ({title, content}) => {
    return (
        <Box className='row'>
            <LeftColumn title={title} />
            <RightColumn content={content} />

        </Box>
    )
}

export default ContentWrapper