import React, { useState, useEffect, Fragment } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { connect, useDispatch } from "react-redux";
import {
  getApp,
  getAppSections,
  createAppSection,
  updateAppSection
} from "../../../services/sync";
import { useParams, useHistory } from "react-router-dom";
import {
  addSection,
  addField,
  removeField,
  updateFieldDetail,
  initialAppBuilderState
} from "../../../redux/actions/appBuilder";
// import CustomButton from "../../../components/atoms/CustomButton";
import Button from "../../../components/atoms/Button";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import settingsImg from "../../../components/atoms/icons/settings.svg";
import arrow from "../../../components/atoms/icons/arrow-left.svg";
import Tooltip from "@material-ui/core/Tooltip";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import DraggableSectionR1C1 from "./Elements/Sections/DraggableSectionR1C1";
import DraggableSectionR1C2 from "./Elements/Sections/DraggableSectionR1C2";
import DraggableSectionR1C3 from "./Elements/Sections/DraggableSectionR1C3";
import DraggableSectionR1C4 from "./Elements/Sections/DraggableSectionR1C4";
import DraggableSectionLeftBlockR1C2 from "./Elements/Sections/DraggableSectionLeftBlockR1C2";
import DraggableSectionRightBlockR1C2 from "./Elements/Sections/DraggableSectionRightBlockR1C2";
import DraggableFieldCalculation from "./Elements/Fields/DraggableFieldCalculation";
import DraggableFieldCategory from "./Elements/Fields/DraggableFieldCategory";
import DraggableFieldProgress from "./Elements/Fields/DraggableFieldProgress";
import DraggableFieldLocation from "./Elements/Fields/DraggableFieldLocation";
import DraggableFieldMember from "./Elements/Fields/DraggableFieldMember";
import DraggableFieldDate from "./Elements/Fields/DraggableFieldDate";
import DraggableFieldDuration from "./Elements/Fields/DraggableFieldDuration";
import DraggableFieldEmail from "./Elements/Fields/DraggableFieldEmail";
import DraggableFieldImage from "./Elements/Fields/DraggableFieldImage";
import DraggableFieldLink from "./Elements/Fields/DraggableFieldLink";
import DraggableFieldText from "./Elements/Fields/DraggableFieldText";
import DraggableFieldRelationship from "./Elements/Fields/DraggableFieldRelationship";
import DraggableFieldNumber from "./Elements/Fields/DraggableFieldNumber";
import DraggableFieldMoney from "./Elements/Fields/DraggableFieldMoney";
import DraggableFieldPhone from "./Elements/Fields/DraggableFieldPhone";
import DrawingCanvas from "./DrawingCanvas";
import ContentEditable from "../../../components/WrappedContentEditable"
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import "./AppBuilder.scss";
import { toast } from "helpers/apiRequests";
import { v4 } from "uuid";


const isTouchDevice = () => {
  if ("ontouchstart" in window) {
    return true;
  }

  return false;
};

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    fontFamily: "Mulish",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "30px",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <Tooltip title="Close Modal">
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </MuiDialogTitle>
  );
});


const backendForDND = isTouchDevice() ? TouchBackend : HTML5Backend;

const useStyles = makeStyles((theme) => ({
  sectionTitle: {},
  paper: {
    textAlign: "center",
    color: "#ffffff",
    backgroundColor: "#F7F7F7",
    border: "1px dashed #B1B1B1",
    height: "70px",
    marginLeft: "5px",
    marginRight: "5px",
  },
  required: {
    "&::after": {
      content: '"*"',
      color: "#fc3b68f2",
    },
  },
  button: {
    margin: theme.spacing(1),
    marginRight: theme.spacing(1),
    textAlign: "center",
    float: "right",
    background: "#FFF3C4",
    borderRadius: "3px",
    color: "#DE911D",
  },
  root: {
    "& hr": {
      margin: theme.spacing(0, 0.5),
      backgroundColor: "#50504c47",
      height: "50px",
    },
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  form: {
    fontSize: "14px",
  },
  body1Text: {
    fontSize: "14px",
  },
  checkboxLabel: {
    fontSize: "14px",
  },
  link: {
    color: "#9e9e9e",
    transition: ".5s ease-in-out all",
    textDecoration: "none",
    "&:hover": {
      color: "#fcaf3b",
      textDecoration: "none",
    },
  },
  navSection: {
    height: "100%",
  },
  navTitle: {
    fontSize: "18px",
    lineHeight: "23px",
    color: "#000000",
    cursor: "pointer",
  },
}));

const AppBuilder = ({ appSections, uniqueHash, match, org }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const [loading, setLoading] = useState(false);

  const [workspace, setWorkspace] = useState({});
  const [app, setApp] = useState({});
  const [showSectionOptions, setShowSectionOptions] = useState(false);
  const [showFieldOptions, setShowFieldOptions] = useState(true);
  const [appBuilderSections, setAppBuilderSections] = useState({});
  const [appFields, setAppFields] = useState([]);
  const [visibility, setVisibility] = useState(
    appBuilderSections?.section?.visibility
      ? appBuilderSections.section.visibility : 'public')
  const [description, setDescription] = useState(
    appBuilderSections?.section?.description
      ? appBuilderSections.section.description : 'Add description')
  const [buttonName, setButtonName] = useState(
    appBuilderSections?.section?.button_name
      ? appBuilderSections.section.button_name : 'Save')
  const [backgroundImage, setBackgroundImage] = useState(
    appBuilderSections?.section?.background_image
      ? appBuilderSections.section.background_image : '')

  const [updateAppSettings, setUpdateAppSettings] = useState(false);
  const [updateAppSettingsLoading, setUpdateAppSettingsLoading] = useState(false);

  const [name, setName] = useState(
    appBuilderSections?.section?.name
      ? appBuilderSections.section.name
      : 'New App');

  const [message, setMessage] = useState('');


  const handleCloseDialog = () => {
    setUpdateAppSettings(false);
  };

  const handleOpenDialog = () => {
    setUpdateAppSettings(true);
  };

  const handleSaveAppSettings = async () => {
    if (!validateForm()) {
      toast.notify(message)
      handleOpenDialog()
      return;
    }

    setUpdateAppSettingsLoading(true);
    let payload = {
      org_id: params.org_id,
      app_id: params.app_id,
      ...((uniqueHash) && {unique_hash: uniqueHash}),
      name,
      visibility,
      description,
      button_name: buttonName,
      background_image: backgroundImage,
    };

    try {
      let response;
      if (uniqueHash) {
        response = await updateAppSection(payload);
      } else {
        response = await createAppSection(payload);
      }
     
      if (response.message) {
        toast.notify("Form Settings Updated Successfully");
      }
    } catch (err) {
    }
    setUpdateAppSettingsLoading(false);
  }

  const validateForm = () => {
    setMessage("");
    if (description === "") {
      setMessage("The description field is required.");
      return false
    }
    if (backgroundImage === "") {
      setMessage("The background image field is required.");
      return false;
    }

    if (backgroundImage && backgroundImage.match(/\.(jpeg|jpg|gif|png)$/) === null) {
      setMessage("Add a valid background image URL.");
      return false;
    }
    return true;
  }
  const revampAppBuilder = (podioAppFields, currentAppBuilderStructure) => {
    if (!podioAppFields) {
      podioAppFields = appFields
    }
    const seen = new Set();
    let podioFields = [];

    // Search the App Sections Array of object, 
    // To know if the current field is in the appBuilderSections
    // If it's not there, add the current podio field to a new array that will contain details of apps
    podioAppFields.map((field) => {
      let getItem;
      if (currentAppBuilderStructure) {
        let sections = currentAppBuilderStructure;

        let i = 0;
        search_section: while (sections.length > i) {
          let section = sections[i];
          if (section && section.columns) {
            for (let column in section.columns) {
              if (section.columns[column].length > 0) {
                getItem = section.columns[column].find((item) => {
                  return item.field_id === field.field_id
                });

                if (getItem) {
                  let updatePayload = {
                    config: field.config,
                    status: field.status,
                    label: field.label,
                    fieldId: field.field_id,
                    external_id: field.external_id,
                    sectionId: getItem.section_id,
                    columnType: getItem.column_type
                  }
                  let payload = {
                    sectionId: getItem.section_id,
                    fieldId: getItem.field_id,
                    columnType: getItem.column_type
                  };
                  if (field.status === "deleted") {
                    dispatch(removeField(payload));
                  } else {
                    dispatch(updateFieldDetail(updatePayload));
                  }
                  break search_section;
                }
              }
            }
          }

          i++;
        }
        if (!getItem && field.status !== "deleted") {
          podioFields.push(field)
        }
      } else {
        if (field.status !== "deleted") {
          podioFields.push(field)
        }
      }
      return field;
    });
    podioFields = podioFields.filter(el => {
      const duplicate = seen.has(el.field_id);
      seen.add(el.field_id);
      return !duplicate;
    })

    if (podioFields.length > 0) {
      let defaultId = v4();
      let defaultSection = {
        default_id: defaultId,
        type: "sectionR1C1",
        name: "Section_R1C1",
      }

      dispatch(addSection(defaultSection))
      podioFields.map((field) => {
        field.sectionId = defaultId;
        field.columnType = "first_column";
        dispatch(addField(field))
        return field;
      })
    }
  }

  async function initPage() {
    setLoading(true);
    let getSectionsQuery = `${params.org_id}/${params.app_id}`;
    let appQuery = `?org_id=${params.org_id}&app_id=${params.app_id}&space_id=${params.space_id}`;
    let appResponse = await getApp(appQuery);
    let appSectionsResponse = await getAppSections(getSectionsQuery);

    // Fetch app sections
    if (appSectionsResponse.data) {
      setAppBuilderSections(appSectionsResponse.data[0]);
   
      if (appSectionsResponse.data[0]) {
        let data = appSectionsResponse?.data[0]?.section.data ?
          appSectionsResponse.data[0].section.data : [];
        let fields = appSectionsResponse?.data[0]?.fields ?
            appSectionsResponse.data[0].fields : [];
        let payload = {};
        if (data) {
         
          payload = {
            appSections: data,
            unique_hash: appSectionsResponse?.data[0].section.unique_hash,
            name: appSectionsResponse.data[0].section.name,
            description: appSectionsResponse.data[0].section.description,
            visibility: appSectionsResponse.data[0].section.visibility,
            button_name: appSectionsResponse.data[0].section.button_name,
            background_image: appSectionsResponse.data[0].section.background_image
          };
          setName(
            appSectionsResponse.data[0].section.name ? 
            appSectionsResponse.data[0].section.name : "thatApp Form"
            )
          setDescription(
            appSectionsResponse.data[0].section.description ?
            appSectionsResponse.data[0].section.description : ""
            )
          setVisibility(
            appSectionsResponse.data[0].section.visibility ?
            appSectionsResponse.data[0].section.visibility : "public"
            )
          setButtonName(
            appSectionsResponse.data[0].section.button_name ?
            appSectionsResponse.data[0].section.button_name : "Save"
            )
          setBackgroundImage(
            appSectionsResponse.data[0].section.background_image ?
            appSectionsResponse.data[0].section.background_image : ""
            )
        } else {
          payload = {
            appSections: [],
            unique_hash: undefined,
            name: undefined,
          };
        }
        dispatch(initialAppBuilderState(payload));

        if (fields && fields.length > 0) {
          setAppFields(fields);
          revampAppBuilder(fields, data);
        }
      }
    }
    // Fetch App
    if (appResponse.data && appSectionsResponse.data) {
      setApp(appResponse.data.app);
      setWorkspace(appResponse.data.space)
    }
    setLoading(false);
  }

  useEffect(() => {
    let payload = {
      appSections: [],
      unique_hash: undefined,
      name: undefined,
    };
    dispatch(initialAppBuilderState(payload));
    initPage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  if (loading) {
    return (
      <div className="load__audit">
        <div className="material_block">
          <svg
            className="spinner"
            width="65px"
            stroke="#F0B429"
            height="65px"
            viewBox="0 0 66 66"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              className="circle"
              fill="none"
              strokeWidth="6"
              strokeLinecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    );
  }

  const openSections = () => {
    setShowSectionOptions(true);
    setShowFieldOptions(false);
  };

  const openFields = () => {
    setShowSectionOptions(false);
    setShowFieldOptions(true);
  };

  const closeSectionsOrFields = () => {
    setShowSectionOptions(false);
    setShowFieldOptions(false);
  };

  // const handleCreateSection = async () => {
  //   if (!validateForm()) {
  //       toast.notify(message, { type: "info" });
  //       handleOpenDialog()
  //       return;
  //     }
  
  //   if (!loading) {
  //     setLoading(true);
  //   }

  //   let payload = {
  //     org_id: params.org_id,
  //     app_id: params.app_id,
  //     space_id: params.space_id,
  //     name,
  //     visibility,
  //     description,
  //     button_name: buttonName,
  //     background_image: backgroundImage,
  //     data: appSections,
  //   };

  //   try {
  //     let response = await createAppSection(payload);
  //     if (response.message) {
  //       toast.notify(response.message);
  //     }
  //   } catch (err) {
  //   }
  //   await initPage();
  //   setLoading(false);
  // };

  // const handleUpdateSection = async () => {
  //   if (!validateForm()) {
  //       toast.notify(message, { type: "info" });
  //       handleOpenDialog()
  //       return;
  //     }
  //   if (!loading) {
  //     setLoading(true);
  //   }

  //   let payload = {
  //     org_id: params.org_id,
  //     app_id: params.app_id,
  //     unique_hash: uniqueHash,
  //     name,
  //     visibility,
  //     description,
  //     button_name: buttonName,
  //     background_image: backgroundImage,
  //     data: appSections,
  //   };

  //   try {
  //     let response = await updateAppSection(payload);
  //     if (response.message) {
  //       toast.notify(response.message);
  //     }
  //   } catch (err) {
  //   }
  //   await initPage();
  //   setLoading(false);
  // };


  return (
    <div className="app-builder-parent">
      <div className="app-builder container-fluid">
        <DndProvider backend={backendForDND}>
          <div
            className="row"
            style={{
              height: "100vh",
              backgroundColor: "#ffffff",
            }}
          >
            <div
              className="col-md-4"
              style={{
                backgroundColor: "#f7f7f7",
                padding: "25px 0",
                overflowY: "scroll",
                height: "calc(100vh - 0.1%)"
              }}
            >
              <div className="row">
                <div className="col-md-12" style={{ display: "flex", justifyContent: "space-between", padding: "0 30px" }}>
                {/* {uniqueHash ? (
                    <span>
                      <Button
                          function={handleUpdateSection}
                          style={{ background: `#F7C948`, color: `#fff`, paddingLeft: "20px"  }}
                          text="Update"
                        />
                    </span>
                  ) : (
                    <span>
                      <CustomButton
                        color="primary"
                        onClick={handleCreateSection}
                        style={{ background: `#F7C948`, color: `#fff`, padding: `10px 32px` }}
                      >
                        Save
                      </CustomButton>
                    </span>
                  )} */}
                  <span>
                    <img src={settingsImg} alt="settings"
                        style={{ cursor: "pointer",  padding: `10px 32px` }}
                        onClick={handleOpenDialog} />
                  </span>
                </div>
              </div>
              
              {!showSectionOptions && !showFieldOptions ? (
                <div className="items-to-add">
                  <div className="row pt-5 item-head">
                    <div className="col-md-12" style={{ display: "flex", justifyContent: "space-between" }}>
                      <ContentEditable
                        style={{ float: "left", fontSize: "20px", cursor: "pointer" }}
                        html={name}
                      />
                    </div>
                  </div>
                  <div className="item" onClick={openSections}>
                    <p className={classes.navTitle}>Sections</p>
                  </div>
                  <div className="item" onClick={openFields}>
                    <p className={classes.navTitle}>Fields</p>
                  </div>
                </div>
              ) : (
                ""
              )}

              {showSectionOptions && !showFieldOptions ? (
                <Fragment>
                  <div className="row pt-5">
                    <div className="col-md-12 item-head-2" style={{ display: "flex", justifyContent: "space-between" }}>
                      <div
                        onClick={closeSectionsOrFields}
                        style={{ cursor: "pointer", textAlign: "left", display: "flex", alignItems: "center" }}
                      >
                        <img src={arrow} alt="go back" />
                        <h5 style={{ display: "inline-block" }}>Sections</h5>
                      </div>

                    </div>
                  </div>
                  <div className="sections draggable-sections-container pt-4">
                    <DraggableSectionR1C1 view="pallete" />
                    <DraggableSectionR1C2 view="pallete" />
                    <DraggableSectionR1C3 view="pallete" />
                    <DraggableSectionR1C4 view="pallete" />
                    <DraggableSectionRightBlockR1C2 view="pallete" />
                    <DraggableSectionLeftBlockR1C2 view="pallete" />
                  </div>
                </Fragment>
              ) : (
                ""
              )}

              {!showSectionOptions && showFieldOptions ? (
                <Fragment>
                  <div className="row pt-5">
                    <div className="col-md-12 item-head-2" style={{ display: "flex", justifyContent: "space-between" }}>
                      <div
                        onClick={closeSectionsOrFields}
                        style={{ cursor: "pointer", textAlign: "left", display: "flex", alignItems: "center" }}
                      >
                        <img src={arrow} alt="go back" />
                        <h5 style={{ display: "inline-block" }}>Fields</h5>
                      </div>
                    </div>
                  </div>
                  <div className="fields mr-5 ml-5 pt-4">
                    <DraggableFieldText view="pallete" />
                    <DraggableFieldCategory view="pallete" />
                    <DraggableFieldDate view="pallete" />
                    <DraggableFieldCalculation view="pallete" />
                    <DraggableFieldEmail view="pallete" />
                    <DraggableFieldRelationship view="pallete" />
                    <DraggableFieldProgress view="pallete" />
                    <DraggableFieldPhone view="pallete" />
                    <DraggableFieldNumber view="pallete" />
                    <DraggableFieldMoney view="pallete" />
                    <DraggableFieldLocation view="pallete" />
                    <DraggableFieldLink view="pallete" />
                    <DraggableFieldMember view="pallete" />
                    <DraggableFieldImage view="pallete" />
                    <DraggableFieldDuration view="pallete" />
                  </div>
                </Fragment>
              ) : (
                ""
              )}
            </div>
            <div
              className="col-md-8"
              style={{
                overflowY: "scroll",
                backgroundColor: "#fffffff0",
                height: "calc(100vh - 0.1%)"
              }}
            >
              <div className="row col-md-12">
                <Breadcrumbs
                  style={{ paddingTop: "20px" }}
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  <Tooltip title="View organisation">
                    <Link
                      color="inherit"
                      href="#"
                      underline="none"
                      className={classes.link}
                      onClick={(e) =>
                        history.push(`/dashboard/workspaces/${params.org_id}`)
                      }
                    >
                      {org.name}
                    </Link>
                  </Tooltip>
                  <Tooltip title="View Workspace">
                    <Link
                      color="inherit"
                      href="#"
                      underline="none"
                      className={classes.link}
                      onClick={(e) =>
                        history.push(
                          `/dashboard/organisation/${params.org_id}/workspaces/${params.space_id}/apps`
                        )
                      }
                    >
                      {workspace ? workspace.name : ""}
                    </Link>
                  </Tooltip>
                  <Tooltip title="View App's Items">
                    <Link
                      color="inherit"
                      href="#"
                      underline="none"
                      className={classes.link}
                      onClick={(e) =>
                        history.push(
                          `/dashboard/app/${params.org_id}/${params.app_id}`
                        )
                      }
                    >
                      {app && app.config ? app.config.item_name : ""}
                    </Link>
                  </Tooltip>

                  <Typography color="primary">App Builder</Typography>
                </Breadcrumbs>
              </div>
              <div
                className="row col-md-12 pt-4"
                style={{
                  width: "100%",
                  height: "100%"
                }}
              >
                <DrawingCanvas />
              </div>
            </div>
            <Dialog
              fullWidth={true}
              maxWidth="sm"
              open={updateAppSettings}
              onClose={handleCloseDialog}
              aria-labelledby="form-dialog-title"
              className="dialog-box"
            >
              <DialogContent>
                <div className="head">
                  <h3>App Builder Settings</h3>
                  <DialogTitle
                    id="form-dialog-title"
                    onClose={handleCloseDialog}
                  ></DialogTitle>
                </div>
                <div>App Builder Settings</div>
                <Divider className="divider" />
                <Fragment>
                  <Grid container style={{ paddingTop: "5px" }}>
                    <Grid item xs={4}>
                      <label
                        htmlFor="app_form_name"
                        className={`${classes.required} label`}
                      >
                        Form Name
                      </label>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        disabled={updateAppSettingsLoading}
                        variant="outlined"
                        aria-describedby="App Section Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        margin="normal"
                        id="app_section_name"
                        type="text"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ paddingTop: "5px" }}>
                    <Grid item xs={4}>
                      <label
                        htmlFor="access_settings"
                        style={{ fontSize: "14px" }}
                        className={`${classes.required} label-two`}
                      >
                        Form Access Settings
                      </label>
                    </Grid>
                    <Grid item xs={8}>
                      <RadioGroup
                        disabled={updateAppSettingsLoading}
                        required={true}
                        aria-label="access_setting"
                        id="access_settings"
                        aria-describedby="Select access settings"
                        name="access_setting"
                        value={visibility}
                        style={{ fontSize: "14px" }}
                        onChange={(e) => setVisibility(e.target.value)}
                      >
                        <FormControlLabel
                          value="private"
                          aria-describedby=""
                          control={<Radio color="primary" />}
                          classes={{
                            label: classes.checkboxLabel,
                          }}
                          label="Private (not visible outside platform)"
                        />
                        <FormControlLabel
                          value="public"
                          control={<Radio color="primary" />}
                          classes={{
                            label: classes.checkboxLabel,
                          }}
                          label="Public (visible outside platform)"
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                  <Grid container style={{ paddingTop: "5px" }}>
                    <Grid item xs={4}>
                      <label
                        htmlFor="button_name"
                        className={`${classes.required} label`}
                      >
                        Form Button Name
                      </label>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        disabled={updateAppSettingsLoading}
                        variant="outlined"
                        aria-describedby="Form Button Name"
                        value={buttonName}
                        onChange={(e) => setButtonName(e.target.value)}
                        margin="normal"
                        id="form_button_name"
                        type="text"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid container style={{ paddingTop: "5px" }}>
                    <Grid item xs={4}>
                      <label
                        htmlFor="background_image"
                        className={`${classes.required} label`}
                      >
                        Form Background Image (URL)
                      </label>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        disabled={updateAppSettingsLoading}
                        variant="outlined"
                        aria-describedby="Background Image"
                        value={backgroundImage}
                        onChange={(e) => setBackgroundImage(e.target.value)}
                        margin="normal"
                        id="form_background_image"
                        type="url"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid container style={{ paddingTop: "8px" }}>
                    <Grid item xs={4}>
                      <label
                        htmlFor="app_description"
                        className={`${classes.required} label`}
                      >
                        Form Description
                    </label>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        variant="outlined"
                        aria-describedby="App Description"
                        disabled={updateAppSettingsLoading}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        margin="normal"
                        id="app_description"
                        size="medium"
                        fullWidth
                        multiline
                        rows={5}
                      />
                    </Grid>
                  </Grid>
                </Fragment>
              </DialogContent>
              <div className="actions">
                <Button
                  tooltip={true}
                  tooltip_title="Close App Builder Settings Modal"
                  tooltip_placement="bottom"
                  function={handleCloseDialog}
                  style={{ background: `#fff`, color: `#626262` }}
                  text="Cancel"
                />
                <Button
                  tooltip={true}
                  tooltip_title="Update App Builder Settings"
                  tooltip_placement="bottom"
                  function={handleSaveAppSettings}
                  disabled={updateAppSettingsLoading}
                  style={
                    updateAppSettingsLoading
                      ? { background: `grey`, color: `#000` }
                      : { background: `#F7C948`, color: `#fff` }
                  }
                  text={updateAppSettingsLoading ? "PLEASE WAIT..." : "Store"}
                />
                {updateAppSettingsLoading && (
                  <CircularProgress size={24} className={classes.buttonProgress} />
                )}
              </div>
            </Dialog>
          </div>
        </DndProvider>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  appSections: state.app_builder.appSections,
  uniqueHash: state.app_builder.unique_hash,
  org: state?.sync?.orgs ? state.sync.orgs.find((x) => x.org_id === parseInt(ownProps.match.params.org_id)) : {}
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AppBuilder);
