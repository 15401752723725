import React from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
//   Tooltip,
  Typography,
} from "@material-ui/core";
import cancel from "../../../assets/icons/cancel.svg";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "5%",
    borderBottom: "1px solid #F7F7F7",
  },
  headerText: {
    color: "black",
  },
  btnContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  btnDesc: {
    color: "#DE911D",
    marginTop: "3%",
  },
}));

const TestAutoReply = ({
  openModal,
  handleClose,
  handleTestSubmit,
  changeTestEmail,
  testEmail,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            openModal(false);
          }
        }}
        // hideBackdrop={true}
        disableEscapeKeyDown={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="connector-modal"
      >
        <DialogContent>
          <DialogContent style={{ padding: "8px 14px" }}>
            <DialogContentText id="alert-dialog-description">
              <Typography>
                <div className={classes.modalHeader}>
                  <h5 className={classes.headerText}>Test Auto Reply</h5>
                  <div>
                    <img onClick={handleClose} alt="cancel" src={cancel} />
                  </div>
                </div>

                <div className="d-flex justify-content-flex-start w-100 align-items-flex-start mt-2">
                  <div
                    style={{ marginRight: "5%", alignItems: "flex-start" }}
                    className={classes.btnContainer}
                  >
                    <p className={classes.btnDesc}>
                      Kindly input your email address to test autoreply feature
                    </p>

                    <input
                      style={{
                        width: "500px",
                        border: "1px solid #CFCFCF",
                        backgroundColor: "white",
                        height: "40px",
                      }}
                      value={testEmail}
                      onChange={(e) => changeTestEmail(e.target.value)}
                      placeholder="Enter Email Address"
                    />
                    <div
                      style={{ textAlign: "right", width: "100%" }}
                      className="mt-5"
                    >
                      <button
                        className="btn btn-default mr-2 "
                        onClick={handleTestSubmit}
                        style={{
                          color: "#fff",
                          background: "#F7C948",
                          width: "156px",
                          height: "45px",
                        }}
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </div>
              </Typography>
            </DialogContentText>
          </DialogContent>
          {/* <DialogActions>
            <Button id="button-cancel" onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions> */}
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default TestAutoReply;
