import React from 'react'
import { CircularProgress } from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles";
import DomainItem from './DomainItem';


const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: 'center'
  }
}));


const DomainList = ({ domains, isLoading }) => {
  const classes = useStyles()

  return (
    <>
      {isLoading && <tr>
        <td colSpan='6' className={classes.center}>
          <CircularProgress size={24} />
        </td>
      </tr>}
      {
        domains.map(domain => (
          <>
            <DomainItem {...{domain}} />
          </>
        ))
      }
    </>
  )
}

export default DomainList