import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from 'ckeditor5-custom-build/build/ckeditor';
// import Editor from "components/Tmail/components/CkEditor";
import "./editor.scss";
import {
  uploadimageUrl,
  generateImageUploadHeaders,
} from "../../printUtils/requests";


const headers = generateImageUploadHeaders();
const config = {
  simpleUpload: {
    uploadUrl: uploadimageUrl,
    withCredentials: true,
    headers,
  },
  htmlSupport: {
    allow: [
      {
        name: /.*/,
        attributes: true,
        classes: true,
        styles: true,
      },
    ],
  },
};

// const additionalaHeaderFooterConfig = {
//   toolbar: {
//     shouldNotGroupWhenFull: true,
//   },
//   fontColor: {
//     columns: 8,
//   },
//   fontBackgroundColor: {
//     columns: 8,
//   },
//   fontSize: {
//     options: [9, 12, "default", 14, 16, 18, 24, 26, 28, 34, 48],
//     supportAllValues: true,
//   },
// };
const additionalaBodyConfig = {
  toolbar: {
    shouldNotGroupWhenFull: true,
  },
  fontColor: {
    columns: 8,
  },
  fontBackgroundColor: {
    columns: 8,
  },
  fontSize: {
    options: [9, 12, "default", 14, 16, 18, 24, 26, 28, 34, 48],
    supportAllValues: true,
  },
};

export default function DraftComponent(props) {
    const [, setEditor] = React.useState(null);
    const {
      contents,
      setContents,
      // headerAndFooterContent,
      // setHeaderAndFooterContent,
    } = props;
  
    const handleFocus = (_event, editor) => {
      window.CKEDITOR = editor;
      setEditor(editor);
      return;
    };
  
    // const handleHeaderChange = React.useCallback((_event, editor) => {
    //   const data = editor.getData();
    //   setHeaderAndFooterContent((prev) => ({
    //     ...prev,
    //     header: data,
    //   }));
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);
  
    const handleBodyChange = React.useCallback((_event, editor) => {
      // const sourceEditor = document.querySelector('.ck-source-editing-area');
      const data = editor.getData();
      setContents((prev) => {
        const copy = [...prev];
        copy[props.index] = { ...copy[props.index], body: data };
        return copy;
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    // const handleFooterChange = React.useCallback((_event, editor) => {
    //   const data = editor.getData();
    //   setHeaderAndFooterContent((prev) => ({
    //     ...prev,
    //     footer: data,
    //   }));
    // }, []);
  
    return (
      <div className="page">
        {/* <div className="editor_header">
          <div className="controls">
            {props.page > 1 ? (
              <div className="deletePage">
                <button
                  className="close-link text-danger"
                  onClick={() => props.removePage(props.index)}
                >
                  <i className="changed__ fa fa-times"></i>
                </button>
              </div>
            ) : null}
            <div className="page_number page-number-header page_number_header">
              {`Page {number} of {total}`}
            </div>
          </div>
          <CKEditor
            editor={Editor}
            data={headerAndFooterContent?.header}
            config={{ ...config, ...additionalaHeaderFooterConfig }}
            onChange={handleHeaderChange}
            onFocus={handleFocus}
            disabled={props.getPrintDetailsStatus === "pending"}
          />
        </div> */}
        <div className="editor_body-draft">
          <CKEditor
            editor={Editor}
            data={contents?.[props.index]?.body}
            config={{ ...config, ...additionalaBodyConfig }}
            onChange={handleBodyChange}
            onFocus={handleFocus}
            disabled={props.getPrintDetailsStatus === "pending"}
          />
        </div>
        {/* <div className="editor_footer">
          <CKEditor
            editor={Editor}
            data={headerAndFooterContent?.footer}
            config={{ ...config, ...additionalaHeaderFooterConfig }}
            onChange={handleFooterChange}
            onFocus={handleFocus}
            disabled={props.getPrintDetailsStatus === "pending"}
          />
          <div className="controls controls-footer">
            <div className="page_number page_number_footer">
              {`Page {number} of {total}`}
            </div>
          </div>
        </div> */}
      </div>
    );
  }

