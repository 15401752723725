import React from "react";
import backIcon from "../../assets/icons/backIcon.svg";
import backIconMobile from "../../assets/icons/backBtn.svg";
import useMediaQuery from "@mui/material/useMediaQuery";

import "./BackBotton.css";
const BackButton = () => {
  const isMobile = useMediaQuery("(max-width:600px)");

  const handleBackButtonClick = () => {
    window.history.back();
  };
  return (
    <div>
      {isMobile ? (
        <div
          className="back-btn-cont"
          onClick={handleBackButtonClick}
          style={{ display: "flex", alignItems: "center" }}
        >
          <img
            className="backIcon"
            width="60px"
            alt="back"
            src={backIconMobile}
          />
        </div>
      ) : (
        <div
          className="back-btn-cont"
          onClick={handleBackButtonClick}
          style={{ display: "flex", alignItems: "center" }}
        >
          <img className="backIcon" width="40px" alt="back" src={backIcon} />
          <h5
            className="back-text"
            style={{ marginLeft: "10px", fontSize: "16px" }}
          >
            Back
          </h5>
        </div>
      )}
    </div>
  );
};

export default BackButton;
