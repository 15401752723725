import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Box
} from "@material-ui/core";

const ViewBackup = ({ openModal, handleClose, item }) => {

    function millisToMinutesAndSeconds(millis) {
        var minutes = Math.floor(millis / 60000);
        var seconds = ((millis % 60000) / 1000).toFixed(0);
        return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
      }

    //   TODO: Remove this after you update the view
    console.log(item);

    return (
        <div>
            <Dialog
                open={openModal}
                onClose={handleClose}
                // hideBackdrop={true}
                disableBackdropClick={false}
                disableEscapeKeyDown={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth="500px"
            >
                <DialogContent>
                    <Box>
                        <h6>SQL Backup Progress Information</h6>
                        {
                            item ?
                                <table className="table table-bordered mt-4">
                                    <tr>
                                        <td>AppId</td>
                                        <td>{item.app_id}</td>
                                    </tr>
                                    <tr>
                                        <td>OrgId</td>
                                        <td>{item.org_id}</td>
                                    </tr>
                                    <tr>
                                        <td>Driver</td>
                                        <td>{item.driver.toUpperCase()}</td>
                                    </tr>
                                    <tr>
                                        <td>Message</td>
                                        <td>{item.message ?? 'N/A'}</td>
                                    </tr>
                                    <tr>
                                        <td>Synced Items</td>
                                        <td>{item.synced_items}</td>
                                    </tr>
                                    <tr>
                                        <td>Status</td>
                                        <td>{item.status}</td>
                                    </tr>
                                    <tr>
                                        <td>Started At</td>
                                        <td>{item.started_at}</td>
                                    </tr>
                                    <tr>
                                        <td>End At</td>
                                        <td>{item.ended_at ?? "N/A" }</td>
                                    </tr>
                                    <tr>
                                        <td>Duration</td>
                                        <td>{millisToMinutesAndSeconds(item.duration)}</td>
                                    </tr>
                                    {
                                        item.errors !== null ?
                                        <tr>
                                            <td>Errors</td>
                                          {JSON.parse(item.errors).map(err =>(
                                            <p key={err}>{err}</p>
                                          ))}
                                        </tr> : null
                                    }
                                    
                                </table> : null
                        }
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button id="button-cancel" onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ViewBackup;
