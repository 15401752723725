import React from 'react';
import EditorComponent from './EditorComponent';
import { usePrintData } from '../../context/PrintProvider';

import './editor.scss';

const EditorsContainer = (props) => {
  const [pages, setPages] = React.useState([]);
  const { template } = usePrintData();

  React.useEffect(() => {
    if (template?.document?.pages) {
      const count = template?.document?.pages;
      if (typeof count === 'number') {
        const pages = new Array(count).fill(EditorComponent);
        return setPages(pages);
      } else {
        setPages([]);
      }
    } else {
      setPages([EditorComponent]);
    }
  }, [template?.document?.pages]);

  const addNewPage = React.useCallback(function () {
    setPages((prev) => [...prev, EditorComponent]);
  }, []);

  const removePage = React.useCallback(function (index) {
    setPages((prev) => {
      if (prev.length > 1) {
        const newPages = [...prev];
        newPages.splice(index, 1);

        // remove data from the contents array
        props.setContents((prev) => {
          const newContent = [...prev];
          newContent.splice(index, 1);
          return newContent;
        });

        return newPages;
      }
      return prev;
    });

    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section id='editor_container'>
      <div className='canvas_container' id='document-editor' size='A4' orientation='portrait'>
        <div id='document-editor-scale' className='scale' style={{ transform: 'scale(1)' }}>
          {pages.length > 0
            ? pages.map((Page, index) => {
                return <Page key={index} index={index} page={index + 1} removePage={() => removePage(index)} {...props} />;
              })
            : null}
        </div>
      </div>

      <section className='text-center mt-5'>
        <button className='btn btn-outline-success text-center' type='button' onClick={addNewPage}>
          Add New Section
        </button>
      </section>
    </section>
  );
};

export default EditorsContainer;
