import React, { useState, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  isTrialCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#FFFBF1",
    border: "1px solid #F2B11B",
    padding: "2%",
    borderRadius: "10px",
    marginTop: "2%",
  },
  heading: {
    fontWeight: "bold",
    fontSize: "16px",
    color: "#F2B11B",
  },
  btn: {
    border: "1px solid #F2B11B",
    color: "#F2B11B",
    padding: "10px 25px",
    borderRadius: "5px",
    fontWeight: "bold",
    fontSize: "16px",
    backgroundColor: "#FFFBF1",
  },
  timerTime: {
    fontWeight: "800",
    fontSize: "18px",
    color: "#DF1C41",
  },
  timeCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "10px",
  },
  descInfo: {
    border: "1px solid #E5E5E5",
    backgroundColor: "#FBFCFC",
    marginTop: "5%",
    padding: "3%",
    borderRadius: "10px",
    textAlign: "left",
  },
}));
const CountdownTimer = ({ setTrialDays }) => {
  const classes = useStyles();
  const userFreeTrial = useSelector(
    (state) => state?.user?.authUser.trial_started_at
  );

  const trialStartedAt = userFreeTrial;
  const trialStartDate = useMemo(() => new Date(trialStartedAt), [trialStartedAt]);
  const countdownEndDate = useMemo(() => {
    return new Date(trialStartDate.getTime() + 15 * 24 * 60 * 60 * 1000);
  }, [trialStartDate]);

  const [timeLeft, setTimeLeft] = useState(
    countdownEndDate.getTime() - new Date().getTime()
  ); // Calculate the initial time left

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date().getTime();
      const timeLeft = countdownEndDate.getTime() - now;
      setTimeLeft(timeLeft > 0 ? timeLeft : 0);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [countdownEndDate, setTimeLeft]);

  const calculateTimeLeft = (milliseconds) => {
    const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));

    return { days };
  };

  const { days } = calculateTimeLeft(timeLeft);

  useEffect(() => {
    setTrialDays?.({ days });
  }, [days, setTrialDays]);

  return (
    <div className="d-flex align-items-center justify-content-center pl-4 pr-4">
      {days === 0 ? (
        <div className={classes.timeCont}>
          <div>
            <p className={classes.timerTime}>Expired</p>
          </div>
        </div>
      ) : (
        <div className={classes.timeCont}>
          <div>
            <p className={classes.timerTime}>
              {days.toString().padStart(2, "0")} Days left
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CountdownTimer;
