import React from 'react';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import actionsIcon from 'assets/images/actionsIcon.svg';
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';
import EditOutline from '@material-ui/icons/EditOutlined';
import ShuffleOutlinedIcon from '@material-ui/icons/ShuffleOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import RestorePageOutlinedIcon from '@material-ui/icons/RestorePageOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

import './table.scss';
import RenderByPermission from 'components/RenderByPermission';

const id = 'menu_toggle';
const TableActionsMenu = ({ data, handleDelete, handleArchive, handleEdit, handleRestore, handleClone, handleTransfer, templateCategory }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const toggle = () => setOpen((open) => !open);
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <div className='actions_container'>
      <div aria-describedby={id} style={{ backgroundImage: `url(${actionsIcon})` }} role='button' onClick={toggle} ref={anchorRef}></div>
      <Popper id={id} open={open} anchorEl={anchorRef.current} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id='menu-list-grow'>
                  {templateCategory === 'archived' ? (
                    <div>
                      <MenuItem onClick={() => handleRestore(data.id)} style={menuitem_styles}>
                        <p className='menu_item'>
                          <RestorePageOutlinedIcon style={iconStyles} />
                          Restore
                        </p>
                      </MenuItem>
                      <RenderByPermission allowTeamMemberAccess={false}>
                        <MenuItem onClick={() => handleDelete(data.id)} style={menuitem_styles}>
                          <p className='menu_item'>
                            <DeleteOutlineOutlinedIcon style={iconStyles} />
                            Delete
                          </p>
                        </MenuItem>                     
                      </RenderByPermission>
                    </div>
                  ) : (
                    <div>
                      <MenuItem onClick={() => handleEdit(data.id)} style={menuitem_styles}>
                        <p className='menu_item'>
                          <EditOutline style={iconStyles} />
                          Edit
                        </p>
                      </MenuItem>
                      <MenuItem onClick={() => handleTransfer(data.id)} style={menuitem_styles}>
                        <p className='menu_item'>
                          <ShuffleOutlinedIcon style={iconStyles} />
                          Transfer
                        </p>
                      </MenuItem>
                      <MenuItem onClick={() => handleClone(data.id)} style={menuitem_styles}>
                        <p className='menu_item'>
                          <FileCopyOutlinedIcon style={iconStyles} />
                          Clone
                        </p>
                      </MenuItem>
                      <RenderByPermission allowTeamMemberAccess={false}>       
                        <MenuItem onClick={() => handleArchive(data.id)} style={menuitem_styles}>
                          <p className='menu_item'>
                            <ArchiveOutlinedIcon style={iconStyles} />
                            Archive
                          </p>
                        </MenuItem>
                      </RenderByPermission>
                    </div>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

export default TableActionsMenu;

const menuitem_styles = { height: '30px', display: 'flex', alignItems: 'center' };
const iconStyles = { width: '16px', height: '16px', marginRight: '4px', marginBottom: '4px' };
