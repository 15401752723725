import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
// import AppBar from "@material-ui/core/AppBar";
// import Tabs from "@material-ui/core/Tabs";
// import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
// import VisibilityIcon from "@material-ui/icons/Visibility";
// import PaymentIcon from "@material-ui/icons/Payment";
import styled from "@emotion/styled";
import UserInfo from "./UserInfo";
import { useSelector, useDispatch } from "react-redux";
// import PeopleIcon from "@material-ui/icons/People";
import Teams from "./Teams";
import BillingCards from "./BillingCards";
import PlansAccount from "./PlansAccount";
import { getTeamMembers, setUser } from "../../redux/actions/user";
import { fetchCards, getPlans, userStats } from "../../services/sync";
import { getMe } from "../../services/auth";
import { toast } from "../../helpers/apiRequests";
import RenderByPermission from "components/RenderByPermission";
import trashIcon from "../../assets/icons/trashIcon.svg";
import walletIcon from "../../assets/icons/walletIcon.svg";
import userIcon from "../../assets/icons/teamsIcon.svg";
import cardIcon from "../../assets/icons/cardIcon.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import BackButton from "components/atoms/BackButton";

const isTeamMember = localStorage.getItem("team_member_uuid");

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    marginTop: "-5%",
  },
  backButton: {
    backgroundColor: "#f7c948",
    boxShadow: "0px 3px 6px #00000029",
    padding: "1vh 1.5vw",
    outline: "none",
    textDecoration: "none",
    border: "none",
    borderRadius: "6px",
    color: "white",
    marginBottom: "2%",
    margin: "1%",
  },
  account_page_title_cont: {
    margin: "2% 0",
  },
  account_page_title: {
    fontSize: "18px",
    fontWeight: "700",
  },
  account_page_desc: {
    color: "#7E7E7E",
    fontSize: "15px",
  },
  account_info: {
    border: "1px solid #E1E1E1",
    borderRadius: "8px",
    display: "flex",
    height: "80vh",
  },
  tabCont: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "15%",
  },
  tab: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    borderTop: "1px solid #E1E1E1",
    borderBottom: "1px solid #E1E1E1",
    width: "100%",
    padding: "5% 5%",
  },
  menuTab: {
    borderRight: "1px solid #E1E1E1",
    width: "30%",
  },
  tabIcons: {
    marginRight: "3%",
  },
  tabPanel: {
    width: "80%",
  },

  "@media screen and (max-width: 600px)": {
    account_info: {
      flexDirection: "column",
      border: "none",
      minHeight: "100%",
    },
    menuTab: {
      width: "100%",
      borderRight: "none",
    },
    tabCont: {
      flexDirection: "row",
      width: "88vw",
      border: "none",
      overflowX: "auto",
      justifyContent: "flex-start",
      marginBottom: "2%",
    },
    tabName: {
      fontSize: "15px",
    },
    tabIcons: {
      display: "none",
    },
    tab: {
      width: "100%",
      border: "none",
      // borderBottom: "1px solid #E1E1E1",
      padding: "4% 0",
      borderRadius: "30px",
      justifyContent: "center",
      margin: "1%",
      cursor: "pointer",
    },
    tabPanel: {
      width: "100%",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  // const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={isMobile ? { padding: "0" } : { padding: "24px" }}>
          <div>{children}</div>
          <br />
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  // index: 2
};

// function a11yProps(index) {
//   return {
//     id: `scrollable-force-tab-${index}`,
//     "aria-controls": `scrollable-force-tabpanel-${index}`,
//   };
// }

const Accounts = () => {
  const [billings, setBillings] = useState(false);
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:600px)");

  const getLastUrlParam = () => {
    const url = new URL(window.location.href);
    const queryParams = url.searchParams;
    const entries = [...queryParams.entries()];
    return entries.length > 0 ? entries.pop()[1] : null;
  };

  const lastParam = getLastUrlParam();

  // const queryString = window.location.search;
  // const urlParams = new URLSearchParams(queryString);
  // const section = urlParams.get("section");

  // const activeTab = () => {
  //   if (section === "plans") {
  //     return 2;
  //   } else {
  //     return 0;
  //   }
  // };

  // const classes = useStyles();

  const [value, setValue] = useState(1);
  useEffect(() => {
    if (lastParam === "plans") {
      setValue(2);
    }
  }, [lastParam]);
  
  const user = useSelector((state) => state.user.authUser);
  const [plans, setPlans] = useState([]);
  const [reloadStore, setReloadStore] = useState(false);
  const active_plan = user?.active_plan;
  const columns = [
    { title: "Card Type", field: "card_brand" },
    { title: "Card No", field: "last_four_digit" },
    { title: "Card Expiry", field: "card_expire" },
  ];
  const [cardList, setCardList] = useState({ data: [], columns: columns });
  const dispatch = useDispatch();
  const [userStatsData, setUserStatsData] = useState(null)

  const loadCards = useCallback(async () => {
    function cards() {
      fetchCards()
        .then((theCards) => {
          const cards = theCards.data;
          // if (cards.length < 1) {
          //   toast.notify("You're yet to have a card on for Sync, kindly click on the modal above to add one", { type: "info"} );
          // }

          setCardList({
            data: [],
            columns: [
              { title: "Card Type", field: "card_brand" },
              { title: "Card No", field: "last_four_digit" },
              { title: "Card Expiry", field: "card_expire" },
            ],
          });

          cards.forEach((d) => {
            setCardList((prevState) => {
              const data = [...prevState.data];
              data.push({
                card_brand: d.card_brand,
                last_four_digit: "***" + d.last_four_digit,
                card_expire: d.card_expire,
                hidden_action: d.make_default,
                hidden_id: d.id,
                created_at: d.created_at,
                make_default: d.make_default,
              });
              return { ...prevState, data };
            });
          });
        })
        .catch((e) => {
          toast.notify("Error fetching cards, try refreshing this page", {
            type: "error",
          });
        });
    }
    await cards();
  }, []);

  useEffect(() => {
    const reloadUser = async () => {
      const response = await getMe();

      dispatch(setUser(response.data));
    };
    dispatch(getTeamMembers());
    reloadUser();
  }, [dispatch, reloadStore]);

  useEffect(() => {
    getPlans().then((res) => {
      let activePlanSeen = false;
      let plans = res.map((plan) => {
        if (!active_plan) {
          plan.text = "Choose Plan";
          return plan;
        }
        if (active_plan?.plan_id === plan.id) {
          activePlanSeen = true;
          plan.text = "Current Plan";
          return plan;
        }
        if (!activePlanSeen && active_plan?.plan_id !== plan.id) {
          plan.text = "Downgrade";
          if (active_plan.planName === "Free") {
            plan.text = "Upgrade";
          }
        } else {
          plan.text = "Upgrade";
        }
        return plan;
      });
      setPlans(plans);
    });
    loadCards();
  }, [loadCards, active_plan]);

  useEffect(() => {
    async function fetchUserStats() {
      try {
        const me = await userStats();
        setUserStatsData(me);
      } catch (e) {
        console.error(e);
      }
    }
    fetchUserStats();
  }, []);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const handleTabClick = (index) => {
    setValue(index);
  };

  const functionTwoMain = () => {
    setBillings(true);
    setValue(3);
  };

  const functionThreeMain = () => {
    setBillings(false);
  };

  const upgradePlan = (planId) => {
    toast.notify("Upgrading plan");
    // setReloadStore(true);
    // setValue(1);
    // setReloadStore(false);
  };

  const [bluesnapToken, setBlueSnapToken] = useState(null);

  const updateBlueSnapToken = React.useCallback(async () => {
    function getProfile() {
      getMe()
        .then((me) => {
          setBlueSnapToken(me.data.bluesnap_token);
        })
        .catch((e) => {
          toast.notify("An error occurred, try refreshing this page", {
            type: "error",
          });
        });
    }
    await getProfile();
  }, []);

  React.useEffect(() => {
    updateBlueSnapToken();
  }, [updateBlueSnapToken]);

  return (
    <Accounts.Wrapper className={classes.root}>
      <div>
        <BackButton />
      </div>
      <div className={classes.account_page_title_cont}>
        <h4 className={classes.account_page_title}>My Account</h4>
        <p className={classes.account_page_desc}>
          Having an up-to-date email address attached to your account is a great
          step towards improved security
        </p>
      </div>

      <div className={classes.account_info}>
        <div className={classes.menuTab}>
          <UserInfo user={user} />
          {!isTeamMember ? (
            <div className={classes.tabCont}>
              <div
                style={
                  isMobile
                    ? value === 1
                      ? {
                          backgroundColor: "#FFFAEB",
                          border: "1px solid #F7C948",
                          color: "#F7C948",
                          cursor: "pointer",
                        }
                      : {
                          backgroundColor: "#F7F7F7",
                          border: "1px solid #CFCFCF",
                          color: "#000",
                          cursor: "pointer",
                        }
                    : value === 1
                    ? {
                        backgroundColor: "#F7F7F7",
                      }
                    : {
                        cursor: "pointer",
                      }
                }
                onClick={() => handleTabClick(1)}
                className={classes.tab}
              >
                <img className={classes.tabIcons} alt="" src={userIcon} />
                <h5
                  style={
                    isMobile ? { width: "30vw", textAlign: "center" } : null
                  }
                  className={classes.tabName}
                >
                  Teams
                </h5>
              </div>
              <div
                style={
                  isMobile
                    ? value === 2
                      ? {
                          backgroundColor: "#FFFAEB",
                          border: "1px solid #F7C948",
                          color: "#F7C948",
                          cursor: "pointer",
                        }
                      : {
                          backgroundColor: "#F7F7F7",
                          border: "1px solid #CFCFCF",
                          color: "#000",
                          cursor: "pointer",
                        }
                    : value === 2
                    ? {
                        backgroundColor: "#F7F7F7",
                      }
                    : {
                        cursor: "pointer",
                      }
                }
                onClick={() => handleTabClick(2)}
                className={classes.tab}
              >
                <img className={classes.tabIcons} alt="" src={walletIcon} />
                <h5
                  style={
                    isMobile ? { width: "39vw", textAlign: "center" } : null
                  }
                  className={classes.tabName}
                >
                  Subscription Plans
                </h5>
              </div>
              <div
                style={
                  isMobile
                    ? value === 3
                      ? {
                          backgroundColor: "#FFFAEB",
                          border: "1px solid #F7C948",
                          color: "#F7C948",
                          cursor: "pointer",
                        }
                      : {
                          backgroundColor: "#F7F7F7",
                          border: "1px solid #CFCFCF",
                          color: "#000",
                          cursor: "pointer",
                        }
                    : value === 3
                    ? {
                        backgroundColor: "#F7F7F7",
                      }
                    : {
                        cursor: "pointer",
                      }
                }
                onClick={() => handleTabClick(3)}
                className={classes.tab}
              >
                <img className={classes.tabIcons} alt="" src={cardIcon} />
                <h5
                  style={
                    isMobile ? { width: "35vw", textAlign: "center" } : null
                  }
                  className={classes.tabName}
                >
                  Billing Cards
                </h5>
              </div>
              <div
                style={
                  isMobile
                    ? value === 4
                      ? {
                          backgroundColor: "#FFFAEB",
                          border: "1px solid #F7C948",
                          color: "#F7C948",
                          cursor: "pointer",
                        }
                      : {
                          backgroundColor: "#F7F7F7",
                          border: "1px solid #CFCFCF",
                          color: "#000",
                          cursor: "pointer",
                        }
                    : value === 4
                    ? {
                        backgroundColor: "#F7F7F7",
                      }
                    : {
                        cursor: "pointer",
                      }
                }
                onClick={() => {
                  window.Intercom(
                    "showNewMessage",
                    "Hello. I would like to delete my Sync account and all data attached to it."
                  );
                }}
                className={classes.tab}
              >
                <img className={classes.tabIcons} alt="" src={trashIcon} />
                <h5
                  style={
                    isMobile ? { width: "39vw", textAlign: "center" } : null
                  }
                  className={classes.tabName}
                >
                  Delete Account
                </h5>
              </div>
            </div>
          ) : (
            <div className={classes.tabCont}>
              <div
                style={
                  isMobile
                    ? value === 1
                      ? {
                          backgroundColor: "#FFFAEB",
                          border: "1px solid #F7C948",
                          color: "#F7C948",
                          cursor: "pointer",
                        }
                      : {
                          backgroundColor: "#F7F7F7",
                          border: "1px solid #CFCFCF",
                          color: "#000",
                          cursor: "pointer",
                        }
                    : value === 1
                    ? {
                        backgroundColor: "#F7F7F7",
                      }
                    : {
                        cursor: "pointer",
                      }
                }
                className={classes.tab}
              >
                <img className={classes.tabIcons} alt="" src={userIcon} />
                <h5
                  style={
                    isMobile ? { width: "30vw", textAlign: "center" } : null
                  }
                  className={classes.tabName}
                >
                  Teams
                </h5>
              </div>
            </div>
          )}

          {/* <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="on"
              indicatorColor="primary"
              textColor="primary"
              aria-label="scrollable force tabs example"
              // initialSelectedIndex={value}
            >
              {!isTeamMember
            ? TabsItemSuperAdmin.map((item, index) => {
                return (
                  <Tab
                    key={item.label}
                    label={item.label}
                    icon={item.icon}
                    {...a11yProps(index)}
                  />
                );
              })
            : TabsItemTeamMember.map((item, index) => {
                return (
                  <Tab
                    key={item.label}
                    label={item.label}
                    icon={item.icon}
                    {...a11yProps(index)}
                  />
                );
              })}
            </Tabs>
          </AppBar> */}
        </div>
        <div className={classes.tabPanel}>
          {/* <TabPanel value={value} index={0}>
            <RenderByPermission allowTeamMemberAccess={true}>
              <UserInfo user={user} />
            </RenderByPermission>
          </TabPanel> */}
          <TabPanel value={value} index={1}>
            <RenderByPermission allowTeamMemberAccess={true}>
              <Teams />
            </RenderByPermission>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <RenderByPermission allowTeamMemberAccess={false}>
              <PlansAccount
                upgradePlan={upgradePlan}
                user={user}
                plans={plans}
                reloadStore={setReloadStore}
                loadCards={loadCards}
                cardList={cardList}
                functionTwoMain={functionTwoMain}
                userStatsData={userStatsData}
              />
            </RenderByPermission>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <RenderByPermission allowTeamMemberAccess={false}>
              <BillingCards
                cardList={cardList}
                loadCards={loadCards}
                columns={columns}
                billings={billings}
                functionThreeMain={functionThreeMain}
                bluesnapToken={bluesnapToken}
              />
            </RenderByPermission>
          </TabPanel>
        </div>
      </div>
    </Accounts.Wrapper>
  );
};

Accounts.Wrapper = styled.div`
  .Tab__bar {
    background-color: #fff;
    box-shadow: none;
    border-bottom: 1px solid #e1e1e1;
  }
`;
export default Accounts;
