import React, { useState } from "react";
import OrphanModal from "../Modals/OrphanModal";

const OrphanGridAction = (props) => {
    const data = typeof props["data"] !== "undefined" ? props["data"] : null;
    const [open, setOpen] = useState(false);
    const [emailItem, setEmailItem] = useState(null);

    const handleClose = () => setOpen(false);

    const handleOpen = (data) => {
       
        setEmailItem(data)
        setOpen(true);
    }


    return (
        <>
            <OrphanModal
                openModal={open}
                handleClose={handleClose}
                email={emailItem}
            />
            {data !== null ? (
                <center>
                    <i
                        className="fa fa-eye text-info"
                        style={{
                            fontSize: "15px",
                            cursor: "pointer",
                        }}
                        title="View email"
                        onClick={() => handleOpen(data)}
                    ></i>
                </center>
            ) : (
                ""
            )}
        </>
    );
};

export default OrphanGridAction;
