/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { CircularProgress } from "@material-ui/core";
//Map Data
import CreateNewConnector from "../../components/Modal/Connector/CreateNewConnector";
import Error from "./components/Error/Error";
import { setUser } from "../../redux/actions/user";
import axios from "../../config";
import { getMe } from "../../services/auth";
import Loader from "../../components/Loader/Loader";
import SelectDbModal from "../../components/Modal/SelectDbModal";
import AlertModal from "../../components/Modal/AlertModal";
import ExternalConnector from "../../components/Modal/ExternalConnector";
import ConnectorGrid from "./ConnectorGrid";
import BackButton from "components/atoms/BackButton";

const SqlConnectorV2 = (props) => {
  const [fetchingUser, setFetchingUser] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const user = useSelector((state) => state.user.authUser);
  const dispatch = useDispatch();
  const [openExternal, setOpenExternal] = useState(false);
  const [connectors, setConnectors] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setOpenAlert(true);
  };
  const openExternalConnector = () => {
    setOpenExternal(true);
  };
  const closeExternalConnector = () => {
    setOpenExternal(false);
  };

  const OpenDefaultConnector = () => {
    setOpenModal(true);
  };
  const addConnectors = (val) => {
    const oldData = [...connectors];
    const newData = [...val, ...oldData];
    setConnectors(newData);
  };

  const getConnectors = async () => {
    try {
      setLoading(true);
      const url = `/list-user-db`;
      const res = await axios.post(url);
      const response = res.data;
      console.log(response);
      setConnectors(response);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getConnectors();
  }, []);

  const handleClose = () => {
    setOpenModal(false);
  };

  const getUser = async () => {
    setFetchingUser(true);
    const response = await getMe();
    setFetchingUser(false);

    dispatch(setUser(response.data));
  };
  
  // Select DB Modal
  const [openSelectDB, setOpenSelectDB] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const closeAlert = () => {
    setOpenAlert(false);
  };

  if (loading) {
    return (
      <center>
        <CircularProgress size={30} />
      </center>
    );
  }
  console.log({ connectors });

  return (
    <>
      <CreateNewConnector
        openModal={openModal}
        handleClose={handleClose}
        reloadUser={getUser}
        addConnectors={addConnectors}
      />
      <ExternalConnector
        openModal={openExternal}
        handleClose={closeExternalConnector}
        addConnectors={addConnectors}
        isEdited={false}
      />
      <div>
        <div>
          <BackButton />
        </div>
        <div className="mt-3">
          {connectors && connectors.length > 0 ? (
            <div className="d-flex justify-content-between">
              <h5>Connectors</h5>
              <button
                className="btn btn-default"
                onClick={() => {
                  handleClickOpen();
                }}
                style={{
                  background: "#f7c948",
                  color: "#fff",
                }}
              >
                Create Connector
              </button>
            </div>
          ) : null}
        </div>
        <p className="mt-2">Configured Connections</p>

        {fetchingUser ? (
          <Loader />
        ) : connectors && connectors.length > 0 ? (
          <ConnectorGrid connectors={connectors} />
        ) : (
          <div className="d-flex justify-content-center">
            <Error
              errorMsg="No Connection Found"
              errorAction="Setup MySQL/PGSQL Syncing"
              onClick={() => {
                handleClickOpen();
              }}
            />
          </div>
        )}
      </div>
      <SelectDbModal
        reloadUser={getUser}
        user={user}
        open={openSelectDB}
        setDbModal={setOpenSelectDB}
        addConnectors={addConnectors}
      />
      <AlertModal
        openModal={openAlert}
        handleClose={closeAlert}
        openDefault={OpenDefaultConnector}
        openExternal={openExternalConnector}
      />
    </>
  );
};

export default withRouter(SqlConnectorV2);
