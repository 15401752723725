import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import "./dside.css";
import MainNavigation from "./Navigation/MainNavigation";
import { Divider } from "@material-ui/core";
import { setOrgs } from "../../../../redux/actions/sync";
import { connect } from "react-redux";
import { green } from "@material-ui/core/colors";
import { setNav } from "../../../../redux/actions/sidebar";

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    marginRight: theme.spacing(1),
    textAlign: "center",
    background: "#FFF3C4",
    borderRadius: "3px",
    color: "#DE911D",
  },
  root: {
    flexGrow: 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  control: {
    padding: theme.spacing(2),
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const Sidebar = ({ open, handleDrawerOpen, user, orgs, setOrgs }) => {
  const classes = useStyles();
  return (
    <div className="dside">
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        

        <div className="sidebar__content">
          {open ? null: <Divider />}
          <MainNavigation />
          {open ? (
            <p
              className="subheading mt-4 mb-3"
              style={{
                fontSize: "16px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
            </p>
          ) : (
            <Divider />
          )}
        </div>
      </Drawer>
     
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.authUser,
});

const mapDispatchToProps = (dispatch) => ({
  setOrgs: (data) => dispatch(setOrgs(data)),
  setNav: (data) => dispatch(setNav(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
