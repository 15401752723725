import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: '14px',
    },
  },
}));

export default function ProgressBar({ isLoading, color = 'primary' }) {
  const classes = useStyles();

  if (!isLoading) return null;

  return (
    <div className={classes.root}>
      <LinearProgress color={color} />
    </div>
  );
}
