import React from 'react'
import { Button, makeStyles } from '@material-ui/core';
import visa from  "../../../assets/images/visa.svg"
import JCB from '../assets/JCB_logo.png'
import Amex from '../assets/amex.png'
import Discover from '../assets/Discover.jpeg'
import { AiOutlinePlus } from 'react-icons/ai'
import dayjs from 'dayjs';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    borderRadius: '4px',
    background: '#FFF',
    boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.05)",
    marginBottom: '14px'
  },
  cardHeader: {
    padding: '20px 30px 14px 30px',
  },
  cardHeaderTitle: {
    color: "#000",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal"
  },
  cardBody: {
    display: 'flex',
    gap: '25px',
    borderTop: '1px solid #E8E8E8',
    padding: '17px 19px',
    alignItems: 'center',
    // justifyContent: 'space-between'
  },
  container: {
    display: 'flex',
    gap: '20px',
  },
  cardImg: {
    width: '70px'
  },
  title: {
    color: '#000',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
    letterSpacing: '0.06px',
    marginBottom: '5px'
  },
  body: {
    color: "#7E7E7E",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    marginBottom: '0px'
  },
  checkInput: {
    width: '18px',
    height: '18px',
    borderRadius: '4px',
    appearance: 'auto',
    marginLeft: 'auto'
  },
  btnOutlined: {
    width: "100%",
    height: "35px",
    borderRadius: "3px",
    color: "#F0B429 !important",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    textTransform: 'capitalize'
  }
}));

const PaymentMethodsCard = ({ setCurrentScreen, cardList, setSelectedCard, loading }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.card}>
        <div className={classes.cardHeader}>
          <h4 className={classes.cardHeaderTitle}>Payment methods</h4>
        </div>
        {cardList?.map(item => <div key={item.id} className={classes.cardBody}>
          {item.card_brand === "VISA" && <img src={visa} alt="" className={classes.cardImg} />}
          {item.card_brand === "JCB" && <img src={JCB} alt="" className={classes.cardImg} />}
          {item.card_brand === "AMEX" && <img src={Amex} alt="" className={classes.cardImg} />}
          {item.card_brand === "DISCOVER" && <img src={Discover} alt="" className={classes.cardImg} />}
          <div>
            <h6 className={classes.title}>Ending in: **** {item.last_four_digit}</h6>
            <p className={classes.body}>Added: {dayjs(item.created_at).format('ddd, MMM DD YYYY')}</p>
          </div>
          <input type="radio" name='payment-option' onClick={() => setSelectedCard(item.id)} defaultValue={item.id} defaultChecked={item.make_default} className={classes.checkInput} />
        </div>)}
      </div>

      <Button type='button'
        variant='outlined'
        color='primary'
        disableElevation={true}
        className={classes.btnOutlined}
        onClick={() => setCurrentScreen('add-card')}
      ><AiOutlinePlus /> &nbsp; Add new card</Button>
    </>
  )
}

export default PaymentMethodsCard