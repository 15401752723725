import { React, useState, useEffect, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import { ItemTypes } from "../../../constants/ItemTypes";
import { connect, useDispatch } from "react-redux";
import CustomButton from "../../../components/atoms/CustomButton";
import SectionR1C1 from "./Sections/SectionR1C1";
import SectionR1C2 from "./Sections/SectionR1C2";
import SectionR1C3 from "./Sections/SectionR1C3";
import SectionR1C4 from "./Sections/SectionR1C4";
import SectionLeftR1C2 from "./Sections/SectionLeftR1C2";
import SectionRightR1C2 from "./Sections/SectionRightR1C2";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { createItem } from "../../../services/sync";
import { initialState } from "../../../redux/actions/appItems";

const ItemSections = (props) => {
  const { app, sections, externalIds, types, values, buttonName } = props;
  const dispatch = useDispatch();
  const [err, setErr] = useState(false);
  const [success, setSuccess] = useState(false);
  const [button, setButton] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setButton(true);

    if (!values) {
      setButton(false);
      setErr(true);
      setSuccess(false);
      return false;
    }

    let allValues = [];

    externalIds.forEach((element) => {
      const val = values[element];

      allValues.push(val);
    });
    const data = {
      app_id: app,
      external_id: externalIds,
      type: types,
      value: allValues,
      field_category_id: allValues,
    };
    createItem(data)
      .then((res) => {
        setButton(false);
        setErr(false);
        setSuccess(true);
        dispatch(initialState());
      })
      .catch((error) => {
        externalIds.splice(0, externalIds.length);
        types.splice(0, types.length);
        setSuccess(false);
        setButton(false);
        setErr(true);
        dispatch(initialState());
      });
  };

  useEffect(() => {
    dispatch(initialState());
  }, [dispatch]);

  return (
    <Fragment>
      {err || success ? (
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          {success ? (
            <Grid item xs={12} sm={6}>
              <Alert severity="success">
                <AlertTitle>Success</AlertTitle>
                Item created successfully!
              </Alert>
            </Grid>
          ) : (
            ""
          )}
          {err ? (
            <Grid item xs={12} sm={6}>
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                Error creating this Item, check your details and try again!
              </Alert>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      ) : (
        ""
      )}
      <Grid container spacing={1} justifyContent="center" alignItems="center">
        <div className="row justify-content-center">
          <div className="col-md-10">
            {sections.length <= 0 ? (
              ""
            ) : (
              <form style={{ width: "inherit" }} onSubmit={onSubmit}>
                {" "}
                {sections.map((section, key) => {
                  if (section.type === ItemTypes.SECTION_R1C1) {
                    return (
                      <SectionR1C1 key={section.id} columns={section.columns} />
                    );
                  }
                  if (section.type === ItemTypes.SECTION_R1C2) {
                    return (
                      <SectionR1C2 key={section.id} columns={section.columns} />
                    );
                  }
                  if (section.type === ItemTypes.SECTION_R1C3) {
                    return (
                      <SectionR1C3 key={section.id} columns={section.columns} />
                    );
                  }
                  if (section.type === ItemTypes.SECTION_R1C4) {
                    return (
                      <SectionR1C4 key={section.id} columns={section.columns} />
                    );
                  }
                  if (section.type === ItemTypes.SECTION_LEFT_BLOCK_R1C2) {
                    return (
                      <SectionRightR1C2
                        key={section.id}
                        columns={section.columns}
                      />
                    );
                  }
                  if (section.type === ItemTypes.SECTION_RIGHT_BLOCK_R1C2) {
                    return (
                      <SectionLeftR1C2
                        key={section.id}
                        columns={section.columns}
                      />
                    );
                  }
                  return <div key={key}></div>;
                })}
                <br />
                &nbsp;&nbsp;
                <CustomButton
                  variant="contained"
                  color="default"
                  onClick={props.backToGrid}
                  style={{ paddingLeft: "10px", marginLeft: "15px" }}
                  className="float-right"
                >
                  Cancel
                </CustomButton>
                <CustomButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ paddingLeft: "10px", marginReft: "15px" }}
                  disabled={button}
                  className="float-right"
                >
                  {buttonName ? buttonName : "Save"}
                </CustomButton>
              </form>
            )}
          </div>
        </div>
      </Grid>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  externalIds: state.app_items.externalIds,
  types: state.app_items.types,
  values: state.app_items.values,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ItemSections);
