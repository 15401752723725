import * as actionType from '../constants/tmail';

const initialState = {
    apps: [],
    domains: [],
    signatures: [],
    defaultEmails: [],
    members: [],
    spaceMembers: [],
    loading: true,
    error: {},
}

const TmailReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case actionType.GET_APP:
            return {
                ...state,
                apps: payload,
                loading: false
            }
        case actionType.ADD_APP:
            return {
                ...state,
                apps: state.apps.concat(payload),
                loading: false
            }
        case actionType.DELETE_APP:
            return {
                ...state,
                apps: state.apps.filter(app => app.id !== payload),
                loading: false
            }
        case actionType.GET_DOMAIN:
            return {
                ...state,
                domains: payload,
                loading: false
            }
        case actionType.GET_SIGNATURE:
            return {
                ...state,
                signatures: payload,
                loading: false
            }
        case actionType.GET_EMAIL:
            return {
                ...state,
                defaultEmails: payload,
                loading: false
            }
        case actionType.GET_MEMBERS:
            return {
                ...state,
                members: payload,
                loading: false
            }
        case actionType.GET_SPACE_MEMBERS:
            return {
                ...state,
                spaceMembers: payload,
                loading: false
            }
        case actionType.ADD_DOMAIN:
            return {
                ...state,
                domains: state.domains.concat(payload),
                loading: false
            }
        case actionType.ADD_SIGNATURE:
            return {
                ...state,
                signatures: state.signatures.concat(payload),
                loading: false
            }
        case actionType.ADD_EMAIL:
            return {
                ...state,
                defaultEmails: state.defaultEmails.concat(payload),
                loading: false
            }
        case actionType.UPDATE_EMAIL:
            
            const newEmail = [...state.defaultEmails];
            const emailIindex = newEmail.findIndex(elem => elem.id === payload.id);
            newEmail[emailIindex] = payload
            return {
                ...state,
                defaultEmails: newEmail,
                loading: false
            }
        case actionType.ADD_MEMBER:
            return {
                ...state,
                members: payload,
                loading: false
            }
        case actionType.RESYNC_APP:
            return {
                ...state,
                loading: false
            }
        case actionType.VERIFY_DOMAIN:
            const newDomain = [...state.domains];
            const index = newDomain.findIndex(elem => elem.id === payload.id);
            newDomain[index] = payload.data
            return {
                ...state,
                loading: false,
                domains: newDomain,
            }
        case actionType.DELETE_DOMAIN:
            return {
                ...state,
                domains: state.domains.filter(app => app.id !== payload),
                loading: false
            }
        case actionType.DELETE_SIGNATURE:
            return {
                ...state,
                signatures: state.signatures.filter(app => app.id !== payload),
                loading: false
            }
        case actionType.DELETE_EMAIL:
            return {
                ...state,
                defaultEmails: state.defaultEmails.filter(app => app.id !== payload),
                loading: false
            }
        case actionType.DELETE_MEMBER:
            return {
                ...state,
                members: state.members.filter(app => app.id !== payload),
                loading: false
            }
        case actionType.ERROR:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        default: return state;
    }

}

export default TmailReducer;