/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import dayjs from "dayjs";
import Axios from 'axios';
import { useParams } from "react-router-dom";
import { toast } from "helpers/apiRequests";
import ContentWrapper from './widgets/ContentWrapper';
import { CircularProgress } from '@material-ui/core';
import ActivityTotal from './ActivityTotal';
import './style.css';
import ReassignModal from './modals/ReassignModal';
const BASE_URL = process.env.REACT_APP_BASEURL

const ShowOrphanMessageV2 = () => {
    const { uuid, app_id, unique_id, orgId } = useParams();
    const [emailData, setEmailData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState("");
    const [open, setOpen] = useState(false);

    const openModal = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        setLoading(true);
        console.log("Hello world");
        const url = `${BASE_URL}/tmail/composer/config`;
        const payload = {
            uuid,
            app_id,
            app_item_id: unique_id,
            orgId
        };
        Axios.post(url, payload).then(res => {
            //console.log({ response: res.data });
            const response = res.data;
            const authToken = response.data.user.token;
            localStorage.removeItem("chosen_token");
            localStorage.removeItem("team_member_uuid");
            localStorage.setItem("chosen_token", authToken);
            localStorage.setItem("chosen_token_v2", authToken);
            if (response.orphanEmail != null) {
                setToken(authToken);
                setEmailData(response.orphanEmail);
            }

            setLoading(false);
        }).catch(error => {
            setLoading(false);
            toast.notify(
                error.response.message, { type: "error" });
        })


    }, []);

    const formatKey = (value) => {
        return value.charAt(0).toLocaleUpperCase() + value.slice(1);
    }
    const formatDate = (value, type) => {
        if (type === "date") return dayjs(value).format("dddd D, MMMM YYYY, h:mm A")
        if (type === "source") return dayjs(value).format("MM/DD/YYYY, h:mm A")
    }
    const formatEmails = (email) => {
        const decoded = JSON.parse(email);
        if (decoded == null || decoded.length === 0) {
            return "";
        }
        return decoded.join(",")
    }

    const formatMessage = message => {
        if (message == null) {
            return '';
        }
        return <div dangerouslySetInnerHTML={{ __html: message }} />;
    }
    const replyOrphanMessage = (data) => {
        const { id, item_id, } = data;
        var base_url = window.location.origin;
        const url = `${base_url}/compose-message/${uuid}/${app_id}/${unique_id}`;

        window.open(url, "_blank");

    }
    if (loading) {
        return <center>
            <CircularProgress />
        </center>
    }

    const errorStyle = {
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        marginTop: "6rem",
    }

    return (
        <div className="auth">
            {
                emailData !== null ?
                    <>
                        <ReassignModal
                            openModal={open}
                            handleClose={handleClose}
                            authToken={token}
                            app_id={app_id}
                            unique_id={unique_id}
                        />
                        <div className="row justify-content-center">
                            <div className="col-md-10">
                                <div className="">
                                    <div className="custom__container__80 mt-4">
                                        <h4 className='mb-3'>Item name: {emailData?.subject}</h4>
                                        <>
                                            <ContentWrapper title="Date" content={formatDate(emailData?.Date, "date")} />
                                            <ContentWrapper title="From" content={emailData?.From} />
                                            <ContentWrapper title="To" content={emailData?.recipient} />
                                            <ContentWrapper title="Cc" content={emailData?.From} />
                                            <ContentWrapper title="Subject" content={emailData?.Subject} />
                                            <ContentWrapper title="Message" content={formatMessage(emailData['body-plain'])} />
                                            <ContentWrapper title="Source" content={formatDate(emailData?.Date, "source")} />
                                            <ContentWrapper title="Actions" />

                                            <div className='d-flex mb-2 buttons_area'>
                                                <button
                                                    style={{
                                                        color: "#fff",
                                                        background: "#F7C948",

                                                    }}
                                                    className='btn btn-default'
                                                    onClick={() => replyOrphanMessage(emailData)}
                                                >Reply</button>
                                                <button
                                                    style={{
                                                        color: "#000",
                                                        background: "#f5f5f5",
                                                        margin: "1rem .5rem"
                                                    }}
                                                    className='btn btn-default'
                                                    onClick={openModal}
                                                >Reassign</button>
                                                <button
                                                    style={{
                                                        color: "#000",
                                                        background: "#f5f5f5",
                                                        margin: "1rem .5rem"
                                                    }}
                                                    className='btn btn-default'
                                                >Block</button>
                                            </div>
                                        </>

                                    </div>

                                    {/* <div className='col-md-6'>
                                        <ActivityTotal />
                                    </div> */}

                                </div>
                            </div>
                        </div>
                    </> :
                    <div style={errorStyle}>
                        <div>
                            <h3>No Email Data</h3>
                            <p>There are no orphan emails for this item at the time</p>
                        </div>
                    </div>
            }
        </div >
    )
}

export default ShowOrphanMessageV2