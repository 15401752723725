import React, { useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
// import IconButton from "@material-ui/core/IconButton";
// import VisibilityIcon from "@material-ui/icons/Visibility";
import ViewMemberModal from "../Modals/ViewMemberModal";
// import { Apps } from "@material-ui/icons";

const ImitateAction = (props) => {
    const [openModal, setOpenModal] = useState(false);
    const data = typeof props["data"] !== "undefined" ? props["data"] : null;
    const [applications, setApplications] = useState([]);
    const [userDetails, setUserDetails] = useState({});

    const handleClose = () => {
        setOpenModal(false);
    }

    const openViewModal = (detail) => {
        setUserDetails(detail);
        setApplications(detail.applications);
        setOpenModal(true);
    }


    return (
        <>
            <ViewMemberModal
                openModal={openModal}
                handleClose={handleClose}
                applications={applications}
                appDetail={userDetails}
            />
            {data !== null ? (
                <>
                    <Tooltip title="View" style={{ marginTop: "10px" }}>
                        <span style={{cursor: "pointer"}} onClick={() => openViewModal(data)}>View</span>
                        {/* <IconButton
                            aria-label="View"
                            onClick={() => openViewModal(data)}
                        >
                            <Apps style={{ color: "#FFF" }} />
                        </IconButton> */}
                    </Tooltip>


                </>
            ) : (
                ""
            )}
        </>
    );
};

export default ImitateAction;
