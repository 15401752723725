export const testRequest = {
  GET_COLUMNS: "/sync/app/23103122?org_id=1473960&db=ThatApp_4814&api_v2=true",
  GET_DATA:
    "/sync/app/items/23103122?org_id=1473960&database=ThatApp_4814&api_v2=true&skip=0&limit=100",
};

export const userRequest = {
  PLANS: "/sync/billing/plans",
  INVOICES: "/account/invoices",
  BUY_PLAN: "/sync/billing/register/plan",
  // INVITE: "/sync/podio/invite/user",
  INVITE: "/account/team-members/invite",
  // TEAM_MEMBERS: "/sync/podio/team/members",
  TEAM_MEMBERS: "/account/team-members/index",
  PENDING_INVITATION: "/account/team-members/index",
  // PENDING_INVITATION: "/sync/podio/invites",
  // DELETE_INVITE: "/sync/podio/delete/invites",
  DELETE_INVITE: "/account/team-members/delete",
  ME: "/auth/me",
  DELETE_PODIO: "/sync/podio/disconnect",
};
export const OrganisationRequest = {
  DELETE_SELECTED: "/sync/podio/permanent_delete/items",
  RESTORE_SELECTED: "/sync/podio/restore/items",
};
export const rawDataRequest = {
  COLLECTION: "/account/collection/list?collection_type=mongo",
};
export const printRequest = {
  ORGANIZATIONS: "/print/podio/organizations/index",
  SPACES: "/print/podio/workspaces/:id/index",
  APPS: "/print/podio/apps/:id/index",
  CREATE: "/print/template/store",
  TEMPLATES: "/print/template/index",
  DELETE: "/print/template/delete"
};

export const cloneRequest = {
  ORGANIZATIONS: "/clone/general/organizations/index",
  SPACES: "/clone/general/workspaces/:org_id/index",
  APPS: "/clone/general/apps/:org_id/:space_id/index",
  WORKFLOWS: "/clone/general/workspaces/:org_id/workflows/index",
};


export const downloadRequest = {
  DOWNLOAD: "/sync/podio/download/files/to-email"
};
