import React from 'react';
import { Box, Dialog, DialogContent } from '@material-ui/core';
import dayjs from 'dayjs';
import mastercard from '../../assets/icons/mastercard.svg';
import visa from '../../assets/icons/visa.svg';
import defaultCard from '../../assets/icons/default_card.svg';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import styles from './SelectPaymentMethod.module.scss';

const SelectPaymentMethod = ({ open, handleClose, cardList, handleSetSelectedCard, selectedCard }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableEscapeKeyDown={true}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      className={styles.override}
      maxWidth='lg'>
      <Box className={styles.wrapper}>
        <DialogContent className={styles.wrapper}>
          {cardList?.data?.length > 0
            ? cardList.data.map((card) => (
                <div className={styles.container} key={card.hidden_id}>
                  <img
                    src={card.card_brand.toLowerCase() === 'visa' ? visa : card.card_brand.toLowerCase() === 'mastercard' ? mastercard : defaultCard}
                    alt={card.card_brand.toLowerCase()}
                    className={styles.card_brand}
                  />
                  <div>
                    <p className={styles.mask}>
                      {card.card_brand}: <span>....</span>
                      {card.last_four_digit}
                    </p>
                    <p className={styles.created}>Added {dayjs(card.created_at).format('ddd, MMM DD YYYY')}</p>
                  </div>
                  <div className={styles.control}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color='primary'
                          checked={selectedCard === card.hidden_id ? true : false}
                          onChange={(e) => handleSetSelectedCard(card.hidden_id, e.target.checked)}
                          id='select_plan'
                          name='select_plan'
                        />
                      }
                      className='ml-4 mt-3 text-right'
                    />
                  </div>
                </div>
              ))
            : ''}
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default SelectPaymentMethod;
