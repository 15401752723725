import React from 'react';
import { ThemedReactSelect } from 'components/templates/print-v2/components/ThemedReactSelect';
import { usePrintData } from 'components/templates/print-v2/context/PrintProvider';

const SelectSignees = ({ signees = [] }) => {
  const { selectedSigner, setSelectedSigner } = usePrintData();
  const options = signees.map((i) => ({
    label: i?.name?.valueObject?.label,
    value: i.order,
  }));

  function handleChange(value) {
    setSelectedSigner(value);
  }

  return (
    <div className='mt-2'>
      <ThemedReactSelect
        name='signers'
        options={options}
        onChange={handleChange}
        style={{ fontSize: '14px' }}
        placeholder='Select Signer'
        value={selectedSigner}
      />
    </div>
  );
};

export default SelectSignees;
