import React from 'react';
import { useSelector } from 'react-redux';
import { Icon } from '@material-ui/core';
import { Warning } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles';
import Logo from "../../../assets/images/podioLogoii.png";
import Mail from "../../../assets/images/mailsend.png";
// import Chrome from "../../../assets/images/chr3.png";

const useStyles = makeStyles({
    blue: {
        color: '#3f50b5'
    },
    green: {
        color: "#4caf50",
        fontSize: 15,
    },
    grey: {
        color: "#666"
    },
    bgDanger: {
        background: "#f00",
        color: "#fff",
        fontSize: 15,
    },
    bgGrey: {
        background: "#f9f1c9",
        color: "#8a6d3b",
        fontSize: 15,
    },
    wn: {
        fontSize: 17
    },
    bgCard: {
        background: "#FA5600"
    }

});

const Dashboard = () => {
    const muClasses = useStyles();
    const user = useSelector((state) => state.user.authUser);
    const [img] = React.useState(
        user?.podio_profile?.profile?.image?.thumbnail_link
    );
    const imgStyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: "50%",
        width: "100px",
        height: "90px"
    };

    const gotoLink = () => {
        const url = "https://chrome.google.com/webstore/detail/tmail-for-podio/nbonnidamdbokahofcindhjengelpnmc";
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    let name = ""

    if (user?.team_member) {
      name = user?.team_member?.name 
    } else {
      name = user?.name
    }

    return (
        <div className='row'>
            <div className="col-md-6">
                <div className="card">
                    <div className="card-header bg-dark">
                        <img src={img} style={imgStyle} alt="" />
                    </div>
                    <div className="card-body">
                        <h6 className='text-center'>Welcome {name}</h6>
                        <div className="row justify-content-centerbm-3">
                            <div className="col-md-6">
                                Email
                            </div>
                            <div className="col-md-6">
                                {user.email}
                            </div>
                        </div>
                        <div className="row justify-content-center mt-3">
                            <div className="col-md-6">
                                Plan
                            </div>
                            <div className="col-md-6">
                                Free Trial
                            </div>
                        </div>
                        <div className="row justify-content-center mt-3">
                            <div className="col-md-6">
                                Reputation
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <Icon className={muClasses.green} >star</Icon>
                                    <Icon className={muClasses.green} >star</Icon>
                                    <Icon className={muClasses.green} >star</Icon>
                                    <Icon className={muClasses.green} >star</Icon>
                                    <Icon className={muClasses.green} >star</Icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="row">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="row">
                                <div className={`col-md-4 text-center py-3 ${muClasses.bgCard}`}>
                                    <Warning className='text-white' />
                                </div>
                                <div className="col-md-8">
                                    <h6 className='mt-2'>Alerts</h6>
                                    <p className='mt-2'>Free trial ends in 30 days</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card">
                            <div className="row">
                                <div className={`col-md-4 text-center py-3 ${muClasses.bgCard}`}>
                                    {/* <Warning className='text-white' /> */}
                                    {/* <img src={Chrome} height="20" alt="" /> */}
                                    <i class="fab fa-chrome h2 text-white"></i>
                                </div>
                                <div className="col-md-8">
                                    <h6 className='mt-2'>Browser Extension</h6>
                                    <p className='mt-2'>
                                        <p style={{ color: "blue", marginTop: 10, cursor: "pointer", textDecoration: "underline" }} onClick={gotoLink}>
                                           Install Chrome
                                        </p>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="row">
                                <div className={`col-md-4 text-center py-3 ${muClasses.bgCard}`}>
                                    <p className="h2"><img src={Logo} alt="" /></p>
                                </div>
                                <div className="col-md-8">
                                    <h6 className='mt-2'>Podio Apps</h6>
                                    <p className='mt-2 p-2'>Connect your podio app and start communicating with your contact</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card">
                            <div className="row">
                                <div className={`col-md-4 text-center py-3 ${muClasses.bgCard}`}>
                                    {/* <Markunread className='text-white' /> */}
                                    <img src={Mail} height="20" alt="" />
                                </div>
                                <div className="col-md-8">
                                    <h6 className='mt-2'>Alerts</h6>
                                    <p className='mt-2'>Free trial ends in 30 days</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4 mx-3">
                    <div className="col-md-12">
                        <h6 className='mr-3'>Latest Updates</h6>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="card" style={{ width: "18rem;" }}>
                            <img className="card-img-top" height={200} src={img} alt="Cardcap" />
                            <div className="card-body">
                                <p className="card-text">
                                    <p>Introducing a new way to send Email - with Invisible Subject Tags</p>
                                    <p>If you don't like the unique reply-to address created with Email Address Mapping or the Subject Tag in the subject of each...</p>
                                    <p className='text-primary'>Learn more {">>>"}</p>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card" style={{ width: "18rem;" }}>
                            <img className="card-img-top" height={200} src={img} alt="Cardcap" />
                            <div className="card-body">
                                <p className="card-text">
                                    <p>Introducing a new way to send Email - with Invisible Subject Tags</p>
                                    <p>If you don't like the unique reply-to address created with Email Address Mapping or the Subject Tag in the subject of each...</p>
                                    <p className='text-primary'>Learn more {">>>"}</p>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Dashboard