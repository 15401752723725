import React from "react";
import "./ConfirmModal.css";
import { Alert } from "@material-ui/lab";
import { CircularProgress } from "@material-ui/core";
import cancelIcon from "../../assets/icons/cancel.svg";

const GDPRModal = ({ gdprModal, action, the_data, loading }) => {
  const [dp, setDp] = React.useState(the_data.org_id.frequency);


  const handleChange = (event) => {
    setDp(event.target.value);
  };

  return (
    <div className="confirm__modal">
      <div className="modal__content">
        <div>
          <div className="modal__card">
            <div>
              <div className="d-flex justify-content-between align-items-center pt-3 pb-3">
                <h6>Data Protection Policy</h6>
                <img
                  src={cancelIcon}
                  alt="Cancel"
                  onClick={() => gdprModal(null, false)}
                />
              </div>
              {loading ? (
                <div  style={{ width: "600px" }} className="info_Gdpr_modal">
                  <CircularProgress size={20} />
                </div>
              ) : (
                <Alert
                  className="info_Gdpr_modal"
                  style={{ textAlign: "left", width: "600px" }}
                  severity="info"
                >
                  Inline with General Data Protection Regulation, you can select
                  the frequency in which you want your deleted Data, store on
                  our server to be permanently deleted.
                </Alert>
              )}
              <div className="form-group">
                <label className="mb-2" htmlFor="exampleFormControlSelect1">
                  Choose a frequency
                </label>
                <select className="form-control" onChange={handleChange}>
                  {/* <option selected disabled>
                    {dp}
                  </option> */}
                  {/* <option value={dp}>
                    {dp === 1 && "Don't Delete"}
                    {dp === 2 && "Monthly"}
                    {dp === 3 && "Quarterly"}
                    {dp === 4 && "6 Months"}
                  </option> */}
                  <option>Select option</option>
                  <option value={1}>Never Delete</option>
                  <option value={2}>Monthly</option>
                  <option value={3}>Quarterly</option>
                  <option value={4}>6 Months</option>
                </select>
              </div>
              <div className="text-right">
                <button
                  onClick={() => {
                    if (dp !== null) action(the_data.org_id.org_id, dp);
                  }}
                  style={{ marginTop: "-20px" }}
                >
                  Save setting
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GDPRModal;
