import React from 'react';

const PrintLoadingContext = React.createContext(null);
export const PrintLoadingProvider = (props) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const value = {
    isLoading,
    setIsLoading,
  };

  return <PrintLoadingContext.Provider value={value}>{props.children}</PrintLoadingContext.Provider>;
};

export default PrintLoadingProvider;

export function usePrintLoadingContext() {
  const context = React.useContext(PrintLoadingContext);

  if (!context) {
    throw new Error('usePrintLoadingContext must be used within a PrintLoadingContext Provider');
  }

  return context;
}
