import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '5px',
    width: '100%',
  },
  container: {
    padding: '0 6px',
    alignItems: 'center',
  },
  title: {
    margin: 0,
  },
}));

const CustomAlert = ({ title = '', message = '', severity = 'warning' }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Alert severity={severity} className={classes.container}>
        <AlertTitle className={classes.title}>{title}</AlertTitle>
        {message}
      </Alert>
    </div>
  );
};

export default CustomAlert;
