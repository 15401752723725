import { CircularProgress, makeStyles, Modal } from "@material-ui/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getModalStyle } from "./SelectAppForMapperCreation";
import NewConnectorDetails from "./NewConnectorDetails";
import Axios from "../../config";
import { toast } from "helpers/apiRequests";

const useStyles = makeStyles((theme) => ({
  mapper_creation: {
    position: "absolute",
    width: 400,
    margin: "auto",
    minWidth: "40%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #fff",
    // borderRadius: "5px",
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function SelectDbModal({ setDbModal, open, user, addConnectors }) {
  const [modalStyle] = useState(getModalStyle);
  const classes = useStyles();
  const [drivers, setDrivers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [choseDb, setChoseDb] = useState("");
  const [org, setOrg] = useState("");
  const sql = useSelector((state) => state.connector.sql_sync);
  const [responseData, setResponseData] = useState({
    show: false,
    data: []
  });
  const [openModal, setOpenModal] = useState(false);

  const orgs = useSelector((state) => state.sync.orgs);
  const orgOptions = orgs.length > 0 ? orgs.map(org => {
    return <option key={org.org_id} value={org.org_id}>{org.name}</option>
  }) : [];

  const changeOrganisation = (e) => {
    const { value } = e.target;
    setOrg(value);
  }

  const handlResponseChange = (val) => {
    setResponseData({
      show: true,
      data: [...val]
    })
    setDbModal(false);
    setOpenModal(true)
  }
  const handleModalClose = () => {
    setOpenModal(false)
  }
  
  const handleClose = () => {
    setDbModal(false);
  };

  const handleOrganizationChange = async (event) => {
    setChoseDb(event.target.value);
    setDrivers((prevState) => [...prevState, choseDb]); // add to array this line isnt functional now, but everything works as it should.. will fix after the demo
  };

  const createNewUserMySqlDB = async () => {
    try {


      setLoading(true);
      let payload;
      // driver: drivers.length == 1 ? drivers[0] ? drivers
      if (!drivers.length) {
        payload = {};
      } else if (drivers.length === 1) {
        payload = {
          drivers: [choseDb],
        };
      } else {
        payload = {
          drivers: [choseDb],
        };
      }
      const requestData = {
        org_id: org,
        ...payload
      }
     
      const url = `/create-user-db`;
      const res = await Axios.post(url, requestData);
      addConnectors(res.data);
      handlResponseChange(res.data)
      setLoading(false);
      toast.notify(res.message)

    } catch (error) {
      setLoading(false);
      toast.notify(error.response.message, { type: "error" });
    }
  };

  return (
    <>
      <NewConnectorDetails
        openModal={openModal}
        handleClose={handleModalClose}
        responseData={responseData}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >

        <div style={modalStyle} className={classes.mapper_creation}>
          <form className="pt-2">
            <h3 className="mb-3">Create Connectionkkkk</h3>
            <div className="form-group">
              <label htmlFor="app">Select Organization</label>
              <select
                className='form-control'
                value={org}
                onChange={changeOrganisation}
              >
                <option>Select An Organization</option>
                {orgOptions}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="organisation">Drivers</label>
              {sql?.mysql_status && sql?.pgsql_status ?
                <h4>You've created all available Connectors</h4> :
                <select
                  onChange={handleOrganizationChange}
                  value={choseDb}
                  className="form-control"
                  id="organisation"
                >
                  <option value="">Select Drivers</option>
                  <option value="mysql">MySql</option>
                  <option value="pgsql">PostgreSql</option>
                </select>
              }
            </div>


            <div className="d-flex justify-content-between mt-5">
              <button onClick={handleClose} className="sync__btn bg-danger">
                Cancel
              </button>
              {loading ?
                <CircularProgress size={24} /> :
                <button
                  onClick={() => {
                    createNewUserMySqlDB();
                  }}
                  className="sync__btn"
                  disabled={loading}
                >
                  Create Connection
                </button>
              }
            </div>

          </form>
        </div>
      </Modal>
    </>
  );
}
