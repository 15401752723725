import React from 'react';
import OrphanGrid from './OrphanGrid';

const OprhanList = ({ orgId }) => {
  return (
    <div className='orphan_tab_container'>
      {orgId ? <OrphanGrid orgId={orgId} /> : null}
    </div>
  );
};

export default OprhanList