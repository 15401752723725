/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./style.css";
import { useSelector, useDispatch } from "react-redux";
import appIcon from "../../../assets/images/app-icon.png";
import addIcon from "../../../assets/images/add.png";
import IconList from "./IconList";
import { ADD_APP_V2 } from "../../../containers/pagePath";
import { GetAllApps } from "../../../redux/actions/tmail";
import { CircularProgress } from "@material-ui/core";

const AppsList = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetAllApps());
  }, []);
  const apps = useSelector((state) => state.tmail.apps);
  const isLoading = useSelector((state) => state.tmail.loading);

  if (isLoading) {
    return (
      <center>
        <CircularProgress size={40} />
      </center>
    );
  }

  function openLink(link) {
    if (link == null) {
      return;
    }
    window.open(link, "_blanck");
  }

  return (
    <div className={" container tmail-apps-list mt-5"}>
      <h2 className="mb-0">Connected Podio Apps</h2>

      <div className="row pt-5">
        {apps.length > 0
          ? apps.map((app) => (
              <div className="col-md-3 mt-3" key={app.app_id}>
                <div
                  data-name={app.name}
                  className="applist-app-container relative text-center pt-4 rounded mx-2"
                >
                  <img src={appIcon} alt="" className="applist_topIcon" />
                  <h5 onClick={() => openLink(app.app_link)} className="mt-3">
                    {app.app_name}
                  </h5>

                  <div className="project_details">
                    <p className="text-muted">{app.space_name}</p>
                    <span className="d-block  text-muted">
                      {app.items} {app.items < 1 ? "item" : "items"}
                    </span>
                  </div>
                  <IconList id={app.id} orgId={app.org_id} appId={app.app_id} />
                </div>
              </div>
            ))
          : null}

        <div className="col-md-3 mt-3 text-center">
          <Link to={ADD_APP_V2} style={{ textDecoration: "none" }}>
            <div className="app-list-add-new rounded text-center pt-4">
              <center className="mt-4" style={{ padding: "-10px" }}>
                <img src={addIcon} alt="" />
                <h5
                  className="text-muted mt-3"
                  style={{ marginBottom: "-20px" }}
                >
                  Add New
                </h5>
              </center>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AppsList;
