import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import AlertDiv from '../components/AlertDiv';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import LeftbarCard from '../components/LeftbarCard';
import dataUsage from '../assets/data-usage.svg'
import userPlus from '../assets/user-plus.svg'


const useStyles = makeStyles((theme) => ({
  text_field: {
    background: 'transparent',
    width: '459px',
    height: '58px',
    fontSize: '14px',
    marginBottom: '23px'
  },
  btn: {
    backgroundColor: '#F0B429',
    color: '#fff',
    textTransform: 'capitalize',
    padding: '11px 27px',
    fontStyle: 'normal',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: 'normal',
    borderRadius: '5px'
  },
  btnCancel: {
    color: '#000',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
    textTransform: 'capitalize',
    padding: '11px 27px',
    background: '#fff',
    borderRadius: '5px',
    border: '1px solid #000'
  },
  btnGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    marginTop: '24px'
  }
}));


const AccountDetailsView = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <h4 className='mb-4'>Account Details</h4>
      <h5 className='mb-4'>Subscription Details</h5>
      
      <AlertDiv message="You have 30 days remaining on your Free Trial. UPGRADE NOW" marginBottom="mb-4" />

      <div className="row pb-5">
        <div className="col-lg-4">
          <div className="row mb-3">
            <div className="col-6">
              <p className='mb-2'>Account Holder: </p>
            </div>
            <div className="col-6">
              <p className='mb-2'>joseph@prodevs.io</p>
            </div>
            <div className="col-6">
              <p className='mb-2'>Account Created on:</p>
            </div>
            <div className="col-6">
              <p className='mb-2'>June 9, 2022</p>
            </div>
            <div className="col-6">
              <p className='mb-2'>Current Plan:</p>
            </div>
            <div className="col-6">
              <p className='mb-2 text-primary'>Free Trial</p>
            </div>
          </div>

          <Button aria-controls="simple-menu" className={classes.btn} aria-haspopup="true" onClick={handleClick}>
            Action 
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>Billing History</MenuItem>
            <MenuItem onClick={handleClose}>Change Subscription Plan</MenuItem>
            <MenuItem onClick={handleClose}>Delete Account</MenuItem>
          </Menu>
        </div>
        <div className="col-lg-4">
          <LeftbarCard
            icon={dataUsage}
            title="Account Usage"
            body={
              <>
                <p className='mb-1'>Apps: <span className='text-primary'>0 / 2</span></p>
                <p className='mb-1'>Emails this month: <span className='text-primary'>0 / 1,000</span></p>
                <p className='mb-0'>Emails last month: <span className='text-primary'>0 / 1,000</span></p>
              </>
            }
          />
        </div>
        <div className="col-lg-4">
          <LeftbarCard
            icon={userPlus}
            title="Additional Users"
            body={
              <>
                <p className='mb-1'>Specify additional valid Podio Users than can access this Tmail account.</p>
                <small className='text-warning'>Add New User</small>
              </>
            }
          />
        </div>
      </div>

      <div className="text-center mt-5">
        <h5 className='mb-2'>Daily Summary</h5>
        <p>Send Daily Summary Report (spam quarantine items etc) to the below address:</p>
        <div className={classes.btnGroup}>
          <Button 
            variant='contained'
            disableElevation={true}
            className={classes.btnCancel}
          >
            joseph@prodevs.io
          </Button>
          <Button 
            variant='contained'
            color='primary'
            disableElevation={true}
            className={classes.btn}
          >
            Save
          </Button>
        </div>
      </div>

    </div>
  )
}

export default AccountDetailsView