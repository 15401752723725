import { toast } from 'helpers/apiRequests';
import { useState } from 'react'
import axiosInstance, { downloadRequest } from 'services';

const DownloadHook = () => {
    const [loading, setLoading] = useState(false);

    const handleDownload = (data) => {
        setLoading(true)

        axiosInstance.get(downloadRequest.DOWNLOAD + `${data ? "?org_id="+ data.org_id : ''}`)
        .then((response) => {
            toast.notify(response.data.message);
        })
        .catch((error) => {
            toast.notify(error.response.data.message, { type: "error"} );
        })
        .finally(() => {
            setLoading(false)
        })
    }



    return {
        loading,
        handleDownload
    }
}

export default DownloadHook