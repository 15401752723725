import React, { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
// import * as AllModules from "ag-grid-enterprise";
// import { ModuleRegistry } from "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import ActionsRenderer from "../actions/ActionsRenderer";
import "./Grid.css";
// ModuleRegistry.registerModules(Object.keys(AllModules));

const HavenGrid = ({ haven, load }) => {
  const [state, setHaven] = useState(haven);
  const [loading, setLoading] = useState(true);
  const [grid_loading, setAgrid] = React.useState("");

  setTimeout(function () {
    setAgrid("No Data");
  }, 6000);

  const filterParams = {
    filterOptions: ["contains", "notContains"],
    textFormatter: function (r) {
      if (r == null) return null;
      r = r.replace(new RegExp("[àáâãäå]", "g"), "a");
      r = r.replace(new RegExp("æ", "g"), "ae");
      r = r.replace(new RegExp("ç", "g"), "c");
      r = r.replace(new RegExp("[èéêë]", "g"), "e");
      r = r.replace(new RegExp("[ìíîï]", "g"), "i");
      r = r.replace(new RegExp("ñ", "g"), "n");
      r = r.replace(new RegExp("[òóôõøö]", "g"), "o");
      r = r.replace(new RegExp("œ", "g"), "oe");
      r = r.replace(new RegExp("[ùúûü]", "g"), "u");
      r = r.replace(new RegExp("[ýÿ]", "g"), "y");
      return r;
    },
    debounceMs: 0,
    caseSensitive: true,
    suppressAndOrCondition: true,
  };

  useEffect(() => {
    setLoading(true);
    setHaven(haven);
    setTimeout(() => setLoading(false), 1000);
  }, [haven]);

  let columns;

  columns = [
    { headerName: "Id", field: "id" },
    {
      headerName: "Organization",
      field: "organization",
      filter: "agTextColumnFilter",
      filterParams,
    },
    {
      headerName: "Haven Status",
      field: "haven",
      filter: "agTextColumnFilter",
      filterParams,
    },
    { headerName: "Action", field: "actions", cellRenderer: "actionsRenderer" },
  ];

  const defaultColDef = {
    flex: 1,
    minWidth: 100,
    filter: true,
    sortable: true,
    resizable: true,
  };
  const autoGroupColumnDef = { minWidth: 200 };

  const frameworks = {
    actionsRenderer: ActionsRenderer,
  };

  if (loading || load) {
    return (
      <div className="material_block">
        <svg
          className="spinner"
          width="65px"
          height="65px"
          viewBox="0 0 66 66"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            className="circle"
            fill="none"
            strokeWidth="6"
            strokeLinecap="round"
            cx="33"
            cy="33"
            r="30"
          ></circle>
        </svg>
      </div>
    );
  }

  return (
    <>
      <div
        className="ag-theme-balham-dark"
        style={{
          height: "500px",
          width: "100%",
          margin: "0 auto",
        }}
      >
        {
          <AgGridReact
            // modules={Object.keys(AllModules)}
            columnDefs={columns}
            defaultColDef={defaultColDef}
            autoGroupColumnDef={autoGroupColumnDef}
            frameworkComponents={frameworks}
            enableRangeSelection={true}
            animateRows={true}
            rowData={state}
            rowGroupPanelShow="always"
            rowSelection="multiple"
            overlayNoRowsTemplate={grid_loading}
            debug={true}
            sideBar={true}
            enableCellTextSelection={true}
            suppressAggFuncInHeader={true}
            enableFilter={true}
            enableSorting={true}
          />
        }
      </div>
    </>
  );
};

export default HavenGrid;
