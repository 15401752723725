import React from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FaPlusCircle } from "react-icons/fa";
import DomainModal from "../components/DomainModal";
import { useDispatch, useSelector } from "react-redux";
import { GetAllDomains } from "redux/actions/tmail";
import { useEffect } from "react";
import DomainList from "../components/DomainList";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#F0B429",
    fontSize: "24px",
  },
  btn: {
    height: "44px",
    width: "185px",
    backgroundColor: "#F0B429",
    borderRadius: "3px",
    textTransform: "capitalize",
  },
  card: {
    borderRadius: "12px",
    background: "#fff",
    boxShadow: "0px 2px 16.9px 0px rgba(0, 0, 0, 0.11)",
  },
  cardBody: {
    padding: "41px 38px",
  },
  paddingX: {
    padding: "35px 38px",
  },
  th: {
    border: "0px",
    borderBottom: "1px solid #9E9E9E",
    paddingTop: "21px",
    paddingBottom: "21px",
    color: "#222",
    fontWeight: "600",
    fontSize: "14px",
    width: "15%"
  },
  td: {
    border: "0px",
    borderBottom: "1px solid #E1E1E1",
    paddingTop: "21px",
    paddingBottom: "21px",
    color: "#626262",
    fontWeight: "400",
    fontSize: "16px",
  },
  tableHeaderRow: {
    alignItems: "center",
    backgroundColor: "#F7F7F7",
  },
  tableBodyRow: {
    alignItems: "center",
    backgroundColor: "#F7F7F7",
    border: "none",
  },
  checkInput: {
    width: "18px",
    height: "18px",
    borderRadius: "4px",
    appearance: "auto",
  },
  successBadge: {
    display: "flex",
    width: "fit-content",
    padding: "8px 16px",
    alignItems: "center",
    gap: "16px",
    borderRadius: "94px",
    background: "#C6F7E2",
    color: "#27AB83",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "600",
    lineGeight: "normal",
  },
  errorBadge: {
    display: "flex",
    width: "fit-content",
    padding: "8px 16px",
    alignItems: "center",
    gap: "16px",
    borderRadius: "94px",
    background: "#FFBDBD",
    color: "#E12D39",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "600",
    lineGeight: "normal",
  },
  actionGroup: {
    display: "flex",
    gap: "14px",
    alignItems: "center",
  },
  actionBtn: {
    background: "none",
    border: "none",
    outline: "none",
    height: "24px",
    width: "24px",
    color: "#626262",
    padding: "0px",
  },
  backButton: {
    backgroundColor: "#f7c948",
    boxShadow: "0px 3px 6px #00000029",
    padding: "1vh 1vw",
    outline: "none",
    textDecoration: "none",
    border: "none",
    borderRadius: "6px",
    color: "white",
    marginBottom: "1%"
  },
}));

const DomainListView = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const domains = useSelector((state) => state.tmail.domains);
  const isLoading = useSelector((state) => state.tmail.loading);

  useEffect(() => {
    dispatch(GetAllDomains());
  }, [dispatch]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleBackButtonClick = () => {
    window.history.back();
  };

  return (
    <div>
      <div>
        <button onClick={handleBackButtonClick} className={classes.backButton}>
          Back
        </button>
      </div>
      <div className="d-flex align-items-start justify-content-between">
        <div>
          <h5 className="mb-2">Sending Domain</h5>
          <p className="mb-0">Send emails from your domain instead of ours.</p>
        </div>

        <Button
          variant="contained"
          color="primary"
          disableElevation={true}
          className={classes.btn}
          onClick={handleOpen}
        >
          <FaPlusCircle />
          &nbsp; Add Domain
        </Button>
      </div>

      <div className="table-responsive">
        <table className={classes.table}>
          <thead>
            <tr className={classes.tableHeaderRow}>
              <th style={{ width: "5%" }} className={classes.th}>
                <input type="checkbox" className={classes.checkInput} />
              </th>
              <th className={classes.th}>Id</th>
              <th className={classes.th}>OrgName</th>
              <th className={classes.th}>Domain</th>
              <th className={classes.th}>Status</th>
              <th className={classes.th}>Actions</th>
            </tr>
          </thead>
          <tbody>
            <DomainList {...{ domains, isLoading }} />
          </tbody>
        </table>
      </div>

      <DomainModal {...{ open, handleClose }} />
    </div>
  );
};

export default DomainListView;
