import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import OprhanList from './OprhanList'

import OrphanEmailMap from './OrphanEmailMap';
import axios from "../../../config";
import { toast } from "helpers/apiRequests";
import { CircularProgress } from '@material-ui/core';

const OrphanSetting = () => {
    const orgs = useSelector((state) => state.sync.orgs);
    const [loading, setLoading] = useState(true);
    const [orgId, setOrgId] = useState(null);
    const [mappers, setMappers] = useState([]);
    const [mapperItem, setMapperItem] = useState(null);
    const [apps, setApps] = useState([]);

    const orgOptions = orgs.length > 0 ? orgs.map(org => {
        return <option key={org.org_id} value={org.org_id}>{org.name}</option>
      }) : null;

    const getAllApps = async () => {
        try {
            setLoading(true)
            const response = await axios.get("/tmail/list/podio-apps");
            setApps(response.apps);
            setLoading(false)

        } catch (error) {
            setApps([]);
            setLoading(false)
            toast.notify("An Error Occured: " + error, { type: "error" });
        }

    };


    useEffect(() => {
        getAllApps();
    }, []);

    const changeOrgId = val => setOrgId(val);

    const setMappedData = (val) => {
        setMappers(val?.mapper);
        setMapperItem(val);
    };
    if (loading) {
        return <center><CircularProgress /></center>
    }
    return (
        <div className='bg-white p-5'>
        <div className="row  mb-3">
        <div className="col-md-4">
          <div className="form-group">
            <label htmlFor="app">Select Organization</label>
            <select
              className='form-control'
              defaultValue={orgId}
              onChange={(e) => setOrgId(e.target.value)}
              style={{paddingLeft: "10px"}}
            >
              <option>Select An Organization</option>
              {orgOptions}
            </select>
          </div>
        </div>
      </div>
            <div className="orphaneSetting_Tabs_Container">
                <ul className="nav nav-pills center" id="myTab2" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" style={{ color: "rgb(52 49 42)", fontWeight: "bolder" }} id="home-tab2" data-toggle="tab" href="#home2" role="tab"
                            aria-controls="home" aria-selected="true">Orphan Emails</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" style={{ color: "rgb(52 49 42)", fontWeight: "bolder" }} id="comment-tab" data-toggle="tab" href="#comment" role="tab"
                            aria-controls="comment" aria-selected="false">Configure</a>
                    </li>
                </ul>
            </div>
            <div className="tab-content tab-bordered" id="myTab3Content" style={{ background: "#fff", height: "fit-content", paddingLeft: "24px", paddingRight: "24px" }}>
                <div className="tab-pane fade show active" id="home2" role="tabpanel" aria-labelledby="home-tab2">
                    <OprhanList
                        orgId={orgId}
                    />
                </div>
                <div className="tab-pane fade" id="comment" role="tabpanel" aria-labelledby="comment-tab2">
                    <OrphanEmailMap
                        orgId={orgId}
                        setMappedData={setMappedData}
                        changeOrgId={changeOrgId}
                        items={mappers}
                        mappedData={mapperItem}
                        apps={apps}
                    />
                </div>
            </div>
        </div>
    )
}

export default OrphanSetting