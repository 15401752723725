import React from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import cancel from "../../../assets/icons/cancel.svg";

const HowToInstall = ({ openModal, handleClose, url }) => {
  const handleClose_ = () => {
    handleClose();
  };
  return (
    <div>
      <Dialog
        open={openModal}
        onClose={handleClose_}
        // onClick={handleClose_}
        disableEscapeKeyDown={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="connector-modal"
      >
        <DialogContent>
          <div
            style={{ borderBottom: "1px solid #EBEBEB" }}
            className="d-flex justify-content-between align-items-center mt-2 pb-3"
          >
            <h5>How to install chrome extension</h5>
            <img onClick={handleClose_} src={cancel} alt="" />
          </div>
          <div className="mt-4">
            <h5 style={{ color: "#DE911D", fontSize: "18px" }}>Steps</h5>

            <div className="mt-3 ml-4 mb-5">
              <ol>
                <li style={{ fontWeight: "500" }} className="mt-2 mb-2">
                  Go to{" "}
                  <span onClick={url} style={{ color: "#DE911D" }}>
                    Chrome Store
                  </span>
                </li>
                <li style={{ fontWeight: "500" }} className="mt-2 mb-2">
                  Add to your browser and pin it
                </li>
                <li style={{ fontWeight: "500" }} className="mt-2 mb-2">
                  Go to the app configured for emailing on podio and you should
                  see <br />
                  our Draft icon and a link text to compose your message
                </li>
                <li style={{ fontWeight: "500" }} className="mt-2 mb-2">
                  Click on it to open the form where you can enter the details
                  and <br /> send your email.
                </li>
              </ol>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default HowToInstall;
