import React from "react";
import Polygon from "../../../assets/icons/Polygon.svg";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "1%",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    backgroundColor: "#fff",
    borderRadius: "10px",
    boxShadow: "0px 0px 50px 0px rgba(0,0,0,0.1)",
    marginTop: "1%",
    color: "black",
    width: "30%",
  },
  infoText: {
    display: "flex",
    margin: "1% 0",
    fontSize: "12px",
  },
  btn: {
    border: "none",
    backgroundColor: "white",
    color: "#F2B11B",
    fontWeight: "600",
    padding: "0",
  },
  image: {
    position: "absolute",
    marginLeft: "-90%",
    marginTop: "-20%",
    width: "30px",
  },
}));

const NewBillInfo = ({ handleFilePopClose }) => {
  const classes = useStyles();

  return (
    <div onMouseLeave={handleFilePopClose} className={classes.container}>
      <img className={classes.image} src={Polygon} alt="" />
      <div>
        <h5 className={classes.infoText}>
          You are limited to 250 records for this organisation. Need more?
          Upgrade your plan to increase your backup up to 1million records.{" "}
        </h5>
      </div>
      <div style={{ width: "100%" }} className="d-flex justify-content-end">
        <button className={classes.btn}>Upgrade</button>
      </div>
    </div>
  );
};

export default NewBillInfo;
