import React, { useState } from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import cancel from "../../../assets/icons/cancel.svg";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { syningcOrg } from "../../../services/trysync";
import { toast } from "helpers/apiRequests";

const useStyles = makeStyles((theme) => ({
  btn: {
    color: "#fff",
    backgroundColor: "#F0B429",
    width: "200px",
    height: "50px",
    borderRadius: "5px",
    border: "none",
    margin: "0 10px",
    fontSize: "12px",
    padding: "10px 20px",
  },

  btnTwo: {
    color: "#F0B429",
    backgroundColor: "#fff",
    width: "200px",
    height: "50px",
    borderRadius: "5px",
    border: "1px solid #F0B429",
    margin: "0 10px",
    fontSize: "12px",
    padding: "10px 20px",
  },
  cont: {
    width: "530px",
    height: "270px",
  },
  actionBtn: {
    // display: "flex",
    alignItems: "center",
    marginTop: "5%",
  },
  btnCont: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  "@media screen and (max-width: 600px)": {
    actionBtn: {
      flexDirection: "column",
      alignItems: "center",
      marginTop: "7%",
    },

    cont: {
      width: "300px",
      height: "330px",
    },
    btn: {
      width: "100%",
      height: "50px",
      marginTop: "10px",
    },
    btnTwo: {
      width: "100%",
      height: "50px",
      marginTop: "10px",
    },
    btnCont: {
      flexDirection: "column",
    },
  },
}));
const SelectSyncTypeModal = ({ openModal, handleClose, orgName, orgId }) => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const authToken = localStorage.getItem("chosen_token");

  const handleResync = () => {
    history.push({
      pathname: "/dashboard/resync-org",
      state: { orgId: orgId, orgName: orgName },
    });
   
  };

  const handleSync = async () => {
    if (!orgId) return false;
    setLoading(true);
    const payload = {
      org_name: orgName,
      org_id: orgId,
      action: "sync",
    };

    const response = await syningcOrg(authToken, payload);

    if (response.status === true && response.message) {
      //   setLoading(response.isLoading);
      handleClose();
      toast.notify(response.message);
    } else {
      setLoading(false);
      toast.notify(response.status.data.message, { type: "error" });
      // history.push("/dashboard/account?section=plans&redirect=/dashboard/sync");
    }
  };

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={handleClose}
        // onClick={handleClose_}
        disableEscapeKeyDown={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="connector-modal"
      >
        <DialogContent className={classes.cont}>
          <div
            style={{ borderBottom: "1px solid #EBEBEB" }}
            className="d-flex justify-content-between align-items-center mt-2 pb-3"
          >
            <h5 style={{ fontSize: "16px" }}>Select Resync Method</h5>
            <img onClick={handleClose} src={cancel} alt="" />
          </div>

          {loading ? (
            <div className="text-center mt-5">
              <svg
                className="spinner"
                width="55px"
                stroke="#F0B429"
                height="55px"
                viewBox="0 0 66 66"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  className="circle"
                  fill="none"
                  strokeWidth="6"
                  strokeLinecap="round"
                  cx="33"
                  cy="33"
                  r="30"
                ></circle>
              </svg>
            </div>
          ) : (
            <div className={classes.actionBtn}>
              <div className="mb-4">
                <div style={{ textAlign: "center", fontSize: "14px" }}>
                  <p style={{ fontSize: "16px", fontWeight: "500", marginTop: "10%" }}>
                    Would you like to sync your entire organisation or a
                    specific Space/App?
                  </p>
                </div>
              </div>
              <div className={classes.btnCont}>
                <button onClick={handleSync} className={classes.btnTwo}>
                  Resync Entire Organisation
                </button>
                <button
                  //   onClick={() => handleResync(org.org_id, org.name)}
                  onClick={handleResync}
                  className={classes.btn}
                >
                  Resync Space and Apps
                </button>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default SelectSyncTypeModal;
