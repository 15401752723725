import React from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  Typography,
} from "@material-ui/core";

const EmailComments = (props) => {
  const {
    changeCheckOne,
    changeCheckTwo,
    changeCheckThree,
    changeCommentField,
    // goBack,
    handleSubmit,
    saveLoading,
    checkOne,
    checkTwo,
    checkThree,
    commentField,
    classes,
  } = props;
  const removeMargin = {
    marginTop: "-8px",
    marginBottom: "10px",
    marginLeft: "30px",
  };

  return (
    <div className="row">
      <div className="col-md-8">
        <div className={classes.header}>Email Comment Options</div>
        <div className={classes.title}>Podio Comment Header Style:</div>
        <form>
          <div className="form-group">
            <select
              style={{ width: "90%" }}
              className="form-control"
              value={commentField}
              onChange={(e) => changeCommentField(e.target.value)}
            >
              <option value="full">
                Full Email Header(From, To, CC, Subject)
              </option>
              <option value="mini">Mini header(only show From)</option>
            </select>
          </div>
          <hr />
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox onChange={changeCheckOne} checked={checkOne} />
              }
              label="Silent Outgoing Comments"
            />
            <Typography variant="p" className="text-muted" style={removeMargin}>
              Silence comment creation for outgoing emails
            </Typography>
            <FormControlLabel
              control={
                <Checkbox onChange={changeCheckTwo} checked={checkTwo} />
              }
              label="Disable Comment Attachments"
            />
            <Typography variant="p" className="text-muted" style={removeMargin}>
              Do not add email attachments to the Podio comment
            </Typography>
            <FormControlLabel
              control={
                <Checkbox onChange={changeCheckThree} checked={checkThree} />
              }
              label="Add Attachments to Items"
            />
            <Typography variant="p" className="text-muted" style={removeMargin}>
              Add email attachments as files at the Item level in Podio
            </Typography>
          </FormGroup>
          <div className="form-goup mt-3">
            <button
              className="btn btn-default mr-2"
              onClick={handleSubmit}
              style={{
                color: "#fff",
                background: "#F7C948",
                width: "150px",
                height: '50px'
              }}
            >
              Save
            </button>
            {/* <button className="btn btn-secondary ml-2" onClick={goBack}>
              Cancel
            </button> */}
          </div>
          <div className="mt-2">
            {saveLoading ? (
              <center className="mt-3">
                <LinearProgress />
              </center>
            ) : null}
          </div>
        </form>
      </div>
    </div>
  );
};

export default EmailComments;
