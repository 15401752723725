import React from 'react';
import { Box, Dialog, DialogTitle, DialogContent, Typography, DialogActions } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { toast } from 'helpers/apiRequests';

import { transferTemplate } from '../printUtils/requests';

import styles from './TransferTemplate.module.css';

const TransferTemplate = (props) => {
  const { open, handleClose, templateId } = props;
  const [isTransferring, setIsTransferring] = React.useState(false);
  const [email, setEmail] = React.useState('');

  function handleTransfer(e) {
    e.preventDefault();
    if (templateId) {
      setIsTransferring(true);

      const payload = {
        template_id: templateId,
        email: email,
        message: '',
      };

      transferTemplate(payload)
        .then((res) => {
          if (res.success === true) toast.notify('Transferred Successfully');
          props.refreshTemplates();
          handleClose();
        })
        .finally(() => setIsTransferring(false));
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableEscapeKeyDown={true}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      className={styles.override}
      maxWidth='lg'>
      <Box className={styles.container}>
        <DialogTitle>
          <Typography id='modal-modal-title' variant='h6' component='p'>
            Transfer Template
          </Typography>
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions style={{ position: 'absolute', right: 0, top: 0 }}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogContent style={{ padding: 0 }}>
          <form onSubmit={handleTransfer}>
            <section>
              <p> Transfer template email</p>
              <input type='email' placeholder='Email Address' value={email} onChange={(e) => setEmail(e.target.value)} required />
            </section>

            <footer>
              <div className={styles.button_container}>
                <button onClick={handleClose}>Cancel</button>
                <button type='submit' disabled={!email || isTransferring}>
                  {isTransferring ? 'Processing...' : 'Transfer'}
                </button>
              </div>
            </footer>
          </form>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default TransferTemplate;
