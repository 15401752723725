import React, { useState } from "react";
import styled from "@emotion/styled";
// import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import { useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "helpers/apiRequests";
import { BsFacebook } from "react-icons/bs";
import Twitter from "@material-ui/icons/Twitter";
import LinkedIn from "@material-ui/icons/LinkedIn";
import useMediaQuery from "@mui/material/useMediaQuery";
// import RenderByPermission from "components/RenderByPermission";
const isTeamMember = localStorage.getItem("team_member_uuid");

const UserInfo = ({ user }) => {
  const isMobile = useMediaQuery("(max-width:600px)");

  let name = "";
  let email = "";

  if (user?.team_member) {
    name = user?.team_member?.name;
    email = user?.team_member?.email;
  } else {
    name = user?.name;
    email = user?.email;
  }

  const [copy, setCopy] = useState(false);
  const unSyncedOrgs = useSelector((state) =>
    state.getALLUnsyncedOrgs?.data ? state.getALLUnsyncedOrgs.data : []
  );
  const syncedOrgs = useSelector((state) =>
    state.sync?.orgs ? state.sync.orgs : []
  );

  const handleCopy = () => {
    toast.notify("Refferal Link Copied to clipboard", { type: "info" });
  };
  const textStyle = {
    color: "rgb(202, 157, 33)",
    fontWeight: "bolder",
    cursor: "pointer",
    // fontSize: "20px"
  };

  const iconStyle = {
    display: "flex",
    marginRight: "50px",
    // marginLeft: "10px",
  };

  const shareFacebook = (link) => {
    const url = `https://web.facebook.com/sharer/sharer.php?u=${link}`;
    window.open(url, "_blank");
  };
  const shareTwitter = (link) => {
    const url = `https://twitter.com/intent/tweet?url=${link}`;
    window.open(url, "_blank");
  };
  const shareLinkedIn = (link) => {
    const url = `https://www.linkedin.com/sharing/share-offsite/?url=${link}`;
    window.open(url, "_blank");
  };
  // const URL = process.env.REACT_APP_URL
  const link = `https://thatapp.io/register?partner=${user.user_refferal_id}`;

  const img = isTeamMember
    ? "https://i.stack.imgur.com/l60Hf.png"
    : user?.podio_profile?.profile?.image;

  return (
    <UserInfo.Wrapper>
      <div className="row justify-content-center">
        <div
          style={{ flexDirection: "column" }}
          className="d-flex flex-direction-column align-items-center col md-8 w-50 pt-4"
        >
          <div className="mb-3">
            <Avatar
              alt={name ? name : user?.podio_profile?.profile?.name}
              src={img}
              style={isMobile ? {
                width: "102px",
                height: "102px",
                border: "1px solid #E1E1E1",
              } : {
                width: "142px",
                height: "142px",
                border: "1px solid #E1E1E1",
              }}
            />
          </div>
          <div className="mb-2">
            <h5 style={{ fontWeight: "bold" }} className="header-five">
              {" "}
              {user && name}{" "}
            </h5>
          </div>
          <div className="mb-4">
            <h5 className="header-five text-[#626262]"> {user && email} </h5>
          </div>
          <div className="mb-3 text-center">
            <h5
              style={{ color: "#CB6E17", fontSize: "16px" }}
              className="font-weight-bold mb-2"
            >
              Organisations
            </h5>
            <p
              style={{ fontSize: "16px", marginBottom: "0" }}
              className="header-five"
            >
              Synced {syncedOrgs.length} org(s)
            </p>
            <p style={{ fontSize: "16px" }} className="header-five">
              Unsynced {unSyncedOrgs.length} org(s)
            </p>
          </div>

          {user.user_is_podio_partner ? (
            <>
              <div className="mb-3">
                <div className="font-weight-bold header-six">Referral Link</div>
                <div className="header-five">
                  <CopyToClipboard text={link} onCopy={() => setCopy(true)}>
                    <p style={textStyle} onClick={handleCopy}>
                      {copy ? "Copy referral link" : "Copy referral link"}&nbsp;
                      <i className="fas fa-copy" />
                    </p>
                  </CopyToClipboard>
                </div>
              </div>
              <div className="font-weight-bold header-six">Share</div>
              <div style={iconStyle}>
                <BsFacebook
                  style={{
                    color: "blue",
                    marginRight: "10px",
                    cursor: "pointer",
                    fontSize: "24px",
                  }}
                  onClick={() => shareFacebook(link)}
                />
                <Twitter
                  style={{
                    color: "#007BFF",
                    marginRight: "10px",
                    cursor: "pointer",
                    fontSize: "24px",
                  }}
                  onClick={() => shareTwitter(link)}
                />
                <LinkedIn
                  style={{
                    color: "rgb(12,102,194)",
                    cursor: "pointer",
                    fontSize: "24px",
                  }}
                  onClick={() => shareLinkedIn(link)}
                />
              </div>
            </>
          ) : null}
        </div>
      </div>
      {/* <RenderByPermission allowTeamMemberAccess={false}>
        <div className="account-info mt-3 d-flex">
          <div className="w-50">
            <div className="header-five">DELETE ACCOUNT</div>
            <p className="w-50 content">
              If you delete your account, your data will be gone forever.
            </p>
          </div>
          <div>
            <Button
              variant="outlined"
              style={{ border: "none", marginLeft: "1rem", color: "#FF0400" }}
              color="primary"
              id="intercom_link"
              onClick={() => {
                window.Intercom(
                  "showNewMessage",
                  "Hello. I would like to delete my Sync account and all data attached to it."
                );
              }}
            >
              Delete
            </Button>
          </div>
        </div>
      </RenderByPermission> */}
    </UserInfo.Wrapper>
  );
};

UserInfo.Wrapper = styled.div`
  color: #515151;
  .content {
    color: #9e9e9e;
  }
  .main-section {
    border-bottom: 1px solid #e1e1e1;
  }
  .profile-pic {
    width: 96px;
    height: 96px;
    border-radius: 50%;
    object-fit: cover;
  }
  .account-info {
    margin-top: 50px;
    padding-top: 50px;
  }
  .delete-account {
    p {
      margin: 0;
    }
  }
`;
export default UserInfo;
