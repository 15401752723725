/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import dayjs from "dayjs";
import Axios from 'axios';
import { useParams, useHistory } from "react-router-dom";
import { toast } from "helpers/apiRequests";
import ContentWrapper from './widgets/ContentWrapper';
import { CircularProgress } from '@material-ui/core';
import ActivityTotal from './ActivityTotal';
import './style.css';
import Podio from "../../../assets/images/podioconnect.png";
import AuthoriseModal from './modals/AuthoriseModal';
import { parseHashString } from "../../Connector/sharedFunctions";
import { OrphanAuthConnect } from '../../../helpers/Authentication/Auth';
import AlertModal from '../../../components/Tmail/components/Modals/AlertModal';

const BASE_URL = process.env.REACT_APP_BASEURL

const ShowOrphanMessage = () => {
    const { emailId } = useParams();
    const history = useHistory();
    const [emailData, setEmailData] = useState(null);
    const [activity, setActivity] = useState([]);
    const [hooks, setHooks] = useState([]);
    const [loading, setLoading] = useState();
    const [openModal, setOpenModal] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectedConnector] = useState({
        title: "Podio",
        image: Podio
    });
    const closeModal = () => setOpenModal(false)
    const handleClose = () => setOpen(false)
    const [connecting, setConnecting] = useState({
        connector: null,
        loading: false,
    });
    const auth_type = localStorage.getItem("orphan_type");

    const fetchEmailData = async () => {
        try {
            setLoading(true);
            const accessToken = localStorage.getItem("orphan_access_token");
            const refreshToken = localStorage.getItem("orphan_refresh_token");
            // const hashToken = localStorage.getItem("orphan_hash_token");
            
            // if (hashToken) {
            //     const emailUrl = `${BASE_URL}/get/email?email_id=${emailId}&hash=${hashToken}`;
            //     const res = await Axios.get(emailUrl);
            //     const data = res.data.data
            //     setEmailData(data);
            //     const topfiveActivity = data?.activities.slice(0, 5);
            //     const topfivehooks = data?.webhooks.slice(0, 5);
            //     setActivity(topfiveActivity);
            //     setHooks(topfivehooks);
            //     localStorage.removeItem("orphan_hash_token");
            // } else {
                const payload = {
                    email_id: emailId,
                    access_token: accessToken,
                    refresh_token: refreshToken
                }
                const url = `${BASE_URL}/tmail/authorize-viewer`;
                const response = await Axios.post(url, payload);
                const hash = response.data.data.hash;
                if (hash != null) {
                    const emailUrl = `${BASE_URL}/get/email?email_id=${emailId}&hash=${hash}`;
                    const res = await Axios.get(emailUrl);
                    const resp = res.data.data
                    setEmailData(resp);
                    const topfiveActivity = resp?.activities.reverse().slice(0, 5);
                    const topfivehooks = resp?.webhooks.slice(0, 5);
                    console.log({ topfiveActivity, topfivehooks });
                    setActivity(topfiveActivity);
                    setHooks(topfivehooks);
                }
            //}
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setOpen(true);
            setEmailData(null);
            toast.notify(error.response.message, { type: "error" });
        }
    }

    useEffect(() => {
        const hashString = window.location.hash.substr(1);
        if (hashString && auth_type === "podio") {
            const access = parseHashString(hashString);
            if (access["access_token"]) {
                access["user_id"] = access["ref[id]"];
                OrphanAuthConnect(access, auth_type, emailId);
                return history.push(`/show_message/${emailId}`);
            }
        }
    }, [auth_type]);

    useEffect(() => {
        const accessToken = localStorage.getItem("orphan_access_token");
        const refreshToken = localStorage.getItem("orphan_refresh_token");
        if (!accessToken || !refreshToken) {
            setOpenModal(true)
        } else {

            fetchEmailData();
        }

    }, []);

    const formatKey = (value) => {
        return value.charAt(0).toLocaleUpperCase() + value.slice(1);
    }
    const formatDate = (value, type) => {
        if (type === "date") return dayjs(value).format("dddd D, MMMM YYYY, h:mm A")
        if (type === "source") return dayjs(value).format("MM/DD/YYYY, h:mm A")
    }
    const formatEmails = (email) => {
        const decoded = JSON.parse(email);
        if (decoded == null || decoded.length === 0) {
            return "";
        }
        return decoded.join(",")
    }

    const formatMessage = message => {
        return <div dangerouslySetInnerHTML={{ __html: message }} />;
    }

    const replyOrphanMessage = (data) => {
        const { uuid, id, item_id, } = data;
        var base_url = window.location.origin;
        const url = `${base_url}/draft-mail/${item_id}/${uuid}?commentId=${id}&type=reply`;

        window.open(url, "_blank");

    }


    if (loading) {
        return <center>
            <CircularProgress />
        </center>
    }
    const msg =
        "You are not authorized to view the content of this email. Kindly authorize with Podio.";
    const redirect = "Redirect to Dashboard";
    const redirectUrl = "/dashboard/sync";

    return (
        <div className="auth">
            <AuthoriseModal
                openModal={openModal}
                handleClose={closeModal}
                info={selectedConnector}
                setConnecting={setConnecting}
                emailId={emailId}
            />
            <AlertModal
                message={msg}
                openModal={open}
                handleClose={handleClose}
                url={redirectUrl}
                redirect={redirect}
            />

            <div className="row justify-content-center">
                <div className="col-md-10">
                    <div className="d-flex items-center">
                        <div className="col-md-6 mt-4 dt">
                            <h4 className='mb-3'>{emailData?.subject}</h4>
                            {
                                emailData !== null ?
                                    <>
                                        <ContentWrapper title="Date" content={formatDate(emailData?.created_at, "date")} />
                                        <ContentWrapper title="From" content={emailData?.from_email} />
                                        <ContentWrapper title="To" content={formatEmails(emailData?.to)} />
                                        <ContentWrapper title="Cc" content={formatEmails(emailData?.cc)} />
                                        <ContentWrapper title="Subject" content={emailData?.subject} />
                                        <ContentWrapper title="Message" content={formatMessage(emailData?.message)} />
                                        <ContentWrapper title="Source" content={formatDate(emailData?.created_at, "source")} />
                                        <ContentWrapper title="Actions" />

                                        <div className='d-flex mb-2 buttons_area'>
                                            <button
                                                style={{
                                                    color: "#fff",
                                                    background: "#F7C948",
                                                }}
                                                className='btn btn-default mt-4 ml-5'
                                                onClick={() => replyOrphanMessage(emailData)}
                                            >Reply</button>
                                            {/* <button
                                                style={{
                                                    color: "#000",
                                                    background: "#f5f5f5",
                                                    margin: "1rem .5rem"
                                                }}
                                                className='btn btn-default'
                                            >Reassign</button>
                                            <button
                                                style={{
                                                    color: "#000",
                                                    background: "#f5f5f5",
                                                    margin: "1rem .5rem"
                                                }}
                                                className='btn btn-default'
                                            >Block</button> */}
                                        </div>
                                    </> : null
                            }
                        </div>
                        {
                            emailData !== null ?
                                <div className='col-md-6'>
                                    <ActivityTotal activity={activity} streams={hooks} />
                                </div> : null
                        }

                    </div>
                </div>
            </div>
        </div >
    )
}

export default ShowOrphanMessage