import React, { useState } from "react";
import { useHistory } from "react-router";
import EmailStatusModal from "../Modals/EmailStatusModal";
import { ITEM_COMMENTS } from "../../../../containers/pagePath";
import { Assessment } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";

const ModerationAction = (props) => {
    const data = typeof props["data"] !== "undefined" ? props["data"] : null;
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const [statistics, setStatistics] = useState();

    const handleClose = () => setOpen(false);

    const handleOpen = (data) => {
        setStatistics(data)
        setOpen(true);
    }
    const clickAction = (data) => {
        history.push({
            pathname: ITEM_COMMENTS,
            state: data
        })
      
    }


    return (
        <>
            <EmailStatusModal
                openModal={open}
                handleClose={handleClose}
                statistic={statistics}
                title="Email"
            />
            {data !== null ? (
                <center>
                    <IconButton
                            aria-label="View"
                            onClick={() => handleOpen(data.statistics)}
                            title="View status"
                        >
                            <Assessment style={{ color: "#FFF", marginTop: "-5px",}} />
                        </IconButton>
                    <i
                        className="fa fa-eye text-info"
                        style={{
                            fontSize: "15px",
                            cursor: "pointer",
                            marginTop: "18px",
                        }}
                        title="View comments"
                        onClick={() => clickAction(data)}
                    ></i>


                </center>
            ) : (
                ""
            )}
        </>
    );
};

export default ModerationAction;
