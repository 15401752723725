export const REFRESH_PROGRESS = "REFRESH_PROGRESS";
export const GET_ALL_PROGRESS = "GET_ALL_PROGRESS";
export const DELETE_PROGRESS = "DELETE_PROGRESS";
export const ADD_DATABASE = "ADD_DATABASE";
export const ADD_MAPPER = "ADD_MAPPER";
export const GET_DATABASE = "GET_DATABASE";
export const GET_WORKFLOW = "GET_WORKFLOW";
export const GET_MAPPERS = "GET_MAPPERS";
export const CLEAR_CURRENT = "CLEAR_CURRENT";
export const DELETE_DATABASE = "DELETE_DATABASE";
export const DELETE_MAPPER = "DELETE_MAPPER";
export const GET_SQL_SYNC = "GET_SQL_SYNC";
export const UPDATE_SQL_SYNC = "UPDATE_SQL_SYNC";
export const DELETE_SQL_SYNC = "DELETE_SQL_SYNC";
export const ERROR = "ERROR";
