import React from 'react';
import { useHistory } from 'react-router-dom';

import { connectRightSignatureUser } from 'components/templates/print-v2/printUtils/requests';

const PrintRightSignatureRedirect = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const template_id = urlParams.get('template_id');
  const auth_code = urlParams.get('code');
  const history = useHistory();

  const REDIRECT_URI = `${window.location.origin}/print-data/signature/auth?template_id=${template_id}`;

  React.useEffect(() => {
    if (template_id && auth_code) {
      connectRightSignatureUser(auth_code, REDIRECT_URI)
        .catch(console.error)
        .finally(() => {
          history.push(`/print-data/edit/${template_id}`);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth_code, template_id]);

  return <p>Authorizing Sharefile User....</p>;
};

export default PrintRightSignatureRedirect;
