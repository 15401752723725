import Axios from 'config';
import axios from 'axios';
import handleResponseError from 'utils/handleResponseError';
import appendTeamMemberHeader from 'utils/appendTeamMemberHeader';

export function createTemplate(payload) {
  return Axios.post('/print/template/store', payload).then((response) => {
    return (window.location = `${window.location.origin}/print-data/edit/${response.data.id}`);
  });
}

export function createTemplateFromDoc(formData) {
  const authToken = localStorage.getItem('chosen_token');
  const baseURL = process.env.REACT_APP_BASEURL;

  let headers = {
    'content-type': 'multipart/form-data',
  };

  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }

  headers = appendTeamMemberHeader(headers);

  return axios({
    method: 'post',
    url: `${baseURL}/print/template/store`,
    data: formData,
    headers,
  })
    .then((response) => {
      return (window.location = `${window.location.origin}/print-data/edit/${response?.data?.data?.id}`);
    })
    .catch((err) => {
      handleResponseError(err.response);
      return Promise.reject(err);
    });
}

export function getItems({ app_id, updater = undefined, queryString = '', setLoadingItems = () => {} }) {
  setLoadingItems(true);
  return Axios.get(`/print/podio/items/${app_id}/index?q=${queryString}`)
    .then((res) => {
      const items = res.data?.item.map((a) => ({ label: a.title, value: a?.item_id }));

      if (updater) {
        return updater(items);
      }

      return items;
    })
    .finally(() => {
      setLoadingItems(false);
    });
}

export function getTemplates({ appId, per_page, page, templateCategory }) {
  if (templateCategory === 'active') {
    let appIdParam = appId ? `&app_id=${appId}` : '';
    return Axios.get(`/print/template/index?per_page=${per_page}&page=${page + 1}${appIdParam}`).then((res) => res?.data);
  } else {
    return Axios.get(`/print/template/archive/index?per_page=${per_page}&page=${page + 1}`).then((res) => res?.data);
  }
}

export function getTemplateDetails(template_id, updater, setIsLoading) {
  setIsLoading(true);
  return Axios.get(`print/template/show/${template_id}`)
    .then((res) => updater(res.data))
    .catch(() => {
      return (window.location = `${window.location.origin}/print-home`);
    })
    .finally(() => setIsLoading(false));
}

export function archiveTemplate(payload) {
  return Axios.post(`/print/template/delete`, payload);
}

export function deleteArchivedTemplate({ id }) {
  return Axios.post(`/print/template/archive/delete/${id}`);
}

export function getTemplateVariables(templateDetails, updater) {
  let url = `print/template/document/variables/${templateDetails.template_id}`;
  if (templateDetails.item_id) url = `${url}/${templateDetails.item_id}`;

  return Axios.get(url).then((res) => updater(res.data));
}

export function getIncomingRelationshipsVariables(item_id) {
  return Axios.get(`/print/podio/document/incoming/${item_id}`);
}

// updates just app_id
export function updateTemplate({ template_id, app_id }) {
  return Axios.post(`/print/podio/template/${template_id}/update`, { app_id: app_id });
}

export function cloneTemplate(template_id) {
  return Axios.post(`/print/template/clone/${template_id}`);
}

export function restoreTemplate(template_id) {
  return Axios.post(`/print/template/archive/restore/${template_id}`);
}

export function transferTemplate(payload) {
  return Axios.post(`/print/template/transfer`, payload);
}

export function togglePrintButton({ template_id, status }) {
  return Axios.post(`/print/podio/template/${template_id}/activate/${status}`);
}

// updates template with configs and editor content
export function saveTemplate(payload) {
  return Axios.post(`/print/template/update`, payload);
}

export function uploadTemplateToPodio(payload) {
  return Axios.post(`/print/podio/document/upload`, payload);
}

export function uploadDocTemplate(file) {
  const authToken = localStorage.getItem('chosen_token');
  const baseURL = process.env.REACT_APP_BASEURL;

  const headers = {
    'content-type': 'multipart/form-data',
  };

  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }

  return axios({
    method: 'post',
    url: `${baseURL}/print/template/document/convert`,
    data: file,
    headers,
  });
}

export const uploadimageUrl = `${process.env.REACT_APP_BASEURL}/print/template/document/upload-image`;
export function generateImageUploadHeaders() {
  const authToken = localStorage.getItem('chosen_token');
  let headers = {
    'X-CSRF-TOKEN': 'CSRF-Token',
  };

  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }

  headers = appendTeamMemberHeader(headers);
  return headers;
}

export function connectRightSignatureUser(code, redirect_uri) {
  const payload = {
    code,
    grant_type: 'authorization_code',
    redirect_uri,
  };

  return Axios.post(`/print/template/signature/auth/connect`, payload);
}

export function disconnectRightSignatureUser() {
  return Axios.post('/print/template/signature/auth/disconnect');
}

export function inviteSigners(payload) {
  return Axios.post('/print/template/signature/signers/save', payload);
}

export function fetchSignerFields({ app_id, org_id }) {
  return Axios.get(`/print/template/app/fields?app_id=${app_id}&org_id=${org_id}`);
}

export function uploadForSigning(payload) {
  return Axios.post(`print/template/signature/signers/invite`, payload);
}

export function retrieveSignedDocument({ template_id, item_id }) {
  return Axios.get(`/print/template/signature/document/show?template_id=${template_id}&item_id=${item_id}
  `);
}

export function uploadSignedDocToPodio(payload) {
  return Axios.post(`/print/template/signature/document/podio/upload`, payload);
}
