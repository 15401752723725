/* eslint-disable react-hooks/exhaustive-deps */
import { AgGridReact } from "ag-grid-react";
// import * as AllModules from "ag-grid-enterprise";
// import { ModuleRegistry } from "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import ActionsRenderer from "../AuditGrids/actions/ActionsRenderer";
import Axios from "../../../config";
import React, { useEffect, useState } from "react";
import { GetAllDatabases } from "../../../redux/actions/connector";
import { useDispatch } from "react-redux";

// ModuleRegistry.registerModules(Object.keys(AllModules));
const Spaces = ({ org_id, user }) => {
  const dispatch = useDispatch();

  const columns = [
    {
      headerName: "Space Name",
      field: "space",
      rowGroup: true,
      hide: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Apps",
      field: "app",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "App Id",
      field: "app_id",
      filter: "agNumberColumnFilter",
    },
    {
      headerName: "Space Id",
      // hide: false,
      field: "space_id",
      filter: "agNumberColumnFilter"
    },
    {
      headerName: "Number of Items(Mongo)",
      field: "items_count",
      filter: "agNumberColumnFilter"
    },
    {
      headerName: "Number of Items(Podio)",
      field: "podio_items_count",
      filter: "agNumberColumnFilter"
    },
    {
      headerName: "Number of Fields",
      field: "fields",
      filter: "agNumberColumnFilter"
    },
    {
      headerName: "Synced Since",
      field: "last_sync",
    },
    { headerName: "Action", field: "actions", cellRenderer: "actionsRenderer" },
  ];

  useEffect(() => {
    dispatch(GetAllDatabases());
  }, [])

  const defaultColDef = {
    flex: 1,
    minWidth: 100,
    filter: true,
    sortable: true,
    resizable: true,
  };
  const rowModelType = "serverSide";

  const frameworks = {
    actionsRenderer: ActionsRenderer,
  };

  const ServerSideDatasource = () => {
    return {
      getRows: function (params) {
        let group = "";
        let query = "";

        if (
          Array.isArray(params.request.groupKeys) &&
          params.request.groupKeys.length
        ) {
          group = `space_id=${params.parentNode.data.space_id}`;
          query = `${query}&${group}`;
        }

        if (group === "") {
          Axios.get(`/sync/podio/audit/spaces?org_id=${org_id}${query}`)
            .then((response) => {
              const rowData = [];

              response.data.map((item, i) => {
                return rowData.push({
                  space: item.space_name,
                  app: item.apps,
                  app_id: "N/A",
                  space_id: item.space_id,
                  fields: item.fields,
                  files: item.files,
                  org_id: org_id,
                  podio_items_count: item.podio_items_count,
                  items_count: item.items_count,
                  // podio_items_count: item.podio_items_count,
                  deleted_from_podio: "false",
                  deleted_space_from_podio: item.deleted_space_from_podio,
                  point: "space",
                  last_sync: item.synced_at,
                  full_app_object: item.full_app_object,
                });
              });
              setTimeout(function () {
                params.successCallback(rowData, rowData.length);
              }, 200);
            })
            .catch(() => {
              params.failCallback();
            });
        } else {
          Axios.get(`/sync/podio/audit/apps?org_id=${org_id}&${query}`)
            .then((response) => {
              const rowData = [];

              response.data.map((item) => {
                return rowData.push({
                  app: item.app,
                  app_id: item.app_id,
                  space_id: item.space_id,
                  fields: item.fields,
                  files: item.files,
                  org_id: org_id,
                  items_count: item.items_count,
                  podio_items_count: item.podio_items_count,
                  deleted_from_podio: item.deleted_from_podio,
                  deleted_space_from_podio: "false",
                  point: "app",
                  last_sync: item.synced_at,
                  full_app_object: item.full_app_object,
                  space_name: item.space_name
                });
              });
              setTimeout(function () {
                params.successCallback(rowData, rowData.length);
              }, 200);
            })
            .catch(() => {
              params.failCallback();
            });
        }
      },
    };
  };

  const [gridApi, setGridApi] = useState(null);
  const [initialRender, setInitialRender] = useState(false);

  const onGridReady = (params) => {
    const datasource = ServerSideDatasource();
    setGridApi(params.api);

    params.api.setServerSideDatasource(datasource);
    setInitialRender(true);
  };

  const refreshCache = () => {
    if (initialRender) {
      const datasource = ServerSideDatasource();
      gridApi.setServerSideDatasource(datasource);

      setInitialRender(true);
    }
  };
  useEffect(() => {
    refreshCache();
  }, [org_id, user]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className="ag-theme-balham-dark"
      style={{
        height: "800px",
        width: "100%",
      }}
    >
      <AgGridReact
        // modules={Object.keys(AllModules)}
        columnDefs={columns}
        onGridReady={onGridReady}
        frameworkComponents={frameworks}
        animateRows={true}
        debug={true}
        enableRangeSelection={true}
        sideBar={true}
        suppressAggFuncInHeader={true}
        enableFilter={true}
        enableSorting={true}
        defaultColDef={defaultColDef}
        rowModelType={rowModelType}
        rowGroupPanelShow={
          user?.permissions?.data_interaction?.row_grouping ? "always" : "never"
        }
        rowSelection="multiple"
      ></AgGridReact>
    </div>
  );
};

export default Spaces;
