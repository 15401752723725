/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Typography,
    Box,
    FormGroup,
    Checkbox,
    FormControlLabel,
    LinearProgress,
} from "@material-ui/core";
import { toast } from "helpers/apiRequests";
import TextEditor from "../../../TmailMessage/TextEditor";
import Axios from 'axios';
const BASE_URL = process.env.REACT_APP_BASEURL;

const SignatureModal = (props) => {
    const { openModal, handleClose, appId, signatureData, handleSignatureChange, signatures } = props;
    const [signature, setSignature] = useState("");
    const [signatureName, setSignatureName] = useState("");
    const [signatureId, setSignatureId] = useState(null);
    const [checkOne, setCheckOne] = useState(false);
    const [checkTwo, setCheckTwo] = useState(false);
    const [loading, setLoading] = useState(false);

    const changeCheckOne = () => setCheckOne(!checkOne);
    const changeCheckTwo = () => setCheckTwo(!checkTwo);

    const changeSignatureHandler = value => setSignature(value);
    const token = localStorage.getItem("chosen_token");
    const config = {
        headers: {
            "Authorization": "Bearer " + token
        }
    };

    useEffect(() => {
        setSignature(signatureData ? signatureData.content : "");
        setSignatureName(signatureData ? signatureData.name : "");
        setSignatureId(signatureData ? signatureData.id : "");
        const boxOne = signatureData ? signatureData.is_app_default === 0 ? false : true : false;
        const boxTwo = signatureData ? signatureData.is_global_default === 0 ? false : true : false;
        setCheckOne(boxOne);
        setCheckTwo(boxTwo);
    }, [openModal])

    const submitSiganture = (e) => {
        e.preventDefault();
        setLoading(true)
        const payload = {
            signature_id: signatureId,
            name: signatureName,
            content: signature,
            podio_app_id: appId,
            is_global_default: checkOne ? "1" : "0",
            is_app_default: checkTwo ? "1" : "0"
        }
       
        const url = `${BASE_URL}/tmail/save/signature`;

        Axios.post(url, payload, config).then(res => {
            
            const newData = res.data.data;
            if (signatureId != null) {
                const filteredSign = signatures.filter(sign => sign.id !== signatureId);
                const newArr = filteredSign.concat(newData);
                handleSignatureChange(newArr)
            } else {
                const newArr = signatures.concat(newData);
                handleSignatureChange(newArr)
            }
            setLoading(false)
            handleClose();
            toast.notify("Signature created successfully");
        }).catch(err => {
            setLoading(false)
            toast.notify(err.response.message, { type: "error" });
        })

    }

    const deleteSignature = () => {
        setLoading(true)
        const url = `${BASE_URL}/tmail/signatures/${signatureId}`;
       
        Axios.delete(url, config).then(res => {
          
            const filteredSign = signatures.filter(sign => sign.id !== signatureId);
            handleSignatureChange(filteredSign)
            setLoading(false);
            handleClose();
            toast.notify("Signature deleted successfully");
        }).catch(err => {
            setLoading(false)
            toast.notify(err.response.message, { type: "error" });
        })

    }

    return (
        <div>
            <Dialog
                open={openModal}
                onClose={handleClose}
                // hideBackdrop={true}
                disableBackdropClick={false}
                disableEscapeKeyDown={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                id="connector-modal"
            >
                <DialogContent>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Typography className="pb-3" gutterBottom>
                                <h4>Add new signature</h4>
                            </Typography>
                            <Typography gutterBottom>
                                <Box>
                                    <form>
                                        <div className="form-row ">
                                            <div className="col-md-4">
                                                <label htmlFor="signature_name">Signature Name</label>

                                            </div>
                                            <div className="form-group col-md-8">
                                                <input
                                                    type="text"
                                                    value={signatureName}
                                                    className="form-control"
                                                    onChange={(e) => setSignatureName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Checkbox
                                                    onChange={changeCheckOne}
                                                    checked={checkOne} />}
                                                label="global default" />
                                            <FormControlLabel
                                                control={<Checkbox
                                                    onChange={changeCheckTwo}
                                                    checked={checkTwo} />}
                                                label="default for this app" />
                                        </FormGroup>
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <TextEditor
                                                    change={changeSignatureHandler}
                                                    msg={signature != null ? signature : ''}
                                                />

                                            </div>
                                        </div>
                                        <div className="mt-2 mb-3">
                                            {
                                                loading ? <center className="mt-3">
                                                    <LinearProgress />
                                                </center> : null
                                            }
                                        </div>
                                        <button type="button" onClick={submitSiganture} className="btn btn-primary mr-2">Save</button>
                                        <button type="button" className="btn btn-secondary mr-2" onClick={handleClose}>Cancel</button>
                                        <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={deleteSignature}
                                            disabled={signatureId ? false : "disabled"}
                                        >Delete</button>
                                    </form>
                                </Box>
                            </Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button id="button-cancel" onClick={handleClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default SignatureModal;
