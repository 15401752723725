import React, { useState } from 'react';
import { FaEye } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import { FaRedo } from "react-icons/fa";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from 'react-redux';
import { verifyDomainName } from 'redux/actions/tmail';
import VerifyDomain from 'components/Tmail/components/Modals/VerifyDomain';
import ViewDomain from 'components/Tmail/components/Modals/ViewDomain';
import DeleteDomain from 'components/Tmail/components/Modals/DeleteDomain';
import RenderByPermission from 'components/RenderByPermission';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: '#F0B429',
    fontSize: '24px'
  },
  btn: {
    height: '44px',
    width: '185px',
    backgroundColor: '#F0B429',
    borderRadius: '3px',
    textTransform: 'capitalize',
  },
  card: {
    borderRadius: '12px',
    background: '#fff',
    boxShadow: '0px 2px 16.9px 0px rgba(0, 0, 0, 0.11)',
  },
  cardBody: {
    padding: '41px 38px'
  },
  paddingX: {
    padding: '35px 38px'
  },
  th: {
    border: '0px',
    borderBottom: '1px solid #9E9E9E',
    paddingTop: '21px',
    paddingBottom: '21px',
    color: '#222',
    fontWeight: '600',
    fontSize: '14px'
  },
  td: {
    border: '0px',
    borderBottom: '1px solid #E1E1E1',
    paddingTop: '21px',
    paddingBottom: '21px',
    color: '#626262',
    fontWeight: '400',
    fontSize: '16px'
  },
  tableHeaderRow: {
    alignItems: 'center',
    backgroundColor: '#F7F7F7',
  },
  tableBodyRow: {
    alignItems: 'center',
    backgroundColor: '#F7F7F7',
    border: 'none'
  },
  checkInput: {
    width: '18px',
    height: '18px',
    borderRadius: '4px',
    appearance: 'auto'
  },
  successBadge: {
    display: "flex",
    width: "fit-content",
    padding: "8px 16px",
    alignItems: "center",
    gap: "16px",
    borderRadius: "94px",
    background: "#C6F7E2",
    color: "#27AB83",
    fontSize: '12px',
    fontStyle: "normal",
    fontWeight: "600",
    lineGeight: "normal"
  },
  errorBadge: {
    display: "flex",
    width: "fit-content",
    padding: "8px 16px",
    alignItems: "center",
    gap: "16px",
    borderRadius: "94px",
    background: "#FFBDBD",
    color: "#E12D39",
    fontSize: '12px',
    fontStyle: "normal",
    fontWeight: "600",
    lineGeight: "normal"
  },
  actionGroup: {
    display: 'flex',
    gap: '14px',
    alignItems: 'center'
  },
  actionBtn: {
    background: 'none',
    border: 'none',
    outline: 'none',
    height: '24px',
    width: '24px',
    color: '#626262',
    padding: '0px'
  }
}));

const DomainItem = ({ domain }) => {
  const classes = useStyles()
  const [openVerify, setOpenVerify] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const changeIsLoading = () => setLoading(false);
  const toggleVerifyModal = () => setOpenVerify(false);
  const dispatch = useDispatch()
  const [viewModal, setViewModal] = useState(false);
  const toggleViewModal = () => setViewModal(false);
  const toggleModal = () => setOpenModal(false);


  const verifyDomain = (id) => {
    setLoading(true);
    dispatch(verifyDomainName(id, changeIsLoading, toggleVerifyModal));
  };

  const openVerifyDomain = (id) => {
    setOpenVerify(true);
    verifyDomain(id);
  };

  return (
    <>
      <tr className={classes.tableBodyRow} key={domain.id}>
        <td className={classes.td}>
          <input type="checkbox" className={classes.checkInput} id={'hi'} name={'hi'} value={'hi'} />
        </td>
        <td className={classes.td}>{domain.org.org_id}</td>
        <td className={classes.td}>{domain.org.org_name}</td>
        <td className={'text-primary ' +classes.td}>
          <a href={`https://${domain.domain}`} target='_blank' rel="noreferrer">{domain.domain}</a>
        </td>
        <td className={classes.td}>
          {domain.verification_status === "active" ?
            <div className={classes.successBadge}>Verified</div> :
            <div className={classes.errorBadge}>Unverified</div>}
        </td>
        <td className={classes.td}>
          <div className={classes.actionGroup}>
            <button 
              type='button' 
              className={classes.actionBtn}
              onClick={() => setViewModal(true)}
              title="View"
            >
              <FaEye />
            </button>
      
            <button 
              type='button' 
              className={classes.actionBtn}
              title="Refresh verification status"
              onClick={() => openVerifyDomain(domain.id)}
            >
              <FaRedo />
            </button>
            <RenderByPermission allowTeamMemberAccess={false}>
              <button 
                type='button' 
                className={classes.actionBtn} 
                title="Delete"
              >
                <FaTrash />
              </button>
            </RenderByPermission>
          </div>
        </td>
      </tr>
      <DeleteDomain
        openModal={openModal}
        handleClose={toggleModal}
        item={domain.id}
      />
      <VerifyDomain
        openModal={openVerify}
        domain={domain}
        handleClose={toggleVerifyModal}
      />
      <ViewDomain
        openModal={viewModal}
        domain={domain}
        handleClose={toggleViewModal}
        verifyDomain={openVerifyDomain}
        isLoading={loading}
      />
    </>
  )
}

export default DomainItem
