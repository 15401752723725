import { Box } from '@material-ui/core'
import React from 'react'

const RightColumn = (props) => {
    const bgStyle = {
        padding: "5px 10px",
    }

    return (
        <Box className="col-md-9" style={bgStyle}>
            <p className='p-1'>
                {props.content}
            </p>
        </Box>
    )
}

export default RightColumn