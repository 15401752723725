/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    FormGroup,
    LinearProgress,
    Typography,
} from "@material-ui/core";
import SelectableItem from "../utility/SelectableItem";
import axios from "../../../../config";
import { toast } from "helpers/apiRequests";
import { GetAllApps } from "../../../../redux/actions/tmail";
import { useDispatch, useSelector } from "react-redux";

const MoveComment = ({ openModal, handleClose, comment, removeComment }) => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [currentAt, setCurrentAt] = useState("");
    const [loading, setLoading] = useState({
        app: false,
        item: false,
        save: false
    });
    const [items, setItems] = useState([]);
    const [selectedApp, setSelectedApp] = useState();
    const [selectedItem, setSelectedItem] = useState();
    const [checked, setChecked] = useState(false);
    const apps = useSelector((state) => state.tmail.apps);
    const isLoading = useSelector((state) => state.tmail.loading);

    const getAppItems = async (id) => {
        try {
            setLoading({
                ...loading,
                item: true
            })
            const response = await axios.get(`/tmail/comment-moderation/items?tmail_app_id=${id}`);
            setItems(response.data.items);
            setLoading({
                ...loading,
                item: false
            })

        } catch (error) {
            setItems([]);
            setLoading({
                ...loading,
                item: false
            });
            toast.notify("An Error Occured: " + error, { type: "error" });

        }

    }

    useEffect(() => {
        if (openModal) {

            dispatch(GetAllApps())
            const convertedTo = comment ? JSON.parse(comment.to) : '';
            let to;
            if (Array.isArray(convertedTo)) {
                if (convertedTo.length === 0) {
                    to = '';
                } else {
                    to = convertedTo.join(",")
                }
            }
            const emailMessage = `from ${comment?.from_email} to ${to}\nabout ${comment?.subject}
            `;
            const currentlyAt = `Item ${comment?.email_item.item_name} (in App ${comment?.email_app.app_name})`;
            setCurrentAt(currentlyAt);
            setEmail(emailMessage);
        }
    }, [openModal]);

    const options = apps.length > 0 ? apps.map(app => {
        return {
            "value": app.id,
            "label": `${app.org_name} > ${app.space_name} > ${app.app_name}`,
        }
    }) : [];
    
    const itemOptions = items.length > 0 ? items.map(app => {
        return {
            "value": app.item_id,
            "label": `${app.item_name}`,
        }
    }) : [];

    const handleAppChange = (val) => {
        setSelectedApp(val);
        getAppItems(val.value)
    }
    const handleItemChange = (val) => {
        setSelectedItem(val);
    }

    const handleDeleteChange = (e) => setChecked(!checked)

    const moveAppHandler = async () => {
        try {
            // handleClose();
            setLoading({
                ...loading,
                save: true
            })
            const payload = {
                tmail_app_id: selectedApp.value,
                tmail_comment_id: comment.id,
                podio_item_id: selectedItem.value,
                delete_old_comment: checked ? 1 : 0
            };
            const res = await axios.post('/tmail/comment-moderation/move', payload);
            setLoading({
                ...loading,
                save: false
            });
            if (res.success === true) {
                if (payload.delete_old_comment === 1) {
                    removeComment()
                }
                handleClose();
            }
            toast.notify(res.message);
        } catch (error) {
            setLoading({
                ...loading,
                save: false
            })
            toast.notify(error.message, { type: "error" });
        }
    }

    return (
        <div>
            <Dialog
                open={openModal}
                onClose={handleClose}
                disableEscapeKeyDown={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
            >
                <DialogContent>
                    <Box style={{ padding: "20px", width: "500px" }}>
                        <Typography id="modal-modal-title" variant="h6" component="p" className="mb-3">
                            Move Email Comment
                        </Typography>
                        <div className="mt-3 mb-3">
                            <div className="form-row">
                                <div className="form-group col-md-2">
                                    <label
                                        style={{
                                            fontWeight: "normal",
                                            fontSize: "15px",
                                            marginTop: "5px"
                                        }}>
                                        Email:
                                    </label>
                                </div>
                                <div className="form-group col-md-10">
                                    <textarea
                                        className="form-control"
                                        name="date"
                                        rows="2"
                                        cols="1"
                                        readOnly
                                    >
                                        {email}
                                    </textarea>

                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-2">
                                    <label
                                        style={{
                                            fontWeight: "normal",
                                            fontSize: "15px",
                                            marginTop: "5px"
                                        }}>
                                        Currently at:
                                    </label>
                                </div>
                                <div className="form-group col-md-10">
                                    <input
                                        className="form-control"
                                        name="date"
                                        value={currentAt}
                                        type="text"
                                        readOnly
                                    />

                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-2">
                                    <label
                                        style={{
                                            fontWeight: "normal",
                                            fontSize: "15px",
                                            marginTop: "5px"
                                        }}>
                                        Move to:
                                    </label>
                                </div>
                                <div className="form-group col-md-10">
                                    <SelectableItem
                                        placeholder="Select Destination App"
                                        options={options}
                                        handleChange={handleAppChange}
                                    />
                                    {
                                        isLoading ? <LinearProgress /> : null
                                    }
                                </div>

                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-2">
                                    <label
                                        style={{
                                            fontWeight: "normal",
                                            fontSize: "15px",
                                            marginTop: "5px"
                                        }}>
                                        to Item:
                                    </label>
                                </div>
                                <div className="form-group col-md-10">
                                    <SelectableItem
                                        placeholder="Select Destination Item"
                                        options={itemOptions}
                                        handleChange={handleItemChange}
                                        isMulti={false}
                                    />
                                    {
                                        loading.item ? <LinearProgress /> : null
                                    }
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-2 mt-2">
                                    <label
                                        style={{
                                            fontWeight: "normal",
                                            fontSize: "15px",
                                        }}>
                                        And
                                    </label>
                                </div>
                                <div className="form-group col-md-10">
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox value={checked} onChange={handleDeleteChange} />}
                                            label="Delete this original item?"
                                        />
                                    </FormGroup>
                                </div>
                            </div>

                        </div>
                        {
                            loading.save ? <LinearProgress /> : null
                        }
                        <div className="d-flex justify-content-between">
                            <Button id="button-cancel" color="primary" onClick={handleClose}>
                                Close
                            </Button>
                            <button
                                type='button'
                                style={{
                                    color: "#fff",
                                    background: "#F7C948",
                                }}
                                className="btn btn-default"
                                onClick={moveAppHandler}
                            >Move</button>
                        </div>
                    </Box>
                </DialogContent>
                <DialogActions>
                    {/* <Button id="button-cancel" className="mr-4 " onClick={handleClose} color="primary">
                        Close
                    </Button> */}
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default MoveComment;
