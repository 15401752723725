import React from 'react';
import AnalysisCard from './utility/AnalysisCard'

const StatusList = ({ statistic }) => {
    return (
        <div className="d-flex justify-content-around mb-3 text-nowrap">
            <div className="">
                <AnalysisCard title="Delivered" total={statistic ? statistic?.delivered : 0} />
            </div>
            <div className="">
                <AnalysisCard title="Opened" total={statistic ? statistic?.opened : 0} />
            </div>
            <div className="">
                <AnalysisCard title="Clicked" total={statistic ? statistic?.clicked : 0} />
            </div>
            <div className="">
                <AnalysisCard title="Complained" total={statistic ? statistic?.complained : 0} />
            </div>
        </div>
    )
}

export default StatusList