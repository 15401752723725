import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import React from "react";

const ReplyLinkTab = (props) => {
  const {
    saveLoading,
    // goBack,
    handleSubmit,
    subJectOptions,
    changeSubject,
    changeCheckCompose,
    compose,
    subject,
    classes,
  } = props;
  const removeMargin = {
    marginTop: "-8px",
    marginBottom: "10px",
    marginLeft: "30px",
  };

  return (
    <div className="row">
      <div className="col-md-8">
        <div className={classes.header}>Reply Links in Podio</div>
        <br />
        <form>
          <div className="form-group">
            <label htmlFor="emailSubject">Subject for Emails</label>
            <select
              className="form-control"
              value={subject}
              onChange={(e) => changeSubject(e.target.value)}
            >
              <option value="default">default item title</option>
              {subJectOptions}
              {/* <option value="send_email">Send Email</option> */}
            </select>
            <p className="text-muted">
              Select a Podio field from your App that should be used in the
              subject line of new emails.
            </p>
          </div>
          {/* <div className="form-group">
                    <label htmlFor="emailBody">Original Email Body</label>
                    <select
                        className='form-control'
                        onChange={(e) => changeBodyField(e.target.value)}
                    >
                        <option value=""></option>
                        {BodyOptions}
                        {/* <option value="send_email">Send Email</option> */}
          {/* </select>
                    <p className="text-muted">Select a Podio field from your App that should be used as the quoted reply text of new emails.</p>
                </div> */}
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox onChange={changeCheckCompose} checked={compose} />
              }
              label="Add Compose Link to App"
            />
            <Typography variant="p" className="text-muted" style={removeMargin}>
              Add a field to the App in Podio with a link to start composing a
              new message (could take a few minutes to populate in Podio).
            </Typography>
          </FormGroup>
          <div className="form-goup mt-3">
            <button
              className="btn btn-default mr-2"
              onClick={handleSubmit}
              style={{
                color: "#fff",
                background: "#F7C948",
                width: "150px",
                height: "50px",
              }}
            >
              Save
            </button>
            {/* <button className='btn btn-secondary ml-2' onClick={goBack}>Cancel</button> */}
          </div>
          <div className="mt-2">
            {saveLoading ? (
              <center className="mt-3">
                <LinearProgress />
              </center>
            ) : null}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ReplyLinkTab;
