import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import axios from "../../config";
import Notification from "../Notification/Notification";
import appendTeamMemberHeader from "utils/appendTeamMemberHeader";

// function rand() {
//   return Math.round(Math.random() * 20) - 10;
// }

export const getModalStyle = function () {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};

const useStyles = makeStyles((theme) => ({
  mapper_creation: {
    position: "absolute",
    width: 400,
    margin: "auto",
    minWidth: "50%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #fff",
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function SelectAppForMapperCreation({ setFields, setOpenItemModal, fields }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [notificationData, setNotificationData] = useState({
    displayNotification: false,
    msg: "",
    severity: "success",
  });

  const [modalStyle] = useState(getModalStyle);
  const [organizations, setOrganizations] = useState([]);
  const [apps, setApps] = useState([]);
  const [selectedApp, setSelectedApp] = useState("");
  const [selectedOrganization, setSelectedOrganization] = useState("");
  const [selectedSpace, setSelectedSpace] = useState("");
  const [spaces, setSpaces] = useState([]);
  const [loading, setLoading] = useState({
    apps: false,
    spaces: false,
  });


  const handleOpen = () => {
    setOpen(true);
  };
  // eslint-disable-next-line
  useEffect(() => {
    getOrganizations();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const token = "Bearer" + localStorage.getItem("chosen_token");
  let headers = {
    Authorization: token,
  }

  headers = appendTeamMemberHeader(headers)
  const getOrganizations = async () => {
    try {
      setLoading({
        apps: false,
        spaces: true,
      });
      let data = await axios.get(`/sync/podio/orgs/synced`, {
        headers
      });
      setOrganizations(data.data);
      setLoading({
        apps: false,
        spaces: false,
      });
    } catch (e) {
      setNotificationData({
        displayNotification: true,
        msg: "Error retrieving organizations",
        severity: "error",
      });
    }
  };

  const getSpaces = async (orgId) => {
    try {
      setLoading({
        apps: false,
        spaces: true,
      });
      let res = await axios.get(`/sync/podio/audit/spaces?org_id=${orgId}`, {
        headers: {
          Authorization: token,
        },
      });
    
      setSpaces(res.data);
      setLoading({
        apps: false,
        spaces: false,
      });
    } catch (e) {
      setNotificationData({
        displayNotification: true,
        msg: "Error retrieving spaces",
        severity: "error",
      });
    }
  };

  const getApps = async (spaceId) => {
    try {
      setLoading({
        apps: true,
        spaces: false,
      });
      let data = await axios.get(
        `/sync/podio/audit/apps?space_id=${spaceId}&org_id=${selectedOrganization}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setApps(data.data);
      setLoading({
        apps: false,
        spaces: false,
      });
    } catch (e) {
      setNotificationData({
        displayNotification: true,
        msg: "Error retrieving apps",
        severity: "error",
      });
    }
  };

  const handleOrganizationChange = async (event) => {
    setSpaces([]);
    setApps([]);

    setSelectedOrganization(event.target.value);
    await getSpaces(event.target.value);
  };

  const handleSpacesChange = async (event) => {
    setApps([]);
    setSelectedSpace(event.target.value);

    await getApps(event.target.value);
  };

  const handleAppChange = async (event) => {
    setSelectedApp(event.target.value);

    const currentApp = apps.find((app) => {
      return app.app_id === Number(event.target.value);
    });
    currentApp.org_id = selectedOrganization;
   

    setFields(currentApp);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setSelectedApp("");
    setSelectedOrganization("");
    setSelectedSpace("");
    setOpen(false);
  };

  const body = (
    <div style={modalStyle} className={classes.mapper_creation}>
      <Notification
        displayNotification={notificationData.displayNotification}
        severity={notificationData.severity}
        msg={notificationData.msg}
      />
      <form className="pt-2">
        <h3 className="mb-3">Create Mapper</h3>
        <div className="form-group">
          <label htmlFor="organisation">Organization</label>

          <select
            onChange={handleOrganizationChange}
            value={selectedOrganization}
            className="form-control"
            id="organisation"
          >
            <option value="">Select Organization</option>

            {organizations.map((organization, index) => (
              <option key={index} value={organization.org_id}>
                {organization.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="space">Space</label>
          {loading.spaces ? (
            <div className="spinner-border ml-2 spinner-border-sm text-info" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            ""
          )}

          <select
            onChange={handleSpacesChange}
            value={selectedSpace}
            className="form-control"
            id="space"
          >
            <option value="">Select Space</option>
            {spaces.map((space, index) => (
              <option key={index} value={space.space_id}>
                {space.space_name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="app">App</label>
          {loading.apps ? (
            <div className="spinner-border ml-2 spinner-border-sm text-info" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            ""
          )}
          <select onChange={handleAppChange} value={selectedApp} className="form-control" id="app">
            <option value="">Select App</option>
            {apps.map((app, index) => {
              return (
                <option key={index} value={app.app_id}>
                  {app.app}
                </option>
              );
            })}
          </select>
        </div>

        <div className="d-flex justify-content-between mt-5">
          <button onClick={handleClose} className="sync__btn bg-danger">
            Cancel
          </button>
          <button
            onClick={() => {
              handleClose();
              setOpenItemModal(true);
            }}
            className="sync__btn"
          >
            Create Mapper
          </button>
        </div>
      </form>
    </div>
  );
  return (
    <div>
      <button className="sync__btn" type="button" onClick={handleOpen}>
        Create Mapper
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
