import React from 'react';

const SyncLoadingContext = React.createContext(null);
export const SyncLoadingProvider = (props) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const value = {
    isLoading,
    setIsLoading,
  };

  return <SyncLoadingContext.Provider value={value} {...props} />;
};

export default SyncLoadingProvider;

export function useSyncLoadingContext() {
  const context = React.useContext(SyncLoadingContext);

  if (!context) {
    throw new Error('useSyncLoadingContext must be used within a SyncLoadingContext Provider');
  }

  return context;
}
