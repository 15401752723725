/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    LinearProgress,
    Typography,
} from "@material-ui/core";
import axios from 'axios';
import { toast } from "helpers/apiRequests";
import SelectableItem from "../../../../components/Tmail/components/utility/SelectableItem";
const BASE_URL = process.env.REACT_APP_BASEURL

const ReassignModal = ({ openModal, handleClose, authToken, app_id, unique_id }) => {
    const [loading, setLoading] = useState({
        app: false,
        item: false,
        save: false
    });
    const [items, setItems] = useState([]);
    const [apps, setApps] = useState([]);
    const [selectedApp, setSelectedApp] = useState();
    const [selectedItem, setSelectedItem] = useState();
    const [podioItem, setPodioItem] = useState();

    const getConnectedApps = async () => {
        try {
            setLoading({
                ...loading,
                app: true
            });
            const config = {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            };
            const url = `${BASE_URL}/tmail/connected/apps`;
            const res = await axios.get(url, config);
            setApps(res.data.data);
            setLoading({
                ...loading,
                app: false
            })

        } catch (error) {
            setApps([]);
            setLoading({
                ...loading,
                app: false
            });
            toast.notify("An Error Occured: " + error, { type: "error" });

        }

    }

    const getAppItems = async (id) => {
        try {
            setLoading({
                ...loading,
                item: true
            });
            const config = {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            };
            const url = `${BASE_URL}/tmail/comment-moderation/items?tmail_app_id=${id}`
            const response = await axios.get(url, config);
            setItems(response.data.data.items);
            setLoading({
                ...loading,
                item: false
            })

        } catch (error) {
            setItems([]);
            setLoading({
                ...loading,
                item: false
            });
            toast.notify("An Error Occured: " + error, { type: "error" });
        }

    }

    useEffect(() => {
        if (openModal) {
            getConnectedApps();
        }
    }, [openModal]);

    const options = apps.length > 0 ? apps.map(app => {
        return {
            "value": app.id,
            "label": `${app.org_name} > ${app.space_name} > ${app.app_name}`,
        }
    }) : [];

    const itemOptions = items.length > 0 ? items.map(app => {
        return {
            "value": app.item_id,
            "label": `${app.item_name}`,
        }
    }) : [];

    const handleAppChange = (val) => {
        const appId = val.value;
        const app = apps.find(where => where.id === appId);
        setSelectedApp(app);
        getAppItems(val.value)
    }

    const handleItemChange = (val) => {
        const item_id = val.value;
        const item = items.find(where => where.item_id === item_id);
        setSelectedItem(item);
    }

    const reAssignItem = async () => {
        try {
            setLoading({
                ...loading,
                save: true
            });
            const config = {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            };
            const payload = {
                app_id,
                org_id: selectedApp.org_id,
                app_item_id: unique_id,
                new_app_id: selectedApp.app_id,
                new_item_id: selectedItem.item_id
            };
            const url = `${BASE_URL}/tmail/orphan/emails/assign`;
            const res = await axios.post(url, payload, config);
            setLoading({
                ...loading,
                save: false
            });
            toast.notify(res.data.message);
            setTimeout(() => {
                window.close();
            }, 3000);
            handleClose();

        } catch (error) {
            setLoading({
                ...loading,
                save: false
            });
            toast.notify(error.message, { type: "error" });
        }
    }

    return (
        <div>
            <Dialog
                open={openModal}
                onClose={handleClose}
                disableEscapeKeyDown={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
            >
                <DialogContent>
                    <Box style={{ padding: "20px", width: "500px" }}>
                        <Typography id="modal-modal-title" variant="h6" component="p" className="mb-3">
                            Assign Orphan to Item
                        </Typography>
                        <div className="mt-3 mb-3">

                            <div className="form-row">
                                <div className="form-group col-md-3">
                                    <label
                                        style={{
                                            fontWeight: "normal",
                                            fontSize: "15px",
                                            marginTop: "5px"
                                        }}>
                                        Select App:
                                    </label>
                                </div>
                                <div className="form-group col-md-9">
                                    <SelectableItem
                                        placeholder="Select Destination App"
                                        options={options}
                                        handleChange={handleAppChange}
                                    />
                                    {
                                        loading.app ? <LinearProgress /> : null
                                    }
                                </div>

                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-3">
                                    <label
                                        style={{
                                            fontWeight: "normal",
                                            fontSize: "15px",
                                            marginTop: "5px"
                                        }}>
                                        Select Item:
                                    </label>
                                </div>
                                <div className="form-group col-md-9">
                                    <SelectableItem
                                        placeholder="Select Destination Item"
                                        options={itemOptions}
                                        handleChange={handleItemChange}
                                        isMulti={false}
                                    />
                                    {
                                        loading.item ? <LinearProgress /> : null
                                    }
                                </div>
                            </div>

                        </div>

                        <div className="d-flex justify-content-between">
                            <Button id="button-cancel" color="primary" onClick={handleClose}>
                                Close
                            </Button>
                            {
                                loading.save ? <CircularProgress /> :
                                    <button
                                        type='button'
                                        style={{
                                            color: "#fff",
                                            background: "#F7C948",
                                        }}
                                        className="btn btn-default"
                                        onClick={reAssignItem}
                                    >Re-Assign</button>
                            }
                        </div>
                    </Box>
                </DialogContent>
                <DialogActions>
                    {/* <Button id="button-cancel" className="mr-4 " onClick={handleClose} color="primary">
                        Close
                    </Button> */}
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ReassignModal;
