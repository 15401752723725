import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Endpoint from "services/Endpoint";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import { AgGridReact } from "ag-grid-react";
// import * as AllModules from "ag-grid-enterprise";
// import { ModuleRegistry } from "ag-grid-enterprise";
import { getSyncedOrganizations } from "../../../services/sync";
import { setOrgs } from "../../../redux/actions/sync";
import HookActions from "./HookActions";
import HookKiller from "./HookKiller";
import axios from "axios";
import { Alert } from "@material-ui/lab";
import Dialog from "@material-ui/core/Dialog";
import LinearProgress from "@material-ui/core/LinearProgress";
import EmptyState from "../../../components/EmptyState";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import styled from "@emotion/styled";
import Button from "@material-ui/core/Button";
import BackButton from "components/atoms/BackButton";
import "./HookManager.css";
import { toast } from "../../../helpers/apiRequests";
import { getMe } from "services/auth";

// ModuleRegistry.registerModules(Object.keys(AllModules));
const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const HookManager = ({ orgs, user, setOrgs }) => {
  const initialOrg = typeof orgs[0] !== "undefined" ? orgs[0].org_id : null;
  const [gridApi, setGridApi] = useState(null);
  // const [gridColumnApi, setGridColumnApi] = useState(null);
  const [org, setOrg] = useState(initialOrg);
  const classes = useStyles();
  const [hookType, setHookType] = useState("space");
  const [loading, setLoading] = useState(false);
  const [space_id, setSpaceId] = useState(null);
  const [spData, setSpData] = useState(null);
  const [app_id, setAppId] = useState(null);
  const [token, setToken] = useState(null);
  // const [allHooks, setAllHooks] = useState([]);

  // const [setApData] = useState(null);
  const [snack, setSnack] = React.useState({
    message: null,
    snackType: null,
    isSnack: false,
    reload: false,
  });

  const [state, setState] = useState({
    columns: [
      { title: "App", field: "app" },
      { title: "Created by", field: "created_by" },
      { title: "Hook Type", field: "type" },
      { title: "Id", field: "id" },
      { title: "Status", field: "status" },
      { title: "Url", field: "url" },
      {
        title: "Action",
        field: "actions",
        cellRenderer: "actionsRenderer",
      },
      {
        title: "Hook Killer",
        field: "hook-killer",
        cellRenderer: "hookKillerRenderer",
      },
    ],
    data: [],
    frameworkComponents: {
      actionsRenderer: HookActions,
      hookKillerRenderer: HookKiller,
    },
  });

  const [hookField, setHookField] = useState({
    columns: [
      { title: "App", field: "app" },
      { title: "Created by", field: "created_by" },
      { title: "Hook Type", field: "type" },
      { title: "Id", field: "id" },
      { title: "Status", field: "status" },
      { title: "Url", field: "url" },
      {
        title: "Action",
        field: "actions",
        cellRenderer: "actionsRenderer",
      },
      {
        title: "Hook Killer",
        field: "hook-killer",
        cellRenderer: "hookKillerRenderer",
      },
    ],
    data: [],
    frameworkComponents: {
      actionsRenderer: HookActions,
      hookKillerRenderer: HookKiller,
    },
  });

  const [space, setSpace] = useState({
    columns: [
      { title: "Created by", field: "created_by" },
      { title: "Hook Type", field: "type", rowGroup: true, hide: true },
      { title: "Id", field: "id" },
      { title: "Status", field: "status" },
      { title: "Url", field: "url" },
      { title: "Actions", field: "action" },
      {
        title: "Action",
        field: "actions",
        cellRenderer: "actionsRenderer",
      },
      {
        title: "Hook Killer",
        field: "hook-killer",
        cellRenderer: "hookKillerRenderer",
      },
    ],
    data: [],
    frameworkComponents: {
      actionsRenderer: HookActions,
      hookKillerRenderer: HookKiller,
    },
  });
  const [spacesArray, setspacesArray] = useState([]);
  const [appsArray, setappsArray] = useState([]);
  const frameworks = {
    actionsRenderer: HookActions,
    hookKillerRenderer: HookKiller,
  };

  const refreshTable = () => {
    generateHooks();

    if (hookType === "app" && org) {
      gridApi.refreshCells({
        force: true,
      });
    }

    if (hookType === "space" && org) {
      gridApi.refreshCells({
        force: true,
      });
    }

    if (hookType === "app_fields_hooks" && org) {
      gridApi.refreshCells({
        force: true,
      });
    }
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    // setGridColumnApi(params.columnApi);
  };

  const context = { HookManager: () => refreshTable() };

  useEffect(() => {
    getSyncedOrganizations().then((response) => setOrgs(response.data));
  }, [setOrgs]);

  useEffect(() => {});

  const getUserToken = React.useCallback(async () => {
    function getProfile() {
      getMe()
        .then((me) => {
          setToken(me.data.access_token);
        
        })
        .catch((e) => {
          toast.notify("An error occurred, try refreshing this page", {
            type: "error",
          });
        });
    }
    await getProfile();
  }, []);

  useEffect(() => {
    getUserToken();
  }, [getUserToken]);

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASEURL_V1}sync/spaces/${org}?database=${user.database.database}&skip=0&limit=1000&api_v2=true`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
  
        const hooksNameArray = data?.data?.map((item) => ({
          name: item.name,
          spaceId: item.space_id,
        }));
        setspacesArray(hooksNameArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    if (
      (hookType === "space" && org) ||
      (hookType === "app" && org) ||
      (hookType === "app_fields_hooks" && org)
    ) {
      // Avoid duplication
      setspacesArray([]);
      fetchData();
    }
  }, [org, hookType, user.database.database, token]);

  useEffect(() => {
    async function fetchAppData() {
      axios
        .get(
          `${process.env.REACT_APP_BASEURL_V1}sync/apps/${space_id}/${org}?database=${user.database.database}&skip=0&limit=1000&api_v2=true`
        )
        .then((res) => {
          res.data.data.map((values) => {
            setappsArray((items) => [
              ...items,
              { title: values.config.name, id: values.app_id },
            ]);
            return null;
          });
        });
    }

    if (
      (hookType === "app" && space_id) ||
      (hookType === "app_fields_hooks" && space_id)
    ) {
      setappsArray([]);
      fetchAppData();
    }
    //eslint-disable-next-line
  }, [space_id, hookType]);


  const generateHooks = () => {
    if (hookType === "space" && org) {
      setSpace({
        ...space,
        data: [],
      });

      if (space_id === null) {
        setSnack({
          message: "Please select a space",
          snackType: "error",
          isSnack: true,
          reload: false,
        });
        return;
      }

      setLoading(true);
      Endpoint.hooksUnderSingleSpace(org, user.database.database, space_id)
        .then((res) => {
          res.data.data.forEach((Ddata) => {
            const app_id = Ddata.app_id;

            delete Ddata.app_id;
            const theHooks = Object.keys(Ddata).map((i) => Ddata[i]);

            theHooks.forEach((hook) => {
              setSpace((prevState) => {
                const data = [...prevState.data];

                data.push({
                  space: app_id,
                  created_by: hook.created_by.type,
                  type: hook.type,
                  id: hook.hook_id,
                  status: hook.status,
                  url: hook.url,
                });
                return { ...prevState, data };
              });
            });
          });
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else if (hookType === "app" && org) {
      if (app_id === null) {
        setSnack({
          message: "Please select an app id",
          snackType: "error",
          isSnack: true,
          reload: false,
        });
        return;
      }

      setSpData(null);
      setLoading(true);
      setState({
        ...state,
        data: [],
      });
      Endpoint.hooksUnderSingleApp(org, user.database.database, app_id)
        .then((res) => {
          res.data.data.forEach((Ddata) => {
            const app_id = Ddata.app_id;

            delete Ddata.app_id;
            const theHooks = Object.keys(Ddata).map((i) => Ddata[i]);

            theHooks.forEach((hook) => {
              setState((prevState) => {
                const data = [...prevState.data];

                data.push({
                  app: app_id,
                  created_by: hook.created_by.type,
                  type: hook.type,
                  id: hook.hook_id,
                  status: hook.status,
                  url: hook.url,
                });
                return { ...prevState, data };
              });
            });
          });
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else if (hookType === "app_fields_hooks" && org) {
      if (app_id === null) {
        setSnack({
          message: "Please select an app id",
          snackType: "error",
          isSnack: true,
          reload: false,
        });
        return;
      }

      setSpData(null);
      setLoading(true);
      setState({
        ...state,
        data: [],
      });
      Endpoint.fieldHooksUnderSingleApp(org, user.database.database, app_id)
        .then((res) => {
          res.data.data.forEach((hook) => {
            setHookField((prevState) => {
              const data = [...prevState.data];

              data.push({
                app: app_id,
                created_by: hook.created_by.type,
                type: hook.type,
                id: hook.hook_id,
                status: hook.status,
                url: hook.url,
              });

              return { ...prevState, data };
            });
          });
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const defaultColDef = {
    flex: 1,
    minWidth: 100,
    filter: true,
    sortable: true,
    resizable: true,
  };
  const autoGroupColumnDef = { minWidth: 200 };

  const [grid_loading] = React.useState(
    "No data to display (Click on the generate button to fetch data)"
  );

  function handleClose() {
    setSnack({ message: "", snackType: "", isSnack: false, reload: false });
  }

  return (
    <HookManager.Wrapper>
      {orgs.length === 0 ? (
        <EmptyState />
      ) : (
        <React.Fragment>
          <div className="top-header">
            <div className="mb-3">
              <BackButton />
            </div>
            <div style={{ margin: "2% 0" }}>
              <h5 className="hookName" style={{ fontWeight: "600" }}>
                <span style={{ color: "#F0B429" }}>Audit</span> {`>`} Hooks
                Manager
              </h5>
            </div>
            <div style={{ marginBottom: "2%" }} className="form-holder">
              <FormControl className={classes.formControl + " formControlCont"}>
                <label>Organisation</label>
                {/* <InputLabel id="demo-simple-select-label">
                  Organisation
                </InputLabel> */}
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={org}
                  className="form-select-input"
                  onChange={(e) => setOrg(e.target.value)}
                  style={{
                    border: "1px solid #CFCFCF",
                    padding: "7px",
                    borderRadius: "5px",
                  }}
                >
                  {orgs.map((x) => {
                    return (
                      <MenuItem key={x.org_id} value={x.org_id}>
                        {x.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl + " formControlCont"}>
                <label>Hook Type</label>
                {/* <InputLabel id="demo-simple-select-label">Hook Type</InputLabel> */}
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={hookType}
                  className="form-select-input"
                  onChange={(e) => setHookType(e.target.value)}
                  style={{
                    border: "1px solid #CFCFCF",
                    padding: "7px",
                    borderRadius: "5px",
                  }}
                >
                  <MenuItem value="space">Space</MenuItem>
                  <MenuItem value="app">App</MenuItem>
                  <MenuItem value="app_fields_hooks">App Fields Hooks</MenuItem>
                </Select>
              </FormControl>

              {org && (
                <div>
                  <label>Space</label>
                  <select
                    className="form-select-text"
                    style={{
                      padding: "7px",
                      borderRadius: "5px",
                      height: "50px",
                      marginTop: "15px",
                      appearance: "auto",
                      border: "1px solid lightgrey",
                      backgroundColor: "white",
                    }}
                    name="spaces"
                    onChange={(e) => {
                      const selectedSpace = spacesArray.find(
                        (space) => space.name === e.target.value
                      );
                      if (selectedSpace) {
                        setSpaceId(selectedSpace.spaceId);
                        setSpData(selectedSpace);
                      }
                    }}
                  >
                    <option value="">Select a space</option>
                    {spacesArray?.map((space) => (
                      <option key={space.spaceId} value={space.name}>
                        {space.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {((org && hookType === "app" && space_id) ||
                (org && hookType === "app_fields_hooks" && space_id)) && (
                <div>
                  <label>Select App</label>
                  <select
                    className="form-select-text"
                    style={{
                      padding: "7px",
                      borderRadius: "5px",
                      height: "50px",
                      marginTop: "15px",
                      appearance: "auto",
                      border: "1px solid lightgrey",
                      backgroundColor: "white",
                    }}
                    name="spaces"
                    onChange={(e) => {
                      const selectedApp = appsArray.find(
                        (apps) => apps.title === e.target.value
                      );
                      if (selectedApp) {
                        setAppId(selectedApp.id);
                      }
                    }}
                  >
                    <option value="">Select a space</option>
                    {appsArray?.map((apps) => (
                      <option key={apps.id} value={apps.title}>
                        {apps.title}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {org &&
                (hookType === "space" ||
                  hookType === "app" ||
                  hookType === "app_fields_hooks") &&
                space_id && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => generateHooks()}
                    style={{ height: "50px", marginTop: "30px" }}
                  >
                    Generate
                  </Button>
                )}
            </div>
          </div>

          {spData && (
            <React.Fragment>
              <h4
                style={{ fontSize: "16px", margin: "2% 0", fontWeight: "600" }}
              >
                {spData.title}
              </h4>
            </React.Fragment>
          )}

          {loading ? (
            <LinearProgress />
          ) : (
            <div
              className="ag-theme-balham-dark"
              style={{
                height: "700px",
                width: "100%",
              }}
            >
              {hookType === "space" && org && (
                <AgGridReact
                  context={context}
                  // modules={Object.keys(AllModules)}
                  columnDefs={space.columns}
                  defaultColDef={defaultColDef}
                  autoGroupColumnDef={autoGroupColumnDef}
                  overlayNoRowsTemplate={grid_loading}
                  enableRangeSelection={true}
                  animateRows={true}
                  rowData={space.data}
                  sideBar={"filters"}
                  enableCharts={true}
                  rowGroupPanelShow="always"
                  rowSelection="multiple"
                  frameworkComponents={space.frameworkComponents}
                  onGridReady={onGridReady}
                />
              )}
              {hookType === "app" && org && (
                <AgGridReact
                  context={context}
                  // modules={Object.keys(AllModules)}
                  frameworks={frameworks}
                  columnDefs={state.columns}
                  defaultColDef={defaultColDef}
                  autoGroupColumnDef={autoGroupColumnDef}
                  frameworkComponents={state.frameworkComponents}
                  overlayNoRowsTemplate={grid_loading}
                  enableRangeSelection={true}
                  animateRows={true}
                  sideBar={"filters"}
                  enableCharts={true}
                  rowData={state.data}
                  rowGroupPanelShow="always"
                  rowSelection="multiple"
                  onGridReady={onGridReady}
                />
              )}
              {hookType === "app_fields_hooks" && org && (
                <AgGridReact
                  context={context}
                  // modules={Object.keys(AllModules)}
                  frameworks={frameworks}
                  columnDefs={hookField.columns}
                  defaultColDef={defaultColDef}
                  autoGroupColumnDef={autoGroupColumnDef}
                  frameworkComponents={hookField.frameworkComponents}
                  overlayNoRowsTemplate={grid_loading}
                  enableRangeSelection={true}
                  animateRows={true}
                  sideBar={"filters"}
                  enableCharts={true}
                  rowData={hookField.data}
                  rowGroupPanelShow="always"
                  rowSelection="multiple"
                  onGridReady={onGridReady}
                />
              )}
            </div>
          )}

          <Dialog
            open={snack.isSnack}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <Alert onClose={handleClose} severity={snack.snackType}>
              {snack.message}
            </Alert>
          </Dialog>
        </React.Fragment>
      )}
    </HookManager.Wrapper>
  );
};

HookManager.Wrapper = styled.div`
  .form-holder {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;
    button {
      height: 75%;
      align-self: center;
    }
  }
`;
const mapStateToProps = (state) => ({
  orgs: state.sync.orgs,
  user: state.user.authUser,
});

const mapDispatchToProps = (dispatch) => ({
  setOrgs: (data) => dispatch(setOrgs(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HookManager));
