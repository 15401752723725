import { defaultSingleObjectArrayState } from "../../utils/constants";
import { extractStatus, handleReduxAction } from "../../helpers/reduxHelpers";
import {
  GET_SOURCE_ORGANISATIONS,
  GET_SOURCE_SPACES,
  GET_SOURCE_APPS,
  GET_DEST_ORGANISATIONS,
  GET_DEST_SPACES,
  GET_SOURCE_WORKFLOWS
} from "../constants/clone";

const defaultPayload = {
  data: [],
  errors: [],
};

const initialState = {
  sourceOrganisations: defaultSingleObjectArrayState,
  sourceSpaces: defaultSingleObjectArrayState,
  sourceApps: defaultSingleObjectArrayState,
  destOrganisations: defaultSingleObjectArrayState,
  destSpaces: defaultSingleObjectArrayState,
  sourceWorkflows: defaultSingleObjectArrayState,
};

const cloneReducer = (
  state = initialState,
  { type, payload = defaultPayload }
) => {
  const status = extractStatus(type);

  switch (type) {
    case GET_SOURCE_ORGANISATIONS.request:
    case GET_SOURCE_ORGANISATIONS.success:
    case GET_SOURCE_ORGANISATIONS.error:
      return handleReduxAction(state, payload, status, "sourceOrganisations");
    case GET_SOURCE_SPACES.request:
    case GET_SOURCE_SPACES.success:
    case GET_SOURCE_SPACES.error:
      return handleReduxAction(state, payload, status, "sourceSpaces");
    case GET_SOURCE_APPS.request:
    case GET_SOURCE_APPS.success:
    case GET_SOURCE_APPS.error:
      return handleReduxAction(state, payload, status, "sourceApps");
    case GET_DEST_ORGANISATIONS.request:
    case GET_DEST_ORGANISATIONS.success:
    case GET_DEST_ORGANISATIONS.error:
      return handleReduxAction(state, payload, status, "destOrganisations");
    case GET_DEST_SPACES.request:
    case GET_DEST_SPACES.success:
    case GET_DEST_SPACES.error:
      return handleReduxAction(state, payload, status, "destSpaces");
    case GET_SOURCE_WORKFLOWS.request:
    case GET_SOURCE_WORKFLOWS.success:
    case GET_SOURCE_WORKFLOWS.error:
      return handleReduxAction(state, payload, status, "sourceWorkflows");
    default:
      return state;
  }
};

export default cloneReducer;