import React from "react";
import "./innovation.css";
import ontime from "../../Images/ontime.png";
import insight from "../../Images/insight.png";
import automate from "../../Images/automate.png";
import connections from "../../Images/connections.png";
import arrRight from "../../Images/arrow-right-hero.png";

export default function Innovation() {
  return (
    <div className="outer-cont">
      <div className="step-text-cont">
        <h2>
          An innovative approach to <span> backing up </span> and
          <span> synchronizing </span>
          your data
        </h2>
      </div>
      <div className="step-cont">
        <div className="step">
          <img src={insight} alt="insight" />
          <p>
            Leveraging webhooks for real-time data changes across all of your
            <a
              style={{ color: "#F2B11B", fontWeight: "600", marginLeft: "2px" }}
              href="https://docs.thatapp.io/components/"
             c
            >
              cloud platforms
            </a>{" "}
            ensures your data is never out of SYNC.
          </p>
        </div>
        <div className="step">
          <img src={connections} alt="insight" />
          <p>
            With{" "}
            <a
              style={{ color: "#F2B11B", fontWeight: "600", marginLeft: "2px" }}
              href="https://docs.thatapp.io/guides/"
              target="_blank"
              rel="noopener noreferrer"
            >
              integrations
            </a>{" "}
            and{" "}
            <a
              style={{ color: "#F2B11B", fontWeight: "600", marginLeft: "2px" }}
              href="https://docs.thatapp.io/components/"
              target="_blank"
              rel="noopener noreferrer"
            >
              connectors
            </a>{" "}
            to hundreds of platforms we can extract, transform, and load your
            data from one system to another with ease.
          </p>
        </div>
        <div className="step">
          <img src={ontime} alt="insight" />
          <p>
            Our systems operate with 99.9%+ uptime and are highly scalable and
            redundant
          </p>
        </div>
        <div className="step">
          <img src={automate} alt="insight" />
          <p>
            <a
              style={{ color: "#F2B11B", fontWeight: "600", marginLeft: "2px" }}
              href="https://docs.thatapp.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Automate
            </a>{" "}
            every aspect of your business and cut out time-intensive, repetitive
            tasks
          </p>
        </div>
      </div>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://docs.thatapp.io/components"
      >
        <button className="started-innovation-button">
          <p>See more</p>
          <img src={arrRight} alt="get sarted" />
        </button>
      </a>
    </div>
  );
}
