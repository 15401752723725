import React, { useState } from 'react';
import styled from '@emotion/styled';
import TextField from '@material-ui/core/TextField';
import styles from './AddTemplate.module.scss';
import { ThemedReactSelect } from '../components/ThemedReactSelect';
import useTemplateDetails from '../hooks/useTemplateDetails';
import useAsync from '../hooks/useAsync';
import { createTemplate, createTemplateFromDoc } from '../printUtils/requests';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import UploadTemplate from '../UploadTemplate/UploadTemplate';
import { Box } from '@material-ui/core';
import handleResponseError from 'utils/handleResponseError';

const useStyles = makeStyles(() => ({
  customTextField: {
    '& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#2684FF',
    },
  },
}));

const AddTemplate = () => {
  const classes = useStyles();
  const { organisations, spaces, apps } = useSelector((state) => state.print);
  const { status, execute } = useAsync(handleCreateTemplate, false);
  const { orgsData, spaceData, appData, selectedApplication, handleOrgs, handleApps, selectApp, selectedWorkspace } = useTemplateDetails();

  const [templateName, setTemplateName] = useState('');
  const [file, setFile] = React.useState('');

  function handleCreateTemplate(e) {
    e.preventDefault();
    if (file) {
      const formData = new FormData();
      formData.append('title', templateName);
      formData.append('platform', 'podio');
      formData.append('type', 'document');
      formData.append('app_id', selectedApplication);
      formData.append('template_file', file);

      return createTemplateFromDoc(formData).catch((err) => {
        handleResponseError(err.response);
        return Promise.reject(err);
      });
    } else {
      const payload = {
        title: templateName,
        platform: 'podio',
        type: 'document',
        app_id: selectedApplication,
      };

      return createTemplate(payload);
    }
  }

  const selectedWorkspaceValue = React.useMemo(() => {
    const val = spaceData?.find((i) => i.value === selectedWorkspace) ?? null;
    return val;
  }, [selectedWorkspace, spaceData]);

  const selectedApplicationValue = React.useMemo(() => {
    const val = appData?.find((i) => i.value === selectedApplication) ?? null;
    return val;
  }, [appData, selectedApplication]);

  return (
    <>
      <AddTemplate.Wrapper>
        <div className={styles.organization_container}>
          <label htmlFor='title' className={styles.label}>
            Template Title <span className='text-danger'>*</span>
          </label>
          <TextField
            variant='outlined'
            id='title'
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
            className={`${classes.customTextField} ${styles.text_field}`}
            required
          />
        </div>
        <div className={styles.inputs_container}>
          <div>
            <label htmlFor='organization' className={styles.label}>
              Organizations <span className='text-danger'>*</span>
            </label>
            <ThemedReactSelect
              required
              className={styles.text_field}
              name='organization'
              options={orgsData}
              onChange={(val) => handleOrgs({ target: val })}
              style={{ fontSize: '14px' }}
              isDisabled={!orgsData?.length > 0}
              isLoading={organisations.processing}
            />
          </div>
          <div>
            <label htmlFor='workspace' className={styles.label}>
              Workspaces <span className='text-danger'>*</span>
            </label>
            <ThemedReactSelect
              required
              className={styles.text_field}
              name='workspace'
              options={spaceData}
              onChange={(val) => handleApps({ target: val })}
              style={{ fontSize: '14px' }}
              isDisabled={!spaceData?.length > 0}
              isLoading={spaces.processing}
              value={selectedWorkspaceValue}
            />
          </div>
          <div>
            <label htmlFor='application' className={styles.label}>
              Apps <span className='text-danger'>*</span>
            </label>
            <ThemedReactSelect
              required
              className={styles.text_field}
              name='application'
              options={appData}
              onChange={(val) => selectApp({ target: val })}
              style={{ fontSize: '14px' }}
              isDisabled={!appData?.length > 0}
              isLoading={apps.processing}
              value={selectedApplicationValue}
            />
          </div>
        </div>
        <Box className='mt-5'>
          <hr />
          <UploadTemplate setFile={setFile} />
        </Box>

        <div className={styles.button_container}>
          <button
            className={`btn ${styles.create_template_btn}`}
            onClick={execute}
            disabled={status === 'pending' || !selectedApplication || !selectApp || !selectedWorkspace}>
            {status === 'pending' ? 'Creating Template...' : 'Create Template'}
          </button>
        </div>
      </AddTemplate.Wrapper>
    </>
  );
};

export default AddTemplate;

AddTemplate.Wrapper = styled.div`
  background: #fff;
  box-shadow: 0px 3px 18px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 48px;
  /* width: 80%;
  margin: auto; */
  .inputs:focus {
    border: 1px #cfcfcf solid !important;
    background: #f7f7f7 !important;
  }
  .text {
    width: 50%;
  }
  .row {
    margin-top: 50px;
  }
  .dropdown-input {
    width: 100%;
  }
  .button-container {
    padding-top: 88px;
    text-align: center;
  }
  .add-button {
    background: #4caf50;
    :hover {
      background-color: #4caf50;
    }
  }
`;
