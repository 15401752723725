import React, { useEffect } from "react";
import { useParams } from "react-router";
import { getTemplateDetails } from "../printUtils/requests";
import useAsync from "../hooks/useAsync";
import DraftContainer from "./Editor/DraftContainer";
import RenderByPermission from "components/RenderByPermission";
import PrintProvider, { usePrintData } from "../context/PrintProvider";

function mutateIncomingPageNumberPosition(val) {
  // val is either an array of either header, footer, both or null
  let pageNumberPosition;
  if (!val) {
    pageNumberPosition = "none";
  } else if (val.length === 2) {
    pageNumberPosition = "header and footer";
  } else {
    pageNumberPosition = val?.[0];
  }
  return pageNumberPosition;
}

export default function DraftMessageV2({
  htmlResponse,
  contents,
  setContents,
}) {
  return (
    <PrintProvider>
      <PrintEditor
        htmlResponse={htmlResponse}
        contents={contents}
        setContents={setContents}
      />
    </PrintProvider>
  );
}

const PrintEditor = ({ htmlResponse, contents, setContents }) => {
  const params = useParams();
  const { template, setTemplate, setDocumentFields, setTemplateFields } =
    usePrintData();


  function updatePrintDetails(val) {
    setTemplate(val);
  }

  const { execute, status } = useAsync(
    () => getTemplateDetails(params?.printId, updatePrintDetails),
    false
  );

  const editorContent = React.useMemo(() => {
    return template?.document?.get_pages ?? [];
  }, [template?.document?.get_pages]);

  React.useEffect(() => {
    if (params?.printId) {
      execute();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.printId]);

  const [headerAndFooterContent, setHeaderAndFooterContent] = React.useState({
    header: "",
    footer: "",
  });
  React.useEffect(() => {
    if (editorContent.length > 0) {
      setContents(() => {
        return editorContent.map((content) => ({
          body: content?.content ?? "",
        }));
      });

      setHeaderAndFooterContent(() => ({
        header: editorContent?.[0]?.header?.content ?? "",
        footer: editorContent?.[0]?.footer?.content ?? "",
      }));
    }
  }, [editorContent, setContents]);

  React.useEffect(() => {
    if (template) {
      setDocumentFields({
        marginTop: template?.document?.marginTop ?? "",
        marginBottom: template?.document?.marginBottom ?? "",
        marginLeft: template?.document?.marginLeft ?? "",
        marginRight: template?.document?.marginRight ?? "",
        orientation: template?.document?.orientation ?? "",
        size: template?.document?.size,
        pageNumberAlign: template?.document?.pageNumberAlign ?? "none",
        pageNumberPosition:
          mutateIncomingPageNumberPosition(
            template?.document?.pageNumberPosition
          ) ?? "none",
        headerHeight: template?.document?.headerHeight ?? 20,
        footerHeight: template?.document?.footerHeight ?? 20,
        value_when_empty: template?.value_when_empty ?? "",
        value_when_errored: template?.value_when_errored ?? "",
      });

      setTemplateFields({
        title: template?.title,
        file_title: template?.file_title ?? "",
        extension: template?.creator?.extension ?? "pdf",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template]);

  useEffect(() => {
    if (htmlResponse) {
      setContents([{ body: htmlResponse }]); // Set the HTML response to the body content
    }
  }, [htmlResponse, contents, setContents]);

  return (
    <RenderByPermission allowTeamMemberAccess={true}>
      <div>
        <main>
          {/* <LeftSideBar refreshTemplate={execute} /> */}
          <DraftContainer
            {...{
              contents,
              setContents,
              headerAndFooterContent,
              setHeaderAndFooterContent,
              getPrintDetailsStatus: status,
            }}
          />
        </main>
      </div>
    </RenderByPermission>
  );
};
