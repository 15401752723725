import React from 'react';
import styles from './UploadTemplate.module.css';

const UploadTemplate = ({ setFile }) => {
  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  return (
    <section className={styles.upload_wrapper}>
      <p>
        You can now upload your custom templates. Accepted formats are <b>DOC</b> and <b>DOCX</b>
      </p>
      <input type='file' placeholder='Upload Template' onChange={handleFileChange} required accept='.doc,.docx' />
    </section>
  );
};

export default UploadTemplate;
