import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
// icons
import logo from "../icons/logo.svg";
import menu_icon from "../icons/menu.svg";
// widgets
import Button from "../widgets/buttons/Button";
import Products from "../widgets/Products";
// style
import "./style/nav.scss";
import "./style/general.scss";

const Navigation = (e) => {
  const user = useSelector((state) => state.user.authUser);
  const history = useHistory();
  const [menu, setMenu] = useState(false);
  return (
    <section className="home_navigation">
      <div className="desktop">
        <div className="inner">
          <div>
            <img
              onClick={() => history.push("/")}
              src={logo}
              alt="ThatApp"
              className="logo link22"
            />
            {/* <Products text="Products" /> */}
            <span
              onClick={() => history.push("/integrations")}
              className="link22"
            >
              Integrations
            </span>
            <span onClick={() => history.push("/pricing")} className="link22">
              Pricing
            </span>
          </div>

          {user.isLoggedIn ? (
            <div>
              <span
                className={`link22 ${e.color}`}
                onClick={() => history.push("/service")}
              >
                Dashboard
              </span>
            </div>
          ) : (
            <div>
              <span
                className="link22"
                onClick={() => history.push("/register")}
              >
                <Button text="Get Started" styling={e.btnBg} />
              </span>

              <span
                className={`link22 ${e.color}`}
                onClick={() => history.push("/login")}
              >
                Login
              </span>
            </div>
          )}
        </div>
      </div>

      <div className="mobile">
        <div className="home_inner d-flex justify-content-between">
          <div>
            <Link className="link" to="/">
              <img src={logo} alt="ThatApp" className="logo" />
            </Link>
          </div>
          <div className="menu">
            <img src={menu_icon} alt="menu" onClick={() => setMenu(!menu)} />
          </div>
        </div>
        <div
          className={
            menu ? "menu-container menu-container-open" : "menu-container"
          }
        >
          <div className="background"></div>
          <div className="menu-list">
            <div
              className="close color-4 font-30"
              onClick={() => setMenu(!menu)}
            >
              &times;
            </div>
            <Products />

            <div onClick={() => history.push("/")}>
              <p>Home</p>
            </div>

            <div onClick={() => history.push("/integrations")}>
              <p>Integrations</p>
            </div>
            <div onClick={() => history.push("/pricing")}>
              <p>Pricing</p>
            </div>

            {/* <Link className="link" to='/thatapp' onClick={() => setMenu(!menu)}><div>Support</div></Link> */}

            <div onClick={() => history.push("/register")}>
              <Button text="Try for free" styling={e.btnBg} />
            </div>

            <div className="color-2" onClick={() => history.push("/login")}>
              Login
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Navigation;
