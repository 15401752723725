/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useCallback, useEffect } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import { AgGridReact } from "ag-grid-react";
import Endpoint from "../../../containers/UserInfo/Endpoint";
// import * as AllModules from "ag-grid-enterprise";
// import { ModuleRegistry } from "ag-grid-enterprise";
import OrphanGridAction from "./utility/OrphanGridAction";

// ModuleRegistry.registerModules(Object.keys(AllModules));
const OrphanGrid = (props) => {
  const filterParams = {
    filterOptions: ["contains", "notContains"],
    debounceMs: 0,
    caseSensitive: false,
    suppressAndOrCondition: true,
  };

  const [gridApi, setGridApi] = useState(null);
  const [initialRender, setInitialRender] = useState(false);

  function formatTitle(field) {
    return field.data.item_name;
  }
  function formatFieldId(field) {
    return (
      <span
        style={{ cursor: "pointer" }}
        onClick={() => openLink(field.data.link)}
      >
        {field.value}
      </span>
    );
  }

  function openLink(link) {
    window.open(link, "_blanck");
  }

  const [users] = useState({
    columns: [
      {
        headerName: "Subject",
        field: "Subject",
        filter: "agTextColumnFilter",
        filterParams,
      },
      {
        headerName: "From",
        field: "from",
        filter: "agTextColumnFilter",
        filterParams,
      },
      {
        headerName: "To",
        field: "recipient",
        filter: "agTextColumnFilter",
        filterParams,
      },
      {
        headerName: "Date",
        field: "Date",
      },
      {
        headerName: "Action",
        field: "actions",
        cellRenderer: "imitateAction",
      },
    ],
    data: [],
  });

  const defaultColDef = {
    flex: 1,
    minWidth: 100,
    filter: true,
    sortable: true,
    resizable: true,
  };
  const autoGroupColumnDef = { minWidth: 200 };
  const frameworks = {
    imitateAction: OrphanGridAction,
    // imitateAction: null
  };
  const paginationPageSize = 100;
  const cacheBlockSize = 100;
  const rowModelType = "serverSide";

  const ServerSideDatasource = () => {
    return {
      getRows: function (params) {
        let query = "";
        if (Object.entries(params.request.filterModel).length > 0) {
          let search_key = Object.keys(params.request.filterModel).length;
          if (search_key === 1) {
            search_key = Object.keys(params.request.filterModel)[0];
          } else {
            search_key = Object.keys(params.request.filterModel)[
              Object.keys(params.request.filterModel).length - 1
            ];
          }
          const filterData = params.request.filterModel[search_key];
          query = `${query}&search_word=${filterData.filter}&filter_field=${search_key}&filter=${filterData.type}`;
        }

        if (params.request.sortModel.length > 0) {
          query = `${query}&sort=${params.request.sortModel[0].sort}&sort_field=${params.request.sortModel[0].colId}`;
        }
        Endpoint.getOrphanEmails(
          params.request.startRow,
          paginationPageSize,
          query,
          props.orgId
        )
          .then((res) => {
            const response = res.data.data;
            setTimeout(function () {
              params.successCallback(response.emails, response.pagination.total);
            }, 200);
          })
          .catch((err) => {
            params.failCallback();
          });
      },
    };
  };

  const onGridReady = (params) => {
    const datasource = ServerSideDatasource();
    setGridApi(params.api);

    params.api.setServerSideDatasource(datasource);
    setInitialRender(true);
  };

  const refreshCache = () => {
    if (initialRender) {
      const datasource = ServerSideDatasource();
      gridApi.setServerSideDatasource(datasource);
    
      setInitialRender(true);
    }
  };
  useEffect(() => {
    refreshCache();
  }, [props.orgId]);

  return (
    <div className="pb-5">
      <div
        className="ag-theme-balham-dark"
        style={{
          height: "700px",
          width: "100%",
        }}
      >
        <AgGridReact
          // modules={Object.keys(AllModules)}
          columnDefs={users.columns}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          rowModelType={rowModelType}
          frameworkComponents={frameworks}
          enableRangeSelection={true}
          animateRows={true}
          // rowData={users.data}
          onGridReady={onGridReady}
          rowGroupPanelShow="always"
          rowSelection="multiple"
          pagination={true}
          paginationPageSize={paginationPageSize}
          cacheBlockSize={cacheBlockSize}
          debug={true}
          sideBar={true}
          enableCellTextSelection={true}
          suppressAggFuncInHeader={true}
          enableFilter={true}
          enableSorting={true}
          serverSideStoreType="partial"
        ></AgGridReact>
      </div>
    </div>
  );
};

export default OrphanGrid;
