/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import Axios from "axios";
// import generateUsername from "generate-username-from-email";
// import swal from "sweetalert";
import "./index.css";
import { useParams, useLocation } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextEditor from "./TextEditor";
import EmailContact from "./EmailContact";
import { toast } from "helpers/apiRequests";
import { CircularProgress } from "@material-ui/core";
import AlertModal from "../../../components/Tmail/components/Modals/AlertModal";
import SignatureListModal from "./components/SignatureListModal";
import Swal from "sweetalert2";
import thatappLogo from "../../../assets/icons/thatapplogo.svg";
import infoIcon from "../../../assets/icons/info.svg";
// import EditorsContainer from "components/templates/print-v2/PrintEditor/Editor/EditorsContainer";
import DraftEditorV2 from "../../../components/templates/print-v2/PrintEditor/DraftMessageV2";
// import PrintProvider, { usePrintData } from "../context/PrintProvider";
import { usePrintData } from "components/templates/print-v2/context/PrintProvider";
import DraftMessageV2 from "../../../components/templates/print-v2/PrintEditor/DraftMessageV2";

const BASE_URL = process.env.REACT_APP_BASEURL;

const TmailMessageBoxV2 = (props) => {
    // const signatures = useSelector((state) => state.tmail.signatures);
    const { itemId, uuid, app_id, unique_id } = useParams();

    const { search } = useLocation();
    const commentId = new URLSearchParams(search).get("commentId");
    const authId = new URLSearchParams(search).get("authId");
    const type = new URLSearchParams(search).get("type");

    const [bigData, setBigData] = useState(null);
    const [userData, setUserData] = useState(null);
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [messageHtml, setMessageHtml] = useState("");
    const [signatureOption, setSignatureOption] = useState("");
    const [appId, setAppId] = useState("");
    const [options, setOptions] = useState([]);
    const [defaultFrom, setDefaultFrom] = useState([]);
    const [printTemplates, setPrintTemplates] = useState([]);
    const [signatures, setSignatures] = useState([]);
    const [itemid_s, setItemId] = useState(null);
    const [selectedFrom, setSelectedFrom] = useState(null);
    const [selectedPrint, setSelectedPrint] = useState(null);
    const [preLoadItem, setPreLoadItem] = useState(null);
    const [response, setResponse] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [openSignature, setOpenSignature] = useState(false);
    const [toEmail, setToEmail] = useState([]);
    const [ccEmail, setCCEmail] = useState([]);
    const [toEmailDefault, setToEmailDefault] = useState([]);
    const [ccEmailDefault, setCCEmailDefault] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        name: userData ? userData.name : "",
        fromEmail: userData ? userData.email : "",
    });
    const [userId, setUserId] = useState("");

    const [selectedOption, setSelectedOption] = useState(null);
    const [showSelect, setShowSelect] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [showGenerate, setShowGenerate] = useState(false);
    const [generatingContent, setGeneratingContent] = useState(false);
    const [userMessage, setUserMessage] = useState(false);
    const [showUserMessage, setShowUserMessage] = useState(false);
    const [htmlResponse, setHtmlResponse] = useState(null);
    const [contentLoader, setContentLoader] = useState(false);

    const [contents, setContents] = React.useState([
        {
            body: "",
        },
    ]);

    const handleRadioChange = (e) => {
        setSelectedOption(e.target.value);
        setShowSelect(true);
    };
    const handleRadioClick = (e) => {
        if (selectedOption === e.target.value) {
            setSelectedOption(null);
            setShowSelect(false);
        } else {
            handleRadioChange(e);
        }
    };

    const [from, setFrom] = useState("");
    const closeModal = () => setOpenModal(false);
    const closeSignModal = () => {
        setSignatureOption("none");
        setOpenSignature(false);
    };

    const openFirstModal = () => {
        setOpenSignature(true);
    };

    // useEffect(() => {
    //     const url = `${BASE_URL}/tmail/composer/config`;
    //     const payload = {
    //         item_id: "2369644363",
    //         user_id: "4801",
    //         template_id: "9763a6a7-20eb-424f-974b-802494e77d74",
    //     };
    //     Axios.post(`${BASE_URL}/tmail/print/html`, payload)
    //         .then((response) => {
    //             setHtmlResponse(response.data.html.map((item) => item.content));
    //             setGeneratingContent(false);
    //             console.log(response);
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //             setGeneratingContent(false);
    //         });
    // });

    useEffect(() => {
        const url = `${BASE_URL}/tmail/composer/config`;
        const payload = {
            uuid,
            item_id: itemId,
            podio_user_id: authId,
            app_id,
            app_item_id: unique_id,
        };
        setLoading(true);
        Axios.post(url, payload)
            .then((res) => {
                setLoading(false);
                if (res.data.app_check === false) {
                    setLoading(false);
                    setOpenModal(true);
                }
                const response = res.data;
                const authToken = response.data.user.token;
                const theItemID = response.data.item_id;
                setUserId(response.data.user_id);
                setPreLoadItem(response.response.item_id);
                setBigData(response);
                setAppId(response.data.app_id);
                localStorage.removeItem("chosen_token");
                localStorage.removeItem("team_member_uuid");
                localStorage.setItem("chosen_token", authToken);
                localStorage.setItem("chosen_token_v2", authToken);
                const config = {
                    headers: {
                        Authorization: "Bearer " + authToken,
                    },
                };

                setSignatures(response?.data?.signatures);
                setDefaultFrom(response?.data?.default_emails);
                setPrintTemplates(response?.data?.print_templates);
                if (response?.data?.default_emails?.length === 0) {
                    const user = [
                        {
                            name: response.data.user.name,
                            email: response.data.user.email,
                        },
                    ];
                    setDefaultFrom(user);
                }

                setUserData(response.data.user);

                if (typeof itemId !== "undefined") {
                    const url = `${BASE_URL}/sync/podio/get/item/${itemId}`;
                    setLoading(true);
                    Axios.get(url, config)
                        .then((res) => {
                            const response = res.data;
                            let sub = res.data.response.title;
                            if (type === "forward") {
                                sub = `FWD: ${res.data.response.title}`;
                            }
                            if (type === "reply") {
                                sub = `RE: ${res.data.response.title}`;
                            }
                            setSubject(sub);
                            const savedEmails = res.data.data.saved_emails;

                            const optionDatas =
                                savedEmails?.length > 0
                                    ? savedEmails.map((saved) => {
                                          return {
                                              label: `${saved}`,
                                              value: saved,
                                          };
                                      })
                                    : [];
                            setOptions(optionDatas);
                            setLoading(false);
                            setResponse(response);
                        })
                        .catch((err) => {
                            setLoading(false);
                        });
                } else {
                    const url = `${BASE_URL}/sync/podio/get/item/${app_id}/${unique_id}`;
                    setLoading(true);
                    Axios.get(url, config)
                        .then((res) => {
                            let sub = res.data.response.title;
                            setItemId(res.data.response.item_id);
                            if (type === "forward") {
                                sub = `FWD: ${res.data.response.title}`;
                            }
                            if (type === "reply") {
                                sub = `RE: ${res.data.response.title}`;
                            }
                            setSubject(sub);
                            // setAppId(res.data.app_id)
                            setLoading(false);
                            if (!res.data.app_check) {
                                setOpenModal(true);
                            }
                            const response = res.data;
                            const savedEmails = res.data.data.saved_emails;

                            const optionDatas =
                                savedEmails?.length > 0
                                    ? savedEmails.map((saved) => {
                                          return {
                                              label: `${saved}`,
                                              value: saved,
                                          };
                                      })
                                    : [];
                            setOptions(optionDatas);
                            setResponse(response);
                        })
                        .catch((err) => {
                            setLoading(false);
                        });
                }

                if (commentId !== null) {
                    const url = `${BASE_URL}/sync/podio/get-tmail-comment/${commentId}`;
                    Axios.get(url, config)
                        .then((res) => {
                            const response = res.data.response;
                            let sub = response.subject;

                            const ccEmailData = JSON.parse(response.cc);
                            const toEmailData = JSON.parse(response.to);
                            if (ccEmailData?.length > 0) {
                                const selectCC = ccEmailData.map((data) => {
                                    return {
                                        value: data,
                                        label: `${data}`,
                                    };
                                });
                                setCCEmail(selectCC);
                                setCCEmailDefault(selectCC);
                            }

                            const selectTo = toEmailData.map((data) => {
                                return {
                                    value: data,
                                    label: `${data}<${data}>`,
                                };
                            });
                            setToEmail(selectTo);
                            setToEmailDefault(selectTo);

                            const date = dayjs(response.created_at).format(
                                "ddd, D MMM YYYY HH:mm:ss"
                            );

                            let originalMessage = `<br><br><br><br>`;
                            originalMessage += `<p>------ Original message ------</p>`;
                            originalMessage += `From: "${response.from_name}" (${response.from_email})`;
                            originalMessage += `<br>`;
                            originalMessage += `To: ${JSON.parse(response.to)}`;
                            originalMessage += `<br>`;
                            originalMessage += `CC: ${JSON.parse(response.cc)}`;
                            originalMessage += `<br>`;
                            originalMessage += `Date: ${date}`;
                            originalMessage += `<br>`;
                            originalMessage += `Subject: ${response.subject}`;
                            originalMessage += `${response.message}`;
                            originalMessage += `<br>`;

                            if (type === "forward") {
                                originalMessage = response.message;
                                sub = `FWD: ${response.subject}`;
                            }
                            if (type === "reply") {
                                sub = `RE: ${response.subject}`;
                            }
                            setSubject(sub);
                            // setMessage(originalMessage);

                            setLoading(false);
                        })
                        .catch((err) => {
                            setLoading(false);
                        });
                }
            })
            .catch((err) => {
                setLoading(false);
                // setOpenModal(true);
            });
    }, []);

    // const fromEmails =
    //     defaultFrom.length > 0
    //         ? defaultFrom.map((from) => (
    //               <option
    //                   key={from.email.toLowerCase()}
    //                   value={from.email.toLowerCase()}
    //               >
    //                   {from.email.toLowerCase()}
    //
    //               </option>
    //           ))
    //         : [];

    const [fromEmails, setFromEmails] = useState([]);

    useEffect(() => {
        if (defaultFrom?.length > 0) {
            const lowerCaseEmails = defaultFrom.map((from) => ({
                ...from,
                email: from.email.toLowerCase(),
            }));
            setFromEmails(lowerCaseEmails);
        } else {
            setFromEmails([]);
        }
    }, [defaultFrom]);

    const { name, fromEmail } = data;

    const handleFromChange = (e) => {
        const val = e.target.value;
        setFrom(val);
        const selected = defaultFrom.find((where) => where.email === val);
        setSelectedFrom(selected.name);
        setData({
            name: selected.name,
            fromEmail: selected.email,
        });
    };

    const handleTemplateChange = (event) => {
        const selectedTemplateId = event.target.value;
        setSelectedPrint(selectedTemplateId);
        if (selectedOption === "option2") {
            setShowGenerate(true);
        } else {
            setShowGenerate(false);
        }
    };

    const handleGeneratePrint = () => {
        if (selectedOption === "option2") {
            if (message) {
                setUserMessage(true);
            }
            setGeneratingContent(true);
            var id_ = typeof itemId != "undefined" ? itemId : itemid_s;
            const payload = {
                template_id: selectedPrint,
                item_id: id_,
                user_id: userId,
            };
            if (userMessage === true) {
                setShowUserMessage(true);
                setGeneratingContent(false);
            } else {
                Axios.post(`${BASE_URL}/tmail/print/html`, payload)
                    .then((response) => {
                        setHtmlResponse(
                            response.data.html.map((item) => item.content)
                        );
                        setGeneratingContent(false);
                    })
                    .catch((error) => {
                        console.error(error);
                        setGeneratingContent(false);
                    });
            }
        }
    };

    const htmlContent = htmlResponse?.[0];

    const handleGeneratePrintProceed = () => {
        if (selectedOption === "option2") {
            setGeneratingContent(true);
            var id_ = typeof itemId != "undefined" ? itemId : itemid_s;
            const payload = {
                template_id: selectedPrint,
                item_id: id_,
                user_id: userId,
            };
            Axios.post(`${BASE_URL}/tmail/print/html`, payload)
                .then((response) => {
                    setHtmlResponse(
                        response.data.html.map((item) => item.content)
                    );
                    setGeneratingContent(false);
                })
                .catch((error) => {
                    console.error(error);
                });
            setShowUserMessage(false);
        }
    };

    // const handlePrintTemplateChange = (e) => {
    //     setSelectedTemplate(e.target.value);
    //   };

    const subjectHandleChange = (e) => {
        setSubject(e.target.value);
    };

    const handleSignatureChange = (val) => {
        setSignatures(val);
    };

    const toChangehandler = (val) => {
        extractEmailValues(val);
        setToEmail(val);
    };
    const ccChangehandler = (val) => {
        setCCEmail(val);
    };

    const messageChangeHandler = (value) => {
        setMessage(value);
    };

    const handleFileChange = (e) => {
        const oldArr = [...attachments];
        const newArr = Array.from(e.target.files);
        const joinedArr = [...oldArr, ...newArr];
        setAttachments(joinedArr);
    };

    const addAttachment = (file) => {
        setAttachments((prevArr) => [...prevArr, ...[file]]);
    };

    const removeAttachment = (file) => {
        const files = attachments.filter((attach) => attach.name !== file.name);
        setAttachments(files);
    };

    const extractEmailValues = (emails) => {
        let list = [];
        emails.forEach((mail) => {
            list.push(mail.value);
        });
        return list;
    };

    const handleSubmit = async (e) => {
        setMessage(contents);
        const editorMessage = message[0]?.body;
        e.preventDefault();
        setContentLoader(true);
        if (
            name === "" ||
            fromEmail === "" ||
            toEmail?.length === 0 ||
            message === "" ||
            selectedPrint === ""
        ) {
            setContentLoader(false);
            toast.notify("Please fill required data", { type: "info" });
            return;
        }

        let originalMessage = ``;

        if (htmlContent) {
            originalMessage += `${message}`;
        } else {
            originalMessage += `${editorMessage}`;
        }
        originalMessage += `<br><br><br><br><br>`;
        originalMessage += `<small><i>The Following Data is Used for Identification Purposes. DO NOT DELETE.</i></small>`;
        originalMessage += `<br>`;

        if (response.data.item_id != null) {
            originalMessage += `<small><i>Record: ${response.data?.item_id}</i></small>`;
            originalMessage += `<br>`;
        }
        if (response.data.applicant != null) {
            originalMessage += `<small><i>Applicant: ${response.data?.applicant}</i></small>`;
            originalMessage += `<br>`;
        }

        if (response.data.full_email != null) {
            originalMessage += `<small><i>Email: ${response.data?.full_email}</i></small>`;
            originalMessage += `<br>`;
        }
        if (response.data.application_number != null) {
            originalMessage += `<small><i>Applicant Number: ${response.data?.application_number}</i></small>`;
            originalMessage += `<br>`;
        }

        const formData = new FormData();
        for (let i = 0; i < attachments?.length; i++) {
            formData.append(`attachments[]`, attachments[i]);
        }
        var id_ = typeof itemId != "undefined" ? itemId : itemid_s;
        formData.append(`itemId`, id_);
        formData.append(`email`, fromEmail);
        formData.append(`name`, name);
        formData.append(`print_template_id`, selectedPrint);
        formData.append(`toEmail`, JSON.stringify(toEmail));
        formData.append(`ccEmail`, JSON.stringify(ccEmail));
        formData.append(`subject`, subject);
        formData.append(`message`, originalMessage);
        formData.append(`commentId`, commentId != null ? commentId : "");
        formData.append(`type`, type != null ? type : "");
        try {
            const config = {
                headers: {
                    Authorization: "Bearer " + bigData.data.user.token,
                },
            };
            await Axios.post(
                `${BASE_URL}/sync/podio/tmail-send`,
                formData,
                config
            );
            setContentLoader(false);
            Swal.fire({
                title: "Success",
                // imageUrl:
                //     "https://t4.ftcdn.net/jpg/05/10/52/31/360_F_510523138_0c1lsboUsa9qvOSxdaOrQIYm2eAhjiGw.jpg",
                icon: "success",
                imageWidth: "75px",
                text: "Message Sent Successfully",
                buttonsStyling: "false",
                confirmButtonText: "Continue",
                confirmButtonColor: "#FCAF3B",
            }).then(() => {
                setTimeout(() => {
                    window.close();
                }, 3000);
            });
            // setAttachments([]);
            // localStorage.removeItem("chosen_token");
        } catch (error) {
            setContentLoader(false);
            toast.notify("Error Sending Mail", { type: "error" });
        }
    };

    const changeSignBox = (e) => {
        const val = e.target.value;
        if (val === "configure_signature") {
            setOpenSignature(true);
            setSignatureOption(val);
            return;
        }
        signatures.forEach((sign) => {
            if (sign.id == val) {
                let originalMessage = message;
                originalMessage += `<br><br><br><br>`;
                originalMessage += `<p>${sign.content}</p>`;
                setMessage(originalMessage);
                // messageChangeHandler(originalMessage)
            }
        });
        setSignatureOption(val);
    };
    const signatureOptions =
        signatures?.length > 0
            ? signatures.map((sign) => (
                  <option key={sign.id} value={sign.id}>
                      {sign.name}
                  </option>
              ))
            : null;
    // Todo: Signature feature
    const msg =
        "This app isn't connected to your Tmail account. Kindly login and connect this app.";
    const redirect = "Redirect to Dashboard";
    const redirectUrl = "/dashboard/tmail/apps";

    useEffect(() => {
        if (showUserMessage || generatingContent || contentLoader) {
            // Prevent scrolling
            document.body.style.overflow = "hidden";
            window.scrollTo(0, 0);
        } else {
            // Re-enable scrolling
            document.body.style.overflow = "auto";
        }

        // Clean-up in case the modal is unmounted while open
        return () => {
            document.body.style.overflow = "auto";
        };
    }, [showUserMessage, generatingContent]);

    return (
        <div className="auth">
            <AlertModal
                message={msg}
                openModal={openModal}
                handleClose={closeModal}
                url={redirectUrl}
                redirect={redirect}
            />
            <SignatureListModal
                openModal={openSignature}
                signatures={signatures}
                handleClose={closeSignModal}
                appId={appId}
                handleSignatureChange={handleSignatureChange}
                openFirstModal={openFirstModal}
            />

            {showUserMessage && (
                <div className="modal_bg">
                    <div className="modal_cont_conf">
                        <img src={infoIcon} alt="" />
                        <div>
                            <h4 className="mt-2">Confirmation</h4>
                            <p className="mt-2">
                                Kindly note that the content in your message box
                                is going to be overridden.{" "}
                            </p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-3">
                            <button
                                onClick={() => {
                                    setShowUserMessage(false);
                                }}
                                className="cancel_btn"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleGeneratePrintProceed}
                                className="generate_btn"
                            >
                                Proceed
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {generatingContent && (
                <div className="modal_bg">
                    <div className="modal_cont">
                        <img width="10%" src={infoIcon} alt="" />
                        <div
                            style={{ textAlign: "center", marginBottom: "5%" }}
                        >
                            <h5 className="mb-4">Generating Content</h5>
                            <CircularProgress color="info" size={20} />
                        </div>
                    </div>
                </div>
            )}

            {contentLoader && (
                <div className="modal_bg">
                    <div className="modal_cont">
                        <img width="10%" src={infoIcon} alt="" />
                        <div
                            style={{ textAlign: "center", marginBottom: "5%" }}
                        >
                            <h5 className="mb-4">Sending Message</h5>
                            <CircularProgress color="info" size={20} />
                        </div>
                    </div>
                </div>
            )}

            <div className="logo_cont">
                <img
                    className="logo_mobile"
                    width="10%"
                    src={thatappLogo}
                    alt=""
                />
            </div>

            {loading ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "800px",
                    }}
                >
                    <CircularProgress color="info" size={40} />
                </div>
            ) : (
                <div className="box-message">
                    <div className="input_field">
                        <h3 className="form_subject">Draft Message</h3>
                        <div className="mt-4" encType="multipart/form-data">
                            <div className="from_cont">
                                <div className="">
                                    <label
                                        style={{
                                            fontWeight: "normal",
                                            fontSize: "18px",
                                            marginTop: "5px",
                                        }}
                                    >
                                        From{" "}
                                        <span className="text-danger">*</span>
                                    </label>
                                </div>

                                <div className="formRow">
                                    <div className="">
                                        <select
                                            style={{
                                                textTransform: "lowercase",
                                                height: "50px",
                                                marginTop: "2%",
                                                border: "1px solid #E1E6ED",
                                                borderRadius: "5px",
                                            }}
                                            className="form-control"
                                            name="fromEmail"
                                            onChange={handleFromChange}
                                            required
                                        >
                                            <option>Select Email</option>
                                            {fromEmails.map((from) => (
                                                <option
                                                    style={{
                                                        textTransform:
                                                            "lowercase",
                                                    }}
                                                    key={from.email}
                                                    value={from.email}
                                                >
                                                    {from.email.toLowerCase()}
                                                </option>
                                            ))}
                                            {/* {fromEmails} */}
                                        </select>
                                    </div>

                                    <div className="">
                                        <input
                                            className="form-control"
                                            name="name"
                                            value={selectedFrom}
                                            required
                                            type="text"
                                            readOnly
                                            style={{
                                                height: "50px",
                                                margin: "2% 0",
                                                border: "1px solid #E1E6ED",
                                                borderRadius: "5px",
                                                backgroundColor: "#F3F3F3",
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="from_cont">
                                <div className="">
                                    <label
                                        style={{
                                            fontWeight: "normal",
                                            fontSize: "18px",
                                            marginTop: "5px",
                                        }}
                                    >
                                        To{" "}
                                        <span className="text-danger">*</span>
                                    </label>
                                </div>
                                <div className="mt-2">
                                    <EmailContact
                                        options={options}
                                        handleChange={toChangehandler}
                                        default={toEmailDefault}
                                    />
                                </div>
                            </div>

                            <div className="from_cont">
                                <div className="">
                                    <label
                                        style={{
                                            fontWeight: "normal",
                                            fontSize: "18px",
                                            marginTop: "5px",
                                        }}
                                    >
                                        CC
                                    </label>
                                </div>
                                <div className="mt-2">
                                    <EmailContact
                                        options={options}
                                        handleChange={ccChangehandler}
                                        default={ccEmailDefault}
                                    />
                                </div>
                            </div>

                            <div className="from_cont">
                                <div className="">
                                    <label
                                        style={{
                                            fontWeight: "normal",
                                            fontSize: "18px",
                                            marginTop: "5px",
                                        }}
                                    >
                                        Subject
                                    </label>
                                </div>
                                <div className="mt-2">
                                    <input
                                        className="form-control"
                                        name="subject"
                                        style={{
                                            height: "50px",
                                        }}
                                        value={subject}
                                        required
                                        type="text"
                                        onChange={subjectHandleChange}
                                    />
                                </div>
                            </div>

                            <div className="from_cont">
                                <div className="">
                                    <label
                                        style={{
                                            fontWeight: "normal",
                                            fontSize: "18px",
                                            marginTop: "5px",
                                        }}
                                    >
                                        Upload attachment
                                    </label>
                                </div>
                                <div className="mt-2">
                                    <input
                                        type="file"
                                        name="attachments"
                                        className="form-control"
                                        style={{
                                            height: "50px",
                                            paddingTop: "8px",
                                        }}
                                        multiple
                                        onChange={handleFileChange}
                                    />
                                </div>
                            </div>
                            {attachments?.length > 0 ? (
                                <div className="mb-4">
                                    {attachments.map((file) => {
                                        return (
                                            <ul
                                                className="list-group"
                                                key={file.name}
                                            >
                                                <li className="list-group-item text-muted mb-2">
                                                    {file.name}
                                                    <i
                                                        className="fas fa-window-close text-danger float-right ml-2"
                                                        onClick={() =>
                                                            removeAttachment(
                                                                file
                                                            )
                                                        }
                                                    ></i>
                                                </li>
                                            </ul>
                                        );
                                    })}
                                </div>
                            ) : null}

                            <div className="from_cont">
                                <div className="">
                                    <label
                                        style={{
                                            fontWeight: "semibold",
                                            fontSize: "18px",
                                            marginTop: "5px",
                                        }}
                                    >
                                        Print Template{" "}
                                    </label>
                                </div>
                                <div className="mt-2">
                                    <div>
                                        <p className="italics">
                                            Select an option
                                        </p>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center">
                                                <input
                                                    style={{
                                                        appearance: "auto",
                                                        width: "20px",
                                                        accentColor: "#F7C948",
                                                    }}
                                                    value="option1"
                                                    checked={
                                                        selectedOption ===
                                                        "option1"
                                                    }
                                                    onChange={handleRadioChange}
                                                    onClick={handleRadioClick}
                                                    type="radio"
                                                />
                                                <p className="ml-2">
                                                    Add dynamic attachment
                                                </p>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <input
                                                    style={{
                                                        appearance: "auto",
                                                        width: "20px",
                                                        accentColor: "#F7C948",
                                                    }}
                                                    value="option2"
                                                    onChange={handleRadioChange}
                                                    checked={
                                                        selectedOption ===
                                                        "option2"
                                                    }
                                                    onClick={handleRadioClick}
                                                    type="radio"
                                                />
                                                <p className="ml-2">
                                                    Add dynamic email template
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <select
                                        className="form-control"
                                        name="template"
                                        onChange={handleTemplateChange}
                                        required
                                        style={
                                            selectedOption === "option1"
                                                ? {
                                                      textTransform:
                                                          "lowercase",
                                                      height: "50px",
                                                      appearance: "auto",
                                                      border: "1px solid #E1E6ED",
                                                      borderRadius: "5px",
                                                  }
                                                : {
                                                      textTransform:
                                                          "capitalize",
                                                      height: "50px",
                                                      appearance: "auto",
                                                      border: "1px solid #E1E6ED",
                                                      borderRadius: "5px",
                                                  }
                                        }
                                    >
                                        <option>Select Template</option>
                                        {printTemplates?.data?.map((from) => (
                                            <option
                                                key={from.id}
                                                value={from.id}
                                            >
                                                {selectedOption === "option1"
                                                    ? `${from.title}.pdf`
                                                    : from.title}
                                            </option>
                                        ))}
                                        {/* {fromEmails} */}
                                    </select>

                                    {showGenerate && (
                                        <div className="mt-3">
                                            <button
                                                onClick={handleGeneratePrint}
                                                className="generate_btn"
                                            >
                                                Generate template
                                            </button>
                                        </div>
                                    )}

                                    <div className="mt-3 mb-5">
                                        <div className="mb-3">
                                            <label
                                                htmlFor="signature"
                                                style={{
                                                    fontWeight: "normal",
                                                    fontSize: "18px",
                                                    marginTop: "5px",
                                                }}
                                            >
                                                Signature
                                            </label>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center signature_mobile">
                                            <div>
                                                <div className="signature">
                                                    <select
                                                        onChange={changeSignBox}
                                                        name="signature"
                                                        value={signatureOption}
                                                        style={{
                                                            height: "50px",
                                                        }}
                                                        className="form-control"
                                                    >
                                                        <option value="none">
                                                            None
                                                        </option>
                                                        {signatureOptions}
                                                        <option value="configure_signature">
                                                            Configure Signatures
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>

                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                type="submit"
                                                style={{
                                                    width: "40%",
                                                    height: "50px",
                                                    backgroundColor: "#f0b429",
                                                }}
                                                onClick={handleSubmit}
                                                className="btn_mobile"
                                            >
                                                Send message
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="editor_cont">
                        <div className="">
                            <div
                                style={{ overflowY: "auto" }}
                                className="edit_cont"
                            >
                                <DraftMessageV2
                                    contents={contents}
                                    htmlResponse={htmlContent}
                                    setContents={setContents}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
export default TmailMessageBoxV2;
