import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import deleteIcon from "../../../assets/icons/delete.svg";
import cancel from "../../../assets/icons/cancel.svg";
import "./style.css";
import Axios from "../../../config";
import { toast } from "helpers/apiRequests";

const DeleteConnectorV2 = ({
  openModal,
  handleClose,
  connector,
  removeConnector,
}) => {
  const [loading, setLoading] = useState(false);

  const deleteMySqlDB = async () => {
    try {
      setLoading(true);
      const payload = { credential_ids: [connector] };
      const url = `/delete-user-db`;
      await Axios.post(url, payload);
      setLoading(false);
      removeConnector();
      handleClose();
      toast.notify(`Connector deleted successfully`);
    } catch (error) {
      setLoading(false);
      handleClose();
      toast.notify(error.message, { type: "error" });
    }
  };

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={handleClose}
        // hideBackdrop={true}
        disableBackdropClick={false}
        disableEscapeKeyDown={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="connector-modal"
      >
        <DialogContent>
          <div style={{ textAlign: "right", marginBottom: "3%" }}>
            <img src={cancel} alt="" onClick={handleClose} />
          </div>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div style={{ textAlign: "center", marginBottom: "3%" }}>
                <img alt="delete" src={deleteIcon} />
              </div>
              <Typography style={{ textAlign: "center" }} gutterBottom>
                You are about to delete your connection. <br /> Would you like
                to proceed?
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            {/* <Button
              id="button-cancel"
              disabled={loading}
              onClick={handleClose}
              color="primary"
            >
              No
            </Button> */}
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              <Button
                // id="button-save"
                onClick={deleteMySqlDB}
                color="primary"
                autoFocus
                style={{
                  backgroundColor: "#E12D39",
                  color: "white",
                  fontSize: "12px",
                  padding: "2% 15%",
                }}
              >
                Yes, Proceed
              </Button>
            )}
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DeleteConnectorV2;
