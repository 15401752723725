/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAllApps,
  configureUpdateApp,
  GetAllDefaultEmails,
  GetAllDomains,
  GetAllSpaceMembers,
  GetAllMembers,
  testAutoReplyEmail,
  saveAutoReplyEmail,
} from "../../../redux/actions/tmail";
import { useParams, useHistory } from "react-router";
import axios from "../../../config";
import { CircularProgress, makeStyles } from "@material-ui/core";
import { toastError, toast } from "helpers/apiRequests";
import EmailComments from "./EmailComments";
import GeneralTab from "./GeneralTab";
import ReplyLinkTab from "./ReplyLinkTab";
import DefaultEmails from "./DefaultEmails";
import MembersTab from "./MembersTab";
import AddDomainToApp from "./AddDomainToApp";
import AutoReply from "./AutoReply";
// import OrphanTab from './OrphanEmail';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: "30px",
    fontWeight: "600",
    marginBottom: "30px",
  },
  header: {
    fontSize: "24px",
    fontWeight: "600",
    marginBottom: "13px",
  },
  title: {
    fontSize: "16px",
    fontWeight: "500",
    marginBottom: "5px",
  },
}));

const Appseting = () => {
  const classes = useStyles();
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetAllApps());
    dispatch(GetAllDefaultEmails());
    dispatch(GetAllDomains());
    dispatch(GetAllMembers(params.appId));

    getConnectedApp();
  }, []);

  const [app, setApp] = useState(null);
  const [loading, setLoading] = useState(false);
  const [option, setOption] = useState("");
  const [uniqueOption, setUniqueOption] = useState("");
  const [fullOption, setFullOption] = useState("");
  const [fields, setFields] = useState({
    app_fields: [],
    subject_fields: [],
    email_fields: [],
    body_fields: [],
    calculation: [],
  });
  const [uniqueFields, setUniqueFields] = useState({
    app_fields: [],
    subject_fields: [],
    email_fields: [],
    body_fields: [],
    calculation: [],
  });
  const [fullNameFields, setFullNameFields] = useState({
    app_fields: [],
    subject_fields: [],
    email_fields: [],
    body_fields: [],
    calculation: [],
  });
  const [subject, setSubject] = useState("");
  const [replySubject, setReplySubject] = useState("");
  const [contactField, setContactField] = useState("");
  const [refApp, setRefApp] = useState("");
  const [uniqueRefApp, setUniqueRefApp] = useState("");
  const [fullRefApp, setFullRefApp] = useState("");
  const [fullContactField, setFullContactField] = useState("");
  const [uniqueContactField, setUniqueContactField] = useState("");
  const [isLoadingRefApp, setIsLoadingRefApp] = useState(false);
  const [orphLoading, setOrphLoading] = useState({
    unique: false,
    full: false,
  });
  const [saveLoading, setSaveLoading] = useState(false);
  const [checkOne, setCheckOne] = useState(false);
  const [checkTwo, setCheckTwo] = useState(false);
  const [checkThree, setCheckThree] = useState(false);
  const [compose, setCompose] = useState(true);
  const [commentField, setCommentField] = useState("");
  const defaultEmails = useSelector((state) => state.tmail.defaultEmails);
  const domains = useSelector((state) => state.tmail.domains);
  const spaceMembers = useSelector((state) => state.tmail.spaceMembers);
  const members = useSelector((state) => state.tmail.members);
  const [selectedDomain, setSelectedDomain] = useState("");
  // const [fromEmail, setFromEmail] = useState("");
  const [testEmail, setTestEmail] = useState("");
  // const [replyTo, setReplyTo] = useState("");
  // const [emailSubject, setEmailSubject] = useState("");
  // const [selectedStatus, setSelectedStatus] = useState("Active");
  // const [replyMessage, setReplyMessage] = useState("");

  useEffect(() => {
    if (app !== null) {
      dispatch(GetAllSpaceMembers(app.space_id));
    }
  }, [app]);

  const getConnectedApp = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `/tmail/connected/apps/${params.appId}?id=${params.appId}`
      );
      const res = response.data;
      setApp(response.data);
      getValueInRevserse(res.contact_settings);
      getUniqueValueInRevserse(res.recipient_unique_id);
      getFullValueInRevserse(res.recipient_full_name);
      setAllAppFieldSettings(res);
      getAllFields(res);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const setAllAppFieldSettings = (res) => {
    setContactField(res.contact_field ? res.contact_field.field_id : "");
    setRefApp(res.referenced_app ? res.referenced_app.app_id : "");
    setSubject(res.email_subjects ? res.email_subjects.field_id : "");
    setReplySubject(
      res.reply_link_subject ? res.reply_link_subject.field_id : "default"
    );
    setSubject(res.email_subjects ? res.email_subjects.field_id : "");
    setCommentField(
      res.email_comment_option ? res.email_comment_option : "full"
    );
    const boxOne = res?.silent_outgoing_comments === 0 ? false : true;
    const boxTwo = res?.disable_comment_attachment === 0 ? false : true;
    const boxThree = res?.add_attachments_to_items === "0" ? false : true;
    const boxFour = res?.add_compose_link_to_app === "0" ? false : true;
    setCheckOne(boxOne);
    setCheckTwo(boxTwo);
    setCheckThree(boxThree);
    setCompose(boxFour);
    setSelectedDomain(res.domain_id ? res.domain_id : "");
    setUniqueContactField(
      res.recipient_unique_id_contact_field
        ? res.recipient_unique_id_contact_field.field_id
        : ""
    );
    setFullContactField(
      res.recipient_full_name_contact_field
        ? res.recipient_full_name_contact_field.field_id
        : ""
    );
    setUniqueRefApp(
      res.recipient_unique_id_referenced_app
        ? res.recipient_unique_id_referenced_app.app_id
        : ""
    );
    setFullRefApp(
      res.recipient_full_name_referenced_app
        ? res.recipient_full_name_referenced_app.app_id
        : ""
    );
  };

  const changeIsLoadingRefApp = () => setIsLoadingRefApp(true);
  const changeIsLoadingSaveApp = () => setSaveLoading(false);

  const changeSubject = (val) => setSubject(val);
  const changeReplySubject = (val) => setReplySubject(val);

  const changeContactField = (val) => {
    setContactField(val);
  };
  const changeUniqueContactField = (val) => {
    setUniqueContactField(val);
  };
  const changeFullContactField = (val) => {
    setFullContactField(val);
  };

  const changeCommentField = (val) => setCommentField(val);
  const changeSelectedDomain = (val) => setSelectedDomain(val);

  const changeFromEmail = (val) => setFromEmail(val);
  const changeReplyTo = (val) => setReplyTo(val);
  const changeEmailSubject = (val) => setEmailSubject(val);
  const changeTestEmail = (val) => setTestEmail(val);
  const changeSelectedStatus = (val) => setSelectedStatus(val);
  const changeReplyMessage = (newContent) => {
    setReplyMessage(newContent);
  };

  const changeRefApp = (val) => {
    changeIsLoadingRefApp();
    getRefAppEmailFields(app, val);
    setRefApp(val);
    setIsLoadingRefApp(true);
  };
  const changeUniqueRefApp = (val) => {
    getUniqueRefAppEmailFields(app, val);
    setUniqueRefApp(val);
  };
  const changeFullRefApp = (val) => {
    getFullRefAppEmailFields(app, val);
    setFullRefApp(val);
  };

  const changeCheckOne = () => setCheckOne(!checkOne);
  const changeCheckTwo = () => setCheckTwo(!checkTwo);
  const changeCheckThree = () => setCheckThree(!checkThree);
  const changeCheckCompose = () => setCompose(!compose);

  const changeSelector = (val) => {
    setOption(val);
  };
  const changeUniqueSelector = (val) => {
    if (val === "three") {
      setUniqueFields({ ...fields, calculation: fields.calculation });
    }
    setUniqueOption(val);
  };
  const changeFullSelector = (val) => {
    if (val === "three") {
      setFullNameFields({ ...fields, calculation: fields.calculation });
    }
    setFullOption(val);
  };
  const [formFilled, setFormFilled] = useState(false);

  const getAllFields = async (app) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `/tmail/get/fields?app_id=${app.app_id}&org_id=${app.org_id}`
      );
      const data = response.data;
      setFields({
        app_fields: data ? data.app_fields : [],
        email_fields: data ? data.email_fields : [],
        subject_fields: data ? data.subject_fields : [],
        body_fields: data ? data.body_fields : [],
        calculation: data ? data.text_calculation_fields : [],
      });
      setUniqueFields({
        app_fields: data ? data.app_fields : [],
        email_fields: data ? data.email_fields : [],
        subject_fields: data ? data.subject_fields : [],
        body_fields: data ? data.body_fields : [],
        calculation: data ? data.text_calculation_fields : [],
      });
      setFullNameFields({
        app_fields: data ? data.app_fields : [],
        email_fields: data ? data.email_fields : [],
        subject_fields: data ? data.subject_fields : [],
        body_fields: data ? data.body_fields : [],
        calculation: data ? data.text_calculation_fields : [],
      });

      if (
        app.recipient_unique_id === "text_calculation_field_in_referenced_app"
      ) {
        const appId = app?.recipient_unique_id_referenced_app?.app_id;
        setOrphLoading({
          ...orphLoading,
          unique: true,
        });
        const response = await axios.get(
          `/tmail/get/fields?app_id=${appId}&org_id=${app.org_id}`
        );
        const dataTwo = response.data;
        setUniqueFields({
          app_fields: data ? data.app_fields : [],
          email_fields: data ? data.email_fields : [],
          subject_fields: data ? data.subject_fields : [],
          body_fields: data ? data.body_fields : [],
          calculation: dataTwo ? dataTwo.text_calculation_fields : [],
        });
        setOrphLoading({
          ...orphLoading,
          unique: false,
        });
      }
      if (
        app.recipient_full_name === "text_calculation_field_in_referenced_app"
      ) {
        const appId = app?.recipient_full_name_referenced_app?.app_id;
        setOrphLoading({
          ...orphLoading,
          full: true,
        });
        const response = await axios.get(
          `/tmail/get/fields?app_id=${appId}&org_id=${app.org_id}`
        );
        const dataThree = response.data;
        setFullNameFields({
          app_fields: data ? data.app_fields : [],
          email_fields: data ? data.email_fields : [],
          subject_fields: data ? data.subject_fields : [],
          body_fields: data ? data.body_fields : [],
          calculation: dataThree ? dataThree.text_calculation_fields : [],
        });
        setOrphLoading({
          ...orphLoading,
          full: false,
        });
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.notify("An Error Occured: " + error, { type: "error" });
    }
  };

  const getRefAppEmailFields = async (app, appId) => {
    try {
      setIsLoadingRefApp(true);
      const response = await axios.get(
        `/tmail/get/fields?app_id=${appId}&org_id=${app.org_id}`
      );
      const data = response.data;
      setContactField("");
      setFields({ ...fields, email_fields: data ? data.email_fields : [] });
      setIsLoadingRefApp(false);
    } catch (error) {
      setIsLoadingRefApp(false);
      toast.notify("An Error Occured: " + error, { type: "error" });
    }
  };
  const getUniqueRefAppEmailFields = async (app, appId) => {
    try {
      setOrphLoading({
        ...orphLoading,
        unique: true,
      });
      const response = await axios.get(
        `/tmail/get/fields?app_id=${appId}&org_id=${app.org_id}`
      );
      const data = response.data;
      setUniqueFields({
        ...uniqueFields,
        calculation: data ? data.text_calculation_fields : [],
      });
      setOrphLoading({
        ...orphLoading,
        unique: false,
      });
    } catch (error) {
      setOrphLoading({
        ...orphLoading,
        unique: false,
      });
      toast.notify("An Error Occured: " + error, { type: "error" });
    }
  };
  const getFullRefAppEmailFields = async (app, appId) => {
    try {
      setOrphLoading({
        ...orphLoading,
        full: true,
      });
      const response = await axios.get(
        `/tmail/get/fields?app_id=${appId}&org_id=${app.org_id}`
      );
      const data = response.data;
      // setFullContactField("")
      setFullNameFields({
        ...fullNameFields,
        calculation: data ? data.text_calculation_fields : [],
      });
      setOrphLoading({
        ...orphLoading,
        full: false,
      });
    } catch (error) {
      setOrphLoading({
        ...orphLoading,
        full: false,
      });
      toast.notify("An Error Occured: " + error, { type: "error" });
    }
  };

  const subJectOptions =
    fields.subject_fields.length === 0
      ? null
      : fields.subject_fields.map((subFd) => (
          <option key={subFd.id} value={subFd.id}>
            {subFd.name}
          </option>
        ));

  const EmailOptions =
    fields.email_fields.length === 0
      ? null
      : fields.email_fields.map((subFd) => (
          <option key={subFd.id} value={subFd.id}>
            {subFd.name}
          </option>
        ));
  // const BodyOptions = fields.body_fields === 0 ? null : fields.body_fields.map(subFd => (
  //     <option key={subFd.id} value={subFd.id}>{subFd.name}</option>
  // ));

  const AppOptions =
    fields.app_fields.length === 0
      ? null
      : fields.app_fields.map((subFd) => (
          <option key={subFd.app_id} value={subFd.app_id}>
            {subFd.name}
          </option>
        ));

  const getValue = (option) => {
    let result;
    if (option === "one") {
      result = "manually";
      setSubject("");
      setRefApp("");
      setContactField("");
    } else if (option === "two") {
      result = "workspace_contacts";
      setRefApp("");
    } else if (option === "three") {
      result = "text_email_field_in_app";
      setRefApp("");
    } else if (option === "four") {
      result = "text_email_field_in_referenced_app";
    }
    return result;
  };

  const getUniqueValue = (option) => {
    let result;
    if (option === "one") {
      result = "manually";
      setUniqueRefApp("");
      setUniqueContactField("");
    } else if (option === "two") {
      result = "workspace_contacts";
      setUniqueRefApp("");
    } else if (option === "three") {
      result = "text_calculation_field_in_app";
      setUniqueRefApp("");
    } else if (option === "four") {
      result = "text_calculation_field_in_referenced_app";
    }
    return result;
  };

  const getFullValue = (option) => {
    let result;
    if (option === "one") {
      result = "manually";
      setFullRefApp("");
      setFullContactField("");
    } else if (option === "two") {
      result = "workspace_contacts";
      setFullRefApp("");
    } else if (option === "three") {
      result = "text_calculation_field_in_app";
      setFullRefApp("");
    } else if (option === "four") {
      result = "text_calculation_field_in_referenced_app";
    }
    return result;
  };

  const getValueInRevserse = (option) => {
    if (option === "manually") {
      setOption("one");
    } else if (option === "workspace_contacts") {
      setOption("two");
    } else if (option === "text_email_field_in_app") {
      setOption("three");
    } else if (option === "text_email_field_in_referenced_app") {
      setOption("four");
    }
  };

  const getUniqueValueInRevserse = (option) => {
    if (option === "manually") {
      setUniqueOption("one");
    } else if (option === "workspace_contacts") {
      setUniqueOption("two");
    } else if (option === "text_calculation_field_in_app") {
      setUniqueOption("three");
    } else if (option === "text_calculation_field_in_referenced_app") {
      setUniqueOption("four");
    }
  };

  const getFullValueInRevserse = (option) => {
    if (option === "manually") {
      setFullOption("one");
    } else if (option === "workspace_contacts") {
      setFullOption("two");
    } else if (option === "text_calculation_field_in_app") {
      setFullOption("three");
    } else if (option === "text_calculation_field_in_referenced_app") {
      setFullOption("four");
    }
  };

  const handleSubmit = (e) => {
    try {
      e.preventDefault();
      setSaveLoading(true);
      const optionValue = getValue(option);
      const uniqueOptionValue = getUniqueValue(uniqueOption);
      const fullOptionValue = getFullValue(fullOption);

      if (option === "") {
        toast.notify("Please Select an option", { type: "info" });
        setSaveLoading(false);
        return;
      } else if (option === "three") {
        if (contactField === "") {
          toast.notify("Please Select an option", { type: "info" });
          setSaveLoading(false);
          return;
        }
      } else if (option === "four") {
        if (contactField === "" || refApp === "") {
          toast.notify("Please Select an option", { type: "info" });
          setSaveLoading(false);
          return;
        }
      }
      let appFieldData;
      let contactFieldData;
      let subjectData;
      if (refApp !== "") {
        appFieldData =
          fields.app_fields.length === 0
            ? null
            : fields.app_fields.find((fid) => fid.app_id === Number(refApp));
        appFieldData.field_name = appFieldData.name;
      }
      if (contactField !== "") {
        contactFieldData =
          fields.email_fields.length === 0
            ? null
            : fields.email_fields.find(
                (fid) => fid.id === Number(contactField)
              );
        contactFieldData.field_id = contactFieldData.id;
        contactFieldData.field_name = contactFieldData.name;
      }
      if (subject !== "") {
        subjectData =
          fields.subject_fields.length === 0
            ? null
            : fields.subject_fields.find((fid) => fid.id === Number(subject));
        subjectData.field_id = subjectData.id;
        subjectData.field_name = subjectData.name;
      }

      let uniqueAppFieldData;
      let uniqueContactFieldData;
      if (uniqueRefApp !== "") {
        uniqueAppFieldData =
          uniqueFields.app_fields.length === 0
            ? null
            : uniqueFields.app_fields.find(
                (fid) => fid.app_id === Number(uniqueRefApp)
              );
        uniqueAppFieldData.field_name = uniqueAppFieldData.name;
      }
      if (uniqueContactField !== "") {
        uniqueContactFieldData =
          uniqueFields.calculation.length === 0
            ? null
            : uniqueFields.calculation.find(
                (fid) => fid.id === Number(uniqueContactField)
              );
        uniqueContactFieldData.field_id = uniqueContactFieldData.id;
        uniqueContactFieldData.field_name = uniqueContactFieldData.name;
      }
      let fullAppFieldData;
      let fullContactFieldData;
      if (fullRefApp !== "") {
        fullAppFieldData =
          fullNameFields.app_fields.length === 0
            ? null
            : fullNameFields.app_fields.find(
                (fid) => fid.app_id === Number(fullRefApp)
              );
        fullAppFieldData.field_name = fullAppFieldData.name;
      }
      if (fullContactField !== "") {
        fullContactFieldData =
          fullNameFields.calculation.length === 0
            ? null
            : fullNameFields.calculation.find(
                (fid) => fid.id === Number(fullContactField)
              );
        fullContactFieldData.field_id = fullContactFieldData.id;
        fullContactFieldData.field_name = fullContactFieldData.name;
      }

      const data = {
        id: params.appId,
        email_subjects: subjectData,
        referenced_app: appFieldData,
        contact_settings: optionValue,
        contact_field: contactFieldData,
        app_id: app.app_id,
        app_name: app.app_name,
        space_name: app.space_name,
        space_id: app.space_id,
        org_id: app.org_id,
        recipient_unique_id: uniqueOptionValue,
        recipient_unique_id_referenced_app: uniqueAppFieldData,
        recipient_unique_id_contact_field: uniqueContactFieldData,
        recipient_full_name: fullOptionValue,
        recipient_full_name_referenced_app: fullAppFieldData,
        recipient_full_name_contact_field: fullContactFieldData,
      };
      dispatch(configureUpdateApp(data, changeIsLoadingSaveApp));
    } catch (error) {
      setSaveLoading(false);
    }
  };

  const goBack = () => {
    history.push("/dashboard/tmail/apps");
  };

  const updateEmailComment = (e) => {
    try {
      e.preventDefault();
      setSaveLoading(true);
      const payload = {
        id: params.appId,
        app_id: app.app_id,
        app_name: app.app_name,
        space_name: app.space_name,
        space_id: app.space_id,
        org_id: app.org_id,
        email_comment_option: commentField,
        silent_outgoing_comments: checkOne,
        disable_comment_attachment: checkTwo,
        add_attachments_to_items: checkThree,
      };
      dispatch(configureUpdateApp(payload, changeIsLoadingSaveApp));
    } catch (error) {
      setSaveLoading(false);
    }
  };

  const addDomainField = (e) => {
    try {
      e.preventDefault();
      setSaveLoading(true);
      const payload = {
        id: params.appId,
        app_id: app.app_id,
        app_name: app.app_name,
        space_name: app.space_name,
        space_id: app.space_id,
        org_id: app.org_id,
        domain_id: selectedDomain,
      };
      dispatch(configureUpdateApp(payload, changeIsLoadingSaveApp));
    } catch (error) {
      setSaveLoading(false);
    }
  };
  // const editorRef = useRef(null);

  // const editorContent = editorRef.current.getContent();

  // changeReplyMessage(editorContent);

  const saveAutoReply = (e) => {
    try {
      e.preventDefault();
      //setSaveLoading to true for 5 seconds

      setSaveLoading(true);
      setTimeout(() => {
        setSaveLoading(false);
      }, 3000);
      const payload = {
        id: params.appId,
        from: fromEmail,
        reply_to: replyTo,
        subject: emailSubject,
        status: selectedStatus,
        message: replyMessage,
      };
      if (!fromEmail || !selectedStatus) {
        toastError.notify("Please fill the required fields");
        return;
      }
      setFormFilled(true);
      dispatch(saveAutoReplyEmail(payload, changeIsLoadingSaveApp));
    } catch (error) {
      setSaveLoading(false);
    }
  };

  const useLocalStorage = (key, initialValue) => {
    const [storedValue, setStoredValue] = useState(() => {
      try {
        const item = window.localStorage.getItem(key);
        return item ? JSON.parse(item) : initialValue;
      } catch (error) {
        console.log(error);
        return initialValue;
      }
    });

    const setValue = (value) => {
      try {
        window.localStorage.setItem(key, JSON.stringify(value));
        setStoredValue(value);
      } catch (error) {
        console.log(error);
      }
    };

    return [storedValue, setValue];
  };

  const [fromEmail, setFromEmail] = useLocalStorage("fromEmail", "");
  const [replyTo, setReplyTo] = useLocalStorage("replyTo", "");
  const [emailSubject, setEmailSubject] = useLocalStorage("emailSubject", "");
  const [selectedStatus, setSelectedStatus] = useLocalStorage(
    "selectedStatus",
    "Active"
  );
  const [replyMessage, setReplyMessage] = useLocalStorage("replyMessage", "");

  useEffect(() => {
    setFromEmail(fromEmail);
    setReplyTo(replyTo);
    setEmailSubject(emailSubject);
    setSelectedStatus(selectedStatus);
    setReplyMessage(replyMessage);
  }, []);

  const [openAlert, setOpenAlert] = useState(false);

  const handleClickOpen = (event) => {
    event.preventDefault();
    setOpenAlert(true);
  };
  const closeAlert = () => {
    setOpenAlert(false);
  };

  const testAutoReply = (e) => {
    try {
      e.preventDefault();
      setSaveLoading(true);
      const payload = {
        id: params.appId,
        email: testEmail,
      };
      closeAlert();
      dispatch(testAutoReplyEmail(payload, changeIsLoadingSaveApp));
    } catch (error) {
      setSaveLoading(false);
    }
  };

  const updateEmailReply = (e) => {
    try {
      e.preventDefault();
      setSaveLoading(true);
      let subjectData = replySubject;
      if (replySubject !== "default") {
        subjectData =
          fields.subject_fields.length === 0
            ? null
            : fields.subject_fields.find(
                (fid) => fid.id === Number(replySubject)
              );
        subjectData.field_id = subjectData.id;
        subjectData.field_name = subjectData.name;
      }
      // if (body !== "") {
      //     bodyData = fields.subject_fields.length === 0 ? null : fields.body_fields.find(fid => fid.id === Number(body));
      //     bodyData.field_id = bodyData.id;
      //     bodyData.field_name = bodyData.name;
      // }
      const payload = {
        id: params.appId,
        app_id: app.app_id,
        app_name: app.app_name,
        space_name: app.space_name,
        space_id: app.space_id,
        org_id: app.org_id,
        reply_link_subject: subjectData,
        add_compose_link_to_app: compose,
      };
      dispatch(configureUpdateApp(payload, changeIsLoadingSaveApp));
    } catch (error) {
      setSaveLoading(false);
    }
  };

  if (loading) {
    return (
      <center style={{ marginTop: "30%" }}>
        <CircularProgress size={50} />
      </center>
    );
  }
  return (
    <div>
      <h4 className="mt-3 mb-5" style={{ fontWeight: "600" }}>
        <span style={{ color: "#F0B429" }}>Connected Podio Apps</span> {`>`}{" "}
        Configure App {!app ? null : app.app_name}{" "}
      </h4>

      <div>
        <ul
          style={{ backgroundColor: "#EBEFF2", width: "fit-content" }}
          className="nav nav-tabs center"
          id="myTab2"
          role="tablist"
        >
          <li className="nav-item">
            <a
              className="nav-link active pt-3 pb-3"
              style={{
                color: "rgb(52 49 42)",
                fontWeight: "bolder",
                padding: ".5rem 1.5rem",
                width: "136px",
                height: "60px",
                textAlign: "center",
              }}
              id="home-tab2"
              data-toggle="tab"
              href="#home2"
              role="tab"
              aria-controls="home"
              aria-selected="true"
            >
              General
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link pt-3 pb-3"
              style={{
                color: "rgb(52 49 42)",
                fontWeight: "bolder",
                padding: ".5rem 1.5rem",
                width: "178px",
                height: "60px",
                textAlign: "center",
              }}
              id="comment-tab"
              data-toggle="tab"
              href="#comment"
              role="tab"
              aria-controls="comment"
              aria-selected="false"
            >
              Email Comments
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link pt-3 pb-3"
              style={{
                color: "rgb(52 49 42)",
                fontWeight: "bolder",
                padding: ".5rem 1.5rem",
                width: "136px",
                height: "60px",
                textAlign: "center",
              }}
              id="reply-tab"
              data-toggle="tab"
              href="#reply"
              role="tab"
              aria-controls="reply"
              aria-selected="false"
            >
              Reply Links
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link pt-3 pb-3"
              style={{
                color: "rgb(52 49 42)",
                fontWeight: "bolder",
                padding: ".5rem 1.5rem",
                width: "158px",
                height: "60px",
                textAlign: "center",
              }}
              id="bcc-tab"
              data-toggle="tab"
              href="#bcc"
              role="tab"
              aria-controls="bcc"
              aria-selected="false"
            >
              Emails Sender
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link pt-3 pb-3"
              style={{
                color: "rgb(52 49 42)",
                fontWeight: "bolder",
                padding: ".5rem 1.5rem",
                width: "136px",
                height: "60px",
                textAlign: "center",
              }}
              id="automate-tab"
              data-toggle="tab"
              href="#automate"
              role="tab"
              aria-controls="automate"
              aria-selected="false"
            >
              Members
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link pt-3 pb-3"
              style={{
                color: "rgb(52 49 42)",
                fontWeight: "bolder",
                padding: ".5rem 1.5rem",
                width: "136px",
                height: "60px",
                textAlign: "center",
              }}
              id="advanced-tab"
              data-toggle="tab"
              href="#advanced"
              role="tab"
              aria-controls="advanced"
              aria-selected="false"
            >
              Domain
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link pt-3 pb-3"
              style={{
                color: "rgb(52 49 42)",
                fontWeight: "bolder",
                padding: ".5rem 1.5rem",
                width: "136px",
                height: "60px",
                textAlign: "center",
              }}
              id="auto-reply"
              data-toggle="tab"
              href="#autoReply"
              role="tab"
              aria-controls="advanced"
              aria-selected="false"
            >
              Auto Reply
            </a>
          </li>
          {/* <li className="nav-item">
                        <a className="nav-link" style={{ color: "rgb(52 49 42)", fontWeight: "bolder" }} id="orphan-tab" data-toggle="tab" href="#orphan" role="tab"
                            aria-controls="orphan" aria-selected="false">Orphan</a>
                    </li> */}
        </ul>
      </div>
      <div
        className="tab-content tab-bordered"
        id="myTab3Content"
        style={{ background: "#fff", height: "fit-content" }}
      >
        <div
          className="tab-pane fade show active"
          id="home2"
          role="tabpanel"
          aria-labelledby="home-tab2"
        >
          <GeneralTab
            option={option}
            changeContactField={changeContactField}
            changeRefApp={changeRefApp}
            AppOptions={AppOptions}
            subJectOptions={subJectOptions}
            changeSelector={changeSelector}
            app={app}
            EmailOptions={EmailOptions}
            changeSubject={changeSubject}
            isLoadingRefApp={isLoadingRefApp}
            handleSubmit={handleSubmit}
            goBack={goBack}
            saveLoading={saveLoading}
            subject={subject}
            refApp={refApp}
            // InAppSetting={false}
            contactField={contactField}
            uniqueFields={uniqueFields}
            fullNameFields={fullNameFields}
            uniqueOption={uniqueOption}
            fullOption={fullOption}
            changeUniqueSelector={changeUniqueSelector}
            changeFullSelector={changeFullSelector}
            changeUniqueRefApp={changeUniqueRefApp}
            changeFullRefApp={changeFullRefApp}
            orphLoading={orphLoading}
            uniqueRefApp={uniqueRefApp}
            fullRefApp={fullRefApp}
            fullContactField={fullContactField}
            uniqueContactField={uniqueContactField}
            changeUniqueContactField={changeUniqueContactField}
            changeFullContactField={changeFullContactField}
            classes={classes}
          />
        </div>
        <div
          className="tab-pane fade"
          id="comment"
          role="tabpanel"
          aria-labelledby="comment-tab2"
        >
          <EmailComments
            goBack={goBack}
            changeCheckOne={changeCheckOne}
            changeCheckTwo={changeCheckTwo}
            changeCheckThree={changeCheckThree}
            changeCommentField={changeCommentField}
            handleSubmit={updateEmailComment}
            saveLoading={saveLoading}
            checkOne={checkOne}
            checkTwo={checkTwo}
            checkThree={checkThree}
            commentField={commentField}
            classes={classes}
          />
        </div>
        <div
          className="tab-pane fade"
          id="reply"
          role="tabpanel"
          aria-labelledby="reply-tab"
        >
          <ReplyLinkTab
            goBack={goBack}
            handleSubmit={updateEmailReply}
            subJectOptions={subJectOptions}
            changeSubject={changeReplySubject}
            changeCheckCompose={changeCheckCompose}
            compose={compose}
            saveLoading={saveLoading}
            subject={replySubject}
            classes={classes}
          />
        </div>
        <div
          className="tab-pane fade"
          id="bcc"
          role="tabpanel"
          aria-labelledby="bcc-tab"
        >
          <DefaultEmails
            emails={defaultEmails}
            domains={domains}
            appId={params.appId}
          />
        </div>
        <div
          className="tab-pane fade"
          id="automate"
          role="tabpanel"
          aria-labelledby="automate-tab"
        >
          <MembersTab
            members={members}
            spaceMembers={spaceMembers}
            appId={params.appId}
            spaceId={app?.space_id}
          />
        </div>
        <div
          className="tab-pane fade"
          id="advanced"
          role="tabpanel"
          aria-labelledby="advanced-tab"
        >
          <AddDomainToApp
            domains={domains}
            saveLoading={saveLoading}
            goBack={goBack}
            handleSubmit={addDomainField}
            changeSelectedDomain={changeSelectedDomain}
            selectedDomain={selectedDomain}
          />
        </div>

        <div
          className="tab-pane fade"
          id="autoReply"
          role="tabpanel"
          aria-labelledby="auto-reply"
        >
          <AutoReply
            saveLoading={saveLoading}
            goBack={goBack}
            handleSubmit={saveAutoReply}
            handleTestSubmit={testAutoReply}
            changeFromEmail={changeFromEmail}
            changeReplyTo={changeReplyTo}
            changeEmailSubject={changeEmailSubject}
            changeSelectedStatus={changeSelectedStatus}
            changeReplyMessage={changeReplyMessage}
            changeTestEmail={changeTestEmail}
            fromEmail={fromEmail}
            testEmail={testEmail}
            replyTo={replyTo}
            emailSubject={emailSubject}
            selectedStatus={selectedStatus}
            replyMessage={replyMessage}
            formFilled={formFilled}
            openAlert={openAlert}
            handleClickOpen={handleClickOpen}
            closeAlert={closeAlert}
            // ref={editorRef}
          />
        </div>

        {/* <div className="tab-pane fade" id='orphan' role="tabpanel" aria-labelledby="orphan-tab">
                    <OrphanTab
                       AppOptions={AppOptions}
                       EmailOptions={EmailOptions}
                    />
                </div> */}
      </div>
    </div>
  );
};

export default Appseting;
