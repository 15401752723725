import React, { useState } from "react";
import { Button, makeStyles } from "@material-ui/core";
// import { toast } from "helpers/apiRequests";
// import { CircularProgress } from "@material-ui/core";
// import { useCallback } from "react";
// import { saveCard } from "services/sync";
// import $ from "jquery";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    borderRadius: "4px",
    background: "#FFF",
    boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.05)",
    marginBottom: "14px",
    padding: "35px 33px",
  },
  cardHeaderTitle: {
    color: "#000",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    marginBottom: "20px",
  },
  formGroup: {
    width: "100%",
    marginBottom: "20px",
  },
  label: {
    color: "#7E7E7E",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    marginBottom: "10px",
  },
  input: {
    width: "100%",
    height: "40px",
    borderRadius: "3px",
    border: "1px solid #D3D3D3",
    background: "transparent",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "20px",
  },
  btnWhiteText: {
    backgroundColor: "#F0B429",
    borderRadius: "3px",
    textTransform: "capitalize",
    padding: "12px 32px",
    color: "#222 !important",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  btnDarkText: {
    backgroundColor: "#CFCFCF",
    borderRadius: "3px",
    textTransform: "capitalize",
    padding: "12px 32px",
    color: "#222 !important",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  btnContainer: {
    display: "flex",
    gap: "10px",
  },
}));

// function changeImpactedElement(tagId, removeClass, addClass) {
//   removeClass = removeClass || "";
//   addClass = addClass || "";
//   $("[data-bluesnap=" + tagId + "]")
//     .removeClass(removeClass)
//     .addClass(addClass);
// }

// const bluesnapSetUp = (bluesnapToken) => {
//   const script = document.createElement("script");
//   script.src = "https://bluesnap.com/web-sdk/4/bluesnap.js";

//   // cardUrl: object that stores card type code (received from BlueSnap) and associated card image URL
//   const cardUrl = {
//     AMEX: "https://files.readme.io/97e7acc-Amex.png",
//     DINERS: "https://files.readme.io/8c73810-Diners_Club.png",
//     DISCOVER: "https://files.readme.io/caea86d-Discover.png",
//     JCB: "https://files.readme.io/e076aed-JCB.png",
//     MASTERCARD: "https://files.readme.io/5b7b3de-Mastercard.png",
//     VISA: "https://files.readme.io/9018c4f-Visa.png",
//   };

//   const bsObj = {
//     //insert your Hosted Payment Fields token
//     token: bluesnapToken,
//     onFieldEventHandler: {
//       onFocus: function (tagId) {
//         // Handle focus
//         changeImpactedElement(tagId, "", "hosted-field-focus");
//       },
//       onBlur: function (tagId) {
//         // Handle blur
//         changeImpactedElement(tagId, "hosted-field-focus");
//       },
//       onError: function (tagId, errorCode, errorDescription) {
//         // Handle a change in validation by displaying help text
//         $("#" + tagId + "-help")
//           .removeClass("helper-text-green")
//           .text(errorDescription);
//       },
//       onType: function (tagId, cardType, cardData) {
//         // get card type from cardType and display card image
//         $("#card-logo > img").attr("src", cardUrl[cardType]);
//         if (null != cardData) {
//           $("#" + tagId + "-help")
//             .addClass("helper-text-green")
//             .text("Okay.");
//         }
//       },
//       onValid: function (tagId) {
//         // Handle a change in validation by removing any help text
//         $("#" + tagId + "-help").text("");
//       },
//     },
//     style: {
//       // Styling all inputs
//       input: {
//         "font-size": "14px",
//         "font-family":
//           "RobotoDraft,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif",
//         "line-height": "1.42857143",
//         color: "#555",
//       },
//       // Styling input state
//       ":focus": {
//         color: "#555",
//       },
//     },
//     ccnPlaceHolder: "1234 5678 9012 3456",
//     cvvPlaceHolder: "123",
//     expPlaceHolder: "MM / YY",
//   };
//   script.onload = () => {
//     window.bluesnap.hostedPaymentFieldsCreate(bsObj);
//   };
//   document.body.appendChild(script);
// };

const AddCard = ({ setCurrentScreen, props }) => {
  const [cardName, setCardName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvv, setCvv] = useState("");
  // const [loading, setLoading] = useState(false);
  // const [apiResponse, setApiResponse] = useState(null);
  // const [bluesnapToken, setBlueSnapToken] = useState(props.user.bluesnap_token);

  const classes = useStyles();

  // const handleSave = async () => {
  //   window.bluesnap.hostedPaymentFieldsSubmitData(function (callback) {
  //     if (
  //       !cardName.trim() ||
  //       !cardNumber.trim() ||
  //       !expiryDate.trim() ||
  //       !cvv.trim()
  //     ) {
  //       // Show an error message or perform other actions for incomplete fields
  //       toast.notify("Please fill in all fields", { type: "error" });
  //       return;
  //     }

  //     if (cvv.length !== 3) {
  //       // Show an error message or perform other actions for invalid CVV
  //       toast.notify("Invalid CVV", { type: "error" });
  //       return;
  //     }
  //     if (expiryDate.length !== 4) {
  //       // Show an error message or perform other actions for invalid expiry date
  //       toast.notify("Invalid expiry date", { type: "error" });
  //       return;
  //     }

  //     if (cardNumber.length !== 16) {
  //       // Show an error message or perform other actions for invalid card number
  //       toast.notify("Invalid card number", { type: "error" });
  //       return;
  //     }

  //     if (cardName && cardNumber && expiryDate && cvv) {
  //       const cardData = callback.cardData;
  //       const name = $('#cardholder-name').val();
  //         setLoading(true);

  //         saveCard({
  //           name,
  //           email: props.user.email,
  //           bluesnap_token: bluesnapToken,
  //           cardName,
  //           cardNumber,
  //           expiryDate,
  //           cvv,
  //         })
  //         .then((res) => {
  //           const response = JSON.parse(res.data);
  //           if (typeof response['message'] !== 'undefined') {
  //             if (Array.isArray(response['message'])) {
  //               toast.notify(response.message[0].description);
  //               return false;
  //             }
  //           }
  //           toast.notify('Card saved successfully!');
  //           props.loadCards();
  //           setShouldShowCardModal(false);
  //         }).catch(error => {
  //           toast("Failed to add card!")
  //           // refreshBluesnapToken().then(data => {
  //           //   if (data?.status && data?.token) {
  //           //     setBlueSnapToken(data?.token)
  //           //   }
  //           // })
  //         })
  //         .catch((error) => {
  //           toast.notify('Failed to add card!', { type: 'error' });
  //         });

  //     }
  //   });
  // 
  //   setCurrentScreen("select-card");
  // };

  return (
    <>
      <div className={classes.card}>
        <h4 className={classes.cardHeaderTitle}>Payment Method</h4>

        <div className={classes.formGroup}>
          <label htmlFor="cardName" className={classes.label}>
            Name on card*
          </label>
          <input
            type="text"
            placeholder="John Doe"
            className={classes.input}
            id="cardName"
            value={cardName}
            onChange={(e) => setCardName(e.target.value)}
          />
        </div>

        <div className={classes.formGroup}>
          <label htmlFor="cardNumber" className={classes.label}>
            Card number*
          </label>
          <input
            type="text"
            placeholder="1234 5689 4758 5943"
            className={classes.input}
            id="cardNumber"
            value={cardNumber}
            onChange={(e) => setCardNumber(e.target.value)}
          />
        </div>

        <div className={classes.grid}>
          <div className={classes.formGroup}>
            <label htmlFor="expiryDate" className={classes.label}>
              Expiry date*
            </label>
            <input
              type="text"
              placeholder="MM/YY"
              className={classes.input}
              id="expiryDate"
              value={expiryDate}
              onChange={(e) => setExpiryDate(e.target.value)}
            />
          </div>

          <div className={classes.formGroup}>
            <label htmlFor="cvv" className={classes.label}>
              CVC/Security code*
            </label>
            <input
              type="text"
              placeholder="***"
              className={classes.input}
              id="cvv"
              value={cvv}
              onChange={(e) => setCvv(e.target.value)}
            />
          </div>
        </div>

        <div className={classes.btnContainer}>
          {/* <div className={classes.btnContainer}>
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              <Button
                variant="contained"
                color="primary"
                disableElevation={true}
                // onClick={handleSave}
                className={classes.btnWhiteText}
              >
                Save
              </Button>
            )}
          </div> */}

          <div className={classes.btnContainer}>
            <Button
              variant="contained"
              color="primary"
              disableElevation={true}
              // onClick={handleSave}
              className={classes.btnWhiteText}
            >
              Save
            </Button>
          </div>

          <Button
            variant="contained"
            color="primary"
            disableElevation={true}
            onClick={() => setCurrentScreen("select-card")}
            className={classes.btnDarkText}
          >
            Cancel
          </Button>
        </div>
      </div>
    </>
  );
};

export default AddCard;
