import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import MailIcon from '@material-ui/icons/Mail';

import styles from './SigneeCard.module.scss';
import dots from 'assets/icons/dots.svg';

const useStyles = makeStyles(() => ({
  root: {
    color: '#fff',
    backgroundColor: '#0277BD',
    height: '30px',
    width: '30px',
  },
}));

function DraggableSignees({ signees, setSignees }) {
  function onDragEnd({ source, destination }) {
    if (destination === undefined || destination === null) return null;
    const deepCopiedSignees = JSON.parse(JSON.stringify(signees));
    const [signeeToBeMoved] = deepCopiedSignees.splice(source.index, 1);
    deepCopiedSignees.splice(destination.index, 0, signeeToBeMoved);
    setSignees(deepCopiedSignees);
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='signees'>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {signees.map((signer, index) => (
              <DraggableSigneeCard key={signer.uuid} index={index} signer={signer} />
            ))}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export const DraggableSigneeCard = (props) => {
  const classes = useStyles();
  const { email, name, uuid } = props.signer;
  if (!name || !email) return null;

  return (
    <Draggable draggableId={uuid} index={props.index}>
      {(provided) => (
        <div className={`${styles.container} ${styles.drag}`} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <img src={dots} alt='dots' />
          <div className={styles.nameWrapper}>
            <Avatar className={classes.root} style={{ height: '25px', width: '25px' }}></Avatar>
            <p>{name?.valueObject?.label}</p>
          </div>

          <div className={styles.nameWrapper}>
            <MailIcon style={{ color: '#0277bd' }} />
            <p>{email?.valueObject?.label}</p>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default DraggableSignees;
