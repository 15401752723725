import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "./style.css";
import { toast } from "helpers/apiRequests";
import { CopyToClipboard } from "react-copy-to-clipboard";
import cancel from "../../../assets/icons/cancel.svg";
import arrow from "../../../assets/icons/arrow-right.svg";

const useStyles = makeStyles((theme) => ({
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  headerText: {
    color: "black",
  },
  detailRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "2%",
    backgroundColor: "white",
    padding: "3%",
    borderRadius: "5px"
  },

  detailCont1: {
    display: "flex",
    width: "40%",
    justifyContent: "space-between",
  },
  btnDesc: {
    color: "#DE911D",
  },
}));

const ViewConnector = ({ openModal, handleClose, credential }) => {
  const classes = useStyles();

  const { db_driver, db_host, db_port, db_name, db_username, db_password } =
    credential ?? {};

  const handleCopy = (text) => {
    toast.notify(`${text} Copied to clipboard`, { type: "info" });
  };
  const textStyle = {
    color: "rgb(202, 157, 33)",
    fontWeight: "bolder",
    cursor: "pointer",
    // fontSize: "20px"
  };

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={handleClose}
        // hideBackdrop={true}
        disableBackdropClick={false}
        disableEscapeKeyDown={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="connector-modal"
      >
        <DialogContent>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography variant="h6">
                <div className={classes.modalHeader}>
                  <p className={classes.headerText}>
                    {db_driver ? db_driver.toUpperCase() : db_driver} connection
                    details
                  </p>
                  <div>
                    <img onClick={handleClose} alt="cancel" src={cancel} />
                  </div>
                </div>
              </Typography>

              <Typography gutterBottom>
                <div className="table-responsive" style={{ width: "500px" }}>
                  <div
                    style={{
                      backgroundColor: "#FFFDF8",
                      borderRadius: "10px",
                      padding: "2%",
                      color: "black",
                      fontWeight: "600",
                    }}
                  >
                    <div className={classes.detailRow}>
                      <div className={classes.detailCont1}>
                        <p style={{ marginBottom: "0" }}>Host</p>
                        <img alt="arrow" src={arrow} />
                      </div>

                      <div style={{ width: "20%" }}>
                        <p style={{ marginBottom: "0" }}>{db_host}</p>
                      </div>

                      <div style={{ width: "30%", textAlign: "end" }}>
                        <CopyToClipboard text={db_host}>
                          <p
                            style={{ marginBottom: "0", textStyle }}
                            // style={textStyle}
                            onClick={() => handleCopy("Port detail")}
                          >
                            <i
                              style={{ color: "#F0B429" }}
                              className="fas fa-copy"
                            />{" "}
                            Copy
                          </p>
                        </CopyToClipboard>
                      </div>
                    </div>

                    <div className={classes.detailRow}>
                      <div className={classes.detailCont1}>
                        <p style={{ marginBottom: "0" }}>Port</p>
                        <img alt="arrow" src={arrow} />
                      </div>

                      <div style={{ width: "20%" }}>
                        <p style={{ marginBottom: "0" }}>{db_port}</p>
                      </div>

                      <div style={{ width: "30%", textAlign: "end" }}>
                        <CopyToClipboard text={db_port}>
                          <p
                            style={{ marginBottom: "0", textStyle }}
                            // style={textStyle}
                            onClick={() => handleCopy("Port detail")}
                          >
                            <i
                              style={{ color: "#F0B429" }}
                              className="fas fa-copy"
                            />{" "}
                            Copy
                          </p>
                        </CopyToClipboard>
                      </div>
                    </div>

                    <div className={classes.detailRow}>
                      <div className={classes.detailCont1}>
                        <p style={{ marginBottom: "0" }}>Driver</p>
                        <img alt="arrow" src={arrow} />
                      </div>

                      <div style={{ width: "20%" }}>
                        <p style={{ marginBottom: "0" }}>{db_driver}</p>
                      </div>

                      <div style={{ width: "30%", textAlign: "end" }}>
                        <CopyToClipboard text={db_driver}>
                          <p
                            style={{ marginBottom: "0", textStyle }}
                            // style={textStyle}
                            onClick={() => handleCopy("Driver detail")}
                          >
                            <i
                              style={{ color: "#F0B429" }}
                              className="fas fa-copy"
                            />{" "}
                            Copy
                          </p>
                        </CopyToClipboard>
                      </div>
                    </div>

                    <div className={classes.detailRow}>
                      <div className={classes.detailCont1}>
                        <p style={{ marginBottom: "0" }}>Database Name</p>
                        <img alt="arrow" src={arrow} />
                      </div>

                      <div style={{ width: "20%" }}>
                        <p style={{ marginBottom: "0" }}>{db_name}</p>
                      </div>

                      <div style={{ width: "30%", textAlign: "end" }}>
                        <CopyToClipboard text={db_name}>
                          <p
                            style={{ marginBottom: "0", textStyle }}
                            // style={textStyle}
                            onClick={() => handleCopy("Database detail")}
                          >
                            <i
                              style={{ color: "#F0B429" }}
                              className="fas fa-copy"
                            />{" "}
                            Copy
                          </p>
                        </CopyToClipboard>
                      </div>
                    </div>

                    <div className={classes.detailRow}>
                      <div className={classes.detailCont1}>
                        <p style={{ marginBottom: "0" }}>Database Username</p>
                        <img alt="arrow" src={arrow} />
                      </div>

                      <div style={{ width: "20%" }}>
                        <p style={{ marginBottom: "0" }}>{db_username}</p>
                      </div>

                      <div style={{ width: "30%", textAlign: "end" }}>
                        <CopyToClipboard text={db_username}>
                          <p
                            style={{ marginBottom: "0", textStyle }}
                            // style={textStyle}
                            onClick={() => handleCopy("Database username")}
                          >
                            <i
                              style={{ color: "#F0B429" }}
                              className="fas fa-copy"
                            />{" "}
                            Copy
                          </p>
                        </CopyToClipboard>
                      </div>
                    </div>

                    <div className={classes.detailRow}>
                      <div className={classes.detailCont1}>
                        <p style={{ marginBottom: "0" }}>Database Password</p>
                        <img alt="arrow" src={arrow} />
                      </div>

                      <div style={{ width: "20%" }}>
                        <p style={{ marginBottom: "0" }}>{db_password}</p>
                      </div>

                      <div style={{ width: "30%", textAlign: "end" }}>
                        <CopyToClipboard text={db_password}>
                          <p
                            style={{ marginBottom: "0", textStyle }}
                            // style={textStyle}
                            onClick={() => handleCopy("Database password")}
                          >
                            <i
                              style={{ color: "#F0B429" }}
                              className="fas fa-copy"
                            />{" "}
                            Copy
                          </p>
                        </CopyToClipboard>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <Box
                  sx={{ display: "flex", fontSize: "small", justifyContent: "space-between" }}>
                  <p>Host:</p>
                  <p>{db_host}</p>
                  <CopyToClipboard
                    text={db_host}
                  >
                    <p style={textStyle} onClick={() => handleCopy("Host detail")}>

                      <i className="fas fa-copy" />
                    </p>
                  </CopyToClipboard>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    fontSize: "small",
                    justifyContent: "space-between"
                  }}
                >
                  <p>Port:</p>
                  <p>{db_port}</p>
                  <CopyToClipboard
                    text={db_port}
                  >
                    <p style={textStyle} onClick={() => handleCopy("Port detail")}>

                      <i className="fas fa-copy" />
                    </p>
                  </CopyToClipboard>
                </Box>
                <Box
                  sx={{ display: "flex", fontSize: "small", justifyContent: "space-between" }}>
                  <p>Driver:</p>
                  <p>{db_driver}</p>
                  <CopyToClipboard
                    text={db_driver}
                  >
                    <p style={textStyle} onClick={() => handleCopy("Driver detail")}>

                      <i className="fas fa-copy" />
                    </p>
                  </CopyToClipboard>
                </Box>
                <Box
                  sx={{ display: "flex", fontSize: "small", justifyContent: "space-between" }}>
                  <p>Database Name:</p>
                  <p>{db_name}</p>
                  <CopyToClipboard
                    text={db_name}
                  >
                    <p style={textStyle} onClick={() => handleCopy("Database name")}>

                      <i className="fas fa-copy" />
                    </p>
                  </CopyToClipboard>
                </Box>
                <Box
                  sx={{ display: "flex", fontSize: "small", justifyContent: "space-between" }}>
                  <p>Database Username:</p>
                  <p>{db_username}</p>
                  <CopyToClipboard
                    text={db_username}
                  >
                    <p style={textStyle} onClick={() => handleCopy("Database username")}>

                      <i className="fas fa-copy" />
                    </p>
                  </CopyToClipboard>
                </Box>
                <Box
                  sx={{ display: "flex", fontSize: "small", justifyContent: "space-between" }}>
                  <p>Database Password:</p>
                  <p>{db_password}</p>
                  <CopyToClipboard
                    text={db_password}
                  >
                    <p style={textStyle} onClick={() => handleCopy("Database password")}>

                      <i className="fas fa-copy" />
                    </p>
                  </CopyToClipboard>
                </Box> */}
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button id="button-cancel" onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ViewConnector;
