import React, { useState, useEffect } from "react";
import "../Haven.css";
import { connect } from "react-redux";
import FileGrid from "./FileGrid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog/Dialog";
import { Alert } from "@material-ui/lab";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import { getOrganization, syncFileHaven } from "../../../services/sync";
// import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import BackButton from "components/atoms/BackButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import cancel from "../../../assets/icons/cancel.svg";

const useStyles = makeStyles((theme) => ({
  headerInfo: {
    marginTop: "0",
    marginLeft: "15px",
    color: "#F0B429",
    fontSize: "18px",
    fontWeight: "600",
  },

  "@media screen and (max-width: 600px)": {
    headerInfo: {
      fontSize: "16px !important",
    },
    headerTotalSync: {
      fontSize: "16px !important",
    },
  },
}));

const Files = (props) => {
  const classes = useStyles();
  const params = useParams();
  const isMobile = useMediaQuery("(max-width:600px)");

  const [org, setOrg] = useState(null);
  const [spaceSync, syncModalData] = useState({
    message: null,
    modal_type: null,
    modal_value: false,
    modal_data: {},
  });
  const [snack, setSnack] = useState({
    message: null,
    snackType: null,
    isSnack: false,
    reload: false,
  });
  const [preloader, setPreloader] = useState(false);

  const [files] = useState({
    columns: [
      {
        headerName: "Menu",
        width: 30,
        field: "menu",
        cellRenderer: "downloadAction",
        sortable: false,
        filter: false,
        enableRowGroup: false,
        checkboxSelection: true,
      },
      { headerName: "App", field: "app_name" },
      { headerName: "App Id", field: "app_id" },
      { headerName: "Item Title", field: "item_title" },
      { headerName: "File Id", field: "file_id" },
      { headerName: "Name", field: "name" },
      { headerName: "Size", field: "size" },
      { headerName: "Mime Type", field: "mimetype" },
      { headerName: "Link", field: "link" },
      { headerName: "Created By", field: "created_by" },
      { headerName: "Hosted By", field: "hosted_by" },
    ],
    data: [],
  });

  const [sync_status, setSyncStatus] = useState(0);
  const [sync_status_text, setSyncStatusText] = useState(null);
  const [selectedFields, setSelectedFields] = useState([]);

  const F_INTERVAL_KEY = "F_IntervalId";

  const confirm_handleClose = () => {
    syncModalData({
      message: null,
      modal_type: null,
      modal_value: false,
      modal_data: {},
    });
  };

  const handleClose = () => {
    if (snack.reload) window.history.go();
    setSnack({ message: "", snackType: "", isSnack: false, reload: false });
  };

  useEffect(() => {
    getOrganization(props.match.params.org_id).then((res) => {
      setOrg(res.data);
      if (res?.data?.mongo_summary) {
        setSyncStatus(res.data.mongo_summary.count.files);
      }
    });
  }, [props.match.params.org_id]);

  function checkIfOrgIsDoneSyncing() {
    if (!props.match.params.org_id) return false;
    getOrganization(props.match.params.org_id).then((res) => {
      const status = res.data.status;

      if (res?.data?.mongo_summary) {
        setSyncStatus(res.data.mongo_summary.count.files);
        setSyncStatusText(status);
      }

      setTimeout(() => {
        if (status === "Done" || status === "N'SYNC") {
          clearInterval(localStorage.getItem(F_INTERVAL_KEY));
          localStorage.removeItem(F_INTERVAL_KEY);
        }
      }, 2000);
    });
  }

  const updateSelectedFields = (value) => {
    let oldValue;
    let newPayload = {
      id: value.data.file_id,
      link: value.data.link,
    };
    let fieldExist = selectedFields.find(
      (field) => field.id === value.data.file_id
    );

    if (fieldExist) {
      oldValue = selectedFields.filter(
        (field) => field.id !== value.data.file_id
      );
      setSelectedFields(oldValue);
    } else {
      setSelectedFields([...selectedFields, newPayload]);
    }
  };
  // const handleMultpleFileDownload = () => {
  //   const openLinks = (fields) => {
  //     fields.map((link) => window.open(link.link, "_blank"));
  //   };

  //   openLinks(selectedFields);
  // };
  const startStuff = async () => {
    const intervalId = setInterval(function startSyncing() {
      checkIfOrgIsDoneSyncing();
    }, 2000);

    localStorage.setItem(F_INTERVAL_KEY, intervalId);
  };

  function resyncFileHaven() {
    let query = `?org_id=${props.match.params.org_id}&org_name=${org.org_name}&file_syncing=1`;

    setPreloader(true);
    syncFileHaven(query)
      .then(() => {
        confirm_handleClose();
        setSnack({
          message: "Syncing has started successfully",
          snackType: "success",
          isSnack: true,
        });
        startStuff();
        setPreloader(false);
      })
      .catch(() => {
        confirm_handleClose();
        setPreloader(false);
        setSnack({
          message: "Failed to resync",
          snackType: "error",
          isSnack: true,
        });
      });
  }

  if (preloader) {
    return (
      <div className="material_block">
        <svg
          className="spinner"
          width="65px"
          height="65px"
          viewBox="0 0 66 66"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            className="circle"
            fill="#fff"
            strokeWidth="6"
            strokeLinecap="round"
            cx="33"
            cy="33"
            r="30"
          ></circle>
        </svg>
      </div>
    );
  }

  return (
    <div className="file__haven">
      <div className="row mb-2">
        {isMobile ? null : (
          <div className="mb-3">
            <BackButton />
          </div>
        )}

        <div
          className="col-md-12 text-left file_haven_org"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <div style={{ display: "flex", alignItems: "flex-start" }}>
            {isMobile ? <BackButton /> : null}
            <div>
              <p className={classes.headerInfo}>
                File Haven {`>`}
                <span style={{ color: "black" }}>
                  {" "}
                  {org !== null &&
                    org.org_name + "(" + props.match.params.org_id + ")"}{" "}
                </span>{" "}
                <br />
              </p>

              <p
                className={classes.headerTotalSync}
                style={{
                  marginTop: "10px",
                  marginLeft: "15px",
                  fontSize: "18px",
                }}
              >
                Total synced files: {sync_status}
              </p>
            </div>
          </div>

          <br />
          <span>
            {sync_status_text && (
              <p>
                <b>Status: Done</b>
              </p>
            )}
          </span>
          <br />
          <div className="file_haven_btn" style={{ float: "right", marginBottom: "2%"}}>
            <button
              style={{
                float: "right",
                color: "white",
                marginLeft: "10px",
                border: "none",
                background: "#f7c948",
                outline: "none",
                borderRadius: "6px",
                boxShadow: "0px 3px 6px #00000029",
              }}
              onClick={() => {
                syncModalData({
                  message: "Are you sure you want to resync file haven?",
                  modal_type: "filehaven",
                  modal_value: true,
                  modal_data: {},
                });
              }}
            >
              Resync Files
            </button>
          </div>
        </div>
      </div>
      {files.columns ? (
        <FileGrid
          org_id={props.match.params.org_id}
          user={props.user}
          files={files}
          params={params}
          match={props.match}
          updateSelectedFields={updateSelectedFields}
          selectedFields={selectedFields}
          history={props.history}
        />
      ) : (
        ""
      )}

      <Dialog
        open={snack.isSnack}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <Alert onClose={handleClose} severity={snack.snackType}>
          {snack.message}
        </Alert>
      </Dialog>

      <Dialog
        open={spaceSync.modal_value}
        onClose={confirm_handleClose}
        aria-labelledby="form-dialog-title"
      >
        <div
          style={{ width: "350px" }}
          className="d-flex align-items-center justify-content-between mr-3 haven_modal_header"
        >
          <DialogTitle id="form-dialog-title">Resync</DialogTitle>
          <img onClick={confirm_handleClose} alt="" src={cancel} />
        </div>
        <DialogContent>
          <DialogContentText style={{textAlign: "center"}}>{spaceSync.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              backgroundColor: "#F0B429",
              color: "white",
              width: "30%",
              marginBottom: "3%",
              marginRight: "3%",
            }}
            onClick={() => resyncFileHaven()}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.authUser,
});

export default connect(mapStateToProps, null)(Files);
