import React from 'react';
import dayjs from 'dayjs';

import styles from './ViewSignedDocument.module.scss';
const formatDate = (value) => {
  return dayjs(value).format('D:MM:YY-h:mm-a');
};

const ActivityLogs = ({ audits = [] }) => {
  return (
    <>
      <div className={styles.activity_container}>
        {audits?.map((activity) => {
          return (
            <p>
              <span>{`[${formatDate(activity.timestamp)}]: `} </span>
              {activity.payload}
            </p>
          );
        })}
      </div>
    </>
  );
};

export default ActivityLogs;
