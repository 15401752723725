import React from "react";
import { useHistory } from "react-router-dom";
// widgets
import Products from "../widgets/Products";
// icons
import logo from "../icons/logo.svg";
// style
import "./style/footer.scss";
import "./style/general.scss";

const Footer = (e) => {
  const history = useHistory();
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  // a function that gets the current year
  const currentYear = new Date().getFullYear();

  return (
    <div className={`footer ${e.bg}`}>
      <div>
        <img
          onClick={() => history.push("/")}
          src={logo}
          className="logo"
          alt="ThatApp"
        />
        <div className="about">
          It's our job to ensure that you're never lost in the clouds. Gain a
          refreshing perspective of your business processes and strategic
          initiatives.
        </div>
      </div>
      <div className="list triple-container">
        <div className="item-container">
          <div className={e.color}>LEARN MORE</div>
          <div className="item link22">
            <Products text="Features" />
          </div>
          <div
            className="item link22"
            onClick={() => {
              history.push("/integrations");
              scrollTop();
            }}
          >
            Integrations
          </div>
          <div
            className="item link22"
            onClick={() => {
              history.push("/pricing");
              scrollTop();
            }}
          >
            Pricing
          </div>
          <div
            className="item link22"
            onClick={() => {
              window.open("http://help.thatapp.io/", "_blank");
            }}
          >
            <a
              className="link"
              href="http://help.thatapp.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Documentation
            </a>
          </div>
          <div
            className="item link22"
            onClick={() => {
              window.open("https://thatapp1.statuspage.io/", "_blank");
            }}
          >
            <a
              className="link"
              href="http://help.thatapp.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Status Page
            </a>
          </div>
        </div>
        <div className="item-container">
          <div className={e.color}>RESOURCES</div>
          <div
            className="item"
            onClick={() => {
              window.open("http://help.thatapp.io/", "_blank");
            }}
          >
            <a
              className="link"
              href="http://help.thatapp.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Support
            </a>
          </div>
          <div
            onClick={() => window.Intercom("show")}
            className="item"
            id="intercom_link"
          >
            Contact
          </div>
        </div>
        <div className="item-container">
          <div className={e.color}>LEGAL</div>
          <div
            className="item"
            onClick={() => {
              window.open(
                "https://help.thatapp.io/en/articles/108584-privacy-policy",
                "_blank"
              );
            }}
          >
            Privacy Policy
          </div>
          <div
            className="item"
            onClick={() => {
              window.open(
                "https://help.thatapp.io/en/articles/9392123-terms-of-service",
                "_blank"
              );
            }}
          >
            Terms of Service
          </div>
          {/* <div className="item">Cookie settings</div> */}
        </div>
      </div>

      <div className="copyright">
        &copy; {currentYear} ThatApp, powered by Techego LLC
      </div>
    </div>
  );
};

export default Footer;
