import { toast } from "helpers/apiRequests";
import axios from 'axios';
import { authConnector, fileHavenAuthConnector, disconnectPodioFilehaven, authTmailConnector } from "../../services/auth";
const BASE_URL = process.env.REACT_APP_BASEURL;

export const authorizePodio = (callback_url) => {
  localStorage.setItem("auth_type", "podio");

  const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

  window.location.href = `https://podio.com/oauth/authorize?client_id=${CLIENT_ID}&redirect_uri=${callback_url}&scope=global:all&response_type=token`;
};

export const fileHavenAuthorizePodio = (callback_url) => {
  localStorage.setItem("file_haven_auth_type", "podio");

  const CLIENT_ID = process.env.REACT_APP_FILE_SYNC_PODIO_CLIENT_ID;
  window.location.href = `https://podio.com/oauth/authorize?client_id=${CLIENT_ID}&redirect_uri=${callback_url}&scope=global:all&response_type=token`;
};

export const TmailAuthorizePodio = (callback_url) => {
  localStorage.setItem("tmail_auth_type", "podio");

  const CLIENT_ID = process.env.REACT_APP_TMAIL_CLIENT_ID;
  window.location.href = `https://podio.com/oauth/authorize?client_id=${CLIENT_ID}&redirect_uri=${callback_url}&scope=global:all&response_type=token`;
};

export const OrphanAuthorizePodio = (callback_url) => {
  localStorage.setItem("orphan_type", "podio");

  const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
  window.location.href = `https://podio.com/oauth/authorize?client_id=${CLIENT_ID}&redirect_uri=${callback_url}&scope=global:all&response_type=token`;
};

export const authConnect = async (code, auth_type) => {
  if (auth_type !== null && auth_type !== "null") {
    const data = `?provider=${auth_type}&code=${code}`;
    let response = await authConnector(data);

    localStorage.removeItem("auth_type");
    return response;
  }

  return true;
};

export const authV2Connect = async (access, auth_type) => {
  if (!auth_type) return;
  const ref_id = access.user_id;
  const access_token = access["access_token"];
  const refresh_token = access["refresh_token"];
  const expires_in = access["expires_in"];
  const data = `?code=codePodio&provider=podio&the_type=hashString&ref_id=${ref_id}&access_token=${access_token}&refresh_token=${refresh_token}&expires_in=${expires_in}`;
  let response = await authConnector(data);

  localStorage.removeItem("auth_type");
  return response;
};

export const authTmailConnect = async (access, auth_type) => {
  try {
    if (!auth_type) return;
    const ref_id = access.user_id;
    const access_token = access["access_token"];
    const refresh_token = access["refresh_token"];
    const expires_in = access["expires_in"];
    const payload = {
      access_token,
      ref_id,
      refresh_token,
      expires_in
    }
    // const data = `?code=codePodio&provider=podio&the_type=hashString&ref_id=${ref_id}&access_token=${access_token}&refresh_token=${refresh_token}&expires_in=${expires_in}`;
    let response = await authTmailConnector(payload);

    localStorage.removeItem("tmail_auth_type");
    return response;
  } catch (error) {
    toast.notify("An error occurred " + error.message, { type: "error" });
  }
};

export const fileHavenAuthV2Connect = async (access, auth_type) => {
  if (!auth_type) return;
  const ref_id = access.user_id;
  const access_token = access["access_token"];
  const refresh_token = access["refresh_token"];
  const expires_in = access["expires_in"];
  const data = `?code=codePodio&provider=podio&the_type=hashString&ref_id=${ref_id}&access_token=${access_token}&refresh_token=${refresh_token}&expires_in=${expires_in}`;

  const response = await fileHavenAuthConnector(data);
  // console.log(response);

  localStorage.removeItem("file_haven_auth_type");
  return response;
};

export const disconnectFilehaven = async () => {
  const response = await disconnectPodioFilehaven();
  return response;
};

export const OrphanAuthConnect = async (access, auth_type, email_id) => {
  try {
    if (!auth_type) return;
    const access_token = access["access_token"];
    const refresh_token = access["refresh_token"];
    localStorage.setItem("orphan_access_token", access_token);
    localStorage.setItem("orphan_refresh_token", refresh_token);
    const payload = {
      email_id,
      access_token,
      refresh_token
    }
    const url = `${BASE_URL}/tmail/authorize-viewer`;
    const res = await axios.post(url, payload);
    console.log(res.data);
    const hash = res.data.data.hash;
    localStorage.setItem("orphan_hash_token", hash);
    localStorage.removeItem("orphan_type");
    return hash;
  } catch (error) {
    console.log(error);
  }
  // return response;
};