import axios from "../../../config";
import { toast } from "helpers/apiRequests";

export const acknowledgeTmail = async () => {
    try {
        const res = await axios.post("/tmail/acknowledge");
    
        return res.status;
    } catch (error) {
        toast.notify(error.response.message, { type: "error" });
    }
}