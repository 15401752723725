import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Logo from "../../assets/images/dashboardlogo.svg";
import "./Sidebar.css";
import MainNavigation from "../Navigation/MainNavigation";
import { Divider } from "@material-ui/core";
import { setOrgs } from "../../redux/actions/sync";
import { connect } from "react-redux";
import Button from "../../components/atoms/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { green } from "@material-ui/core/colors";
import menuIcon from "../../assets/icons/menuIcon.svg";
import cancelIcon from "../../assets/icons/cancelIcon.svg";
import arrowRight from "../../assets/icons/arrowRight.svg";
import Avatar from "@material-ui/core/Avatar";
import useMediaQuery from "@mui/material/useMediaQuery";

import {
  createOrganization,
  getSyncedOrganizations,
} from "../../services/sync";
import { getNavigation } from "../../services/common";
import { setNav } from "../../redux/actions/sidebar";
import Tooltip from "@material-ui/core/Tooltip";
// import searchIcon from "../../assets/icons/searchIcon.svg";
import OrganizationNavFunction from "../Navigation/OrganizationNavFunction";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from "helpers/apiRequests";
import InfoIcon from "../../assets/icons/info.svg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    marginRight: theme.spacing(1),
    textAlign: "center",
    background: "#FFF3C4",
    borderRadius: "3px",
    color: "#DE911D",
  },
  root: {
    flexGrow: 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  control: {
    padding: theme.spacing(2),
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  search_div_collapse: {
    padding: "15% 3%",
    margin: "40% 0 10% 20%",
    backgroundColor: "#252D3A",
    border: "1.5px solid #2B3341",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },

  "@media (max-width: 600px)": {
    drawerClose: {
      width: 0,
      zIndex: -1,
    },
    drawerOpen: {
      width: "80%",
    },
    search_div_collapse: {
      margin: "10% 0 5% 5%",
    },
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    fontFamily: "Mulish",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "30px",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  infoDiv: {
    display: "flex",
    backgroundColor: "lightgray",
    borderRadius: "5px",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const Sidebar = ({ open, handleDrawerOpen, user, orgs, setOrgs }) => {
  const classes = useStyles();
  const [openNewOrganisation, setOpenNewOrganisation] = useState(false);
  const [organisationError, setOrganisationError] = useState(false);
  const [newOrganisationName, setNewOrganisationName] = useState("");
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(false);
  const [info, setInfo] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");

  // const handleOpen = () => {
  //   setOpenNewOrganisation(true);
  // };

  const handleInfoOpen = () => {
    setInfo(true);
    setTimeout(() => setInfo(false), 5000);
  };

  //write a useEffect that checks if the info is set to true and then sets it to false after 5 seconds
  // useEffect(() => {
  //   if (info) {
  //     setTimeout(() => setInfo(false), 5000);
  //   }
  // }, [info]);

  const handleClose = () => {
    setOpenNewOrganisation(false);
  };

  const updateNavigation = async () => {
    let response = await getNavigation();
    setNav(response.menus);
  };

  const updateOrgs = async () => {
    getSyncedOrganizations().then((res) => {
      setOrgs(res.data);
    });
  };

  const createNewOrganisation = async (e) => {
    e.preventDefault();

    if (newOrganisationName.length === 0) {
      setOrganisationError(true);
      return;
    }

    if (!loading) {
      setLoading(true);
    }

    try {
      let data = {
        name: newOrganisationName,
      };

      let response = await createOrganization(data);

      await updateOrgs();
      await updateNavigation();
      setNewOrganisationName("");
      setOpenNewOrganisation(false);

      if (response.message) {
        toast.notify(response.message);
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      setOpenNewOrganisation(false);
      setNewOrganisationName("");
    }
  };

  const userEmail = user?.podio_profile?.profile?.mail
    ? user?.podio_profile?.profile?.mail[0]
    : "";
  const userName = user?.name;
  const isTeamMember = localStorage.getItem("team_member_uuid");

  const img = isTeamMember
    ? "https://i.stack.imgur.com/l60Hf.png"
    : user?.podio_profile?.profile?.image;

  const handleResize = () => {
    setSearch(!search);
  };
  const combinedFunction = () => {
    handleResize();
    handleDrawerOpen();
  };

  // useEffect(() => {
  //   const handleResize = () => {
  //     const sidebarWidth = document.getElementById('sidebar').offsetWidth;
  //     if (sidebarWidth < 100) { // Adjust this value according to your sidebar collapse threshold
  //       setSearch(true);
  //     } else {
  //       setSearch(false);
  //     }
  //   };

  //   // Call the handleResize function when the window is resized
  //   window.addEventListener('resize', handleResize);

  //   // Call the handleResize function once initially to set the initial state
  //   handleResize();

  //   // Remove the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);

  return (
    <div id="sidebar" className="sidebar">
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <div className="sidebar__header">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Tooltip onClick={handleInfoOpen} title="Podio logo">
                  <img src={Logo} alt="logo" />
                </Tooltip>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <span className="logo__title">Podio</span>

                  {isMobile ? null : (
                    <div>
                      {open && (
                        <>
                          {userEmail?.length > 33 ? (
                            <div>
                              <Tooltip title={userEmail} placement="bottom">
                                {
                                  <p
                                    style={{ marginLeft: "5%" }}
                                    className="profile_email_display"
                                  >
                                    {userEmail}
                                  </p>
                                }
                              </Tooltip>
                            </div>
                          ) : (
                            <div>
                              <p
                                style={{ color: "#F7C948" }}
                                className="profile_email_display"
                              >
                                {userEmail}
                              </p>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <Tooltip
                className="cancelIcon"
                onClick={handleDrawerOpen}
                title="Podio logo"
              >
                <img src={cancelIcon} alt="logo" />
              </Tooltip>
            </div>

            {isMobile ? (
              <div className="mt-4 ml-2">
                <div className="d-flex align-items-center">
                  <Avatar
                    alt={userName}
                    src={img}
                    style={{
                      width: "50px",
                      height: "50px",
                      // border: "1px solid #E1E1E1",
                    }}
                  />

                  <div style={{ width: "70%" }} className="ml-3">
                    {open && (
                      <>
                        {userEmail?.length > 33 ? (
                          <div>
                            <Tooltip title={userEmail} placement="bottom">
                              {<h5 style={{ marginLeft: "5%" }}>{userName}</h5>}
                            </Tooltip>
                            <h4 style={{ color: "#B1B1B1" }}>{userEmail}</h4>
                          </div>
                        ) : (
                          <div>
                            <h5 style={{ color: "#fff", fontSize: "16px" }}>
                              {userName}
                            </h5>
                            <p
                              style={{
                                color: "#B1B1B1",
                                fontSize: "10px",
                                marginBottom: "0",
                                marginTop: "4%",
                              }}
                            >
                              {userEmail}
                            </p>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <Link to="/account_overview">
                    <img src={arrowRight} alt="" />
                  </Link>
                </div>
              </div>
            ) : null}
          </div>
        </div>

        {/* {search ? (
          <div className={classes.search_div_collapse}>
            <img style={{ width: "20px" }} alt="search" src={searchIcon} />
          </div>
        ) : (
          <div
            style={{
              padding: "0% 3%",
              margin: "10% 2% 10% 6%",
              backgroundColor: "#252D3A",
              border: "1.5px solid #2B3341",
              borderRadius: "8px",
            }}
          >
            <img style={{ marginLeft: "3%" }} alt="search" src={searchIcon} />
            <input
              style={{
                outline: "none",
                backgroundColor: "#252D3A",
                width: "190px",
                color: "white",
              }}
              placeholder="Search"
            />
          </div>
        )} */}

        <div className="sidebar__content">
          <p className="subheading mt-2">SYSTEM</p>
          <MainNavigation user={user} />

          {open ? (
            <p
              className="subheading mt-4 mb-3"
              style={{
                fontSize: "14px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span style={{ position: "relative", top: "10px" }}>
                ORGANIZATIONS
              </span>
              {/* <ButtonTwo
                className={classes.button}
                size="small"
                tooltip={true}
                tooltip_title="Create New Organisation"
                tooltip_placement="bottom"
                function={handleOpen}
                text={<AddIcon />}
                style={{ background: `#F7C948`, color: `#fff` }}
              /> */}
            </p>
          ) : (
            <p
              className="subheading mt-4 mb-3"
              style={{
                fontSize: "14px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span style={{ position: "relative", top: "10px" }}>ORGA...</span>
              {/* <ButtonTwo
                className={classes.button}
                size="small"
                tooltip={true}
                tooltip_title="Create New Organisation"
                tooltip_placement="bottom"
                function={handleOpen}
                text={<AddIcon />}
                style={{ background: `#F7C948`, color: `#fff` }}
              /> */}
            </p>
          )}
          <OrganizationNavFunction open={open} />
        </div>

        {info ? (
          <div
            style={{
              display: "flex",
              padding: "3%",
              borderRadius: "5px",
              backgroundColor: "#2B3341",
              alignItems: "center",
              marginLeft: "10px",
              position: "sticky",
              top: "90%",
            }}
          >
            <div
              style={{ width: "20%", display: "flex", alignItems: "center" }}
            >
              <img alt="" src={InfoIcon} />
            </div>
            {search ? null : (
              <div style={{ width: "80%" }}>
                <p
                  style={{ fontSize: "14px", width: "30%", marginBottom: "0" }}
                >
                  Hi! We currently only <br /> support Podio platform.
                  <br /> Other platforms are coming
                  <br /> soon.
                </p>
              </div>
            )}
          </div>
        ) : null}
      </Drawer>
      <span className="menuIconCont" style={{ cursor: "pointer" }}>
        {search ? (
          <img
            alt=""
            aria-label="open drawer"
            onClick={combinedFunction}
            edge="start"
            src={menuIcon}
            style={{
              position: "relative",
              top: "15px",
              left: "49.5%",
              zIndex: "3000",
            }}
          />
        ) : (
          <img
            alt=""
            aria-label="open drawer"
            onClick={combinedFunction}
            edge="start"
            src={menuIcon}
            style={{
              position: "relative",
              top: "15px",
              left: "82.5%",
              zIndex: "1300",
            }}
          />
        )}
      </span>

      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openNewOrganisation}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className="dialog-box"
      >
        <DialogContent className="pb-5">
          <div className="head">
            <h3>Create New Organisation</h3>
            <DialogTitle
              id="form-dialog-title"
              onClose={handleClose}
            ></DialogTitle>
          </div>
          <div>
            Create a new organisation by filling out the information below
          </div>
          <Divider className="divider" />
          <div className="form">
            <div className="label">Organisation Name</div>
            <div>
              <TextField
                disabled={loading}
                variant="outlined"
                autoFocus
                error={organisationError}
                margin="dense"
                value={newOrganisationName}
                onChange={(e) => setNewOrganisationName(e.target.value)}
                // helperText="Required."
                id="name"
                type="text"
                size="small"
                style={{ width: `100%` }}
              />
            </div>
          </div>
          <div className="actions">
            <Button
              function={handleClose}
              style={{ background: `#fff`, color: `#626262` }}
              text="Cancel"
            />
            <Button
              disabled={loading}
              function={createNewOrganisation}
              style={
                loading
                  ? { background: `grey`, color: `#000` }
                  : { background: `#F7C948`, color: `#fff` }
              }
              text={loading ? "PLEASE WAIT..." : "Create"}
            />
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.authUser,
});

const mapDispatchToProps = (dispatch) => ({
  setOrgs: (data) => dispatch(setOrgs(data)),
  setNav: (data) => dispatch(setNav(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
