import SideBarHandler from './SideBarHandler';
import React from 'react';
import './styles.css';
// icons
import dashboard from '../../../../../assets/icons/dashboard.svg';
import file from '../../../../../assets/icons/file.svg';
import eye from '../../../../../assets/icons/eye.svg';
import target from '../../../../../assets/icons/target.svg';
import connection from '../../../../../assets/icons/connection.svg';
// import email from '../../../../assets/icons/file.svg';
import { TMAIL_DASHBOARD, TMAIL_DASHBOARD_APPS, DOMAINS, CAMPAIGN, MEMBERS } from '../../../../../containers/pagePath';

const MainNavigation = () => {
  const sidebarRoutes = [
    {
      id: 'dashboard',
      icon: dashboard,
      name: 'Dashboard',
      link: TMAIL_DASHBOARD,
    },
    {
      id: 'file',
      icon: file,
      name: 'Apps',
      link: TMAIL_DASHBOARD_APPS,
    },
    {
      icon: target,
      name: 'Domains',
      link: DOMAINS,
    },
    {
      icon: eye,
      name: 'Campaign',
      link: CAMPAIGN
    },
   
    {
      id: 'mysql',
      icon: connection,
      name: 'Members',
      link: MEMBERS,
    }
    // {
    //   id: 'tmail',
    //   icon: email,
    //   name: 'Tmail',
    //   link: '/tmail-dashboard',
    // },
  ];

  return <SideBarHandler pages={sidebarRoutes} />;
};

export default MainNavigation;
