/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import ItemSections from "./ItemSections";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { getAppSections } from "../../../services/sync";
import Endpoint from "services/Endpoint";

import {
  addSection,
  addField,
  removeField,
  updateFieldDetail,
  initialAppBuilderState,
} from "../../../redux/actions/appBuilder";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { v4 } from "uuid";

const CreateItem = ({ appSections, history, user }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [itemSections, setItemSections] = useState([]);
  const [appFields, setAppFields] = useState([]);
  const [buttonName, setButtonName] = useState("Save");
  const [appName, setAppName] = useState("");

  const backToGrid = () => {
    history.push(`/dashboard/app/${params.org_id}/${params.id}`);
  };

  async function initPage() {
    let getSectionsQuery = `${params.org_id}/${params.id}`;

    let appSectionsResponse = await getAppSections(getSectionsQuery);

    // Fetch app sections
    if (appSectionsResponse?.data && appSectionsResponse.data[0]) {
      let data = appSectionsResponse?.data[0]?.section.data
        ? appSectionsResponse.data[0].section.data
        : [];
      let fields = appSectionsResponse?.data[0]?.fields
        ? appSectionsResponse.data[0].fields
        : [];
      let payload = {};
      if (data) {
        setButtonName(
          appSectionsResponse.data[0].section.button_name
            ? appSectionsResponse.data[0].section.button_name
            : "Save"
        );
        payload = {
          appSections: data,
          unique_hash: appSectionsResponse?.data[0].section.unique_hash,
          name: appSectionsResponse.data[0].section.name,
          description: appSectionsResponse?.data[0]?.section?.description,
          button_name: appSectionsResponse.data[0].section.button_name,
          background_image:
            appSectionsResponse.data[0].section.background_image,
        };
      } else {
        payload = {
          appSections: [],
          unique_hash: undefined,
          name: undefined,
        };
      }

      dispatch(initialAppBuilderState(payload));

      if (fields && fields.length > 0) {
        setAppFields(fields);
        revampAppBuilder(fields, data);
      }
    }
    setLoading(false);
  }

  const revampAppBuilder = (podioAppFields, sections) => {
    if (!podioAppFields) {
      podioAppFields = appFields;
    }
    const seen = new Set();
    let podioFields = [];

    // Search the App Sections Array of object,
    // To know if the current field is in the appBuilderSections
    // If it's not there, add the current podio field to a new array that will contain details of apps
    podioAppFields.map((field) => {
      let getItem;
      if (sections) {
        let i = 0;
        search_section: while (sections.length > i) {
          let section = sections[i];
          if (section && section.columns) {
            for (let column in section.columns) {
              if (section.columns[column].length > 0) {
                getItem = section.columns[column].find((item) => {
                  return item.field_id === field.field_id;
                });

                if (getItem) {
                  let updatePayload = {
                    config: field.config,
                    status: field.status,
                    label: field.label,
                    fieldId: field.field_id,
                    external_id: field.external_id,
                    sectionId: getItem.section_id,
                    columnType: getItem.column_type,
                  };
                  let payload = {
                    sectionId: getItem.section_id,
                    fieldId: getItem.field_id,
                    columnType: getItem.column_type,
                  };
                  if (field.status === "deleted") {
                    dispatch(removeField(payload));
                  } else {
                    dispatch(updateFieldDetail(updatePayload));
                  }
                  break search_section;
                }
              }
            }
          }

          i++;
        }
        if (!getItem && field.status !== "deleted") {
          podioFields.push(field);
        }
      } else {
        if (field.status !== "deleted") {
          podioFields.push(field);
        }
      }
      return field;
    });
    podioFields = podioFields.filter((el) => {
      const duplicate = seen.has(el.field_id);
      seen.add(el.field_id);
      return !duplicate;
    });

    if (podioFields.length > 0) {
      let defaultId = v4();
      let defaultSection = {
        default_id: defaultId,
        type: "sectionR1C1",
        name: "Section_R1C1",
      };

      dispatch(addSection(defaultSection));
      podioFields.map((field) => {
        field.sectionId = defaultId;
        field.columnType = "first_column";
        dispatch(addField(field));
        return field;
      });
    }
  };
  useEffect(() => {
    setLoading(true);
    let payload = {
      appSections: [],
      unique_hash: undefined,
      name: undefined,
    };
    dispatch(initialAppBuilderState(payload));
    initPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getApp = async () => {
    let response = await Endpoint.singleApp(
      params.id,
      params.org_id,
      user.database.database
    );
    setAppName(response.data.data[0].config.name);
  }

  useEffect(() => {
    getApp();
  }, [])

  useEffect(() => {
    if (appSections.length > 0) {
      setItemSections(appSections);
    }
  }, [appSections]);
  if (loading) {
    return (
      <div className="load__audit">
        <div className="material_block">
          <svg
            className="spinner"
            width="65px"
            stroke="#F0B429"
            height="65px"
            viewBox="0 0 66 66"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              className="circle"
              fill="none"
              strokeWidth="6"
              strokeLinecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    );
  }

  return (
    <div className="file__haven">
      <div className="row">
        <div
          className="col-md-12"
          style={{ cursor: "pointer" }}
          onClick={(e) =>
            history.push(`/dashboard/app/${params.org_id}/${params.id}`)
          }
        >
          <ArrowBackIcon
            style={{ padding: "0px 10px 0 0", marginTop: "-10px", display: "inline-block" }}
            size="small"
          />
          <h5 style={{ display: "inline-block" }}>Back to Table</h5>
          <h4 className="text-center mt-5">Create {appName}</h4>
        </div>
        {/* <h4 className="col-md-6 text-center">Create Item</h4>  */}
      </div>
      <div className="p-10">
        {itemSections.length > 0 ? (
          <ItemSections
            app={params.id}
            org={params.org_id}
            buttonName={buttonName}
            sections={itemSections}
            backToGrid={backToGrid}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  appSections: state.app_builder.appSections,
  user: state.user.authUser,
});

export default connect(mapStateToProps, null)(CreateItem);
