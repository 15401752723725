/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "../../../config";
import { CircularProgress } from "@material-ui/core";
import { useParams, useHistory } from "react-router";
import ModerationGrid from "./ModerationGrid";

const CommentModeration = () => {
  const params = useParams();
  const history = useHistory();
  const [app, setApp] = useState();
  const [loading, setLoading] = useState(false);

  const getConnectedApp = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `/tmail/connected/apps/${params.appId}?id=${params.appId}`
      );
      const res = response.data;
      setApp(res);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getConnectedApp();
  }, []);

  if (loading) {
    return (
      <center>
        <CircularProgress />
      </center>
    );
  }

  const goBack = () => {
    history.goBack();
  };

  return (
    <>
      <h5 className="mb-3">
        <span
          style={{ cursor: "pointer" }}
          onClick={goBack}
          className="text-primary"
        >
          Apps
        </span>{" "}
        {">"} Comment Moderation({app?.app_name})
      </h5>
      <div className="mt-4">
        <h6 className="mb-3">{app?.app_name} Item List</h6>
        <ModerationGrid appId={params.appId} app={app} />
      </div>
    </>
  );
};

export default CommentModeration;
