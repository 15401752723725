import React from "react";
import styled from "@emotion/styled";
import thatapp from "../../../assets/images/thatapp.png";

const Footer = () => {
  return (
    <Footer.Wrapper>
      <div className="footer-container">
        <footer>
          <img src={thatapp} alt="thatapp" />
          <p>Copyright © TECHeGO {new Date().getFullYear()}</p>
          <a href="https://help.thatapp.io/en/articles/108584-privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>
        </footer>
      </div>
    </Footer.Wrapper>
  );
};

Footer.Wrapper = styled.div`
  .footer-container {
    background-color: #f7f7f7;
  }
  footer {
    text-align: center;
    border-top: 1px solid #e1e1e1;
    padding: 33px 0 65px 0;
    margin: 100px 80px 0 80px;
  }
  footer img {
    margin-bottom: 27px;
    width: 300px;
  }
`;
export default Footer;
