import React from 'react';
import {
    CircularProgress,
    Dialog,
    DialogContent,
} from "@material-ui/core";

const SpinnerModal = ({ openModal, handleClose }) => {
    
    return (
        <div>
            <Dialog
                open={openModal}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        openModal(false)
                    }
                }}
                // hideBackdrop={true}
                disableEscapeKeyDown={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                id="connector-modal"
            >
                <DialogContent>
                    <DialogContent>
                        <CircularProgress />
                    </DialogContent>
                    
                </DialogContent>
            </Dialog>
        </div>
    )

}
export default SpinnerModal