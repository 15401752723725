import React from 'react'
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
  parentContainer: {
    // height: "70vh",
    display: "flex", 
    alignItems: "center", 
    justifyContent: "center"
  },
}));

const ModulesLayout = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.parentContainer}>
      {children}
    </div>
  )
}

export default ModulesLayout
