import React from "react";
import { AgGridReact } from "ag-grid-react";
// import * as AllModules from "ag-grid-enterprise";
// import { ModuleRegistry } from "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import MembersAction from "./actions/MembersAction";
import SwitchRole from "./actions/SwitchRole";

// ModuleRegistry.registerModules(Object.keys(AllModules));
const MemberGrid = (props) => {
  const filterParams = {
    filterOptions: ["contains", "notContains"],
    textFormatter: function (r) {
      if (r == null) return null;
      r = r.replace(new RegExp("[àáâãäå]", "g"), "a");
      r = r.replace(new RegExp("æ", "g"), "ae");
      r = r.replace(new RegExp("ç", "g"), "c");
      r = r.replace(new RegExp("[èéêë]", "g"), "e");
      r = r.replace(new RegExp("[ìíîï]", "g"), "i");
      r = r.replace(new RegExp("ñ", "g"), "n");
      r = r.replace(new RegExp("[òóôõøö]", "g"), "o");
      r = r.replace(new RegExp("œ", "g"), "oe");
      r = r.replace(new RegExp("[ùúûü]", "g"), "u");
      r = r.replace(new RegExp("[ýÿ]", "g"), "y");
      return r;
    },
    debounceMs: 0,
    caseSensitive: true,
    suppressAndOrCondition: true,
  };

  let columns;
  let data;

  columns = [
    { headerName: "Space Name", field: "space", rowGroup: true, hide: true },
    {
      headerName: "Member",
      field: "name",
      filter: "agTextColumnFilter",
      filterParams,
    },
    {
      headerName: "Admin",
      field: "role",
      filter: "agTextColumnFilter",
      filterParams,
      cellRenderer: "roleRenderer",
    },
    {
      headerName: "Regular",
      field: "role",
      filter: "agTextColumnFilter",
      filterParams,
      cellRenderer: "roleRenderer",
    },
    {
      headerName: "Light",
      field: "role",
      filter: "agTextColumnFilter",
      filterParams,
      cellRenderer: "roleRenderer",
    },
    {
      headerName: "User Id",
      hide: false,
      field: "user_id",
      filter: "agTextColumnFilter",
      filterParams,
    },

    {
      headerName: "Link",
      field: "link",
      filter: "agTextColumnFilter",
      filterParams,
    },
    { headerName: "Action", field: "actions", cellRenderer: "actionsRenderer" },
  ];

  data = props.audit.data;

  const defaultColDef = {
    flex: 1,
    minWidth: 100,
    filter: true,
    sortable: true,
    resizable: true,
  };
  const autoGroupColumnDef = { minWidth: 200 };

  const frameworks = {
    actionsRenderer: MembersAction,
    roleRenderer: SwitchRole,
  };

  const [gridLoading, setAgrid] = React.useState("Loading...");

  setTimeout(function () {
    setAgrid("No Data");
  }, 6000);

  return (
    <div
      className="ag-theme-balham-dark"
      style={{
        height: "500px",
        width: "100%",
        zIndex: "4",
      }}
    >
      <AgGridReact
        // modules={Object.keys(AllModules)}
        columnDefs={columns}
        defaultColDef={defaultColDef}
        autoGroupColumnDef={autoGroupColumnDef}
        frameworkComponents={frameworks}
        enableRangeSelection={true}
        animateRows={true}
        enableCellChangeFlash={true}
        rowData={data}
        enableCharts={true}
        overlayNoRowsTemplate={gridLoading}
        rowGroupPanelShow="always"
        rowSelection="multiple"
        debug={true}
        sideBar={true}
        enableCellTextSelection={true}
        suppressAggFuncInHeader={true}
        enableFilter={true}
        enableSorting={true}
      />
    </div>
  );
};

export default MemberGrid;
