import React from 'react'
import { TMailSelect } from './TMailSelect'
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
  text_field: {
    background: 'transparent',
    width: '459px',
    height: '58px',
    fontSize: '14px',
    marginBottom: '23px'
  }
}));

const AppFieldInput = ({
  option,
  EmailOptions,
  changeContactField,
  changeSubject,
  subJectOptions,
  changeRefApp,
  AppOptions,
  isloadingRefApp,
  subject,
  contactField,
  refApp,
  //InAppSetting
}) => {
  const classes = useStyles();

  return (
    <div>
      {option === 'single-reference' && <TMailSelect
        required
        placeholder="Select Referenced App"
        className={classes.text_field}
        name='organization'
        value={refApp}
        onChange={changeRefApp}
        options={AppOptions}
      />}

      {(option === 'single' || option === 'single-reference') &&<><TMailSelect
        required
        placeholder="Select Field That Contains Email Address(Es)"
        className={classes.text_field}
        name='organization'
        options={EmailOptions}
        value={contactField}
        onChange={changeContactField}
        isLoading={isloadingRefApp}
        isDisabled={isloadingRefApp}
      />

      <TMailSelect
        required
        placeholder="Subject For Emails"
        className={classes.text_field}
        name='organization'
        options={subJectOptions}
        value={subject}
        onChange={changeSubject}
      /></>}
    </div>
  )
}

export default AppFieldInput
