/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Box, Dialog, DialogContent, LinearProgress } from "@material-ui/core";
import { createMember } from "../../../../redux/actions/tmail";
import { useDispatch } from "react-redux";
import cancelIcon from "../../../../assets/icons/cancel.svg";

const RoleModal = ({ openModal, handleClose, member, spaceMembers, appId }) => {
  const dispatch = useDispatch();
  const [role, setRole] = useState("");
  const [selectedMember, setSelectedMember] = useState();
  const [saveLoading, setSaveLoading] = useState(false);

  const changeIsLoadingSaveApp = () => {
    setSaveLoading(false);
  };

  const handleClose_ = () => {
    handleClose();
  };

  useEffect(() => {
    if (openModal) {
      setRole(member?.role);
      setSelectedMember(member || null);
    }
  }, [openModal]);

  const handleRoleChange = (e) => {
    setRole(e.target.value);
    const newMember = { ...member };
    newMember["role"] = e.target.value;
    setSelectedMember(newMember);
  };

  const updateMemberRole = () => {
    setSaveLoading(true);
    const memberToUpdate = spaceMembers.find(
      (space) => space.user_id === selectedMember.podio_user_id
    );
    memberToUpdate["role"] = role;
    const payload = {
      tmail_app_id: appId,
      podio_users: [memberToUpdate],
    };
    dispatch(createMember(payload, changeIsLoadingSaveApp, handleClose_));
  };

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={handleClose}
        // hideBackdrop={true}
        disableEscapeKeyDown={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogContent>
          <Box style={{ padding: "20px", width: "500px" }}>
            <div className="d-flex align-items-center justify-content-between mb-4">
              <h5>Change Member's Role</h5>
              <img onClick={handleClose} src={cancelIcon} alt="" />
            </div>

            <div className="form-group">
              <select
                className="form-control"
                value={role}
                onChange={(e) => handleRoleChange(e)}
              >
                <option>Select Role</option>
                <option value="member">Member</option>
                <option value="admin">Admin</option>
              </select>
            </div>
            <div className="mt-2">
              {saveLoading ? (
                <center className="mt-3">
                  <LinearProgress />
                </center>
              ) : null}
            </div>
            <div className="d-flex justify-content-end mt-4">
              <button
                type="button"
                style={{
                  color: "#fff",
                  background: "#F7C948",
                }}
                className="btn btn-default"
                onClick={updateMemberRole}
              >
                Update
              </button>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default RoleModal;
