import React from 'react';
import { Box, Dialog, DialogTitle, DialogContent, Typography, DialogActions } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { toast } from "helpers/apiRequests";

import { cloneTemplate } from '../printUtils/requests';

import styles from './cloneTemplate.module.css';

const CloneTemplate = (props) => {
  const { open, handleClose, templateId } = props;
  const [isCloning, setIsCloning] = React.useState(false);

  function handleClone() {
    if (templateId) {
      setIsCloning(true);
      cloneTemplate(templateId)
        .then((res) => {
          toast.notify('Cloned Successfully');
          window.open(`${window.location.origin}/print-data/edit/${res?.data?.id}`, '_blank');
          handleClose();
        })
        .finally(() => setIsCloning(false));
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableEscapeKeyDown={true}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      className={styles.override}
      maxWidth='lg'>
      <Box className={styles.container}>
        <DialogTitle>
          <Typography id='modal-modal-title' variant='h6' component='p'>
            Clone Template
          </Typography>
        </DialogTitle>
        <DialogActions style={{ position: 'absolute', right: 0, top: 0 }}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogContent style={{ padding: 0 }}>
          <section>
            <p>Are you sure you want to clone this Template?</p>
          </section>

          <footer>
            <div className={styles.button_container}>
              <button onClick={handleClose}>Cancel</button>
              <button onClick={handleClone} disabled={isCloning}>
                {isCloning ? 'Cloning...' : 'Confirm'}
              </button>
            </div>
          </footer>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default CloneTemplate;
