import React from 'react';
import Axios from 'config';
// import * as AllModules from 'ag-grid-enterprise';
// import { ModuleRegistry } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import dayjs from 'dayjs';
import Button from '@material-ui/core/Button';

import styles from './TeamMemberLogs.module.scss';
import { toast } from 'helpers/apiRequests';

const defaultColDef = {
  flex: 1,
  minWidth: 10,
  filter: true,
  sortable: true,
  resizable: true,
};

const formatDate = (date) => {
  const value = date.value;
  return dayjs(value).format('MM/DD/YYYY - hh:mm:ss');
};

const autoGroupColumnDef = { minWidth: 50 };
// ModuleRegistry.registerModules(Object.keys(AllModules));

function getTeamMemberLogs(per_page, page) {
  return Axios.get(`/account/team-members/activities?per_page=${per_page}&start=${page}`);
}

const TeamMemberLogs = ({ setViewLogs }) => {
  const filterParams = {
    filterOptions: ['contains', 'notContains'],
    textFormatter: function (r) {
      if (r == null) return null;
      r = r.replace(new RegExp('[àáâãäå]', 'g'), 'a');
      r = r.replace(new RegExp('æ', 'g'), 'ae');
      r = r.replace(new RegExp('ç', 'g'), 'c');
      r = r.replace(new RegExp('[èéêë]', 'g'), 'e');
      r = r.replace(new RegExp('[ìíîï]', 'g'), 'i');
      r = r.replace(new RegExp('ñ', 'g'), 'n');
      r = r.replace(new RegExp('[òóôõøö]', 'g'), 'o');
      r = r.replace(new RegExp('œ', 'g'), 'oe');
      r = r.replace(new RegExp('[ùúûü]', 'g'), 'u');
      r = r.replace(new RegExp('[ýÿ]', 'g'), 'y');
      return r;
    },
    debounceMs: 0,
    caseSensitive: true,
    suppressAndOrCondition: true,
  };

  let columns;

  columns = [
    { headerName: 'Id', field: 'id', hide: true, width: 10 },
    { headerName: 'Timestamp', field: 'created_at', valueFormatter: formatDate },
    { headerName: 'Name', field: 'causer.name' },
    { headerName: 'Email', field: 'causer.email' },
    { headerName: 'Event', field: 'metadata.event' },
    {
      headerName: 'Description',
      field: 'description',
      filter: 'agTextColumnFilter',
      filterParams,
    },
  ];

  const paginationPageSize = 50;
  const cacheBlockSize = 50;

  const ServerSideDatasource = () => {
    return {
      getRows: function (params) {
        getTeamMemberLogs(paginationPageSize, params.request.startRow)
          .then((response) => {
            const data = response.data;
            params.successCallback(data.data, data.pagination.total);
          })
          .catch(() => {
            params.failCallback();
            toast.notify('Failed to fetch Team member logs', { type: 'error' });
          });
      },
    };
  };

  const onGridReady = (params) => {
    const theData = ServerSideDatasource();
    params.api.setServerSideDatasource(theData);
  };

  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <h4>Team Member Activity Logs</h4>
        <Button variant='outlined' size='small' color='primary' onClick={() => setViewLogs(false)} style={{ padding: '6px 9px' }}>
          View Team members
        </Button>
      </div>
      <div
        className='ag-theme-balham-dark'
        style={{
          height: '500px',
          width: '100%',
          margin: '0 auto',
        }}>
        {
          <AgGridReact
            // modules={Object.keys(AllModules)}
            columnDefs={columns}
            defaultColDef={defaultColDef}
            autoGroupColumnDef={autoGroupColumnDef}
            enableRangeSelection={true}
            animateRows={true}
            rowGroupPanelShow='always'
            rowSelection='multiple'
            sideBar={true}
            enableCellTextSelection={true}
            suppressAggFuncInHeader={true}
            enableFilter={true}
            enableSorting={true}
            pagination={true}
            paginationPageSize={paginationPageSize}
            rowModelType='serverSide'
            serverSideStoreType='partial'
            cacheBlockSize={cacheBlockSize}
            onGridReady={onGridReady}
          />
        }
      </div>
    </div>
  );
};

export default TeamMemberLogs;
