import { Button, makeStyles } from '@material-ui/core';
import React from 'react'
import CheckIcon from '../assets/Checkicon.svg'

const useStyles = makeStyles((theme) => ({
  card: {
    width: '598px',
    borderRadius: '24px',
    background: '#FFF',
    boxShadow: '12px 12px 36px 6px rgba(2, 2, 70, 0.12)',
    padding: '32px',
    margin: '0px auto'
  },
  title: {
    color: "#020246",
    fontSize: "18px",
    fontWeight: "500",
    lineHeight: "28px",
    marginBottom: '8px',
    textAlign: 'center'
  },
  text: {
    color: "rgba(0, 0, 0, 0.50)",
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '20px',
    textAlign: 'center'
  },
  highlight: {
    color: '#F0B429',
    textDecorationLine: 'underline',
    cursor: 'pointer'
  },
  check: {
    display: 'block',
    height: '120px',
    width: '120px',
    borderRadius: '100%',
    margin: '0px auto'
  },
  body: {
    display: 'block',
    padding: '20px auto',
    marginBottom: '52px'
  },
  btnNext: {
    color: '#000',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '19px',
    display: 'flex',
    padding: '9.3px 15.5px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '6.2px',
    borderRadius: '6.2px',
    border: '0.775px solid #F0B429',
    background: '#F0B429',
    boxShadow: '6.2px 6.2px 18.6px 0px rgba(2, 2, 70, 0.15)',
    width: '345px',
    margin: '0px auto'
  }
}));

const Success = ({ showActivities, trackingId }) => {
  const classes = useStyles();

  const gotoActivities = () => {
    localStorage.setItem('log-item', JSON.stringify(trackingId))
    showActivities()
  }

  return (
    <div className={classes.card}>
      <img src={CheckIcon} className={classes.check} alt='check' />
      <div className={classes.body}>
        <h5 className={classes.title}>Thanks a lot for putting up this order</h5>
        <p className={classes.text}>
          Your tracking number is <span onClick={gotoActivities} className={classes.highlight}>{trackingId}</span>. You can use this to monitor the progress of your order
        </p>
      </div>
      <Button type='button' onClick={showActivities} className={classes.btnNext}>Done</Button>
    </div>
  )
}

export default Success
