/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import dayjs from 'dayjs'
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import { AgGridReact } from "ag-grid-react";
// import * as AllModules from "ag-grid-enterprise";
// import { ModuleRegistry } from "ag-grid-enterprise";
import Endpoint from "../../../containers/UserInfo/Endpoint";
import CommentItemAction from "./utility/CommentItemAction";

// ModuleRegistry.registerModules(Object.keys(AllModules));
const CommentItemGrid = (props) => {
    const filterParams = {
        filterOptions: ["contains", "notContains"],
        debounceMs: 0,
        caseSensitive: false,
        suppressAndOrCondition: true,
    };

    const openRenderer = (field) =>{
        return <span>{field.data.statistics?.opened}</span>
    }
    const deliveredRenderer = (field) =>{
        return <span>{field.data.statistics?.delivered}</span>
    }
    
    const getToEmail = data => {
        const result = JSON.parse(data.value);
        return result.join(",");
    }

    const dateFormatter = (date) => {
        return dayjs(date.value).format("YYYY-MM-DD HH:ss A");
    }


    const [users] = useState({
        columns: [
            {
                headerName: 'Date',
                field: "date",
                filter: "agTextColumnFilter",
                filterParams,
                valueFormatter: dateFormatter
            },
            {
                headerName: "From",
                field: "from_email",
                filter: "agTextColumnFilter",
                filterParams,
            },
            {
                headerName: "To",
                field: "to",
                filter: "agTextColumnFilter",
                filterParams,
                valueFormatter: getToEmail
            },
            {
                headerName: "Subject",
                field: "subject",
                filter: "agTextColumnFilter",
                filterParams,
            },
            {
                headerName: "Opened",
                field: "statistics",
                cellRendererFramework: openRenderer
            },
            {
                headerName: "Delivered",
                field: "statistics",
                cellRendererFramework: deliveredRenderer
            },

            {
                headerName: "Action",
                field: "actions",
                cellRenderer: "imitateAction"
            },
        ],
        data: [],
    });

    const defaultColDef = {
        flex: 1,
        minWidth: 100,
        filter: true,
        sortable: true,
        resizable: true,
    };
    const autoGroupColumnDef = { minWidth: 200 };
    const frameworks = {
        imitateAction: CommentItemAction
    };
    const paginationPageSize = 100;
    const cacheBlockSize = 100;
    const rowModelType = "serverSide";

    const ServerSideDatasource = () => {
        return {
            getRows: function (params) {
                let query = "";
                if (Object.entries(params.request.filterModel).length > 0) {
                    let search_key = Object.keys(params.request.filterModel).length;
                    if (search_key === 1) {
                        search_key = Object.keys(params.request.filterModel)[0];
                    } else {
                        search_key = Object.keys(params.request.filterModel)[
                            Object.keys(params.request.filterModel).length - 1
                        ];
                    }
                    const filterData = params.request.filterModel[search_key];
                    query = `${query}&search_word=${filterData.filter}&filter_field=${search_key}&filter=${filterData.type}`;
                }

                if (params.request.sortModel.length > 0) {
                    query = `${query}&sort=${params.request.sortModel[0].sort}&sort_field=${params.request.sortModel[0].colId}`;
                }
                const load = {
                    appId: props.item.app.id,
                    itemId: props.item.item_id
                }
                Endpoint.getCommentItems(
                    params.request.startRow,
                    paginationPageSize,
                    query,
                    load
                )
                    .then((res) => {
                        const response = res.data.data;
                        const data = response.comments.map(resp => {
                            return {
                                ...resp.comment,
                                podio_comment_id: resp.podio_comment_id,
                                statistics: resp.statistics,
                                webhooks: resp.webhooks,
                                attachments: resp.attachments,
                                status: resp.delivered_on !== "" ? "DELIVERED" : "",
                                appId: props.item.app.id,
                                email_app: resp.email_app,
                                email_item: props.item
                            }
                        })
                        setTimeout(function () {
                            params.successCallback(data, response.pagination.total,);
                        }, 200);
                    })
                    .catch((err) => {
                        params.failCallback();
                    });
            },
        };
    };

    const onGridReady = (params) => {
        const theData = ServerSideDatasource();
        params.api.setServerSideDatasource(theData);
    };
    

    return (
        <div className="pb-5">

            <div
                className="ag-theme-balham-dark"
                style={{
                    height: "700px",
                    width: "100%",
                }}
            >
                <AgGridReact
                    // modules={Object.keys(AllModules)}
                    columnDefs={users.columns}
                    defaultColDef={defaultColDef}
                    autoGroupColumnDef={autoGroupColumnDef}
                    rowModelType={rowModelType}
                    frameworkComponents={frameworks}
                    enableRangeSelection={true}
                    animateRows={true}
                    // rowData={users.data}
                    onGridReady={onGridReady}
                    rowGroupPanelShow="always"
                    rowSelection="multiple"
                    pagination={true}
                    paginationPageSize={paginationPageSize}
                    cacheBlockSize={cacheBlockSize}
                    debug={true}
                    sideBar={true}
                    enableCellTextSelection={true}
                    suppressAggFuncInHeader={true}
                    enableFilter={true}
                    enableSorting={true}
                    serverSideInfiniteScroll={true}
                >
                </AgGridReact>
            </div>
        </div>
    );
};

export default CommentItemGrid;
