import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ArrowDropDownSharpIcon from '@material-ui/icons/ArrowDropDownSharp';
import { toast } from 'helpers/apiRequests';

import styles from './fields.module.scss';
import CustomTooltip from 'components/templates/print-v2/components/CustomTooltip';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    boxShadow: 'none',
    borderRadius: '0',
    '&:last-child': {
      borderRadius: '0',
    },
    '&:before': {
      height: 0,
    },
  },
  heading: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    fontSize: '14px',
    fontWeight: 400,
    color: '#626262',
  },
  body: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#3B3B3B',
    padding: '6px 6px',
    marginBottom: '4px',
    display: 'block',
  },
}));

export const AccordionItem = ({ label, LabelComponent = undefined, children, expanded, handleExpand }) => {
  const classes = useStyles();

  return (
    <Accordion className={`${styles.expansion_panel} ${classes.root}`} expanded={expanded === label} onChange={handleExpand(label)}>
      <AccordionSummary
        expandIcon={<ArrowDropDownSharpIcon fontSize='medium' />}
        className={classes.heading}
        style={{ flexDirection: 'row-reverse', padding: 0, height: '40px' }}
        aria-controls='panel1a-content'
        id='panel1a-header'>
        {LabelComponent ? <LabelComponent /> : label}
      </AccordionSummary>
      <AccordionDetails className={classes.body}>{children}</AccordionDetails>
    </Accordion>
  );
};

const ExpansionGroup = ({ data, toggleView, setDataToInsert }) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpand = (panel) => (_, expanded) => {
    setExpanded(() => (expanded ? panel : false));
  };

  const handleTableAndLists = React.useCallback((item) => {
    if (item.type === 'table') {
      setDataToInsert(item);
      toggleView('table');
    }

    if (item.type === 'list') {
      setDataToInsert(item);
      toggleView('list');
    }
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePaste = React.useCallback((value) => {
    return () => {
      navigator.clipboard.writeText(value).then(() => {
        toast.notify('Variable copied to clipboard', { type: 'info' });
      });

      if (window.CKEDITOR) {
        const editor = window.CKEDITOR;
        const selection = editor.model.document.selection;
        const position = selection.getFirstPosition();

        window.CKEDITOR.model.change((writer) => {
          return writer.insertText(value, position);
        });

        // hack to prevent variable being pasted multiple times witn enter btn is pressed
        document.activeElement.blur();
      }
      return;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {data?.map((item, index) => {
        return item.items ? (
          <AccordionItem key={`${item.label}-${index}`} label={item.label} expanded={expanded} handleExpand={handleExpand}>
            <ExpansionGroup data={item.items} toggleView={toggleView} setDataToInsert={setDataToInsert} />
          </AccordionItem>
        ) : (
          <CustomTooltip title={item?.hint ? item.hint : item?.label ? item?.label : ''} placement='top' key={`${item.label}-${index}`}>
            {item.type === 'table' || item.type === 'list' ? (
              <button className={styles.expansion_button} onClick={() => handleTableAndLists(item)}>
                {item.label}
              </button>
            ) : (
              <button className={styles.expansion_button} onClick={handlePaste(`{!!${item.variable}!!}`)}>
                {item.label}
              </button>
            )}
          </CustomTooltip>
        );
      })}
    </>
  );
};

export default ExpansionGroup;
