import React, { useEffect, useState } from "react";
import LogsLayout from "./_LogsLayout";
import { CircularProgress, makeStyles } from "@material-ui/core";
import { activityRetry, fetchActivity } from "../services/clone";
import dayjs from "dayjs";
import Space from "../components/Space";
import reload from "../../../assets/icons/reload.svg";
import { formatNumberWithKAndM } from "utils/formatNumberWithKAndM";
import { toast, ToastContainer } from "react-toastify";
// import axios from "axios";

const useStyles = makeStyles((theme) => ({
  modalTitle: {
    color: "#F7C948",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "20px",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "20px",
  },
  gridItem: {
    color: "#515151",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
  },
  bold: {
    fontWeight: "700",
  },
  loader: {
    position: "absolute",
    backgroundColor: "#fff",
    top: "0px",
    left: "0px",
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  border: {
    width: "100%",
    height: "1px",
    background: "#E1E1E1",
    margin: "32px 0",
  },
  th: {
    border: "0px",
    // borderBottom: '1px solid #E1E1E1',
    paddingTop: "21px",
    paddingBottom: "21px",
    color: "#222",
    fontWeight: "600",
    fontSize: "14px",
  },
  td: {
    border: "0px",
    // borderBottom: '1px solid #E1E1E1',
    paddingTop: "21px",
    paddingBottom: "21px",
    color: "#626262",
    fontWeight: "400",
    fontSize: "16px",
    maxWidth: "200px",
  },
  success: {
    border: "0px",
    // borderBottom: '1px solid #E1E1E1',
    paddingTop: "21px",
    paddingBottom: "21px",
    color: "#008000",
    fontWeight: "400",
    fontSize: "16px",
  },
  error: {
    border: "0px",
    // borderBottom: '1px solid #E1E1E1',
    paddingTop: "21px",
    paddingBottom: "21px",
    color: "#FF3333",
    fontWeight: "400",
    fontSize: "16px",
  },
  tableHeaderRow: {
    alignItems: "center",
    backgroundColor: "#fff !important",
  },
  tableBodyRow: {
    // alignItems: 'center',
    backgroundColor: "#fff !important",
    // backgroundColor: 'initial'
  },
  btnWhiteText: {
    backgroundColor: "#F0B429",
    color: "#fff !important",
    borderRadius: "3px",
    textTransform: "capitalize",
    padding: "8px 32px",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "normal",
  },
  whiteSpace: {
    whiteSpace: "nowrap",
  },
  title: {
    color: "#7E7E7E",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    marginBottom: "24px",
  },
  figure: {
    color: "#FADB5F",
    fontSize: "56px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    marginBottom: "5px",
  },
  description: {
    color: "#7E7E7E",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  grid2: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "20px",
    margin: "37px 0",
  },
  gridItem2: {
    borderRadius: "6px",
    padding: "26px 23px",
    background: "#FFF",
    boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.15)",
    textAlign: "center",
  },
  spaceItem: {
    height: "64px",
    width: "100%",
    backgroundColor: "#F7F7F7",
    marginBottom: "16px",
    padding: "23px 32px",
    display: "flex",
    alignItems: "center",
    gap: "40px",
    color: "#222",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    textTransform: "uppercase",
  },
  forReload: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    backgroundColor: "#F0B429",
    padding: "0 32px",
    borderRadius: "5px",
    cursor: "pointer",
  },
  forReloadText: {
    marginTop: "14px",
    color: "white",
  },
  capitalize: {
    textTransform: "capitalize",
  },
  actions: {
    textAlign: "left",
    textTransform: "uppercase",
  },
  actionRetry: {
    cursor: "pointer",
  },
  actionColor: {
    color: "black",
  },
  retryButton: {
    background: "transparent",
    border: "none",
    outline: "none",
    color: "red",
  },
}));

const LogsModal = ({ open, id, handleClose }) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reloadComponent, setReloadComponent] = useState(false);

  // const [show, setShow] = useState(false);

  // const toggleShow = () => {
  //   setShow(prev => !prev)
  // }

  const timeFormatter = (time) => {
    return dayjs(time).format("hh:mm a");
  };

  const reloadPage = () => {
    // Toggle the state to trigger re-render
    setReloadComponent(!reloadComponent);
  };

  // const apiUrl = "/clone/general/transfer/retry-step/:id"; // Replace this with your API endpoint

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await fetchActivity(id);
       
        if (response.success) {
          setData(response.data);
         
        }
      } catch (ex) {
        
      } finally {
        localStorage.removeItem("log-item");
        setLoading(false);
      }
    };
    fetchData();
  }, [id, reloadComponent]);

  const handleRetry = (item) => {
    const transfer_id = item.transfer_id;
    const data = { log_id: item.id };
    const notify = () => toast("Data retrieved successfully")
    const postRetry = async (transfer_id) => {
      try {
        const response = await activityRetry(transfer_id, data);
        if (response.success) {
          notify()
        }
      } catch (ex) {
       
      }
    };
    postRetry(transfer_id);
  };

  return (
    <LogsLayout
      open={open}
      handleClose={handleClose}
      title="Log Detail"
      headerChildren={
        !loading && (
          <>
            <div className={classes.header}>
              <ToastContainer/>
              <div className={classes.grid}>
                <div className={classes.gridItem}>
                  Source Organization :{" "}
                  <span className={classes.bold}>
                    {data?.organization?.source_org_name}
                  </span>
                </div>
                <div className={classes.gridItem}>
                  Transaction ID :{" "}
                  <span className={classes.bold}>{data?.id}</span>
                </div>
                <div className={classes.gridItem}>
                  Destination Organization :{" "}
                  <span className={classes.bold}>
                    {data?.organization?.destination_org_name}
                  </span>
                </div>
                <div className={classes.gridItem}>
                  Total Cost : <span className={classes.bold}></span>
                </div>
                {/* <div className={classes.gridItem}>Source Workspace : <span className={classes.bold}>Sales</span></div>
              <div className={classes.gridItem}>Destination Workspace : <span className={classes.bold}>Transfers</span></div> */}
              </div>
              <div onClick={reloadPage} className={classes.forReload}>
                <img src={reload} alt="reload" />
                <p className={classes.forReloadText}>Reload</p>
              </div>

              {/* <Button className={classes.btnWhiteText} onClick={toggleShow}>
              View all spaces and apps being transferred
            </Button> */}
            </div>
          </>
        )
      }
    >
      {loading && (
        <div className={classes.loader}>
          <CircularProgress size={40} />
        </div>
      )}

      {!loading && (
        <div className={classes.grid2}>
          <div className={classes.gridItem2}>
            <h5 className={classes.title}>Spaces in all Organizations</h5>
            <h1 className={classes.figure}>{data?.spaces_count}</h1>
            <p className={classes.description}>All Spaces</p>
          </div>
          <div className={classes.gridItem2}>
            <h5 className={classes.title}>Apps Across all Spaces</h5>
            <h1 className={classes.figure}>{data?.apps_count}</h1>
            <p className={classes.description}>Total Apps</p>
          </div>
          <div className={classes.gridItem2}>
            <h5 className={classes.title}>File across all Apps</h5>
            <h1 className={classes.figure}>{data?.files_count}</h1>
            <p className={classes.description}>Total Files</p>
          </div>
          <div className={classes.gridItem2}>
            <h5 className={classes.title}>Comments across all Apps</h5>
            <h1 className={classes.figure}>{data?.comments_count}</h1>
            <p className={classes.description}>Total Comments</p>
          </div>
          <div className={classes.gridItem2}>
            <h5 className={classes.title}>Items across all Apps</h5>
            <h1 className={classes.figure}>
              {formatNumberWithKAndM(data?.transferred_items_count)} of{" "}
              {formatNumberWithKAndM(data?.items_count)}
            </h1>
            <p className={classes.description}>Total Items</p>
          </div>
        </div>
      )}

      {!loading &&
        data?.grouped_apps?.map((item, index) => (
          <Space key={index} data={item} />
        ))}

      {!loading && (
        <div className="table-responsive">
          <table className={classes.table}>
            <thead>
              <tr className={classes.tableHeaderRow}>
                <th className={classes.th}>Events</th>
                <th className={classes.th}>Message</th>
                <th className={classes.th}>Time</th>
                <th className={classes.th}>Step</th>
                <th className={classes.th}>Status</th>
                <th className={classes.th}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data?.logs?.map((item, index) => (
                <tr key={index} className={classes.tableBodyRow}>
                  <td className={classes.td}>
                    {item.event.replaceAll("_", " ")}
                  </td>
                  <td className={classes.td}>{item.message}</td>
                  <td className={`${classes.td} ${classes.whiteSpace}`}>
                    {timeFormatter(item.created_at)}
                  </td>
                  <td className={`${classes.td} ${classes.capitalize}`}>
                    {item.step.replaceAll("_", " ")}
                  </td>
                  <td className={classes[item.status]}>
                    {item.status.replaceAll("_", " ")}
                  </td>
                  <td
                    className={`${classes[item.status]} ${classes.actions} ${
                      item.can_retry ? classes.actionRetry : classes.actionColor
                    }`}
                  >
                    {item.can_retry ? (
                      <button
                        className={`${classes.actionRetry} ${classes.retryButton}`}
                        onClick={() => handleRetry(item)}
                      >
                        RETRY
                      </button>
                    ) : (
                      "..."
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* {show && <AppsSpacesModal {...{show, toggleShow, data}} />} */}
    </LogsLayout>
  );
};

export default LogsModal;
