import React, { useState } from 'react';
import AppFieldInput from './AppFieldInput';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';

const NewAppQuestion = (props) => {
  const {
    option,
    changeOption,
    changeContactField,
    changeRefApp,
    changeSubject,
    fields,
    isloadingRefApp,
    subject,
    contactField,
    refApp,
    InAppSetting,
  } = props;
  const [showField, setShowField] = useState(false);
  const optionChange = (e) => {
    setShowField(true);
    changeOption(e.target.value);
  };

  const subJectOptions =
    fields.subject_fields === 0
      ? null
      : fields.subject_fields.map((subFd) => (
          <option key={subFd.id} value={subFd.id}>
            {subFd.name}
          </option>
        ));

  const EmailOptions =
    fields.email_fields === 0
      ? null
      : fields.email_fields.map((subFd) => (
          <option key={subFd.id} value={subFd.id}>
            {subFd.name}
          </option>
        ));

  const AppOptions =
    fields.app_fields === 0
      ? null
      : fields.app_fields.map((subFd) => (
          <option key={subFd.id} value={subFd.app_id}>
            {subFd.name}
          </option>
        ));

  return (
    <div className='d-flex flex-column'>
      <div className='d-flex mb-1'>
        <FormControlLabel
          control={
            <GreyCheckbox
              value='one'
              checked={option === 'one'}
              onChange={optionChange}
              inputProps={{ 'aria-label': 'primary checkbox' }}
              size='small'
            />
          }
          label='not applicable - I will Manually add recipients to each email'
        />
        {/* <div className='form-check'>
          <input className='form-check-input' type='radio' value='one' checked={option === 'one'} onChange={optionChange} />
          <label className='form-check-label sr-only' htmlFor='exampleRadios1'>
            Default radio
          </label>
        </div>
        <span>not applicable - I will Manually add recipients to each email</span> */}
      </div>
      {/* <div className='d-flex mb-2'>

        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            value="two"
            checked={option === "two"}
            onChange={optionChange}
          />
          <label className="form-check-label sr-only" htmlFor="exampleRadios1" >
            Default radio
          </label>
        </div>
        <span>in a Workspace Contacts field in this app.</span>
      </div> */}
      <div className='d-flex mb-1'>
        <FormControlLabel
          control={
            <GreyCheckbox
              value='three'
              checked={option === 'three'}
              onChange={optionChange}
              inputProps={{ 'aria-label': 'primary checkbox' }}
              size='small'
            />
          }
          label='in a Single-line Text field or Email field in this app.'
        />
        {/* <div className='form-check'>
          <input className='form-check-input' type='radio' value='three' checked={option === 'three'} onChange={optionChange} />
          <label className='form-check-label sr-only' htmlFor='exampleRadios1'>
            Default radio
          </label>
        </div>
        <span>in a Single-line Text field or Email field in this app.</span> */}
      </div>
      <div className='d-flex'>
        <FormControlLabel
          control={
            <GreyCheckbox
              value='four'
              checked={option === 'four'}
              onChange={optionChange}
              inputProps={{ 'aria-label': 'primary checkbox' }}
              size='small'
            />
          }
          label='in a Single-line Text field or Email field in a Referenced app.'
        />
        {/* <div className='form-check'>
          <input className='form-check-input' type='radio' value='four' checked={option === 'four'} onChange={optionChange} />
          <label className='form-check-label sr-only' htmlFor='exampleRadios1'>
            Default radio
          </label>
        </div>
        <span>in a Single-line Text field or Email field in a Referenced app.</span> */}
      </div>
      {showField ? (
        <AppFieldInput
          option={option}
          EmailOptions={EmailOptions}
          changeContactField={changeContactField}
          changeSubject={changeSubject}
          subJectOptions={subJectOptions}
          changeRefApp={changeRefApp}
          AppOptions={AppOptions}
          isloadingRefApp={isloadingRefApp}
          subject={subject}
          refApp={refApp}
          contactField={contactField}
          InAppSetting={InAppSetting}
        />
      ) : null}
    </div>
  );
};

export default NewAppQuestion;

const GreyCheckbox = withStyles({
  root: {
    color: 'grey',
    '&$checked': {
      color: 'grey',
    },
  },
  checked: {},
})((props) => <Checkbox color='default' {...props} />);
