import React from "react";
import {
  Dialog,
  DialogContent,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import cancel from "../../../assets/icons/cancel.svg";
import "./style.css";
import "toasted-notes/src/styles.css";

const ViewFieldsV2 = ({ openModal, handleClose, fields }) => {
  return (
    <div id="viewFields">
      <Dialog
        open={openModal}
        onClose={handleClose}
        // hideBackdrop={true}
        disableBackdropClick={false}
        disableEscapeKeyDown={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="connector-modal"
        className="viewFields"
      >
        <DialogContent>
          <Box>
            <div className="d-flex align-items-center justify-content-between w-100">
              <h3
                style={{
                  margin: "20px 0",
                }}
                className="mapper_field_text"
              >
                Mapper Fields
              </h3>
              <div>
                <img onClick={handleClose} src={cancel} alt="" />
              </div>
            </div>
            <div className="box">
              <Table>
                <TableHead>
                  <TableRow>
                    {[
                      "Field Name",
                      "Label",
                      "Type",
                      "Field ID",
                      "External ID",
                    ].map((header) => (
                      <TableCell xs={"auto"} key={header} item>
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fields.map((field, i) => (
                    <TableRow key={field.field_id}>
                      <TableCell component="th" scope="row">
                        {field.name}
                      </TableCell>
                      <TableCell>{field.label}</TableCell>
                      <TableCell>{field.type}</TableCell>
                      <TableCell>{field.field_id}</TableCell>
                      <TableCell>{field.external_id}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ViewFieldsV2;
