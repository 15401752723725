import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

export const CustomSlider = withStyles({
  root: {
    color: '#f7c948',
    height: 5,
  },
  mark: {
    backgroundColor: 'red',
  },
  thumb: {
    height: 15,
    width: 15,
    border: '2px solid currentColor',
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 5,
    borderRadius: 4,
  },
  rail: {
    height: 5,
    borderRadius: 4,
    backgroundColor: '#E1E1E1',
  },
})(Slider);
