/* eslint-disable no-unused-vars */
import React from "react";
import styled from "@emotion/styled";
import Logo from "../../assets/images/logoyellow.svg";
import ConnectCard from "../../components/Cards/ConnectCard";
import { css } from "@emotion/core";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/actions/auth";
import "./Connector.css";
import ArrowRight from "../../assets/icons/arrow-up.svg"

const ConnectTemplate = (props) => {
  const {
    logo = Logo,
    userName = "New Name",
    cards = [],
    onConnectCardClick,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <ConnectTemplate.Wrapper
      backgroundColor={props.backgroundColor}
      textColor={props.textColor}
    >
      <div className="container">
        <div className="header connect">
          <div style={{ display: "flex", alignItems: "flex-start", flexDirection: "column", gap: "10px" }}>
            <img src={Logo} alt="logo" />

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                cursor: "pointer",
                gap: "8px",
              }}
              onClick={() => history.goBack()}
            >
              <img
                src={ArrowRight}
                alt="Go back"
                style={{
                  transform: "rotate(-90deg)",
                  height: "12px",
                  width: "12px",
                }}
              />
              <p style={{ margin: 0, fontSize: "16px" }}>Back</p>
            </div>

          </div>

          <div className="logout">
            <span
              style={{ color: "#F7C948", cursor: "pointer" }}
              onClick={() => {
                dispatch(logout());
                history.push("/login");
              }}
            >
              LOGOUT
            </span>
          </div>
        </div>
        <div className="main-text">
          <h1>Welcome {userName}</h1>
          <p> Choose a CRM to Connect to ThatApp </p>
        </div>
        <div className="display-cards">
          {cards.map((item) => (
            <ConnectCard
              key={item.text}
              image={item.image}
              status={item.status}
              text={item.text}
              onClick={() => onConnectCardClick(item)}
              isLoading={item.isLoading}
            />
          ))}
        </div>
        <footer>
          <p>We are working to bring more CRM’s to you......</p>
        </footer>
      </div>
    </ConnectTemplate.Wrapper>
  );
};

ConnectTemplate.Wrapper = styled.div`
  background: #fffaeb;
  ${(props) =>
    props.backgroundColor &&
    css`
      background-color: ${props.backgroundColor};
    `}
  min-height: 100vh;
  .container {
    .logo-container {
      text-align: center;
    }
    .logo {
      margin-top: 30px;
    }
    .main-text {
      text-align: center;
      h1 {
        font-size: 40px;
        color: #bad360;
        ${(props) =>
    props.textColor &&
    css`
            color: ${props.textColor};
          `}
      }
      p {
        font-size: 16px;
        color: #626262;
      }
    }
    .display-cards {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 33px;
      margin-top: 81px;
      @media screen and (max-width: 1100px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media screen and (max-width: 500px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
    footer {
      text-align: center;
      padding: 40px 0;
    }
  }
  @media screen and (max-width: 1200px) {
    height: 100%;
    /* grid-template-columns: repeat(2, 1fr); */
  }
`;
export default ConnectTemplate;
