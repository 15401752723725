import React from "react";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

const CustomButton = (props) => {
  if (props.tooltip === "true") {
    return (
      <Tooltip title={props.tooltip_title} placement={props.tooltip_placement}>
        <Button variant="contained" color="primary" {...props}>
          {props.children}{" "}
        </Button>
      </Tooltip>
    );
  } else {
    return (
      <Button variant="contained" color="primary" {...props}>
        {props.children}{" "}
      </Button>
    );
  }
};

export default CustomButton;
