import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import "./Teams.scss";
import TeamsList from "./TeamsList";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import TextField from "@material-ui/core/TextField";
import { useDispatch, useSelector } from "react-redux";
import { sendInvite, getTeamMembers } from "../../redux/actions/user";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "react-select";
import ViewTeamMemberOrgs from "./ViewTeamMemberOrgs/ViewTeamMemberOrgs";
import TeamMemberLogs from "./TeamMemberLogs/TeamMemberLogs";
import RenderByPermission from "components/RenderByPermission";
import profileIcon from "../../assets/icons/profileIcon.svg";
import cancel from "../../assets/icons/cancel.svg";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    position: "relative",
    borderRadius: "15px",
  },
  loader: {
    backgroundColor: "rgba(0,0,0,0.3)",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 2,
    display: "grid",
    placeItems: "center",
  },
}));

const Teams = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [viewLogs, setViewLogs] = React.useState(false);

  const [selectedOrgs, setSelectedOrgs] = React.useState([]);
  const [accessType, setAccessType] = React.useState(undefined);

  const [viewSelectedTeamMember, setViewSelectedTeamMember] =
    React.useState(false);
  const [selectedMember, setSelectedMember] = React.useState(undefined);

  const handleOrgChange = (val) => {
    if (val) {
      setSelectedOrgs(val);
    } else {
      setSelectedOrgs([]);
    }
  };

  const handleAccessTypeChange = (val) => {
    if (val?.value) {
      setAccessType(val?.value);
    } else {
      setAccessType(undefined);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const dispatch = useDispatch();
  const invitation = useSelector((state) => state.user.invite);
  const teamMembers = useSelector((state) => state.user.teamMembers.data);
  const isProcessingGetTeamMemberRequest = useSelector(
    (state) => state.user.teamMembers.processing
  );

  const orgs = useSelector((state) => state.sync.orgs);
  const orgOptions = React.useMemo(
    () =>
      orgs?.map((org) => ({
        label: `${org?.name} - ${org.org_id}`,
        value: org.org_id,
      })) ?? [],
    [orgs]
  );

  const handleClose = () => {
    setOpen(false);
    dispatch(getTeamMembers());
  };

  React.useEffect(() => {
    if (invitation.processed) {
      dispatch(getTeamMembers());
      setName("");
      setEmail("");
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitation.processed]);

  const handleSend = () => {
    if (email !== "" && name !== "" && selectedOrgs.length > 0 && accessType) {
      const org_ids = selectedOrgs.map((org) => org.value);
      dispatch(sendInvite({ email, name, org_ids, role_id: accessType }));
    } else {
      return;
    }
  };

  if (viewLogs)
    return (
      <RenderByPermission allowTeamMemberAccess={false}>
        <TeamMemberLogs setViewLogs={setViewLogs} />
      </RenderByPermission>
    );

  return (
    <div style={{ overflowY: "auto", height: "75vh" }} className="teams">
      {viewSelectedTeamMember ? (
        <ViewTeamMemberOrgs
          open={viewSelectedTeamMember}
          toggle={setViewSelectedTeamMember}
          selectedMember={selectedMember}
          setSelectedMember={setSelectedMember}
        />
      ) : null}

      <div className="header">
        <div className="left">
          <h5 style={{ fontSize: "18px", fontWeight: "bold" }}>Teams</h5>
          <p style={{ fontSize: "15px", color: "#7E7E7E" }}>
            You have{" "}
            {teamMembers?.length === 1
              ? `1 Member`
              : teamMembers?.length > 1
              ? `${teamMembers?.length} Members`
              : "No Members"}{" "}
            on your team.
          </p>
        </div>
        <div className="right">
          <RenderByPermission allowTeamMemberAccess={false}>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              onClick={() => setViewLogs(true)}
              style={{ width: "118px", height: "45px" }}
            >
              View Logs
            </Button>
          </RenderByPermission>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: "2rem", width: "146px", height: "45px" }}
            onClick={handleOpen}
          >
            Invite Member
          </Button>
        </div>
      </div>
      {/* <div className="role_details">
        <h3>Roles</h3>
        <p>
          <span>Super Admin:</span> Control over billing, add & resync orgs.
          invite & reject team members.
        </p>
        <p>
          <span>Team Member:</span> Read and write access to functions for
          specific organizations. Can invite other team members.
        </p>
        <p>
          <span>Partner: </span>
          Read-only access for partner managed organizations.
        </p>
      </div> */}
      <div className="users">
        {teamMembers === null
          ? false
          : teamMembers.map((item) => {
              return (
                <TeamsList
                  id={item.id}
                  key={item.email}
                  image={profileIcon}
                  name={item.name}
                  role={item.role}
                  email={item.email}
                  status={item?.status}
                  organizations={item?.organizations}
                  setViewSelectedTeamMember={setViewSelectedTeamMember}
                  setSelectedMember={setSelectedMember}
                  isLoading={isProcessingGetTeamMemberRequest}
                />
              );
            })}
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            {invitation.processing && (
              <div className={classes.loader}>
                <CircularProgress />
              </div>
            )}
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h5 id="transition-modal-title">Invite a Team Member</h5>
              <img src={cancel} alt="" />
            </div>
            <p id="transition-modal-description">
              Invite team members by email and choose their priviledges
            </p>
            <div className="input-fields-container">
              <TextField
                variant="outlined"
                type="text"
                label="Name"
                onChange={(e) => setName(e.target.value)}
                value={name}
                fullWidth
                style={{ marginBottom: "1rem" }}
              />
              <TextField
                variant="outlined"
                type="email"
                label="Email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                fullWidth
              />
              <div className="mt-3">
                <Select
                  isClearable
                  placeholder="Select Organizations"
                  options={orgOptions}
                  onChange={handleOrgChange}
                  isMulti={true}
                  theme={(theme) => {
                    return {
                      ...theme,
                      colors: {
                        ...theme.colors,
                        ...customTheme,
                      },
                    };
                  }}
                />
              </div>
              <div className="mt-3">
                <Select
                  isClearable
                  placeholder="Select Access Type"
                  options={[
                    { label: "Team Member", value: 1 },
                    { label: "Partner ", value: 2 },
                  ]}
                  onChange={handleAccessTypeChange}
                  isMulti={false}
                  theme={(theme) => {
                    return {
                      ...theme,
                      colors: {
                        ...theme.colors,
                        ...customTheme,
                      },
                    };
                  }}
                />
              </div>
              <br />
              <br />
              <Button
                variant="contained"
                size="large"
                fullWidth
                color="primary"
                style={{ marginRight: "4rem" }}
                onClick={handleSend}
                disabled={
                  !email || !name || selectedOrgs.length < 1 || !accessType
                }
              >
                Send Invite
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default Teams;

const customTheme = {
  primary25: "rgba(252, 175, 59, 0.2)",
  primary: "rgba(252, 175, 59, 0.9)",
  primary50: "rgba(252, 175, 59, 0.5)",
  primary75: "rgba(252, 175, 59, 0.7)",
};
