import React from "react";
import lock from "../../../assets/icons/lockIcon.svg";
import unlock from "../../../assets/icons/unlockIcon.svg";
// import cancelRed from "../../../assets/icons/cancelRed.svg";
import cancel from "../../../assets/icons/cancelIcon.svg";
import { makeStyles } from "@material-ui/core";
import {
  enableHaven,
  getSyncedOrganizations,
  deleteHaven,
} from "services/sync";
import { toast } from "helpers/apiRequests";
import { useDispatch } from "react-redux";
import { setOrgs } from "redux/actions/sync";
import Polygon from "../../../assets/icons/Polygon.svg";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "0 1%",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    backgroundColor: "#fff",
    borderRadius: "10px",
    boxShadow: "0px 0px 50px 0px rgba(0,0,0,0.1)",
    marginTop: "1%",
    color: "black",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    margin: "2% 1% 0 1%",
    width: "100%",
    alignItems: "center",
  },
  redBtn: {
    backgroundColor: "#fff",
    color: "#E12D39",
    padding: "1% 1% 1% 1%",
    border: "none",
    cursor: "pointer",
    width: "120px",
    fontSize: "13px",
    fontWeight: "700",
    marginBottom: "2%",
  },
  yellowBtn: {
    backgroundColor: "#fff",
    color: "#F7C948",
    padding: "1% 1% 1% 1%",
    border: "none",
    cursor: "pointer",
    width: "120px",
    fontSize: "13px",
    fontWeight: "700",
    marginBottom: "2%",
  },
  infoText: {
    display: "flex",
    margin: "2% 0 0 0",
    fontSize: "15px",
  },
  image: {
    width: "4%",
  },
  imageInd: {
    position: "absolute",
    marginLeft: "-90%",
    marginTop: "-27%",
    width: "30px",
  },
}));

const FileInfoPop = ({
  isProtected,
  org,
  handleFilePopClose,
  orgId,
  count,
  fileCount,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const startFileHaven = (org) => {
    enableHaven(`?org_ids[]=${orgId}`)
      .then(() => {
        toast.notify("File haven has been activated successfully");
        getSyncedOrganizations().then((res) => {
          dispatch(setOrgs(res.data));
          // props.setOrgs(res.data);
        });
      })
      .catch((err) => {});
  };

  const startFileHavenDeletion = (org) => {
    deleteHaven(`?org_ids[]=${orgId}`)
      .then((res) => {
        toast.notify("File haven has been deactivated successfully");
        getSyncedOrganizations().then((res) => {
          dispatch(setOrgs(res.data));
          // props.setOrgs(res.data);
        });
      })
      .catch((err) => {});
  };

  function formatNumber(num) {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(2) + "M";
    } else {
      return num.toLocaleString();
    }
  }

  return (
    <div onMouseLeave={handleFilePopClose} className={classes.container}>
      <img className={classes.imageInd} src={Polygon} alt="" />
      <div className={classes.header}>
        {isProtected === 1 ? (
          <img className={classes.image} src={lock} alt="lock" />
        ) : (
          <img className={classes.image} src={unlock} alt="unlock" />
        )}
        <h5 style={{ fontSize: "12px", fontWeight: "700" }}>
          File Haven Protection
        </h5>
        <img onClick={handleFilePopClose} src={cancel} alt="cancel" />
      </div>
      <div className={classes.infoText}>
        {isProtected === 1 ? (
          <h5 style={{ fontSize: "12px" }}>
            All{" "}
            <span style={{ fontWeight: "700" }}>
              {count ? formatNumber(Number(fileCount)) : 0} Files
            </span>{" "}
            on <span style={{ fontWeight: "700" }}>{org}</span> organisation are
            protected
          </h5>
        ) : (
          <h5 style={{ fontSize: "12px" }}>
            All{" "}
            <span style={{ fontWeight: "700" }}>
              {count ? formatNumber(Number(fileCount)) : 0} Files
            </span>{" "}
            on <span style={{ fontWeight: "700" }}>{org}</span> organisation are
            not protected
          </h5>
        )}
      </div>
      <div style={{ width: "100%" }} className="d-flex justify-content-end">
        {isProtected === 1 ? (
          <div style={{ width: "100%" }} className="d-flex justify-content-end">
            <button
              onClick={() => {
                startFileHavenDeletion();
              }}
              className={classes.redBtn}
            >
              Disable file haven
            </button>
          </div>
        ) : (
          <div style={{ width: "100%" }} className="d-flex justify-content-end">
            <button
              onClick={() => {
                startFileHaven();
              }}
              className={classes.yellowBtn}
            >
              Enable file haven
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FileInfoPop;
