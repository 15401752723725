import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import cancelIcon from "../../../../assets/icons/cancel.svg";
import { deleteAppMember } from "../../../../redux/actions/tmail";

const DeleteMember = ({ openModal, handleClose, item, appId }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    handleClose();
  };

  const changeLoading = () => setLoading(false);

  const deleteDefaultMail = (id) => {
    setLoading(true);
    const payload = {
      tmail_app_id: appId,
      delete_member: 1,
      id,
      member_ids: [id],
    };

    dispatch(deleteAppMember(payload, closeModal, changeLoading));
  };

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={handleClose}
        // hideBackdrop={true}
        disableBackdropClick={false}
        disableEscapeKeyDown={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="connector-modal"
      >
        <DialogContent>
          <DialogContent>
            <div className="d-flex align-items-center justify-content-between mb-4">
              <h5>Delete Member</h5>
              <img onClick={handleClose} src={cancelIcon} alt="" />
            </div>
            <DialogContentText id="alert-dialog-description">
              <Typography gutterBottom>
                You are about to delete a member from this app.
                <br />
                Would you like to proceed?
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              <Button
                id="button-save"
                onClick={() => {
                  deleteDefaultMail(item.id);
                }}
                color="primary"
                autoFocus
              >
                Yes
              </Button>
            )}
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DeleteMember;
