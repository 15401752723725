import React from 'react'
import Tooltip from "@material-ui/core/Tooltip";
import './style.scss'

const Button = e => {
    if(e.tooltip){
        return <Tooltip title={e.tooltip_title} placement={e.tooltip_placement}>
            <button
                type={e.type}
                className={`${e.class} style-button`}
                style={e.style}
                onClick={e.function}
                disabled={e.disabled}
            >{e.text}</button>
        </Tooltip>
    } else {
        return <button
            type={e.type}
            className={`${e.class} style-button`}
            style={e.style}
            onClick={e.function}
            disabled={e.disabled}
        >{e.text}</button>
    }
    
}

export default Button
