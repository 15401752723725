import React, { useState } from "react";
import logo from "../../../assets/images/logo.png";
import eyeShow from "../../../assets/images/eye_show.svg";
import podio from "../../../assets/images/podio_form.svg";
import eyeHide from "../../../assets/images/eye_hide.svg";
// import bg from "../../../assets/images/authbg.png";
// import Asterisk from "../../../components/Common/Asterisk";
import { Link, useLocation } from "react-router-dom";
import { registerUser } from "../../../services/auth";
import "../register.css";

const NewRegister = (props) => {
  const { search } = useLocation();
  const podioPartner = new URLSearchParams(search).get("partner");

  const getEmailQuery = new URLSearchParams(props.location.search).get("email");
  let queryEmail = getEmailQuery ? getEmailQuery : "";

  // const [passwordType, setPasswordType] = useState("password");
  const [loading, setLoading] = useState(false);
  const [terms, setTerms] = useState(false);
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: queryEmail,
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });
  };

  // const hidePassword = () => {
  //   if (passwordType === "password") {
  //     setPasswordType("text");
  //     return;
  //   }

  //   setPasswordType("password");
  // };

  const authorizeWithPodio = async () => {
    const url = `${window.location.origin}/login`;

    const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=0,height=0,left=-1000,top=-1000`;
    const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
    window.open(
      `https://podio.com/oauth/authorize?client_id=${CLIENT_ID}&redirect_uri=${url}&scope=global:all&response_type=token`,

      "login",
      params
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const url = new URL(window.location.href);
    const invite_code = url.searchParams.get("invite_code");
    const { firstName, lastName, email, password } = data;
    const userDetails = {
      name: firstName + " " + lastName,
      email,
      password,
    };

    if (invite_code !== null) {
      userDetails.invite_code = invite_code;
    }
    if (podioPartner !== null) {
      userDetails.podioPartner = podioPartner;
    }

    try {
      const response = await registerUser(userDetails);

      if (response.status) {
        setLoading(false);
      } else {
        setLoading(false);
      }
      localStorage.removeItem("partnerId");

      //Redirect the user
      props.history.push("/account/confirm-email");
    } catch (x) {
      setLoading(false);
    }
  };

  return (
    <div className="auth__forms__cont">
      <div className="auth__title__cont">
        <Link className="home__link" to="/">Home</Link>

        <h2>
          Already have an account?{" "}
          <span>
            <Link to="/login">Login</Link>
          </span>
        </h2>
      </div>
      <div className="auth__form__wrapper">
        <form onSubmit={handleSubmit} className="create__form">
          <div className="create__logo__cont">
            <img src={logo} alt="thatapplogo" />
            <h2>Create new account</h2>
          </div>

          <button
            style={{ marginTop: "15px" }}
            type="button"
            onClick={() => authorizeWithPodio()}
            className="create__podio"
          >
            <img src={podio} alt="podio" />
            <p>Sign up with Podio</p>
          </button>
          <div className="or__divide__cont">
            <div></div>
            <p>OR</p>
            <div></div>
          </div>
          <div className="create__inputs__cont">
            <div className="inputs__cont">
              <label htmlFor="firstName">First Name</label>
              <input
                className="inputs__cont__input"
                type="text"
                value={data.firstName}
                onChange={handleChange}
                name="firstName"
                id="firstName"
                required
                placeholder="John"
              />
            </div>
            <div className="inputs__cont">
              <label htmlFor="lastName">Last Name</label>
              <input
                className="inputs__cont__input"
                type="text"
                name="lastName"
                id="lastName"
                value={data.lastName}
                onChange={handleChange}
                required
                placeholder="Doe"
              />
            </div>
            <div className="inputs__cont">
              <label htmlFor="email">Email Address</label>
              <input
                className="inputs__cont__input"
                type="email"
                name="email"
                id="email"
                value={data.email}
                onChange={handleChange}
                required
                placeholder="johndoe@thatapp.io"
              />
            </div>
            <div className="inputs__cont">
              <label htmlFor="password">Password</label>
              <div className="create__pass__cont">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                  placeholder="Password123@"
                  value={data.password}
                  onChange={handleChange}
                  required
                />
                <div
                  onClick={() => setShowPassword(!showPassword)}
                  className="create__eye__cont"
                >
                  <img src={showPassword ? eyeHide : eyeShow} alt="show" />
                </div>
              </div>
            </div>
          </div>

          <div className="create__form__terms">
            <input
              className="create__form__terms__input check__input"
              type="checkbox"
              onChange={(e) => setTerms(e.target.checked)}
              id="terms"
            />
            <label className="create__form__terms__label" htmlFor="terms">
              By Signing Up You Agree To Our{" "}
              <span>
                <a
                  className="link__text"
                  href="https://help.thatapp.io/en/articles/108584-privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </span>{" "}
              And
              <span>
                {" "}
                <a
                  className="link__text"
                  target="_blank"
                  rel="noreferrer"
                  href="https://help.thatapp.io/en/articles/9392123-terms-of-service"
                >
                  Terms of Service.
                </a>
              </span>
            </label>
          </div>
          <button disabled={!terms || loading} className="form__bottom__button">
            {loading ? "Loading..." : "Create account"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default NewRegister;
