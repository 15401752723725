import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrgs, getSpaces, getApps } from '../../../../redux/actions/print';

const useTemplateDetails = () => {
  const dispatch = useDispatch();
  const organizatons = useSelector((state) => state.print.organisations);
  const spaces = useSelector((state) => state.print.spaces);
  const apps = useSelector((state) => state.print.apps);

  const [orgsData, setorgsData] = useState([]);
  const [spaceData, setspaceData] = useState([]);
  const [appData, setappData] = useState([]);

  const [selectedOrganization, setSelectedOrganization] = useState('');
  const [selectedWorkspace, setSelectedWorkspace] = useState('');
  const [selectedApplication, setSelectedApplication] = useState('');

  useEffect(() => {
    if (!organizatons.processed && !organizatons.success) {
      dispatch(getOrgs());
    }
  }, [dispatch, organizatons.processed, organizatons.success]);

  useEffect(() => {
    if (organizatons.success) {
      setorgsData(
        organizatons.data.map((item) => {
          return { value: item.org_id, label: `${item.name} - ${item.org_id}` };
        })
      );
    }
  }, [organizatons.data, organizatons.success]);

  useEffect(() => {
    if (spaces.success) {
      setspaceData(
        spaces?.data?.map((item) => {
          return { value: item.space_id, label: item.name };
        })
      );
    }
  }, [spaces.data, spaces.success]);

  useEffect(() => {
    if (apps.success) {
      setappData(
        apps.data.map((item) => {
          return { value: item.app_id, label: item.url_label };
        })
      );
    }
  }, [apps.data, apps.success]);

  const handleOrgs = (event) => {
    setSelectedWorkspace("");
    setSelectedApplication("");

    setSelectedOrganization(event.target.value);
    dispatch(getSpaces({ id: event.target.value }));
  };

  const handleApps = (event) => {
    setSelectedApplication("");

    setSelectedWorkspace(event.target.value);
    dispatch(getApps({ id: event.target.value }));
  };

  const selectApp = (event) => {
    setSelectedApplication(event.target.value);
  };

  return { orgsData, spaceData, appData, selectedOrganization, selectedWorkspace, selectedApplication, handleOrgs, handleApps, selectApp };
};

export default useTemplateDetails;
