import React, { useCallback, useEffect } from "react";

import { connect, useDispatch } from "react-redux";
import { setUser } from "../redux/actions/user";
import { imitate_account } from "../services/auth";
import { setAuditOrg } from "../redux/actions/orgs";
import { setNav } from "../redux/actions/sidebar";

const ImitateAccount = (props) => {
  const dispatch = useDispatch();
  const user_id = new URLSearchParams(props.location.search).get("task");


  // if (!props.user.type || !user_id) {
  //   if(!props.user.user_is_podio_partner){
  //     alert("You're unauthorized!");
  //     window.location.href = "/service?reloadagain=true";

  //   }
  // }

  const setNewUser = useCallback(async () => {
    const imitate = async () => {
      const response = await imitate_account(`?user_id=${user_id}`);

      if (response.data) {
        //Old
        setAuditOrg(null);
        dispatch(setNav(null));
        // localStorage.clear();
        localStorage.removeItem("chosen_token");
        localStorage.removeItem("team_member_uuid");
        //New
        dispatch(setUser(response.data));
        localStorage.setItem("chosen_token", response.data.access_token);

        window.location.href = "/service?reloadagain=true";
      } else {
        alert("You're unauthorized!");
        window.history.back();
      }
    };

    await imitate();
  }, [dispatch, user_id]);

  useEffect(() => {
    setNewUser();
  }, [setNewUser]);

  return (
    <div className="material_block">
      <svg
        className="spinner"
        width="65px"
        height="65px"
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className="circle"
          fill="#fff"
          strokeWidth="6"
          strokeLinecap="round"
          cx="33"
          cy="33"
          r="30"
        ></circle>
      </svg>
    </div>
  );
};

const mapDisptachToProps = (dispatch) => ({
  setAuditOrg: (data) => dispatch(setAuditOrg(data)),
});

const mapStatetoProps = (state) => ({
  user: state.user.authUser,
});

export default connect(mapStatetoProps, mapDisptachToProps)(ImitateAccount);
