/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import ConnectCard from './ConnectCard';
import MyModal from "../../../components/molecule/Modal";
import ConnectModal from "../../Connector/ConnectModal";
import Podio from "../../../assets/images/podioconnect.png";
import { authorizeTmail, parseHashString } from "../../Connector/sharedFunctions";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button";
import printLogo from "../../../assets/images/printlogo.png";

const Connect = () => {
    const [selectedConnector] = useState({
        title: "Podio",
        image: Podio
    });
    const [openModal, setOpenModal] = useState(false);
    const closeAuthorizationModal = () => {
        setOpenModal(false);
    }
    const [confirmOpen, setConfirmOpen] = useState({
        open: false,
        data: null,
    });
    const confirm_handleClose = () => {
        setConfirmOpen({ open: false, data: null });
    };
    const [connecting, setConnecting] = useState({
        connector: null,
        loading: false,
    });
    const confirmProtectOrgs = () => {

    }

    return (
        <div className='container'>
            <div className="row justify-content-center">
                <ConnectCard 
                image={Podio}
                text="Authorise Tmail with Podio"
                />
                <MyModal
                    open={openModal}
                    close={closeAuthorizationModal}
                >
                    <ConnectModal
                        info={selectedConnector}
                        handleConnect={() =>
                            authorizeTmail(selectedConnector, setConnecting, "/dashboard/haven")
                        }
                        handleClose={closeAuthorizationModal}
                    />
                </MyModal>
                <Dialog
                    open={confirmOpen.open}
                    onClose={confirm_handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">File Haven</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Do you want to enable file haven for a organisations?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={confirm_handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => confirmProtectOrgs()} color="primary">
                            Yes I am Sure
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )
}

export default Connect