import React, { useState, useEffect } from "react";
import axios from "../../config";
import { CircularProgress } from "@material-ui/core";
import Swal from "sweetalert2";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backButton: {
    backgroundColor: "#f7c948",
    boxShadow: "0px 3px 6px #00000029",
    padding: "1vh 1vw",
    outline: "none",
    textDecoration: "none",
    border: "none",
    borderRadius: "6px",
    color: "white",
    marginBottom: "1%",
  },
}));
const MyPartner = () => {
  const classes = useStyles();

  const [partners, setPartner] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const url = "/auth/allowed-users";
    setLoading(true);
    axios
      .get(url)
      .then((res) => {
        setLoading(false);
       
        setPartner(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  const imitateAccount = (user) => {
    const x = user.user_id_extension;
    if (x.toLowerCase() === "not connected") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Podio is not connected for this user, you can't imitate their account at this time.",
      });
      return false;
    } else {
      window.location.href = `/imitate-account?imitate_user=true&task=${user.id}`;
    }
  };

  if (loading) {
    return (
      <center>
        <CircularProgress size={40} />
      </center>
    );
  }

  const handleBackButtonClick = () => {
    window.history.back();
  };

  return (
    <div>
      <div>
        <button onClick={handleBackButtonClick} className={classes.backButton}>
          Back
        </button>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-10">
          <h4>Podio Partner</h4>
          <div className="table-responsive">
            <table className="table table-bordered table-striped">
              <thead>
                <th>#</th>
                <th>UserId</th>
                <th>Name</th>
                <th>Email</th>
                <th>PodioId</th>
                <th>Action</th>
              </thead>
              <tbody>
                {partners.length > 0
                  ? partners.map((item, i) => {
                      return (
                        <tr key={item.id}>
                          <td> {i + 1} </td>
                          <td> {item.id} </td>
                          <td> {item.name} </td>
                          <td> {item.email} </td>
                          <td> {item.user_id_extension} </td>
                          <td>
                            <i
                              className="fa fa-eye"
                              style={{
                                fontSize: "20px",
                                marginRight: "10px",
                                cursor: "pointer",
                                color: "#F0B429",
                              }}
                              title="Imititate"
                              onClick={() => imitateAccount(item)}
                            ></i>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyPartner;
