import { takeLatest, call } from "redux-saga/effects";
import api, { userRequest } from "../../services";
import {
  SEND_INVITE,
  DELETE_INVITE,
  GET_TEAM_MEMBERS,
  GET_PENDING_INVITATION,
  GET_ME,
  DELETE_PODIO,
} from "../constants/user";
import safeSaga from "../../helpers/safeSaga";
import { successHandler } from "../../helpers/apiRequests";

function* sendInvite({ payload }) {
  const response = yield call([api, "post"], userRequest.INVITE, payload);

  yield call(successHandler, response, SEND_INVITE.success, "User Invited Successfully");
}

function* deleteInvite({ payload }) {
  const response = yield call([api, "post"], userRequest.DELETE_INVITE, payload);

  yield call(successHandler, response, DELETE_INVITE.success, true);
}

function* getMembers() {
  const response = yield call([api, "get"], userRequest.TEAM_MEMBERS);

  yield call(successHandler, response, GET_TEAM_MEMBERS.success);
}

function* getPendingInvitation() {
  const response = yield call([api, "get"], userRequest.PENDING_INVITATION);

  yield call(successHandler, response, GET_PENDING_INVITATION.success);
}

function* getMe() {
  const response = yield call([api, "get"], userRequest.ME);

  yield call(successHandler, response, GET_ME.success);
}

function* deletePodio() {
  const response = yield call([api, "post"], userRequest.DELETE_PODIO);

  // yield call(getMe);
  yield call(
    successHandler,
    response,
    DELETE_PODIO.success,
    "Successfully Disconnected Account from Podio"
  );
}

export default function* usersSaga() {
  yield takeLatest(
    SEND_INVITE.request,
    safeSaga(sendInvite, SEND_INVITE.error)
  );
  yield takeLatest(
    DELETE_INVITE.request,
    safeSaga(deleteInvite, DELETE_INVITE.error)
  );
  yield takeLatest(
    GET_TEAM_MEMBERS.request,
    safeSaga(getMembers, GET_TEAM_MEMBERS.error)
  );
  yield takeLatest(
    GET_PENDING_INVITATION.request,
    safeSaga(getPendingInvitation, GET_PENDING_INVITATION.error)
  );
  yield takeLatest(GET_ME.request, safeSaga(getMe, GET_ME.error));
  yield takeLatest(
    DELETE_PODIO.request,
    safeSaga(deletePodio, DELETE_PODIO.error)
  );
}
