import React, { useState } from "react";
import { partnerApply } from "../../services/partner";

import "./Partner.css";

const Partner = () => {
  const [data, setData] = useState({
    fullName: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await partnerApply(data);
  };

  return (
    <div className="partner">
      <div className="row mt-4 mb-4">
        <div className="col-md-12">
          <h3>Apply as a Partner</h3>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="form-group col-md-4">
            <label>Full Name</label>
            <input
              className="form-control"
              type="text"
              name="fullName"
              value={data.fullName}
              onChange={handleChange}
            />
          </div>
          <div className="form-group col-md-4">
            <label>Email</label>
            <input
              className="form-control"
              type="email"
              name="email"
              value={data.email}
              onChange={handleChange}
            />
          </div>
          <div className="form-group col-md-4">
            <label>Phone Number</label>
            <input
              className="form-control"
              type="number"
              name="phone"
              value={data.phone}
              onChange={handleChange}
            />
          </div>
          <div className="form-group col-md-4">
            <label>Address</label>
            <input
              className="form-control"
              type="text"
              name="address"
              value={data.address}
              onChange={handleChange}
            />
          </div>
          <div className="form-group col-md-4">
            <label>City</label>
            <input
              className="form-control"
              type="text"
              name="city"
              value={data.city}
              onChange={handleChange}
            />
          </div>
          <div className="form-group col-md-4">
            <label>State</label>
            <input
              className="form-control"
              type="text"
              name="state"
              value={data.state}
              onChange={handleChange}
            />
          </div>
          <div className="form-group col-md-4">
            <label>Country</label>
            <input
              className="form-control"
              type="text"
              name="country"
              value={data.country}
              onChange={handleChange}
            />
          </div>
          <div className="form-group col-md-4">
            <label>
              Payout Name <small>(Name on bank account)</small>
            </label>
            <input className="form-control" type="text" />
          </div>
          <div className="form-group col-md-4">
            <label>Bank Account Type </label>
            <input className="form-control" type="text" />
          </div>
          <div className="form-group col-md-4">
            <label>Bank Account Class </label>
            <input className="form-control" type="text" />
          </div>
          <div className="form-group col-md-4">
            <label>Bank ID</label>
            <input className="form-control" type="text" />
          </div>
          <div className="form-group col-md-4">
            <label>Bank Country</label>
            <input className="form-control" type="text" />
          </div>
          <div className="form-group col-md-4">
            <label>Bank State</label>
            <input className="form-control" type="text" />
          </div>
          <div className="form-group col-md-4">
            <label>Bank City</label>
            <input className="form-control" type="text" />
          </div>
          <div className="form-group col-md-4">
            <label>Bank Address </label>
            <input className="form-control" type="text" />
          </div>
          <div className="form-group col-md-4">
            <label>Bank Zip Code</label>
            <input className="form-control" type="text" />
          </div>
          <div className="form-group col-md-4">
            <label>Bank Account ID </label>
            <input className="form-control" type="text" />
          </div>
        </div>
        <div className="col-md-12 text-center">
          <button className="btn">Apply</button>
        </div>
      </form>
    </div>
  );
};

export default Partner;
