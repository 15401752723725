// src/redux/selectedItemsSlice.js

// Action Types
const ADD_ITEM = 'selectedItems/addItem';
const REMOVE_ITEM = 'selectedItems/removeItem';

// Action Creators
export const addItem = (item) => ({
  type: ADD_ITEM,
  payload: item,
});

export const removeItem = (itemId) => ({
  type: REMOVE_ITEM,
  payload: itemId,
});

// Initial State
const initialState = [];

// Reducer
export const selectedItemsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ITEM:
      return [...state, action.payload];
    case REMOVE_ITEM:
      return state.filter(item => item._item_id !== action.payload);
    default:
      return state;
  }
};
