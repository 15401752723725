import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
// components
import Header from '../components/shared/Header'
import Footer from '../components/shared/Footer'
// style
import './features.scss'
// images
import features_landing from '../Images/print-landing.png'
import features1 from '../Images/print-features1.png'
import features2 from '../Images/print-features2.png'
import arrRight from "../Images/arrow-right-hero.png";
// icons
import print from '../icons/print.svg'

const PrintFeatures = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };
    return <div className="features">
        <Header />
        <section className="landing">
            <div className="text">
                <div className='inner'>
                <img src={print} alt="print" className="logo" />
                <div className="title">Easily Generate Documents from your Data</div>
                <div className="color-black-2">Easily generate a document(pdf, Docx, doc) from any Datasource such as Podio. Creating of documents has been made easy with our tool.</div>
                <Link to='/register' className="hero-button" style={{ backgroundColor: '#A5C62E' }}>
                    <p>Get started now</p>
                    <img src={arrRight} alt="get started" />
                </Link>
                </div>
            </div>
            <div className="image"><img src={features_landing} alt="features" /></div>
        </section>
        <section className="automate">
            <div className="head">
                <div className="title">Features to automate your workflow seamlessly</div>
                <div className="text">It's our job to ensure that you're never lost in the clouds. Gain a refreshing perspective of your business processes and strategic initiatives.</div>
            </div>
            <div className='double-container' style={{ backgroundColor: '#FBFFEE' }}>
                <div className='tabs-feature'>
                    <div onClick={() => handleTabClick(0)} className='tab-feature' style={{ backgroundColor: activeTab === 0 ? '#F1FFCA' : 'transparent' }}>Template Creation</div>
                    <div onClick={() => handleTabClick(1)} className='tab-feature' style={{ backgroundColor: activeTab === 1 ? '#F1FFCA' : 'transparent' }}>Platform Integration</div>
                </div>
                <AnimatePresence mode="wait">
                    {activeTab === 0 ? 
                        <div className='tab-details'>
                            <div className='tab-text'>You can create the way you want your document to look using our drag and drop tool, this would give you the ability to print the document the way you want.</div>
                            <div className='tab-image'><img src={features1} alt="features one" /></div>
                        </div>
                        : 
                        <div className='tab-details'>
                            <div className='tab-text'>With a click of a button, you can easily get your document within your platform. Current support is only available in Podio through hooks.</div>
                            <div className='tab-image'><img src={features2} alt="features two" /></div>
                        </div>
                    }
                </AnimatePresence>
            </div>
        </section>
        <Footer bg="#A5C62E" />
    </div>
}

export default PrintFeatures
