import React, { useState } from "react";
import "../register.css";
import logo from "../../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { requestPasswordReset } from "../../../services/auth";
// import Asterisk from "../../../components/Common/Asterisk";
import { toast } from "helpers/apiRequests";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { email } = data;
    const object = { email };
    try {
      await requestPasswordReset(object);
      toast.notify("Check your email for the reset password link");
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
    setData({
      email: "",
      password: "",
    });
  };

  return (
    <div className="auth__forms__cont">
      <div className="auth__title__cont">
        <Link className="home__link" to="/">
          Home
        </Link>

        <h2>
          Remember Password?{" "}
          <span>
            <Link to="/login">Login</Link>
          </span>
        </h2>
      </div>
      <div className="auth__form__wrapper">
        <form onSubmit={handleSubmit} className="create__form">
          <div className="create__logo__cont">
            <img src={logo} alt="thatapplogo" />
            <h2>Forgot Password?</h2>
            <p>
              Enter the email address of the organisation you belong to and
              we’ll send you instructions to reset your password.
            </p>
          </div>
          <div className="create__inputs__cont">
            <div className="inputs__cont">
              <label htmlFor="email">Email Address</label>
              <input
                className="inputs__cont__input"
                type="email"
                name="email"
                id="email"
                value={data.email}
                onChange={handleChange}
                required
                placeholder="hello@gmail.com"
              />
            </div>
          </div>

          <button
            style={{ marginTop: "25px" }}
            className="form__bottom__button"
            disabled={loading}
          >
            {loading ? "Loading..." : "Send Reset instructions"}
          </button>
        </form>
      </div>
    </div>
    // <div className="auth">
    //   <div className="row">
    //     <div className="col-md-12 col-lg-6">
    //       <div className="auth__sidebar">
    //         <div className="layer">
    //           <div className="custom__container__80">
    //             <img src={Logo} alt="logo" />
    //             <h1 className="title">
    //               Welcome to <br /> ThatApp!
    //             </h1>
    //             <p className="description">
    //               It is our job to ensure that you are never lost in the clouds.
    //               Gain a refreshing perspective of your business processes and
    //               strategic initiatives through the eyes of our skilled workflow
    //               architects and system integrators.
    //             </p>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="col-md-12 col-lg-6">
    //       <div className="auth__component">
    //         <div className="custom__container__80">
    //           <div className="text-right">
    //             <p className="route__text">
    //               Remember Password?{" "}
    //               <Link className="link__text" to="/login">
    //                 Sign in
    //               </Link>
    //             </p>
    //           </div>
    //           <div className="auth__form__container mt-5">
    //             <h1 className="pt-5">Forgot Password?</h1>
    //             <p>
    //               Enter the email address of the organisation you belong to and
    //               we’ll send you instructions to reset your password.
    //             </p>
    //             <form onSubmit={handleSubmit} className="mt-3">
    //               <div className="form-row">
    //                 <div className="form-group col-md-12">
    //                   <label>
    //                     Company Email <Asterisk />
    //                   </label>
    //                   <input
    //                     className="form-control"
    //                     name="email"
    //                     value={data.email}
    //                     required
    //                     type="email"
    //                     onChange={handleChange}
    //                   />
    //                 </div>
    //               </div>
    //               <button className="btn auth-btn" disabled={loading}>
    //                 {loading ? "Please Wait" : "Send Reset instructions"}
    //               </button>
    //             </form>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default ForgotPassword;
