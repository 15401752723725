import React, { Fragment} from "react";
import { selectItem, deleteItem } from "../../../redux/actions/test";
import { useDispatch } from "react-redux";

const CheckboxRenderer = (props) => {
  const data = typeof props["data"] !== "undefined" ? props["data"] : null;
  const dispatch = useDispatch();

  
  const checkedHandler = (event) => {
    let checked = event.target.checked;
    let colId = props.column.colId;

    props.node.setDataValue(colId, checked);

    if (checked) {
      dispatch(selectItem(data._item_id));
    } else {
      dispatch(deleteItem(data._item_id));
    }
  };

  return (
    <Fragment>
      {data?.check_deletion === "true" ? (
        <input type="checkbox" onClick={checkedHandler} checked={props.value} style={{width: "auto"}} />
      ) : (
        <p>Nothing</p>
      )}
    </Fragment>
  );
};

export default CheckboxRenderer;
