import React from 'react';
import { Box, Dialog, DialogTitle, DialogContent, Typography, DialogActions } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { toast } from 'helpers/apiRequests';

import useAsync from 'components/templates/print-v2/hooks/useAsync';
import { uploadForSigning } from 'components/templates/print-v2/printUtils/requests';

import styles from './ConfirmUploadForSigning.module.scss';

const ConfirmUploadForSigning = (props) => {
  const { open, handleClose, template_id, item_id } = props;
  const [message, setMessage] = React.useState('');
  const { execute, status } = useAsync(() => {
    const payload = { template_id, item_id };

    if (message) payload.message = message;

    return uploadForSigning(payload).then(() => {
      toast.notify('Template Uploaded for Signing Successfully');
      handleClose();
    });
  }, false);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableEscapeKeyDown={true}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      className={styles.override}
      maxWidth='lg'>
      <Box className={styles.container}>
        <DialogTitle>
          <Typography id='modal-modal-title' variant='h6' component='p'>
            Upload Template for Signing
          </Typography>
        </DialogTitle>
        <DialogActions style={{ position: 'absolute', right: 0, top: 0 }}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogContent style={{ padding: 0 }}>
          <form className={styles.form_container}>
            <section>
              <p>Are you sure you want to upload this Template for signing?</p>
              <textarea
                name='message'
                cols='30'
                rows='4'
                placeholder='Optional message for all signers'
                value={message}
                onChange={(e) => setMessage(e.target.value)}></textarea>
            </section>
            <footer>
              <div className={styles.button_container}>
                <button onClick={handleClose} type='button'>
                  Cancel
                </button>
                <button onClick={execute} disabled={status === 'pending'}>
                  {status === 'pending' ? 'Uploading...' : 'Upload'}
                </button>
              </div>
            </footer>
          </form>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default ConfirmUploadForSigning;
