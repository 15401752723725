/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "../../config";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import { AgGridReact } from "ag-grid-react";
// import * as AllModules from "ag-grid-enterprise";
// import { ModuleRegistry } from "ag-grid-enterprise";
import { CircularProgress } from "@material-ui/core";
import MapperAction from "./components/MapperAction";
import CreateNewDataMapModalV2 from "../../components/Modal/Connector/CreateNewDataMapModalV3";
import SelectAppForMapperCreationV2 from "../../components/Modal/SelectAppForMapperCreationV2";
import useMediaQuery from "@mui/material/useMediaQuery";

// ModuleRegistry.registerModules(Object.keys(AllModules));
const MapperGrid = (props) => {
  const filterParams = {
    filterOptions: ["contains", "notContains"],
    debounceMs: 0,
    caseSensitive: false,
    suppressAndOrCondition: true,
  };

  const [loading, setLoading] = useState(false);
  const [mappers, setMappers] = useState([]);
  const [openItemModal, setOpenItemModal] = useState(false);
  const [fields, setFields] = useState({});
  const [mapperExists, setMapperExists] = useState(true);
  const isMobile = useMediaQuery("(max-width:600px)");

  const handleCloseItemModal = () => {
    setFields({});
    setOpenItemModal(false);
  };

  const fieldFormater = (field) => {
    return field.data.fields.length;
  };
  const driverFormater = (field) => {
    return field.value.toUpperCase();
  };

  const [users] = useState({
    columns: [
      {
        headerName: "Org ID",
        field: "org_id",
        filter: "agTextColumnFilter",
        filterParams,
      },
      {
        headerName: "App ID",
        field: "app_id",
        filter: "agTextColumnFilter",
        filterParams,
      },
      {
        headerName: "App name",
        field: "app_name",
        filter: "agTextColumnFilter",
        filterParams,
      },
      {
        headerName: "Table Name",
        field: "table_name",
        filter: "agTextColumnFilter",
        filterParams,
      },
      {
        headerName: "Space Name",
        field: "space_name",
      },
      {
        headerName: "Driver",
        field: "driver",
        valueFormatter: driverFormater,
      },
      {
        headerName: "Credential Id",
        field: "credential_id",
      },
      {
        headerName: "Total Fields",
        field: "space_name",
        valueFormatter: fieldFormater,
      },
      {
        headerName: "Actions",
        field: "actions",
        cellRenderer: "imitateAction",
      },
    ],
    data: [],
  });

  const getMappers = async (spaceId) => {
    try {
      setLoading(true);
      const res = await axios.get(`/mappers?credential_id=${spaceId}`);
      setMappers(res);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const addMappers = () =>
  // val
  {
    // const oldData = [...mappers];
    // const newData = [...oldData];
    // // const newData = [...val, ...oldData];
    // // setMappers(newData);
    // console.log(oldData);
    // console.log(newData);
    // console.log(val);
    getMappers(props.connectorId);
    handleCloseItemModal();
  };

  useEffect(() => {
    getMappers(props.connectorId);
  }, [props.connectorId]);

  const defaultColDef = {
    flex: 1,
    minWidth: 100,
    filter: true,
    sortable: true,
    resizable: true,
  };
  const autoGroupColumnDef = { minWidth: 200 };
  const frameworks = {
    imitateAction: (params) => (
      <MapperAction addMappers={addMappers} mappers={mappers} {...params} />
    ),
  };
  const paginationPageSize = 100;
  const cacheBlockSize = 100;

  if (loading) {
    return (
      <center>
        <CircularProgress />
      </center>
    );
  }

  return (
    <div className="pb-5">
      <div className="d-flex justify-content-between align-items-center mb-2">
        {isMobile ? null : <p className="text-bold fs-5 mb-0"></p>}

        <SelectAppForMapperCreationV2
          setOpenItemModal={setOpenItemModal}
          fields={fields}
          setFields={setFields}
          selectedOrg={props.selectedOrg}
          mapperExists={mapperExists}
        />
      </div>

      <CreateNewDataMapModalV2
        setMapperExists={setMapperExists}
        openItemModal={openItemModal}
        handleCloseItemModal={handleCloseItemModal}
        selectedApp={fields}
        connectorId={props.connectorId}
        addMappers={addMappers}
        isConnector={true}
        edit={false}
        type="new"
      />
      <div
        className="ag-theme-balham-dark"
        style={{
          height: "650px",
        }}
      >
        <AgGridReact
          // modules={Object.keys(AllModules)}
          rowData={mappers}
          columnDefs={users.columns}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          frameworkComponents={frameworks}
          enableRangeSelection={true}
          animateRows={true}
          rowGroupPanelShow="always"
          rowSelection="multiple"
          pagination={true}
          paginationPageSize={paginationPageSize}
          cacheBlockSize={cacheBlockSize}
          debug={true}
          sideBar={true}
          enableCellTextSelection={true}
          suppressAggFuncInHeader={true}
          enableFilter={true}
          enableSorting={true}
        ></AgGridReact>
      </div>
    </div>
  );
};

export default MapperGrid;
