import React, { useEffect, useState } from 'react'
import { AnimatePresence } from "framer-motion";
// components
import Header from '../components/shared/Header'
import Footer from '../components/shared/Footer'
// style
import './features.scss'
// images
import features_landing from '../Images/ava-features2.png'
import features1 from '../Images/ava-landing.png'
import features2 from '../Images/ava-features1.png'
import arrRight from "../Images/arrow-right-hero.png";
// icons
import hybrid from '../icons/hybrid.svg'
import data from '../icons/data.svg'
import dataAnalytics from '../icons/dataAnalytics.svg'
import mobile from '../icons/mobile.svg'
import iot from '../icons/iot.svg'
import ava from '../icons/ava.svg'

const AvaFeatures = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    return <div className="features">
        <Header />
        <section className="landing">
            <div className="text">
                <div className='inner'>
                <img src={ava} alt="ava" className="logo" />
                <div className="title">Automate your data from multiple platforms easily</div>
                <div className="color-black-2">Easily migrate, automate and move your data seamlessly from multiple platforms such as Salesforce, Podio, Shopify, etc or even Mysql to any platform of your choice.</div>
            <div onClick={() => window.Intercom("show")} id="intercom_link" className="hero-button" style={{ backgroundColor: '#0196B1' }}>
                    <p>Get started now</p>
                    <img src={arrRight} alt="get started" />
                </div>
                </div>
            </div>
            <div className="image"><img src={features_landing} alt="features" /></div>
        </section>
        <section className="automate">
            <div className="head">
                <div className="title">Features to automate your workflow seamlessly</div>
                <div className="text">It's our job to ensure that you're never lost in the clouds. Gain a refreshing perspective of your business processes and strategic initiatives.</div>
            </div>
            <div className='double-container' style={{ backgroundColor: '#EFFDFF' }}>
                <div className='tabs-feature'>
                    <div onClick={() => handleTabClick(0)} className='tab-feature' style={{ backgroundColor: activeTab === 0 ? '#CAF5FF' : 'transparent' }}>Cloud Integration and Flow Management</div>
                    <div onClick={() => handleTabClick(1)} className='tab-feature' style={{ backgroundColor: activeTab === 1 ? '#CAF5FF' : 'transparent' }}>API Integration</div>
                </div>
                <AnimatePresence mode="wait">
                    {activeTab === 0 ? 
                        <div className='tab-details'>
                            <div className='tab-text'>Connect disparate SaaS applications to share & sync data between them automatically and in a fraction of time.</div>
                            <div className='tab-image'><img src={features1} alt="features one" /></div>
                        </div>
                        : 
                        <div className='tab-details'>
                            <div className='tab-text'>Build new products and services by easily creating all kinds of API mashups.</div>
                            <div className='tab-image'><img src={features2} alt="features two" /></div>
                        </div>
                    }
                </AnimatePresence>
            </div>
        </section>
        <section className="work-easily">
            <div className="head">
                <div className="title">Other Features</div>
            </div>
            <div className="triple-container">
                <div className="item one" id='1'>
                    <img src={hybrid} alt="hybrid" />
                    <div className="other-text bold">Hybrid Integration</div>
                    <div>Even when you need to connect to the cloud, make sure that the actual data never leaves on-premises.</div>
                </div>
                <div className="item two" id='2'>
                    <img src={data} alt="data" />
                    <div className="other-text bold">Data Migration</div>
                    <div>Relocate all data between systems, databases and applications without heavy custom coding and at low cost.</div>
                </div>
                <div className="item three" id='3'>
                    <img src={dataAnalytics} alt="data analytics" />
                    <div className="other-text bold">Data Analytics</div>
                    <div>Bring together business analytics and corporate applications to eliminate data silos and get maximum value from your analytic investments.</div>
                </div>
            </div>
            <div className='triple-container'>
                <div className="item four" id='4'>
                    <img src={mobile} alt="mobile" />
                    <div className="other-text bold">Mobile Integration</div>
                    <div>Empower the creation of digital services by connecting mobile apps with any kind of back-end business applications faster and with less hustle.</div>
                </div>
                <div className="item five" id='5'>
                    <img src={iot} alt="iot" />
                    <div className="other-text bold">IoT Integration</div>
                    <div>Connect your IoT-platform with other cloud and on-premises applications or collect data from multiple IoT-platforms</div>
                </div>
            </div>
        </section>
        <Footer bg="#0196B1" />
    </div>
}

export default AvaFeatures
