import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import Sync from "../Sync/Sync";
import Haven from "../Haven/Haven";
import { Route, Switch, withRouter } from "react-router";
import useMediaQuery from "@mui/material/useMediaQuery";
import RawData from "../Monitor/RawData/RawData";
import HowFilterWorks from "../Monitor/RawData/HowFilterWorks";
import Partner from "../Partner/Partner";
import Audit from "../Audit/Audit";
import Files from "../Haven/Files/File";
import Hooks from "../Hooks/Hooks";
import HookManager from "../Hooks/HookManager/HookManager";
import Items from "../Items/Items";
import CreateItem from "../Items/CreateItem/CreateItem";
import Live from "../Trail/Live";
import Accounts from "../UserInfo/Accounts";
import Plans from "../UserInfo/Plans";
import PlatformUsers from "../UserInfo/PlatformUsers";
import MyInvoices from "../UserInfo/MyInvoices";
import PrintEditor from "../../components/templates/print-v2/PrintEditor";
import {
  USER_ACCOUNT,
  PLANS,
  MY_INVOICES,
  PLATFORM_USERS,
  PRINT_DATA_EDIT,
  PLATFORM_REFFERALS,
  PODIO_PARTNERS,
  ADD_APP_V2,
  TMAIL_DASHBOARDV2,
  TMAIL_DASHBOARD_APPSV2,
  DOMAINSV2,
  MEMBERSV2,
  CAMPAIGNV2,
  APP_SETTING,
  CONNECTOR_BACKUP,
  MY_PARTNERS,
  COMMENT_MODERATE,
  ITEM_COMMENTS,
  ADD_MEMBERS,
  ORPHAN_EMAIL,
  MAPPER,
  CONNECTOR,
} from "../pagePath";
import Workspace from "../Workspace/Workspace";
import App from "../App/App";
import AppBuilder from "../App/AppBuilder/AppBuilder";
import PlatformRefferals from "../UserInfo/Refferals";
import PodioPartner from "../UserInfo/PodioPartners";
import MyPartner from "../UserInfo/MyPartner";
import Connect from "../Tmail/Connect/Connect";
import DashbordView from "../../components/Tmail/components/DashboardView";
import AppList from "../../components/Tmail/components/AppsList";
import NewApp from "../../components/Tmail/components/NewApp";
import Domain from "../../components/Tmail/components/Domain";
import Member from "../../components/Tmail/components/Members";
import Campaign from "../../components/Tmail/components/Campaign";
import Appseting from "../../components/Tmail/components/Appseting";
import CommentModerate from "../../components/Tmail/components/CommentModeration";
import ItemComments from "../../components/Tmail/components/ItemComments";
import AddMemberGrid from "../../components/Tmail/components/AddMemberGrid";
import Backup from "../Mysql/components/BackupProgress";
import OrphanSetting from "../../components/Tmail/components/OrphanSetting";
import SqlMapper from "../Mysql/SqlMapper";
import SqlConnector from "../Mysql/ConnectorV2";
import {
  TMAIL_V3_ACCOUNT_DETAILS,
  TMAIL_V3_ADD_APPLICATION,
  TMAIL_V3_ADD_MEMBER,
  TMAIL_V3_APPLICATION,
  TMAIL_V3_APPLICATION_SETTING,
  TMAIL_V3_DASHBOARD,
  TMAIL_V3_DOMAIN,
  TMAIL_V3_DOMAIN_LIST,
  TMAIL_V3_MEMBERS,
} from "components/Tmail-V3/routes";
import DashboardView from "components/Tmail-V3/modules/DashboardView";
import ApplicationView from "components/Tmail-V3/modules/ApplicationView";
import DomainView from "components/Tmail-V3/modules/DomainView";
import MembersView from "components/Tmail-V3/modules/MembersView";
import DomainListView from "components/Tmail-V3/modules/DomainListView";
import NewMemberView from "components/Tmail-V3/modules/NewMemberView";
import NewApplicationView from "components/Tmail-V3/modules/NewApplicationView";
import AccountDetailsView from "components/Tmail-V3/modules/AccountDetailsView";
import AppSetting from "components/Tmail-V3/modules/AppSetting";
import ResyncOrg from "components/Table/SyncTable/ResyncOrg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  hide: {
    display: "none",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    background: "white",
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [openMobile, setOpenMobile] = React.useState(false);
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(!open);
    setOpenMobile(!openMobile);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header baropen={open} handleDrawerOpen={handleDrawerOpen} />
      {isMobile ? (
        <Sidebar open={openMobile} handleDrawerOpen={handleDrawerOpen} />
      ) : (
        <Sidebar open={open} handleDrawerOpen={handleDrawerOpen} />
      )}
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Switch>
          <Route exact path="/dashboard/sync" component={Sync} />
          <Route exact path="/dashboard/apps/:id" component={Audit} />
          <Route exact path="/dashboard/workspaces/:id" component={Workspace} />
          <Route
            exact
            path="/dashboard/organisation/:org_id/workspaces/:id/apps"
            component={App}
          />
          <Route
            exact
            path="/dashboard/organisation/:org_id/workspaces/:space_id/apps/:app_id/app-builder"
            component={AppBuilder}
          />
          <Route exact path="/dashboard/filters" component={HowFilterWorks} />

          <Route
            exact
            path="/dashboard/createitem/app/:id/:org_id"
            component={CreateItem}
          />
          <Route exact path="/dashboard/haven" component={Haven} />
          <Route exact path="/dashboard/resync-org" component={ResyncOrg} />
          <Route exact path="/dashboard/raw-data" component={RawData} />
          <Route exact path="/dashboard/partners/apply" component={Partner} />
          <Route exact path="/dashboard/view/:org_id" component={Files} />
          <Route
            exact
            path="/dashboard/deleted-file/:org_id"
            component={Files}
          />
          <Route exact path="/dashboard/hooks" component={Hooks} />
          <Route exact path="/dashboard/manage-hook" component={HookManager} />
          <Route exact path="/dashboard/app/:org_id/:id" component={Items} />
          <Route exact path="/dashboard/delete/:org_id/:id" component={Items} />
          <Route exact path="/dashboard/test" component={Live} />
          <Route exact path={USER_ACCOUNT} component={Accounts} />
          <Route exact path={PLANS} component={Plans} />
          <Route exact path={MY_INVOICES} component={MyInvoices} />
          <Route exact path={PLATFORM_USERS} component={PlatformUsers} />
          <Route exact path={PODIO_PARTNERS} component={PodioPartner} />
          <Route exact path={MY_PARTNERS} component={MyPartner} />
          <Route
            exact
            path={PLATFORM_REFFERALS}
            component={PlatformRefferals}
          />
          <Route exact path={PRINT_DATA_EDIT} component={PrintEditor} />
          <Route exact path="/dashboard/connect/tmail" component={Connect} />
          <Route exact path={TMAIL_DASHBOARDV2} component={DashbordView} />
          <Route exact path={TMAIL_DASHBOARD_APPSV2} component={AppList} />
          <Route exact path={ADD_APP_V2} component={NewApp} />
          <Route exact path={DOMAINSV2} component={Domain} />
          <Route exact path={MEMBERSV2} component={Member} />
          <Route exact path={TMAIL_V3_DASHBOARD} component={DashboardView} />
          <Route
            exact
            path={TMAIL_V3_APPLICATION}
            component={ApplicationView}
          />
          <Route
            exact
            path={TMAIL_V3_ACCOUNT_DETAILS}
            component={AccountDetailsView}
          />
          <Route
            exact
            path={TMAIL_V3_ADD_APPLICATION}
            component={NewApplicationView}
          />
          <Route exact path={TMAIL_V3_DOMAIN} component={DomainView} />
          <Route exact path={TMAIL_V3_DOMAIN_LIST} component={DomainListView} />
          <Route exact path={TMAIL_V3_MEMBERS} component={MembersView} />
          <Route exact path={TMAIL_V3_ADD_MEMBER} component={NewMemberView} />
          <Route
            exact
            path={TMAIL_V3_APPLICATION_SETTING}
            component={AppSetting}
          />
          <Route exact path={CAMPAIGNV2} component={Campaign} />
          <Route exact path={APP_SETTING} component={Appseting} />
          <Route exact path={CONNECTOR_BACKUP} component={Backup} />
          <Route exact path={COMMENT_MODERATE} component={CommentModerate} />
          <Route exact path={ITEM_COMMENTS} component={ItemComments} />
          <Route exact path={ADD_MEMBERS} component={AddMemberGrid} />
          <Route exact path={ORPHAN_EMAIL} component={OrphanSetting} />
          <Route exact path={MAPPER} component={SqlMapper} />
          <Route exact path={CONNECTOR} component={SqlConnector} />
        </Switch>
      </main>
    </div>
  );
};

export default withRouter(Dashboard);
