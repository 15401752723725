import React from "react";
import "./workflow.css";
// import avatars from "../../Images/avatar-collage.png";

import mysql from "../../Images/mysql.png";
import mongodb from "../../Images/mongodb.png";
import sharefile from "../../Images/sharefile.png";
import rightsignature from "../../Images/rightsignature.png";
import postgres from "../../Images/postgres.png";
import podio from "../../Images/podio.png";
import jsonImg from "../../Images/json.png";

import arrUp from "../../Images/arrow-side-up.png";
import arrDown from "../../Images/arrow-side-down.png";
import arrRight from "../../Images/arrow-right-orange.png";

export default function Workflow() {
  return (
    <div className="workflow-cont">
      <div className="workflow-title">
        <h2>Features to automate your workflow seamlessly</h2>
        {/* <div className="avatar-cont">
          <p>Used by</p>
          <img src={avatars} alt="users" />
          <p>
            <span>10k+</span> users
          </p>
        </div>
        <p className="avatar-sub">
          Used by <span>10k+</span>users
        </p> */}
      </div>
      <div className="workflow-tech-cont">
        <div className="workflow-tech">
          <img src={podio} alt="podio" className="workflow-podio" />
          <div className="tech-name">
            <h2>Podio</h2>
            <div className="tech-status">
              <img src={arrUp} alt="available" />
              <p className="available">Available</p>
            </div>
          </div>
        </div>
        <div className="workflow-tech">
          <img src={mysql} alt="podio" className="workflow-mysql" />
          <div className="tech-name">
            <h2>MySQL</h2>
            <div className="tech-status">
              <img src={arrUp} alt="available" />
              <p className="available">Available</p>
            </div>
          </div>
        </div>
        <div className="workflow-tech">
          <img src={mongodb} alt="podio" className="workflow-mongodb" />
          <div className="tech-name">
            <h2>MongoDB</h2>
            <div className="tech-status">
              <img src={arrUp} alt="available" />
              <p className="available">Available</p>
            </div>
          </div>
        </div>
        <div className="workflow-tech">
          <img src={sharefile} alt="podio" className="workflow-sharefile" />
          <div className="tech-name">
            <h2>ShareFile</h2>
            <div className="tech-status">
              <img src={arrUp} alt="available" />
              <p className="available">Available</p>
            </div>
          </div>
        </div>
        <div className="workflow-tech">
          <img src={jsonImg} alt="podio" className="workflow-json" />
          <div className="tech-name">
            <h2>JSON</h2>
            <div className="tech-status">
              <img src={arrDown} alt="soon" />
              <p className="soon">Upcoming</p>
            </div>
          </div>
        </div>
        <div className="workflow-tech">
          <img src={rightsignature} alt="podio" className="workflow-rightsig" />
          <div className="tech-name">
            <h2>Right Signature</h2>
            <div className="tech-status">
              <img src={arrUp} alt="available" />
              <p className="available">Available</p>
            </div>
          </div>
        </div>
        <div className="workflow-tech">
          <img src={postgres} alt="podio" className="workflow-postgres" />
          <div className="tech-name">
            <h2>PostgreSQL</h2>
            <div className="tech-status">
              <img src={arrUp} alt="available" />
              <p className="available">Available</p>
            </div>
          </div>
        </div>
        <div className="more-tech-tab">
          <p>See more </p>
          <img src={arrRight} alt="see more" />
        </div>
      </div>
      <button className="more-tech">
        <p>See more </p>
        <img src={arrRight} alt="see more" />
      </button>
    </div>
  );
}
