import React from 'react';
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from "@material-ui/core";

const TableDesign = ({ name, records }) => {
    return (
        <Table className="mb-3 table-striped">
            <TableHead>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell >Type</TableCell>
                    <TableCell >Valid</TableCell>
                    <TableCell >Value</TableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                {records.map((field, i) => (
                    <TableRow key={i}>
                        <TableCell component="th" scope="row">
                            {field.name ? field.name : name}
                        </TableCell>
                        <TableCell>{field.type}</TableCell>
                        <TableCell>
                            {field.valid === "valid" ? <span className='text-success'>
                                {field.valid}</span> : <span className='text-danger'>
                                {field.valid}</span>}
                        </TableCell>
                        <TableCell style={{ maxWidth: "300px", wordWrap: "break-word" }} >{field.value}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}

export default TableDesign