import React from 'react';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classes from './style.module.css';
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import Axios from '../../../config';
import { toast } from "helpers/apiRequests"
import { useSelector, useDispatch } from 'react-redux';
import { getMe } from '../../../services/auth';
import { setUser } from '../../../redux/actions/user';

const useStyles = makeStyles({
    blue: {
        color: '#3f50b5'
    },
    green: {
        color: "#4caf50",
        fontSize: 15,
    },
    grey: {
        color: "#666"
    },
    bgDanger: {
        background: "#f00",
        color: "#fff",
        fontSize: 15,
    },
    bgGrey: {
        background: "#f9f1c9",
        color: "#8a6d3b",
        fontSize: 15,
    },
    wn: {
        fontSize: 17
    },
    cardDisplay: {
        display: 'block',
    }

});

const textAlign = {
    textAlign: 'left'
};

const LatestCard = ({ rateLimit }) => {
    const muClasses = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.authUser);

    const getUser = async () => {
        const response = await getMe();
        dispatch(setUser(response.data));
    };

    const disconectPodio = () => {
        Swal.fire({
            title: "Remove Podio",
            text: 'Your podio account would be removed from Tmail',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Remove Account',
            cancelButtonText: "Cancel",
        }).then((result) => {
            if (result.value) {
                const url = "/tmail/disconnect";
                Axios.post(url).then(async res => {
                    await getUser();
                    Swal.fire({
                        title: "Podio Disconnected",
                        text: 'Your podio account has been disconnected',
                        icon: 'success',
                    }).then(() => {
                        history.push("/dashboard/sync");
                    })

                }).catch(error => {
                    console.log(error)
                    toast.notify(error.message, { type: "error" });
                })
            }
        });
    }



    return (
        <Card variant="outlined" elevation={2} className={classes.latestCard} style={{ padding: 20, }} >
            <CardHeader title="Rate Limit" className={muClasses.grey} />

            <CardContent className={muClasses.cardDisplay} >
                <div className={classes.cardFlow}>
                    <p className={"alert alert-primary"} style={textAlign}>
                        Podio Rate Limit: {parseInt(rateLimit.rate_limit).toLocaleString()}<br />
                        Podio Rate Limit Remaining: {parseInt(rateLimit.rate_limit_remaining).toLocaleString()}
                    </p>
                    {
                        user?.tmail_connection === true ?
                            <button
                                className='btn btn-danger btn-sm'
                                onClick={disconectPodio}
                            >Disconnect Podio</button> : null
                    }

                </div>
            </CardContent>
        </Card>
    )
}

export default LatestCard