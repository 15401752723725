import React from 'react';
import InfoIcon from '@material-ui/icons/Info';

import styles from './fields.module.scss';
import CustomTooltip from 'components/templates/print-v2/components/CustomTooltip';
import { usePrintData } from 'components/templates/print-v2/context/PrintProvider';

const extensionOptions = [
  { name: 'Portable Document Format (PDF)', value: 'pdf' },
  { name: 'Microsoft Word (Docx)', value: 'docx' },
  { name: 'Microsoft Word (Doc)', value: 'doc' },
  { name: 'Rich Text Format (RTF)', value: 'rtf' },
  { name: 'Hypertext Markup Language (HTML)', value: 'html' },
];

export default function TemplateFields() {
  const { templateFields, setTemplateFields } = usePrintData();

  function handleChange(e) {
    const { name, value } = e.target;
    setTemplateFields((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  return (
    <div className={styles.template_fields_container}>
      <fieldset className='d-flex flex-column'>
        <label htmlFor='title' className={`${styles.label} mb-1`}>
          Print Button Title
        </label>
        <input type='text' id='title' name='title' placeholder='Template 1' value={templateFields?.title} onChange={handleChange} />
      </fieldset>

      <fieldset className='mt-3 d-flex flex-column'>
        <label htmlFor='file_title' className={`${styles.label} mb-1`}>
          Document Title
          <CustomTooltip
            title="If you'd like to insert a variable into the Document Title, copy it from a place where it's used within the Template"
            placement='top'>
            <InfoIcon style={{ fontSize: '14px', marginLeft: '5px', color: '#28a745' }} />
          </CustomTooltip>
        </label>
        <input
          type='text'
          id='file_title'
          name='file_title'
          placeholder='Document Title'
          value={templateFields?.file_title}
          onChange={handleChange}
          required
        />
      </fieldset>

      <fieldset className='mt-3 d-flex flex-column'>
        <label htmlFor='document-extension' className='mb-1'>
          Extension
        </label>
        <select name='extension' id='document-extension' value={templateFields?.extension} onChange={handleChange}>
          {extensionOptions.map((option) => (
            <option value={option.value} key={option.value} className='form-control' style={{ lineHeight: 4 }}>
              &nbsp;&nbsp;&nbsp;{option.name}
            </option>
          ))}
        </select>
      </fieldset>
    </div>
  );
}
