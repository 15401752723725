import React, { Fragment, useState, useEffect } from "react";
import styled from "@emotion/styled";
import SimpleSelect from "./Dropdown";
import Button from "@material-ui/core/Button";
import { setOrgs } from "../../../redux/actions/sync";
import { getCollection } from "../../../redux/actions/rawData";
import { getSyncedOrganizations } from "../../../services/sync";
import { useDispatch, useSelector } from "react-redux";
import CopyToClipboard from "react-copy-to-clipboard";
import CopyTest from "./CopyTest";
import { useHistory } from "react-router";
import EmptyState from "../../../components/EmptyState";
import BackButton from "components/atoms/BackButton";
import copyIcon from "../../../assets/icons/copyIcon.svg";
import "./RawData.css";
const RawData = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    getSyncedOrganizations().then((response) =>
      dispatch(setOrgs(response.data))
    );
  }, [dispatch]);

  const orgs = useSelector((state) => state.sync.orgs);
  const collections = useSelector((state) => state.rawData.collections);
  const user = useSelector((state) => state.user.authUser);
  const [orgList, setorgList] = useState([]);

  useEffect(() => {
    let newArray = ["Select an Organization"];

    newArray = orgs.map((org, index) => {
      return org.name;
    });
    setorgList(newArray);
  }, [orgs]);

  useEffect(() => {
    if (!collections.success) {
      dispatch(getCollection());
    }
  }, [collections.success, dispatch]);

  useEffect(() => {
    if (!collections.success) {
      dispatch(getCollection());
    }
  }, [collections.success, dispatch]);

  const DataView = ["Podio Structure", "Collection"];

  const [rawData, setRawData] = useState(0);
  const [selectedOrg, setselectedOrg] = useState("none");
  const [collection, setCollection] = useState(-1);
  const [selectedOrgId, setSelectedOrgId] = useState(-1);
  const history = useHistory();

  const [copyForm, setcopyForm] = useState({
    status: "success",
    message: "",
    isOpen: false,
  });

  const closeHandler = () => {
    setcopyForm({
      ...copyForm,
      isOpen: false,
    });
  };

  const handleRawChange = (event) => {
    setRawData(event.target.value);
  };

  const handleOrgorCollectionChange = (event) => {
    if (rawData) {
      setCollection(event.target.value);
    } else {
      setselectedOrg(event.target.value);
    }
  };

  useEffect(() => {
    if (selectedOrg > -1) {
      const selectedOrgId = orgs[selectedOrg].details.org_id;
      setSelectedOrgId(selectedOrgId);
    } else {
      setSelectedOrgId(-1);
    }
  }, [orgs, selectedOrg]);

  return (
    <RawData.Wrapper style={{ padding: "0", height: "90vh" }}>
      {orgs.length === 0 ? (
        <EmptyState />
      ) : (
        <Fragment>
          <div className="d-flex h-100vh raw-data-container">
            <div
              style={{ width: "30%", borderBottom: "0" }}
              className="raw-data-header"
            >
              <div>
                <BackButton />
              </div>
              <div className="d-flex flex-column mt-4">
                <h5 style={{ fontWeight: "600" }}>
                  <span style={{ color: "#F0B429" }}>Audit</span> {`>`} Raw Data
                  Manager
                </h5>
                <p className="text-danger" style={{ marginTop: "7%" }}>
                  Please select an organisation or a collection
                </p>
                <SimpleSelect
                  options={DataView}
                  title="Raw Data View"
                  value={rawData}
                  handleChange={handleRawChange}
                />
                <span className="mt-3">
                  <SimpleSelect
                    className="ml-2"
                    options={
                      rawData
                        ? collections.data.map((item) => item.name)
                        : orgList
                    }
                    title={
                      rawData ? "Select Collection" : "Select Organisation"
                    }
                    value={rawData ? collection : selectedOrg}
                    handleChange={handleOrgorCollectionChange}
                  />
                </span>
              </div>
            </div>

            <div
              className="raw-data-filter-work"
              style={{
                width: "70%",
                height: "90vh",
                padding: "3%",
                backgroundColor: "#FBFBFB",
              }}
            >
              <Button
                variant="outlined"
                // color="primary"
                className="mb-3"
                onClick={() => history.push("/dashboard/filters")}
              >
                How Our Filters Work
              </Button>
              <p style={{ width: "90%", fontSize: "16px", fontWeight: "600" }}>
                Below are the list of URL avaliable for the Current Data view
              </p>

              <div
                style={{ borderRadius: "8px" }}
                className="bg-white p-2 mt-3"
              >
                {rawData === 0 && selectedOrg !== "none" ? (
                  <Fragment>
                    <CopyPanel
                      label="Organization URL"
                      link={`${process.env.REACT_APP_BASEURL}/sync/podio/raw/${
                        orgs[selectedOrg] && orgs[selectedOrg].details.database
                      }/podio_organization_${selectedOrgId}?email=${
                        user.email
                      }&secret_key=${user.raw_data_hash}`}
                      setcopyForm={setcopyForm}
                      selectedOrgId={selectedOrgId}
                    />
                    <CopyPanel
                      label="Spaces URL"
                      link={`${process.env.REACT_APP_BASEURL}/sync/podio/raw/${
                        orgs[selectedOrg] && orgs[selectedOrg].details.database
                      }/podio_spaces_${selectedOrgId}?email=${
                        user.email
                      }&secret_key=${user.raw_data_hash}`}
                      setcopyForm={setcopyForm}
                      selectedOrgId={selectedOrgId}
                    />
                    <CopyPanel
                      label="Apps URL"
                      link={`${process.env.REACT_APP_BASEURL}/sync/podio/raw/${
                        orgs[selectedOrg] && orgs[selectedOrg].details.database
                      }/podio_apps_${selectedOrgId}?email=${
                        user.email
                      }&secret_key=${user.raw_data_hash}`}
                      setcopyForm={setcopyForm}
                      selectedOrgId={selectedOrgId}
                    />
                    <CopyPanel
                      label="Items URL"
                      link={`${process.env.REACT_APP_BASEURL}/sync/podio/raw/${
                        orgs[selectedOrg] && orgs[selectedOrg].details.database
                      }/podio_items_${selectedOrgId}?email=${
                        user.email
                      }&secret_key=${user.raw_data_hash}`}
                      setcopyForm={setcopyForm}
                      selectedOrgId={selectedOrgId}
                    />
                    <CopyPanel
                      label="Files URL"
                      link={`${process.env.REACT_APP_BASEURL}/sync/podio/raw/${
                        orgs[selectedOrg] && orgs[selectedOrg].details.database
                      }/podio_files_${selectedOrgId}?email=${
                        user.email
                      }&secret_key=${user.raw_data_hash}`}
                      setcopyForm={setcopyForm}
                      selectedOrgId={selectedOrgId}
                    />
                  </Fragment>
                ) : (
                  <Fragment>
                    {collections?.data[collection]?.name ? (
                      <CopyPanel
                        label="Collection URL"
                        link={`${
                          process.env.REACT_APP_BASEURL
                        }/sync/podio/raw/${orgs[0].details.database}/${
                          collections.data[collection] &&
                          collections.data[collection].name
                        }?email=${user.email}&secret_key=${user.raw_data_hash}`}
                        setcopyForm={setcopyForm}
                        selectedOrgId={collection}
                      />
                    ) : (
                      <p></p>
                    )}
                  </Fragment>
                )}
              </div>
              <CopyTest copyForm={copyForm} closeHandler={closeHandler} />
            </div>
          </div>
        </Fragment>
      )}
    </RawData.Wrapper>
  );
};

RawData.Wrapper = styled.div`
  background-color: #fff;
  border-radius: 6px;
  padding: 40px 32px;
  .header {
    border-bottom: 1px solid gray;
    padding-bottom: 2rem;
    margin-bottom: 40px;
  }

  .copy-cards {
    background-color: #fff;
    padding: 15px;

    .my-input {
      background: #ffffff;
      border: 1px solid #cfcfcf;
      box-sizing: border-box;
      border-radius: 3px;
      height: 58px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;

      span:first-of-type {
        overflow: hidden;
        white-space: nowrap;
        width: 46rem;
      }
      span:last-of-type {
        border-left: 2px solid #e1e1e1;
        padding-left: 1rem;
        margin-left: 1rem;
        white-space: nowrap;

        :hover {
          cursor: pointer;
        }
      }
    }
  }
`;

export default RawData;

const CopyPanel = (props) => {
  return (
    <div className="copy-cards">
      <p>{props.label} </p>
      <div
        style={{ backgroundColor: "#FBFBFB", borderRadius: "4px" }}
        className="d-flex justify-content-between align-items-center p-2"
      >
        <div
          style={{
            width: "80%",
          }}
        >
          <a
            href={props.link}
            style={{
              color: "black",
              textDecoration: "none",
              wordWrap: "break-word",
            }}
          >
            {props.link}
          </a>
        </div>
        <div
          className="d-flex justify-content-flex-end"
          style={{ width: "15%" }}
        >
          <CopyToClipboard
            onCopy={() => {
              if (props.selectedOrgId === -1) {
                props.setcopyForm({
                  status: "error",
                  message: "Please select an organisation or a collection",
                  isOpen: true,
                });
              } else {
                props.setcopyForm({
                  status: "success",
                  message: "Successfully copied link",
                  isOpen: true,
                });
              }
            }}
            text={props.link}
          >
            <img src={copyIcon} alt="" />
          </CopyToClipboard>
        </div>
      </div>
    </div>
  );
};
