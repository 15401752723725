export function extractLabelVariablePairs(data, fieldLabel = '') {
  let result = [];

  if (!data) return result;

  if (data.hasOwnProperty('label') && data.hasOwnProperty('variable') && fieldLabel) {
    result.push({ label: `${fieldLabel}: ${data.label}`, value: data.variable, type: data.type });
  }
  if (data.hasOwnProperty('fields')) {
    data.fields.forEach((field) => {
      const fieldLabel = field.label;
      if (field.hasOwnProperty('items')) {
        field.items.forEach((item) => {
          result = result.concat(extractLabelVariablePairs(item, fieldLabel));
        });
      }
      if (field.hasOwnProperty('label') && field.hasOwnProperty('variable')) {
        result.push({ label: `${field.label}`, value: field.variable, type: field.type });
      }
    });
  }
  return result;
}
