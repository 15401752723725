import React, { useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { toast } from "helpers/apiRequests";
import Axios from "../../../config";
import ViewBackup from "./Modal/ViewBackup";
import RefreshProgress from "./Modal/RefreshProgress";
import StopSync from "./Modal/StopSync";
import StopIcon from "@material-ui/icons/Stop";

const ImitateAction = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [openRefModal, setOpenRefModal] = useState(false);
  const [item, setItem] = useState();
  const data = typeof props["data"] !== "undefined" ? props["data"] : null;

  const handleClose = () => {
    setOpenModal(false);
  };
  const handleRefClose = () => {
    setOpenRefModal(false);
  };
  const openViewModal = (val) => {
    setItem(val);
    setOpenModal(true);
  };

  const [stopSyncVisible, setStopSyncVisible] = useState(false);

  const openRefreshModal = (val) => {
    setItem(val);
    setOpenRefModal(true);
    const url = `/backup-progress/refresh?progress_id=${data.id}`;
    Axios.get(url)
      .then((res) => {
        const newData = res.data;
        console.log(newData);
        console.log(props["api"], props["api"].getSelectedNodes());
        props["api"].getSelectedNodes()[0].setData(newData);
        setOpenRefModal(false);
        toast.notify(res.message);
        // props.api.gridApi.refresh()
      })
      .catch((err) => {
        setOpenRefModal(false);
        toast.notify(err.response.message, { type: "error" });
      });
  };

  return (
    <>
      {stopSyncVisible ? (
        <StopSync
          {...{
            isOpen: stopSyncVisible,
            toggleModal: () => setStopSyncVisible(false),
            id: data?.id,
          }}
        />
      ) : null}
      <ViewBackup openModal={openModal} handleClose={handleClose} item={item} />
      <RefreshProgress openModal={openRefModal} handleClose={handleRefClose} />
      {data !== null ? (
        <>
          <Tooltip title="View" style={{ marginTop: "-7px" }}>
            <IconButton aria-label="View" onClick={() => openViewModal(data)}>
              <VisibilityIcon style={{ color: "#FFF" }} />
            </IconButton>
          </Tooltip>
          {data.status.includes("Completed") ? null : (
            <i
              className="fa fa-sync-alt ag-grid-data-list"
              style={{
                fontSize: "1.50em",
                marginRight: "1px",
                cursor: "pointer",
                border: "1px solid",
              }}
              title="Refresh"
              onClick={() => openRefreshModal(data)}
            ></i>
          )}

          {data?.status?.toLowerCase().includes("pending") ||
          data?.status?.toLowerCase().includes("progress") ? (
            <Tooltip title="Stop Syncing" style={{ marginTop: "-7px" }}>
              <IconButton
                aria-label="View"
                onClick={() => setStopSyncVisible(true)}
              >
                <StopIcon style={{ color: "#FFF" }} />
              </IconButton>
            </Tooltip>
          ) : null}
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default ImitateAction;
