import {
  GET_SOURCE_ORGANISATIONS,
  GET_SOURCE_SPACES,
  GET_SOURCE_APPS,
  GET_DEST_ORGANISATIONS,
  GET_DEST_SPACES,
  GET_SOURCE_WORKFLOWS
} from "../constants/clone";

export const getSourceOrgs = () => ({
  type: GET_SOURCE_ORGANISATIONS.request,
});

export const getSourceSpaces = (payload) => ({
  type: GET_SOURCE_SPACES.request,
  payload,
});

export const getSourceApps = (payload) => ({
  type: GET_SOURCE_APPS.request,
  payload,
});

export const getDestOrgs = () => ({
  type: GET_DEST_ORGANISATIONS.request,
});

export const getDestSpaces = (payload) => ({
  type: GET_DEST_SPACES.request,
  payload,
});

export const getSourceWorkflows = (payload) => ({
  type: GET_SOURCE_WORKFLOWS.request,
  payload,
});