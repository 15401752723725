import React, { useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import RoleModal from "../Modals/RoleModal";

const AddMembersAction = (props) => {
    const [openModal, setOpenModal] = useState(false);
    const [role, setRole] = useState("");
    const data = typeof props["data"] !== "undefined" ? props["data"] : null;

    const handleClose = () => {
        setOpenModal(false);
    }

    const handleChange = (val) => {
        const newData = data
        newData.role = val;
        props["api"].getSelectedNodes()[0].setData(newData);
        setRole(val)
    }

    const clickAction = (data) => setOpenModal(true);

    return (
        <>
            <RoleModal
                openModal={openModal}
                handleClose={handleClose}
                handleRoleChange={handleChange}
                role={role}
            />
            {data !== null ? (
                <>
                    <Tooltip title="View" style={{ marginTop: "-10px" }}>
                        <i
                            className="fa fa-plus text-light"
                            style={{
                                cursor: "pointer",
                                fontSize: "20px"
                            }}
                            onClick={() => clickAction(data)}
                        >

                        </i>
                    </Tooltip>


                </>
            ) : (
                ""
            )}
        </>
    );
};

export default AddMembersAction;
