import React from "react";
import {
    Box,
    CircularProgress,
    Typography,
} from "@material-ui/core";

import "toasted-notes/src/styles.css";
import Modal from "../../../../components/molecule/Modal";

const RefreshProgress = ({ openModal, handleClose, }) => {


    return (
        <div>
            <Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box style={{padding: "20px"}}>
                    <Typography id="modal-modal-title" variant="h6" component="p">
                        Refreshing Progress
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <center>
                            <CircularProgress />
                        </center>
                    </Typography>
                </Box>
            </Modal>
        </div>
    );
};

export default RefreshProgress;
