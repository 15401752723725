import React, { useState, useEffect, Fragment } from 'react'
import axios from "../../../config";
import { toast } from "helpers/apiRequests";
import "toasted-notes/src/styles.css";
import { CircularProgress } from '@material-ui/core';
import MapperList from './MapperList';
import Error from '../../../containers/Mysql/components/Error/Error';
import MapperModal from './Modals/MapperModal';

import styles from "./OrphanEmailMap.module.css";

const OrphanEmailMap = (props) => {
  const {
    orgId,
    setMappedData,
    mappedData,
    items,
    apps,
    changeOrgId
  } = props;
  const [mappers, setMappers] = useState([]);
  const [org, setOrg] = useState();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const getMapper = async (orgId) => {
    try {
      setLoading(true)
      const url = `/tmail/orphan/mapper/get?org_id=${orgId}`;
      const res = await axios.get(url);
      const data = res.data
   
      if (data !== null) {
        setMappers(data.mapper);
        setMappedData(data)
      } else {
        setMappers([]);
        setMappedData(null)
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setMappers([])
      toast.notify(error.message, { type: "error" });
    }
  }
  
  useEffect(() => {
    if (orgId !== null) {
      setOrg(orgId);
      getMapper(orgId);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);



  return (
    <div className='orphan_tab_container'>
      <MapperModal openModal={open} handleClose={handleClose} changeOrgId={changeOrgId} items={items} mappedData={mappedData} apps={apps} />
      {org && (
        <>
          <div className={styles.container}>
            <div className={`headings ${styles.header_section}`}>
              <div>
                <p>App Fields</p>
              </div>
              <div>
              <p>Orphan Fields</p>
              </div>
            </div>

            <div className={`row ${styles.body_section}`}>
              <div className={`${styles.section_container}`}>
                {mappers.length > 0 && !loading ? (
                  mappers.map((field, index) => (
                    <Fragment key={index}>
                      <MapperList label={field.podio_field_label} value={field.orphan_field_name} />
                    </Fragment>
                  ))
                ) : mappers.length === 0 && !loading ? (
                  <Error errorMsg='No Mapper Found' errorAction='Setup Orphan Mapper' onClick={handleOpen} />
                ) : (
                  <center>
                    <CircularProgress />
                  </center>
                )}
                {mappers.length > 0 ? (
                  <button
                    className='btn btn-default mr-2'
                    onClick={handleOpen}
                    style={{
                      color: '#fff',
                      background: '#F7C948',
                      width: '88px',
                    }}>
                    Edit
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default OrphanEmailMap