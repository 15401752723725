import React from "react";
import {Link} from 'react-router-dom'
import Logo from "../../assets/images/logoyellow.svg";
import Button from "../../components/atoms/Button";
import open from "../../components/atoms/icons/open.svg";
import "./style/style.scss";

function index() {
    return <div className="pricing">
        <div className="top-section">
            <div className="navigation">
                <div className="logo"><img src={Logo} alt="logo" /></div>
                <Link to="/login">
                    <Button text="Sign In" className="button-gold" />
                </Link>
            </div>
            <div className="head">
                <div className="title">Simple Pricing For Everyone</div>
                <div className="subtitle">Pricing built to suit business of all kinds. Choose a plan to get started</div>
            </div>
        </div>
        <div className="bottom-section">
            <div className="table">
                <div className="list">
                    <div className="item">
                        <div className="name">STARTER</div>
                        <div className="price">
                            <span className="value">$35</span>/Month
                        </div>
                        <div className="features">
                            <p>10 Projects</p>
                            <p>10 Pages</p>
                            <p>100 Mb Disk Space</p>
                        </div>
                        <Button text="Sign In" className="button-gold" />
                    </div>
                    <div className="item">
                        <div className="name">BUSINESS</div>
                        <div className="price">
                            <span className="value">$60</span>/Month
                        </div>
                        <div className="features">
                            <p>10 Projects</p>
                            <p>10 Pages</p>
                            <p>100 Mb Disk Space</p>
                        </div>
                        <Button text="Sign In" className="button-gold" />
                    </div>
                    <div className="item">
                        <div className="name">ENTERPRISE</div>
                        <div className="price">
                            <span className="value">$100</span>/Month
                        </div>
                        <div className="features">
                            <p>10 Projects</p>
                            <p>10 Pages</p>
                            <p>100 Mb Disk Space</p>
                        </div>
                        <Button text="Sign In" className="button-gold" />
                    </div>
                    <div className="item">
                        <div className="name">DATA REPARO</div>
                        <div className="price">
                            <span className="value">$250</span>/Month
                        </div>
                        <div className="features">
                            <p>10 Projects</p>
                            <p>10 Pages</p>
                            <p>100 Mb Disk Space</p>
                        </div>
                        <Button text="Sign In" className="button-gold" />
                    </div>
                </div>
                <div className="subtitle">Sign in to start using the free plan</div>
            </div>
            <div className="faq">
                <div className="head">
                    <div className="title">Frequently Asked Questions</div>
                    <div>Got questions? We got answeres to them.</div>
                </div>
                <div className="list">
                    <div className="item">
                        <div className="pagination">
                            <span>What exactly is thatapp.io about?</span>
                            <img src={open} alt="open" />
                        </div>
                        <div className="text">You can open a thatapp.io account from one of the links above and choose a suitable plan for you.</div>
                    </div>
                    <div className="item">
                        <div className="pagination">
                            <span>What exactly is thatapp.io about?</span>
                            <img src={open} alt="open" />
                        </div>
                        <div className="text">You can open a thatapp.io account from one of the links above and choose a suitable plan for you.</div>
                    </div>
                    <div className="item">
                        <div className="pagination">
                            <span>What exactly is thatapp.io about?</span>
                            <img src={open} alt="open" />
                        </div>
                        <div className="text">You can open a thatapp.io account from one of the links above and choose a suitable plan for you.</div>
                    </div>
                    <div className="item">
                        <div className="pagination">
                            <span>What exactly is thatapp.io about?</span>
                            <img src={open} alt="open" />
                        </div>
                        <div className="text">You can open a thatapp.io account from one of the links above and choose a suitable plan for you.</div>
                    </div>
                    <div className="item">
                        <div className="pagination">
                            <span>What exactly is thatapp.io about?</span>
                            <img src={open} alt="open" />
                        </div>
                        <div className="text">You can open a thatapp.io account from one of the links above and choose a suitable plan for you.</div>
                    </div>
                    <div className="item">
                        <div className="pagination">
                            <span>What exactly is thatapp.io about?</span>
                            <img src={open} alt="open" />
                        </div>
                        <div className="text">You can open a thatapp.io account from one of the links above and choose a suitable plan for you.</div>
                    </div>
                    <div className="item">
                        <div className="pagination">
                            <span>What exactly is thatapp.io about?</span>
                            <img src={open} alt="open" />
                        </div>
                        <div className="text">You can open a thatapp.io account from one of the links above and choose a suitable plan for you.</div>
                    </div>
                    <div className="item">
                        <div className="pagination">
                            <span>What exactly is thatapp.io about?</span>
                            <img src={open} alt="open" />
                        </div>
                        <div className="text">You can open a thatapp.io account from one of the links above and choose a suitable plan for you.</div>
                    </div>
                    <div className="item">
                        <div className="pagination">
                            <span>What exactly is thatapp.io about?</span>
                            <img src={open} alt="open" />
                        </div>
                        <div className="text">You can open a thatapp.io account from one of the links above and choose a suitable plan for you.</div>
                    </div>
                    <div className="item">
                        <div className="pagination">
                            <span>What exactly is thatapp.io about?</span>
                            <img src={open} alt="open" />
                        </div>
                        <div className="text">You can open a thatapp.io account from one of the links above and choose a suitable plan for you.</div>
                    </div>
                </div>
            </div>
            <div className="more-questions">
                <div className="text">
                    <div className="title1">Got more questions?</div>
                    <div className="title2">Reach out to us</div>
                </div>
                <div className="button-container">
                    <Button text="Reach out" className="button-gold" />
                </div>
            </div>
        </div>
    </div>
}

export default index
