import React, { useState } from 'react';
import OrphanFieldsModal from './Modals/OrphanFieldsModal';
import SelectableItem from './utility/SelectableItem';

const mergeLabelAndValue = field => ({ label: field.label, value: JSON.stringify(field) });
const MapperComponent = (props) => {
    const {
        orphanFields,
        field,
        fieldId,
        updateMapper,
        fields
    } = props;
    const [value, setValue] = useState("");
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    // multiple options have same value, 'label' seems to be the unique property in the options
    const transformedOptions = React.useMemo(() => {
      if (orphanFields?.length === 0) return [];

      return orphanFields.map(mergeLabelAndValue); 
    }, [orphanFields]);

    const handleFieldChange = (val) => {
      if (val) {
        const parsedValObject = JSON.parse(val?.value);
        const app = orphanFields.find((field) => field.label === parsedValObject.label && field.value === parsedValObject.value);
        const fieldData = fields.find((where) => where.field_id === fieldId);
        const data = {
          podio_field_id: fieldId,
          podio_field_label: fieldData.label,
          orphan_field_key: app.label,
          orphan_field_name: app.label,
          podio_field_type: fieldData.type,
        };
        updateMapper(data);
        setValue(app.value);
      } else {
        setValue('');
      }
    };

    const handleOpen = (data) => {
        setOpen(true);
    }

    return (
        <>
            <div className="form-row">
                <div className="form-group col-md-4">
                    <label
                        style={{
                            fontWeight: "normal",
                            fontSize: "18px",
                            marginTop: "5px",
                        }}
                        className='text-bold'
                    >
                        {field.label}:
                    </label>
                </div>
        <div className='form-group col-md-6'>
          <SelectableItem placeholder='Select Orphan Fields' options={transformedOptions} handleChange={handleFieldChange} isMulti={false} />
        </div>

                {
                    value ? <div className="form-group col-md-1">
                        <button
                            style={{ cursor: "pointer", color: "#ffffff" }}
                            className="btn btn-warning"
                            onClick={handleOpen}
                        > Preview </button>
                    </div> : null
                }

                <OrphanFieldsModal
                    open={open}
                    handleClose={handleClose}
                    data={value}
                />
            </div>
        </>
    )
}

export default MapperComponent