import React from 'react';
import { Box, Dialog, DialogTitle, DialogActions } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import dayjs from 'dayjs';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import AccessTime from '@material-ui/icons/AccessTime';
import Button from '@material-ui/core/Button';

import fileIcon from 'assets/icons/print_file.svg';
import useAsync from 'components/templates/print-v2/hooks/useAsync';
import { retrieveSignedDocument } from 'components/templates/print-v2/printUtils/requests';
import ActivityLogs from './ActivityLogs';

import styles from './ViewSignedDocument.module.scss';

const formatDate = (value) => {
  return dayjs(value).format('dddd D, MMMM YYYY, h:mm A');
};

const ViewSignedDocument = ({ open, handleClose, template_id, item_id }) => {
  const [viewLogs, setViewLogs] = React.useState(false);
  const { execute, status, value } = useAsync(
    () =>
      retrieveSignedDocument({ template_id, item_id }).then((res) => {
        return res?.data?.document;
      }),
    false
  );

  React.useEffect(() => {
    execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      className={styles.override}
      maxWidth='lg'>
      <Box className={styles.container}>
        <DialogTitle>
          <div className={styles.titleContainer}>
            <h6> Signed Document Details</h6>
            <Button variant='outlined' onClick={() => setViewLogs(!viewLogs)}>
              Toggle Activity Logs
            </Button>
          </div>
        </DialogTitle>
        <DialogActions style={{ position: 'absolute', right: 0, top: 0 }}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <div className={styles.contentContainer}>
          {status === 'pending' ? <p>Fetching Document details...</p> : null}
          {value && viewLogs ? <ActivityLogs audits={value?.audits} /> : null}
          {value && !viewLogs ? (
            <>
              <div className={styles.file_details_container}>
                <img src={fileIcon} alt='file icon' />
                <div>
                  <p>
                    Document Name: <b>{value.name}</b>
                  </p>
                  <p>Sender: {value?.sender?.name}</p>
                  <p>
                    Document status: <span className={value?.state === 'pending' ? styles.pending : styles.completed}>{value?.state}</span>
                  </p>
                  <p>Date Sent: {formatDate(value?.sent_at)}</p>
                  {value?.signed_pdf_url ? (
                    <a href={value?.signed_pdf_url} target='_blank' rel='noreferrer' className={`${styles.download_link}`}>
                      Download File
                    </a>
                  ) : null}
                </div>
              </div>

              <h6 className='mt-2 mb-2'>Recipients</h6>
              <div className={styles.recipient_container}>
                {value?.recipients.map((recipient, i) => {
                  return (
                    <p key={i}>
                      {recipient?.email}
                      {recipient?.status === 'pending' ? (
                        <span className={styles.pending}>
                          {
                            <>
                              <AccessTime /> {recipient?.status}
                            </>
                          }
                        </span>
                      ) : (
                        <span className={styles.completed}>
                          {
                            <>
                              <CheckCircleOutline /> {recipient?.status}
                            </>
                          }
                        </span>
                      )}
                    </p>
                  );
                })}
              </div>
            </>
          ) : null}
        </div>
      </Box>
    </Dialog>
  );
};

export default ViewSignedDocument;
