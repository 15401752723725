import React, { useEffect } from "react";
import "./integration.css";
import heroBg from "../Images/integration-hero-img.png";
import Features from "../components/integration/Features";
import Header from "../components/shared/Header";
import Footer from "../components/shared/Footer";
export default function Integration() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div>
      <Header />
      <div className="integration-hero-section">
        <div className="integration-hero-section-inner">
          <div className="integration-hero-text">
            <h1>
              Unlock Full Control With Our Integrated
              <span className="integration-hero-span"> App Solutions</span>
            </h1>
            <p className="integration-hero-p">
              It's our job to ensure that you're never lost in the clouds. Gain
              a refreshing perspective of your business processes and strategic
              initiatives.
            </p>
          </div>
          <div className="integration-hero-img-section">
            <img src={heroBg} alt="integrations" />
          </div>
        </div>
        <div className="info-cont">
          <div className="info-cont-inner">
            <Features />
          </div>
        </div>
      </div>
      <Footer bg="#f0b429" />
    </div>
  );
}
