import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import arrowDown from "../../assets/icons/arrowDownB.svg";
import { useHistory } from "react-router";
import {
  USER_ACCOUNT,
  PLANS,
  MY_INVOICES,
  PLATFORM_USERS,
  //   PLATFORM_REFFERALS,
  //   MY_PARTNERS,
} from "../../containers/pagePath";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import PdcModal from "components/PodioDataCloneModal/PdcModal";
import Axios from "config";
import { getMe } from "../../services/auth";
import { setUser } from "../../redux/actions/user";
import BackButton from "components/atoms/BackButton";
import backIconMobile from "../../assets/icons/backBtn.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  hide: {
    display: "none",
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "5%",
  },
  headerText: {
    color: "black",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    background: "white",
    marginTop: "10%",
    height: "95vh",
  },
  // backButton: {
  //   backgroundColor: "#f7c948",
  //   boxShadow: "0px 3px 6px #00000029",
  //   padding: "1vh 1vw",
  //   outline: "none",
  //   textDecoration: "none",
  //   border: "none",
  //   borderRadius: "6px",
  //   color: "white",
  //   marginBottom: "2%",
  // },
}));

const AccountOverview = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [open, setOpen] = React.useState(true);
  const [openMobile, setOpenMobile] = React.useState(false);
  const history = useHistory();
  const [openPdcModal, setOpenPdcModal] = React.useState(false);
  const dispatch = useDispatch();
  const [cloneTabs, setCloneTabs] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(!open);
    setOpenMobile(!openMobile);
  };

  const handlePdcOpen = () => {
    setOpenPdcModal(true);
  };

  const handlePdcClose = () => {
    setOpenPdcModal(false);
  };
  function handleDisconnectFromPodio() {
    Axios.post("/sync/podio/disconnect")
      .then(() => {
        getMe().then((response) => {
          dispatch(setUser(response.data));
          window.location = "/connect";
        });
      })
      .catch(console.error);
  }

  const handleCloneTab = () => {
    setCloneTabs(!cloneTabs);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header baropen={open} handleDrawerOpen={handleDrawerOpen} />
      {isMobile ? (
        <Sidebar open={openMobile} handleDrawerOpen={handleDrawerOpen} />
      ) : (
        <Sidebar open={open} handleDrawerOpen={handleDrawerOpen} />
      )}

      {cloneTabs ? (
        <div className={classes.content}>
          <div onClick={handleCloneTab} className="mt-2">
            <img src={backIconMobile} alt="" />
          </div>
          <div
            style={{
              border: "1px solid #CDD0D53B",
              padding: "5% 3%",
              borderRadius: "12px",
            }}
            className="d-flex justify-content-between mt-4"
            onClick={(e) => {
              handlePdcOpen(e);
            }}
          >
            <h5 style={{ fontSize: "16px" }}> Organisation to Organisation</h5>
            <img src={arrowDown} alt="" />
          </div>

          <div
            onClick={(e) => {
              handlePdcOpen(e);
            }}
            style={{
              border: "1px solid #CDD0D53B",
              padding: "5% 3%",
              borderRadius: "12px",
            }}
            className="d-flex justify-content-between mt-4"
          >
            <h5 style={{ fontSize: "16px" }}> App to Space</h5>
            <img src={arrowDown} alt="" />
          </div>

          {/* <div
            onClick={(e) => {
              handlePdcOpen(e);
            }}
            style={{
              border: "1px solid #CDD0D53B",
              padding: "5% 3%",
              borderRadius: "12px",
            }}
            className="d-flex justify-content-between mt-4"
          >
            <h5 style={{ fontSize: "16px" }}> Clone Podio Data</h5>
            <img src={arrowDown} alt="" />
          </div> */}
        </div>
      ) : (
        <div className={classes.content}>
          <BackButton />
          <div
            style={{
              border: "1px solid #CDD0D53B",
              padding: "5% 3%",
              borderRadius: "12px",
            }}
            className="d-flex justify-content-between mt-4"
            onClick={(e) => {
              history.push(USER_ACCOUNT);
            }}
          >
            <h5 style={{ fontSize: "16px" }}> My Account</h5>
            <img src={arrowDown} alt="" />
          </div>

          <div
            onClick={(e) => {
              history.push(MY_INVOICES);
            }}
            style={{
              border: "1px solid #CDD0D53B",
              padding: "5% 3%",
              borderRadius: "12px",
            }}
            className="d-flex justify-content-between mt-4"
          >
            <h5 style={{ fontSize: "16px" }}> My Invoices</h5>
            <img src={arrowDown} alt="" />
          </div>

          <div
            onClick={handleCloneTab}
            style={{
              border: "1px solid #CDD0D53B",
              padding: "5% 3%",
              borderRadius: "12px",
            }}
            className="d-flex justify-content-between mt-4"
          >
            <h5 style={{ fontSize: "16px" }}> Clone Podio Data</h5>
            <img src={arrowDown} alt="" />
          </div>

          <div
            onClick={(e) => {
              history.push(PLATFORM_USERS);
            }}
            style={{
              border: "1px solid #CDD0D53B",
              padding: "5% 3%",
              borderRadius: "12px",
            }}
            className="d-flex justify-content-between mt-4"
          >
            <h5 style={{ fontSize: "16px" }}> Platform Users</h5>
            <img src={arrowDown} alt="" />
          </div>

          <div
            onClick={(e) => {
              history.push(PLANS);
            }}
            style={{
              border: "1px solid #CDD0D53B",
              padding: "5% 3%",
              borderRadius: "12px",
            }}
            className="d-flex justify-content-between mt-4"
          >
            <h5 style={{ fontSize: "16px" }}> Plans</h5>
            <img src={arrowDown} alt="" />
          </div>

          <div
            onClick={() => {
              Swal.fire({
                title: "Remove Podio",
                text: "Your podio account would be removed from sync",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Remove Account",
                cancelButtonText: "Cancel",
              }).then((result) => {
                if (result.value) {
                  handleDisconnectFromPodio();
                }
              });
            }}
            style={{
              border: "1px solid #CDD0D53B",
              padding: "5% 3%",
              borderRadius: "12px",
            }}
            className="d-flex justify-content-between mt-4"
          >
            <h5 style={{ fontSize: "16px" }}> Disconnect Podio</h5>
            <img src={arrowDown} alt="" />
          </div>

          {/* <div
          onClick={() => {
            dispatch(logout());
            history.push("/login");
          }}
          style={{
            border: "1px solid #CDD0D53B",
            padding: "5% 3%",
            borderRadius: "12px",
          }}
          className="d-flex justify-content-between mt-4"
        >
          <h5 style={{ fontSize: "16px" }}> Logout</h5>
          <img src={arrowDown} alt="" />
        </div> */}
        </div>
      )}

      <PdcModal open={openPdcModal} handleClose={handlePdcClose} />
    </div>
  );
};

export default AccountOverview;
