import React, { useState, useEffect} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import Pusher from "pusher-js";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import "./HooksGrid.css";
// import * as AllModules from "ag-grid-enterprise";
// import { ModuleRegistry } from "ag-grid-enterprise";

// ModuleRegistry.registerModules(Object.keys(AllModules));
const LatestGridHooksClass = (props) => {
    const [autoGroupColumnDef] = useState({ minWidth: 200 })
    const [columnDefs] = useState ([
      { headerName: "Exec. Time", field: "execution_time" },
      { headerName: "Mongo Id", field: "mongo_id" },
      { headerName: "User", field: "user" },
      { headerName: "Hook ID", field: "hook_id" },
      { headerName: "Outcome", field: "outcome" },
      { headerName: "Time", field: "time" },
      { headerName: "Type", field: "type" },
    ]);
    const [rowData, setRowData] = useState([]);
    const [defaultColDef] = useState({
        flex: 1,
        minWidth: 140,
        resizable: true,
        filter: true,
        sortable: true,
        enableRowGroup: props.user?.permissions?.data_interaction?.row_grouping ? true : false,
        enablePivot: props.user?.permissions?.data_interaction?.pivot_reporting ? true : false,
        enableValue: true,
      });

    useEffect(() => {
      const pusher = new Pusher("91c3f23431d0a0e33eee", {
        cluster: "mt1",
        encrypted: true,
      });
      const channel = pusher.subscribe("2265081_hooks");
  
      channel.bind("UserEvent", (data) => {
        let newRow = rowData.map((item) => {
          if (item.hook_id === data.hook_id) item = { ...data };
          return item;
        });
  
        setRowData(newRow);
      });
  
      setRowData(props.hooksLog);
    }, [props.hooksLog, rowData]);

    return (
      <>
        <div style={{ width: "100%", height: "500px" }}>
          <div
            id="myGrid"
            style={{
              height: "100%",
              width: "100%",
              overflow: "hidden",
            }}
            className="ag-theme-balham-dark"
          >
            <AgGridReact
              // modules={Object.keys(AllModules)}
              columnDefs={columnDefs}
              rowData={rowData}
              defaultColDef={defaultColDef}
              enableFilter={true}
              enableSorting={true}
              enableCharts={true}
              rowGroupPanelShow={ props.user?.permissions?.data_interaction?.row_grouping ? "always" : "never"}
              rowSelection="multiple"
              debug={true}
              sideBar={true}
              enableCellTextSelection={true}
              suppressAggFuncInHeader={true}
              autoGroupColumnDef={autoGroupColumnDef}
              enableRangeSelection={true}
              animateRows={true}
            ></AgGridReact>
          </div>
        </div>
      </>
    );
  
}

export default LatestGridHooksClass;
