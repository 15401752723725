import React from 'react';
import Axios from '../../../config';
import { useDispatch, useSelector } from 'react-redux';
import { getTeamMembers } from 'redux/actions/user';
import Select from 'react-select';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { Box, Dialog, DialogTitle, DialogContent, Typography, DialogActions } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { toast } from '../../../helpers/apiRequests';

import styles from './ViewTeamMemberOrgs.module.scss';
const isTeamMember = localStorage.getItem('team_member_uuid');

// super user can view and manage all roles
// team member user can view and modify clients/partner accounts but restricted to organizations they have acces to
// team member user can view other team member users but cannot modify other team members

const ViewTeamMemberOrgs = (props) => {
  const [loading, setLoading] = React.useState(false);

  const role = props.selectedMember?.role.id;

  const dispatch = useDispatch();
  const [selectedOrgs, setSelectedOrgs] = React.useState([]);

  const currentOrgs = React.useMemo(
    () => props?.selectedMember?.organizations?.map((org) => org?.org_id ?? []),
    [props?.selectedMember?.organizations]
  );

  const orgs = useSelector((state) => state.sync.orgs);
  const options = React.useMemo(() => orgs?.map((org) => ({ label: `${org?.name} - ${org.org_id}`, value: org.org_id })) ?? [], [orgs]);

  const filteredOptions = options.filter((org) => !currentOrgs?.includes(org.value));

  const { open, toggle, selectedMember, setSelectedMember } = props;
  function handleClose() {
    dispatch(getTeamMembers());
    toggle(false);
    setSelectedMember(undefined);
  }

  const handleOrgChange = (val) => {
    if (val) {
      setSelectedOrgs(val);
    } else {
      setSelectedOrgs([]);
    }
  };

  function handleSubmit() {
    const { email, name, role } = props.selectedMember;
    const org_ids = selectedOrgs.map((org) => org.value);

    if (org_ids.length > 0) {
      setLoading(true);
      Axios.post('/account/team-members/invite', { email, name, role_id: role.id, org_ids })
        .then(() => {
          toast.notify('Access Granted Successfully');
          handleClose();
        })
        .catch(console.error)
        .finally(() => setLoading(false));
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableEscapeKeyDown={true}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      className={styles.override}
      maxWidth='lg'>
      <Box className={styles.container}>
        <DialogTitle>
          <Typography id='modal-modal-title' variant='h6' component='p'>
            View Organizations
          </Typography>
        </DialogTitle>
        <DialogActions style={{ position: 'absolute', right: 0, top: 0 }}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogContent style={{ padding: 0, height: '400px' }}>
          <section>
            {!isTeamMember || role === 2 ? (
              <div className={styles.add_container}>
                <Select
                  isClearable
                  placeholder='Select Organizations'
                  options={filteredOptions}
                  onChange={handleOrgChange}
                  isMulti={true}
                  theme={(theme) => {
                    return {
                      ...theme,
                      colors: {
                        ...theme.colors,
                        ...customTheme,
                      },
                    };
                  }}
                />

                <Button variant='contained' color='primary' onClick={handleSubmit} disabled={loading}>
                  {loading ? 'Granting Access...' : 'Grant Access'}
                </Button>
              </div>
            ) : null}
            <div className={styles.org_container}>
              {props?.selectedMember?.organizations?.map((organization) => (
                <OrgGroup
                  key={organization.id}
                  {...{ organization, selectedMember, loggedInUserOrgs: options.map((org) => org?.value) }}
                  handleClose={handleClose}
                />
              ))}
            </div>
          </section>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default ViewTeamMemberOrgs;

function OrgGroup({ organization, selectedMember, handleClose, loggedInUserOrgs }) {
  const role = selectedMember?.role.id;
  const [loading, setLoading] = React.useState(false);

  function handleRemove() {
    setLoading(true);
    return Axios.post('account/team-members/revoke-organization', { member_id: selectedMember.member_id, organization_id: organization.id })
      .then(() => {
        toast.notify('Removed Successfully');
        handleClose();
      })
      .finally(() => setLoading(false));
  }

  //team members should not be able to revoke orgs they cant access from other teammembers
  let disableOrganization = isTeamMember && !loggedInUserOrgs.includes(organization.org_id) && selectedMember?.role?.id === 1;

  return (
    <div className={styles.org_group}>
      <div className={styles.dot_contianer}>
        <span className={styles.dot}></span>
        <div className={styles.org_wrapper}>
          <p className={styles.org_name}>{organization?.org_name}</p>
          <p className={styles.org_id}>{` - ${organization.org_id}`}</p>
        </div>
      </div>
      {role === 2 || !isTeamMember ? (
        <button onClick={handleRemove} disabled={loading || disableOrganization} className='btn btn-danger'>
          {loading ? 'Revoking...' : 'Revoke Access'}
        </button>
      ) : null}
    </div>
  );
}

const customTheme = {
  primary25: '#fff',
  primary: 'rgba(252, 175, 59, 0.9)',
  primary50: 'rgba(252, 175, 59, 0.5)',
  primary75: 'rgba(252, 175, 59, 0.7)',
};
