import React, { useState } from 'react';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import AppFieldInput from './AppFieldInput';

const NewAppQuestion = ({
  option,
  changeOption,
  changeContactField,
  changeRefApp,
  changeSubject,
  fields,
  isloadingRefApp,
  subject,
  contactField,
  refApp,
  InAppSetting
}) => {
  const [showField, setShowField] = useState(false);
  const optionChange = (payload) => {
    setShowField(true);
    changeOption(payload.target.value);
  };

  const subJectOptions =
    fields.subject_fields === 0
      ? null
      : fields.subject_fields.map((subFd) => (
          { label: subFd.name, value: subFd.id }
        ));

  const EmailOptions =
    fields.email_fields === 0
      ? null
      : fields.email_fields.map((subFd) => (
        { label: subFd.name, value: subFd.id }
      ));

  const AppOptions =
    fields.app_fields === 0
      ? null
      : fields.app_fields.map((subFd) => (
        { label: subFd.name, value: subFd.app_id }
      ));

  return (
    <RadioGroup aria-label="application" name="application" value={option} onChange={optionChange}>
      <FormControlLabel 
        value="manually" 
        control={<Radio color="primary" />}
        label="Not applicable - I will manually add recipients to each email" 
      />
      <FormControlLabel 
        value="single" 
        control={<Radio color="primary" />}
        label="In a Single-line Text field or Email field in this app." 
      />
      {(showField && option === 'single') ? (
        <AppFieldInput
          option={option}
          EmailOptions={EmailOptions}
          changeContactField={changeContactField}
          changeSubject={changeSubject}
          subJectOptions={subJectOptions}
          changeRefApp={changeRefApp}
          AppOptions={AppOptions}
          isloadingRefApp={isloadingRefApp}
          subject={subject}
          refApp={refApp}
          contactField={contactField}
          InAppSetting={InAppSetting}
        />
      ) : null}
      <FormControlLabel 
        value="single-reference" 
        control={<Radio color="primary" />}
        label="In a Single-line Text field or Email field in a Referenced app." 
      />
      {(showField && option === 'single-reference') ? (
        <AppFieldInput
          option={option}
          EmailOptions={EmailOptions}
          changeContactField={changeContactField}
          changeSubject={changeSubject}
          subJectOptions={subJectOptions}
          changeRefApp={changeRefApp}
          AppOptions={AppOptions}
          isloadingRefApp={isloadingRefApp}
          subject={subject}
          refApp={refApp}
          contactField={contactField}
          InAppSetting={InAppSetting}
        />
      ) : null}
    </RadioGroup>
  )
}

export default NewAppQuestion