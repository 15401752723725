import { toast } from "helpers/apiRequests";


const redirect = () => {
  localStorage.setItem("user_token", null);
  localStorage.setItem("user_token", null);
  toast.notify("Redirecting to Login", { type: "error" });
  setTimeout(() => (window.location.href = "/login"), 1000);
};
export default (error) => {
  if (error?.data) {
    const { errors, message } = error.data;
    if (message) {
      toast.notify(message, { type: "error" });
      if (message === "token_expired") {
        redirect();
      }
    }
    if (!message) {
      if (errors && typeof errors === Array) {
        errors.forEach((error) => {
          toast.notify(error.code, { type: "error" });
        });
      }
    }
  }
};