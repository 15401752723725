/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Error from "../../../containers/Mysql/components/Error/Error";
import CreateMemberModal from "./Modals/CreateMemberModal ";
import DeleteMember from "./Modals/DeleteMember";
import { ADD_MEMBERS } from "../../../containers/pagePath";
import { useHistory } from "react-router";
import RoleModal from "./Modals/RoleModal";

const MembersTab = ({ spaceMembers, members, appId, spaceId }) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [member, setMember] = useState();

  const handleOpen = () => setOpen(true);
  const handleOpenDelete = (data) => {
    setMember(data);
    setOpenDelete(true);
  };
  const handleOpenEdit = (data) => {
    setMember(data);
    setOpenEdit(true);
  };
  const handleClose = () => setOpen(false);
  const handleCloseDelete = () => setOpenDelete(false);
  const handleCloseEdit = () => setOpenEdit(false);
  const clickAction = () => {
    const stateData = {
      spaceId,
      appId,
    };
    history.push({
      pathname: ADD_MEMBERS,
      state: stateData,
    });
  };
  return (
    <div className="row">
      <div className="col-md-10">
        <CreateMemberModal
          openModal={open}
          handleClose={handleClose}
          spaceMembers={spaceMembers}
          appId={appId}
        />
        <DeleteMember
          openModal={openDelete}
          handleClose={handleCloseDelete}
          item={member}
          appId={appId}
        />
        <RoleModal
          openModal={openEdit}
          handleClose={handleCloseEdit}
          member={member}
          spaceMembers={spaceMembers}
          appId={appId}
        />
        <div className="table-responsive">
          {members.length > 0 ? (
            <button
              className="map-data-button mb-2"
              onClick={handleOpen}
              style={{
                color: "#de911d",
              }}
            >
              Add Member
            </button>
          ) : null}

          {members.length > 0 ? (
            <div className="table-responsive">
              <table
                style={{ width: "80%" }}
                className="table table-striped table-bordered"
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {members.map((member, i) => {
                    return (
                      <tr key={member.id}>
                        <td>{i + 1}</td>
                        <td>{member.podio_user_name}</td>
                        <td>{member.podio_user_email}</td>
                        <td>{member.role}</td>
                        <td>
                          <i
                            className="fas fa-edit text-primary mr-3"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => handleOpenEdit(member)}
                          ></i>
                          <i
                            className="fas fa-trash-alt text-danger"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => handleOpenDelete(member)}
                          ></i>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <Error
              errorMsg="No Members Found"
              errorAction="Add Members here"
              onClick={handleOpen}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MembersTab;
