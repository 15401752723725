/* eslint-disable react/display-name */
import React, { useEffect } from 'react';
import MaterialTable from 'material-table';

import dayjs from 'dayjs';
import DeleteTemplate from '../DeleteTemplate/DeleteTemplate';
import ArchiveTemplate from '../DeleteTemplate/ArchiveTemplate';
import TableActionsMenu from './TableActionsMenu';
import useAsync from '../hooks/useAsync';
import { getTemplates } from '../printUtils/requests';
import './table.scss';
import CloneTemplate from '../CloneTemplate/CloneTemplate';
import RestoreTemplate from '../RestoreTemplate/RestoreTemplate';
import TransferTemplate from '../TransferTemplate/TransferTemplate';
import { useLocation } from 'react-router-dom';

const formatDate = (date, format = 'MMMM DD, YYYY') => (date ? dayjs(date).format(format) : null);
function RenderDateRow(props) {
  const { item1 = '', item2 = '' } = props;
  if (!item1) return props?.fallback ?? null;
  return (
    <div className='cont'>
      {formatDate(item1)}
      {item2 && <p>{item2}</p>}
    </div>
  );
}

const Table = ({ paginationData, setpaginationData, templateCategory }) => {
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [archiveOpen, setArchiveOpen] = React.useState(false);
  const [cloneOpen, setCloneOpen] = React.useState(false);
  const [transferOpen, setTransferOpen] = React.useState(false);
  const [restoreOpen, setRestoreOpen] = React.useState(false);

  const [itemToDelete, setItemToDelete] = React.useState(undefined);
  const [itemToArchive, setItemToArchive] = React.useState(undefined);
  const [itemToClone, setItemToClone] = React.useState(undefined);
  const [itemToTransfer, setItemTransfer] = React.useState(undefined);
  const [itemToRestore, setItemRestore] = React.useState(undefined);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const appId = params.get('app_id');

  const { execute, value, status } = useAsync(
    () => getTemplates({ appId, per_page: paginationData.per_page, page: paginationData.page, templateCategory }),
    false
  );

  useEffect(() => {
    execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationData.per_page, paginationData.page, templateCategory]);

  const templates = React.useMemo(() => value?.data ?? [], [value?.data]);
  const total = React.useMemo(() => value?.pagination?.total ?? 0, [value?.pagination?.total]);

  const handleEdit = (id) => {
    window.open(`/print-data/edit/${id}`, '_self');
  };

  const handleDelete = (id) => {
    setDeleteOpen(true);
    setItemToDelete(id);
  };

  const handleArchive = (id) => {
    setArchiveOpen(true);
    setItemToArchive(id);
  };

  const handleClone = (id) => {
    setCloneOpen(true);
    setItemToClone(id);
  };

  const handleTransfer = (id) => {
    setTransferOpen(true);
    setItemTransfer(id);
  };

  const handleRestore = (id) => {
    setRestoreOpen(true);
    setItemRestore(id);
  };

  const handleCloseDelete = () => {
    setDeleteOpen(false);
    setItemToDelete(undefined);
  };

  const handleCloseArchive = () => {
    setArchiveOpen(false);
    setItemToArchive(undefined);
  };

  const handleCloseClone = () => {
    setCloneOpen(false);
    setItemToClone(undefined);
  };

  const handleCloseTransfer = () => {
    setTransferOpen(false);
    setItemTransfer(undefined);
  };

  const handleCloseRestore = () => {
    setRestoreOpen(false);
    setItemRestore(undefined);
  };

  function handleRowsPerPageChange(size) {
    setpaginationData((prev) => ({ ...prev, per_page: size }));
  }
  function handlePageChange(page) {
    setpaginationData((prev) => ({ ...prev, page }));
  }

  const columns = [
    {
      title: 'TITLE',
      field: 'title',
      render: (row) => (templateCategory === 'active' ? <a href={`/print-data/edit/${row.id}`}>{row?.title}</a> : <h3>{row?.title}</h3>),
    },
    { title: 'PRINTS ', field: 'prints', render: (row) => (row.prints === 1 ? `1 Print` : `${row.prints} Prints`) },
    {
      title: 'CREATED AT',
      field: 'created_at',
      render: (row) => <RenderDateRow item1={row['created_at']} item2={row?.creator?.name} />,
    },
    {
      title: 'LAST UPDATED',
      field: 'last_updated',
      render: (row) => <RenderDateRow item1={row?.updated_at} item2={row?.updater?.name} />,
    },
    {
      title: 'LAST PRINTED',
      field: 'last_printed',
      render: (row) => <RenderDateRow item1={row?.last_printed_at} fallback='No printing history' />,
    },
    {
      title: 'ACTIONS',
      field: null,
      align: 'center',
      render: (row) => (
        <TableActionsMenu data={row} {...{ handleDelete, handleArchive, handleEdit, handleClone, handleTransfer, templateCategory, handleRestore }} />
      ),
    },
  ];

  const options = {
    actionsColumnIndex: -1,
    search: false,
    pageSize: paginationData.per_page,
    filterType: 'dropdown',
    pageSizeOptions: [1, 2, 5, 10, 20, 25],
    paginationType: 'normal',
  };

  return (
    <>
      {restoreOpen ? (
        <RestoreTemplate open={restoreOpen} handleClose={handleCloseRestore} templateId={itemToRestore} refreshTemplates={execute} />
      ) : null}
      {archiveOpen ? (
        <ArchiveTemplate open={archiveOpen} handleClose={handleCloseArchive} templateId={itemToArchive} refreshTemplates={execute} />
      ) : null}
      {deleteOpen ? <DeleteTemplate open={deleteOpen} handleClose={handleCloseDelete} templateId={itemToDelete} refreshTemplates={execute} /> : null}
      {cloneOpen ? <CloneTemplate open={cloneOpen} handleClose={handleCloseClone} templateId={itemToClone} refreshTemplates={execute} /> : null}
      {transferOpen ? (
        <TransferTemplate open={transferOpen} handleClose={handleCloseTransfer} templateId={itemToTransfer} refreshTemplates={execute} />
      ) : null}
      <div div className='templates_table_container'>
        <MaterialTable
          title=''
          columns={columns}
          data={templates}
          options={options}
          page={paginationData.page}
          totalCount={total}
          isLoading={status === 'pending'}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
        />
      </div>
    </>
  );
};

export default Table;
