import React from 'react'
// icons
import arrow_right from '../icons/arrow-right2.svg'

const IntegrationsTab = e => {
    return <div className="item">
        <img src={e.image} className="client" alt="podio" />
        <div className="text">
            <div className="name bold">
                <span>{e.name}</span>
                <img src={arrow_right} alt="arrow" />
            </div>
            {e.available 
                ? <span className="tag bg-6 color-3 font-12">Available</span>
                : <span className="tag bg-7 color-1 font-12">Coming Soon</span>
            }
        </div>
    </div>
}

export default IntegrationsTab
