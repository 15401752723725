import React from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Typography,
} from "@material-ui/core";
import dayjs from 'dayjs';

const AttachmentModal = ({ openModal, handleClose, attachments, }) => {
    const openLinkInNewTab = (url) => {
        window.open(url, '_blank')
    }

    return (
        <div>
            <Dialog
                open={openModal}
                onClose={handleClose}
                // hideBackdrop={true}
                disableEscapeKeyDown={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="700px"
            >
                <DialogContent>
                    <Box style={{ padding: "20px", width: "700px" }}>
                        <Typography id="modal-modal-title" variant="h6" component="p" className="mb-3">
                            Attachments List
                        </Typography>
                        {
                            attachments?.length === 0 ? <center>
                                <h5>No data at the momment</h5>
                            </center> :

                                <div className="table-responsive">

                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <th>#</th>
                                            <th>Filename</th>
                                            <th>Url</th>
                                            <th>Date</th>
                                        </thead>
                                        <tbody>
                                            {
                                                attachments?.map((hook, index) => (
                                                    <tr key={hook.id}>
                                                        <td>{index + 1}</td>
                                                        <td>{hook.filename}</td>
                                                        <td>
                                                            <button
                                                                className="btn btn-secondary btn-sm"
                                                                style={{
                                                                    background: "#F0B429",
                                                                }}
                                                                onClick={() => openLinkInNewTab(hook.url)}
                                                            >View</button></td>
                                                        <td>{dayjs(hook.created_at).format("YYYY-MM-DD:mm:ss A")}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                        }
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button id="button-cancel" className="mr-4 " onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AttachmentModal;
