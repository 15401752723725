/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from "react";
import ConnectTemplate from "../../../containers/Connector/ConnectTemplate";
import Podio from "../../../assets/images/podioconnect.png";
import Sales from "../../../assets/images/salesforce.png";
import Zoho from "../../../assets/images/zoho.png";
import Json from "../../../assets/images/json.png";
import printLogo from "../../../assets/images/printlogo.png";
import { useHistory } from "react-router";
import { PRINT_HOME } from "../../../containers/pagePath";
import { useSelector, useDispatch } from "react-redux";
import ConnectModal from "../../../containers/Connector/ConnectModal";
import {
  parseHashString,
  authorize,
} from "../../../containers/Connector/sharedFunctions";
import MyModal from "../../../components/molecule/Modal";
import { authV2Connect } from "../../../helpers/Authentication/Auth";
import { getMe } from "../../../services/auth";

import { setUser } from '../../../redux/actions/user';
import { getPrintSyncedOrganizations } from '../../../services/sync';
import { setOrgs } from '../../../redux/actions/sync';
import { getNavigation } from '../../../services/common';
import { setNav } from '../../../redux/actions/sidebar';
import { setAuditOrg } from '../../../redux/actions/orgs';
import * as actions from '../../../redux/actions';
import Axios from 'config';

function authorizePrintWithPodio({ ref_id, access_token, refresh_token }) {
  return Axios.post(`${process.env.REACT_APP_BASEURL}/print/podio/auth/connect`, {
    ref_id,
    access_token,
    refresh_token,
  });
}

const Connections = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedConnector, setSelectedConnector] = useState({
    title: "",
    image: "",
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const [connecting, setConnecting] = useState({
    connector: null,
    loading: false,
  });
  const user = useSelector((state) => state.user.authUser);
  const [connectors, setConnectors] = useState([]);

  useEffect(() => {
    if (user.connected_platform) {
      const connectItems = user.connected_platform.map(
        (item) => item.connector_id
      );

      setConnectors(connectItems);
    }
  }, [user]);

  const setConnector = (title, logo, index) => {
    if (connectors.includes(index)) {
      getOrgs();
    } else {
      setSelectedConnector({
        title,
        image: logo,
      });
      setShowModal(true);
    }
  };

  const getCards = () => [
    {
      status: user.print_connection ? 'Connected' : 'Disconnected',
      image: Podio,
      text: 'Podio',
    },
    // {
    //   image: Json,
    //   text: "Json",
    // },
    // {
    //   image: Sales,
    //   text: "Salesforce",
    // },
    // {
    //   image: Zoho,
    //   text: "Zoho CRM",
    // },
  ];

  const [cards, setCards] = useState(getCards());

  useEffect(() => {
    setCards(getCards());
    //eslint-disable-next-line
  }, [connectors]);

  const handleConnectCardClick = (item) => {
    if (item.status === 'Disconnected') {
      setSelectedConnector({
        title: item.text,
        image: item.image,
      });

      setShowModal(true);

      return;
    } else {
      setCards((prev) =>
        prev.map((currentItem) => {
          if (currentItem.text === item.text && currentItem.status) {
            // we are going to decide how to get the index for the setConnector later
            setConnector(item.text, item.image, 1);

            if (currentItem.status.toLowerCase() === 'connected') {
              return {
                ...currentItem,
                isLoading: !currentItem.isLoading,
              };
            }
          }

          return currentItem;
        })
      );
    }
  };

  const getNav = async () => {
    const response = await getNavigation();

    dispatch(setNav(response.menus));
  };

  const getUser = useCallback(async () => {
    const response = await getMe();

    dispatch(setUser(response.data));
  }, [dispatch]);

  const getOrgs = async () => {
    const response = await getPrintSyncedOrganizations();

    await dispatch(setOrgs(response.data));
    await getNav();
    await getUser();
    const newResponse = await getPrintSyncedOrganizations();

    await dispatch(setAuditOrg(newResponse.data));
    //Dispatch synced and unsynced
    const authToken = localStorage.getItem("chosen_token");

    dispatch(actions.getAllUnSyncedAccounts(authToken));

    if (response && newResponse) {
      return history.push(PRINT_HOME);
    }
  };

  useEffect(() => {
    const auth_type = localStorage.getItem("auth_type");

    const hashString = window.location.hash.substr(1);

    if (hashString && auth_type === "podio") {
      const access = parseHashString(hashString);

      if (access["access_token"]) {
        access["user_id"] = access["ref[id]"];
        const response = authV2Connect(access, auth_type);

        if (response) {
          getUser();
        }
      }
    }
  }, [connecting, getUser]);

  useEffect(() => {
    const auth_type = localStorage.getItem('auth_type');

    if (auth_type === 'podio') {
      const hashString = window.location.hash.substr(1);
      const access = parseHashString(hashString);
      const payload = {
        ref_id: access['ref[id]'],
        access_token: access.access_token,
        refresh_token: access.refresh_token,
      };

      authorizePrintWithPodio(payload).then(console.log);
    }
  }, [connecting, getUser]);

  return (
    <div>
      <MyModal open={showModal} onClose={() => setShowModal(false)}>
        <ConnectModal
          info={selectedConnector}
          handleConnect={() =>
            authorize(selectedConnector, setConnecting, "/connect-print")
          }
          handleClose={() => setShowModal(false)}
        />
      </MyModal>
      <ConnectTemplate
        cards={cards}
        userName={user.name}
        onConnectCardClick={handleConnectCardClick}
        backgroundColor="#F8FCE9"
        textColor="#bad360"
        logo={printLogo}
      />
    </div>
  );
};

export default Connections;
