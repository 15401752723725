/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import "./style.css"
import SelectableItem from './utility/SelectableItem';
import axios from "../../../config";
import { toast } from "helpers/apiRequests";
import { CircularProgress } from '@material-ui/core';
import MapperComponent from './MapperComponent';

import styles from "./OrphanEmail.module.css"

const OrphanEmail = (props) => {
    const { changeOrgId, mappedData, items, apps, handleClose } = props;
    const [selectedApp, setSelectedApp] = useState(null);
    const [saveLoading, setSaveLoading] = useState(false);
    const [fields, setFields] = useState([]);
    const [orphanFields, setOrphanFields] = useState([]);
    const [mappers, setMappers] = useState([]);
    const [loading, setLoading] = useState({
        app: false,
        orphan: false
    });
    const [defaultOptions, setDefaultOptions] = useState();

    const getOrphanFields = async (orgId) => {
        try {
            setLoading({
                ...loading,
                orphan: true
            })
            const response = await axios.get(`/tmail/orphan/fields/list?org_id=${orgId}`);
            const data = response.data;
            const orphanFieldData = []
            for (var [key, value] of Object.entries(data)) {
                orphanFieldData.push({
                    label: key,
                    value
                })
            }

            setOrphanFields(orphanFieldData);
            setLoading({
                ...loading,
                orphan: false
            });
        } catch (error) {
            setLoading({
                ...loading,
                orphan: false
            })
            toast.notify("An Error Occured: " + error, { type: "warn" });
        }

    }

    useEffect(() => {
        if (items && items.length > 0 && mappedData.podio_app_id != null) {
            const app = apps.find(app => app.app_id === mappedData.podio_app_id);
            if (app) {
                const defaultValue = {
                    label: app.name.toUpperCase(),
                    value: app.app_id
                };
                setDefaultOptions(defaultValue)
                const fields = app?.fields?.filter(where => where.type === "text");
                setSelectedApp(app);
                setFields(fields);
                getOrphanFields(app.org_id)
            }

        }
    }, [items, mappedData]);

    const handleAppChange = (val) => {
        const value = val?.value ?? "";
        const app = apps?.find(app => app?.app_id === Number(value));
        setSelectedApp(app);
        const fields = app?.fields.filter(where => where.type === "text" || where.type === "email");
        setFields(fields)
        getOrphanFields(app?.org_id)
    }

    const updateMapper = (val) => {
        const copiedMapper = [...mappers];
        const checkIfFieldExist = copiedMapper.find(where => where.podio_field_id === val.podio_field_id);
        if (checkIfFieldExist) {
            const index = copiedMapper.findIndex(where => where.podio_field_id === val.podio_field_id);
            copiedMapper[index] = val;
            setMappers(copiedMapper);
        } else {
            const map = [val];
            const newMapper = copiedMapper.concat(map);
            setMappers(newMapper)
        }

    }

    const handleSubmit = async () => {
        try {
            setSaveLoading(true);
            if (mappers.length === 0) {
                setSaveLoading(false);
                toast.notify("Please map a field", { type: "info" });
                return;
            }
            const payload = {
                mapper: mappers,
                org_id: selectedApp.org_id,
                podio_app_id: selectedApp.app_id
            }
            const url = '/tmail/orphan/mapper/save';
            const res = await axios.post(url, payload);
            changeOrgId(payload.org_id);
            handleClose();
            setSaveLoading(false);
            toast.notify(res.message);
        } catch (error) {
            setSaveLoading(false);
            toast.notify(error.message, { type: "error" });
        }
    }

    const options = apps.length > 0 ? apps.map(app => {
        return {
            label: app.name.toUpperCase(),
            value: app.app_id
        }
    }) : [];

    return (
      <div className='orphan_tab_container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='form-group'>
              <SelectableItem placeholder='Select Podio App' options={options} handleChange={handleAppChange} default={defaultOptions} isMulti={false}/>
            </div>
          </div>
        </div>
        {selectedApp && !loading.orphan ? (
          <div className={styles.wrapper}>
            <div className={styles.contianer}>
              <div>
                <p>App Fields</p>
              </div>
              <div>
                <p>Orphan Fields</p>
              </div>
            </div>

            <div className={styles.body_container}>
              {fields.length > 0 && !loading.orphan
                ? fields.map((field) => (
                    <MapperComponent
                      key={field.field_id}
                      field={field}
                      orphanFields={orphanFields}
                      fieldId={field.field_id}
                      updateMapper={updateMapper}
                      fields={fields}
                    />
                  ))
                : null}
            </div>
            {saveLoading ? (
              <CircularProgress />
            ) : (
              <button
                className='btn btn-default ml-4 mt-4'
                onClick={handleSubmit}
                style={{
                  color: '#fff',
                  background: '#F7C948',
                }}>
                Save
              </button>
            )}
          </div>
        ) : loading.orphan ? (
          <center>
            <CircularProgress />
          </center>
        ) : null}
      </div>
    );
}

export default OrphanEmail