import { Checkbox, FormControl, ListItemText, MenuItem, Select } from '@material-ui/core'
import React from 'react'


const MultiSelect = ({ className, setValues, value, values, list, label }) => {
  const handleChange = (event) => {
    setValues(event.target.value)
  };

  return (
    <FormControl variant="outlined" className={`${className ?? ""}`}>
      <Select
        labelId="demo-simple-select-outlined-label" 
        id="demo-simple-select-outlined"
        multiple 
        value={values} 
        onChange={handleChange} 
        renderValue={(selected) => selected.map((id) => {
          const selected = list.find((item) => item[value] === id);
          return selected ? selected[label] : '';
        }).join(', ')}
      >
        {list?.map((option) => (
          <MenuItem key={option[value]} value={option[value]}>
            <Checkbox checked={values.includes(option[value])} />
            <ListItemText primary={option[label]} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default MultiSelect
