import React from "react";
import infoIcon from "../../assets/icons/info.svg";
import cancel from "../../assets/icons/cancel.svg";
import deleteIcon from "../../assets/icons/delete.svg";
import "./ConfirmModal.css";
import { CircularProgress } from "@mui/material";

const ActionModal = ({
  message,
  title,
  toggleActionModal,
  performAction,
  isLoading,
}) => {
  return (
    <div className="modal_bg">
      <div className="modal_cont">
        <div className="d-flex justify-content-end">
          <img
            onClick={() => toggleActionModal(false, "", null, null)}
            width="7%"
            src={cancel}
            alt=""
            style={{cursor: "pointer"}}
          />
        </div>
        <div className="d-flex justify-content-center mt-3">
          {title.includes("Delete") ? (
            <img width="10%" src={deleteIcon} alt="" />
          ) : (
            <img width="10%" src={infoIcon} alt="" />
          )}
        </div>
        <div style={{ textAlign: "center", marginBottom: "5%" }}>
          <h5 className="mb-3 bold mt-2">{title}</h5>
          <p className="pl-2 pr-2">{message}</p>
          {isLoading ? (
            <div className="mt-3">
              <CircularProgress color="info" size={20} />
            </div>
          ) : (
            <button
              style={
                title.includes("Delete")
                  ? {
                      width: "60%",
                      height: "50px",
                      backgroundColor: "#E12D39",
                      border: "none",
                      marginTop: "5%",
                      color: "white",
                      borderRadius: "4px",
                    }
                  : {
                      width: "60%",
                      height: "50px",
                      backgroundColor: "#f0b429",
                      border: "none",
                      marginTop: "5%",
                      color: "white",
                      borderRadius: "4px",
                    }
              }
              onClick={() => performAction()}
            >
              Yes, Proceed
            </button>
          )}

          {/* <CircularProgress color="info" size={20} /> */}
        </div>
      </div>
    </div>
  );
};

export default ActionModal;
