import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { Icon, ListItemIcon } from '@material-ui/core';
import Logo from 'assets/images/print-logo.png';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useHistory } from 'react-router';
import { connect, useDispatch, useSelector } from 'react-redux';
import { setUser } from 'redux/actions/user';
import { setAuditOrg } from 'redux/actions/orgs';
import { USER_ACCOUNT } from 'containers/pagePath';
import { logout } from 'redux/actions/auth';
import AppSwitch from 'components/Navigation/AppSwitch';
import styles from './Header.module.css';
import ProgressBar from '../../../ProgressBar';

const isTeamMember = localStorage.getItem('team_member_uuid');

const Header = (props) => {
  const user = useSelector((state) => state.user.authUser);
  const userPrintData = useSelector((state) => state.user.print_data);
  const [img] = React.useState(isTeamMember ? 'https://i.stack.imgur.com/l60Hf.png' : user?.podio_profile?.profile?.image);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const printStats = React.useMemo(() => {
    const available_prints = userPrintData?.usage;
    return available_prints;
  }, [userPrintData?.usage]);

  let name = ""
  let email = ""
  if (user?.team_member) {
    name = user?.team_member?.name 
    email = user?.team_member?.email 
  } else {
    name = user?.name
    email = user?.email
  }

  let role = '';
  if (!user.team_member) {
    role = 'Super Admin';
  } else {
    role = user?.team_member?.role_id === 2 ? 'Partner' : user?.team_member?.role_id === 1 ? 'Team Member' : '';
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.progress_container}>
          <ProgressBar isLoading={props.isLoading} />
        </div>

        <header>
          <a href='/print-home'>
            <img src={Logo} alt='logo' />
          </a>

          <ul className={styles.nav_links}>
            {window?.location?.pathname?.includes('/print-data') ? (
              <li className='mr-4'>
                <a href='/print-home'>Dashboard</a>
              </li>
            ) : null}
            {printStats ? (
              <div className={styles.print_stats_container}>
                <li>{printStats?.eligible_prints} Total Print</li>
                <li>{printStats?.total_prints} Total Print Used</li>
              </div>
            ) : null}
          </ul>

          <div className={styles.actions}>
            <div>
              <AppSwitch />
            </div>

            <div className={styles.menu_icon}>
              <div className={styles.user__image__container}>
                <Avatar alt={user?.name ? user.name : user?.podio_profile?.profile?.name} src={img} />
              </div>
              <div className={styles.popup}>
                <div
                  ref={anchorRef}
                  aria-controls={open ? 'menu-list-grow' : undefined}
                  aria-haspopup='true'
                  onClick={handleToggle}
                  style={{ cursor: 'pointer' }}>
                    {name ? name : user?.podio_profile?.profile?.name}
                  <i className='fas fa-angle-down' style={{ position: 'relative', top: '5px', marginLeft: '20px', fontSize: '15px' }}></i>
                  <small className='d-block'>{email}</small>
                  <small className="d-block">{role}</small>
                </div>
                <Popper className='nav__popup' open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                      }}>
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList autoFocusItem={open} id='menu-list-grow' onKeyDown={handleListKeyDown}>
                            <MenuItem
                              onClick={(e) => {
                                history.push(USER_ACCOUNT);
                                handleClose(e);
                              }}>
                              <ListItemIcon className='min-w-40'>
                                <Icon>account_circle</Icon>
                              </ListItemIcon>
                              My account
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                dispatch(logout());
                                history.push('/login');
                              }}>
                              <ListItemIcon className='min-w-40'>
                                <Icon>exit_to_app</Icon>
                              </ListItemIcon>
                              Logout
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            </div>
          </div>
        </header>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.authUser,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
  setAuditOrg: (data) => dispatch(setAuditOrg(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
