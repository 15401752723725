import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Route, Switch } from "react-router";
import Header from "./V2/Header/Header";
import CssBaseline from "@material-ui/core/CssBaseline";
import Sidebar from "./V2/Sidebar/Sidebar";
import AppsList from "./components/Apps";
import NewApp from "./components/NewApp";
import DashboardView from "./components/Dashboard";
import useMediaQuery from "@mui/material/useMediaQuery";

// import Footer from './Footer';
import Domain from "./components/Domain";
import Members from "./components/Members";
import Campaign from "./components/Campaign";
import {
  TMAIL_DASHBOARD,
  TMAIL_DASHBOARD_APPS,
  ADD_APP,
  DOMAINS,
  CAMPAIGN,
  MEMBERS,
} from "../../containers/pagePath";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  hide: {
    display: "none",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    background: "#F7F7F7",
  },
}));

const Tmail = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [openMobile, setOpenMobile] = React.useState(false);
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(!open);
    setOpenMobile(!openMobile);
  };
  
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header baropen={open} handleDrawerOpen={handleDrawerOpen} />
      {isMobile ? (
        <Sidebar open={openMobile} handleDrawerOpen={handleDrawerOpen} />
      ) : (
        <Sidebar open={open} handleDrawerOpen={handleDrawerOpen} />
      )}
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Switch>
          <Route exact path={TMAIL_DASHBOARD} component={DashboardView} />
          <Route exact path={TMAIL_DASHBOARD_APPS} component={AppsList} />
          <Route exact path={ADD_APP} component={NewApp} />
          <Route exact path={DOMAINS} component={Domain} />
          <Route exact path={MEMBERS} component={Members} />
          <Route exact path={CAMPAIGN} component={Campaign} />
        </Switch>
        {/* <Footer /> */}
      </main>
    </div>
  );
};

export default Tmail;
