import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SideBarHandler from "./SideBarHandler";
import Illus from "../../assets/images/Illus.svg";
import grid from "../../assets/icons/grid.svg"
// import gridA from "../../assets/images/gridA.svg";
// import Button from "@material-ui/core/Button";
// import RenderByPermission from "components/RenderByPermission";

const OrganizationNavFunction = ({ open }) => {
  const nav = useSelector(state => state.nav.nav)
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (nav?.children.length) {
      buildNav(nav ? nav?.children : []);
    } else {
      setItems([]);
    }

    buildNav(nav ? nav?.children : []);
  }, [nav]);

  // function handleClick() {
  //   const button =   window.document.getElementById("syncAnOrgButton");
  //   if (button) button.click()
  //   return;
  // }

  function buildNav(data) {
    let nav = data.map((obj, i) => {
      return {
        id: i + 1,
        title: "",
        items: [
          {
            id: obj.id,
            icon: grid,
            name: obj.title,
            link: `/dashboard/workspaces/${obj.id}`,
            submenu: obj.children.map((child, i) => {
              return {
                id: i + 1,
                icon: "",
                name:
                  child.title,
                submenu: child.children.map((item, i) => {
                  const theData = JSON.parse(item.other_data);

                  return {
                    id: i + 1,
                    name: item.title,
                    icon: "",
                    link: `/dashboard/app/${theData.org_id}/${theData.app_id}`,
                  };
                }),
              };
            }),
          },
        ],
      };
    });

    setItems(nav);
  }

  return (
    <div>
      {items.length > 0 ? (
        items.map((item) => {
          return (
            <SideBarHandler
              key={item.id}
              id={item.id}
              pages={item.items}
              type="organization"
            />
          );
        })
      ) : (
          <>
            {open && <div>
              <img
                src={Illus}
                alt="Orgs Illustration"
                style={{ marginTop: "10px", marginLeft: "10px" }}
              />
              <p
                style={{ textAlign: "center", marginTop: "10px", fontSize: "15px" }}
              >
                <b>Start Syncing an organization</b>
              </p>
              <p style={{ textAlign: "center" }}>
                Add an organization to start <br />
                syncing now.
              </p>              
              {/* <RenderByPermission allowTeamMemberAccess={false}>
                <Button
                style={{ marginLeft: "30px", textTransform: "capitalize" }}
                variant="contained"
                color="primary"
                onClick={handleClick}
                >
                  Sync an Organization
                </Button>
              </RenderByPermission> */}
            </div>}
          
        </>
      )}
    </div>
  );
};

export default OrganizationNavFunction;
