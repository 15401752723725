/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Delete, Sync, Chat } from "@material-ui/icons";
import { GiSpanner } from "react-icons/gi";
import styled from "@emotion/styled";
import { deleteConnectedApp, resyncApp } from "../../../redux/actions/tmail";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import "./style.css";
import { COMMENT_MODERATE } from "../../../containers/pagePath";
import "./style.css";
import { useHistory } from "react-router";
import AcceptanceModal from "./utility/AcceptanceModal";

const IconBox = styled.div`
  border: 1px solid #ccc;
  margin: 0 5px;
  border-radius: 6px;
  margin-top: 10px;
  padding: 3px;
  color: gray;
  :hover {
    color: ${(props) => props.color};

    & span {
      display: block;
    }
  }
  & span {
    display: none;
  }
`;

const IconList = ({ id, orgId, appId }) => {
  const dispatch = useDispatch();
  const [iconDescription, setIconDescription] = useState("");
  const [openSyncModal, setOpenSyncModal] = useState(false)
  const history = useHistory();

  const closeResyncModal = () => setOpenSyncModal(false)
  const onIconHover = (text) => setIconDescription(text);

  const resyncHandler = () => {

    setOpenSyncModal(true)
  }

  const deleteApp = (id) => {
    const payload = {
      id,
    };
    Swal.fire({
      title: "Remove App",
      text: "Are you sure, You want to remove app",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Remove App",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.value) {
        dispatch(deleteConnectedApp(payload));
      }
    });
  };
  const resyncConectedApp = () => {
    const payload = {
      id,
      org_id: orgId,
      app_id: appId
    }
    dispatch(resyncApp(payload))
  }
  return (
    <div className="wrapper" onMouseLeave={() => setIconDescription("")}>
      <div className="text-center" style={{ height: "15px" }}>
        {iconDescription}
      </div>
      <AcceptanceModal
        openModal={openSyncModal}
        handleClose={closeResyncModal}
        message="Would You like to resync connected app"
        handleAccept={resyncConectedApp}
      />
      <div className="iconWrapper">
        <IconBox
          color="green"
          onClick={() => {
            history.push(`/dashboard/tmail/apps/setting/${id}`);
          }}
          onMouseOver={() => onIconHover("Configuration Settings")}
        >
          <GiSpanner className="text-grey" style={{ width: "20px", height: "20px" }} />
        </IconBox>

        <IconBox
          color="blue"
          onClick={() => {
            history.push(`/dashboard/tmail/comment-moderate/${id}`);
         
          }}
          onMouseOver={() => onIconHover("Moderate Email Comments")}
        >
          <Chat className="text-grey" />
        </IconBox>
        <IconBox color="brown" onMouseOver={() => onIconHover("Reload all Podio items")}>
          <Sync className="text-grey" onClick={resyncHandler} />
        </IconBox>
        <IconBox color="red" onMouseOver={() => onIconHover("Delete")}>
          <Delete className="text-grey" onClick={() => deleteApp(id)} />
        </IconBox>
      </div>
    </div>
  );
};

export default IconList;
