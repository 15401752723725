import * as user from "../constants/user";

export const setUser = (data) => ({
  type: user.SET_USER,
  payload: data,
});
export const getMe = () => ({
  type: user.GET_ME.request,
});

export const sendInvite = (payload) => ({
  type: user.SEND_INVITE.request,
  payload,
});
export const deleteInvite = (payload) => ({
  type: user.DELETE_INVITE.request,
  payload,
});
export const getTeamMembers = () => ({
  type: user.GET_TEAM_MEMBERS.request,
});
export const getPendingInvitation = () => ({
  type: user.GET_PENDING_INVITATION.request,
});
export const deletePodio = () => ({
  type: user.DELETE_PODIO.request,
});
