import React from 'react'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Typography
} from "@material-ui/core";

const OrphanFieldsModal = ({ open, handleClose, data }) => {
    const formatMessage = message => {
        return <div dangerouslySetInnerHTML={{ __html: message }} />;
    }
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                disableEscapeKeyDown={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <Typography variant='h5' style={{marginLeft: "1rem", fontWeight: "600", color: "#3e2121"}}>
                        Details
                    </Typography>
                    <Box style={{ padding: "20px", width: "500px" }}>
                        <p>{formatMessage(data)}</p>
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button id="button-cancel" className="mr-4 " onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>

            </Dialog>
        </div>
    )
}

export default OrphanFieldsModal