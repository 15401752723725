import * as actionTypes from '../constants/connector';
import axios from '../../config';
// import Swal from "sweetalert2";
import { toast } from "helpers/apiRequests"

const TOKEN = localStorage.getItem("chosen_token");

export const setError = () => {
    return {
        type: actionTypes.ERROR
    }
}

export const getSync = (payload) => {
    return {
        type: actionTypes.GET_SQL_SYNC,
        payload
    }

}

export const clearCurrent = () => {
    return {
        type: actionTypes.CLEAR_CURRENT
    }

}

export const addDD = (payload) => {
    return {
        type: actionTypes.ADD_DATABASE,
        payload
    }

}

export const updateSyncStatus = (payload) => {
    return {
        type: actionTypes.UPDATE_SQL_SYNC,
        payload
    }

}

export const deleteSyncStatus = (payload) => {
    return {
        type: actionTypes.DELETE_SQL_SYNC,
        payload
    }

}

export const getDBs = (payload) => {
    return {
        type: actionTypes.GET_DATABASE,
        payload
    }
}

export const getWorkflow = (payload) => {
    return {
        type: actionTypes.GET_WORKFLOW,
        payload
    }
}

export const deleteDB = (payload) => {
    return {
        type: actionTypes.DELETE_DATABASE,
        payload
    }
}

export const GetAllDatabases = () => {
    return async dispatch => {
        try {
            const url = `/list-user-db`;
            const res = await axios.post(url);
            dispatch(getDBs(res.data))
        } catch (error) {
            dispatch(setError());
            toast.notify(error.response.message, { type: "error" });
        }
    }
}

export const GetWorkflow = (org_id) => {
    return async dispatch => {
        try {
            const url = `/workflows/list?org_id=${org_id}`;
            const res = await axios.get(url);
            dispatch(getWorkflow(res.data))
        } catch (error) {
            dispatch(setError());
            toast.notify(error.response.message, { type: "error" });
        }
    }
}

export const getAllSyncStatus = () => {
    return async dispatch => {
        try {
            const url = `/sync-status`;
            const res = await axios.get(url);
            const payload = {
                mysql_status: res.data.mysql_status,
                pgsql_status: res.data.pgsql_status,
            }
            dispatch(getSync(payload))
        } catch (error) {
            dispatch(setError());
            toast.notify(error.response.message, { type: "error" });
        }
    }
}

export const CreateDatabase = (payload, saveLoading, handlResponseChange) => {
    return async dispatch => {
        try {
            const url = `/create-user-db?token=${TOKEN}`;
            const res = await axios.post(url, payload);
            handlResponseChange(res.data)
            dispatch(addDD(res.data));
            saveLoading()
            toast.notify(res.message);
        } catch (error) {
            dispatch(setError());
            saveLoading()
            toast.notify(error.response.message, { type: "error" });
        }
    }
}

export const CreateExternalDatabase = (payload, saveLoading, handlResponseChange) => {
    return async dispatch => {
        try {
            const url = `/create-user-db/external`;
            const res = await axios.post(url, payload);
            handlResponseChange(res.data);
            dispatch(addDD(res.data));
            saveLoading()
            toast.notify(res.message);
        } catch (error) {
            dispatch(setError());
            saveLoading()
            toast.notify(error.response.message, { type: "error" });
        }
    }
}

export const DeleteDatabase = (payload, saveLoading, selectedDriver) => {
    return async dispatch => {
        try {
            const url = `/delete-user-db`;
            await axios.post(url, payload);
            saveLoading();
            dispatch(deleteDB(selectedDriver));
            toast.notify(`Database deleted successfully`);
        } catch (error) {
            console.log(error);
            dispatch(setError());
            saveLoading()
            toast.notify(error.message, { type: "error" });
        }
    }
}
