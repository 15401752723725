import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Icon, ListItemIcon } from "@material-ui/core";
import clsx from "clsx";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { useHistory } from "react-router";
import  "./dhead.css";
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import { connect, useDispatch } from "react-redux";
import { setUser } from "../../../../redux/actions/user";
import { setAuditOrg } from "../../../../redux/actions/orgs";
import {
  USER_ACCOUNT,
  PLANS,
  MY_INVOICES,
} from "../../../../containers/pagePath";
import { logout } from '../../../../redux/actions/auth';
import AppSwitch from '../../../Navigation/AppSwitch';
import Tooltip from "@material-ui/core/Tooltip";
import Tmail from '../../../../assets/images/tmailTrans2.png';
import RenderByPermission from "components/RenderByPermission";

const drawerWidth = 0;
const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

const Header = ({ baropen, handleDrawerOpen, setUser, user, setAuditOrg }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [img] = React.useState(user?.podio_profile?.profile?.image?.thumbnail_link);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <div className="header">
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: baropen,
        })}
      >
        <Toolbar>


          <span style={{ marginLeft: "0px" }}>
              <img src={Tmail} alt="logo" width={100} />
              
          </span>
          <IconButton
            color="black"
            style={{ marginLeft: "100px" }}
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            
          >
            <Tooltip title="Menu icon">
              <MenuIcon />
            </Tooltip>
          </IconButton>



          <div className="user__nav d-flex justify-between w-full">
            <div style={{ margin: 'auto', marginTop: '7px' }}>
              {/* <AppSwitch/>  */}
            </div>

            <div className="user">
              <div style={{ marginRight: "20px" }}>
                <AppSwitch />
              </div>
              <div className="user__image__container">
                {img ? (
                  <img
                    alt="Podio Profile"
                    src={img}
                    style={{ width: "100%" }}
                  />
                ) : (
                  ""
                )}
              </div>
              <div style={{ marginRight: "20px" }}>
                <div
                  ref={anchorRef}
                  aria-controls={open ? "menu-list-grow" : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                  style={{ cursor: "pointer" }}
                >
                  <i
                    className="fas fa-angle-down"
                    style={{ position: "relative", top: "1px" }}
                  ></i>
                </div>
                <Popper
                  className="nav__popup"
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList
                            autoFocusItem={open}
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                          >
                            <MenuItem
                            >
                              {user?.name ? user.name : user?.podio_profile?.profile?.name}
                            </MenuItem>
                            <RenderByPermission>
                              <MenuItem
                                onClick={(e) => {
                                  history.push(USER_ACCOUNT);
                                  handleClose(e);
                                }}
                                >
                                <ListItemIcon className="min-w-40">
                                  <Icon>account_circle</Icon>
                                </ListItemIcon>
                                My account
                              </MenuItem>
                            </RenderByPermission>
                            <RenderByPermission allowTeamMemberAccess={false}>
                              <MenuItem
                                onClick={(e) => {
                                  history.push(MY_INVOICES);
                                  handleClose(e);
                                }}
                              >
                                <ListItemIcon className="min-w-40">
                                  <LibraryBooksIcon />
                                </ListItemIcon>
                                My Invoices
                              </MenuItem>
                            </RenderByPermission> 
                            <RenderByPermission allowTeamMemberAccess={false}>
                              <MenuItem
                                onClick={(e) => {
                                  history.push(PLANS);
                                  handleClose(e);
                                }}
                              >
                                <ListItemIcon className="min-w-40">
                                  <Icon>money</Icon>
                                </ListItemIcon>
                                Plans
                              </MenuItem>
                            </RenderByPermission>                           
                            <MenuItem
                              onClick={() => {
                                dispatch(logout());
                                history.push("/login")
                              }}
                            >
                              <ListItemIcon className="min-w-40">
                                <Icon>exit_to_app</Icon>
                              </ListItemIcon>
                              Logout
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};


const mapStateToProps = (state) => ({
  user: state.user.authUser,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
  setAuditOrg: (data) => dispatch(setAuditOrg(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);

