import React from 'react';
import PrintDashboardTemplate from '../PrintDashboardTemplate';
import AddTemplate from './AddTemplate';
import styles from './AddTemplate.module.scss';

const CreateTemplate = () => {
  return (
    <PrintDashboardTemplate>
      <div className='header-dashboard flex pad'>
        <h1 className={styles.template_header}>New Template</h1>
        <button className={styles.add_template_btn} onClick={() => window.open('print-home', '_self')}>
          Cancel
        </button>
      </div>

      <div className={styles.form_container}>
        <AddTemplate />
      </div>
    </PrintDashboardTemplate>
  );
};

export default CreateTemplate;
