import React from 'react';
import { Buffer } from 'buffer';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { extractLabelVariablePairs } from '../util';
import styles from './createList.module.scss';
import { ThemedReactSelect } from 'components/templates/print-v2/components/ThemedReactSelect';
import ThemedRadio from 'components/templates/print-v2/components/ThemedRadio';

const CreateList = ({ setCurrentView, dataToInsert, setDataToInsert }) => {
  const [formValues, setFormValues] = React.useState({
    variables: [],
    listType: 'ul',
    delimeter: ',',
    emptyValue: 'List is empty',
  });

  const variableOptions = React.useMemo(() => {
    return extractLabelVariablePairs(dataToInsert?.data);
  }, [dataToInsert]);

  function generateList() {
    const variableGroup = dataToInsert.data.variable;
    const type = formValues.listType;
    const emptyValue = formValues.emptyValue;
    const variable = formValues.variables;
    const delimeter = formValues.delimeter;
    const variables = [];

    variable.forEach((v) => variables.push(v));

    let liText = '';
    let name = '';
    variables.forEach((variable, index) => {
      if (variable.value.includes('convert_to_html')) {
        const match = /convert_to_html\((.*?)->(.*?)\)/.exec(variable.value);
        if (match) {
          name = match[1];
        }
      }

      const varName = /^\$\w+/g.exec(variable.value);
      if (varName) {
        name = varName[0];
      }
      const del = variables.length === index + 1 ? '' : delimeter;
      if (variable.type === 'linked_image') {
        liText += variable.value + del;
      } else {
        liText += '{!!' + variable.value + '!!}' + del;
      }
    });

    let uuid = window.crypto.randomUUID();
    const loopVariables = ` @if(isset(${variableGroup}) && !empty(${variableGroup})) @foreach(${variableGroup} as ${name}) loop_foreach_variable_${uuid} @endforeach @else loop_else_variable_${uuid} @endif `;
    let encoded = Buffer.from(loopVariables).toString('base64');

    let html = `<div class="dynamic_list variables-items ck_editor_hidden_operator" data-ck_hidden_operator=${encoded}>\n`;
    html += `<${type}>\n`;
    html += `\n <li class="loop_foreach_variable_${uuid}">${liText}</li>\n`;
    html += `\n <li class="loop_else_variable_${uuid}">${emptyValue}</li>\n`;
    html += `\n </${type}>\n`;
    html += `\n </div> <br>`;

    if (window.CKEDITOR) {
      const editor = window.CKEDITOR;
      const insertPosition = editor.model.document.selection.getFirstPosition();

      const viewFragment = editor.data.processor.toView(html);
      const modelFragment = editor.data.toModel(viewFragment);

      editor.model.insertContent(modelFragment, insertPosition);

      // hack to prevent variable being pasted multiple times witn enter btn is pressed
      document.activeElement.blur();
    }
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((previousValues) => ({ ...previousValues, [name]: value }));
  };

  function handleVariableChange(val) {
    setFormValues((previousValues) => ({ ...previousValues, variables: val }));
  }

  return (
    <div>
      <button
        onClick={() => {
          setDataToInsert(undefined);
          setCurrentView('variables');
        }}
        className={styles.back_button}>
        <i className='fa fa-arrow-left'></i>
        Go Back
      </button>
      <p className={styles.create_list_title}>Insert list into document</p>

      {dataToInsert ? (
        <>
          <RadioGroup
            aria-label='variables-list-type'
            name='listType'
            value={formValues.listType}
            onChange={handleChange}
            className={`${styles.radio_button_container} mb-3`}>
            <FormControlLabel value='ul' control={<ThemedRadio size='small' />} label='Bulleted list' />
            <FormControlLabel value='ol' control={<ThemedRadio size='small' />} label='Numbered list' />
          </RadioGroup>

          <fieldset className='d-flex flex-column mb-3'>
            <label htmlFor='variables' className={`${styles.label} mb-2`}>
              Variables
            </label>
            <ThemedReactSelect isMulti name='variables' options={variableOptions} onChange={handleVariableChange} style={{ fontSize: '14px' }} />
          </fieldset>

          <fieldset className='d-flex flex-column mb-3'>
            <label htmlFor='delimeter' className={`${styles.label} mb-2`}>
              Variable delimeter
            </label>
            <input name='delimeter' id='delimeter' value={formValues.delimeter} onChange={handleChange} />
            <p className={styles.helper_text}>A delimiter is a sequence of one or more characters used to specify the boundary between separate.</p>
          </fieldset>

          <fieldset className='d-flex flex-column'>
            <label htmlFor='variables-list-empty' className={`${styles.label}`}>
              Empty text
            </label>
            <input name='emptyValue' id='emptyValue' value={formValues.emptyValue} onChange={handleChange} />
            <p className={styles.helper_text}>This text will show if the list is empty.</p>
          </fieldset>

          <button className={`${styles.change_button} btn-success`} onClick={generateList}>
            Insert to document
          </button>
        </>
      ) : null}
    </div>
  );
};

export default CreateList;
