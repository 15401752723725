import { Radio } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import React from 'react';

const ThemedRadio = withStyles({
  root: {
    color: 'grey',
    '&$checked': {
      color: 'grey',
    },
  },
  checked: {},
})((props) => <Radio color='default' {...props} />);

export default ThemedRadio;
