import React, { useState } from "react";
import { Dialog, DialogContent, Box } from "@material-ui/core";
import cancelIcon from "../../../../assets/icons/cancel.svg";

const ViewDefaultEmail = ({ openModal, handleClose, item }) => {
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={handleClose}
        // hideBackdrop={true}
        disableBackdropClick={false}
        disableEscapeKeyDown={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth="500px"
      >
        <DialogContent>
          <Box>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-4">
              <h5>View Email Details</h5>
              <img onClick={handleClose} src={cancelIcon} alt="" />
            </div>

            {item ? (
              <table className="table table-bordered mt-4">
                <tr>
                  <td>Userame</td>
                  <td>{item.username}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>{item.email}</td>
                </tr>
                <tr>
                  <td>Smtp Status</td>
                  <td>{item.create_with_smtp === 1 ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>{item.status}</td>
                </tr>
                <tr>
                  <td>Domain</td>
                  <td>{item.domain.name}</td>
                </tr>

                <tr>
                  <td>Password</td>
                  <td>
                    {show ? item.password : "******"}
                    <i
                      className="fas fa-eye ml-3"
                      style={{
                        cursor: "pointer",
                        fontSize: "13px",
                        color: "#F7C948",
                      }}
                      onClick={toggleShow}
                    ></i>
                  </td>
                </tr>
              </table>
            ) : null}
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ViewDefaultEmail;
