import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  CircularProgress
} from "@material-ui/core";

import { deleteConnectedDomain } from "../../../../redux/actions/tmail"

const DeleteDomain = ({ openModal, handleClose, item }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const stopLoading = () => {
    setLoading(false)
    handleClose()
  };

  const deleteDomainConfig = (id) => {
    setLoading(true)
    const payload = {
      id
    }
    dispatch(deleteConnectedDomain(payload, stopLoading));
  }


  return (
    <div>

      <Dialog
        open={openModal}
        onClose={handleClose}
        // hideBackdrop={true}
        disableBackdropClick={false}
        disableEscapeKeyDown={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="connector-modal"
      >
        <DialogContent>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography gutterBottom>
                You are about to delete your Domain connection.<br />
                Would you like to proceed?
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button id="button-cancel" disabled={loading} onClick={handleClose} color="primary">
              No
            </Button>
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              <Button
                id="button-save"
                onClick={() => {
                  deleteDomainConfig(item)
                }}
                color="primary"
                autoFocus
              >
                Yes
              </Button>
            )}
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DeleteDomain;
