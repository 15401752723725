/* eslint-disable no-unused-vars */
import React, { useState, useCallback } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import { AgGridReact } from "ag-grid-react";
import Endpoint from "../../UserInfo/Endpoint";
import { makeStyles } from "@material-ui/core";

// import * as AllModules from "ag-grid-enterprise";
// import { ModuleRegistry } from "ag-grid-enterprise";
import ImitateAction from "./ImitateAction";
import BackButton from "components/atoms/BackButton";

// ModuleRegistry.registerModules(Object.keys(AllModules));

const useStyles = makeStyles((theme) => ({
  backButton: {
    backgroundColor: "#f7c948",
    boxShadow: "0px 3px 6px #00000029",
    padding: "1vh 1vw",
    outline: "none",
    textDecoration: "none",
    border: "none",
    borderRadius: "6px",
    color: "white",
    marginBottom: "1%",
  },
}));

const BackupProgress = (props) => {
  const classes = useStyles();
  const filterParams = {
    filterOptions: ["contains", "notContains"],
    debounceMs: 0,
    caseSensitive: false,
    suppressAndOrCondition: true,
  };

  function formatTime(millis) {
    let hours = Math.floor(millis / 3600000);
    let minutes = Math.floor((millis % 3600000) / 60000);
    let seconds = Math.floor((millis % 60000) / 1000).toFixed(0);

    hours = hours.toString().padStart(2, "0");
    minutes = minutes.toString().padStart(2, "0");
    seconds = seconds.toString().padStart(2, "0");

    return hours + ":" + minutes + ":" + seconds;
  }

  const valFormat = (p) => formatTime(p.value);

  const [users] = useState({
    columns: [
      {
        headerName: "Org Id",
        field: "org_id",
        filter: "agTextColumnFilter",
        filterParams,
      },
      {
        headerName: "App Id",
        field: "app_id",
        filter: "agTextColumnFilter",
        filterParams,
      },
      {
        headerName: "Driver",
        field: "driver",
        filter: "agTextColumnFilter",
        filterParams,
      },
      {
        headerName: "Sync Items",
        field: "synced_items",
      },
      {
        headerName: "Status",
        field: "status",
        filter: "agTextColumnFilter",
        filterParams,
      },
      {
        headerName: "Started At",
        field: "started_at",
      },
      {
        headerName: "End At",
        field: "ended_at",
      },
      {
        headerName: "Duration",
        field: "duration",
        valueFormatter: valFormat,
      },
      {
        headerName: "Message",
        field: "message",
      },
      {
        headerName: "Action",
        field: "actions",
        cellRenderer: "imitateAction",
      },
    ],
    data: [],
  });

  const defaultColDef = {
    flex: 1,
    minWidth: 100,
    filter: true,
    sortable: true,
    resizable: true,
  };
  const autoGroupColumnDef = { minWidth: 200 };
  const frameworks = {
    imitateAction: ImitateAction,
    // imitateAction: null
  };
  const paginationPageSize = 30;
  const cacheBlockSize = 30;
  const rowModelType = "serverSide";

  const ServerSideDatasource = () => {
    return {
      getRows: function (params) {
        let query = "";
        if (Object.entries(params.request.filterModel).length > 0) {
          let search_key = Object.keys(params.request.filterModel).length;
          if (search_key === 1) {
            search_key = Object.keys(params.request.filterModel)[0];
          } else {
            search_key = Object.keys(params.request.filterModel)[
              Object.keys(params.request.filterModel).length - 1
            ];
          }
          const filterData = params.request.filterModel[search_key];
          query = `${query}&${search_key}=${filterData.filter}`;
        }

        if (params.request.sortModel.length > 0) {
          query = `${query}&sort=${params.request.sortModel[0].sort}&sort_field=${params.request.sortModel[0].colId}`;
        }
        Endpoint.getBackupData(
          params.request.startRow,
          paginationPageSize,
          query
        )
          .then((response) => {
            const data = response.data.data.data;

            // console.log("[DATA", data);
            setTimeout(function () {
              params.successCallback(data, response.data.data.total);
            }, 200);
          })
          .catch((err) => {
            params.failCallback();
          });
      },
    };
  };

  const onGridReady = (params) => {
    const theData = ServerSideDatasource();
    params.api.setServerSideDatasource(theData);
  };
  const handleBackButtonClick = () => {
    window.history.back();
  };

  return (
    <div className="p-24 pb-5">
      <div>
        <BackButton />
      </div>
      <div className="d-flex justify-content-between align-items-center mt-4">
        <div className="d-flex ">
          <h4 style={{ fontWeight: `bold`, marginBottom: `15px` }}>
            Sync Progress
          </h4>
        </div>
        <div className="d-flex">
          {/* <Link to="/dashboard/podio-partners">
            <button className="sync__btn2" >Podio Partners</button>
          </Link> */}
        </div>
      </div>
      <div
        className="ag-theme-balham-dark"
        style={{
          height: "700px",
          width: "100%",
          // padding: '20px',
        }}
      >
        <AgGridReact
          // modules={Object.keys(AllModules)}
          columnDefs={users.columns}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          rowModelType={rowModelType}
          frameworkComponents={frameworks}
          enableRangeSelection={true}
          animateRows={true}
          // rowData={users.data}
          onGridReady={onGridReady}
          rowGroupPanelShow="always"
          rowSelection="multiple"
          pagination={true}
          paginationPageSize={paginationPageSize}
          cacheBlockSize={cacheBlockSize}
          debug={true}
          sideBar={true}
          enableCellTextSelection={true}
          suppressAggFuncInHeader={true}
          enableFilter={true}
          enableSorting={true}
          serverSideStoreType="partial"
        >
          {/* <AgGridColumn
            className={"default-class"}
            field="name"
            filter={filterParams}
          /> */}
        </AgGridReact>
      </div>
    </div>
  );
};

export default BackupProgress;
