import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { toast } from "helpers/apiRequests";
import { getMe } from "../../services/auth";
import Summary from "./Summary";
import SyncTable from "../../components/Table/SyncTable/SyncTable";
import MyDialog from "../../components/syncModal/sync";
import "./Sync.css";
import Backdrop from "../../components/Loader/Backdrop";
import { useDispatch } from "react-redux";
import {
  deleteOrganization,
  getSyncedOrganizations,
  syncOrganization,
  getOrganization,
  setFrequencyData,
  setOrgUpdate,
} from "../../services/sync";
import { syningcOrg } from "../../services/trysync";
import { connect } from "react-redux";
import { setOrgs } from "../../redux/actions/sync";
import { setUser } from "../../redux/actions/user";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import { getNavigation } from "../../services/common";
import { setNav } from "../../redux/actions/sidebar";
import * as actions from "../../redux/actions";
import GDPRModal from "../../components/ConfirmModal/GDPRModal";
import OrgUpdateModal from "../../components/ConfirmModal/OrgUpdateModal";
import { useSyncLoadingContext } from "context/LoadingContext/SyncLoadingContext";
import { useSelector } from "react-redux";
const Sync = ({
  onFetchAllUnSyncedOrganizations,
  onGetSynced,
  onGetUnsynced,
  orgs,
  setOrgs,
  spaceCount,
  appCount,
  itemsCount,
  setNav,
  user,
  flowCount,
  workflowCount,
}) => {
  React.useEffect(() => {
    if (user && user.podio_id === null) {
      window.location = "/connect";
    }
  }, [user]);

  const dispatch = useDispatch();
  const [options, setOption] = useState([]);
  const [loading, setLoading] = useState(false);
  const [gprLoading, seGprtLoading] = useState(false);
  const [org, setOrg] = useState(null);
  const [value, setValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState(false);
  const authToken = localStorage.getItem("chosen_token");
  const [isModal, setModal] = useState({
    value: false,
    message: "",
    id: null,
    name: null,
  });
  const [gdpr_modal, setGdprModal] = useState({ org_id: null, value: false });
  const [org_modal, setOrgUpdateModal] = useState({
    org_id: null,
    org_name: null,
    value: false,
  });

  const INTERVAL_KEY = "IntervalId";
  const ORGS_KEY = "selectedOrgs";

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getUser = useCallback(async () => {
    const response = await getMe();

    dispatch(setUser(response.data));
  });

  const state = useSelector((state) => state);
  const getNav = async () => {
    const response = await getNavigation();

    dispatch(setNav(response.menus));
  };

  React.useEffect(() => {
    if (state?.sync?.orgs?.length > 0) {
      getNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.sync?.orgs]);

  const getOrgs = async () => {
    try {
      const response = await getSyncedOrganizations();

      await dispatch(setOrgs(response.data));
      await getUser();
      // await getNav();

      // await dispatch(setAuditOrg(newResponse.data));
      //Dispatch synced and unsynced
      // const authToken = localStorage.getItem("chosen_token");

      // dispatch(actions.getAllUnSyncedAccounts(authToken));

      if (response) {
        return history.push("/dashboard/sync");
      }
    } catch (error) {
      // dispatch(logout());
    }
  };

  React.useEffect(() => {
    if (!orgs) {
      getOrgs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgs]);

  const handleClickOpen = () => {
    setOption(onGetUnsynced.map((item) => item.name));
    setValue("");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSync = async () => {
    if (!org) return false;
    handleClose();
    setLoading(true);

    const payload = {
      org_name: org.name,
      org_id: org.org_id,
      action: "sync",
    };

    const response = await syningcOrg(authToken, payload);
    // const response = await syningcOrg(authToken, org.name, org.org_id);

    // console.log(response);

    if (response.status === true && response.message) {
      await startStuff();
      setLoading(response.isLoading);
      fetchOrgs();
      toast.notify(response.message);
    } else {
      setLoading(false);
      toast.notify(response.status.data.message, { type: "error" });
      // history.push("/dashboard/account?section=plans&redirect=/dashboard/sync");
    }
  };

  const startStuff = async () => {
    const intervalId = setInterval(function startSyncing() {
      checkIfOrgIsDoneSyncing();
      getSyncedOrganizations().then((res) => {
        setOrgs(res.data);
      });
      getNavigation().then((response) => {
        setNav(response.menus);
      });
    }, 10000);

    localStorage.setItem(INTERVAL_KEY, intervalId);
  };

  async function checkIfOrgIsDoneSyncing() {
    // if (!currentSyncOrgId) return false;
    // I used local storage here because the setstate was not working
    const currentOrg = JSON.parse(localStorage.getItem(ORGS_KEY));

    if (currentOrg === null) {
      clearInterval(localStorage.getItem(INTERVAL_KEY));
      localStorage.removeItem(ORGS_KEY);
      localStorage.removeItem(INTERVAL_KEY);
      return "";
    }

    if (!currentOrg.org_id) return false;

    try {
      let response = await getOrganization(currentOrg.org_id);
      if (response.data) {
        const status = response?.data?.status;
        if (status === "Done" || status === "N'SYNC") {
          clearInterval(localStorage.getItem(INTERVAL_KEY));
          localStorage.removeItem(ORGS_KEY);
          localStorage.removeItem(INTERVAL_KEY);
        }
      }
    } catch (err) {
      console.log("Error", err);
    }
  }
  const { setIsLoading } = useSyncLoadingContext();

  const fetchOrgs = useCallback(async () => {
    onFetchAllUnSyncedOrganizations(authToken);
    try {
      setIsLoading(true);
      let res = await getSyncedOrganizations();
      setOrgs(res.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }

    // eslint-disable-next-line
  }, [setOrgs, authToken]);

  useEffect(() => {
    fetchOrgs();
  }, [fetchOrgs]);

  const toggleModal = (value, message, id, name) =>
    setModal({ value, message, id, name });

  const gdprModal = (org, value) => setGdprModal({ org_id: org, value: value });

  const submitFrequency = (org_id, value) => {
    // console.log("[HERE 1]");
    seGprtLoading(true);
    setFrequencyData(`?organisation_id=${org_id}&frequency=${value}`)
      .then(async () => {
        // console.log("[HERE 2]");
        seGprtLoading(false);
        toast.notify("Frequency was saved successfully");
        setGdprModal({ org_id: null, value: false });
        let res = await getSyncedOrganizations();

        setOrgs(res.data);
      })
      .catch(() => {
        // console.log("[HERE 3]");
        seGprtLoading(false);
        toast.notify("An error occurred while trying to save the frequency.", {
          type: "error",
        });
        setGdprModal({ org_id: null, value: false });
      });
    // console.log("[HERE 4]");
    seGprtLoading(true);
  };

  const orgUpdateModal = (org_id, org_name, value) =>
    setOrgUpdateModal({ org_id: org_id, org_name: org_name, value: value });

  const submitOrgUpdate = (org_id, value) => {
    setOrgUpdate(`?org_name=${value}&org_id=${org_id}`)
      .then((r) => {
        getSyncedOrganizations().then((res) => {
          setOrgs(res.data);
        });
        toast.notify(r.message);
        setOrgUpdateModal({ org_id: null, org_name: null, value: false });
      })
      .catch(() => {
        toast.notify("An error occurred while trying to update organisation.", {
          type: "error",
        });
        setOrgUpdateModal({ org_id: null, org_name: null, value: false });
      });
  };

  const updateNavigation = async () => {
    let response = await getNavigation();

    setNav(response.menus);
  };

  const syncOrg = async () => {
    toggleModal(null);
    let query = `?org_id=${isModal.id}&org_name=${isModal.name}`;

    try {
      let response = await syncOrganization(query);

      if (response) {
        await startStuff();
        toast.notify("Your organization is syncing");
        await updateNavigation();
      }

      fetchOrgs();
      toggleModal(null);
    } catch (x) {
      toggleModal(null);
    }
  };

  const deleteOrg = async () => {
    toggleModal(null);
    let query = `?org_id=${isModal.id}`;

    try {
      let response = await deleteOrganization(query);

      if (response) {
        fetchOrgs();
        toast.notify("Your organization has been deleted");
        updateNavigation();
      }

      fetchOrgs();
    } catch (err) {
      toggleModal(null);
    }
  };

  // alert(orgs.length)
  const history = useHistory();

  // const handleUserSubscription = () => {

  // }

  return (
    <div className="sync__layout">
      <Backdrop loading={loading} />
      <div style={{ width: "100px" }}>
        <MyDialog
          open={open}
          Close={handleClose}
          handleClose={handleClose}
          click={() => handleSync()}
          style={{ maxWidth: "100px" }}
        >
          <Autocomplete
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
              setOrg(
                onGetUnsynced.find((element) => element.name === newValue)
              );
              // I used local storage here because the setstate was not working

              localStorage.setItem(
                ORGS_KEY,
                JSON.stringify(
                  onGetUnsynced.find((element) => element.name === newValue)
                )
              );
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            id="controllable-states-demo"
            options={options}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                // label="Sync your organization"
                variant="outlined"
              />
            )}
          />
        </MyDialog>
      </div>
      {isModal.value ? (
        <ConfirmModal
          message={isModal.message}
          toggleModal={toggleModal}
          syncOrg={syncOrg}
          deleteOrg={deleteOrg}
        />
      ) : (
        ""
      )}
      {gdpr_modal.value && (
        <GDPRModal
          gdprModal={gdprModal}
          action={submitFrequency}
          the_data={gdpr_modal}
          loading={gprLoading}
        />
      )}
      {org_modal.value && (
        <OrgUpdateModal
          setOrgUpdateModal={orgUpdateModal}
          action={submitOrgUpdate}
          the_data={org_modal}
        />
      )}
      <Summary
        click={handleClickOpen}
        synced={onGetSynced}
        orgs={orgs}
        spaceCount={spaceCount}
        appCount={appCount}
        itemsCount={itemsCount}
        flowCount={flowCount}
        workflowCount={workflowCount}
      />

      <SyncTable
        user={user}
        orgs={orgs}
        toggleModal={toggleModal}
        gdprModal={gdprModal}
        orgUpdateModal={orgUpdateModal}
        flowCount={flowCount}
        workflowCount={workflowCount}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.authUser,
  loading: state.syncing.loading,
  onGetSynced: state.getALLsyncedOrgs.data ? state.getALLsyncedOrgs.data : [],
  onGetUnsynced: state.getALLUnsyncedOrgs.data
    ? state.getALLUnsyncedOrgs.data
    : [],
  syncing: state.syncing.data,
  syncingError: state.syncing.error,
  db_name: state.user.authUser.database
    ? state.user.authUser.database.database
    : null,
  orgs: state.sync.orgs,
  synced: state.sync.orgs ? state.sync.orgs.filter((item) => item.details) : [],
  unSyncedAccounts: state.sync.orgs
    ? state.sync.orgs.filter((item) => item.sync === 0)
    : [],
  spaceCount: state.sync.orgs
    ? state.sync.orgs.reduce((total, acc) => {
        if (acc.details) {
          if (acc.details.mongo_summary)
            return total + acc.details.mongo_summary.count.spaces;
        }

        return total;
      }, 0)
    : [],
  appCount: state.sync.orgs
    ? state.sync.orgs.reduce((total, acc) => {
        if (acc.details) {
          if (acc.details.mongo_summary)
            return total + acc.details.mongo_summary.count.apps;
        }

        return total;
      }, 0)
    : [],
  itemsCount: state.sync.orgs
    ? state.sync.orgs.reduce((total, acc) => {
        if (acc.details) {
          if (acc.details.mongo_summary)
            return total + acc.details.mongo_summary.count.items;
        }

        return total;
      }, 0)
    : [],
    flowCount: state.sync.orgs
    ? state.sync.orgs.reduce((total, acc) => {
        if (acc.details) {
          if (acc.details.mongo_summary)
            return total + acc.details.mongo_summary.count.total_flows;
        }

        return total;
      }, 0)
    : [],
    workflowCount: state.sync.orgs
    ? state.sync.orgs.reduce((total, acc) => {
        if (acc.details) {
          if (acc.details.mongo_summary)
            return total + acc.details.mongo_summary.count.total_workflows;
        }

        return total;
      }, 0)
    : [],
});

const mapDispatchToProps = (dispatch) => ({
  setOrgs: (data) => dispatch(setOrgs(data)),
  setNav: (data) => dispatch(setNav(data)),
  getAllPodioAccounts: (idToken) =>
    dispatch(actions.getAllPodioAccounts(idToken)),
  onFetchAllSyncedOrganizations: (token) =>
    dispatch(actions.getAllSyncedAccounts(token)),
  onFetchAllUnSyncedOrganizations: (token) =>
    dispatch(actions.getAllUnSyncedAccounts(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sync);
