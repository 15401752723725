import React from "react";
import "./features.css";
import integrate from "../../Images/integrate.png";
import backup from "../../Images/backup.png";
import user from "../../Images/user.png";
import printing from "../../Images/printing.png";

export default function Features() {
  return (
    <div className="feature-cont">
      <div className="text-cont">
        <h2>
          Gain an Unfair Advantage with <span>AI-Powered</span> Insights
        </h2>
        <p>
          Leave the complexity to us. Get a clear, elevated view of your data,
          business processes and strategic initiatives with actionable,
          data-driven insights.
        </p>
      </div>
      <div className="features-card">
        <div className="feature">
          <img src={integrate} alt="integrate" />
          <h2>Integrate. Automate. Relax.</h2>
          <p>
            AVA is our Artificial Intelligence and your Automated Virtual
            Assistant. A hybrid Integration Platform for your ERP, SaaS, Cloud
            Data.
          </p>
        </div>
        <div className="feature">
          <img src={backup} alt="backup" />
          <h2>What are you syncing about?</h2>
          <p>
            Sync enables you to backup your entire organization's data, ensuring
            real-time protection with enterprise data security.
          </p>
        </div>
        <div className="feature">
          <img src={printing} alt="integrate" />
          <h2>Dynamic Document Creation, eSignature and Smart Contracts</h2>
          <p>
            Create dynamic documents in any format seamlessly, with real-time
            customization and smooth integration with your data for efficient
            document management.
          </p>
        </div>
        <div className="feature">
          <img src={user} alt="integrate" />
          <h2>Effortless User Management</h2>
          <p>
            Easily manage user permissions, audit data changes, and data access
            across all workspaces. Audit simplifies oversight, giving you full
            control and transparency over your data.
          </p>
        </div>
      </div>
    </div>
  );
}
