import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { hookKiller } from "../../../services/sync";
import { Alert } from "@material-ui/lab";
import {useSelector} from 'react-redux'

const HookActions = (props) => {
  const user = useSelector(state => state.user.authUser)

  const [spaceSync, syncModalData] = React.useState({
    message: null,
    modal_type: null,
    modal_value: false,
    modal_data: {},
  });
  const [snack, setSnack] = React.useState({
    message: null,
    snackType: null,
    isSnack: false,
    reload: false,
  });

  const data = typeof props["data"] !== "undefined" ? props["data"] : null;

  const killHook = () => {
    hookKiller(`?token=${user.access_token}&org_id=${data.id}&url=${data.url}`)
      .then((r) => {
        confirm_handleClose();
        setSnack({
          message: "Hook killed successfully",
          snackType: "success",
          isSnack: true,
          reload: true,
        });
      })
      .catch((err) => {
        confirm_handleClose();
        setSnack({
          message:
            "You don't have the permission to do this, contact your podio administrator.",
          snackType: "error",
          isSnack: true,
          reload: false,
        });
      });
  };

  function confirm_handleClose() {
    syncModalData({
      message: null,
      modal_type: null,
      modal_value: false,
      modal_data: {},
    });
  }

  function handleClose() {
    if (snack.reload) window.history.go();
    setSnack({ message: "", snackType: "", isSnack: false, reload: false });
  }

  return (
    <div>
      {data && (
        <div>
          {data.status === "active" ? (
            <i
              className="far fa-window-close"
              style={{
                fontSize: "1.50em",
                marginRight: "10px",
                cursor: "pointer",
              }}
              title="Kill Hook"
              onClick={() => {
                syncModalData({
                  message: `Do you want to kill this hook?`,
                  modal_type: "kill_hook",
                  modal_value: true,
                  modal_data: {},
                });
              }}
            ></i>
          ) : ""
          }
          <Dialog
            open={spaceSync.modal_value}
            onClose={confirm_handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Manage Hook</DialogTitle>
            <DialogContent>
              <DialogContentText>{spaceSync.message}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={confirm_handleClose} color="primary">
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  if (spaceSync.modal_type === "kill_hook") {
                    killHook();
                  }
                }}
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={snack.isSnack}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <Alert onClose={handleClose} severity={snack.snackType}>
              {snack.message}
            </Alert>
          </Dialog>
        </div>
      )}
    </div>
  );
};

export default HookActions;
