import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import thatapp from "../../assets/icons/thatapp.svg";
import useWindowSize from "hooks/useWindowSize";
import OrgToOrg from "./modules/OrgToOrg";
// import WorkToOrg from './modules/WorkToOrg';
import AppToWork from "./modules/AppToWork";
import ActivityLog from "./modules/ActivityLog";
// import cart from "../../assets/icons/cart.svg"
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    position: "absolute",
    top: "0",
    width: "100%",
    padding: "0",
    overflowX: "scroll",
    height: "100vh",
  },
  bgGoldButton: {
    background: "#F7C948",
    color: "#fff",
    width: "100%",
  },
  loader: {
    backgroundColor: "rgba(0,0,0,0.3)",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 2,
    display: "grid",
    placeItems: "center",
  },
  headerIcons: {
    display: "flex",
    // justifyContent:
    alignItems: "center",
    gap: "61px",
  },
}));

const PdcModal = ({ open, handleClose }) => {
  const [activeTab, setActiveTab] = useState("organization-to-organization");
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:600px)");

  const showActivities = () => {
    setActiveTab("all-activities");
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={`${classes.paper} plans-account-modal pricing-pricing`}>
          <div className="inner-account-modal">
            <div className="header">
              <img src={thatapp} alt="ThatApp" className="logo" />
              <div className="modal-navigation">
                {isMobile ? null : (
                  <RenderPlanHeader
                    selected={activeTab === "organization-to-organization"}
                  >
                    <span
                      onClick={() =>
                        setActiveTab("organization-to-organization")
                      }
                      className={
                        activeTab === "organization-to-organization"
                          ? "active"
                          : ""
                      }
                    >
                      Organisation to Organisation
                    </span>
                  </RenderPlanHeader> 
                )}

                {/* <RenderPlanHeader selected={activeTab === "workspace-to-organization"}>
                  <span
                    onClick={() => setActiveTab("workspace-to-organization")}
                    className={activeTab === "workspace-to-organization" ? "active" : ""}
                  >
                    Space to Space
                  </span>
                </RenderPlanHeader> */}
                <RenderPlanHeader selected={activeTab === "app-to-workspace"}>
                  <span
                    onClick={() => setActiveTab("app-to-workspace")}
                    className={activeTab === "app-to-workspace" ? "active" : ""}
                  >
                    App to Space
                  </span>
                </RenderPlanHeader>
              </div>
              <div className={classes.headerIcons}>
                {/* <span onClick={() => setActiveTab("cart")} className="close">
                  <img src={cart} alt='cart'/>
                </span> */}
                <span onClick={handleClose} className="close">
                  &times;
                </span>
              </div>
            </div>
            <div className="modal-container">
              {activeTab === "organization-to-organization" && (
                <OrgToOrg showActivities={showActivities} />
              )}
              {/* {activeTab === "workspace-to-organization" && <WorkToOrg showActivities={showActivities} />} */}
              {activeTab === "app-to-workspace" && (
                <AppToWork showActivities={showActivities} />
              )}
              {activeTab === "all-activities" && <ActivityLog />}
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default PdcModal;

function RenderPlanHeader({ selected, children }) {
  const { width } = useWindowSize();
  const isMobile = width < 650;

  if (!isMobile) return children;

  if (isMobile && selected) {
    return children;
  } else {
    return null;
  }
}
