import React from "react";
import cancelIcon from "../../assets/icons/cancel.svg";
import redC from "../../assets/icons/redCaution.svg";
import yellowC from "../../assets/icons/yellowCaution.svg";
import "./ConfirmModal.css";

const ConfirmModal = ({ message, syncOrg, toggleModal, deleteOrg }) => {
  return (
    <div className="confirm__modal">
      <div className="modal__content">
        <div>
          <div className="modal__card">
            <div>
              <div style={{ textAlign: "right", marginRight: "5%" }}>
                <img
                  src={cancelIcon}
                  alt="Cancel"
                  onClick={() => toggleModal(null, null)}
                />
              </div>
              <div>
                <img
                  src={message.includes("resync") ? yellowC : redC}
                  alt="Cancel"
                  onClick={() => toggleModal(null, null)}
                />
              </div>
              <h6 className="mt-4 mb-4 for_message">{message}</h6>
              <button
                style={
                  message.includes("resync")
                    ? { backgroundColor: "#F0B429" }
                    : { backgroundColor: "#E12D39" }
                }
                onClick={() => {
                  message.includes("resync") ? syncOrg() : deleteOrg();
                }}
              >
                {message.includes("resync") ? "Confirm" : "Yes Proceed"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
