import React from "react";
import { Dialog, DialogContent, Box } from "@material-ui/core";
import Axios from "config";
import useAsync from "components/templates/print-v2/hooks/useAsync";
import toaster from "toasted-notes";
import "toasted-notes/src/styles.css";
import cancelIcon from "../../../../assets/icons/cancel.svg";

const StopSync = ({ isOpen, toggleModal, id }) => {
  const { execute, status } = useAsync(stopSync, false);

  function stopSync() {
    return Axios.get(`/backup-progress/stop?progress_id=${id}`).then(
      (response) => {
        if (response.success) {
          toaster.notify("Sync stopped successfully", {
            duration: "4000",
            position: "bottom",
          });
          toggleModal();
        }
      }
    );
  }

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={toggleModal}
        disableBackdropClick={false}
        disableEscapeKeyDown={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ width: "450px" }}>
          <div className="d-flex justify-content-between align-items-center mt-2 mb-4">
            <h5>Cancel Sync Process</h5>
            <img onClick={toggleModal} alt="" src={cancelIcon} />
          </div>
          <Box>
            <h6 className="text-center mt-5">
              Are you sure you want to cancel this sync.
            </h6>
          </Box>

          <Box className="text-center mt-5 mb-3 d-flex justify-content-end">
            <button
              className="btn btn-outline-danger"
              disabled={status === "pending"}
              onClick={execute}
            >
              {status === "pending" ? "Stopping Sync..." : "Stop Sync"}
            </button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default StopSync;
