import React from "react";
import { LinearProgress } from "@material-ui/core";

const AddDomainToApp = (props) => {
  const {
    domains,
    saveLoading,
    handleSubmit,
    // goBack,
    changeSelectedDomain,
    selectedDomain,
  } = props;
  const options =
    domains.length > 0
      ? domains.map((domain) => {
          return (
            <option key={domain.id} value={domain.id}>
              {" "}
              {domain.domain}{" "}
            </option>
          );
        })
      : null;
  return (
    <div className="row">
      <div className="col-md-8">
        <form>
          <div className="form-group">
            <label htmlFor="DomainId">Select Domain</label>
            <select
              className="form-control"
              value={selectedDomain}
              onChange={(e) => changeSelectedDomain(e.target.value)}
              style={{ textTransform: "lowercase" }}
            >
              {options}
            </select>
          </div>
          <button
            className="btn btn-default mr-2"
            onClick={handleSubmit}
            style={{
              color: "#fff",
              background: "#F7C948",
            }}
          >
            Save
          </button>
          {/* <button className="btn btn-secondary ml-2" onClick={goBack}>
            Cancel
          </button> */}
          <div className="mt-2">
            {saveLoading ? (
              <center className="mt-3">
                <LinearProgress />
              </center>
            ) : null}
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddDomainToApp;
