import React from 'react'
import styled from "@emotion/styled";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

const Dropdown = props => {
    return (
        <Dropdown.Wrapper>
            <TextField
                className={`${props.className ?? ""} dropdown-input` }
                id={props?.name ?? ""}
                name={props?.name}
                select
                label={props.label}
                value={props.value}
                onChange={props.function}
                helperText={props.helperText}
                disabled={props.disabled ?? false}
                variant="outlined"
            >
                {props.list?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                    {option.label}
                    </MenuItem>
                ))}
            </TextField>
        </Dropdown.Wrapper>
    )
}

Dropdown.Wrapper = styled.div`
    width:100%;
`;

export default Dropdown
