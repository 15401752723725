import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

const CustomTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'rgba(90, 90, 90, 1)',
    color: '#fff',
    fontSize: 12,
  },
}))(Tooltip);

export default CustomTooltip;
