import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
// import * as AllModules from "ag-grid-enterprise";
// import { ModuleRegistry } from "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import { AgGridReact } from "ag-grid-react";
import { getInvoicesRequest } from "../../redux/actions/test";
import { useDispatch, useSelector } from "react-redux";
import InvoiceAction from "./InvoiceAction";
import InvoiceAlertModal from "../../components/Modal/InvoiceAlertModal";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backButton: {
    backgroundColor: "#f7c948",
    boxShadow: "0px 3px 6px #00000029",
    padding: "1vh 1vw",
    outline: "none",
    textDecoration: "none",
    border: "none",
    borderRadius: "6px",
    color: "white",
    marginBottom: "1%",
  },
}));
// ModuleRegistry.registerModules(Object.keys(AllModules));
const Invoices = () => {
  const classes = useStyles();

  const filterParams = {
    filterOptions: ["contains", "notContains"],
    textFormatter: function (r) {
      if (r == null) return null;
      r = r.replace(new RegExp("[àáâãäå]", "g"), "a");
      r = r.replace(new RegExp("æ", "g"), "ae");
      r = r.replace(new RegExp("ç", "g"), "c");
      r = r.replace(new RegExp("[èéêë]", "g"), "e");
      r = r.replace(new RegExp("[ìíîï]", "g"), "i");
      r = r.replace(new RegExp("ñ", "g"), "n");
      r = r.replace(new RegExp("[òóôõøö]", "g"), "o");
      r = r.replace(new RegExp("œ", "g"), "oe");
      r = r.replace(new RegExp("[ùúûü]", "g"), "u");
      r = r.replace(new RegExp("[ýÿ]", "g"), "y");
      return r;
    },
    debounceMs: 0,
    caseSensitive: true,
    suppressAndOrCondition: true,
  };
  const dispatch = useDispatch();
  const [openAlert, setOpenAlert] = useState(false);

  const [invoices, setInvoices] = React.useState({
    columns: [
      // {
      //   headerName: "Extension",
      //   field: "extension",
      //   rowGroup: true,
      //   hide: true,
      // },
      {
        headerName: "Menu",
        width: 30,
        field: "menu",
        cellRenderer: "viewAction",
      },
      {
        headerName: "Billing Start",
        field: "start_date",
        filter: "agTextColumnFilter",
        filterParams,
      },
      {
        headerName: "Billing End",
        field: "end_date",
        filter: "agTextColumnFilter",
        filterParams,
      },
      {
        headerName: "Total Cost",
        field: "total_cost",
        filter: "agTextColumnFilter",
        filterParams,
      },
      // { headerName: 'Url', field: 'url', filter: 'agTextColumnFilter', filterParams},
      {
        headerName: "Payment Status",
        field: "status",
        filter: "agTextColumnFilter",
        filterParams,
      },
    ],
    data: [],
  });

  const invoiceState = useSelector((state) => state.liveData.invoices);

  useEffect(() => {
    dispatch(getInvoicesRequest());
  }, [dispatch]);

  useEffect(() => {
    if (invoiceState.success) {
      invoiceState.data.forEach((Ddata) => {
        setInvoices((prevState) => {
          const data = [...prevState.data];

          data.push({
            total_cost: "$" + Ddata.total_cost,
            start_date: Ddata.start_date,
            end_date: Ddata.end_date,
            status: Ddata.status === 1 ? "PAID" : "NOT PAID",
            encryption_id: Ddata.encrypted_id,
          });
          return { ...prevState, data };
        });
      });
    } else {
      setAgrid("No data rows");
    }
  }, [invoiceState.data, invoiceState.success]);

  const defaultColDef = {
    flex: 1,
    minWidth: 100,
    filter: true,
    sortable: true,
    resizable: true,
  };
  const autoGroupColumnDef = { minWidth: 200 };
  const frameworks = {
    viewAction: InvoiceAction,
    // viewAction: null,
  };

  const [, setAgrid] = React.useState("Loading...");

  setTimeout(function () {
    setAgrid("No Data");
  }, 3000);

  const handleClickOpen = () => {
    setOpenAlert(true);
  };

  const closeAlert = () => {
    setOpenAlert(false);
  };
  const handleBackButtonClick = () => {
    window.history.back();
  };
  return (
    <React.Fragment>
      <div className="flex flex-1 items-center justify-between p-24">
        <div>
          <button
            onClick={handleBackButtonClick}
            className={classes.backButton}
          >
            Back
          </button>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div className="flex flex-col">
            <Typography variant="h6" style={{ marginBottom: "2rem" }}>
              Invoices
            </Typography>
          </div>

          <div>
            <button
              className="btn btn-default"
              style={{
                background: "#f7c948",
                color: "#fff",
                marginBottom: "2%",
              }}
              onClick={() => {
                handleClickOpen();
              }}
            >
              Refresh Invoices
            </button>
          </div>
        </div>
      </div>
      <div className="p-24">
        <div
          className="ag-theme-balham-dark"
          style={{
            height: "700px",
            width: "100%",
          }}
        >
          <AgGridReact
            // modules={Object.keys(AllModules)}
            columnDefs={invoices.columns}
            defaultColDef={defaultColDef}
            autoGroupColumnDef={autoGroupColumnDef}
            frameworkComponents={frameworks}
            enableRangeSelection={true}
            animateRows={true}
            rowData={invoices.data}
            // overlayNoRowsTemplate={grid_loading}

            rowGroupPanelShow="always"
            rowSelection="multiple"
            debug={true}
            sideBar={true}
            enableCellTextSelection={true}
            suppressAggFuncInHeader={true}
            enableFilter={true}
            enableSorting={true}
          />
        </div>
      </div>

      <InvoiceAlertModal openModal={openAlert} handleClose={closeAlert} />
    </React.Fragment>
  );
};

export default Invoices;
