import React from "react";

import "./ConfirmEmail.css";
import "../register.css";

// import email_icon from "../../../assets/icons/email-icon.svg";
import logo from "../../../assets/images/logo.png";
import { Link } from "react-router-dom";

const ConfirmEmail = () => {
  return (
    <div className="auth__forms__cont">
      <div className="auth__title__cont">
        <Link className="home__link" to="/">
          Home
        </Link>

        <h2>
          Already have an account?{" "}
          <span>
            <Link to="/login">Login</Link>
          </span>
        </h2>
      </div>
      <div className="auth__form__wrapper">
        <div className="create__form">
          <div className="create__logo__cont">
            <img src={logo} alt="thatapplogo" />
            <h2>Verify your email</h2>
            <p>
              Please verify your account by clicking on the link sent to your
              email address to begin using ThatApp.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmEmail;
