import React, { useState, useEffect, Fragment, useRef } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useParams, useHistory } from "react-router-dom";
import {
  getSpaces,
  createSpace,
  deleteSpace,
  updateSpace,
  // getAuditSpaces
} from "../../services/sync";
import { connect, useDispatch } from "react-redux";
import CustomButton from "../../components/atoms/CustomButton";
import Button from "../../components/atoms/Button";
import useMediaQuery from "@mui/material/useMediaQuery";

// import AutoComplete from "../../components/atoms/AutoComplete";
import AddIcon from "@material-ui/icons/Add";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import ListIcon from "@material-ui/icons/List";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import WorkspaceCardView from "./WorkspaceCardView";
import WorkspaceListView from "./WorkspaceListView";
import AppsIcon from "@material-ui/icons/Apps";
import Tooltip from "@material-ui/core/Tooltip";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import { toast } from "helpers/apiRequests";
import { setOrgs } from "../../redux/actions/sync";
import { setNav } from "../../redux/actions/sidebar";
import { getNavigation } from "../../services/common";
import "./workspace.css";
import RenderByPermission from "components/RenderByPermission";
import cancel from "../../assets/icons/cancel.svg";
import searchIcon from "../../assets/icons/search.svg";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    fontFamily: "Mulish",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "30px",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <Tooltip title="Close Modal">
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    marginRight: theme.spacing(1),
    textAlign: "center",
    float: "right",
    background: "#F0B429",
    borderRadius: "3px",
    color: "white",
  },
  root: {
    "& hr": {
      margin: theme.spacing(0, 0.5),
      backgroundColor: "#50504c47",
      height: "50px",
    },
  },
  required: {
    "&::after": {
      content: '"*"',
      color: "#fc3b68f2",
    },
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  form: {
    fontSize: "14px",
  },
  body1Text: {
    fontSize: "14px",
  },
  checkboxLabel: {
    fontSize: "14px",
  },
  link: {
    "&:hover": {
      color: "#fcaf3b",
      textDecoration: "none",
    },
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "30px",
    textDecoration: "none",
  },
  label: {
    display: "block",
  },
  input: {
    width: "48%",
    borderRadius: "5px",
    outline: "none",
    marginTop: "1%",
    backgroundColor: "white",
    border: "1px solid #E0E0E0",
    marginRight: "2%",
  },
  listbox: {
    width: 200,
    margin: 0,
    padding: 0,
    zIndex: 1,
    position: "absolute",
    listStyle: "none",
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
    maxHeight: 200,
    border: "1px solid rgba(0,0,0,.25)",
    li: {
      padding: "10px",
    },
    '& li[data-focus="true"]': {
      backgroundColor: "#4a8df6",
      color: "white",
      cursor: "pointer",
      padding: "10px",
    },
    "& li:active": {
      backgroundColor: "#2977f5",
      color: "white",
    },
  },
  backButton: {
    backgroundColor: "#f7c948",
    boxShadow: "0px 3px 6px #00000029",
    padding: "1vh 1vw",
    outline: "none",
    textDecoration: "none",
    border: "none",
    borderRadius: "6px",
    color: "white",
    marginBottom: "2%",
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  headerText: {
    color: "black",
  },
  btnContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  btnDesc: {
    color: "#DE911D",
    marginTop: "3%",
  },
  modalInfo: {
    color: "#DE911D",
    fontSize: "12px",
  },

  "@media screen and (max-width: 768px)": {
    root: {
      flexDirection: "column",
    },
    input: {
      width: "100%",
      marginTop: "5%",
      paddingLeft: "3%",
    },
    button: {
      fontSize: "0.8rem !important",
    },
  },
}));

const Workspace = ({ orgs, setOrgs }) => {
  const history = useHistory();
  const classes = useStyles();
  const params = useParams();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [loading, setLoading] = useState(false);
  const [organization, setOrg] = useState({});
  const [selectedOrg, setSelectedOrg] = useState(0);
  const [workspaceName, setWorkspaceName] = useState("");
  const [privacy, setPrivacy] = useState("closed");
  const [postNewMember, setPostNewMember] = useState(false);
  const [postNewApp, setPostNewApp] = useState(false);
  const [autoJoin, setAutoJoin] = useState(false);
  const [workspaceNameValidation, setWorkspaceNameValidation] = useState(false);
  const [selectedOrgValidation, setSelectedOrgValidation] = useState(false);
  const [deleteWorkspace, setDeleteWorkspace] = useState(false);
  const [editWorkspace, setEditWorkspace] = useState(false);
  const [createWorkspace, setCreateWorkspace] = useState(false);
  const [workspaceView, setWorkspaceView] = useState("card");
  const [workspaces, setWorkspaces] = useState([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [createWorkspaceLoading, setCreateWorkspaceLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    const fetchOrgs = async () => {
      let org = orgs.find((x) => parseInt(x.org_id) === parseInt(params.id));
      setOrg(org);
    };

    const fetchSpaces = async () => {
      let response = await getSpaces(params.id);
      let data = response?.data
        ? response.data.map((space) => ({ ...space, display: true }))
        : [];
      setWorkspaces(data);

      if (response.data) {
        setLoading(false);
      }
    };

    fetchOrgs();
    fetchSpaces();
  }, [orgs, params.id]);

  useEffect(() => {
    (async () => {
      let menuResponse = await getNavigation();
      setNav(menuResponse.menus);
    })();
  }, [params.id]);

  const updateNavigation = async () => {
    let response = await getNavigation();

    dispatch(setNav(response.menus));
  };

  const openCreateDialog = () => {
    setCreateWorkspace(true);

    resetStateData();
  };

  const closeCreateDialog = () => {
    setCreateWorkspace(false);
  };

  const openEditDialog = (app_id) => {
    setEditWorkspace(true);

    let workspace = workspaces.find(
      (x) => parseInt(x.space_id) === parseInt(app_id)
    );

    setWorkspaceName(workspace.name);
    setPrivacy(workspace.privacy);
    setPostNewMember(workspace.post_on_new_member);
    setAutoJoin(workspace.full_space_object.auto_join);
    setPostNewApp(workspace.post_on_new_app);
    setSelectedOrg(workspace.org_id);
  };

  const closeEditDialog = () => {
    setEditWorkspace(false);
    resetStateData();
  };

  const openDeleteDialog = () => {
    setDeleteWorkspace(true);
  };

  const closeDeleteDialog = () => {
    setDeleteWorkspace(false);
  };

  const handleSelectedWorkspace = (id) => {
    setSelectedWorkspace(id);
  };

  const handleWorkspaceView = (view) => {
    setWorkspaceView(view);
  };

  const handleOrganisationRoute = (e) => {
    let org_id = e.target.value;
    let link = `/dashboard/workspaces/${org_id}`;

    history.push(link);
  };

  const fetchAllSpaces = async () => {
    let response = await getSpaces(params.id);
    let data = response?.data
      ? response.data.map((space) => ({ ...space, display: true }))
      : [];
    setWorkspaces(data);
    setLoading(false);
  };
  const text = useRef("");

  const filterWorkspace = (newValue) => {
    const newWorkSpace = workspaces.filter((workspace) => {
      const regex = new RegExp(`${newValue}`, "gi");
      return (
        workspace.name.match(regex) ||
        workspace.space_id.toString().match(regex)
      );
    });
    setWorkspaces(newWorkSpace);
  };

  const clearFilter = () => {
    fetchAllSpaces();
  };

  const handleWorkspaceSearch = (e) => {
    if (text.current.value !== "") {
      filterWorkspace(e.target.value);
    } else {
      clearFilter();
    }
  };

  const resetStateData = () => {
    setWorkspaceName("");
    setPrivacy("closed");
    setPostNewMember(false);
    setAutoJoin(false);
    setPostNewApp(false);
    setSelectedOrg(0);

    setWorkspaceNameValidation(false);
    setSelectedOrgValidation(false);
    updateNavigation();
  };

  const createNewWorkspace = async (e) => {
    e.preventDefault();

    if (workspaceName.length === 0 || parseInt(workspaceName) === 0) {
      setWorkspaceNameValidation(true);
      return;
    }

    if (selectedOrg === 0) {
      setSelectedOrgValidation(true);
      return;
    }

    setCreateWorkspaceLoading(true);

    let data = {
      org_id: parseInt(selectedOrg),
      name: workspaceName,
      privacy: privacy,
      auto_join: autoJoin,
      post_on_new_app: postNewApp,
      post_on_new_member: postNewMember,
    };

    try {
      let createResponse = await createSpace(data);

      if (createResponse.message) {
        toast.notify(createResponse.message);
      }

      setLoading(true);

      const fetchSpaces = async () => {
        let response = await getSpaces(params.id);
        let data = response?.data
          ? response.data.map((space) => ({ ...space, display: true }))
          : [];
        setWorkspaces(data);
        setLoading(false);
      };

      fetchSpaces();
    } catch (err) {}

    setCreateWorkspaceLoading(false);
    setCreateWorkspace(false);
    resetStateData();
  };

  const handleEditWorkspace = async (e) => {
    e.preventDefault();

    if (workspaceName.length === 0 || parseInt(workspaceName) === 0) {
      setWorkspaceNameValidation(true);
      return;
    }

    if (selectedOrg === 0) {
      setSelectedOrgValidation(true);
      return;
    }

    setCreateWorkspaceLoading(true);

    let data = {
      org_id: parseInt(selectedOrg),
      name: workspaceName,
      privacy: privacy,
      auto_join: autoJoin,
      post_on_new_app: postNewApp,
      post_on_new_member: postNewMember,
      space_id: selectedWorkspace,
    };

    try {
      let updateResponse = await updateSpace(data);

      if (updateResponse.message) {
        toast.notify(updateResponse.message);
      }

      setLoading(true);
      const fetchSpaces = async () => {
        let response = await getSpaces(params.id);

        let data = response?.data
          ? response.data.map((space) => ({ ...space, display: true }))
          : [];
        setWorkspaces(data);
        setLoading(false);
      };

      fetchSpaces();
    } catch (err) {}

    setCreateWorkspaceLoading(false);
    setEditWorkspace(false);
    resetStateData();
  };

  const handleDeleteWorkspace = async () => {
    setCreateWorkspaceLoading(true);
    try {
      let deleteResponse = await deleteSpace(
        `?org_id=${organization.org_id}&space_id=${selectedWorkspace}`
      );

      if (deleteResponse.message) {
        toast.notify(deleteResponse.message);
      }
    } catch (err) {}

    setCreateWorkspaceLoading(false);
    setLoading(true);

    let response = await getSpaces(params.id);

    let data = response?.data
      ? response.data.map((space) => ({ ...space, display: true }))
      : [];
    setWorkspaces(data);
    setDeleteWorkspace(false);
    setSelectedWorkspace(null);
    setLoading(false);
    updateNavigation();
  };

  if (loading) {
    return (
      <div className="load__audit">
        <div className="material_block">
          <svg
            className="spinner"
            width="65px"
            stroke="#F0B429"
            height="65px"
            viewBox="0 0 66 66"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              className="circle"
              fill="none"
              strokeWidth="6"
              strokeLinecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    );
  }

  return (
    <div className="">
      <div className="d-flex justify-content-between align-items-center">
        <h5 className="org_title">Organisation</h5>
        <Box>
          <CustomButton
            className={classes.button}
            size="large"
            tooltip="true"
            tooltip_title="Create New Space"
            tooltip_placement="bottom"
            startIcon={<AddIcon />}
            onClick={openCreateDialog}
          >
            Add New Space
          </CustomButton>
        </Box>
      </div>

      <div className="row align-items-center">
        <div className="col-md-12" style={{ width: "100%" }}>
          <Box
            style={{ alignItems: "center" }}
            display="flex"
            className={classes.root}
          >
            <Box display="flex" flexGrow={1} className="pt-2 input_cont">
              {/* <Select
                native
                value={organization.name}
                onChange={e => handleOrganisationRoute(e)}
                label="Organisation"
                inputProps={{
                  name: 'organisation',
                  id: 'outlined-org-native-simple',
                }}
              >
                <option value={organization.org_id}>
                  {organization.name}
                </option>
                {orgs.map(
                  (org, index) => {
                    if (org.org_id !== organization.org_id) {
                      return (
                        <option
                          key={index}
                          value={org.org_id}>
                          {org.name}
                        </option>
                      )
                    }
                    return ""
                  }

                )}
              </Select> */}
              <div
                className={classes.input}
                style={{
                  border: "1px solid #E1E1E1",
                  display: "flex",
                  alignItems: "center",
                  paddingRight: "3%",
                }}
              >
                <input
                  style={{
                    display: "inline-block",
                    border: "none",
                    backgroundColor: "white",
                    padding: "0",
                  }}
                  type="text"
                  ref={text}
                  onChange={handleWorkspaceSearch}
                  placeholder="Search Workspaces..."
                />
                <img alt="" src={searchIcon} />
              </div>
              <select
                value={organization.name}
                onChange={(e) => handleOrganisationRoute(e)}
                className={classes.input}
              >
                <option style={{ padding: "10px" }} value={organization.org_id}>
                  {organization.name}
                </option>
                {orgs.map((org, index) => {
                  if (org.org_id !== organization.org_id) {
                    return (
                      <option
                        style={{ padding: "10px" }}
                        key={index}
                        value={org.org_id}
                      >
                        {org.name}
                      </option>
                    );
                  }
                  return "";
                })}
              </select>
            </Box>

            {isMobile ? (
              <Box style={{ width: "100%" }}>
                {workspaceView === "card" ? (
                  <button
                    onClick={() => handleWorkspaceView("list")}
                    className="d-flex align-items-center mt-3 switch_btn"
                    style={{ fontSize: "14px" }}
                  >
                    Filter By: <span className="ml-2 filter_style">List</span>
                  </button>
                ) : (
                  <button
                    onClick={() => handleWorkspaceView("card")}
                    className="d-flex align-items-center mt-3 switch_btn"
                    style={{ fontSize: "14px" }}
                  >
                    Filter By: <span className="ml-2 filter_style">Card</span>
                  </button>
                )}
              </Box>
            ) : (
              <Box p={1}>
                {workspaceView === "card" ? (
                  <div className="d-flex align-items-center mt-3">
                    <Tooltip title="Change to List View">
                      <ListIcon
                        fontSize="large"
                        style={{ cursor: "pointer", color: "#756f6f" }}
                        onClick={() => handleWorkspaceView("list")}
                      />
                    </Tooltip>
                    <h5 style={{ fontSize: "14px", marginLeft: "5px" }}>
                      List view
                    </h5>
                  </div>
                ) : (
                  <div className="d-flex align-items-center mt-3">
                    <Tooltip title="Change to Card View">
                      <AppsIcon
                        fontSize="large"
                        style={{ cursor: "pointer", color: "#756f6f" }}
                        onClick={() => handleWorkspaceView("card")}
                      />
                    </Tooltip>
                    <h5 style={{ fontSize: "14px", marginLeft: "5px" }}>
                      Grid view
                    </h5>
                  </div>
                )}
              </Box>
            )}

            {/* <Box p={1} className={classes.root}>
              <Divider orientation="vertical" flexItem />
            </Box> */}
          </Box>
        </div>
      </div>
      <br />
      <div>
        {workspaceView === "card" ? (
          <WorkspaceCardView
            workspaces={workspaces}
            organization={organization}
            selectedWorkspace={selectedWorkspace}
            openEditDialog={openEditDialog}
            openDeleteDialog={openDeleteDialog}
            handleSelectedWorkspace={handleSelectedWorkspace}
            openCreateDialog={openCreateDialog}
          />
        ) : (
          <WorkspaceListView
            workspaces={workspaces}
            organization={organization}
            openEditDialog={openEditDialog}
            openDeleteDialog={openDeleteDialog}
            selectedWorkspace={selectedWorkspace}
            handleSelectedWorkspace={handleSelectedWorkspace}
          />
        )}
      </div>

      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={createWorkspace}
        onClose={closeCreateDialog}
        aria-labelledby="form-dialog-title"
        className="dialog-box"
      >
        <DialogContent style={{ padding: "7%" }}>
          <div className={classes.modalHeader}>
            <h5 className={classes.headerText}>Create New Space</h5>
            <div>
              <img onClick={closeCreateDialog} alt="cancel" src={cancel} />
            </div>
          </div>
          <Divider style={{ margin: "2% 0" }} className="divider" />
          <div className={classes.modalInfo}>
            Create a new workspace by filling out the information below
          </div>
          <Fragment>
            <Grid container style={{ flexDirection: "column" }}>
              <Grid item xs={4}>
                <label
                  htmlFor="organization_id"
                  className={`${classes.required} label`}
                  style={{ width: "50vw" }}
                >
                  Organisation Id
                </label>
              </Grid>
              <Grid item>
                <select
                  disabled={createWorkspaceLoading}
                  native={true}
                  required={true}
                  variant="outlined"
                  error={selectedOrgValidation}
                  value={selectedOrg}
                  onChange={(e) => setSelectedOrg(e.target.value)}
                  inputProps={{
                    name: "organization",
                    id: "filled-age-native-simple",
                  }}
                  fullWidth
                  style={{
                    marginTop: "2%",
                    backgroundColor: "white",
                    border: "1px solid #dcdcdc",
                    borderRadius: "5px",
                    paddingLeft: "1%",
                    appearance: "auto",
                    paddingRight: "1%",
                  }}
                >
                  <option aria-label="None" value="0">
                    Select Organisation
                  </option>
                  {orgs.map((organization, index) => (
                    <option key={index} value={organization.org_id}>
                      {organization.name}
                    </option>
                  ))}
                </select>
              </Grid>
            </Grid>
            <Grid
              container
              style={{ paddingTop: "5px", flexDirection: "column" }}
            >
              <Grid item xs={4}>
                <label
                  htmlFor="workspace_name"
                  className={`${classes.required} label`}
                  style={{ width: "50vw" }}
                >
                  Workspace Name
                </label>
              </Grid>
              <Grid item>
                <TextField
                  disabled={createWorkspaceLoading}
                  variant="outlined"
                  aria-describedby="Workspace Name"
                  value={workspaceName}
                  error={workspaceNameValidation}
                  onChange={(e) => setWorkspaceName(e.target.value)}
                  margin="normal"
                  id="workspace_name"
                  type="text"
                  size="small"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{ paddingTop: "5px", flexDirection: "column" }}
            >
              <Grid item xs={4}>
                <label
                  htmlFor="access_settings"
                  style={{ fontSize: "14px", width: "50vw" }}
                  className={`${classes.required} label-two`}
                >
                  Access Settings
                </label>
              </Grid>
              <Grid item xs={8}>
                <RadioGroup
                  disabled={createWorkspaceLoading}
                  required={true}
                  aria-label="access_setting"
                  id="access_settings"
                  aria-describedby="Select access settings"
                  name="access_setting"
                  value={privacy}
                  style={{ fontSize: "14px" }}
                  onChange={(e) => setPrivacy(e.target.value)}
                >
                  <FormControlLabel
                    value="closed"
                    aria-describedby="Select access settings - closed"
                    control={<Radio color="primary" />}
                    classes={{
                      label: classes.checkboxLabel,
                    }}
                    label="Private (not visible for others, invite only)"
                    style={{ width: "70vw" }}
                  />
                  <FormControlLabel
                    value="open"
                    control={<Radio color="primary" />}
                    classes={{
                      label: classes.checkboxLabel,
                    }}
                    label="Open (visible and open for all employees to join)"
                    style={{ width: "70vw" }}
                  />
                </RadioGroup>
              </Grid>
            </Grid>
            <div className="mt-4">
              <h5 style={{ fontSize: "13px" }}>Others</h5>
              <p>You can select more than 1</p>
            </div>
            <Grid
              container
              style={{ paddingTop: "5px", flexDirection: "column" }}
            >
              <Grid style={{ display: "flex" }} item>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={createWorkspaceLoading}
                      color="primary"
                      checked={autoJoin}
                      onChange={() => setAutoJoin(!autoJoin)}
                      id="post_auto_join"
                      name="post_on_auto_join"
                    />
                  }
                  classes={{
                    label: classes.checkboxLabel,
                  }}
                />
                <div>
                  <label htmlFor="post_new_app" className={`label-two`}>
                    Auto Join
                  </label>
                  <br />
                  <label
                    style={{ fontWeight: "600" }}
                    htmlFor="post_new_app"
                    className={`label-two`}
                  >
                    New Employees should be joined automatically
                  </label>
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              style={{ paddingTop: "10px", flexDirection: "column" }}
            >
              <Grid style={{ display: "flex" }} item>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={createWorkspaceLoading}
                      color="primary"
                      checked={postNewApp}
                      onChange={() => setPostNewApp(!postNewApp)}
                      id="post_new_app"
                      name="post_on_new_app"
                    />
                  }
                  classes={{
                    label: classes.checkboxLabel,
                  }}
                />

                <div>
                  <label htmlFor="post_new_app" className={`label-two`}>
                    Post on New App
                  </label>
                  <br />
                  <label
                    style={{ fontWeight: "600" }}
                    htmlFor="post_new_app"
                    className={`label-two`}
                  >
                    New apps should be announced with a status update
                  </label>
                </div>
              </Grid>
            </Grid>

            <Grid
              container
              style={{ paddingTop: "5px", flexDirection: "column" }}
            >
              <Grid style={{ display: "flex" }} item>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={createWorkspaceLoading}
                      color="primary"
                      checked={postNewMember}
                      onChange={() => setPostNewMember(!postNewMember)}
                      name="post_on_new_member"
                    />
                  }
                  classes={{
                    label: classes.checkboxLabel,
                  }}
                />

                <div>
                  <label className={`label-two`}>Post on New Member</label>
                  <br />
                  <label style={{ fontWeight: "600" }} className={`label-two`}>
                    New members should be announced with a status update
                  </label>
                </div>
              </Grid>
            </Grid>
            <div className="actions pt-5 m-0">
              <Button
                tooltip={true}
                tooltip_title="Close Workspace Create Modal"
                tooltip_placement="bottom"
                function={closeCreateDialog}
                style={{ background: `#fff`, color: `#626262` }}
                text="Cancel"
              />
              <Button
                tooltip={true}
                tooltip_title="Create New Workspace"
                tooltip_placement="bottom"
                function={createNewWorkspace}
                disabled={createWorkspaceLoading}
                style={
                  createWorkspaceLoading
                    ? { background: `grey`, color: `#000` }
                    : { background: `#F7C948`, color: `#fff` }
                }
                text={createWorkspaceLoading ? "PLEASE WAIT..." : "Create"}
              />
              {createWorkspaceLoading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Fragment>
        </DialogContent>
      </Dialog>
      <Dialog
        open={deleteWorkspace}
        onClose={closeDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Workspace</DialogTitle>
        <hr />
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to delete the current selected workspace (
            {workspaces.length > 0
              ? workspaces.find(
                  (x) => parseInt(x.space_id) === parseInt(selectedWorkspace)
                )?.space_name
              : ""}
            )
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button function={closeDeleteDialog} color="default" text="No" />
          <Button
            function={handleDeleteWorkspace}
            style={
              createWorkspaceLoading
                ? { background: `grey`, color: `#000` }
                : { background: `#F7C948`, color: `#fff` }
            }
            text={createWorkspaceLoading ? "Deleting Workspace..." : "Yes"}
          />
          {createWorkspaceLoading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </DialogActions>
      </Dialog>

      <RenderByPermission>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={editWorkspace}
          onClose={closeEditDialog}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent style={{ padding: "7%" }}>
            <div className={classes.modalHeader}>
              <h5 className={classes.headerText}>Edit Space</h5>
              <div>
                <img onClick={closeEditDialog} alt="cancel" src={cancel} />
              </div>
            </div>
            <Divider style={{ margin: "2% 0" }} className="divider" />
            <div className={classes.modalInfo}>
              Edit the current space by filling out the information below
            </div>
            <FormControl
              style={{ width: "100%" }}
              component="div"
              className={classes.form}
            >
              <Grid
                container
                style={{ flexDirection: "column", marginTop: "2%" }}
              >
                <Grid item xs={4}>
                  <label htmlFor="organization_id" className={classes.required}>
                    Organisation Id
                  </label>
                </Grid>
                <Grid item>
                  <Select
                    disabled={createWorkspaceLoading}
                    native={true}
                    required={true}
                    autoWidth={true}
                    variant="outlined"
                    error={selectedOrgValidation}
                    value={selectedOrg}
                    onChange={(e) => setSelectedOrg(e.target.value)}
                    inputProps={{
                      name: "organization",
                      id: "filled-age-native-simple",
                    }}
                    fullWidth
                    style={{ marginTop: "2%" }}
                  >
                    <option aria-label="None" value="0">
                      Select Organization
                    </option>
                    {orgs.map((organization, index) => (
                      <option key={index} value={organization.org_id}>
                        {organization.name}
                      </option>
                    ))}
                  </Select>
                </Grid>
              </Grid>

              <Grid
                container
                style={{
                  paddingTop: "5px",
                  flexDirection: "column",
                  marginTop: "2%",
                }}
              >
                <Grid item xs={4}>
                  <label htmlFor="workspace_name" className={classes.required}>
                    Workspace Name
                  </label>
                </Grid>
                <Grid item>
                  <TextField
                    disabled={createWorkspaceLoading}
                    variant="outlined"
                    required={true}
                    aria-describedby="Workspace Name"
                    value={workspaceName}
                    error={workspaceNameValidation}
                    onChange={(e) => setWorkspaceName(e.target.value)}
                    margin="normal"
                    id="workspace_name"
                    type="text"
                    size="small"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                style={{ paddingTop: "5px", flexDirection: "column" }}
              >
                <Grid item xs={4}>
                  <label
                    htmlFor="access_settings"
                    style={{ fontSize: "14px" }}
                    className={classes.required}
                  >
                    Access Settings
                  </label>
                </Grid>
                <Grid item xs={8}>
                  <RadioGroup
                    disabled={createWorkspaceLoading}
                    required={true}
                    aria-label="access_setting"
                    id="access_settings"
                    aria-describedby="Select access settings"
                    name="access_setting"
                    value={privacy}
                    style={{ fontSize: "14px" }}
                    onChange={(e) => setPrivacy(e.target.value)}
                  >
                    <FormControlLabel
                      value="closed"
                      aria-describedby="Select access settings - closed"
                      control={<Radio color="primary" />}
                      classes={{
                        label: classes.checkboxLabel,
                      }}
                      label="Private (not visible for others, invite only)"
                    />
                    <FormControlLabel
                      value="open"
                      control={<Radio color="primary" />}
                      classes={{
                        label: classes.checkboxLabel,
                      }}
                      label="Open (visible and open for all employees to join)"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
              <div className="mt-4">
                <h5 style={{ fontSize: "13px" }}>Others</h5>
                <p>You can select more than 1</p>
              </div>
              <Grid
                container
                style={{ paddingTop: "5px", flexDirection: "column" }}
              >
                <Grid style={{ display: "flex" }} item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={createWorkspaceLoading}
                        color="primary"
                        checked={autoJoin}
                        onChange={() => setAutoJoin(!autoJoin)}
                        id="post_auto_join"
                        name="post_on_auto_join"
                      />
                    }
                    classes={{
                      label: classes.checkboxLabel,
                    }}
                  />

                  <div>
                    <label htmlFor="post_new_app">Auto Join</label>
                    <br />
                    <label style={{ fontWeight: "600" }} htmlFor="post_new_app">
                      New Employees should be joined automatically
                    </label>
                  </div>
                </Grid>
              </Grid>

              <Grid
                container
                style={{ paddingTop: "10px", flexDirection: "column" }}
              >
                <Grid item style={{ display: "flex" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={createWorkspaceLoading}
                        color="primary"
                        checked={postNewApp}
                        onChange={() => setPostNewApp(!postNewApp)}
                        id="post_new_app"
                        name="post_on_new_app"
                      />
                    }
                    classes={{
                      label: classes.checkboxLabel,
                    }}
                  />
                  <div>
                    <label htmlFor="post_new_app">Post on New App</label>
                    <br />
                    <label style={{ fontWeight: "600" }} htmlFor="post_new_app">
                      "New apps should be announced with a status update"
                    </label>
                  </div>
                </Grid>
              </Grid>

              <Grid
                container
                style={{ paddingTop: "5px", flexDirection: "column" }}
              >
                <Grid style={{ display: "flex" }} item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={createWorkspaceLoading}
                        color="primary"
                        checked={postNewMember}
                        onChange={() => setPostNewMember(!postNewMember)}
                        name="post_on_new_member"
                      />
                    }
                    classes={{
                      label: classes.checkboxLabel,
                    }}
                  />
                  <div>
                    <label>Post on New Member</label>
                    <br />
                    <label style={{ fontWeight: "600" }}>
                      New members should be announced with a status update
                    </label>
                  </div>
                </Grid>
              </Grid>
            </FormControl>
          </DialogContent>
          <DialogActions
            style={{
              padding: "10px 0",
              marginRight: "20px",
              marginBottom: "5%",
            }}
          >
            <CustomButton
              disabled={createWorkspaceLoading}
              tooltip="true"
              tooltip_title="Create New Workspace"
              tooltip_placement="bottom"
              color="primary"
              onClick={handleEditWorkspace}
            >
              Update Space
            </CustomButton>
            {createWorkspaceLoading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </DialogActions>
        </Dialog>
      </RenderByPermission>
    </div>
  );
};

const mapStateToProps = (state) => ({
  orgs: state.sync.orgs,
});

const mapDispatchToProps = (dispatch) => ({
  setOrgs: (data) => dispatch(setOrgs(data)),
  setNav: (data) => dispatch(setNav(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Workspace);
