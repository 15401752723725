import React from 'react'
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  leftBarHeight: {
    height: '160px'
  },
  leftBar: {
    width: '49px',
    height: '100%',
    backgroundColor: '#F0B429',
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
}));

const LeftbarCard = ({ icon, title, body, count }) => {
  const classes = useStyles();

  return (
    <div className="card overflow-hidden w-100 m-0 mb-4 shadow-sm border-0">
      <div className="card-body p-0 d-flex align-items-center">
        <div className={"media position-relative w-100 align-items-center " + classes.leftBarHeight}>
          <div className={classes.leftBar}>
            <img src={icon} alt="" />
          </div>
          <div className="media-body py-3 ml-3 pr-4">
            <div className="d-flex mb-3 align-items-center justify-content-between">
              <h5 className='mb-0'>{title}</h5>
              <h5 className='text-primary'>{count}</h5>
            </div>
            <div>{body}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LeftbarCard
