import React from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

const InvoiceAction = (props) => {

    //Action Display
    const data = (typeof props["data"] !== "undefined" ? props["data"] : null);

    return (
        <>
            {
                data !== null
                    ?
                    <>
                        <Tooltip title="View Details" style={{marginTop: "-5px"}} ><IconButton aria-label="View Invoice" onClick={() => {
                            const url = "https://billing.thatapp.io/download/invoice/" + data.encryption_id + "/Sync";
                            window.open(
                                url,
                                '_blank'
                            );
                        }}><VisibilityIcon style={{color: "#FFF"}} /></IconButton></Tooltip>
                    </>
                    :
                    ""
            }
        </>
    )
};

export default InvoiceAction;