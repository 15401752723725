import React from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FaPlusCircle } from "react-icons/fa";
import { TMailSelect } from "../components/TMailSelect";
import { Link } from "react-router-dom";
import { TMAIL_V3_ADD_MEMBER } from "../routes";
import { useState } from "react";
import { useSelector } from "react-redux";
import axios from "../../../config";
import MembersTable from "components/Tmail/components/MembersTable";
import { useMemo } from "react";

const useStyles = makeStyles((theme) => ({
  btn: {
    height: "44px",
    width: "185px",
    backgroundColor: "#F0B429",
    borderRadius: "3px",
    textTransform: "capitalize",
  },
  text_field: {
    background: "transparent",
    width: "100%",
    height: "58px",
    fontSize: "14px",
    // marginBottom: '32px'
  },
  backButton: {
    backgroundColor: "#f7c948",
    boxShadow: "0px 3px 6px #00000029",
    padding: "1vh 1vw",
    outline: "none",
    textDecoration: "none",
    border: "none",
    borderRadius: "6px",
    color: "white",
    marginBottom: "1%",
  },
}));

const MembersView = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState({
    space: false,
    member: false,
  });
  const [spaces, setSpaces] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState("");
  const [selectedSpace, setSelectedSpace] = useState("");
  const orgs = useSelector((state) => state.sync.orgs);
  // const orgOptions = orgs.length > 0 ? orgs.map(org => {
  //   return { value: org.org_id, label: org.name }}) : [];

  const changeOrganisation = (e) => {
    const org = e.target.value;
    setSelectedOrg(e.target);
    getSpaces(org);
  };

  const orgOptions = useMemo(() => {
    if (orgs) {
      return orgs.length > 0
        ? orgs.map((org) => {
            return { value: org.org_id, label: org.name };
          })
        : null;
    }
  }, [orgs]);

  const changeSpace = (val) => {

    setSelectedSpace(val.target);
  };

  const getSpaces = async (orgId) => {
    try {
      setLoading({
        ...loading,
        space: true,
      });
      let res = await axios.get(`/sync/podio/audit/spaces?org_id=${orgId}`);
      setSpaces(res.data);
      setLoading({
        ...loading,
        space: false,
      });
    } catch (e) {
      setLoading({
        ...loading,
        space: false,
      });
    }
  };

  const spaceOptions =
    spaces.length > 0
      ? spaces.map((space) => {
          return {
            label: `${space.space_name}`,
            value: space.space_id,
          };
        })
      : [];

  const handleBackButtonClick = () => {
    window.history.back();
  };
  return (
    <div>
      <div>
        <button onClick={handleBackButtonClick} className={classes.backButton}>
          Back
        </button>
      </div>
      <div className="d-flex align-items-start justify-content-between mb-5">
        <div>
          <h5 className="mb-2">Workspace Members</h5>
          <p className="mb-0">
            To access workspace members, select an organization and the
            workspace
          </p>
        </div>

        <Link to={TMAIL_V3_ADD_MEMBER}>
          <Button
            variant="contained"
            color="primary"
            disableElevation={true}
            className={classes.btn}
          >
            <FaPlusCircle />
            &nbsp; Add Member
          </Button>
        </Link>
      </div>

      <div className="row align-items-center">
        <div className="col-5">
          <TMailSelect
            required
            placeholder="Select an Organization"
            className={classes.text_field}
            name="organization"
            value={selectedOrg}
            options={orgOptions}
            onChange={(val) => changeOrganisation({ target: val })}
          />
        </div>
        <div className="col-5">
          <TMailSelect
            required
            placeholder="Select Workspace"
            className={classes.text_field}
            name="spaces"
            options={spaceOptions}
            onChange={(val) => changeSpace({ target: val })}
            value={selectedSpace}
            isLoading={loading.space}
            isDisabled={!spaceOptions?.length > 0 || loading.space}
          />
        </div>
        <div className="col-2">
          <div className="d-flex align-items-center">
            <label htmlFor="">Show</label>
            <select className="form-control">
              <option value="">10</option>
            </select>
            &nbsp; &nbsp;
            <label htmlFor="">entries</label>
          </div>
        </div>
      </div>

      {selectedSpace ? <MembersTable spaceId={selectedSpace.value} /> : null}
    </div>
  );
};

export default MembersView;
