import React from "react";
import {
  Button,
  Dialog,
  // DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router";
import { CONNECTOR } from "../../containers/pagePath";
import "./Modal.css";
import cancelIcon from "../../assets/icons/cancel.svg";

const CredentialModal = ({
  openModal,
  handleClose,
  connector,
  changeConnector,
  saveMapper,
  connectors,
  dataItem,
}) => {
  const history = useHistory();
  const options =
    connectors.length > 0
      ? connectors.map((connector) => (
          <option key={connector.id} value={connector.id}>
            {connector.id} {"->"} {connector.db_name} {"->"}{" "}
            {connector.db_driver}
          </option>
        ))
      : [];
  const handleSaveMapper = () => {
    if (!connector || connector === null) {
      return;
    }
    handleClose();
    saveMapper();
  };
  const gotoConnector = () => {
    history.push({
      pathname: CONNECTOR,
    });
  };

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            openModal(false);
          }
        }}
        // hideBackdrop={true}
        disableEscapeKeyDown={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="connector-modal"
      >
        <DialogContent>
          <DialogContent>
            <DialogContentText
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              id="alert-dialog-description"
            >
              <Typography
                className="dbCredential_title"
                style={{ color: "black" }}
                variant="h5"
              >
                Database Credential
              </Typography>

              <div>
                <img src={cancelIcon} alt="cancel" onClick={handleClose} />
              </div>
            </DialogContentText>
            {connectors.length > 0 ? (
              <div className="form-group mb-3 mt-3">
                <label
                  style={{ fontSize: "18px" }}
                  className="mb-2 dbCredential_label"
                  htmlFor="org"
                >
                  Connector
                </label>
                <select
                  className="form-control input-area-gd"
                  defaultValue={connector}
                  onChange={(e) => changeConnector(e.target.value)}
                  required
                  style={{fontSize: "14px,", color: "#7E7E7E"}}
                >
                  <option>Select a connector</option>
                  {options}
                </select>
              </div>
            ) : (
              <center>
                <Typography variant="p">No Database Credential</Typography>
                <br />
                <Button
                  id="button-cancel"
                  onClick={gotoConnector}
                  color="primary"
                >
                  Create Connectors
                </Button>
              </center>
            )}
          </DialogContent>
          {/* <DialogActions> */}
          <div className="d-flex justify-content-end pr-4">
            {connectors.length > 0 ? (
              <Button
                id="button-cancel"
                onClick={handleSaveMapper}
                className="btn btn-primary"
                style={{
                  backgroundColor: "#F0B429",
                  color: "white",
                  margin: "2% 0",
                }}
              >
                Continue
              </Button>
            ) : null}
          </div>
          {/* </DialogActions> */}
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default CredentialModal;
