/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import Podio from "../../assets/images/podioconnect.png";
// import Sales from "../../assets/images/salesforce.png";
// import Teletrac from "../../assets/images/teletrac.svg";
// import Shopify from "../../assets/images/shopfiy.png";
// import Wrike from "../../assets/images/wrike.png";
import { useHistory } from "react-router-dom";
import { authV2Connect } from "../../helpers/Authentication/Auth";
import { getMe } from "../../services/auth";
import { setUser } from "../../redux/actions/user";
import { getSyncedOrganizations } from "../../services/sync";
import { setOrgs } from "../../redux/actions/sync";
import { getNavigation } from "../../services/common";
import { setNav } from "../../redux/actions/sidebar";
import MyModal from "../../components/molecule/Modal";
import ConnectTemplate from "./ConnectTemplate";
import ConnectModal from "./ConnectModal";
import { useSelector, useDispatch } from "react-redux";
import { parseHashString, authorize } from "./sharedFunctions";
// import { logout } from "../../redux/actions/auth";

const Connector = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedConnector, setSelectedConnector] = useState({
    title: "",
    image: "",
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const [connecting, setConnecting] = useState({
    connector: null,
    loading: false,
  });
  const user = useSelector((state) => state.user.authUser);
  const [connectors, setConnectors] = useState([]);

  useEffect(() => {
    if (user.connected_platform) {
      const connectItems = user.connected_platform.map(
        (item) => item.connector_id
      );

      setConnectors(connectItems);
    }
  }, [user]);

  const setConnector = (title, logo, index) => {
    if (connectors.includes(index)) {
      getOrgs();
    } else {
      setSelectedConnector({
        title,
        image: logo,
      });
      setShowModal(true);
    }
  };

  const getCards = () => [
    {
      status: connectors.includes(1) ? "Connected" : "Disconnected",
      image: Podio,
      text: "Podio",
    },
    // {
    //   image: Teletrac,
    //   text: "Teletrac",
    // },
    // {
    //   image: Shopify,
    //   text: "Shopify",
    // },
    // {
    //   image: Sales,
    //   text: "Salesforce",
    // },
    // {
    //   image: Wrike,
    //   text: "Wrike",
    // },
  ];

  const [cards, setCards] = useState(getCards());

  useEffect(() => {
    setCards(getCards());
    //eslint-disable-next-line
  }, [connectors]);

  const handleConnectCardClick = (item) => {
    setCards((prev) =>
      prev.map((currentItem) => {
        if (currentItem.text === item.text && currentItem.status) {
          // we are going to decide how to get the index for the setConnector later
          setConnector(item.text, item.image, 1);

          if (currentItem.status.toLowerCase() === "connected") {
            return {
              ...currentItem,
              isLoading: !currentItem.isLoading,
            };
          }
        }

        return currentItem;
      })
    );
  };

  const getNav = async () => {
    const response = await getNavigation();

    dispatch(setNav(response.menus));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getUser = useCallback(async () => {
    const response = await getMe();

    dispatch(setUser(response.data));
  });

  const getOrgs = async () => {
    try {
      const response = await getSyncedOrganizations();

      await dispatch(setOrgs(response.data));
      await getUser();
      await getNav();

      // await dispatch(setAuditOrg(newResponse.data));
      //Dispatch synced and unsynced
      // const authToken = localStorage.getItem("chosen_token");

      // dispatch(actions.getAllUnSyncedAccounts(authToken));

      if (response) {
        return history.push("/dashboard/sync");
      }
    } catch (error) {
      // dispatch(logout());
    }
  };

  const makeCall = useCallback(
    async (access, auth_type) => {
      try{
        
        const response = await authV2Connect(access, auth_type);
        if (response) {
          getUser();
        }else{
          return history.push("/connect");
        }
      }catch(err){
        return history.push("/connect");
      }

      
    },
    [getUser]
  );

  useEffect(() => {
    const auth_type = localStorage.getItem("auth_type");

    const hashString = window.location.hash.substr(1);

    if (hashString && auth_type === "podio") {
      const access = parseHashString(hashString);

      if (access["access_token"]) {
        access["user_id"] = access["ref[id]"];
        try{
          makeCall(access, auth_type);
          return history.push("/connect");
        }catch(err){
          return history.push("/connect");
        }

      }
    }
  }, [connecting, getUser, makeCall]);

  let name = ""

  if (user?.team_member) {
    name = user?.team_member?.name 
  } else {
    name = user?.name
  }

  return (
    <React.Fragment>
      <MyModal open={showModal} onClose={() => setShowModal(false)}>
        <ConnectModal
          info={selectedConnector}
          handleConnect={() =>
            authorize(selectedConnector, setConnecting, "/connect")
          }
          handleClose={() => setShowModal(false)}
        />
      </MyModal>

      <ConnectTemplate
        cards={cards}
        userName={name}
        onConnectCardClick={handleConnectCardClick}
        textColor="#8D2B0B"
      />
    </React.Fragment>
  );
};

export default Connector;
