import React from "react";
import RightSidebarTabs from "./Tabs";
import { toast } from "helpers/apiRequests";
import styles from "./RightSideBar.module.scss";
import useAsync from "components/templates/print-v2/hooks/useAsync";
import SplitButton from "../fields/EditorButtonGroup";
import { usePrintData } from "components/templates/print-v2/context/PrintProvider";
import {
  togglePrintButton,
  uploadTemplateToPodio,
} from "../../../printUtils/requests";
import Select from "react-select";


let PRINT_DOCUMENTS_DOMAIN;
if (window.location.host === "thatapp.io") {
  PRINT_DOCUMENTS_DOMAIN = "https://documents.thatapp.io/api/v2";
} else {
  PRINT_DOCUMENTS_DOMAIN = "https://test-api.thatapp.io/api/v2";
}

const RightSideBar = (props) => {
  const { onSelect } = props;
  const { execute, status } = useAsync(handlePrintButtonStatus, false);
  const { selectedItem, template } = usePrintData();
  const [value, setValue] = React.useState(0);

  const isPrintButtonActive = React.useMemo(
    () => template?.podio_options?.metadata?.is_button_active ?? false,
    [template?.podio_options?.metadata?.is_button_active]
  );

  function handlePrintButtonStatus() {
    return togglePrintButton({
      template_id: template?.id,
      status: !isPrintButtonActive ? "activate" : "deactivate",
    }).then(props.refreshTemplate);
  }

  const [isUploadingToPodio, setIsUploadingToPodio] = React.useState(false);

  const [selectedOption, setSelectedOption] = React.useState("");
  const [selectedProcessor, setSelectedProcessor] = React.useState(
    localStorage.getItem("selectedProcessor") || ""
  );

  function handleUpload(extension) {
    setIsUploadingToPodio(true);
    const payload = {
      template_id: template?.id,
      item_id: selectedItem?.value,
      extension: extension,
    };

    return uploadTemplateToPodio(payload)
      .then((res) => {
        if (res.success === true) {
          toast.notify("Uploaded Successfully");
        }
      })
      .finally(() => setIsUploadingToPodio(false));
  }

  function handlePreview(extension) {
    const authToken = localStorage.getItem("chosen_token");
    let uuid = window.crypto.randomUUID();

    return window.open(
      `${PRINT_DOCUMENTS_DOMAIN}/print/template/document/preview/${template?.id}/${selectedItem?.value}/${extension}?token=${authToken}&uuid=${uuid}`,
      "_blank"
    );
  }
  function handleDownload(extension) {
    const authToken = localStorage.getItem("chosen_token");
    let uuid = window.crypto.randomUUID();

    return window.open(
      `${PRINT_DOCUMENTS_DOMAIN}/print/template/document/download/${template?.id}/${selectedItem?.value}/${extension}?token=${authToken}&uuid=${uuid}`,
      "_blank"
    );
  }

  function handleSave(e) {
    e.preventDefault();
    props.handleSave();
  }
  const options = [
    { value: "snappy", label: "V1" },
    { value: "weasy", label: "V2" },
  ];
  return (
    <aside className={styles.right_container}>
      <form onSubmit={handleSave}>
        <RightSidebarTabs {...{ value, setValue, ...props }} />

        {value !== 2 ? (
          <div className={styles.buttons_container}>
            <div>
              <label>Select Document Processor</label>
              <Select
                options={options}
                value={options.find(
                  (option) => option.value === selectedProcessor
                )}
                onChange={(option) => {
                  setSelectedProcessor(option.value);
                  localStorage.setItem("selectedProcessor", option.value);
                  const selectedValue = option.value;
          
                  setSelectedOption(selectedOption);
                  onSelect(selectedValue);
                }}
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    padding: "5px",
                    marginBottom: "10px",
                  }),
                }}
              />
            </div>

            <button
              style={{ marginTop: "10px" }}
              type="submit"
              className="btn btn-success"
              disabled={props.savingTemplate}
            >
              {props.savingTemplate ? "Saving..." : "Save"}
            </button>
            <SplitButton
              onClick={handleUpload}
              label={isUploadingToPodio ? "Uploading..." : "Upload to Podio"}
              disabled={isUploadingToPodio || !selectedItem?.value}
              disabledOptions={[]}
            />
            <SplitButton
              onClick={handleDownload}
              label="Download"
              disabled={!selectedItem?.value}
              disabledOptions={[]}
            />
            <SplitButton
              onClick={handlePreview}
              label="Preview"
              disabled={!selectedItem?.value}
              disabledOptions={[]}
            />
            {isPrintButtonActive ? (
              <button
                className="btn btn-outline-danger"
                disabled={status === "pending"}
                onClick={execute}
              >
                {status === "pending"
                  ? "Deactivating..."
                  : "Deactivate Print Button"}
              </button>
            ) : (
              <button
                className="btn btn-outline-success"
                disabled={status === "pending"}
                onClick={execute}
              >
                {status === "pending" ? "Activating" : "Activate Print Button"}
              </button>
            )}
          </div>
        ) : null}
      </form>
    </aside>
  );
};

export default RightSideBar;
