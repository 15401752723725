/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, Fragment } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import "./Haven.css";
import HavenGrid from "./Grids/Grid";
import { setOrgs } from "../../redux/actions/sync";
import { connect } from "react-redux";
import Config from "../../utils/Config";
import {
  getSyncedOrganizations,
  setupHaven,
  disableHaven,
} from "../../services/sync";
import Dialog from "@material-ui/core/Dialog/Dialog";
// import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import EmptyState from "../../components/EmptyState";
// import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";
// import AuthoriseModal from "./Modals/AuthoriseModal";
import MyModal from "../../components/molecule/Modal";
import ConnectModal from "../Connector/ConnectModal";
import {
  authorizeFileHaven,
  parseHashString,
} from "../Connector/sharedFunctions";
import Podio from "../../assets/images/podioconnect.png";
import { fileHavenAuthV2Connect } from "../../helpers/Authentication/Auth";
import { getMe } from "../../services/auth";
import { setUser } from "../../redux/actions/user";
import { makeStyles } from "@material-ui/styles";
import { toast } from "helpers/apiRequests";
import DownloadHook from "./hooks/DownloadHook";
import Confirm from "../../components/Modal/Confirm";
import cancel from "../../assets/icons/cancel.svg";
import info from "../../assets/icons/info.svg";
import redInfo from "../../assets/icons/redInfo.svg";

const useStyles = makeStyles((theme) => ({
  modalHeader: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "5% 5% 0 5%",
  },
  headerText: {
    color: "black",
  },
  btnContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  btnDesc: {
    color: "#DE911D",
    marginTop: "3%",
  },
}));

const Haven = ({ _orgs }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  // const user = useSelector((state) => state.user.authUser);
  const [haven, setHaven] = useState([]);
  const [loading, setLoading] = useState(true);
  const [allProtected, setAllProtected] = useState(false);
  const [connectLoading, setConnectLoading] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState({
    open: false,
    data: null,
  });
  const [openModal, setOpenModal] = useState(false);
  const savedOrg = localStorage.getItem("selectedOrg");
  const [selectedOrg] = React.useState(JSON.parse(savedOrg));

  const closeAuthorizationModal = () => {
    setOpenModal(false);
  };
  const [selectedConnector] = useState({
    title: "Podio",
    image: Podio,
  });

  const [connecting, setConnecting] = useState({
    connector: null,
    loading: false,
  });

  const authorizeFirst = () => {
    setOpenModal(true);
  };

  const [confirmOpenDownload, setConfirmOpenDownload] = React.useState({
    open: false,
    data: null,
  });

  // const handleConfirmClickOpenDownload = (data) => {
  //   setConfirmOpenDownload({ open: true, data: data });
  // };

  const handleClickCloseDownload = () => {
    setConfirmOpenDownload({ open: false, data: null });
  };

  const { loading: downloadLoading, handleDownload } = DownloadHook();

  const startFileHaven = (org) => {
   
    setupHaven(`?org_id=${org.org_id}`)
      .then(async (resp) => {
        if (resp.status === true) {
          await getUser();
        }
        toast.notify("File haven has been activated successfully");
        getSyncedOrganizations().then((res) => {
          dispatch(setOrgs(res.data));
          window.location.reload();
          // props.setOrgs(res.data);
          
        });
      })
      .catch((err) => {
        console.log("[ERR]", err);
      });
  };

  useEffect(() => {
    let havenArray = [];
    let orgs;

    if (!Config.isEmpty(_orgs)) {
      orgs = _orgs;

    } else {
      getSyncedOrganizations().then((res) => {
        orgs = res.data;
        dispatch(setOrgs(orgs));
      });
    }

    if (orgs && Array.isArray(orgs)) {
      orgs.forEach((data) => {
        const org_name = data.name;
        const org_id = data.org_id;
        const file_haven = data.details ? data.details.file_haven : null;

        havenArray.push({
          id: org_id,
          organization: org_name,
          haven: file_haven === null ? "Unprotected" : "Protected",
          _hidden_status: file_haven,
          authorizeFirst,
        });
      });
      setLoading(false);
    } else {
      setLoading(false);
    }

    setHaven(havenArray);
    //eslint-disable-next-line
  }, []);

  const confirm_handleClose = () => {
    setConfirmOpen({ open: false, data: null });
  };

  const confirmProtectOrgs = async () => {
    setLoading(true);

    try {
      // Collect all organization IDs
      const orgIds = haven.map((r) => r.id);
      const orgNames = haven.map((r) => r.organization).join(", ");

      // Setup haven for all organizations at once
      // await setupHaven(`?org_ids=${orgIds.join(',')}`);

      await setupHaven(orgIds);

      // Notify success for each organization
      toast.notify(`${orgNames} haven has been activated successfully`);

      // Fetch the latest organizations data
      const _r = await getSyncedOrganizations();
      const orgs = _r.data;

      let havenArray = [];

      if (orgs && Array.isArray(orgs)) {
        orgs.forEach((data) => {
          const org_name = data.name;
          const org_id = data.org_id;
          const file_haven = data.details ? data.details.file_haven : null;

          havenArray.push({
            id: org_id,
            organization: org_name,
            haven: file_haven === null ? "Unprotected" : "Protected",
            _hidden_status: file_haven,
            authorizeFirst,
          });
        });
      }

      setHaven(havenArray);
      setOrgs(orgs);
    } catch (err) {
      // Handle errors if needed
    } finally {
      setLoading(false);
      setConfirmOpen({ open: false, data: null });
    }
  };

  const allOrgsProtected = haven.every((org) => org.haven === "Protected");

  useEffect(() => {
    if (allOrgsProtected) {
      setAllProtected(true);
      // toast.notify("All organizations have been protected successfully!");
    } else {
      setAllProtected(false);
    }
  }, [allOrgsProtected]);

  const confirmDeleteOrgs = async () => {
    setLoading(true);

    try {
      // Collect all organization IDs
      const orgIds = haven.map((r) => r.id);
      const orgNames = haven.map((r) => r.organization).join(", ");
      setConfirmOpen(false)
      // Setup haven for all organizations at once
      // await setupHaven(`?org_ids=${orgIds.join(',')}`);

      await disableHaven(orgIds);

      // Notify success for each organization
      toast.notify(`${orgNames} haven has been deactivated successfully`);

      // Fetch the latest organizations data
      const _r = await getSyncedOrganizations();
      const orgs = _r.data;

      let havenArray = [];

      if (orgs && Array.isArray(orgs)) {
        orgs.forEach((data) => {
          const org_name = data.name;
          const org_id = data.org_id;
          const file_haven = data.details ? data.details.file_haven : null;

          havenArray.push({
            id: org_id,
            organization: org_name,
            haven: file_haven === null ? "Unprotected" : "Protected",
            _hidden_status: file_haven,
            authorizeFirst,
          });
        });
      }

      setHaven(havenArray);
      setOrgs(orgs);
    } catch (err) {
      // Handle errors if needed
    } finally {
      setLoading(false);
      setConfirmOpen({ open: false, data: null });
    }
  };

  // const confirmProtectOrgs = () => {
  //   setLoading(true);
  //   haven.forEach((r) => {
  //     setupHaven(`?org_id=${r.id}`)
  //       .then(() => {
  //         toast.notify(
  //           r.organization + " haven has been activated successfully"
  //         );
  //         let havenArray = [];

  //         getSyncedOrganizations().then((_r) => {
  //           const orgs = _r.data;

  //           if (orgs && Array.isArray(orgs)) {
  //             orgs.forEach((data) => {
  //               const org_name = data.name;
  //               const org_id = data.org_id;
  //               const file_haven = data.details
  //                 ? data.details.file_haven
  //                 : null;

  //               havenArray.push({
  //                 id: org_id,
  //                 organization: org_name,
  //                 haven: file_haven === null ? "Unprotected" : "Protected",
  //                 _hidden_status: file_haven,
  //                 authorizeFirst,
  //               });
  //             });
  //             setLoading(false);
  //           } else {
  //             setLoading(false);
  //           }

  //           setHaven(havenArray);
  //           setOrgs(orgs);
  //         });
  //       })
  //       .catch((err) => {});
  //   });
  //   setConfirmOpen({ open: false, data: null });
  // };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getUser = useCallback(async () => {
    const response = await getMe();
    setConnectLoading(true);
    dispatch(setUser(response.data));
    setConnectLoading(false);

    console.log(connectLoading);
  });

  const makeCall = useCallback(
    async (access, auth_type) => {
      setConnectLoading(true);
      const response = await fileHavenAuthV2Connect(access, auth_type);
      if (response) {
        getUser();
      }
    },
    [getUser]
  );

  useEffect(() => {
    const auth_type = localStorage.getItem("file_haven_auth_type");

    const hashString = window.location.hash.substr(1);

    if (hashString && auth_type === "podio") {
      const access = parseHashString(hashString);

      if (access["access_token"]) {
        access["user_id"] = access["ref[id]"];
        makeCall(access, auth_type);
        if (selectedOrg) {
          startFileHaven(selectedOrg);
          localStorage.removeItem("selectedOrg");
        }
        return history.push("/dashboard/haven");
      }
    }
  }, [connecting, getUser, makeCall]);

  // const handleBackButtonClick = () => {
  //   window.history.back();
  // };

  return (
    <div>
      {_orgs.length === 0 ? (
        <div className="file__haven_empty_container">
          <EmptyState />
        </div>
      ) : (
        <Fragment>
          <div className="file__haven">
            {/* <div>
              <button
                onClick={handleBackButtonClick}
                className={classes.backButton}
              >
                Back
              </button>
            </div> */}
            <div className="d-flex align-items-center mb-4">
              <div className="d-flex align-items-center mr-auto">
                <h3 className="fileHaven_title">File Haven</h3>
                {/* <h3 className="ml-3"> {">"}</h3>
                {
                  user && user.file_connection ?
                    <Button
                      color="success"
                      variant="text"
                      size="small"
                      disabled
                      className="ml-2 text-success"
                    >
                      <h4 className={classes.connect} style={{ fontSize: "20px" }}> {connectLoading ? <CircularProgress size={20} /> : "Connected"} </h4></Button> :
                    <Button
                      color="danger"
                      variant="text"
                      size="small"
                      disabled
                      className="ml-2 text-danger"
                    >
                      <h4 className={classes.connect} style={{ fontSize: "20px" }}>{connectLoading ? <CircularProgress size={20} /> : "Disconnected"}</h4></Button>
                } */}
              </div>

              {allProtected ? (
                <div>
                  {_orgs.length !== 0 && (
                    <button
                      className="file__haven__btn"
                      disabled={loading}
                      onClick={() => setConfirmOpen({ open: true, data: {} })}
                    >
                      Disable All Organisations
                    </button>
                  )}
                </div>
              ) : (
                <div>
                  {_orgs.length !== 0 && (
                    <button
                      className="file__haven__btn"
                      disabled={loading}
                      onClick={() => setConfirmOpen({ open: true, data: {} })}
                    >
                      Protect All Organisations
                    </button>
                  )}
                </div>
              )}

              {/* &nbsp;
                &nbsp;
              <button
                className="file__haven__btn"
                disabled={downloadLoading}
                onClick={() => handleConfirmClickOpenDownload({})}
              >
                Download All Organisations File
              </button> */}
            </div>
            {loading || downloadLoading ? (
              <div
                style={{ height: "70vh" }}
                className="d-flex align-items-center justify-content-center"
              >
                <CircularProgress size={40} />
              </div>
            ) : (
              <HavenGrid haven={haven} load={loading || downloadLoading} />
            )}

            <MyModal open={openModal} close={closeAuthorizationModal}>
              <ConnectModal
                info={selectedConnector}
                handleConnect={() =>
                  authorizeFileHaven(
                    selectedConnector,
                    setConnecting,
                    "/dashboard/haven"
                  )
                }
                handleClose={closeAuthorizationModal}
              />
            </MyModal>
            <Dialog
              open={confirmOpen.open}
              onClose={confirm_handleClose}
              aria-labelledby="form-dialog-title"
            >
              <div className={classes.modalHeader}>
                <div>
                  <img
                    onClick={confirm_handleClose}
                    alt="cancel"
                    src={cancel}
                  />
                </div>
              </div>

              {allProtected ? (
                <div className="text-center mt-3">
                  <img alt="info" src={redInfo} />
                </div>
              ) : (
                <div className="text-center mt-3">
                  <img alt="info" src={info} />
                </div>
              )}

              <DialogContent
                style={{ justifyContent: "center", display: "flex" }}
              >
                {allProtected ? (
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "black",
                      width: "80%",
                      textAlign: "center",
                    }}
                  >
                    Do you want to disable file haven for all organisations?
                  </p>
                ) : (
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "black",
                      width: "80%",
                      textAlign: "center",
                    }}
                  >
                    Do you want to enable file haven for all organisations?
                  </p>
                )}
              </DialogContent>

              <DialogActions>
                <div
                  style={{ textAlign: "center", width: "100%" }}
                  className="mt-2 pb-4"
                >
                  {allProtected ? (
                    <button
                      className="btn btn-default mr-2 "
                      onClick={() => confirmDeleteOrgs()}
                      style={{
                        color: "#fff",
                        background: "#E12D39",
                        width: "156px",
                        height: "45px",
                      }}
                    >
                      Yes Proceed
                    </button>
                  ) : (
                    <button
                      className="btn btn-default mr-2 "
                      onClick={() => confirmProtectOrgs()}
                      style={{
                        color: "#fff",
                        background: "#F7C948",
                        width: "156px",
                        height: "45px",
                      }}
                    >
                      Yes Proceed
                    </button>
                  )}
                  
                </div>
              </DialogActions>
            </Dialog>

            <Confirm
              className="confirm-modal-container"
              openModal={confirmOpenDownload.open}
              closeModal={handleClickCloseDownload}
              message={"Do you want to download all organisation files?"}
              action={() => {
                handleDownload();
                handleClickCloseDownload();
              }}
              title={"Download all organisation files"}
              toggleModal={handleClickCloseDownload}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  _orgs: state.sync.orgs,
});

const mapDispatchToProps = (dispatch) => ({
  setOrgs: (data) => dispatch(setOrgs(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Haven);
