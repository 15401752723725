import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import VisibilityIcon from "@material-ui/icons/Visibility";
import LockIcon from "@material-ui/icons/Lock";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";

import {
  deleteHaven,
  getSyncedOrganizations,
  // setupHaven,
  enableHaven,
} from "../../../services/sync";
import { toast } from "helpers/apiRequests";
import { setOrgs } from "../../../redux/actions/sync";
import { connect, useDispatch } from "react-redux";
import DownloadHook from "../hooks/DownloadHook";
import Confirm from "../../../components/Modal/Confirm";
import cancel from "../../../assets/icons/cancel.svg";
import info from "../../../assets/icons/info.svg";
import redInfo from "../../../assets/icons/redInfo.svg";

const useStyles = makeStyles((theme) => ({
  modalHeader: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "5% 5% 0 5%",
  },
  headerText: {
    color: "black",
  },
  btnContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  btnDesc: {
    color: "#DE911D",
    marginTop: "3%",
  },
}));
const ActionsRenderer = (props) => {
  const classes = useStyles();

  const history = useHistory();
  const dispatch = useDispatch();
  const [confirmOpen, setConfirmOpen] = React.useState({
    open: false,
    data: null,
  });
  const [confirmOpenDownload, setConfirmOpenDownload] = React.useState({
    open: false,
    data: null,
  });

  const { handleDownload } = DownloadHook();

  const handleConfirmClickOpenDownload = (data) => {
    setConfirmOpenDownload({ open: true, data: data });
  };

  const handleClickCloseDownload = () => {
    setConfirmOpenDownload({ open: false, data: null });
  };

  const [confirmOpenDelete, setConfirmOpenDelete] = React.useState({
    open: false,
    data: null,
  });

  const handleConfirmClickOpenDelete = (data) => {
    setConfirmOpenDelete({ open: true, data: data });
  };

  const confirm_handleClose = () => {
    setConfirmOpen({ open: false, data: null });
  };

  const confirm_handleCloseDelete = () => {
    setConfirmOpenDelete({ open: false, data: null });
  };

  const handleRouting = (data) => {
    history.push(`/dashboard/view/${data}`);
  };

  const startFileHaven = (org) => {
    enableHaven(`?org_ids[]=${org.org_id}`)
      .then(() => {
        toast.notify("File haven has been activated successfully");
        getSyncedOrganizations().then((res) => {
          dispatch(setOrgs(res.data));
          window.location.reload();
          // props.setOrgs(res.data);
        });
      })
      .catch((err) => {});
  };

  const startFileHavenDeletion = (org) => {
    deleteHaven(`?org_ids[]=${org.org_id}`)
      .then((res) => {
        toast.notify("File haven has been deactivated successfully");
        getSyncedOrganizations().then((res) => {
          dispatch(setOrgs(res.data));
          window.location.reload();
          // props.setOrgs(res.data);
        });
      })
      .catch((err) => {});
  };

  // const startFileHavenDeletion = (orgs) => {
  //   // Extract org_ids and convert them into a query string
  //   const orgIds = orgs.map(org => org.org_id);
  //   const queryString = orgIds.map(id => `org_ids[]=${id}`).join('&');
  
  //   // Call deleteHaven with the updated query string
  //   deleteHaven(`?${queryString}`)
  //     .then((res) => {
  //       toast.notify("File haven has been deactivated successfully");
  //       getSyncedOrganizations().then((res) => {
  //         dispatch(setOrgs(res.data));
  //         window.location.reload();
  //         // props.setOrgs(res.data);
  //       });
  //     })
  //     .catch((err) => {
  //       console.error(err); // Optional: log the error for debugging purposes
  //     });
  // };
  

  const data = typeof props["data"] !== "undefined" ? props["data"] : null;

  const handleConfirmClickOpen = (data) => {

    localStorage.setItem("selectedOrg", JSON.stringify(data));
    if (!props.user.file_connection) {
      data.authorizeFirst();
    } else {
      setConfirmOpen({ open: true, data: data });
    }
  };

  return (
    <React.Fragment>
      {data !== null ? (
        <React.Fragment>
          {data._hidden_status === 1 ? (
            <React.Fragment>
              <Tooltip title="View">
                <IconButton
                  onClick={() => handleRouting(data.id)}
                  aria-label="View"
                >
                  <VisibilityIcon style={{ color: "#FFF" }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Disable Protection">
                <IconButton
                  aria-label="Diasble Protection"
                  onClick={() =>
                    handleConfirmClickOpenDelete({
                      org_id: data.id,
                      org_name: data.organization,
                    })
                  }
                >
                  <LockOpenIcon style={{ color: "#FFF" }} />
                </IconButton>
              </Tooltip>

              <Tooltip title="Download">
                <IconButton
                  onClick={() =>
                    handleConfirmClickOpenDownload({
                      org_id: data.id,
                      org_name: data.organization,
                    })
                  }
                  aria-label="Download"
                >
                  <CloudDownloadIcon style={{ color: "#FFF" }} />
                </IconButton>
              </Tooltip>
            </React.Fragment>
          ) : (
            <>
              <Tooltip title="Enable Protection">
                <IconButton
                  onClick={() =>
                    handleConfirmClickOpen({
                      org_id: data.id,
                      org_name: data.organization,
                      authorizeFirst: data.authorizeFirst,
                    })
                  }
                  aria-label="Enable Protection"
                >
                  <LockIcon style={{ color: "#FFF" }} />
                </IconButton>
              </Tooltip>
            </>
          )}
        </React.Fragment>
      ) : null}

      <Confirm
        className="confirm-modal-container"
        openModal={confirmOpenDownload.open}
        closeModal={handleClickCloseDownload}
        message={
          "Are you sure you want to download all the files for this organization?"
        }
        action={() => {
          handleDownload(confirmOpenDownload.data);
          handleClickCloseDownload();
        }}
        title={`Download ${
          confirmOpenDownload.data && confirmOpenDownload.data.org_name
        } data`}
        toggleModal={handleClickCloseDownload}
      />

      <Dialog
        open={confirmOpen.open}
        onClose={confirm_handleClose}
        aria-labelledby="form-dialog-title"
      >
        <div className={classes.modalHeader}>
          <div>
            <img onClick={confirm_handleClose} alt="cancel" src={cancel} />
          </div>
        </div>

        <div className="text-center mt-3">
          <img alt="info" src={info} />
        </div>
        <DialogContent style={{ justifyContent: "center", display: "flex" }}>
          <p
            style={{
              fontSize: "14px",
              fontWeight: "600",
              color: "black",
              width: "80%",
              textAlign: "center",
            }}
          >
            Are you sure you want to enable file haven?
          </p>
        </DialogContent>

        <DialogActions>
          <div
            style={{ textAlign: "center", width: "100%" }}
            className="mt-2 pb-4"
          >
            <button
              className="btn btn-default mr-2 "
              onClick={() => {
                startFileHaven(confirmOpen.data);
                confirm_handleClose();
              }}
              style={{
                color: "#fff",
                background: "#F7C948",
                width: "156px",
                height: "45px",
              }}
            >
              Yes Proceed
            </button>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        open={confirmOpenDelete.open}
        onClose={confirm_handleCloseDelete}
        aria-labelledby="form-dialog-title"
      >
        <div className={classes.modalHeader}>
          <div>
            <img
              onClick={confirm_handleCloseDelete}
              alt="cancel"
              src={cancel}
            />
          </div>
        </div>

        <div className="text-center mt-3">
          <img alt="info" src={redInfo} />
        </div>
        <DialogContent style={{ justifyContent: "center", display: "flex" }}>
          <p
            style={{
              fontSize: "14px",
              fontWeight: "600",
              color: "black",
              width: "80%",
              textAlign: "center",
            }}
          >
            Are you sure you want to deactivate file haven?
          </p>
        </DialogContent>

        <DialogActions>
          <div
            style={{ textAlign: "center", width: "100%" }}
            className="mt-2 pb-4"
          >
            <button
              className="btn btn-default mr-2 "
              onClick={() => {
                startFileHavenDeletion(confirmOpenDelete.data);
                confirm_handleCloseDelete();
              }}
              style={{
                color: "#fff",
                background: "#E12D39",
                width: "156px",
                height: "45px",
              }}
            >
              Yes Proceed
            </button>
          </div>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  _orgs: state.sync.orgs,
  user: state.user.authUser,
});

const mapDispatchToProps = (dispatch) => ({
  setOrgs: (data) => dispatch(setOrgs(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionsRenderer);
