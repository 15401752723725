/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import { AgGridReact } from "ag-grid-react";
// import * as AllModules from "ag-grid-enterprise";
// import { ModuleRegistry } from "ag-grid-enterprise";
import { createMember } from "../../../redux/actions/tmail";
import { useDispatch } from 'react-redux';
import AddMembersAction from "./utility/AddMembersAction";
import { toast } from "helpers/apiRequests";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router";
import axios from '../../../config';

// ModuleRegistry.registerModules(Object.keys(AllModules));
const AddMemberGrid = (props) => {
    const data = props.location.state;
    const filterParams = {
        filterOptions: ["contains", "notContains"],
        debounceMs: 0,
        caseSensitive: false,
        suppressAndOrCondition: true,
    };
    const [selectedRow, setSelectedRow] = useState([]);
    const [saveLoading, setSaveLoading] = useState(false);
    const [members, setMembers] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory();

    function formatRole(string) {
        const value = string.value;
        return value.charAt(0).toLocaleUpperCase() + value.slice(1);
    }

    const changeIsLoadingSaveApp = () => {
        setSaveLoading(false)
    }

    const handleClose = () => {
        history.goBack();
    }

    const [users] = useState({
        columns: [
            {
                headerName: "Name",
                field: "name",
                filter: "agTextColumnFilter",
                filterParams,
                checkboxSelection: true,
            },
            {
                headerName: "Email",
                field: "email",
                filter: "agTextColumnFilter",
                filterParams,
            },
            {
                headerName: "Role",
                field: "role",
                filter: "agTextColumnFilter",
                filterParams,
                valueFormatter: formatRole
            },
            {
                headerName: "Assign Role",
                field: "actions",
                cellRenderer: "imitateAction"
            },
        ],
        data: [],
    });

    const defaultColDef = {
        flex: 1,
        minWidth: 100,
        filter: true,
        sortable: true,
        resizable: true,
    };

    const getSpaceMembers = async () => {
        try {
            setSaveLoading(true);
            let res = await axios.get(`/sync/podio/space-members/list?space_id=${data.spaceId}`);
           
            const response = res.data;
            const resultdata = response.members.map(member => {
                return {
                    ...member,
                    spaceLink: response.space.space_link,
                    space_name: response.space.space_name
                }
            });
            setMembers(resultdata);
            setSaveLoading(false);

        } catch (e) {
            setSaveLoading(false);
        }
    };

    useEffect(()=>{
        getSpaceMembers();
    }, [])
    const autoGroupColumnDef = { minWidth: 200 };
    const frameworks = {
        imitateAction: AddMembersAction,
    };
    const paginationPageSize = 100;
    const cacheBlockSize = 100;

    const onSelectionChanged = params => {
        setSelectedRow(params.api.getSelectedRows());
    }

    const createNewMember = () => {

        const unSetRole = selectedRow.filter(member => {
            if(member.role === "regular"){
                return member;
            }
            return null;
        });
        const errors = unSetRole.filter(unset => unset !== null);
        if(errors.length > 0){
            toast.notify("Some members were not assigned a role. Please assign role", { type: "error" });
        }
       
        const payload = {
            tmail_app_id: data.appId,
            podio_users: selectedRow
        };
        dispatch(createMember(payload,changeIsLoadingSaveApp, handleClose));
        setSelectedRow([]);
    }

    if (saveLoading) {
       return <center>
            <CircularProgress />
        </center>
    }

    return (
        <div className="p-24 pb-5">
            <div className="d-flex justify-content-between align-items-center mb-4">
                <div className="d-flex ">
                    <h4 style={{ fontWeight: `bold`, marginBottom: `15px` }}>
                        Add WorkSpace Members
                    </h4>
                </div>
                <div className="d-flex">
                    {
                        selectedRow.length > 0 ? <button
                            type='button'
                            style={{
                                color: "#fff",
                                background: "#F7C948",
                            }}
                            className="btn btn-default"
                            onClick={createNewMember}
                        >Add members</button> : null
                    }
                </div>
            </div>
            
            <div
                className="ag-theme-balham-dark"
                style={{
                    height: "700px",
                    width: "100%",
                    // padding: "20px"
                }}
            >
                <AgGridReact
                    // modules={Object.keys(AllModules)}
                    columnDefs={users.columns}
                    rowData={members}
                    defaultColDef={defaultColDef}
                    autoGroupColumnDef={autoGroupColumnDef}
                    frameworkComponents={frameworks}
                    enableRangeSelection={true}
                    animateRows={true}
                    rowGroupPanelShow="always"
                    rowSelection="multiple"
                    pagination={true}
                    paginationPageSize={paginationPageSize}
                    paginationAutoPageSize={true}
                    paginateChildRows={true}
                    cacheBlockSize={cacheBlockSize}
                    debug={true}
                    sideBar={true}
                    enableCellTextSelection={true}
                    suppressAggFuncInHeader={true}
                    enableFilter={true}
                    enableSorting={true}
                    onSelectionChanged={onSelectionChanged}
                >
                </AgGridReact>
            </div>
        </div>
    );
};

export default AddMemberGrid;
