import React from "react";
import { LinearProgress } from "@material-ui/core";

const AppFieldInput = (props) => {
  const {
    option,
    EmailOptions,
    changeContactField,
    changeSubject,
    subJectOptions,
    changeRefApp,
    AppOptions,
    isloadingRefApp,
    subject,
    contactField,
    refApp,
    //InAppSetting
  } = props;
  return (
    <>
      {option === "two" ? (
        <>
          <div className="form-group mb-4 mt-4">
            <label htmlFor="email">Select Contact Field</label>
            <select
              className="form-control"
              value={contactField}
              onChange={(e) => changeContactField(e.target.value)}
            >
              <option>Select</option>
              {EmailOptions}
            </select>
          </div>

          <div className="form-group mb-4">
            <label htmlFor="subect">Subject for Emails</label>
            <select
              className="form-control"
              value={subject}
              onChange={(e) => changeSubject(e.target.value)}
            >
              <option>Select</option>
              {subJectOptions}
            </select>
          </div>
        </>
      ) : null}
      {option === "three" ? (
        <>
          <div className="form-group mb-4 mt-4">
            <label htmlFor="fieldEmail">
              Select Field that contains Email Address
            </label>
            <select
              className="form-control"
              value={contactField}
              onChange={(e) => changeContactField(e.target.value)}
            >
              <option>Select</option>
              {EmailOptions}
            </select>
          </div>

          <div className="form-group mb-4">
            <label htmlFor="subect">Subject for Emails</label>
            <select
              className="form-control"
              value={subject}
              onChange={(e) => changeSubject(e.target.value)}
            >
              <option>Select</option>
              {subJectOptions}
            </select>
          </div>
        </>
      ) : null}
      {option === "four" ? (
        <>
          <div className="form-group mb-4 mt-4">
            <label htmlFor="refApp">Select Referenced App</label>
            <select
              className="form-control"
              value={refApp}
              onChange={(e) => changeRefApp(e.target.value)}
            >
              <option>Select</option>
              {AppOptions}
            </select>
          </div>
          {isloadingRefApp ? (
            <LinearProgress />
          ) : (
            <div className="form-group mb-4 mt-4">
              <label htmlFor="fieldEmail">
                Select Field that contains Email Address
              </label>
              <select
                className="form-control"
                value={contactField}
                onChange={(e) => changeContactField(e.target.value)}
              >
                <option>Select</option>

                {EmailOptions}
              </select>
            </div>
          )}

          <div className="form-group mb-4">
            <label htmlFor="subect">Subject for Emails</label>
            <select
              className="form-control"
              value={subject}
              onChange={(e) => changeSubject(e.target.value)}
            >
              <option>Select</option>
              {subJectOptions}
            </select>
          </div>
        </>
      ) : null}
    </>
  );
};

export default AppFieldInput;
