import React, { useState, useEffect, Fragment, useRef } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useParams, useHistory } from "react-router-dom";
import {
  getApps,
  getSpace,
  getSpaces,
  createApp,
  iconSuggestions,
  updateApp,
  deleteApp,
} from "../../services/sync";
import { connect } from "react-redux";
// import AutoComplete from "../../components/atoms/AutoComplete";
import CustomButton from "../../components/atoms/CustomButton";
import Button from "../../components/atoms/Button";
// import AddIcon from "@material-ui/icons/Add";
// import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import ListIcon from "@material-ui/icons/List";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DialogContentText from "@material-ui/core/DialogContentText";
import Checkbox from "@material-ui/core/Checkbox";
import AppCardView from "./AppCardView";
import AppListView from "./AppListView";
import AppsIcon from "@material-ui/icons/Apps";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Select from "@material-ui/core/Select";
import { green } from "@material-ui/core/colors";
import CircularProgress from "@material-ui/core/CircularProgress";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { toast } from "helpers/apiRequests";
import Popover from "@material-ui/core/Popover";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import SearchIcon from "@material-ui/icons/Search";
import Avatar from "@material-ui/core/Avatar";
import "./app.css";
import RenderByPermission from "components/RenderByPermission";
import BackButton from "components/atoms/BackButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import searchIcon from "../../assets/icons/search.svg";

function a11yProps(index) {
  return {
    id: `new-app-${index}`,
    "aria-controls": `new-app-${index}`,
  };
}

const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "#fcaf3b",
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#fcaf3b",
      opacity: 1,
    },
    "&$selected": {
      color: "#fcaf3b",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#fcaf3b",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

// const useInputStyles = makeStyles((theme) => ({
//   label: {
//     display: "block",
//   },
//   input: {
//     borderRadius: "5px",
//     border: ".5px solid #e1e1e1",
//     backgroundColor: "white",
//     padding: "10px",
//   },
//   listbox: {
//     width: 200,
//     margin: 0,
//     padding: 0,
//     zIndex: 1,
//     position: "absolute",
//     listStyle: "none",
//     backgroundColor: theme.palette.background.paper,
//     overflow: "auto",
//     maxHeight: 200,
//     border: "1px solid rgba(0,0,0,.25)",
//     li: {
//       padding: "10px",
//     },
//     '& li[data-focus="true"]': {
//       backgroundColor: "#4a8df6",
//       color: "white",
//       cursor: "pointer",
//       padding: "10px",
//     },
//     "& li:active": {
//       backgroundColor: "#2977f5",
//       color: "white",
//     },
//   },
//   "@media screen and (max-width: 768px)": {
//     input: {
//       width: "100% !important",
//       marginTop: "5%",
//       paddingLeft: "3%",
//     },
//   },
// }));

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    marginRight: theme.spacing(1),
    textAlign: "center",
    float: "right",
    background: "#FFF3C4",
    borderRadius: "3px",
    color: "#DE911D",
  },
  root: {
    "& hr": {
      margin: theme.spacing(0, 0.5),
      backgroundColor: "#50504c47",
      height: "50px",
    },
  },
  required: {
    "&::after": {
      content: '"*"',
      color: "#fc3b68f2",
    },
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  link: {
    "&:hover": {
      color: "#fcaf3b",
      textDecoration: "none",
    },
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "30px",
    textDecoration: "none",
  },
  control: {
    padding: theme.spacing(2),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  body1Text: {
    fontSize: "14px",
  },
  checkboxLabel: {
    fontSize: "14px",
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  searchIconSection: {
    display: "flex",
    padding: theme.spacing(2),
    flexDirection: "column",
  },
  popover: {
    width: theme.spacing(40),
    borderRadius: theme.shape.borderRadius,
  },
  paper: {
    position: "sticky",
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  inputCont: {
    border: "1px solid #E1E1E1",
    display: "flex",
    alignItems: "center",
    paddingRight: "3%",
    borderRadius: "5px",
    margin: "0",
    marginRight: "1%",
    width: "32%",
  },
  iconButton: {
    padding: 10,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  iconSvg: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: theme.palette.text.secondary,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[1],
    },
  },
  results: {
    fontSize: "13px",
    marginTop: "10px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  "@media screen and (max-width: 768px)": {
    root: {
      flexDirection: "column",
    },
    inputSelect: {
      width: "100% !important",
      marginTop: "5%",
      paddingLeft: "3%",
    },
    inputCont: {
      width: "100% !important",
      
    }
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    fontFamily: "Mulish",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "30px",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

let Icons = (props) => {
  const { icons, classes, handleSelectAppIcon } = props;

  return (
    <Fragment>
      <div className="row">
        {icons.map((icon, key) => {
          return (
            <div key={icon.key} className="col-2 pt-2">
              <Avatar
                tabIndex={-1}
                className={classes.iconSvg}
                title={icon.key}
                src={`https://d2cmuesa4snpwn.cloudfront.net/images/emails/app-icons/${icon.key}.png`}
                alt="Icon"
                onClick={() => handleSelectAppIcon(icon)}
              />
            </div>
          );
        })}
      </div>

      <hr />
    </Fragment>
  );
};

let preLoadedAppIcons = [
  {
    key: 19,
    link: "https://d2cmuesa4snpwn.cloudfront.net/images/emails/app-icons/19.png",
  },
  {
    key: 39,
    link: "https://d2cmuesa4snpwn.cloudfront.net/images/emails/app-icons/39.png",
  },
  {
    key: 283,
    link: "https://d2cmuesa4snpwn.cloudfront.net/images/emails/app-icons/283.png",
  },
  {
    key: 62,
    link: "https://d2cmuesa4snpwn.cloudfront.net/images/emails/app-icons/62.png",
  },
  {
    key: 251,
    link: "https://d2cmuesa4snpwn.cloudfront.net/images/emails/app-icons/251.png",
  },
  {
    key: 289,
    link: "https://d2cmuesa4snpwn.cloudfront.net/images/emails/app-icons/289.png",
  },
  {
    key: 200,
    link: "https://d2cmuesa4snpwn.cloudfront.net/images/emails/app-icons/200.png",
  },
  {
    key: 170,
    link: "https://d2cmuesa4snpwn.cloudfront.net/images/emails/app-icons/170.png",
  },
  {
    key: 22,
    link: "https://d2cmuesa4snpwn.cloudfront.net/images/emails/app-icons/22.png",
  },
  {
    key: 11,
    link: "https://d2cmuesa4snpwn.cloudfront.net/images/emails/app-icons/11.png",
  },
  {
    key: 37,
    link: "https://d2cmuesa4snpwn.cloudfront.net/images/emails/app-icons/37.png",
  },
  {
    key: 180,
    link: "https://d2cmuesa4snpwn.cloudfront.net/images/emails/app-icons/180.png",
  },
  {
    key: 181,
    link: "https://d2cmuesa4snpwn.cloudfront.net/images/emails/app-icons/181.png",
  },
  {
    key: 31,
    link: "https://d2cmuesa4snpwn.cloudfront.net/images/emails/app-icons/31.png",
  },
  {
    key: 175,
    link: "https://d2cmuesa4snpwn.cloudfront.net/images/emails/app-icons/175.png",
  },
  {
    key: 176,
    link: "https://d2cmuesa4snpwn.cloudfront.net/images/emails/app-icons/176.png",
  },
  {
    key: 192,
    link: "https://d2cmuesa4snpwn.cloudfront.net/images/emails/app-icons/192.png",
  },
  {
    key: 45,
    link: "https://d2cmuesa4snpwn.cloudfront.net/images/emails/app-icons/45.png",
  },
  {
    key: 55,
    link: "https://d2cmuesa4snpwn.cloudfront.net/images/emails/app-icons/55.png",
  },
  {
    key: 123,
    link: "https://d2cmuesa4snpwn.cloudfront.net/images/emails/app-icons/123.png",
  },
  {
    key: 138,
    link: "https://d2cmuesa4snpwn.cloudfront.net/images/emails/app-icons/138.png",
  },
  {
    key: 44,
    link: "https://d2cmuesa4snpwn.cloudfront.net/images/emails/app-icons/44.png",
  },
];

const App = ({ orgs, user }) => {
  const history = useHistory();
  const classes = useStyles();
  // const inputClasses = useInputStyles();
  const params = useParams();
  const [selectedApp, setSelectedApp] = useState(null);
  const [loading, setLoading] = useState(true);
  const [organization, setOrg] = useState();
  const [workspace, setWorkspace] = useState({});
  const [workspaces, setWorkspaces] = useState([]);
  const [newApp, setNewApp] = useState(false);
  const [editCurrentApp, setEditCurrentApp] = useState(false);
  const [deleteCurrentApp, setDeleteCurrentApp] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");

  const [appView, setAppView] = useState("card");
  const [apps, setApps] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [appIcons, setAppIcons] = useState(preLoadedAppIcons);

  const [searchIconValue, setSearchIconValue] = useState("");
  const [selectedAppIcon, setSelectedAppIcon] = useState(176);

  const [appName, setAppName] = useState("");
  const [itemName, setItemName] = useState("");
  const [appType, setAppType] = useState("standard");
  const [appDescription, setAppDescription] = useState("");
  const [appUsage, setAppUsage] = useState("");

  const [postNewItemsToStream, setPostNewItemsToStream] = useState(false);
  const [postItemEditsToStream, setPostItemEditsToStream] = useState(false);
  const [disableNotifications, setDisableNotifications] = useState(false);
  const [membersAddItem, setMembersAddItem] = useState(false);
  const [membersEditItem, setMembersEditItem] = useState(false);
  const [disableComments, setDisableComments] = useState(false);
  const [allowFiveStarRating, setAllowFiveStarRating] = useState(false);
  const [fiveStarRatingLabel, setFiveStarRatingLabel] = useState("");
  const [allowItemThumbs, setAllowItemThumbs] = useState(false);
  const [itemThumbsLabel, setItemThumbsLabel] = useState("");
  const [rsvp, setRsvp] = useState(false);
  const [rsvpLabel, setRsvpLabel] = useState("");
  const [yesNo, setYesNo] = useState(false);
  const [yesNoLabel, setYesNoLabel] = useState("");
  const [approveItem, setApproveItem] = useState(false);
  const [allowAttachments, setAllowAttachments] = useState(false);
  const [createAppLoading, setCreateAppLoading] = useState(false);

  const [appNameValidation, setAppNameValidation] = useState(false);
  const [appTypeMeetingValidation, setAppTypeMeetingValidation] =
    useState(false);
  const [appDescriptionValidation, setAppDescriptionValidation] =
    useState(false);
  const [fiveStarValidation, setFiveStarValidation] = useState(false);
  const [itemThumbsValidation, setItemThumbsValidation] = useState(false);
  const [rsvpValidation, setRsvpValidation] = useState(false);
  const [yesNoValidation, setYesNoValidation] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const [meetingTime, setMeetingTime] = useState("");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? "simple-popover" : undefined;

  useEffect(() => {
    setLoading(true);

    const getOrg = async () => {
      let response =
        orgs?.length > 0
          ? orgs.find((org) => parseInt(org.org_id) === parseInt(params.org_id))
          : {};
      setOrg(response);
    };

    const fetchSpace = async () => {
      let query = `?org_id=${params.org_id}&space_id=${params.id}`;
      let response = await getSpace(query);
      let spaces = await getSpaces(params.org_id);

      setWorkspaces(spaces.data);
      if (response.data) {
        setWorkspace(response.data);
      }
    };

    const fetchApps = async () => {
      let query = `?org_id=${params.org_id}&space_id=${params.id}`;
      let response = await getApps(query);
      let data = response?.data?.data
        ? response.data.data.map((v) => ({ ...v, display: true }))
        : [];
      setApps(data);

      setLoading(false);
    };

    getOrg();
    fetchSpace();
    fetchApps();
  }, [orgs, params.id, params.org_id]);

  // const openCreateDialog = () => {
  //   setNewApp(true);
  // };

  const closeCreateDialog = () => {
    setNewApp(false);
    resetAppData();
    setSelectedApp(null);
  };

  const openEditDialog = (appId) => {
    setEditCurrentApp(true);
    let app = apps.find((x) => parseInt(x.app_id) === parseInt(appId));

    setAppName(app.config.name);
    setItemName(app.config.item_name);
    setAppType(app.config.type);
    setAppDescription(app.config.description);

    setAppUsage(app.config.usage);
    setMeetingTime("");
    setSelectedAppIcon(app.config.icon_id ? app.config.icon_id : 176);
    setPostNewItemsToStream(app.config.silent_creates);
    setPostItemEditsToStream(app.config.silent_edits);
    setDisableNotifications(app.config.disable_notifications);
    setMembersAddItem(app.config.allow_create);
    setMembersEditItem(app.config.allow_edit);
    setDisableComments(app.config.allow_comments);
    setAllowFiveStarRating(app.config.fivestar);
    setFiveStarRatingLabel(
      app.config.fivestar_label ? app.config.fivestar_label : ""
    );
    setAllowItemThumbs(app.config.thumbs);
    setItemThumbsLabel(app.config.thumbs_label ? app.config.thumbs_label : "");
    setRsvp(app.config.rsvp);
    setRsvpLabel(app.config.rsvp_label ? app.config.rsvp_label : "");
    setYesNo(app.config.yesno);
    setYesNoLabel(app.config.yesno_label ? app.config.yesno_label : "");
    setApproveItem(app.config.approved);
    setAllowAttachments(app.config.allow_attachments);
    setCreateAppLoading(false);
    setAppNameValidation(false);
    setAppDescriptionValidation(false);
    setFiveStarValidation(false);
    setItemThumbsValidation(false);
    setRsvpValidation(false);
    setYesNoValidation(false);
    setAppTypeMeetingValidation(false);
  };

  const closeEditDialog = () => {
    setEditCurrentApp(false);
    resetAppData();
  };

  const openDeleteDialog = (app_id) => {
    setDeleteCurrentApp(true);
  };

  const closeDeleteDialog = () => {
    setDeleteCurrentApp(false);
  };

  const handleDeleteApp = async (e) => {
    e.preventDefault();
    setCreateAppLoading(true);
    try {
      let deleteResponse = await deleteApp(
        `?org_id=${organization.org_id}&app_id=${selectedApp}`
      );

      if (deleteResponse.message) {
        toast.notify(deleteResponse.message);
      }
    } catch (err) {}

    setCreateAppLoading(false);
    setLoading(true);

    const fetchApps = async () => {
      let query = `?org_id=${params.org_id}&space_id=${params.id}`;
      let response = await getApps(query);

      let data = response?.data?.data
        ? response.data.data.map((v) => ({ ...v, display: true }))
        : [];
      setApps(data);

      setLoading(false);
    };

    fetchApps();

    setDeleteCurrentApp(false);
    setSelectedApp(null);
    setLoading(false);
  };

  const handleSearchIcon = async (e) => {
    e.preventDefault();

    if (searchIconValue.length < 2) {
      return;
    }

    let searchResponse = await iconSuggestions(searchIconValue);

    if (searchResponse.data) {
      let newData = [];
      let result = JSON.parse(searchResponse.data);

      result.forEach((x) => {
        newData.push({
          key: x,
          link: `https://d2cmuesa4snpwn.cloudfront.net/images/emails/app-icons/${x}.png`,
        });
      });
      setAppIcons(newData);
    }
  };

  const handleEditApp = async (e) => {
    e.preventDefault();

    //Validation
    if (appName.length === 0 || parseInt(appName) === 0) {
      setAppNameValidation(true);
      return;
    } else {
      setAppNameValidation(false);
    }

    if (appDescription.length === 0 || parseInt(appDescription) === 0) {
      setAppDescriptionValidation(true);
      return;
    } else {
      setAppDescriptionValidation(false);
    }

    if (allowFiveStarRating && fiveStarRatingLabel.length === 0) {
      setFiveStarValidation(true);
      return;
    } else {
      setFiveStarValidation(false);
    }

    if (allowItemThumbs && itemThumbsLabel.length === 0) {
      setItemThumbsValidation(true);
      return;
    } else {
      setItemThumbsValidation(false);
    }

    if (rsvp && rsvpLabel.length === 0) {
      setRsvpValidation(true);
      return;
    } else {
      setRsvpValidation(false);
    }

    if (yesNo && yesNoLabel.length === 0) {
      setYesNoValidation(true);
      return;
    } else {
      setYesNoValidation(false);
    }

    if (appType === "meeting" && meetingTime.length === 0) {
      setAppTypeMeetingValidation(true);
      return;
    }

    setCreateAppLoading(true);

    try {
      let data = {
        org_id: organization.org_id,
        app_id: selectedApp,
        name: appName,
        type: appType,
        icon: `${selectedAppIcon}.png`,
        description: appDescription,
        usage: appUsage,
        allow_edit: membersEditItem,
        allow_create: membersAddItem,
        allow_comments: disableComments,
        silent_creates: postNewItemsToStream,
        silent_edits: postItemEditsToStream,
        disable_notifications: disableNotifications,
        fivestar: allowFiveStarRating,
        fivestar_label: fiveStarRatingLabel,
        approved: approveItem,
        thumbs: allowItemThumbs,
        thumbs_label: itemThumbsLabel,
        rsvp: rsvp,
        rsvp_label: rsvpLabel,
        yesno: yesNo,
        yesno_label: yesNoLabel,
        allow_attachments: allowAttachments,
        meeting_time: meetingTime,
      };

      let response = await updateApp(data);

      if (response.message) {
        toast.notify(response.message);
      }

      setLoading(true);

      const fetchApps = async () => {
        let query = `?org_id=${params.org_id}&space_id=${params.id}`;
        let response = await getApps(query);

        let data = response?.data?.data
          ? response.data.data.map((v) => ({ ...v, display: true }))
          : [];
        setApps(data);

        setLoading(false);
      };

      fetchApps();
      setCreateAppLoading(false);
      setEditCurrentApp(false);
      resetAppData();
      setSelectedApp(null);
    } catch (e) {
      setCreateAppLoading(false);
      setEditCurrentApp(false);
      resetAppData();
      setSelectedApp(null);
    }
  };

  // Create App
  const handleCreateApp = async (e) => {
    e.preventDefault();

    //Validation
    if (appName.length === 0 || parseInt(appName) === 0) {
      setAppNameValidation(true);
      return;
    } else {
      setAppNameValidation(false);
    }

    if (appDescription.length === 0 || parseInt(appDescription) === 0) {
      setAppDescriptionValidation(true);
      return;
    } else {
      setAppDescriptionValidation(false);
    }

    if (allowFiveStarRating && fiveStarRatingLabel.length === 0) {
      setFiveStarValidation(true);
      return;
    } else {
      setFiveStarValidation(false);
    }

    if (allowItemThumbs && itemThumbsLabel.length === 0) {
      setItemThumbsValidation(true);
      return;
    } else {
      setItemThumbsValidation(false);
    }

    if (rsvp && rsvpLabel.length === 0) {
      setRsvpValidation(true);
      return;
    } else {
      setRsvpValidation(false);
    }

    if (yesNo && yesNoLabel.length === 0) {
      setYesNoValidation(true);
      return;
    } else {
      setYesNoValidation(false);
    }

    if (appType === "meeting" && meetingTime.length === 0) {
      setAppTypeMeetingValidation(true);
      return;
    }

    setCreateAppLoading(true);

    try {
      let data = {
        org_id: organization.org_id,
        space_id: workspace.space_id,
        name: appName,
        type: appType,
        icon: `${selectedAppIcon}.png`,
        description: appDescription,
        usage: appUsage,
        allow_edit: membersEditItem,
        allow_create: membersAddItem,
        allow_comments: disableComments,
        silect_creates: postNewItemsToStream,
        silent_edits: postItemEditsToStream,
        disable_notifications: disableNotifications,
        fivestar: allowFiveStarRating,
        fivestar_label: fiveStarRatingLabel,
        approved: approveItem,
        thumbs: allowItemThumbs,
        thumbs_label: itemThumbsLabel,
        rsvp: rsvp,
        rsvp_label: rsvpLabel,
        yesno: yesNo,
        yesno_label: yesNoLabel,
        allow_attachments: allowAttachments,
        meeting_time: meetingTime,
      };

      let response = await createApp(data);

      if (response.message) {
        toast.notify(response.message);
      }

      setLoading(true);

      const fetchApps = async () => {
        let query = `?org_id=${params.org_id}&space_id=${params.id}`;
        let response = await getApps(query);

        let data = response?.data?.data
          ? response.data.data.map((v) => ({ ...v, display: true }))
          : [];
        setApps(data);

        setLoading(false);
      };

      fetchApps();
      setCreateAppLoading(false);
      setNewApp(false);
      resetAppData();
      setSelectedApp(null);
    } catch (e) {
      setCreateAppLoading(false);
      setNewApp(false);
      resetAppData();
      setSelectedApp(null);
    }
  };

  const resetAppData = () => {
    setAppName("");
    setItemName("");
    setAppType("standard");
    setAppDescription("");
    setAppUsage("");
    setSelectedAppIcon(176);

    setMeetingTime("");
    setPostNewItemsToStream(false);
    setPostItemEditsToStream(false);
    setDisableNotifications(false);
    setMembersAddItem(false);
    setMembersEditItem(false);
    setDisableComments(false);
    setAllowFiveStarRating(false);
    setFiveStarRatingLabel("");
    setAllowItemThumbs(false);
    setItemThumbsLabel("");
    setRsvp(false);
    setRsvpLabel("");
    setYesNo(false);
    setYesNoLabel("");
    setApproveItem(false);
    setAllowAttachments(false);
    setCreateAppLoading(false);
    setAppNameValidation(false);
    setAppDescriptionValidation(false);
    setFiveStarValidation(false);
    setItemThumbsValidation(false);
    setRsvpValidation(false);
    setYesNoValidation(false);
    setAppTypeMeetingValidation(false);
  };

  const handleAppView = (view) => {
    setAppView(view);
  };

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleWorkspaceRoute = (e) => {
    let workspace_id = e.target.value;
    let link = `/dashboard/organisation/${params.org_id}/workspaces/${workspace_id}/apps`;

    history.push(link);
  };
  const handleOrganisationRoute = (e) => {
    let org_id = e.target.value;
    let link = `/dashboard/workspaces/${org_id}`;

    history.push(link);
  };

  const fetchAllApps = async () => {
    let query = `?org_id=${params.org_id}&space_id=${params.id}`;
    let response = await getApps(query);

    let data = response?.data?.data
      ? response.data.data.map((v) => ({ ...v, display: true }))
      : [];
    setApps(data);

    setLoading(false);
  };
  const text = useRef("");

  const filterWorkspace = (newValue) => {
    const newApps = apps.filter((workspace) => {
      const regex = new RegExp(`${newValue}`, "gi");

      return (
        workspace.config.name.match(regex) ||
        workspace.app_id.toString().match(regex)
      );
    });
    setApps(newApps);
  };

  const clearFilter = () => {
    fetchAllApps();
  };

  const handleAppSearch = (e) => {
    if (text.current.value !== "") {
      filterWorkspace(e.target.value);
    } else {
      clearFilter();
    }
  };
  const handleSelectedApp = (id) => {
    setSelectedApp(id);
  };

  const handleSelectAppIcon = (icon) => {
    setSelectedAppIcon(icon.key);
    setAnchorEl(null);
  };

  if (loading) {
    return (
      <div className="load__audit">
        <div className="material_block">
          <svg
            className="spinner"
            width="65px"
            stroke="#F0B429"
            height="65px"
            viewBox="0 0 66 66"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              className="circle"
              fill="none"
              strokeWidth="6"
              strokeLinecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    );
  }

  return (
    <div className="">
      <div className="mb-4">
        <BackButton />
      </div>
      <div className="row align-items-center">
        <div className="col-md-12" style={{ width: "100%" }}>
          <Box
            style={{ alignItems: "center" }}
            display="flex"
            className={classes.root}
          >
            <Box display="flex" flexGrow={1} className="pt-2 input_cont">
              <div className={classes.inputCont} style={{}}>
                <input
                  style={{
                    display: "inline-block",
                    border: "none",
                    backgroundColor: "white",
                    padding: "0",
                  }}
                  type="text"
                  ref={text}
                  onChange={handleAppSearch}
                  placeholder="Search Apps..."
                />
                <img alt="" src={searchIcon} />
              </div>

              <select
                value={organization.name}
                onChange={(e) => handleOrganisationRoute(e)}
                className={classes.inputSelect}
                style={{
                  borderRadius: "5px",
                  padding: "10px",
                  border: ".5px solid #e1e1e1",
                  marginRight: "10px",
                  width: "32%",
                  backgroundColor: "white",
                }}
              >
                <option style={{ padding: "10px" }} value={organization.org_id}>
                  {organization.name}
                </option>
                {orgs.map((org, index) => {
                  if (org.org_id !== organization.org_id) {
                    return (
                      <option
                        style={{ padding: "10px" }}
                        key={index}
                        value={org.org_id}
                      >
                        {org.name}
                      </option>
                    );
                  }
                  return "";
                })}
              </select>

              <select
                value={workspace.space_name}
                onChange={(e) => handleWorkspaceRoute(e)}
                className={classes.inputSelect}
                style={{
                  borderRadius: "5px",
                  padding: "10px",
                  border: ".5px solid #e1e1e1",
                  marginRight: "10px",
                  width: "32%",
                  backgroundColor: "white",
                }}
              >
                <option style={{ padding: "10px" }} value={workspace.space_id}>
                  {workspace.name}
                </option>
                {workspaces.map((space, index) => {
                  if (space.space_id !== workspace.space_id) {
                    return (
                      <option
                        style={{ padding: "10px" }}
                        key={index}
                        value={space.space_id}
                      >
                        {space.name}
                      </option>
                    );
                  }
                  return "";
                })}
              </select>
            </Box>

            {isMobile ? (
              <Box style={{ width: "100%" }}>
                {appView === "card" ? (
                  <button
                    onClick={() => handleAppView("list")}
                    className="d-flex align-items-center mt-3 switch_btn"
                    style={{ fontSize: "14px" }}
                  >
                    Filter By: <span className="ml-2 filter_style">List</span>
                  </button>
                ) : (
                  <button
                    onClick={() => handleAppView("card")}
                    className="d-flex align-items-center mt-3 switch_btn"
                    style={{ fontSize: "14px" }}
                  >
                    Filter By: <span className="ml-2 filter_style">Card</span>
                  </button>
                )}
              </Box>
            ) : (
              <Box p={1}>
                {appView === "card" ? (
                  <div className="d-flex align-items-center mt-3">
                    <Tooltip title="Change to List View">
                      <ListIcon
                        fontSize="large"
                        style={{ cursor: "pointer", color: "#756f6f" }}
                        onClick={() => handleAppView("list")}
                      />
                    </Tooltip>
                    <h5 style={{ fontSize: "14px", marginLeft: "5px" }}>
                      List view
                    </h5>
                  </div>
                ) : (
                  <div className="d-flex align-items-center mt-3">
                    <Tooltip title="Change to Card View">
                      <AppsIcon
                        fontSize="large"
                        style={{ cursor: "pointer", color: "#756f6f" }}
                        onClick={() => handleAppView("card")}
                      />
                    </Tooltip>
                    <h5 style={{ fontSize: "14px", marginLeft: "5px" }}>
                      Grid view
                    </h5>
                  </div>
                )}
              </Box>
            )}

            {/* <Box>
              <CustomButton
                className={classes.button}
                size="large"
                tooltip="true"
                tooltip_title="Create New App"
                tooltip_placement="bottom"
                startIcon={<AddIcon />}
                onClick={openCreateDialog}
              >
                New App
              </CustomButton>
            </Box> */}
          </Box>
        </div>
      </div>
      <br />
      <div className="row">
        {appView === "card" ? (
          <AppCardView
            user={user}
            apps={apps}
            organization={organization}
            workspace={workspace}
            openEditDialog={openEditDialog}
            openDeleteDialog={openDeleteDialog}
            selectedApp={selectedApp}
            handleSelectedApp={handleSelectedApp}
          />
        ) : (
          <AppListView
            user={user}
            apps={apps}
            organization={organization}
            workspace={workspace}
            openEditDialog={openEditDialog}
            openDeleteDialog={openDeleteDialog}
            selectedApp={selectedApp}
            handleSelectedApp={handleSelectedApp}
          />
        )}
      </div>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={newApp}
        onClose={closeCreateDialog}
        aria-labelledby="form-dialog-title"
        className="dialog-box"
      >
        <DialogContent>
          <div className="head">
            <h3>Create New App</h3>
            <DialogTitle
              id="form-dialog-title"
              onClose={closeCreateDialog}
            ></DialogTitle>
          </div>
          <div>
            <AntTabs
              value={currentTab}
              onChange={handleChange}
              aria-label="add new app"
            >
              <AntTab label="General" {...a11yProps(0)} />
              <AntTab label="Advanced" {...a11yProps(1)} />
            </AntTabs>
            {currentTab === 0 ? (
              <Fragment>
                <Grid container style={{ paddingTop: "20px" }}>
                  <Grid item xs={4}>
                    <label
                      htmlFor="organization_id"
                      className={`${classes.required} label`}
                    >
                      App Name
                    </label>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      variant="outlined"
                      aria-describedby="App Name"
                      value={appName}
                      disabled={createAppLoading}
                      error={appNameValidation}
                      onChange={(e) => setAppName(e.target.value)}
                      margin="normal"
                      id="app_name"
                      type="text"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container style={{ paddingTop: "20px" }}>
                  <Grid item xs={4}>
                    <label htmlFor="organization_id" className={`label`}>
                      Item Name
                    </label>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      variant="outlined"
                      aria-describedby="App Name"
                      disabled={createAppLoading}
                      value={itemName}
                      onChange={(e) => setItemName(e.target.value)}
                      margin="normal"
                      id="app_name"
                      type="text"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container style={{ paddingTop: "20px" }}>
                  <Grid item xs={4}>
                    <label
                      htmlFor="app_type"
                      className={`${classes.required} label`}
                    >
                      App Type
                    </label>
                  </Grid>
                  <Grid item xs={8}>
                    <Select
                      native={true}
                      disabled={createAppLoading}
                      variant="outlined"
                      value={appType}
                      onChange={(e) => setAppType(e.target.value)}
                      inputProps={{
                        name: "app_type",
                        id: "filled-age-native-simple",
                      }}
                      fullWidth
                    >
                      <option value="standard">Standard</option>
                    </Select>
                    <p style={{ paddingTop: "1px" }}>
                      <InfoOutlinedIcon
                        fontSize="small"
                        style={{
                          cursor: "pointer",
                          color: "#127FBF",
                          marginLeft: "5px",
                          marginRight: "5px",
                          fontSize: "15px",
                        }}
                      />
                      The Podio default, useful for all types of apps
                    </p>
                  </Grid>
                </Grid>
                {appType === "meeting" ? (
                  <Grid container style={{ paddingTop: "5px" }}>
                    <Grid item xs={4}>
                      <label htmlFor="meeting_time" className={`label`}>
                        Meeting Time
                      </label>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        aria-describedby="Meeting Time"
                        disabled={createAppLoading}
                        error={appTypeMeetingValidation}
                        value={meetingTime}
                        onChange={(e) => setMeetingTime(e.target.value)}
                        // margin="normal"
                        id="meeting_time"
                        type="datetime-local"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}
                <Grid container style={{ paddingTop: "8px" }}>
                  <Grid item xs={4}>
                    <label
                      htmlFor="app_description"
                      className={`${classes.required} label-two`}
                    >
                      App Icon
                    </label>
                  </Grid>
                  <Grid item xs={8}>
                    <CustomButton
                      aria-describedby={id}
                      variant="outlined"
                      color="default"
                      endIcon={<KeyboardArrowDownIcon />}
                      onClick={handleClick}
                    >
                      <img
                        src={`https://d2cmuesa4snpwn.cloudfront.net/images/emails/app-icons/${selectedAppIcon}.png`}
                        alt="Icon"
                      />
                    </CustomButton>
                    <Popover
                      id={id}
                      open={openPopover}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      classes={{
                        paper: classes.popover,
                      }}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                    >
                      <div className={classes.searchIconSection}>
                        <Paper square={true} className={classes.paper}>
                          <IconButton
                            className={classes.iconButton}
                            aria-label="search"
                            onClick={handleSearchIcon}
                          >
                            <SearchIcon />
                          </IconButton>
                          <InputBase
                            autoFocus
                            onChange={(e) => {
                              setSearchIconValue(e.target.value);
                              handleSearchIcon(e);
                            }}
                            className={classes.input}
                            placeholder="Find an icon…"
                            inputProps={{ "aria-label": "find an icon" }}
                          />
                        </Paper>
                        <Typography
                          className={classes.results}
                        >{`${appIcons.length} matching results`}</Typography>
                        <hr />
                        <Icons
                          icons={appIcons}
                          classes={classes}
                          handleSelectAppIcon={handleSelectAppIcon}
                        />
                      </div>
                    </Popover>
                  </Grid>
                </Grid>
                <Grid container style={{ paddingTop: "8px" }}>
                  <Grid item xs={4}>
                    <label
                      htmlFor="app_description"
                      className={`${classes.required} label`}
                    >
                      App Description
                    </label>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      variant="outlined"
                      aria-describedby="App Description"
                      disabled={createAppLoading}
                      error={appDescriptionValidation}
                      value={appDescription}
                      onChange={(e) => setAppDescription(e.target.value)}
                      margin="normal"
                      id="app_description"
                      size="medium"
                      fullWidth
                      multiline
                      rows={5}
                    />
                  </Grid>
                </Grid>
                <Grid container style={{ paddingTop: "5px" }}>
                  <Grid item xs={4}>
                    <label htmlFor="app_usage">
                      Instruction for creating an item
                    </label>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      variant="outlined"
                      aria-describedby="Instruction for creating an item"
                      disabled={createAppLoading}
                      value={appUsage ? appUsage : ""}
                      onChange={(e) => setAppUsage(e.target.value)}
                      margin="normal"
                      id="app_usage"
                      size="medium"
                      fullWidth
                      multiline
                      rows={5}
                    />
                  </Grid>
                </Grid>
              </Fragment>
            ) : (
              <Fragment>
                <Grid container style={{ paddingTop: "5px" }}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      disabled={createAppLoading}
                      control={
                        <Checkbox
                          color="primary"
                          checked={postNewItemsToStream}
                          onChange={() =>
                            setPostNewItemsToStream(!postNewItemsToStream)
                          }
                          id="post_new_items_to_stream"
                          name="post_new_items_to_stream"
                        />
                      }
                      classes={{
                        label: classes.checkboxLabel,
                      }}
                      label="Do not post new items to stream"
                    />
                  </Grid>
                </Grid>
                <Grid container style={{ paddingTop: "5px" }}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      disabled={createAppLoading}
                      control={
                        <Checkbox
                          color="primary"
                          checked={postItemEditsToStream}
                          onChange={() =>
                            setPostItemEditsToStream(!postItemEditsToStream)
                          }
                          id="post_edits_items_to_stream"
                          name="post_edits_items_to_stream"
                        />
                      }
                      classes={{
                        label: classes.checkboxLabel,
                      }}
                      label="Do not post items edits to stream"
                    />
                  </Grid>
                </Grid>
                <Grid container style={{ paddingTop: "5px" }}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      disabled={createAppLoading}
                      control={
                        <Checkbox
                          color="primary"
                          checked={disableNotifications}
                          onChange={() =>
                            setDisableNotifications(!disableNotifications)
                          }
                          id="disable_notification"
                          name="disable_notification"
                        />
                      }
                      classes={{
                        label: classes.checkboxLabel,
                      }}
                      label="Disable notifications for items created / edited / deleted for users that follow this app"
                    />
                  </Grid>
                </Grid>
                <Grid container style={{ paddingTop: "5px" }}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      disabled={createAppLoading}
                      control={
                        <Checkbox
                          color="primary"
                          checked={membersAddItem}
                          onChange={() => setMembersAddItem(!membersAddItem)}
                          id="members_cant_add_item"
                          name="members_cant_add_item"
                        />
                      }
                      classes={{
                        label: classes.checkboxLabel,
                      }}
                      label="Allow members add items to this app"
                    />
                  </Grid>
                </Grid>
                <Grid container style={{ paddingTop: "5px" }}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      disabled={createAppLoading}
                      control={
                        <Checkbox
                          color="primary"
                          checked={membersEditItem}
                          onChange={() => setMembersEditItem(!membersEditItem)}
                          id="members_cant_edit_item"
                          name="members_cant_edit_item"
                        />
                      }
                      classes={{
                        label: classes.checkboxLabel,
                      }}
                      label="Allow members edit items to this app"
                    />
                  </Grid>
                </Grid>
                <Grid container style={{ paddingTop: "5px" }}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      disabled={createAppLoading}
                      control={
                        <Checkbox
                          color="primary"
                          checked={disableComments}
                          onChange={() => setDisableComments(!disableComments)}
                          id="disable_comment"
                          name="disable_comment"
                        />
                      }
                      classes={{
                        label: classes.checkboxLabel,
                      }}
                      label="Disable comments"
                    />
                  </Grid>
                </Grid>
                <Grid container style={{ paddingTop: "5px" }}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      disabled={createAppLoading}
                      control={
                        <Checkbox
                          color="primary"
                          checked={allowFiveStarRating}
                          onChange={() =>
                            setAllowFiveStarRating(!allowFiveStarRating)
                          }
                          id="unique_id"
                          name="allow_five_star_rating"
                        />
                      }
                      classes={{
                        label: classes.checkboxLabel,
                      }}
                      label="Allow Five Star Rating"
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{ display: allowFiveStarRating ? "flex" : "none" }}
                >
                  <Grid item xs={4}>
                    <label
                      htmlFor="five_star_rating"
                      style={{ marginTop: "2.5rem" }}
                    >
                      Five Star Rating Label
                    </label>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      variant="outlined"
                      aria-describedby="Five Star Rating Label"
                      disabled={createAppLoading}
                      error={fiveStarValidation}
                      value={fiveStarRatingLabel}
                      onChange={(e) => setFiveStarRatingLabel(e.target.value)}
                      margin="normal"
                      id="five_star_rating_label"
                      type="text"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container style={{ paddingTop: "5px" }}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      disabled={createAppLoading}
                      control={
                        <Checkbox
                          color="primary"
                          checked={allowItemThumbs}
                          onChange={() => setAllowItemThumbs(!allowItemThumbs)}
                          id="allow_item_thumb"
                          name="allow_item_thumb"
                        />
                      }
                      classes={{
                        label: classes.checkboxLabel,
                      }}
                      label="Allow Item Thumbs"
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{ display: allowItemThumbs ? "flex" : "none" }}
                >
                  <Grid item xs={4}>
                    <label
                      htmlFor="five_star_rating"
                      style={{ marginTop: "2.5rem" }}
                    >
                      Item Thumbs Label
                    </label>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      variant="outlined"
                      aria-describedby="Item Thumbs Label"
                      error={itemThumbsValidation}
                      disabled={createAppLoading}
                      value={itemThumbsLabel}
                      onChange={(e) => setItemThumbsLabel(e.target.value)}
                      margin="normal"
                      id="item_thumbs_label"
                      type="text"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container style={{ paddingTop: "5px" }}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      disabled={createAppLoading}
                      control={
                        <Checkbox
                          color="primary"
                          checked={rsvp}
                          onChange={() => setRsvp(!rsvp)}
                          id="rsvp"
                          name="rsvp"
                        />
                      }
                      classes={{
                        label: classes.checkboxLabel,
                      }}
                      label="Allow RSVP"
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{
                    display: rsvp ? "flex" : "none",
                  }}
                >
                  <Grid item xs={4}>
                    <label htmlFor="allow_rsvp" style={{ marginTop: "2.5rem" }}>
                      RSVP Label
                    </label>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      variant="outlined"
                      disabled={createAppLoading}
                      error={rsvpValidation}
                      aria-describedby="RSVP Label"
                      value={rsvpLabel}
                      onChange={(e) => setRsvpLabel(e.target.value)}
                      margin="normal"
                      id="rsvp_label"
                      type="text"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container style={{ paddingTop: "5px" }}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      disabled={createAppLoading}
                      control={
                        <Checkbox
                          color="primary"
                          checked={yesNo}
                          onChange={() => setYesNo(!yesNo)}
                          id="yesNo"
                          name="yesNo"
                        />
                      }
                      classes={{
                        label: classes.checkboxLabel,
                      }}
                      label="Enable Yes/No rating"
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{
                    display: yesNo ? "flex" : "none",
                  }}
                >
                  <Grid item xs={4}>
                    <label htmlFor="yesNo" style={{ marginTop: "2.5rem" }}>
                      Yes/No Label
                    </label>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      variant="outlined"
                      aria-describedby="YesOrNo Label"
                      error={yesNoValidation}
                      value={yesNoLabel}
                      onChange={(e) => setYesNoLabel(e.target.value)}
                      margin="normal"
                      id="yesNo_label"
                      disabled={createAppLoading}
                      type="text"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container style={{ paddingTop: "5px" }}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      disabled={createAppLoading}
                      control={
                        <Checkbox
                          color="primary"
                          checked={approveItem}
                          onChange={() => setApproveItem(!approveItem)}
                          id="approve_id"
                          name="approve_id"
                        />
                      }
                      classes={{
                        label: classes.checkboxLabel,
                      }}
                      label="Approve Item"
                    />
                  </Grid>
                </Grid>
                <Grid container style={{ paddingTop: "5px" }}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      disabled={createAppLoading}
                      control={
                        <Checkbox
                          color="primary"
                          checked={allowAttachments}
                          onChange={() =>
                            setAllowAttachments(!allowAttachments)
                          }
                          id="allow_attachments"
                          name="allow_ttachments"
                        />
                      }
                      classes={{
                        label: classes.checkboxLabel,
                      }}
                      label="Allow Attachments"
                    />
                  </Grid>
                </Grid>
              </Fragment>
            )}
          </div>
        </DialogContent>
        <div className="actions">
          <Button
            tooltip={true}
            tooltip_title="Close App Create Modal"
            tooltip_placement="bottom"
            function={closeCreateDialog}
            style={{ background: `#fff`, color: `#626262` }}
            text="Cancel"
          />
          <RenderByPermission>
            <Button
              tooltip={true}
              tooltip_title="Create New App"
              tooltip_placement="bottom"
              function={handleCreateApp}
              disabled={createAppLoading}
              style={
                createAppLoading
                  ? { background: `grey`, color: `#000` }
                  : { background: `#F7C948`, color: `#fff` }
              }
              text={createAppLoading ? "PLEASE WAIT..." : "Create"}
            />
            {createAppLoading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </RenderByPermission>
        </div>
      </Dialog>
      {/* Edit dialog */}
      <RenderByPermission>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={editCurrentApp}
          onClose={closeEditDialog}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" onClose={closeEditDialog}>
            Edit App
          </DialogTitle>
          <DialogContent>
            <div>
              <AntTabs
                value={currentTab}
                onChange={handleChange}
                aria-label="edit app"
              >
                <AntTab label="General" {...a11yProps(0)} />
                <AntTab label="Advanced" {...a11yProps(1)} />
              </AntTabs>
              {currentTab === 0 ? (
                <Fragment>
                  <Grid container style={{ paddingTop: "20px" }}>
                    <Grid item xs={4}>
                      <label
                        htmlFor="organization_id"
                        style={{ marginTop: "2.5rem" }}
                        className={classes.required}
                      >
                        App Name
                      </label>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        variant="outlined"
                        aria-describedby="App Name"
                        value={appName}
                        disabled={createAppLoading}
                        error={appNameValidation}
                        onChange={(e) => setAppName(e.target.value)}
                        margin="normal"
                        id="app_name"
                        type="text"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid container style={{ paddingTop: "20px" }}>
                    <Grid item xs={4}>
                      <label
                        htmlFor="organization_id"
                        style={{ marginTop: "2.5rem" }}
                      >
                        Item Name
                      </label>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        variant="outlined"
                        aria-describedby="App Name"
                        disabled={createAppLoading}
                        value={itemName}
                        onChange={(e) => setItemName(e.target.value)}
                        margin="normal"
                        id="app_name"
                        type="text"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid container style={{ paddingTop: "20px" }}>
                    <Grid item xs={4}>
                      <label htmlFor="app_type" className={classes.required}>
                        App Type
                      </label>
                    </Grid>
                    <Grid item xs={8}>
                      <Select
                        native={true}
                        disabled={createAppLoading}
                        variant="outlined"
                        value={appType}
                        onChange={(e) => setAppType(e.target.value)}
                        inputProps={{
                          name: "app_type",
                          id: "filled-age-native-simple",
                        }}
                        fullWidth
                      >
                        <option value="standard">Standard</option>
                      </Select>
                      <p style={{ paddingTop: "1px" }}>
                        <InfoOutlinedIcon
                          fontSize="small"
                          style={{
                            cursor: "pointer",
                            color: "#127FBF",
                            marginLeft: "5px",
                            marginRight: "5px",
                            fontSize: "15px",
                          }}
                        />
                        The Podio default, useful for all types of apps
                      </p>
                    </Grid>
                  </Grid>
                  {appType === "meeting" ? (
                    <Grid container style={{ paddingTop: "5px" }}>
                      <Grid item xs={4}>
                        <label
                          htmlFor="meeting_time"
                          style={{ marginTop: "1.0rem" }}
                        >
                          Meeting Time
                        </label>
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          aria-describedby="Meeting Time"
                          disabled={createAppLoading}
                          error={appTypeMeetingValidation}
                          value={meetingTime}
                          onChange={(e) => setMeetingTime(e.target.value)}
                          // margin="normal"
                          id="meeting_time"
                          type="datetime-local"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}
                  <Grid container style={{ paddingTop: "8px" }}>
                    <Grid item xs={4}>
                      <label
                        htmlFor="app_description"
                        className={classes.required}
                      >
                        App Icon
                      </label>
                    </Grid>
                    <Grid item xs={8}>
                      <CustomButton
                        aria-describedby={id}
                        variant="outlined"
                        color="default"
                        endIcon={<KeyboardArrowDownIcon />}
                        onClick={handleClick}
                      >
                        <img
                          src={`https://d2cmuesa4snpwn.cloudfront.net/images/emails/app-icons/${selectedAppIcon}.png`}
                          alt="Icon"
                        />
                      </CustomButton>
                      <Popover
                        id={id}
                        open={openPopover}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        classes={{
                          paper: classes.popover,
                        }}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                      >
                        <div className={classes.searchIconSection}>
                          <Paper square={true} className={classes.paper}>
                            <IconButton
                              className={classes.iconButton}
                              aria-label="search"
                              onClick={handleSearchIcon}
                            >
                              <SearchIcon />
                            </IconButton>
                            <InputBase
                              autoFocus
                              onChange={(e) => {
                                setSearchIconValue(e.target.value);
                                handleSearchIcon(e);
                              }}
                              className={classes.input}
                              placeholder="Find an icon…"
                              inputProps={{ "aria-label": "find an icon" }}
                            />
                          </Paper>
                          <Typography
                            className={classes.results}
                          >{`${appIcons.length} matching results`}</Typography>
                          <hr />
                          <Icons
                            icons={appIcons}
                            classes={classes}
                            handleSelectAppIcon={handleSelectAppIcon}
                          />
                        </div>
                      </Popover>
                    </Grid>
                  </Grid>
                  <Grid container style={{ paddingTop: "8px" }}>
                    <Grid item xs={4}>
                      <label
                        htmlFor="app_description"
                        className={classes.required}
                      >
                        App Description
                      </label>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        variant="outlined"
                        aria-describedby="App Description"
                        disabled={createAppLoading}
                        error={appDescriptionValidation}
                        value={appDescription}
                        onChange={(e) => setAppDescription(e.target.value)}
                        margin="normal"
                        id="app_description"
                        size="medium"
                        fullWidth
                        multiline
                        rows={5}
                      />
                    </Grid>
                  </Grid>

                  <Grid container style={{ paddingTop: "5px" }}>
                    <Grid item xs={4}>
                      <label htmlFor="app_usage" className={`label`}>
                        Instruction for creating an item
                      </label>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        variant="outlined"
                        aria-describedby="Instruction for creating an item"
                        disabled={createAppLoading}
                        value={appUsage}
                        onChange={(e) => setAppUsage(e.target.value)}
                        margin="normal"
                        id="app_usage"
                        size="medium"
                        fullWidth
                        multiline
                        rows={5}
                      />
                    </Grid>
                  </Grid>
                </Fragment>
              ) : (
                <Fragment>
                  <Grid container style={{ paddingTop: "5px" }}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        disabled={createAppLoading}
                        control={
                          <Checkbox
                            color="primary"
                            checked={postNewItemsToStream}
                            onChange={() =>
                              setPostNewItemsToStream(!postNewItemsToStream)
                            }
                            id="post_new_items_to_stream"
                            name="post_new_items_to_stream"
                          />
                        }
                        classes={{
                          label: classes.checkboxLabel,
                        }}
                        label="Do not post new items to stream"
                      />
                    </Grid>
                  </Grid>
                  <Grid container style={{ paddingTop: "5px" }}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        disabled={createAppLoading}
                        control={
                          <Checkbox
                            color="primary"
                            checked={postItemEditsToStream}
                            onChange={() =>
                              setPostItemEditsToStream(!postItemEditsToStream)
                            }
                            id="post_edits_items_to_stream"
                            name="post_edits_items_to_stream"
                          />
                        }
                        classes={{
                          label: classes.checkboxLabel,
                        }}
                        label="Do not post items edits to stream"
                      />
                    </Grid>
                  </Grid>
                  <Grid container style={{ paddingTop: "5px" }}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        disabled={createAppLoading}
                        control={
                          <Checkbox
                            color="primary"
                            checked={disableNotifications}
                            onChange={() =>
                              setDisableNotifications(!disableNotifications)
                            }
                            id="disable_notification"
                            name="disable_notification"
                          />
                        }
                        classes={{
                          label: classes.checkboxLabel,
                        }}
                        label="Disable notifications for items created / edited / deleted for users that follow this app"
                      />
                    </Grid>
                  </Grid>
                  <Grid container style={{ paddingTop: "5px" }}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        disabled={createAppLoading}
                        control={
                          <Checkbox
                            color="primary"
                            checked={membersAddItem}
                            onChange={() => setMembersAddItem(!membersAddItem)}
                            id="members_cant_add_item"
                            name="members_cant_add_item"
                          />
                        }
                        classes={{
                          label: classes.checkboxLabel,
                        }}
                        label="Allow members add items to this app"
                      />
                    </Grid>
                  </Grid>
                  <Grid container style={{ paddingTop: "5px" }}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        disabled={createAppLoading}
                        control={
                          <Checkbox
                            color="primary"
                            checked={membersEditItem}
                            onChange={() =>
                              setMembersEditItem(!membersEditItem)
                            }
                            id="members_cant_edit_item"
                            name="members_cant_edit_item"
                          />
                        }
                        classes={{
                          label: classes.checkboxLabel,
                        }}
                        label="Allow members edit items to this app"
                      />
                    </Grid>
                  </Grid>
                  <Grid container style={{ paddingTop: "5px" }}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        disabled={createAppLoading}
                        control={
                          <Checkbox
                            color="primary"
                            checked={disableComments}
                            onChange={() =>
                              setDisableComments(!disableComments)
                            }
                            id="disable_comment"
                            name="disable_comment"
                          />
                        }
                        classes={{
                          label: classes.checkboxLabel,
                        }}
                        label="Disable comments"
                      />
                    </Grid>
                  </Grid>
                  <Grid container style={{ paddingTop: "5px" }}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        disabled={createAppLoading}
                        control={
                          <Checkbox
                            color="primary"
                            checked={allowFiveStarRating}
                            onChange={() =>
                              setAllowFiveStarRating(!allowFiveStarRating)
                            }
                            id="unique_id"
                            name="allow_five_star_rating"
                          />
                        }
                        classes={{
                          label: classes.checkboxLabel,
                        }}
                        label="Allow Five Star Rating"
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    style={{ display: allowFiveStarRating ? "flex" : "none" }}
                  >
                    <Grid item xs={4}>
                      <label
                        htmlFor="five_star_rating"
                        style={{ marginTop: "2.5rem" }}
                      >
                        Five Star Rating Label
                      </label>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        variant="outlined"
                        aria-describedby="Five Star Rating Label"
                        disabled={createAppLoading}
                        error={fiveStarValidation}
                        value={fiveStarRatingLabel}
                        onChange={(e) => setFiveStarRatingLabel(e.target.value)}
                        margin="normal"
                        id="five_star_rating_label"
                        type="text"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid container style={{ paddingTop: "5px" }}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        disabled={createAppLoading}
                        control={
                          <Checkbox
                            color="primary"
                            checked={allowItemThumbs}
                            onChange={() =>
                              setAllowItemThumbs(!allowItemThumbs)
                            }
                            id="allow_item_thumb"
                            name="allow_item_thumb"
                          />
                        }
                        classes={{
                          label: classes.checkboxLabel,
                        }}
                        label="Allow Item Thumbs"
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    style={{ display: allowItemThumbs ? "flex" : "none" }}
                  >
                    <Grid item xs={4}>
                      <label
                        htmlFor="five_star_rating"
                        style={{ marginTop: "2.5rem" }}
                      >
                        Item Thumbs Label
                      </label>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        variant="outlined"
                        aria-describedby="Item Thumbs Label"
                        error={itemThumbsValidation}
                        disabled={createAppLoading}
                        value={itemThumbsLabel}
                        onChange={(e) => setItemThumbsLabel(e.target.value)}
                        margin="normal"
                        id="item_thumbs_label"
                        type="text"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid container style={{ paddingTop: "5px" }}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        disabled={createAppLoading}
                        control={
                          <Checkbox
                            color="primary"
                            checked={rsvp}
                            onChange={() => setRsvp(!rsvp)}
                            id="rsvp"
                            name="rsvp"
                          />
                        }
                        classes={{
                          label: classes.checkboxLabel,
                        }}
                        label="Allow RSVP"
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    style={{
                      display: rsvp ? "flex" : "none",
                    }}
                  >
                    <Grid item xs={4}>
                      <label
                        htmlFor="allow_rsvp"
                        style={{ marginTop: "2.5rem" }}
                      >
                        RSVP Label
                      </label>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        variant="outlined"
                        disabled={createAppLoading}
                        error={rsvpValidation}
                        aria-describedby="RSVP Label"
                        value={rsvpLabel}
                        onChange={(e) => setRsvpLabel(e.target.value)}
                        margin="normal"
                        id="rsvp_label"
                        type="text"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid container style={{ paddingTop: "5px" }}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        disabled={createAppLoading}
                        control={
                          <Checkbox
                            color="primary"
                            checked={yesNo}
                            onChange={() => setYesNo(!yesNo)}
                            id="yesNo"
                            name="yesNo"
                          />
                        }
                        classes={{
                          label: classes.checkboxLabel,
                        }}
                        label="Enable Yes/No rating"
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    style={{
                      display: yesNo ? "flex" : "none",
                    }}
                  >
                    <Grid item xs={4}>
                      <label htmlFor="yesNo" style={{ marginTop: "2.5rem" }}>
                        Yes/No Label
                      </label>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        variant="outlined"
                        aria-describedby="YesOrNo Label"
                        error={yesNoValidation}
                        value={yesNoLabel}
                        onChange={(e) => setYesNoLabel(e.target.value)}
                        margin="normal"
                        id="yesNo_label"
                        disabled={createAppLoading}
                        type="text"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid container style={{ paddingTop: "5px" }}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        disabled={createAppLoading}
                        control={
                          <Checkbox
                            color="primary"
                            checked={approveItem}
                            onChange={() => setApproveItem(!approveItem)}
                            id="approve_id"
                            name="approve_id"
                          />
                        }
                        classes={{
                          label: classes.checkboxLabel,
                        }}
                        label="Approve Item"
                      />
                    </Grid>
                  </Grid>
                  <Grid container style={{ paddingTop: "5px" }}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        disabled={createAppLoading}
                        control={
                          <Checkbox
                            color="primary"
                            checked={allowAttachments}
                            onChange={() =>
                              setAllowAttachments(!allowAttachments)
                            }
                            id="allow_attachments"
                            name="allow_attachments"
                          />
                        }
                        classes={{
                          label: classes.checkboxLabel,
                        }}
                        label="Allow Attachements"
                      />
                    </Grid>
                  </Grid>
                </Fragment>
              )}
            </div>
          </DialogContent>
          <DialogActions style={{ paddingTop: "10px" }}>
            <CustomButton
              tooltip="true"
              tooltip_title="Close App Create Modal"
              tooltip_placement="bottom"
              onClick={closeEditDialog}
              color="default"
            >
              Cancel
            </CustomButton>
            <CustomButton
              disabled={createAppLoading}
              tooltip="true"
              tooltip_title="Create New App"
              tooltip_placement="bottom"
              color="primary"
              onClick={handleEditApp}
            >
              Update
            </CustomButton>
            {createAppLoading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </DialogActions>
        </Dialog>
      </RenderByPermission>
      {/* Delete app dialog */}
      <RenderByPermission>
        <Dialog
          open={deleteCurrentApp}
          onClose={closeDeleteDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete App</DialogTitle>
          <hr />
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you want to delete the current selected app (
              {apps.length > 0
                ? apps.find((x) => parseInt(x.app_id) === parseInt(selectedApp))
                    ?.config?.name
                : ""}
              )
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button function={closeDeleteDialog} color="default" text="No" />
            <Button
              function={handleDeleteApp}
              style={
                createAppLoading
                  ? { background: `grey`, color: `#000` }
                  : { background: `#F7C948`, color: `#fff` }
              }
              text={createAppLoading ? "Deleting App..." : "Yes"}
            />
            {createAppLoading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </DialogActions>
        </Dialog>
      </RenderByPermission>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.authUser,
  orgs: state.sync.orgs,
});

export default connect(mapStateToProps, null)(App);
