import React from 'react';
import ChordDiagram from 'react-chord-diagram';

export default function App() {
  const matrix = [
    [11975, 5871, 8916, 2868, 5357],
    [1951, 10048, 2060, 6171, 5468],
    [8010, 16145, 8090, 8045, 7979],
    [1010, 1215, 1240, 8445, 7344],
    [1013, 990, 940, 6907, 9097],
  ];
  const matrix2 = [
    [1, 1],
    [1, 1],
  ];

  return (
    <div>
      <ChordDiagram
        matrix={matrix}
        componentId={1}
        groupLabels={['Black', 'Yellow', 'Brown', 'Orange', 'Green']}
        groupColors={['#000000', '#FFDD89', '#957244', '#F26223', '#FE0000']}
      />
      <ChordDiagram matrix={matrix2} componentId={1} groupLabels={['Black', 'Yellow']} groupColors={['#000000', '#FFDD89']} />
    </div>
  );
}
