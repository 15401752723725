import React from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Typography,
} from "@material-ui/core";


const WebhooksModal = ({ openModal, handleClose, hooks, }) => {

    function formatEvent(string) {
        return string.charAt(0).toLocaleUpperCase()+string.slice(1).replace("_", " ");
    }

    return (
        <div>
            <Dialog
                open={openModal}
                onClose={handleClose}
                // hideBackdrop={true}
                disableEscapeKeyDown={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="700px"
            >
                <DialogContent>
                    <Box style={{ padding: "20px", width: "500px" }}>
                        <Typography id="modal-modal-title" variant="h6" component="p" className="mb-3">
                            Webhooks Detail
                        </Typography>
                        {
                            hooks?.length === 0 ? <center>
                                <h5>No data at the moment</h5>
                            </center> :
                                <div className="table-responsive">
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <th>#</th>
                                            <th>Event</th>
                                            <th>Recipient</th>
                                            <th>Timestamp</th>
                                        </thead>
                                        <tbody>
                                            {
                                                hooks?.map((hook, index) => (
                                                    <tr key={hook.timestamp}>
                                                        <td>{index + 1}</td>
                                                        <td>{formatEvent(hook.event)}</td>
                                                        <td>{hook.recipient}</td>
                                                        <td>{hook.timestamp}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                        }
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button id="button-cancel" className="mr-4 " onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default WebhooksModal;
