import React from "react";
import styled from "@emotion/styled";
import CustomButton from "../../atoms/CustomButton";

const ConnectModal = (props) => {
  const { info } = props;

  return (
    <ConnectModal.Wrapper>
      <img src={info.image} alt="logo" />
      <p>Connect your {info.title} </p>
      <CustomButton onClick={props.handleConnect}>
        Authorize with {info.title}
      </CustomButton>
      <CustomButton
        variant="outlined"
        color="secondary"
        onClick={props.handleClose}
      >
        Cancel
      </CustomButton>
    </ConnectModal.Wrapper>
  );
};

ConnectModal.Wrapper = styled.div`
  padding: 10px;
  text-align: center;
  button {
    width: 100%;
    margin-bottom: 1rem;
    :nth-of-type(1) {
      background-color: #2871b6;
    }
  }
`;

export default ConnectModal;
