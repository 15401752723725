/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import StatusList from './StatusList';
import CommentItemGrid from './CommentItemGrid';
import { useHistory } from 'react-router';
import axios from '../../../config';

const ItemComments = (props) => {
  const [statistics, setStatistics] = useState();
  const [loading, setLoading] = useState(false);
  const data = props.location.state;
  const history = useHistory();

  const getStatistics = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`/tmail/comment-moderation/item/stats?tmail_app_id=${data.app.id}&item_id=${data.item_id}`);
      const response = res.data.item.statistics;
      setStatistics(response)
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getStatistics();
  }, []);

  const goBack = () => {
    history.goBack();
  }

  if (loading) {
    return <center>
      <CircularProgress />
    </center>
  }

  return (
    <>
      {/* <h4>Item Comments Moderation</h4> */}
      <div>
        <h5 className='mb-5'>
          <span
            style={{ cursor: "pointer" }}
            onClick={goBack} className="text-primary">{data?.app.app_name}</span> {">"} {data?.item_name} ({data.item_id})</h5>
        <StatusList
          statistic={statistics}
        />

        <CommentItemGrid
          item={data}
        />
      </div>
    </>
  )
}

export default ItemComments