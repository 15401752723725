/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "./style.css";
import { useSelector } from "react-redux";
import NewConnectorDetails from "../NewConnectorDetails";
import Axios from "../../../config";
import { toast } from "helpers/apiRequests";
import mysql from "../../../assets/icons/mysqlv2.svg";
import postgresql from "../../../assets/icons/postgresql.svg";
import cancel from "../../../assets/icons/cancel.svg";

const useStyles = makeStyles((theme) => ({
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "5%",
  },
  headerText: {
    color: "black",
  },
  btnContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  btnDesc: {
    color: "#DE911D",
  },
}));
const CreateNewConnector = ({ openModal, handleClose, addConnectors }) => {
  const classes = useStyles();
  const sql = useSelector((state) => state.connector.sql_sync);
  const [drivers, setDrivers] = useState([]);
  const [isCreated, setIsCreated] = useState(false);
  const [responseData, setResponseData] = useState({
    show: false,
    data: [],
  });
  const handlResponseChange = (val) => {
    setResponseData({
      show: true,
      data: [...val],
    });
    handleClose();
    setOpen(true);
  };
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleModalClose = () => {
    setResponseData({
      show: false,
      data: [],
    });
    setOpen(false);
  };
  const [org, setOrg] = useState("");

  const orgs = useSelector((state) => state.sync.orgs);
  const orgOptions =
    orgs?.length > 0
      ? orgs.map((org) => {
        return (
          <option key={org.org_id} value={org.org_id}>
            {org.name}
          </option>
        );
      })
      : null;

  const changeOrganisation = (e) => {
    const { value } = e.target;
    setOrg(value);
  };

  const saveLoading = () => {
    setLoading(false);
  };

  //after creating new user and db, reload the page to show new connectors in list of connectors

  const createNewUserMySqlDB = async () => {
    try {
      setLoading(true);
      let payload;
      if (!drivers.length) {
        payload = {};
      } else if (drivers.length === 1) {
        payload = {
          drivers: drivers,
        };
      } else {
        payload = {
          drivers: drivers,
        };

      }
      const requestData = {
        org_id: org,
        ...payload,
      };

      const url = `/create-user-db`;
      const res = await Axios.post(url, requestData);
      addConnectors(res.data);
      handlResponseChange(res.data);
      setLoading(false);
      toast.notify(res.message);
      setIsCreated(true);
    } catch (error) {
      setLoading(false);
      let msg = error.response.message;
      if (
        error.response.data.error_list &&
        error.response.data.error_list.length > 0
      ) {
        msg = error.response.data.error_list[0].message;
      }
      toast.notify(msg, { type: "error" });
    }
  };

  if (isCreated) {
    window.location.reload();
  }

  const addorRemoveDrivers = (e) => {
    const { checked, value } = e.target;
    if (!checked) {
      let filterDrivers = drivers.filter((drive) => drive !== value);
      return setDrivers(filterDrivers);
    }
    return setDrivers((prevState) => [...prevState, value]);
  };


  return (
    <div>
      <NewConnectorDetails
        openModal={open}
        handleClose={handleModalClose}
        responseData={responseData}
      />
      <Dialog
        open={openModal}
        onClose={handleClose}
        // hideBackdrop={true}
        disableBackdropClick={false}
        disableEscapeKeyDown={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="connector-modal"
      >
        <DialogContent>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography variant="h6">
                <div className={classes.modalHeader}>
                  <h5 className={classes.headerText}>Create Connection</h5>
                  <div>
                    <img onClick={handleClose} alt="cancel" src={cancel} />
                  </div>
                </div>
              </Typography>
              <div className="form-group mt-3">
                <label style={{ marginBottom: "3%" }} htmlFor="app">
                  Select Organization <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  value={org}
                  onChange={changeOrganisation}
                >
                  <option>Select An Organization</option>
                  {orgOptions}
                </select>
              </div>

              <Typography style={{ color: "black" }} gutterBottom>
                Select either MySQL or PostgreSQL to create a new database.
              </Typography>
              <div className="mt-2">
                <img src={mysql} alt="mysql" className="mb-3 mr-2" width={50} />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={addorRemoveDrivers}
                      value={"mysql"}
                      name={"mysql"}
                      sx={{
                        border: "1px solid #CFCFCF",
                        borderRadius: "4px",
                        width: "20px",
                        height: "20px",
                        margin: "0 10px",
                      }}
                    />
                  }
                  label={`MySql`}
                ></FormControlLabel>
                <img
                  style={{ width: "8%" }}
                  src={postgresql}
                  alt="postgresql"
                  width={50}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={addorRemoveDrivers}
                      value={"pgsql"}
                      name={"pgsql"}
                    />
                  }
                  label={`PostgreSql`}
                ></FormControlLabel>
              </div>

              <Typography
                style={{
                  backgroundColor: "#FFFAEB",
                  color: "#DE911D",
                  padding: "10px",
                  borderRadius: "10px",
                }}
                gutterBottom
              >
                You are about setting up MySQL/PGSQL syncing. Would you like to
                proceed?
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              <Button
                id="button-save"
                onClick={createNewUserMySqlDB}
                color="primary"
                autoFocus
                style={{ padding: "5px 0" }}
              >
                Yes
              </Button>
            )}
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateNewConnector;
