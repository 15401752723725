/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from 'react';
import avaSmall from '../../assets/images/avasmall.svg';
import syncSmall from '../../assets/images/syncsmall.svg';
import appIcon from '../../assets/images/group_app.png';
import Tmail from '../../assets/images/logoTrans2.png';
import history from '../../helpers/history';
import styled from '@emotion/styled';
import Tooltip from '@material-ui/core/Tooltip';

const useClickOutside = (handler) => {
  const domNode = useRef();

  useEffect(() => {
    const maybeHandler = (event) => {
      if (!domNode.current.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener('mousedown', maybeHandler);

    return () => {
      document.removeEventListener('mousedown', maybeHandler);
    };
  });

  return domNode;
};

const AppSwitch = () => {
  const [showApps, setshowApps] = React.useState(true);
  const domNode = useClickOutside(() => {
    setshowApps(true);
  });

  return (
    <AppSwitch.Wrapper>
      <div className='apps'>
        <Tooltip title=''>
          <img src={appIcon} alt='App Icon' className='app-icon' onClick={() => setshowApps(!showApps)} />
        </Tooltip>
        <div ref={domNode} className={`app-list ${showApps ? 'active' : ''}`}>
          <h6>Apps</h6>
          <div>
            <Tooltip title='Sync'>
              <img src={syncSmall} alt='Sync' onClick={() => history.push('/dashboard/sync')} />
            </Tooltip>

            <Tooltip title='Ava'>
              <img src={avaSmall} alt='Ava' onClick={() => window.open('https://ava.thatapp.io/login', '_blank')} />
            </Tooltip>

     
            {/* <Tooltip title='Tmail'>
              <img src={Tmail} alt='Tmail' width={70} onClick={() => history.push('/tmail-dashboard')} />
            </Tooltip> */}
          </div>
        </div>
      </div>
    </AppSwitch.Wrapper>
  );
};

AppSwitch.Wrapper = styled.div`
  .apps {
    margin-right: 10px;
    position: relative;
    cursor: pointer;
    .app-icon {
      width: 25px;
      margin-bottom: 5px;
    }
    /* img {
      } */
    .app-list {
      position: absolute;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
      border-radius: 6px;
      padding: 15px 24px;
      z-index: 2;
      left: 50%;
      transform: translateX(-50%);
      top: 52px;
      -webkit-filter: drop-shadow(0 1px 10px rgba(113, 158, 206, 0.8)); /*set shadow colour  and size here*/
      -moz-box-shadow: 0 1px 10px rgba(113, 158, 206, 0.8);
      filter: drop-shadow(0 1px 10px rgba(113, 158, 206, 0.8));

      :after,
      :before {
        bottom: 100%;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }
      :after {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #ffffff;
        border-width: 19px;
        left: 50%;
        margin-left: -19px;
      }

      :before {
        border-color: rgba(113, 158, 206, 0);
        border-bottom-color: #719ece;
        border-width: 20px;
        left: 50%;
        margin-left: -20px;
      }
      h6 {
        margin-bottom: 19px;
      }
      > div {
        display: flex;
        img {
          border: 0.5px solid #cfcfcf;
          border-radius: 4px;
          padding: 5px;
          :not(:last-of-type) {
            margin-right: 24px;
          }
          :hover {
            cursor: pointer;
          }
        }
      }
    }
    .active {
      display: none;
    }
  }
`;
export default AppSwitch;
