import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
} from "@material-ui/core";
import cancelIcon from "../../../../assets/icons/cancel.svg";
import "toasted-notes/src/styles.css";
import TableDesign from "../utility/TableDesign";
// import TableView from "../utility/TableView";
const textStyle = {
  fontWeight: 500,
  textDecoration: "underline",
};
const textP = {
  fontWeight: "normal",
  fontSize: "16px",
  marginBottom: "1%",
};
const divStyle = {
  padding: "10px",
  background: "#eee",
};
const ViewDomain = ({
  openModal,
  handleClose,
  domain,
  verifyDomain,
  isLoading,
}) => {
  const value = domain.sending_dns_records[0];

  return (
    <div id="viewFields">
      <Dialog
        open={openModal}
        onClose={handleClose}
        // hideBackdrop={true}
        disableBackdropClick={false}
        disableEscapeKeyDown={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="connector-modal"
        className="viewFieldscont"
        fullWidth="800px"
        
      >
        <DialogContent style={{ width: "100%" }}>
          <Box  className="box">
            <div
              style={{
                marginBottom: "2rem",
                background: "#ffffff",
                width: "100%",
                position: "absolute",
                height: "60px",
                top: "0",
                right: "0",
                left: "0",
                padding: "3rem 2rem 3rem 2rem",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3>Domain Configuration</h3>
              <img width="35px" src={cancelIcon} alt="" />
            </div>
            <div
              style={{
                paddingTop: "3.5rem",
              }}
            >
              <p style={textP}>
                Follow these steps to verify your domain and make it active on
                ThatAPP
              </p>
              <p style={textP}>
                <b>1. Go to your DNS provider</b>
              </p>
              <p style={textP}>
                {" "}
                Go to the DNS provider that you use to manage {
                  domain.domain
                }{" "}
                and add the following DNS records.
                <br />
              </p>

              <p style={textP}>
                <b>2. Add DNS records for sending</b>
              </p>
              {domain.sending_dns_records[0].current_value ? (
                <>
                  <p style={textP}>
                    It appears you already have an SPF record with:{" "}
                  </p>
                  <div style={divStyle}>
                    <p style={textP}>{value?.current_value}</p>
                  </div>
                  <p style={textP}>
                    Our automated tools suggest the following new value:{" "}
                  </p>
                  <div style={divStyle}>
                    <p style={textP}>{value?.recommended_value}</p>
                  </div>
                </>
              ) : null}
              {/* <p style={textP}><b>It appears you already have an SPF record with</b></p>
                        <p style={textP}><b>It appears you already have an SPF record with</b></p>
                        <p>To allow GlobiMail to send emails from your domain, you need to allow our servers via an SPF rule in a TXT record.
                            It appears you already have an SPF record with:</p> */}
              <h6 className="text-left mt-4 mb-2" style={textStyle}>
                Sending DNS records
              </h6>
              <TableDesign
                records={domain.sending_dns_records}
                name={domain.domain}
              />

              <h6 className="text-left mb-2" style={textStyle}>
                Receiving DNS records
              </h6>
              <TableDesign
                records={domain.receiving_dns_records}
                name={domain.domain}
              />
            </div>
          </Box>
        </DialogContent>

        <DialogActions>
          <i
            className="fa fa-sync"
            style={{
              fontSize: "20px",
              marginLeft: "20px",
              marginRight: "10px",
              cursor: "pointer",
            }}
            title="Refresh verification status"
            onClick={() => {
              handleClose();
              verifyDomain(domain.id);
            }}
          ></i>
          <Button id="button-cancel" onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ViewDomain;
