/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { useHistory } from "react-router-dom";
import parseISO from "date-fns/parseISO";
import SyncIcon from "../../assets/icons/resyncIcon.svg";
import EyeIcon from "../../assets/icons/eye-icon.svg";
// import EditIcon from "../../assets/icons/edit-icon.svg";
// import DeleteIcon from "../../assets/icons/trash-icon.svg";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Confirm from "../../components/Modal/Confirm.js";
import { toast } from "helpers/apiRequests";
import { syncApps } from "../../services/sync";

const AppListView = ({
  user,
  apps,
  organization,
  openEditDialog,
  openDeleteDialog,
  handleSelectedApp,
}) => {
  const history = useHistory();
  const params = useParams();

  const initial = {
    message: null,
    modal_type: null,
    title: "",
    modal_value: false,
    modal_data: {},
    popup: false,
  };
  const [syncModal, syncModalData] = useState(initial);
  const [preLoader, setPreLoader] = useState(false);

  //Call the modals
  const syncAppPopup = (org, app) => {
    syncModalData({
      message: "Do you want to Resync this App?",
      modal_type: "syncapp",
      title: "Sync App",
      modal_value: true,
      modal_data: { org, app },
      popup: true,
    });
  };
  const toggleModal = () => {
    syncModalData(initial);
  };

  //Action
  const handleSubmit = async (type) => {
    let data;
    setPreLoader(true);
    if (type === "syncapp") {
      try {
        data = {
          org_id: syncModal.modal_data.org,
          app_id: syncModal.modal_data.app,
        };

        await syncApps(`?org_id=${data.org_id}&app_ids=${data.app_id}`);
        toast.notify("App synced successfully");
      } catch (e) {}
      setPreLoader(false);
      syncModalData(initial);
    }
  };

  return (
    <div className="sync__table">
      <Confirm
        className="confirm-modal-container"
        openModal={syncModal.modal_value}
        message={syncModal.message}
        action={() => handleSubmit(syncModal.modal_type)}
        title={syncModal.title}
        toggleModal={toggleModal}
        modalLoader={preLoader}
      />
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr style={{ backgroundColor: "#FBFBFB" }}>
              <th style={{ border: "none" }}>ID</th>
              <th style={{ border: "none" }}>Name</th>
              <th style={{ border: "none" }}>Created</th>
              <th style={{ border: "none" }}>Items</th>
              <th style={{ border: "none" }}>Status</th>
              <th style={{ border: "none" }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {apps.map((app, i) => {
              if (app.display === true) {
                return (
                  <tr key={i}>
                    <td>
                      <p style={{fontWeight: "600"}} className="title m-0">{app.app_id}</p>
                    </td>
                    <td>
                      <p style={{fontWeight: "600"}} className="title m-0">{app.config.name}</p>
                    </td>
                    <td style={{fontWeight: "600"}}>
                      {app.hooks && app.hooks.length > 0
                        ? formatDistanceToNow(
                            parseISO(app.hooks[0].created_on)
                          ) + " ago"
                        : ""}
                    </td>
                    <td>
                      <p style={{fontWeight: "600"}} className="mb-1">
                        <span className="font-weight-bold">
                          {app.fields ? app.fields.length : 0}{" "}
                        </span>{" "}
                        Items
                      </p>
                    </td>
                    <td>
                      <span style={{fontWeight: "600", padding: "3% 10%"}} className="info">
                        {app.status ? app.status : "none"}
                      </span>
                    </td>
                    <td>
                      <div className="dropdown">
                        {/* <span
                          className="dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          ...
                          </span> */}

                        <span
                          // className="dropdown-item"
                          onClick={() =>
                            syncAppPopup(organization.org_id, app.app_id)
                          }
                          style={{
                            color: "#035388",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                        >
                          <img src={SyncIcon} alt="icon" /> Resync App
                        </span>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          {/* <span
                            className="dropdown-item"
                            onClick={() => syncAppPopup(organization.org_id, app.app_id)}
                          >
                            <img src={SyncIcon} alt="icon" /> Resync App
                            </span> */}
                          {/* <span
                            className="dropdown-item"
                            onClick={() =>
                              openEditDialog(app.app_id)
                            }
                          >
                            <img src={EditIcon} alt="icon" width="12px" />
                              Edit
                            </span> */}

                          {/* <Link to={`/dashboard/organisation/${params.org_id}/workspaces/${params.id}/apps/${app.app_id}/app-builder`}>
                            <span className="dropdown-item">
                              {" "}
                              <img src={EyeIcon} alt="icon" width="13px" />
                                App Builder
                              </span>
                          </Link> */}

                          {/* <span
                            className="dropdown-item"
                            onClick={() => {
                              handleSelectedApp(app.app_id)
                              openDeleteDialog()
                            }}
                          >
                            <img src={DeleteIcon} alt="icon" width="13px" />{" "}
                              Delete
                            </span> */}
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              }
              return "";
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AppListView;
