import React from 'react';
import { useParams } from 'react-router';
import { toast } from 'helpers/apiRequests';

import LeftSideBar from './EditorSideBar/LeftSideBar/LeftSideBar';
import RightSideBar from './EditorSideBar/RightSideBar/RightSideBar';
import Header from '../Header/Header';

import styles from './PrintEditor.module.scss';

import { getTemplateDetails, saveTemplate } from '../printUtils/requests';
import useAsync from '../hooks/useAsync';
import EditorsContainer from './Editor/EditorsContainer';
import { usePrintLoadingContext } from 'context/LoadingContext/PrintLoadingContext';
import RenderByPermission from 'components/RenderByPermission';
import PrintProvider, { usePrintData } from '../context/PrintProvider';

function mutateIncomingPageNumberPosition(val) {
  // val is either an array of either header, footer, both or null
  let pageNumberPosition;
  if (!val) {
    pageNumberPosition = 'none';
  } else if (val.length === 2) {
    pageNumberPosition = 'header and footer';
  } else {
    pageNumberPosition = val?.[0];
  }
  return pageNumberPosition;
}

function mutateOutGoingPageNumberPosition(val) {
  let pageNumberPosition;
  if (val === 'header and footer') {
    pageNumberPosition = ['header', 'footer'];
  } else if (val === 'none') {
    pageNumberPosition = null;
  } else {
    pageNumberPosition = [val];
  }
  return pageNumberPosition;
}

export default function Component() {
  return (
    <PrintProvider>
      <PrintEditor />
    </PrintProvider>
  );
}

const PrintEditor = () => {
  const params = useParams();
  const { template, setTemplate, documentFields, setDocumentFields, templateFields, setTemplateFields } = usePrintData();
  const { isLoading, setIsLoading } = usePrintLoadingContext();
  const [selectedValue, setSelectedValue] = React.useState('');

  const handleSelect = (value) => {
    setSelectedValue(value);
  };

  function updatePrintDetails(val) {
    setTemplate(val);
  }

  const { execute, status } = useAsync(() => getTemplateDetails(params?.printId, updatePrintDetails, setIsLoading), false);

  const editorContent = React.useMemo(() => {
    return template?.document?.get_pages ?? [];
  }, [template?.document?.get_pages]);

  React.useEffect(() => {
    if (params?.printId) {
      execute();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.printId]);

  const [contents, setContents] = React.useState([
    {
      body: '',
    },
  ]);

  const [headerAndFooterContent, setHeaderAndFooterContent] = React.useState({ header: '', footer: '' });
  React.useEffect(() => {
    if (editorContent.length > 0) {
      setContents(() => {
        return editorContent.map((content) => ({
          body: content?.content ?? '',
        }));
      });

      setHeaderAndFooterContent(() => ({
        header: editorContent?.[0]?.header?.content ?? '',
        footer: editorContent?.[0]?.footer?.content ?? '',
      }));
    }
  }, [editorContent]);

  React.useEffect(() => {
    if (template) {
      setDocumentFields({
        marginTop: template?.document?.marginTop ?? '',
        marginBottom: template?.document?.marginBottom ?? '',
        marginLeft: template?.document?.marginLeft ?? '',
        marginRight: template?.document?.marginRight ?? '',
        orientation: template?.document?.orientation ?? '',
        size: template?.document?.size,
        pageNumberAlign: template?.document?.pageNumberAlign ?? 'none',
        pageNumberPosition: mutateIncomingPageNumberPosition(template?.document?.pageNumberPosition) ?? 'none',
        headerHeight: template?.document?.headerHeight ?? 20,
        footerHeight: template?.document?.footerHeight ?? 20,
        value_when_empty: template?.value_when_empty ?? '',
        value_when_errored: template?.value_when_errored ?? ''
      });

      setTemplateFields({
        title: template?.title,
        file_title: template?.file_title ?? '',
        extension: template?.creator?.extension ?? 'pdf',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template]);

  const [savingTemplate, setSavingTemplate] = React.useState(false);

  function handleSave() {
    setSavingTemplate(true);
    const payload = {
      id: template.id,
      title: templateFields.title,
      platform: 'podio',
      file_title: templateFields.file_title ?? '',
      type: 'document',
      pdf_processor: selectedValue,
      value_when_empty: documentFields?.value_when_empty,
      value_when_errored: documentFields?.value_when_errored,
      podio_options: {
        app_id: template.podio_options.app_id,
        app_field_id: template.podio_options.app_field_id,
        hook_id: null,
        value_id: null,
      },
      document: {
        css: null,
        orientation: documentFields.orientation,
        size: documentFields.size,
        extension: templateFields.extension,
        marginTop: documentFields.marginTop,
        marginBottom: documentFields.marginBottom,
        marginLeft: documentFields.marginLeft,
        marginRight: documentFields.marginRight,
        pageNumberAlign: documentFields.pageNumberAlign === 'none' ? null : documentFields.pageNumberAlign,
        pageNumberPosition: mutateOutGoingPageNumberPosition(documentFields.pageNumberPosition),
        pages: contents.length,
        headerHeight: documentFields.headerHeight,
        footerHeight: documentFields.footerHeight,
      },
    };

    payload.document.get_pages = contents.map((a, index) => {
      return {
        content: a.body,
        index: index + 1,
        header: {
          default: 1,
          content: headerAndFooterContent.header,
          height: 117,
        },
        footer: {
          default: 1,
          content: headerAndFooterContent.footer,
          height: 117,
        },
      };
    });
    return saveTemplate(payload)
      .then(() => {

        toast.notify('Template Saved Successfully');
      })
      .finally(() => setSavingTemplate(false));
  }

  return (
    <RenderByPermission allowTeamMemberAccess={true}>
      <div>
        <Header {...{ isLoading, setIsLoading }} />
        <main className={styles.container}>
          <LeftSideBar refreshTemplate={execute} />
          <EditorsContainer {...{ contents, setContents, headerAndFooterContent, setHeaderAndFooterContent, getPrintDetailsStatus: status }} />
          <RightSideBar
            refreshTemplate={execute}
            fetchTemplateStatus={status}
            onSelect={handleSelect}
            {...{
              handleSave,
              savingTemplate,
            }}
          />
        </main>
      </div>
    </RenderByPermission>
  );
};
