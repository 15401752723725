import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import chrome from "../../../assets/images/chrome_icon.png";
import HowToInstall from "./HowToInstall";

const useStyles = makeStyles({
  blue: {
    color: "#3f50b5",
  },
  green: {
    color: "#4caf50",
    fontSize: 15,
  },
  grey: {
    color: "#666",
  },
  bgDanger: {
    background: "#f00",
    color: "#fff",
    fontSize: 15,
  },
  bgGrey: {
    background: "#f9f1c9",
    color: "#8a6d3b",
    fontSize: 15,
  },
  wn: {
    fontSize: 17,
  },
  image: {
    width: "25%",
  },
  container: {
    border: "1px solid #EBEBEB",
    padding: "5% 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  Hline: {
    border: "1px solid #EBEBEB",
    width: "90%",
    height: "1px",
  },
  installBtn: {
    backgroundColor: "#38C769",
    boxShadow: "0px 3px 6px #00000029",
    padding: "1vh 1.5vw",
    outline: "none",
    textDecoration: "none",
    border: "none",
    borderRadius: "2px",
    color: "white",
    width: "300px",
  },
  howTo: {
    textAlign: "center",
    textDecoration: "underline",
    fontWeight: "500",
  },
});

const gotoLink = () => {
  const url =
    "https://chrome.google.com/webstore/detail/tmail-for-podio/nbonnidamdbokahofcindhjengelpnmc";
  window.open(url, "_blank", "noopener,noreferrer");
};

const ExtensionCard = (props) => {
  const classes = useStyles();
  const [openAlert, setOpenAlert] = useState(false);

  const closeModal = () => {
    setOpenAlert(false);
  };

  const handleInfoClick = () => {
    setOpenAlert(true);
  };

  return (
    <div className={classes.container}>
      <HowToInstall
        url={gotoLink}
        openModal={openAlert}
        handleClose={closeModal}
      />
      <div className="text-center">
        <h4>Browser Extension</h4>
      </div>
      <div className={classes.Hline}></div>

      <div className="text-center">
        <img className={classes.image} src={chrome} alt="chromeLogo" />
      </div>
      <div className="text-center">
        <button onClick={gotoLink} className={classes.installBtn}>
          Install Extension
        </button>
      </div>
      <div className={classes.howTo}>
        <p style={{ paddingBottom: "10px" }} onClick={handleInfoClick}>
          How do i install this extension?
        </p>
      </div>
    </div>
  );
};

export default ExtensionCard;
