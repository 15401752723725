/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Typography,
  LinearProgress,
} from "@material-ui/core";
import { toast } from "helpers/apiRequests";
import { createDefaultEmail } from "../../../../redux/actions/tmail";
import { useDispatch } from "react-redux";
import cancelIcon from "../../../../assets/icons/cancel.svg";

const EditModal = ({
  openModal,
  handleClose,
  domains,
  appId,
  item,
  updatedSeleected,
}) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [active, setActive] = useState("Active");
  const [saveLoading, setSaveLoading] = useState(false);
  const [inputToggle, setInputToggle] = useState(false);
  const [inputType, setInputType] = useState("password");
  const [eyeIcon, setEyeIcon] = useState("fa-eye");
  const [inputData, setInputData] = useState({
    username: "",
    name: "",
    password: "",
  });

  const toggleInput = () => {
    setInputToggle(!inputToggle);
    if (inputToggle) {
      setInputType("password");
      setEyeIcon("fa-eye");
    } else {
      setInputType("text");
      setEyeIcon("fa-eye-slash");
    }
  };

  useEffect(() => {
    setDomainId(item ? item.domain.id : "");
    setInputData(
      item
        ? {
            username: item.username,
            name: item.name,
            password: item.password ? item.password : "",
          }
        : {
            username: "",
            password: "",
            name: "",
          }
    );
    setSmtpId(item ? item.create_with_smtp : "");
    setEmailId(item ? item.id : "");
    setActive(item ? item.status : "");
  }, [openModal]);

  const [domainId, setDomainId] = useState("");
  const [emailId, setEmailId] = useState("");
  const [smtpId, setSmtpId] = useState("");
  const options =
    domains.length > 0
      ? domains.map((domain) => {
          return (
            <option key={domain.id} value={domain.id}>
              {`${domain.domain.toLowerCase()} ${
                domain.verification_status === "active"
                  ? "(Verified)"
                  : "(Unverified)"
              }`}
            </option>
          );
        })
      : null;
  const handleClose_ = () => {
    handleClose();
  };
  const changeIsActive = (e) => {
    setActive(e.target.value);
  };
  const changeIsLoadingSaveApp = () => {
    setSaveLoading(false);
  };
  // const changeSmtpMethod = e => {
  //     const val = e.target.value;
  //     setSmtpId(val)
  //     if (val === "1") {
  //         setShow(true)
  //     } else {
  //         setShow(false)
  //     }
  // }
  const changeDomainHandler = (e) => {
    setDomainId(e.target.value.toLowerCase());
  };

  const { username, password, name } = inputData;
  const handleInputChanges = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  const saveDefaultEmail = (e) => {
    e.preventDefault();
    setSaveLoading(true);
    if (!username || domainId === "" || active === "") {
      toast.notify("Select all option", { type: "warn" });
      setSaveLoading(false);
      return;
    }
    const payload = {
      username,
      name,
      password,
      domain_id: domainId,
      create_with_smtp: "1",
      tmail_app_id: appId,
      status: active,
      tmail_email_id: emailId,
    };
    dispatch(createDefaultEmail(payload, changeIsLoadingSaveApp, handleClose_));
    if (emailId !== "") {
      updatedSeleected();
    }
    setInputData({
      username: "",
      password: "",
    });
    setDomainId("");
    setSmtpId("");
    setInputType("password");
    setEyeIcon("fa-eye");
    // setSaveLoading(false)
  };

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            openModal(false);
          }
        }}
        // hideBackdrop={true}
        disableEscapeKeyDown={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="connector-modal"
        fullWidth="700px"
      >
        <div>
          <DialogContent>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Typography>
                  <form>
                    <div className="d-flex align-items-center justify-content-between mb-4">
                      <h5>Edit Sending Email</h5>
                      <img onClick={handleClose} src={cancelIcon} alt="" />
                    </div>

                    <div className="form-group">
                      <label htmlFor="domain">Domains</label>
                      <select
                        name="domain"
                        id="domain"
                        className="form-control"
                        value={domainId}
                        onChange={changeDomainHandler}
                        style={{ textTransform: "lowercase" }}
                      >
                        <option>Select Domain</option>
                        {options}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="username">Name</label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        value={name}
                        onChange={handleInputChanges}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="username">Username</label>
                      <input
                        type="text"
                        name="username"
                        className="form-control"
                        value={username}
                        onChange={handleInputChanges}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="smtp">Status</label>
                      <select
                        name="smtp"
                        id="smtp"
                        className="form-control"
                        onChange={changeIsActive}
                        value={active}
                      >
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </select>
                    </div>
                    {/* <div className="form-group">
                                            <label htmlFor="smtp">Create with SMTP</label>
                                            <select
                                                name="smtp"
                                                id="smtp"
                                                className='form-control'
                                                onChange={changeSmtpMethod}
                                                value={smtpId}
                                            >
                                                <option>Select SMTP option</option>
                                                <option value="1">Yes</option>
                                                <option value="0">No</option>
                                            </select>
                                        </div> */}

                    <div className="form-group">
                      <label htmlFor="password">Password</label>
                      <div class="input-group mb-3">
                        <input
                          class="form-control"
                          type={inputType}
                          name="password"
                          value={password}
                          onChange={handleInputChanges}
                        />
                        <span class="input-group-text">
                          <i
                            class={`far ${eyeIcon}`}
                            style={{ cursor: "pointer" }}
                            onClick={toggleInput}
                          ></i>
                        </span>
                      </div>
                    </div>

                    {/* <FormGroup>
                                            <FormControlLabel
                                                control={<Checkbox
                                                    onChange={changeIsActive}
                                                    checked={active} />}
                                                label={active ? "Mark as inactive" : "Mark as active"} />
                                        </FormGroup> */}

                    <div className="mt-2">
                      {saveLoading ? (
                        <center className="mt-3">
                          <LinearProgress />
                        </center>
                      ) : null}
                    </div>
                  </form>
                </Typography>
              </DialogContentText>
            </DialogContent>
            <div className="d-flex justify-content-end mb-3 mr-3">
              <button
                type="button"
                style={{
                  color: "#fff",
                  background: "#F7C948",
                }}
                onClick={saveDefaultEmail}
                className="btn btn-default"
              >
                Save
              </button>
            </div>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
};
export default EditModal;
