import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
// components
import Header from '../components/shared/Header'
import Footer from '../components/shared/Footer'
// style
import './features.scss'
// illustrations
import features_landing from '../illustrations/features-landing.svg'
import features1 from '../Images/sync-features2.gif'
import features2 from '../Images/sync-features1.gif'
import arrRight from "../Images/arrow-right-hero.png";
// icons

import hooks from '../icons/hooks.svg'
import app_builder from '../icons/app-builder.svg'
import raw_data from '../icons/raw-data.svg'
import users from '../icons/users.svg'
import gdpr from '../icons/gdpr.svg'
import sync from '../icons/sync.svg'

const SyncFeatures = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };
    
    return <div className="features">
        <Header />
        <section className="landing">
            <div className="text">
                <div className='inner'>
                <img src={sync} alt="sync" className="logo" />
                <div className="title">A data-driven platform, purposely engineered for you</div>
                <div className="color-black-2">Gain a refreshing perspective of your business processes and strategic initiatives through the eyes of our skilled workflow architects and system integrators. </div>
                <Link to='/register' className="hero-button">
                    <p>Get started now</p>
                    <img src={arrRight} alt="get started" />
                </Link>
                </div>
            </div>
            <div className="image"><img src={features_landing} alt="features" /></div>
        </section>
        <section className="automate">
            <div className="head">
                <div className="title">Features to automate your workflow seamlessly</div>
                <div className="text">It's our job to ensure that you're never lost in the clouds. Gain a refreshing perspective of your business processes and strategic initiatives.</div>
            </div>
            <div className='double-container' style={{ backgroundColor: '#FFFBF2' }}>
                <div className='tabs-feature'>
                    <div onClick={() => handleTabClick(0)} className='tab-feature' style={{ backgroundColor: activeTab === 0 ? '#FFEFCA' : 'transparent' }}>Backup and Restore Data</div>
                    <div onClick={() => handleTabClick(1)} className='tab-feature' style={{ backgroundColor: activeTab === 1 ? '#FFEFCA' : 'transparent' }}>Audit Feature</div>
                </div>
                <AnimatePresence mode="wait">
                    {activeTab === 0 ? 
                        <div className='tab-details'>
                            <div className='tab-text'>You can back up and restore your data for various platforms such as Podio, shopify(coming soon) and Salesforce(coming soon). You have the ability to completely restore any lost data and have access to it.</div>
                            <div className='tab-image'><img src={features1} alt="features one" /></div>
                        </div>
                        : 
                        <div className='tab-details'>
                            <div className='tab-text'>You can audit your apps and spaces on your CRM, access features such as access control and even restoration of lost data on space/app level within Podio. Other platform integrations are coming soon.</div>
                            <div className='tab-image'><img src={features2} alt="features two" /></div>
                        </div>
                    }
                </AnimatePresence>
            </div>
        </section>
        <section className="work-easily">
            <div className="head">
                <div className="title">Other Features</div>
            </div>
            <div className="triple-container">
                <div className="item one" id='1'>
                    <img src={hooks} alt="hooks" />
                    <div className="other-text bold">Hooks Management</div>
                    <div>Manage your hooks easily and remove hooks that are redundant. Have more control of your Data</div>
                </div>
                <div className="item two" id='2'>
                    <img src={app_builder} alt="app builder" />
                    <div className="other-text bold">App Builder</div>
                    <div>We have extended the ability to build your app easily with different layouts. Innovate clearly and easily.</div>
                </div>
                <div className="item three" id='3'>
                    <img src={raw_data} alt="raw data" />
                    <div className="other-text bold">Raw Data</div>
                    <div>Have access to a JSON response of your data and control it however way you want.</div>
                </div>
            </div>
            <div className='triple-container'>
                <div className="item four" id='4'>
                    <img src={users} alt="users" />
                    <div className="other-text bold">User Management</div>
                    <div>Add and remove members of your organisation to your backup data</div>
                </div>
                <div className="item five" id='5'>
                    <img src={gdpr} alt="gdpr" />
                    <div className="other-text bold">GDPR</div>
                    <div>We are GDPR complainant, manage the when you want you data completely removed from our platform</div>
                </div>
            </div>
        </section>
        <Footer bg="#f0b429" />
    </div>
}

export default SyncFeatures
