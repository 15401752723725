import React from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import cancel from "../../assets/icons/cancel.svg";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  headerText: {
    color: "black",
  },
  btnContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  btnDesc: {
    color: "#DE911D",
    marginTop: "3%",
  },
  cont_main: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: "10%",
  },

  "@media (max-width: 600px)": {
    cont_main: {
      flexDirection: "column",
    },
  },
}));

const AlertModal = ({ openModal, handleClose, openDefault, openExternal }) => {
  const classes = useStyles();

  const openDefaultConnector = () => {
    handleClose();
    openDefault();
  };
  const openExternalConnector = () => {
    handleClose();
    openExternal();
  };
  return (
    <div>
      <Dialog
        open={openModal}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            openModal(false);
          }
        }}
        // hideBackdrop={true}
        disableEscapeKeyDown={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="connector-modal"
      >
        <DialogContent>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography>
                <div className={classes.modalHeader}>
                  <h5 className={classes.headerText}>Set up a Connection</h5>
                  <div>
                    <img onClick={handleClose} alt="cancel" src={cancel} />
                  </div>
                </div>

                <div className={classes.cont_main}>
                  <div
                    style={{ marginRight: "5%" }}
                    className={classes.btnContainer}
                  >
                    <Tooltip
                      title="Do you have a database?"
                      style={{ fontSize: "1rem" }}
                    >
                      <button
                        type="button"
                        onClick={openExternalConnector}
                        style={{
                          color: "#000",
                          background: "transparent",
                          border: "1px solid grey",
                          width: "254px",
                          height: "48px",
                          fontSize: "14px",
                        }}
                      >
                        Setup custom connection
                      </button>
                    </Tooltip>
                    <p className={classes.btnDesc}>(Do you have a database?)</p>
                  </div>

                  <div className={classes.btnContainer}>
                    <Tooltip
                      title="Have a database created for you"
                      style={{ fontSize: "1rem" }}
                    >
                      <button
                        type="button"
                        className="btn btn-warning text-white"
                        onClick={openDefaultConnector}
                        style={{
                          width: "254px",
                          height: "48px",
                          fontSize: "14px",
                        }}
                      >
                        Use default connection
                      </button>
                    </Tooltip>
                    <p className={classes.btnDesc}>
                      (Have a database created for you)
                    </p>
                  </div>
                </div>
              </Typography>
            </DialogContentText>
          </DialogContent>
          {/* <DialogActions>
            <Button id="button-cancel" onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions> */}
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default AlertModal;
