import React from 'react'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { TMailSelect } from '../components/TMailSelect';
import { makeStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import { Button, CircularProgress } from '@material-ui/core'
import { useState } from 'react';
import axios from "../../../config";
import { useEffect } from 'react';
import { createDomain } from 'redux/actions/tmail';
import appendTeamMemberHeader from 'utils/appendTeamMemberHeader';
import { toast } from 'helpers/apiRequests';
import Notification from 'components/Notification/Notification';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { useMemo } from 'react';


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    width: '892px',
    boxShadow: theme.shadows[5],
    padding: '26px 33px',
    borderRadius: '5px'
  },
  text_field: {
    background: '#fff',
    width: '100%',
    height: '56px',
    fontSize: '14px',
    marginBottom: '32px'
  }, 
  label: {
    display: 'block',
    color: '#000',
    fontFamily: 'Mulish',
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: "400",
    lineHeight: '25px',
    marginBottom: '10px'
  },
  btn: {
    backgroundColor: '#F0B429',
    borderRadius: '3px',
    textTransform: 'capitalize',
    padding: '12px 32px',
    fontStyle: 'normal',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: 'normal'
  },
  btnCancel: {
    color: '#F0B429',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
    padding: '12px 32px',
    background: 'transparent',
    border: '1px solid #F0B429'
  },
  btnGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '20px'
  }
}));

const DomainModal = ({ open, handleClose }) => {
  const classes = useStyles();
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState({});
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [isDomainValid, setIsDomainValid] = useState(false);
  const [domain, setDomain] = useState("");
  const dispatch = useDispatch()
  const [notificationData, setNotificationData] = useState({
    displayNotification: false,
    msg: "",
    severity: "success",
  });



  const token = "Bearer " + localStorage.getItem("chosen_token");

  // Use useMemo to memoize the headers object
  const headers = useMemo(() => {
    return {
      Authorization: token,
    };
  }, [token]);
  
  // Now, you can create a new object with the modified headers
  const updatedHeaders = appendTeamMemberHeader(headers);

  const getOrganizations = useCallback(async () => {
    try {
      setLoading(true);
      let data = await axios.get(`/sync/podio/orgs/synced`, {
        updatedHeaders
      });
      setOrganizations(
        data.data.map(item => ({ label: item.name, value: item.org_id }))
      );
      setLoading(false);
    } catch (e) {
      setNotificationData({
        displayNotification: true,
        msg: "Error retrieving organizations",
        severity: "error",
      });
    }
  }, [updatedHeaders]);

  useEffect(() => {
    getOrganizations();
  }, [getOrganizations]);

  const validateDomain = (domain) => {
    const result = domain.match(/^[a-z0-9-.]+\.[a-z]{2,4}/);

    if (result) {
      setNotificationData({
        displayNotification: true,
        msg: "A valid Domain",
        severity: "success",
      })
      return true
    }
    setNotificationData({
      displayNotification: true,
      msg: "Invalid Domain",
      severity: "error",
    })
    return false

  }

  const handleOrganizationChange = async (event) => {
    setSelectedOrganization(event.target);
  };

  const handleDomainChange = (e) => {
    const value = e.target.value;
    setIsDomainValid(validateDomain(value));
    setDomain(value)
  }
  
  const changeIsLoadingSaveApp = () => setSaveLoading(false);

  const submitDomain = (e) => {
    try {
      e.preventDefault();
      setSaveLoading(true)
      if (!selectedOrganization || !domain) {
        toast.notify("Please fill all required fields and select an organization", { type: "info" });
        setSaveLoading(false)
        return
      } else if (!validateDomain(domain)) {
        toast.notify("Invalid Domain name", { type: "error" });
      }

      const payload = {
        org_id: selectedOrganization.value,
        domain
      }
   
      dispatch(createDomain(payload, changeIsLoadingSaveApp, handleClose))
      // handleClose();

    } catch (error) {
      setSaveLoading(false)
    }
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <h5 id="transition-modal-title" className='mb-3'>Create Domain</h5>
          <Notification
            displayNotification={notificationData.displayNotification}
            severity={notificationData.severity}
            msg={notificationData.msg}
          />

          <form onSubmit={submitDomain} className='pt-4'>
            <label className={classes.label}>Organization</label>
            <TMailSelect
              required
              placeholder="Select Organization"
              className={classes.text_field}
              name='organization'
              options={organizations}
              onChange={(val) => handleOrganizationChange({ target: val })}
              isDisabled={loading}
              isLoading={loading}
              value={selectedOrganization} 
            />

            <label className={classes.label}>Domain Name</label>
            <TextField 
              id="outlined-basic" 
              className={classes.text_field} 
              variant="outlined" 
              onChange={handleDomainChange} 
              pattern="./*"
            />
            {
              !isDomainValid && domain !== "" ? <center><p className='text-danger'>Invalid domain. E.g domain.com </p></center> : null
            }

            <div className={classes.btnGroup}>
              {
                saveLoading ? 
                  <CircularProgress size={24} />  
                  : 
                  <>
                    <button type='button' className={classes.btnCancel} onClick={handleClose}>
                      Cancel
                    </button>
                    <Button 
                      type='submit'
                      variant='contained'
                      color='primary'
                      disableElevation={true}
                      className={classes.btn}
                      disabled={!isDomainValid}
                    >
                      Create Domain
                    </Button>
                  </>
              }
            </div>
          </form>
        </div>
      </Fade>
    </Modal>
  )
}

export default DomainModal
