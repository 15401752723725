/* eslint-disable no-unused-vars */
import React from 'react';
import ConnectModal from '../../../Connector/ConnectModal';
import { authorizeOrphanEmail, parseHashString } from "../../../Connector/sharedFunctions";
import MyModal from "../../../../components/molecule/Modal";


const AuthoriseModal = ({ openModal, handleClose, info, setConnecting, emailId }) => {

    return (
        <MyModal
            open={openModal}
            close={handleClose}
        >
            <ConnectModal
                info={info}
                handleConnect={() =>
                    authorizeOrphanEmail(info, setConnecting, `/show_message/${emailId}`)
                }
                handleClose={handleClose}
            />
        </MyModal>
    )
}

export default AuthoriseModal