import React from 'react';
import { FormControlLabel, RadioGroup } from '@material-ui/core';

import styles from './ManageSignatures.module.scss';
import { ThemedReactSelect, ThemedAsyncReactSelect } from 'components/templates/print-v2/components/ThemedReactSelect';
import { usePrintData } from 'components/templates/print-v2/context/PrintProvider';
import ThemedRadio from 'components/templates/print-v2/components/ThemedRadio';
import { getItems } from 'components/templates/print-v2/printUtils/requests';
import useAsync from 'components/templates/print-v2/hooks/useAsync';
import { fetchSignerFields } from 'components/templates/print-v2/printUtils/requests';

const transformSignerFieldOptions = (val) => {
  return (i) => {
    const label = val ? `${val} > ${i.name}` : i.name;
    return { label: label, value: i.id, external_id: i.external_id };
  };
};

const Form = ({
  formValues,
  setFormValues,
  setSignees,
  signerFieldsOptions,
  fieldOptionsStatus,
  defaultFormState,
  selectedApp,
  setSelectedApp,
  referencedApps,
}) => {
  const { template } = usePrintData();

  const templateApp = template?.podio_options?.metadata?.app;
  const templateOrgId = template?.podio_options?.metadata?.organization?.org_id;

  const [contactDetailsLocation, setContactDetailsLocation] = React.useState('same_app');
  const isReferencedApp = contactDetailsLocation === 'referenced_app';

  const {
    execute: executeReferencedSignerFields,
    value: referencedSignerFieldOptions,
    status: referencedfieldOptionsStatus,
  } = useAsync((props) => {
    if (props?.app_id) {
      return fetchSignerFields({ app_id: props?.app_id, org_id: templateOrgId })
        .then((res) => {
          return {
            text_fields: res?.data?.text_fields?.map(transformSignerFieldOptions(templateApp?.name)) ?? '',
            email_fields: res?.data?.email_fields?.map(transformSignerFieldOptions(templateApp?.name)) ?? '',
          };
        })
        .catch(console.error);
    }
    return;
  }, false);

  const [sameItems, setSameItems] = React.useState([]);
  const [sameSelectedItem, setSameSelectedItem] = React.useState({ label: '', value: '' });

  const { execute: executeSameItem, status: sameItemStatus } = useAsync((query = '') => {
    return getItems({ app_id: templateApp?.app_id, updater: setSameItems, queryString: query });
  }, false);

  function handleSameItemSearch(query) {
    executeSameItem(query);
  }

  React.useEffect(() => {
    if (!isReferencedApp) {
      handleSameItemSearch('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReferencedApp]);

  const [items, setItems] = React.useState([]);
  const [selectedItem, setSelectedItem] = React.useState({ label: '', value: '' });

  const { execute, status: itemStatus } = useAsync((query = '') => {
    return getItems({ app_id: selectedApp?.value, updater: setItems, queryString: query });
  }, false);

  function handleItemSearch(query) {
    if (selectedApp?.value) {
      execute(query);
    }
  }

  React.useEffect(() => {
    handleItemSearch('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedApp?.value]);

  function handleChange(e) {
    const { name, value } = e.target;
    setFormValues((previousValues) => ({ ...previousValues, [name]: { valueObject: value, name } }));
  }

  function handleMessageChange(e) {
    const { value } = e.target;
    setFormValues((values) => ({ ...values, message: { value } }));
  }

  function handleSelectApp(val) {
    setSelectedApp(val);
    setFormValues(defaultFormState);

    if (isReferencedApp) {
      executeReferencedSignerFields({ app_id: val?.value });
    }
  }

  function resetFormState() {
    setSelectedApp('');
    setSelectedItem({ label: '', value: '' });
    setSameSelectedItem({ label: '', value: '' });
    setFormValues(defaultFormState);
  }

  function handleContactDetailsLocation(event) {
    const { value } = event.target;
    setContactDetailsLocation(value);
    resetFormState();
  }

  function addSigner(e, values) {
    e.preventDefault();
    // prevent empty values
    if (!values?.email?.valueObject?.value || !values?.name?.valueObject?.value) return;
    let uuid = window.crypto.randomUUID();
    const metaData = {
      uuid,
      app_id: !isReferencedApp ? templateApp?.app_id : selectedApp?.value,
      item_id: !isReferencedApp ? sameSelectedItem?.value : selectedItem?.value,
      field_app_location: contactDetailsLocation,
    };
    setSignees((prev) => [...prev, { ...values, ...metaData }]);

    resetFormState();
  }

  return (
    <form className={styles.formContainer}>
      <div>
        <div>
          <div className='d-flex align-items-center mb-2'>
            <label className='mr-3'>Contact's details location:</label>
            <RadioGroup
              aria-label='contactDetailsLocation-list-type'
              id='contactDetailsLocation'
              name='contactDetailsLocation'
              value={contactDetailsLocation}
              onChange={handleContactDetailsLocation}
              className='flex-row'>
              <FormControlLabel
                value='same_app'
                control={<ThemedRadio size='small' />}
                label={<p style={{ fontSize: '13px', marginBottom: 0 }}>Single-line field in Same App</p>}
              />
              <FormControlLabel
                value='referenced_app'
                control={<ThemedRadio size='small' />}
                label=<p style={{ fontSize: '13px', marginBottom: 0 }}>Single-line field in referenced App</p>
              />
            </RadioGroup>
          </div>
          {!isReferencedApp ? (
            <div className='mb-3'>
              <label htmlFor='name'>Select Item</label>
              <ThemedAsyncReactSelect
                name='items'
                placeHolder='Select an Item'
                isClearable={true}
                isDisabled={sameItemStatus === 'pending'}
                isLoading={sameItemStatus === 'pending'}
                value={sameSelectedItem}
                defaultOptions={sameItems}
                cacheOptions
                onChange={(val) => {
                  return setSameSelectedItem(val);
                }}
                loadOptions={handleSameItemSearch}
              />
            </div>
          ) : null}
          {isReferencedApp ? (
            <div className={styles.inputRow}>
              <span>
                <label htmlFor='name'>Select Referenced App</label>
                <ThemedReactSelect
                  required
                  className={styles.text_field}
                  name='application'
                  options={referencedApps}
                  onChange={handleSelectApp}
                  style={{ fontSize: '14px' }}
                  value={selectedApp}
                />
              </span>
              <span>
                <label htmlFor='name'>Select Item</label>
                <ThemedAsyncReactSelect
                  name='items'
                  placeHolder='Select an Item'
                  isDisabled={!selectedApp?.value || itemStatus === 'pending'}
                  isLoading={itemStatus === 'pending'}
                  value={selectedItem}
                  defaultOptions={items}
                  cacheOptions
                  onChange={(val) => {
                    return setSelectedItem(val);
                  }}
                  loadOptions={handleItemSearch}
                />
              </span>
            </div>
          ) : null}
        </div>
        <div className={styles.inputRow}>
          <span>
            <label htmlFor='name'>Select full name</label>
            <ThemedReactSelect
              required
              className={styles.text_field}
              id='name'
              name='name'
              options={isReferencedApp ? referencedSignerFieldOptions?.text_fields : signerFieldsOptions?.text_fields}
              value={formValues.name.valueObject}
              onChange={(val) => {
                handleChange({ target: { name: 'name', value: val } });
              }}
              style={{ fontSize: '14px' }}
              isLoading={(!isReferencedApp && fieldOptionsStatus === 'pending') || (isReferencedApp && referencedfieldOptionsStatus === 'pending')}
              isDisabled={(isReferencedApp && !selectedItem?.value) || (!isReferencedApp && !sameSelectedItem?.value)}
            />
          </span>
          <span>
            <label htmlFor='email'>Select email</label>
            <ThemedReactSelect
              required
              className={styles.text_field}
              id='email'
              name='email'
              options={isReferencedApp ? referencedSignerFieldOptions?.email_fields : signerFieldsOptions?.email_fields}
              value={formValues.email.valueObject}
              onChange={(val) => {
                handleChange({ target: { name: 'email', value: val } });
              }}
              style={{ fontSize: '14px' }}
              isLoading={(!isReferencedApp && fieldOptionsStatus === 'pending') || (isReferencedApp && referencedfieldOptionsStatus === 'pending')}
              isDisabled={(isReferencedApp && !selectedItem?.value) || (!isReferencedApp && !sameSelectedItem?.value)}
            />
          </span>
        </div>
      </div>
      <textarea
        name='message'
        cols='30'
        rows='3'
        placeholder='Optional message'
        value={formValues?.message?.value}
        onChange={handleMessageChange}></textarea>

      <button className='btn btn-success mt-3' onClick={(e) => addSigner(e, formValues)} disabled={!formValues.name || !formValues.email}>
        Add Signer
      </button>
    </form>
  );
};

export default Form;
