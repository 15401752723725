/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from "react";
import axios from '../../../config';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import { AgGridReact } from "ag-grid-react";
// import * as AllModules from "ag-grid-enterprise";
// import { ModuleRegistry } from "ag-grid-enterprise";
import MemberAction from "./utility/MembersAction";
import { CircularProgress } from "@material-ui/core";

// ModuleRegistry.registerModules(Object.keys(AllModules));
const MembersTable = (props) => {
    const filterParams = {
        filterOptions: ["contains", "notContains"],
        debounceMs: 0,
        caseSensitive: false,
        suppressAndOrCondition: true,
    };

    const [loading, setLoading] = useState(false);
    const [members, setMembers] = useState([]);
    function formatRole(string) {
        const value = string.value;
        if (!value || value === "" ) {
            return "";
        }
        return value.charAt(0).toLocaleUpperCase() + value.slice(1);
    }

    const profileRenderer = (profile) =>{
        return <span 
        style={{cursor: "pointer"}} 
        onClick={()=>openProfile(profile.data.profile_link)}
        >{profile.value}</span>
    }

    const openProfile = (link) =>{
        window.open(link, "_blanck");
    }

    const [users] = useState({
        columns: [
            {
                headerName: "Profile ID",
                field: "user_id",
                filter: "agTextColumnFilter",
                filterParams,
                cellRendererFramework: profileRenderer
            },
            {
                headerName: "Name",
                field: "name",
                filter: "agTextColumnFilter",
                filterParams,
            },
            {
                headerName: "Email",
                field: "email",
                filter: "agTextColumnFilter",
                filterParams,
            },
            {
                headerName: "Space Name",
                field: "space_name",
            },
            {
                headerName: "Role",
                field: "role",
                filter: "agTextColumnFilter",
                filterParams,
                valueFormatter: formatRole
            },
            {
                headerName: "Applications",
                field: "actions",
                cellRenderer: "imitateAction"
            },
        ],
        data: [],
    });

    const getSpaceMembers = async (spaceId) => {
        try {
            setLoading(true);
            let res = await axios.get(`/sync/podio/space-members/list?space_id=${spaceId}`);
            const response = res.data;
            const data = response.members.map(member => {
                return {
                    ...member,
                    spaceLink: response.space.space_link,
                    space_name: response.space.space_name
                }
            });
            setMembers(data);
            setLoading(false);

        } catch (e) {
            setLoading(false);
        }
    };

    useEffect(()=>{
        getSpaceMembers(props.spaceId)
    }, [props.spaceId])

    const defaultColDef = {
        flex: 1,
        minWidth: 100,
        filter: true,
        sortable: true,
        resizable: true,
    };
    const autoGroupColumnDef = { minWidth: 200 };
    const frameworks = {
        imitateAction: MemberAction,
        // imitateAction: null
    };
    const paginationPageSize = 100;
    const cacheBlockSize = 100;

    if (loading) {
        return <center>
            <CircularProgress />
        </center>
    }

    return (
        <div className="pb-5">
            <div className="d-flex justify-content-between align-items-center mb-4">
                <div className="d-flex ">
                    
                </div>
                <div className="d-flex">
                </div>
            </div>
            <div
                className="ag-theme-balham-dark"
                style={{
                    height: "650px",
                }}
            >
                <AgGridReact
                    // modules={Object.keys(AllModules)}
                    rowData={members}
                    columnDefs={users.columns}
                    defaultColDef={defaultColDef}
                    autoGroupColumnDef={autoGroupColumnDef}
                    frameworkComponents={frameworks}
                    enableRangeSelection={true}
                    animateRows={true}
                    rowGroupPanelShow="always"
                    rowSelection="multiple"
                    pagination={true}
                    paginationPageSize={paginationPageSize}
                    cacheBlockSize={cacheBlockSize}
                    debug={true}
                    sideBar={true}
                    enableCellTextSelection={true}
                    suppressAggFuncInHeader={true}
                    enableFilter={true}
                    enableSorting={true}
                    // loadingOverlayComponent={loadingOverlayComponent}
                >
                </AgGridReact>
            </div>
        </div>
    );
};

export default MembersTable;
