export const ItemTypes = {
  SECTION_R1C1: "sectionR1C1",
  SECTION_R1C2: "sectionR1C2",
  SECTION_R1C3: "sectionR1C3",
  SECTION_R1C4: "sectionR1C4",
  SECTION_RIGHT_BLOCK_R1C2: "sectionRightBlockR1C2",
  SECTION_LEFT_BLOCK_R1C2: "sectionLeftBlockR1C2",
  FIELD_CALCULATION: "calculation",
  FIELD_CATEGORY: "category",
  FIELD_DATE: "date",
  FIELD_DURATION: "duration",
  FIELD_EMAIL: "email",
  FIELD_IMAGE: "image",
  FIELD_MEMBER: "contact",
  FIELD_LINK: "embed",
  FIELD_LOCATION: "location",
  FIELD_MONEY: "money",
  FIELD_NUMBER: "number",
  FIELD_PHONE: "phone",
  FIELD_PROGRESS: "progress",
  FIELD_RELATIONSHIP: "app",
  FIELD_TAG: "tag",
  FIELD_TEXT: "text",
};

export const supportedGridLayouts = [
  ItemTypes.SECTION_R1C1,
  ItemTypes.SECTION_R1C2,
  ItemTypes.SECTION_R1C3,
  ItemTypes.SECTION_R1C4,
  ItemTypes.SECTION_RIGHT_BLOCK_R1C2,
  ItemTypes.SECTION_LEFT_BLOCK_R1C2,
];

export const supportedInputFields = [
  ItemTypes.FIELD_CALCULATION,
  ItemTypes.FIELD_CATEGORY,
  ItemTypes.FIELD_DATE,
  ItemTypes.FIELD_DURATION,
  ItemTypes.FIELD_EMAIL,
  ItemTypes.FIELD_IMAGE,
  ItemTypes.FIELD_MEMBER,
  ItemTypes.FIELD_LINK,
  ItemTypes.FIELD_LOCATION,
  ItemTypes.FIELD_MONEY,
  ItemTypes.FIELD_NUMBER,
  ItemTypes.FIELD_PHONE,
  ItemTypes.FIELD_PROGRESS,
  ItemTypes.FIELD_RELATIONSHIP,
  ItemTypes.FIELD_TAG,
  ItemTypes.FIELD_TEXT,
];
