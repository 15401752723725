import React from 'react';

const PrintContext = React.createContext(null);

const PrintProvider = ({ children }) => {
  const [template, setTemplate] = React.useState(undefined);
  const [selectedSigner, setSelectedSigner] = React.useState(null);
  const [selectedItem, setSelectedItem] = React.useState(undefined);
  const [templateFields, setTemplateFields] = React.useState({
    title: '',
    file_title: '',
    extension: '',
  });

  const [documentFields, setDocumentFields] = React.useState({
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    orientation: 'portrait',
    size: 'A4',
    pageNumberAlign: 'none',
    pageNumberPosition: 'none',
    headerHeight: 20,
    footerHeight: 20,
    value_when_empty: '',
    value_when_errored: '',
  });

  const value = {
    template,
    setTemplate,
    selectedSigner,
    setSelectedSigner,
    templateFields,
    setTemplateFields,
    documentFields,
    setDocumentFields,
    selectedItem,
    setSelectedItem,
  };
  return <PrintContext.Provider value={value}>{children}</PrintContext.Provider>;
};

export default PrintProvider;

export function usePrintData() {
  const context = React.useContext(PrintContext);

  if (!PrintContext) {
    throw new Error('usePrintData must be used within a print Provider');
  }

  return context;
}
