import React from 'react';
import PrintDashboardTemplate from '../PrintDashboardTemplate';
import Table from '../Table/Table';
import { useHistory } from 'react-router';
import styles from './PrintHome.module.css';
import RenderByPermission from 'components/RenderByPermission';

const defaultPaginationState = { page: 0, per_page: 5 };
const PrintHome = () => {
  const history = useHistory();
  const [paginationData, setpaginationData] = React.useState(defaultPaginationState);
  const [templateCategory, setTemplateCategory] = React.useState('active');

  function handlecategoryChange(e) {
    setTemplateCategory(() => {
      setpaginationData(defaultPaginationState);
      return e.target.value;
    });
  }

  return (
    <RenderByPermission>
      <PrintDashboardTemplate>
        <div className={styles.container}>
          <div className={styles.top_bar}>
            <h1>Dashboard</h1>
            <button onClick={() => history.push('/create-template')}>
              <i className='fas fa-plus'></i>
              New Template
            </button>
          </div>
        </div>
        <div className={styles.table_container}>
          <div className={styles.select_template_category}>
            <select name={templateCategory} value={templateCategory} onChange={handlecategoryChange}>
              <option value='active'>Active Templates</option>
              <option value='archived'>Archived Templates</option>
            </select>
          </div>
          <Table {...{ paginationData, setpaginationData, templateCategory }} />
        </div>
      </PrintDashboardTemplate>
    </RenderByPermission>
  );
};

export default PrintHome;
