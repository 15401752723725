import { makeStyles } from '@material-ui/core';
import React from 'react'
import { useState } from 'react';
import { BsArrowRight, BsChevronDown, BsChevronUp } from 'react-icons/bs';


const useStyles = makeStyles((theme) => ({
  spaceItem: {
    position: 'relative',
    width: '100%',
    backgroundColor: '#F7F7F7',
    marginBottom: '26px',
  },
  spaceHeader: {
    height: '64px',
    padding: '23px 32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '40px',
    color: '#222',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
    textTransform: 'uppercase',
    cursor: 'pointer',
  },
  view: {
    color: "#515151",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    textTransform: 'lowercase'
  },
  td: {
    border: '0px',
    borderBottom: '1px solid #E1E1E1',
    paddingTop: '21px',
    paddingBottom: '21px',
    color: '#626262',
    fontWeight: '400',
    fontSize: '16px',
    textAlign: 'center'
  },
  tableBodyRow: {
    alignItems: 'center',
    backgroundColor: '#fff',
    border: 'none'
  },
  center: {
    textAlign: 'center'
  },
  table: {
    position: 'relative',
    backgroundColor: '#fff',
    marginTop: '0px'
  },
  textBlack: {
    color: '#010101'
  },
  link: {
    cursor: 'pointer'
  }
}));

const Space = ({ data }) => {
  const classes = useStyles()
  const [show, setShow] = useState(false)

  const navigateUrl = (url) => {
    let element = document.createElement('a');
    
    if (url) {
      if(url.startsWith('http://') || url.startsWith('https://')){
        element.href =  url;
      } else{
        element.href = 'http://' + url;
      }
      element.target = '_blank';
      
      element.click();
    }
  }

  return (
    <div className={classes.spaceItem}>
      <div className={classes.spaceHeader}>
        <div>
          <span className={classes.textBlack} onClick={() => navigateUrl(data?.space?.source_space_link ? data?.space?.source_space_link : '')}>{data?.space?.source_space_name}</span> &nbsp;
          <BsArrowRight /> &nbsp;
          <span className={classes.textBlack} onClick={() => navigateUrl(data?.space?.destination_space_link ? data?.space?.destination_space_link : '')}>{data?.space?.destination_space_name}</span> 
        </div>
        <div className={classes.view} onClick={() => setShow(prev => !prev)}> 
          View all apps
          &nbsp;
          {show ? <BsChevronUp /> : <BsChevronDown />}
        </div>
      </div>
      {show && <div className="">
        <table className={classes.table}>
          <tbody>
            {data?.apps?.length < 1 && <tr className={classes.tableBodyRow}>
              <td className={`${classes.td} ${classes.center}`}>No apps found</td>
            </tr>}
            {data?.apps?.length > 0 && data?.apps?.map((item) => <tr key={item.destination_app_id} className={classes.tableBodyRow}>
              <td className={classes.td}>{item.source_app_id}</td>
              <td className={`${classes.td} ${classes.link}`} onClick={() => navigateUrl(item?.source_app_link ? item?.source_app_link : '')}>{item.source_app_name}</td>
              <td className={classes.td}>
                <BsArrowRight />
              </td>
              <td className={classes.td}>{item.destination_app_id}</td>
              <td className={`${classes.td} ${classes.link}`} onClick={() => navigateUrl(item?.destination_app_link ? item?.destination_app_link : '')}>{item.destination_app_name}</td>
            </tr>)}
          </tbody>
        </table>
      </div>}
    </div>
  )
}

export default Space
