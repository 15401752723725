import React from "react";
import { useDrop } from "react-dnd";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  deleteAppField
} from "../../../services/sync";
import { addSection, removeSection, removeField } from "../../../redux/actions/appBuilder";
import { ItemTypes, supportedGridLayouts } from "../../../constants/ItemTypes";
import Grid from "@material-ui/core/Grid";
import DraggableSectionR1C1 from "./Elements/Sections/DraggableSectionR1C1";
import DraggableSectionR1C2 from "./Elements/Sections/DraggableSectionR1C2";
import DraggableSectionR1C3 from "./Elements/Sections/DraggableSectionR1C3";
import DraggableSectionR1C4 from "./Elements/Sections/DraggableSectionR1C4";
import DraggableSectionLeftBlockR1C2 from "./Elements/Sections/DraggableSectionLeftBlockR1C2";
import DraggableSectionRightBlockR1C2 from "./Elements/Sections/DraggableSectionRightBlockR1C2";

const DrawingCanvas = ({ appSections, currentSection }) => {
  const dispatch = useDispatch();
  const params = useParams();

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: supportedGridLayouts,
    drop: (item, monitor) => dragHandler(item, monitor),

    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
      handlerId: monitor.getHandlerId(),
    }),
  }));
  const isActive = canDrop && isOver;

  const removeSelectedSection = (uniqueId) => {
    appSections.find((section) => {
      if (section.id !== uniqueId) {
        for (let column in section.columns) {
          if (section.columns[column].length > 0) {
            section.columns[column].map(async (item) => {
              let payload = {
                sectionId: uniqueId,
                fieldId: item.field_id,
                columnType: column
              }
              if (item.external_id) {
                let data = {
                  org_id: params.org_id,
                  app_id: params.app_id,
                  field_id: item.field_id,
                }
                await deleteAppField(data);
              }
              dispatch(removeField(payload));
            });
          }
        }
      }
      return section;
    })
    dispatch(removeSection(uniqueId));
  };

  const dragHandler = (section, monitor) => {
    dispatch(addSection(section));
  };

  const displayLayout = () => {
    return appSections.map((section, key) => {
      if (section.type === ItemTypes.SECTION_R1C1) {
        return (
          <DraggableSectionR1C1
            key={section.id}
            sectionId={section.id}
            view="drawing_canvas"
            handleRemoveSection={removeSelectedSection}
          />
        );
      }

      if (section.type === ItemTypes.SECTION_R1C2) {
        return (
          <DraggableSectionR1C2
            key={section.id}
            sectionId={section.id}
            view="drawing_canvas"
            handleRemoveSection={removeSelectedSection}
          />
        );
      }

      if (section.type === ItemTypes.SECTION_R1C3) {
        return (
          <DraggableSectionR1C3
            key={section.id}
            sectionId={section.id}
            view="drawing_canvas"
            handleRemoveSection={removeSelectedSection}
          />
        );
      }

      if (section.type === ItemTypes.SECTION_R1C4) {
        return (
          <DraggableSectionR1C4
            key={section.id}
            sectionId={section.id}
            view="drawing_canvas"
            handleRemoveSection={removeSelectedSection}
          />
        );
      }

      if (section.type === ItemTypes.SECTION_LEFT_BLOCK_R1C2) {
        return (
          <DraggableSectionLeftBlockR1C2
            key={section.id}
            sectionId={section.id}
            view="drawing_canvas"
            handleRemoveSection={removeSelectedSection}
          />
        );
      }

      if (section.type === ItemTypes.SECTION_RIGHT_BLOCK_R1C2) {
        return (
          <DraggableSectionRightBlockR1C2
            key={section.id}
            sectionId={section.id}
            view="drawing_canvas"
            handleRemoveSection={removeSelectedSection}
          />
        );
      }

      return <div key={key}></div>;
    });
  };

  return (
    <div
      className="drawing-canvas pt-2"
      ref={drop}
      style={{
        width: "100%",
        background: "0 0",
        border: "1px dashed #c9d1dd",
        padding: "30px 30px",
      }}
    >
      {isActive ? (
        <p className="text-center">Release to drop</p>
      ) : (
        <p className="text-center">Drag item here</p>
      )}
      <Grid container spacing={1}>
        {displayLayout()}
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  appSections: state.app_builder.appSections,
  currentSection: state.app_builder.draggedSection,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DrawingCanvas);
