import React from "react";
import "../ConfirmModal.css";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import cancel from "../../../assets/icons/cancel.svg";

const useStyles = makeStyles(() => ({
  dFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "1.5rem",
  },
  button: {
    marginTop: 20,
    background: "#2871B6",
    color: "#fff",
    border: "none",
    width: "100%",
    padding: "10px 30px",
    borderRadius: "4px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#2871B6",
    },
  },
  cancel: {
    color: "#2871B6",
    marginTop: 10,
    cursor: "pointer",
  },
  buttonProgress: {
    color: green[600],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },

  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5% 5% 0 5%",
  },
  headerText: {
    color: "black",
  },
  btnContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  btnDesc: {
    color: "#DE911D",
    marginTop: "3%",
  },
}));

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const PreviewWorkflowModal = ({
  openModal,
  closeModal,
  toggleModal,
  responseData,
  flowId
}) => {
  const classes = useStyles();

  function formatXml(xml) {
    let formatted = "";
    const reg = /(>)(<)(\/*)/g;
    xml = xml?.replace(reg, "$1\r\n$2$3");
    let pad = 0;
    xml?.split("\r\n").forEach((node) => {
      let indent = 0;
      if (node.match(/.+<\/\w[^>]*>$/)) {
        indent = 0;
      } else if (node.match(/^<\/\w/)) {
        if (pad !== 0) {
          pad -= 1;
        }
      } else if (node.match(/^<\w[^>]*[^]>.*$/)) {
        indent = 1;
      } else {
        indent = 0;
      }
      const padding = new Array(pad + 1).join("  ");
      formatted += padding + node + "\r\n";
      pad += indent;
    });
    return formatted;
  }

  const formattedResponse = formatXml(responseData);
  // console.log(formattedResponse);

  return (
    <div>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={openModal}
        onClose={closeModal}
      >
        <div className={classes.modalHeader}>
          <p
            style={{
              fontSize: "16px",
              fontWeight: "600",
              color: "black",
              width: "350px",
              marginBottom: "0",
            }}
          >
            Flow Details - {flowId}
          </p>
          <div>
            <img onClick={toggleModal} alt="cancel" src={cancel} />
          </div>
        </div>

        <DialogContent>
          <div
            style={{
              color: "black",
              fontWeight: "400",
              marginBottom: "3%",
              fontSize: "14px",
              width: "600px",
              marginLeft: "3%",
              marginTop: "5%"
            }}
          >
            <code>
              <pre>{formattedResponse}</pre>
            </code>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PreviewWorkflowModal;
