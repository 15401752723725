import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Typography,
} from "@material-ui/core";


const AcceptanceModal = ({openModal, handleClose, message, handleAccept}) => {
    const acceptYes = () => {
        handleAccept();
        handleClose();
    }
  return (
    <div>
        <Dialog
                open={openModal}
                onClose={handleClose}
                // hideBackdrop={true}
                disableEscapeKeyDown={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                id="connector-modal"
            >
                <DialogContent>

                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Typography gutterBottom>
                                {message}
                            </Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button id="button-cancel" onClick={handleClose} color="primary">
                            No
                        </Button>

                        <Button
                            id="button-save"
                            onClick={acceptYes}
                            color="primary"
                            autoFocus
                        >
                            Yes
                        </Button>
                    </DialogActions>
                </DialogContent>
                </Dialog>
    </div>
  )
}

export default AcceptanceModal