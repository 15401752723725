function appendTeamMemberHeader(headers) {
  const newHeaders = { ...headers };
  const team_member_uuid = localStorage.getItem('team_member_uuid');
  if (team_member_uuid && team_member_uuid !== null) {
    newHeaders['x-team-member-uuid'] = team_member_uuid;
  }

  return newHeaders;
}

export default appendTeamMemberHeader;
