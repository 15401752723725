import React from 'react'
import styles from "./OrphanEmailMap.module.css";

const MapperList = ({ label, value }) => {
    return (
        <div className="form-row" >
            <div className="form-group col-md-5">
                <label
                    style={{
                        fontWeight: "normal",
                        fontSize: "18px",
                        marginTop: "5px",
                        marginLeft: "20px"
                    }}
                    className='text-bold'
                >
                    {label}:
                </label>
            </div>
            <div className="form-group col-md-7">
                <input
                    type="text"
                    value={value}
                    className={`form-control ${styles.disabled_input}`}
                    readOnly
                />
            </div>
        </div>
    )
}

export default MapperList