import React from 'react';
import Select from 'react-select';
// import AsyncSelect from 'react-select/async';

const PdcSelect = (props) => {
  return (
    <Select
      {...props}
      theme={(theme) => {
        return {
          ...theme,
          colors: {
            ...theme.colors,
            ...custiomTheme,
          },
        };
      }}
    />
  );
};

// const ThemedAsyncReactSelect = (props) => {
//   return (
//     <AsyncSelect
//       {...props}
//       theme={(theme) => {
//         return {
//           ...theme,
//           colors: {
//             ...theme.colors,
//             ...custiomTheme,
//           },
//         };
//       }}
//     />
//   );
// };

export { 
  // ThemedAsyncReactSelect, 
  PdcSelect 
};

const custiomTheme = {
  primary25: 'rgba(0, 0, 0, 0.2)',
  primary: '#F7C948',
  primary50: 'rgba(0, 0, 0, 0.5)',
  primary75: 'rgba(0, 0, 0, 0.7)',
};