import React from 'react'
// styles
import './style.scss'

const Button = e => {
    return <button disabled={e.disable} type={e.type} className={e.styling} onClick={e.function} id={e.id}>
        {e.img && <img src={e.img} alt="" />} {e.text}
    </button>
}

export default Button
