import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import Pusher from "pusher-js";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { hookKiller } from "../../../services/sync";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  text: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  "@media screen and (max-width: 600px)": {
    hook_manager: {
      width: "90vw !important",
    },
  },
}));
const HooksKiller = ({ orgs, handleOpenSnack, user }) => {
  const [spacing] = React.useState(2);

  const [newValue, setValue] = useState("");
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const pusher = new Pusher("91c3f23431d0a0e33eee", {
      cluster: "mt1",
      encrypted: true,
    });
    const channel = pusher.subscribe(`${user.podio_id}_hook_killer`);

    channel.bind("UserEvent", (data) => {
      //setPopup(true)
      handleOpenSnack("Your hooks have been killed");
    });
  }, [user.podio_id, handleOpenSnack]);

  const handleChange = (event) => {
    setUrl(event.target.value);
  };

  const killHook = () => {
    setLoading(true);
    hookKiller(`?org_id=${newValue.id}&url=${url}`)
      .then((res) => {
        setLoading(false);
        handleOpenSnack("Your hooks are being killed");
        setValue("");
        setUrl("");
      })
      .catch((err) => {
        setLoading(false);
        handleOpenSnack("There was an error");
        setValue("");
        setUrl("");
      });
  };

  return (
    <>
      <div className="pt-20">
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={spacing}>
              <div
                style={{ marginTop: "20px", width: "100%", padding: "0 2%" }}
                className={classes.hook_manager}
              >
                <div>
                  <label style={{ fontWeight: "600", marginBottom: "10px" }}>
                    Select an Organization{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <Autocomplete
                    id="combo-box-demo"
                    options={orgs}
                    getOptionLabel={(option) =>
                      option.title ? option.title : ""
                    }
                    onChange={(event, newValue) => {
                      setValue(newValue);
                    }}
                    value={newValue}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // label="Select an Organization"
                        name="thedata"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </div>
                <div style={{ marginTop: "15px" }}>
                  <label style={{ fontWeight: "600", marginBottom: "10px" }}>
                    URL <span className="text-danger">*</span>
                  </label>

                  <TextField
                    id="outlined-basic"
                    value={url}
                    // label="URL"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                  />
                </div>
                <div style={{ marginTop: "15px" }}>
                  <Button
                    style={{ background: "#FBB03B", padding: "10px 0" }}
                    variant="primary"
                    onClick={killHook}
                    color="primary"
                    fullWidth
                  >
                    {loading ? "Please Wait..." : "KILL HOOKS"}
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.authUser,
});

export default connect(mapStateToProps, null)(withRouter(HooksKiller));
