import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { makeStyles } from "@material-ui/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import { Alert } from "@material-ui/lab";
import Dialog from "@material-ui/core/Dialog";
import Snackbar from "@material-ui/core/Snackbar";
import "./Hooks.css";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { ChartHandler } from "./ChartHandler";
import {
  getHooksChart,
  getHooksLog,
  getSyncedOrganizations,
} from "../../services/sync";
import LatestGridHooksClass from "./LatestHooksGridClass";
import HooksKiller from "./Killer/HooksKiller";
import Summary from "./Summary";
import Chart from "./Chart/HooksChart";
import { setOrgs } from "../../redux/actions/sync";
import { hooksPage } from "../../redux/actions/hooks";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: 2,
  },
  // backButton: {
  //   backgroundColor: "#f7c948",
  //   boxShadow: "0px 3px 6px #00000029",
  //   padding: "1vh 1vw",
  //   outline: "none",
  //   textDecoration: "none",
  //   border: "none",
  //   borderRadius: "6px",
  //   color: "white",
  //   marginBottom: "3%"
  // }
}));

const Hooks = (props) => {
  const [tableValue, setTableValue] = useState(0);
  const [widgets, setWidgets] = useState(null);
  const [orgs, setOrgs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snack, setSnack] = React.useState({
    message: null,
    snackType: "success",
    isSnack: false,
  });
  const [message, setMessage] = useState("");
  const classes = useStyles();
  const [popup, setPopup] = useState(false);

  const [hooksLog, setHooksLog] = useState(null);
  const [dailyHooks, setDailyHooks] = useState("");
  const [totalHooks, setTotalHooks] = useState("");
  const [monthlyHooks, setMonthlyHooks] = useState("");
  const dispatch = useDispatch();
  const hookState = useSelector((state) => state.hooks.hookPage);
  const user = props.user;
  const vertical = "bottom";
  const horizontal = "center";

  useEffect(() => {
    getSyncedOrganizations().then((response) => props.setOrgs(response.data));

    if (user.hook_block) {
      setSnack({
        message: "Your hooks are disabled, Please contact the admin",
        snackType: "warning",
        isSnack: true,
      });
    }

    getHooksChart().then((res) => {
      buildWidgetData(res);
    });

    if (hookState.success) {
      setHooksLog(hookState.data.data);
      setDailyHooks(hookState.data.daily_hooks);
      setTotalHooks(hookState.data.yearly_hooks);
      setMonthlyHooks(hookState.data.monthly_hooks);
      setLoading(false);
    } else {
      console.log("checking if it is called");
      setLoading(false);
      getHooksLog().then((res) => {
        dispatch(hooksPage({ data: res }));
        setHooksLog(res.data);
        setDailyHooks(res.daily_hooks);
        setTotalHooks(res.yearly_hooks);
        setMonthlyHooks(res.monthly_hooks);
      });
    }

    buildOrgs(props.orgs);
    // eslint-disable-next-line
  }, [user.hook_block]);

  const handleSnackClose = () => {
    setSnack({ message: null, snackType: "success", isSnack: false });
  };

  const syncHandleClose = () => {
    setPopup(false);
  };

  const handleOpenSnack = (message) => {
    setPopup(true);
    setMessage(message);
  };

  const buildOrgs = (orgs) => {
    let build = orgs.map((org) => {
      return { title: org.name, id: org.org_id };
    });

    return setOrgs(build);
  };

  const buildWidgetData = (data) => {
    const widgetData = ChartHandler(data);
    setWidgets(widgetData);
  };

  const handleTableTabs = (event, newValue) => {
    setTableValue(newValue);
  };

  if (!widgets) {
    return (
      <div className="material_block">
        <svg
          className="spinner"
          width="65px"
          height="65px"
          viewBox="0 0 66 66"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            className="circle"
            stroke="#ffc107"
            fill="none"
            strokeWidth="6"
            strokeLinecap="round"
            cx="33"
            cy="33"
            r="30"
          ></circle>
        </svg>
      </div>
    );
  }

  if (snack.isSnack || user.hook_block) {
    return (
      <Dialog
        open={snack.isSnack}
        onClose={handleSnackClose}
        aria-labelledby="form-dialog-title"
      >
        <Alert onClose={handleSnackClose} severity={snack.snackType}>
          {snack.message}
        </Alert>
      </Dialog>
    );
  }

  if (loading) {
    return (
      <div className="material_block">
        <svg
          className="spinner"
          width="65px"
          height="65px"
          viewBox="0 0 66 66"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            className="circle"
            fill="none"
            strokeWidth="6"
            stroke="#ffc107"
            strokeLinecap="round"
            cx="33"
            cy="33"
            r="30"
          ></circle>
        </svg>
      </div>
    );
  }

  // const handleBackButtonClick = () => {
  //   window.history.back();
  // };

  return (
    <div className="p-24">
      <Snackbar
        open={popup}
        autoHideDuration={5000}
        anchorOrigin={{ vertical, horizontal }}
        key={`${vertical},${horizontal}`}
        onClose={syncHandleClose}
      >
        <Alert
          onClose={syncHandleClose}
          severity="info"
          style={{
            width: "300px !important",
            position: "relative",
            bottom: "250px",
            left: "150px",
            fontSize: "20px",
          }}
        >
          {message}
        </Alert>
      </Snackbar>

      {/* <div>
          <button onClick={handleBackButtonClick} className={classes.backButton}>Back</button>
        </div> */}
      <Summary
        loading={loading}
        dailyHooks={dailyHooks}
        monthlyHooks={monthlyHooks}
        totalHooks={totalHooks}
      />
      <div className="mt-10 mb-10 chartMobile">
        <Chart data={widgets} />
      </div>
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12}>
          <div className="pt-15">
            <Paper>
              <Tabs
                value={tableValue}
                onChange={handleTableTabs}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="Latest Hooks" />
                <Tab label="Hooks Killer" />
              </Tabs>
            </Paper>
          </div>
        </Grid>
        <Grid item xs={12}>
          {tableValue === 0
            ? hooksLog !== null && (
                <LatestGridHooksClass hooksLog={hooksLog} user={user} />
              )
            : ""}
          {tableValue === 1 ? (
            user?.type || user?.permissions?.tools.hook_killer ? (
              <HooksKiller orgs={orgs} handleOpenSnack={handleOpenSnack} />
            ) : (
              <Redirect to="/dashboard/account?section=plans&message=unsupported-plan" />
            )
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.authUser,
  orgs: state.sync.orgs,
});

const mapDispatchToProps = (dispatch) => ({
  setOrgs: (data) => dispatch(setOrgs(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Hooks));
