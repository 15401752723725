import React, { useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
// import * as AllModules from "ag-grid-enterprise";
// import { ModuleRegistry } from "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import MainAction from "./gridActions/MainActions";
import Endpoint from "services/Endpoint";
import Switch from "../../Items/Switch";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

// ModuleRegistry.registerModules(Object.keys(AllModules));
const FileGrid = (props) => {
  const gridApi = useRef();
  // const history = useHistory();
  const params = useParams();
  const [checked, setChecked] = useState(true);
  const [gridApiNew, setGridApi] = useState(null);

  const defaultColDef = {
    resizable: true,
    flex: 1,
    minWidth: 100,
    enableValue: true,
    enableRowGroup: props.user?.permissions?.data_interaction?.row_grouping
      ? true
      : false,
    enablePivot: props.user?.permissions?.data_interaction?.pivot_reporting
      ? true
      : false,
    sortable: true,
    filter: "agTextColumnFilter",
    filterParams: {
      suppressAndOrCondition: true,
    },
  };

  const autoGroupColumnDef = { minWidth: 200 };
  const paginationPageSize = 100;
  const cacheBlockSize = 100;
  const rowModelType = "serverSide";

  useEffect(() => {
    (async () => {
      props.match.url.includes("delete") ? setChecked(false) : setChecked(true);
    })();
  }, [props.match.url]);

  // useEffect(() =>{
  //   gridApi.current.refreshCells({ force: true });
  // }, [])

  const handleChecked = (event) => {
    const url = window.location.origin;

    setChecked(event.target.checked);
    // gridApi.current.refreshCells({ force: true });
    if (!checked) {
      props.history.push(`/dashboard/view/${params.org_id}`);
      window.location.href = `${url}/dashboard/view/${params.org_id}`;
      gridApiNew.refreshCells({ force: true });
    } else {
      window.location.href = `${url}/dashboard/deleted-file/${params.org_id}`;
      // props.history.push(`/dashboard/deleted-file/${params.org_id}`);
      gridApiNew.refreshCells({ force: true });
    }
  };

  const location = useLocation();
  const paramsId = new URLSearchParams(location.search);
  const appId = paramsId.get('app_id');

  // console.log(appId);

  const ServerSideDatasource = () => {
    return {
      getRows: function (params) {
        const skip = params.request.startRow;
        const limit = paginationPageSize;

        let query = "&api_v2=true";

        if (params.request.sortModel.length > 0) {
          query = `&sort=${params.request.sortModel[0].sort}&sort_field=${params.request.sortModel[0].colId}`;
        }

        if (params.request.groupKeys.length > 0) {
          const group_value = params.request.groupKeys[0];
          const group_field = params.request.rowGroupCols[0].field;

          query = `${query}&group_field=${group_field}&group_value=${group_value.replace(
            "&",
            "andReplaced"
          )}`;
        }

        if (Object.entries(params.request.filterModel).length > 0) {
          let search_key = Object.keys(params.request.filterModel).length;

          if (search_key === 1) {
            search_key = Object.keys(params.request.filterModel)[0];
          } else {
            search_key = Object.keys(params.request.filterModel)[
              Object.keys(params.request.filterModel).length - 1
            ];
          }

          const filterData = params.request.filterModel[search_key];

          query = `${query}&search_word=${filterData.filter}&filter_field=${search_key}&filter=${filterData.type}`;
        }

        let deleted_from_podio = false;
        if (props.match.url.includes("delete")) {
          deleted_from_podio = true;
        }

        Endpoint.getFiles(
          props.org_id,
          appId,
          props.user.database.database,
          skip,
          limit,
          query,
          deleted_from_podio
        )
          .then((result) => {
            const total_count = result?.data?.count;
            const mainData = result?.data?.data ?? result?.data;
            const RowArray = [];

            mainData.map((Ddata, i) => {
              return RowArray.push({
                app_name: Ddata?.app_name,
                app_id: Ddata?.app_id,
                item_title: Ddata?.item_title,
                file_id: Ddata?.file_id,
                name: Ddata?.name,
                size: Ddata?.size,
                mimetype: Ddata?.mimetype,
                link: Ddata?.link,
                created_by: Ddata?.created_by?.name,
                hosted_by: Ddata?.hosted_by,
                deleted_from_podio: Ddata?.["deleted_from_podio"]
                  ? Ddata?.deleted_from_podio
                  : "Exists",
                org_id: props?.org_id,
                db: props?.user?.database?.database,
              });
            });

            let theRowCol = [];

            if (
              Array.isArray(params.request.rowGroupCols) &&
              params.request.rowGroupCols.length > 0
            ) {
              params.request.rowGroupCols.forEach((rowCol) => {
                theRowCol.push({
                  field: rowCol.field,
                  displayName: rowCol.displayName,
                });
              });
            }

            setTimeout(function () {
              let endResults = [];
              let compiled;
              let data = false;

              if (
                Array.isArray(params.request.groupKeys) &&
                params.request.groupKeys.length
              ) {
                data = true;
              }

              if (theRowCol.length > 0 && !data) {
                theRowCol.forEach((x) => {
                  RowArray.map((item) => {
                    const mainKey = x.field;

                    const val = item[mainKey];
                    const check = endResults.some(function (o) {
                      return o[mainKey] === val;
                    });

                    if (!check) {
                      endResults.push(item);
                    }

                    return null;
                  });
                });
                compiled = endResults;
              } else {
                compiled = RowArray;
              }

              params.successCallback(compiled, total_count);
            }, 200);
          })
          .catch((err) => params.failCallback());
      },
    };
  };

  const onGridReady = (params) => {
    setGridApi(params.api);

    gridApi.current = params.api; // <== to access the Actions on Grid

    const theData = ServerSideDatasource();

    params.api.setServerSideDatasource(theData);
  };

  const frameworks = {
    downloadAction: MainAction,
  };

  const onRowSelected = (event) => {
    props.updateSelectedFields(event);
  };

  // const refreshTable = () => {
  //   generateHooks();

  //   if (hookType === "app" && org) {
  //     gridApi.refreshCells({
  //       force: true,
  //     });
  //   }

  //   if (hookType === "space" && org) {
  //     gridApi.refreshCells({
  //       force: true,
  //     });
  //   }

  //   if (hookType === "app_fields_hooks" && org) {
  //     gridApi.refreshCells({
  //       force: true,
  //     });
  //   }
  // };

  return (
    <div
      className="ag-theme-balham-dark"
      style={{
        height: "700px",
        width: "100%",
      }}
    >
      <div
        style={{ justifyContent: "flex-end", marginTop: "-2%" }}
        className="d-flex"
      >
        <Switch handleChecked={handleChecked} checked={checked} />
      </div>

      <AgGridReact
        // modules={Object.keys(AllModules)}
        columnDefs={props.files.columns}
        defaultColDef={defaultColDef}
        autoGroupColumnDef={autoGroupColumnDef}
        rowModelType={rowModelType}
        frameworkComponents={frameworks}
        enableRangeSelection={true}
        checkboxSelection={true}
        animateRows={true}
        overlayLoadingTemplate={
          '<span className="ag-overlay-loading-center">Please wait while your rows are loading</span>'
        }
        overlayNoRowsTemplate={
          "<span style=\"padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;\">This is a custom 'no rows' overlay</span>"
        }
        onGridReady={onGridReady}
        rowGroupPanelShow="always"
        rowSelection="multiple"
        pagination={true}
        paginationPageSize={paginationPageSize}
        cacheBlockSize={cacheBlockSize}
        debug={true}
        sideBar={true}
        enableCellTextSelection={true}
        suppressAggFuncInHeader={true}
        enableFilter={true}
        enableSorting={true}
        groupUseEntireRow={true}
        onRowSelected={onRowSelected}
      />
    </div>
  );
};

export default FileGrid;
