import React from "react";
import "./ConfirmModal.css";
import cancelIcon from "../../assets/icons/cancel.svg";

const OrgUpdateModal = ({ setOrgUpdateModal, action, the_data }) => {
  const [name, setName] = React.useState(the_data.org_name);

  const handleChange = (event) => {
    setName(event.target.value);
  };

  return (
    <div className="confirm__modal">
      <div className="modal__content">
        <div>
          <div className="modal__card">
            <div style={{ width: "100%" }}>
              <div className="modal__header">
                <h4>Edit Organisation</h4>
                <img
                  onClick={setOrgUpdateModal}
                  src={cancelIcon}
                  alt="cancel"
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleFormControlSelect1">
                  Organisation name
                </label>
                <input
                  type="text"
                  className="form-control"
                  style={{ marginTop: "1rem" }}
                  onChange={handleChange}
                  value={name}
                  name="org_name"
                />
              </div>
              <div className="modal__footer">
                <button
                  onClick={() => {
                    if (name !== null) action(the_data.org_id, name);
                  }}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrgUpdateModal;
