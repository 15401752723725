import React from 'react'
import { Modal } from '@material-ui/core'
import { Close } from '@material-ui/icons';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  modalPresentation: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    // padding: '50px 0',
  },
  modal: {
    position: 'relative',
    width: '100%',
    height: '100%',
    borderRadius: '6px',
    backgroundColor: '#FFF',
    margin: 'auto',
    padding: '41px 64px',
    overflowY: 'auto'
  },
  modalTitle: {
    color: '#3B3B3B',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
  },
  modalText: {
    color: '#626262',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
  },
  border: {
    width: '100%',
    height: '1px',
    background: '#E1E1E1',
    margin: '32px 0 0 0'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '24px'
  },
  btn: {
    width: '32px',
    height: '32px',
    padding: '0px',
    background: 'transparent',
    border: 'none'
  }
}));


const LogsLayout = ({ open, handleClose, title, headerChildren, children }) => {
  const classes = useStyles()

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modalPresentation}
    >
      <div className={classes.modal}>
        <div className={classes.header}>
          <h4 className={classes.modalTitle}>{title}</h4>
          <button type='button' className={classes.btn} onClick={handleClose}>
            <Close  />
          </button>
        </div>
        {headerChildren}
        <div className={classes.border}></div>
          {children}
      </div>
    </Modal>
  )
}

export default LogsLayout;