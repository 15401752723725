import { useSelector } from "react-redux";
const isTeamMember = localStorage.getItem('team_member_uuid');

const RenderByPermission = ({ allowTeamMemberAccess = true, children }) => {
  const user = useSelector((state) => state.user.authUser);
  if (!isTeamMember) return children;

  // 1 conotes team-member/sub admin user access
  // 2 conotes regular/readonly user access
  if (isTeamMember && allowTeamMemberAccess && user?.team_member?.role_id === 1) return children;

  return null;
};

export default RenderByPermission;
