import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import { formatNumber } from "utils/formatNumber";
import React from "react";
import copyWhite from "../../../assets/icons/copyWhite.svg";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  spaceItem: {
    height: "64px",
    width: "100%",
    backgroundColor: "#F7F7F7",
    marginBottom: "16px",
    padding: "23px 32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "40px",
    color: "#222",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    textTransform: "uppercase",
  },
  btn: {
    fontSize: "19px",
    color: "#E10000 !important",
    backgroundColor: "transparent",
    border: "none",
    height: "fit-content",
    width: "fit-content",
  },
  container: {
    display: "flex",
    gap: "73px",
  },
  cartItems: {
    width: "734px",
  },
  checkoutBox: {
    width: "350px",
  },
  checkoutTitle: {
    color: "#25252D",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    letterSpacing: "-0.32px",
  },
  priceBox: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    borderTop: "1px solid #E0E0E0",
    borderBottom: "1px solid #E0E0E0",
    padding: "20px 0px",
    marginBottom: "5px",
  },
  priceBox1: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    borderTop: "1px solid #E0E0E0",
    padding: "20px 0px",
    marginBottom: "5px",
  },
  price: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  priceTextStyle: {
    color: "#000",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    marginBottom: "0px",
  },
  btnContainer: {
    marginTop: "30px",
  },
  btnWhiteText: {
    backgroundColor: "#F0B429",
    borderRadius: "3px",
    textTransform: "capitalize",
    padding: "12px 32px",
    color: "#222 !important",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    width: "100%",
  },
  spaceItemDetails: {
    fontSize: "15px",
    color: "#535c68 !important",
    textTransform: "capitalize",
  },
  freeCont: {
    width: "350px",
    border: "1px solid #F2B11B",
    padding: "5%",
    backgroundColor: "#FFFBF1",
    borderRadius: "10px",
  },
}));

const CheckoutCardV2 = ({
  selectedWorkflows,
  responseData,
  onClick,
  btnTitle,
  loading,
  disabled,
  data,
  flowData,
}) => {
  const classes = useStyles();
  const user = useSelector((state) => state?.user?.authUser);

  return (
    <div>
      {user.free_trial === true ? (
        <div className={classes.freeCont}>
          <p style={{ fontSize: "14px" }}>
            Your cloning process will begin automatically. You can monitor its
            progress in the activity log. By clicking 'Proceed to Clone,' you
            acknowledge that the transfer will rely on your synced data and may
            be impacted when your trial expires.
          </p>
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <button
              onClick={onClick}
              style={{ width: "100%", color: "white" }}
              className="generate_btn"
            >
              <img src={copyWhite} alt="" />
              Proceed to Clone
            </button>
          )}
        </div>
      ) : (
        <div className={classes.checkoutBox}>
          <h5 className={classes.checkoutTitle}>Order Summary</h5>
          <section>
            {
              flowData?.clone_type === "workflow" ?
                <div className={classes.priceBox1}>
                  {responseData?.hasOwnProperty("spaces_count") ? (
                    <div className={classes.price}>
                      <h6 className={classes.priceTextStyle}>Total Space</h6>
                      <p className={classes.priceTextStyle}>
                        {responseData?.spaces_count}
                      </p>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <div className={classes.price}>
                    <h6 className={classes.priceTextStyle}>Total Workflows</h6>
                    <p className={classes.priceTextStyle}>
                      {responseData?.workflows_count}
                    </p>
                  </div>
                  <div className={classes.price}>
                    <h6 className={classes.priceTextStyle}>Total flows</h6>
                    <p className={classes.priceTextStyle}>
                      {responseData?.flows_count}
                    </p>
                  </div>
                </div>
                :
                <div className={classes.priceBox1}>
                  {responseData?.hasOwnProperty("spaces_count") ? (
                    <div className={classes.price}>
                      <h6 className={classes.priceTextStyle}>Total Space</h6>
                      <p className={classes.priceTextStyle}>
                        {responseData?.spaces_count}
                      </p>
                    </div>
                  ) : (
                    <div></div>
                  )}

                  <section>
                    {
                      flowData?.clone_type === "workflow" ?
                        <div className={classes.priceBox1}>
                          <div className={classes.price}>
                            <h6 className={classes.priceTextStyle}>Total Space</h6>
                            <p className={classes.priceTextStyle}>
                              {responseData?.spaces_count}
                            </p>
                          </div>
                          <div className={classes.price}>
                            <h6 className={classes.priceTextStyle}>Total Workflows</h6>
                            <p className={classes.priceTextStyle}>
                              {responseData?.workflows_count}
                            </p>
                          </div>
                          <div className={classes.price}>
                            <h6 className={classes.priceTextStyle}>Total flows</h6>
                            <p className={classes.priceTextStyle}>
                              {responseData?.flows_count}
                            </p>
                          </div>
                        </div>
                        :
                        <>
                          <div className={classes.price}>
                            <h6 className={classes.priceTextStyle}>Total Apps</h6>
                            <p className={classes.priceTextStyle}>
                              {responseData?.apps_count}
                            </p>
                          </div>
                          {flowData?.clone_type === "structure_only" ? null : (
                            <>
                              <div className={classes.price}>
                                <h6 className={classes.priceTextStyle}>Total Files</h6>
                                <p className={classes.priceTextStyle}>
                                  {responseData?.files_count}
                                </p>
                              </div>
                              <div className={classes.price}>
                                <h6 className={classes.priceTextStyle}>Total Items</h6>
                                <p className={classes.priceTextStyle}>
                                  {responseData?.items_count}
                                </p>
                              </div>
                            </>
                          )}
                        </>
                    }
                  </section>
                </div>
            }
          </section>
          <div className={classes.priceBox}>
            {/* <div className={classes.price}>
              <h6 className={classes.priceTextStyle}>Items Cost</h6>
              <p className={classes.priceTextStyle}>${formatNumber(responseData.items_cost)}</p>
            </div>
            <div className={classes.price}>
              <h6 className={classes.priceTextStyle}>Files Cost</h6>
              <p className={classes.priceTextStyle}>${formatNumber(responseData.files_cost)}</p>
            </div> */}

            <section>
              {
                flowData?.clone_type === "workflow" ?
                  <>
                    {flowData?.clone_type === "structure_only" ? (
                      <div className={classes.price}>
                        <h6 className={classes.priceTextStyle}>Cost per app</h6>
                        <p className={classes.priceTextStyle}>
                          ${responseData?.unit_item_cost}
                        </p>
                      </div>
                    ) : (
                      <div className={classes.price}>
                        <h6 className={classes.priceTextStyle}>Cost per record</h6>
                        <p className={classes.priceTextStyle}>
                          ${responseData?.unit_item_cost}
                        </p>
                      </div>
                    )}
                  </>
                  :
                  <>
                    {flowData?.clone_type === "structure_only" ? (
                      <div className={classes.price}>
                        <h6 className={classes.priceTextStyle}>Cost per app</h6>
                        <p className={classes.priceTextStyle}>
                          ${responseData?.unit_item_cost}
                        </p>
                      </div>
                    ) : (
                      <div className={classes.price}>
                        <h6 className={classes.priceTextStyle}>Cost per record</h6>
                        <p className={classes.priceTextStyle}>
                          ${responseData?.unit_item_cost}
                        </p>
                      </div>
                    )}
                  </>
              }
            </section>

            <div className={classes.price}>
              <h6 className={classes.priceTextStyle}>Subtotal</h6>
              {
                flowData?.clone_type === "workflow" ?
                  <p className={classes.priceTextStyle}>
                    ${responseData?.subtotal}
                  </p>
                  :
                  <p className={classes.priceTextStyle}>
                    $
                    {responseData?.hasOwnProperty("subtotal")
                      ? formatNumber(responseData?.subtotal)
                      : formatNumber(
                        responseData?.items_cost + responseData?.files_cost
                      )}
                  </p>
              }
            </div>
            <div className={classes.price}>
              <h6 className={classes.priceTextStyle}>Tax</h6>
              <p className={classes.priceTextStyle}>
                ${formatNumber(responseData?.taxes_cost)}
              </p>
            </div>
          </div>
          <div className={classes.price}>
            <h6 className={classes.priceTextStyle}>Total</h6>
            {
              flowData?.clone_type === "workflow" ?
                <p className={classes.priceTextStyle}>
                  ${responseData?.subtotal}
                </p>
                :
                <p className={classes.priceTextStyle}>
                  $
                  {responseData?.hasOwnProperty("subtotal")
                    ? formatNumber(responseData?.subtotal + responseData?.taxes_cost)
                    : formatNumber(
                      responseData?.items_cost +
                      responseData?.files_cost +
                      responseData?.taxes_cost
                    )}
                </p>
            }
          </div>

          {user.new_billing === true ? (
            <div style={{ marginTop: "15%" }} className={classes.freeCont}>
              <p style={{ fontSize: "14px" }}>
                Your cloning process will begin automatically. You can monitor
                its progress in the activity log. By clicking 'Proceed to
                Clone,' you acknowledge that you accept the cost for this
                transfer and it will be added to your accumulated billing. To
                confirm the cost, you can multiply the total item for all apps
                by ${responseData?.unit_item_cost}.
              </p>
              {loading ? (
                <CircularProgress size={24} />
              ) : (
                <button
                  onClick={onClick}
                  style={{ width: "100%", color: "white" }}
                  className="generate_btn"
                >
                  <img src={copyWhite} alt="" />
                  Proceed to Clone
                </button>
              )}
            </div>
          ) : (
            <div className={classes.btnContainer}>
              {loading ? (
                <CircularProgress size={24} />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation={true}
                  onClick={onClick}
                  className={classes.btnWhiteText}
                  disabled={disabled}
                >
                  {btnTitle}
                </Button>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CheckoutCardV2;
