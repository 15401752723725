import { Component } from 'react';
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default class TextEditor extends Component {
  state = {
    msg: ''
  }

  changeHandle = (event, editor) =>{
    const data = editor.getData();

    this.props.change(data)
  }
  render() {
    return null;
    // return (
    //   <CKEditor
    //     data={this.props.msg}
    //     editor={ClassicEditor}
    //     onReady={editor =>{
    
    //     }}
    //     onChange={this.changeHandle}
        
    // />
    // )
  }
}
