import Axios from "config";
import asyncHandler from "utils/asyncHandler";

export const processOrg = asyncHandler(
  async (data) =>
    await Axios.post("/clone/general/checkout/process/organization", data)
  // async (data) => await Axios.post('/clone/general/transfer/process/organization', data)
);

export const processSpace = asyncHandler(
  async (data) =>
    await Axios.post("/clone/general/checkout/process/space", data)
  // async (data) => await Axios.post('/clone/general/transfer/process/space', data)
);

export const processApps = asyncHandler(
  async (data) => await Axios.post("/clone/general/checkout/process/apps", data)
  // async (data) => await Axios.post('/clone/general/transfer/process/apps', data)
);

export const fetchActivities = asyncHandler(
  async (page) =>
    await Axios.get(`/clone/general/transfer/index?page=${page}&per_page=5`)
);

export const fetchActivity = asyncHandler(
  async (id) => await Axios.get(`/clone/general/transfer/show/${id}`)
);

export const activityRetry = asyncHandler(
  async (id, data) => await Axios.post(`/clone/general/transfer/retry-step/${id}`, data)
);

export const processCharge = asyncHandler(
  async (data) =>
    await Axios.post(`/clone/general/checkout/process/charge`, data)
);
