import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import { getSpaces, getApps, resyncOrganization } from "../../../services/sync";
import arrowDown from "../../../assets/icons/arrow-down.svg";
import arrowUp from "../../../assets/icons/arrow-up.svg";
import searchIcon from "../../../assets/icons/searchIcon.svg";
import { toast } from "../../../helpers/apiRequests";
import BackButton from "components/atoms/BackButton";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  appTable: {
    overflowY: "auto",
    // height: "73vh",
  },
  tableHeader: {
    overflowY: "auto",
    height: "73vh",
  },
  spaces: {
    backgroundColor: "#FBFBFB",
    display: "flex",
    alignItems: "center",
    margin: "1% 0",
    padding: "1% 3%",
  },
  app: {
    display: "flex",
    alignItems: "center",
    margin: "0% 0",
    padding: "10px 3%",
  },
  spaces_items: {
    display: "flex",
    alignItems: "center",
    width: "80%",
  },
  spaces_action: {
    display: "flex",
  },
  action_text: {
    color: "#E12D39",
    fontSize: "15px",
  },
  app_name: {
    fontSize: "15px",
    width: "20%",
  },
  app_id: {
    color: "#7E7E7E",
    fontSize: "15px",
  },
  title_info: {
    color: "#DE911D",
    fontWeight: "600",
    marginTop: "1%",
  },
  btn: {
    color: "#fff",
    backgroundColor: "#F0B429",
    width: "150px",
    height: "50px",
    borderRadius: "5px",
    border: "none",
  },
  searchDiv: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #CFCFCF",
    borderRadius: "8px",
    padding: "0 2px 0 8px",
    marginTop: "3%",
    width: "350px",
  },
  searchAppDiv: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #CFCFCF",
    borderRadius: "8px",
    padding: "0 2px 0 8px",
    // marginTop: "1%",
    marginBottom: "2%",
    width: "350px",
    marginLeft: "2%",
  },
}));

const ResyncOrg = () => {
  const classes = useStyles();
  const location = useLocation();
  const { orgId, orgName } = location.state || {};
  const [workspaces, setWorkspaces] = useState([]);
  const [apps, setApps] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingApps, setLoadingApps] = useState({});
  const [selectedSpaces, setSelectedSpaces] = useState([]);
  const [selectedApps, setSelectedApps] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [filteredWorkspaces, setFilteredWorkspaces] = useState([]);
  const [filteredApps, setFilteredApps] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchAppQuery, setSearchAppQuery] = useState("");

  useEffect(() => {
    const fetchSpaces = async () => {
      setLoading(true);
      let response = await getSpaces(orgId);
      let data = response?.data
        ? response.data.map((space) => ({ ...space, showApps: false }))
        : [];
      setWorkspaces(data);
      setLoading(false);
    };

    fetchSpaces();
  }, [orgId]);

  useEffect(() => {
    const filterSpaces = () => {
      const query = searchQuery.toLowerCase();
      const filtered = workspaces.filter((space) =>
        space.name.toLowerCase().includes(query)
      );
      setFilteredWorkspaces(filtered);
    };

    filterSpaces();
  }, [searchQuery, workspaces]);

  const fetchAppsForSpace = async (space) => {
    if (!apps[space.space_id]) {
      setLoadingApps((prev) => ({ ...prev, [space.space_id]: true }));
      let query = `?org_id=${orgId}&space_id=${space.space_id}`;
      let response = await getApps(query);
      let appData = response?.data?.data ? response.data.data : [];
      setApps((prev) => ({ ...prev, [space.space_id]: appData }));
      setLoadingApps((prev) => ({ ...prev, [space.space_id]: false }));
    }
  };

  useEffect(() => {
    const filterApps = () => {
      const query = searchAppQuery.toLowerCase();
      const newFilteredApps = {};

      // Iterate over each space in apps object
      Object.keys(apps).forEach((spaceId) => {
        newFilteredApps[spaceId] = apps[spaceId].filter((app) =>
          app.config.name.toLowerCase().includes(query)
        );
      });

      setFilteredApps(newFilteredApps);
    };

    filterApps();
  }, [searchAppQuery, apps]);

  const fetchAppsForSpaceCheckbox = async (space) => {
    if (!apps[space.space_id]) {
      setLoadingApps((prev) => ({ ...prev, [space.space_id]: false }));
      let query = `?org_id=${orgId}&space_id=${space.space_id}`;
      let response = await getApps(query);
      let appData = response?.data?.data ? response.data.data : [];
      setApps((prev) => ({ ...prev, [space.space_id]: appData }));
      setLoadingApps((prev) => ({ ...prev, [space.space_id]: false }));
      return appData;
    }
    return apps[space.space_id];
  };

  const toggleShowApps = (space) => {
    fetchAppsForSpace(space);
    setWorkspaces((prev) =>
      prev.map((s) =>
        s.space_id === space.space_id ? { ...s, showApps: !s.showApps } : s
      )
    );
  };

  const handleSpaceCheckboxChange = async (space) => {
    const isChecked = selectedSpaces.includes(space.space_id);

    // Fetch apps for the space without displaying them
    const fetchedApps = await fetchAppsForSpaceCheckbox(space);

    if (isChecked) {
      // Uncheck space and its apps
      setSelectedSpaces((prev) => prev.filter((id) => id !== space.space_id));
      setSelectedApps((prev) => {
        const newSelectedApps = { ...prev };
        delete newSelectedApps[space.space_id];
        return newSelectedApps;
      });
    } else {
      // Check space and its apps
      setSelectedSpaces((prev) => [...prev, space.space_id]);
      setSelectedApps((prev) => ({
        ...prev,
        [space.space_id]: fetchedApps.map((app) => app.app_id),
      }));
    }
  };

  const handleAppCheckboxChange = (spaceId, appId) => {
    const isChecked = selectedApps[spaceId]?.includes(appId);
    setSelectedApps((prev) => {
      const newSelectedApps = { ...prev };
      if (isChecked) {
        // Uncheck app
        newSelectedApps[spaceId] = newSelectedApps[spaceId].filter(
          (id) => id !== appId
        );
        if (newSelectedApps[spaceId].length === 0) {
          delete newSelectedApps[spaceId];
        }
      } else {
        // Check app
        newSelectedApps[spaceId] = [...(newSelectedApps[spaceId] || []), appId];
      }
      return newSelectedApps;
    });

    // Check the space if any of its apps are checked
    if (!selectedSpaces.includes(spaceId) && !isChecked) {
      setSelectedSpaces((prev) => [...prev, spaceId]);
    }
  };

  const handleSelectAllSpaces = async () => {
    const allAppsPromises = workspaces.map(async (space) => {
      const fetchedApps = await fetchAppsForSpaceCheckbox(space);
      return {
        [space.space_id]: fetchedApps.map((app) => app.app_id) || [],
      };
    });
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);

    if (newSelectAll) {
      const allSpaces = workspaces.map((space) => space.space_id);
      setSelectedSpaces(allSpaces);

      Promise.all(allAppsPromises).then((allAppsArray) => {
        const allApps = allAppsArray.reduce((acc, current) => {
          return { ...acc, ...current };
        }, {});
        setSelectedApps((prev) => ({
          ...prev,
          ...allApps,
        }));
      });
    } else {
      setSelectedSpaces([]);
      setSelectedApps({});
    }
  };

  const isSpaceChecked = (spaceId) => selectedSpaces.includes(spaceId);
  const isAppChecked = (spaceId, appId) =>
    selectedApps[spaceId]?.includes(appId);

  const handleSyncClick = async () => {
    const payload = {
      org_id: orgId,
      org_name: orgName,
      action: "resync",
      spaces: selectedSpaces.map((spaceId) => ({
        space_id: spaceId,
        sync_all: false,
        apps: selectedApps[spaceId]?.map((appId) => ({ app_id: appId })) || [],
      })),
    };

    try {
      await resyncOrganization(payload);
      toast.notify("Your resync has been queued for processing successfully!");
      window.history.back();
    } catch (error) {
      console.error("Error resyncing organization:", error);
      // toast.notify("Failed to resync organization.");
    }
  };

  if (loading) {
    return (
      <div style={{ marginTop: "15%" }} className="load__audit">
        <div className="material_block">
          <svg
            className="spinner"
            width="55px"
            stroke="#F0B429"
            height="55px"
            viewBox="0 0 66 66"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              className="circle"
              fill="none"
              strokeWidth="6"
              strokeLinecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className="d-flex justify-content-between mt-3 align-items-center">
        <div className="mb-4">
          <BackButton />
        </div>

        <div className="d-flex justify-content-end">
          <button className={classes.btn} onClick={handleSyncClick}>
            Resync
          </button>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <div className="mt-3">
          <h5>{orgName}</h5>
          <p className={classes.title_info}>
            Kindly select workspace(s) you want to sync{" "}
          </p>
        </div>
        <div className={classes.searchDiv}>
          <img src={searchIcon} alt="" />
          <input
            style={{
              appearance: "auto",
              backgroundColor: "white",
              outline: "none",
              padding: "0",
            }}
            type="search"
            placeholder="Search spaces"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>

      <div className={classes.tableHeader}>
        <div className="d-flex align-items-center mt-3 mb-4 pl-4">
          <input
            style={{
              appearance: "auto",
              width: "15px",
              height: "15px",
              marginRight: "40px",
              marginLeft: "20px",
              // accentColor: "#DE911D",
            }}
            type="checkbox"
            checked={selectAll}
            onChange={handleSelectAllSpaces}
          />
          <h5 style={{ fontSize: "16px", marginBottom: "0" }}>
            Select all spaces
          </h5>
        </div>
        {filteredWorkspaces.map((space, i) => (
          <div key={space.space_id}>
            <div className={classes.spaces}>
              <div className={classes.spaces_items}>
                <input
                  style={{
                    appearance: "auto",
                    width: "15px",
                    height: "15px",
                    marginRight: "50px",
                    // accentColor: "#DE911D",
                  }}
                  type="checkbox"
                  checked={isSpaceChecked(space.space_id)}
                  onChange={() => handleSpaceCheckboxChange(space)}
                />
                <div>
                  <h5 style={{ fontSize: "16px" }} className="title">
                    {space.name}
                  </h5>
                  <p
                    style={{ color: "#DE911D", fontWeight: "500" }}
                    className="num mb-0"
                  >
                    {space.space_id}
                  </p>
                </div>
              </div>
              <div className={classes.spaces_action}>
                {/* <h5 className={classes.action_text}>Clear all</h5> */}
                {space.showApps ? (
                  <img
                    onClick={() => toggleShowApps(space)}
                    className="ml-5"
                    alt=""
                    src={arrowUp}
                  />
                ) : (
                  <img
                    onClick={() => toggleShowApps(space)}
                    className="ml-5"
                    alt=""
                    src={arrowDown}
                  />
                )}
              </div>
            </div>
            {loadingApps[space.space_id] ? (
              <div className="load__audit">
                <div className="material_block">
                  <svg
                    className="spinner"
                    width="55px"
                    stroke="#F0B429"
                    height="55px"
                    viewBox="0 0 66 66"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="circle"
                      fill="none"
                      strokeWidth="6"
                      strokeLinecap="round"
                      cx="33"
                      cy="33"
                      r="30"
                    ></circle>
                  </svg>
                </div>
              </div>
            ) : (
              space.showApps && (
                <div
                  style={
                    apps[space.space_id]?.length > 0
                      ? { minHeight: "10vh" }
                      : { height: "3vh" }
                  }
                  className={classes.appTable}
                >
                  {apps[space.space_id]?.length > 0 ? (
                    <div className={classes.searchAppDiv}>
                      <img src={searchIcon} alt="" />
                      <input
                        style={{
                          appearance: "auto",
                          backgroundColor: "white",
                          outline: "none",
                          padding: "0",
                        }}
                        type="search"
                        placeholder="Search apps"
                        value={searchAppQuery}
                        onChange={(e) => setSearchAppQuery(e.target.value)}
                      />
                    </div>
                  ) : null}

                  {apps[space.space_id]?.length > 0 ? (
                    filteredApps[space.space_id].map((app, i) => (
                      <div className={classes.app} key={app.app_id}>
                        <input
                          style={{
                            appearance: "auto",
                            width: "15px",
                            height: "15px",
                            marginRight: "50px",
                            // accentColor: "#DE911D",
                          }}
                          type="checkbox"
                          checked={isAppChecked(space.space_id, app.app_id)}
                          onChange={() =>
                            handleAppCheckboxChange(space.space_id, app.app_id)
                          }
                        />
                        <h5 className={classes.app_name}>{app.config.name}</h5>
                        <h5 className={classes.app_id}>{app.app_id}</h5>
                      </div>
                    ))
                  ) : (
                    <div className="d-flex align-items-center justify-content-center">
                      <p>There are no apps within this space</p>
                    </div>
                  )}
                </div>
              )
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResyncOrg;
