import * as actionType from '../constants/connector';

const initialState = {
    progress: [],
    database: [],
    current: {
        show: false,
        data: []
    },
    loading: true,
    sql_sync: {},
    error: {},
}

const ConnectorReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case actionType.GET_ALL_PROGRESS:
            return {
                ...state,
                progress: payload,
                loading: false
            }
        case actionType.GET_SQL_SYNC:
            return {
                ...state,
                sql_sync: payload,
                loading: false
            }
        case actionType.CLEAR_CURRENT:
            return {
                ...state,
                current: {
                    show: false,
                    data: []
                },
                loading: false
            }
        case actionType.GET_DATABASE:
            return {
                ...state,
                database: payload,
                loading: false
            }
        case actionType.ADD_DATABASE:
            return {
                ...state,
                database: state.database.concat(payload),
                loading: false
            }
        case actionType.DELETE_DATABASE:
            return {
                ...state,
                database: state.database.filter(db => db.id !== payload),
                loading: false
            }
        case actionType.REFRESH_PROGRESS:
            const newArr = [...state.progress];
            const index = newArr.findIndex(elem => elem.id === payload.id);
            newArr[index] = payload
            return {
                ...state,
                progress: newArr,
                loading: false
            }
        case actionType.UPDATE_SQL_SYNC:
            const newStatus = {
                ...state.sql_sync
            }
            payload.forEach(driver => {
                if (driver === "mysql") {
                    newStatus.mysql_status = true
                }
                if (driver === "pgsql") {
                    newStatus.pgsql_status = true
                }
            });
            return {
                ...state,
                sql_sync: newStatus,
                loading: false
            }
        case actionType.DELETE_SQL_SYNC:
            const newSqlStatus = {
                ...state.sql_sync
            }
            payload.forEach(driver => {
                if (driver === "mysql") {
                    newSqlStatus.mysql_status = false;
                }
                if (driver === "pgsql") {
                    newSqlStatus.pgsql_status = false;
                }
            });
            return {
                ...state,
                sql_sync: newSqlStatus,
                loading: false
            }

        case actionType.ERROR:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        default: return state;
    }

}

export default ConnectorReducer;