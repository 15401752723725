import React, { useState, useEffect, useCallback } from "react";
import ConnectTemplate from "../../../components/Tmail/Connector/ConnectTemplate"
import Podio from "../../../assets/images/podioconnect.png";
// import Sales from "../../../assets/images/salesforce.png";
// import Zoho from "../../../assets/images/zoho.png";
// import Json from "../../../assets/images/json.png";
import printLogo from "../../../assets/images/printlogo.png";
import { useHistory } from "react-router";
import { TMAIL_DASHBOARD } from "../../../containers/pagePath";
import { useSelector, useDispatch } from "react-redux";
import ConnectModal from "../../../components/Tmail/Connector/ConnectModal";
import {
  parseHashString,
  authorize,
} from "../../../containers/Connector/sharedFunctions";
import MyModal from "../../../components/molecule/Modal";
import { authV2Connect } from "../../../helpers/Authentication/Auth";
import { getMe } from "../../../services/auth";

import { setUser } from "../../../redux/actions/user";
import { getSyncedOrganizations } from "../../../services/sync";
import { setOrgs } from "../../../redux/actions/sync";
import { getNavigation } from "../../../services/common";
import { setNav } from "../../../redux/actions/sidebar";
import { setAuditOrg } from "../../../redux/actions/orgs";
import * as actions from "../../../redux/actions";

const Connections = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedConnector, setSelectedConnector] = useState({
    title: "",
    image: "",
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const [connecting, setConnecting] = useState({
    connector: null,
    loading: false,
  });
  const user = useSelector((state) => state.user.authUser);
  const [connectors, setConnectors] = useState([]);

  useEffect(() => {
    if (user.connected_platform) {
      const connectItems = user.connected_platform.map(
        (item) => item.connector_id
      );

      setConnectors(connectItems);
    }
  }, [user]);

  const setConnector = (title, logo, index) => {
    console.log(index,title);
    if (connectors.includes(index)) {
      getOrgs();
    } else {
      setSelectedConnector({
        title,
        image: logo,
      });
      setShowModal(true);
    }
  };

  const getCards = () => [
    {
      status: connectors.includes(1) ? "Connected" : "Disconnected",
      image: Podio,
      text: "Podio",
    },
  ];

  const [cards, setCards] = useState(getCards());

  useEffect(() => {
    setCards(getCards());
    //eslint-disable-next-line
  }, [connectors]);

  const handleConnectCardClick = (item) => {
    console.log(item);
    setCards((prev) =>
      prev.map((currentItem) => {
        if (currentItem.text === item.text && currentItem.status) {
          // we are going to decide how to get the index for the setConnector later
          setConnector(item.text, item.image, 1);

          if (currentItem.status.toLowerCase() === "connected") {
            return {
              ...currentItem,
              isLoading: !currentItem.isLoading,
            };
          }
        }

        return currentItem;
      })
    );
  };

  const getNav = async () => {
    const response = await getNavigation();

    dispatch(setNav(response.menus));
  };

  const getUser = useCallback(async () => {
    const response = await getMe();

    dispatch(setUser(response.data));
  }, [dispatch]);

  const getOrgs = async () => {
    const response = await getSyncedOrganizations();

    dispatch(setOrgs(response.data));
    await getNav();
    await getUser();
    const newResponse = await getSyncedOrganizations();

    dispatch(setAuditOrg(newResponse.data));
    //Dispatch synced and unsynced
    const authToken = localStorage.getItem("chosen_token");

    dispatch(actions.getAllUnSyncedAccounts(authToken));

    if (response && newResponse) {
      return history.push(TMAIL_DASHBOARD);
    }
  };

  useEffect(() => {
    const auth_type = localStorage.getItem("auth_type");

    const hashString = window.location.hash.substr(1);

    if (hashString && auth_type === "podio") {
      const access = parseHashString(hashString);

      if (access["access_token"]) {
        access["user_id"] = access["ref[id]"];
        const response = authV2Connect(access, auth_type);

        if (response) {
          getUser();
        }
      }
    }
  }, [connecting, getUser]);
  return (
    <div>
      <MyModal open={showModal} onClose={() => setShowModal(false)}>
        <ConnectModal
          info={selectedConnector}
          handleConnect={() =>
            authorize(selectedConnector, setConnecting, "/tmail-dashboard")
          }
          handleClose={() => setShowModal(false)}
        />
      </MyModal>
      <ConnectTemplate
        cards={cards}
        userName={user.name}
        onConnectCardClick={handleConnectCardClick}
        backgroundColor="#F8FCE9"
        textColor="#F27649"
        logo={printLogo}
      />
    </div>
  );
};

export default Connections;
