import React from "react";
import { useHistory, useParams } from "react-router-dom";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import parseISO from "date-fns/parseISO";
import EyeIcon from "../../assets/icons/eye-icon.svg";
import EditIcon from "../../assets/icons/editIcon.svg";
import RenderByPermission from "components/RenderByPermission";
// import DeleteIcon from "../../assets/icons/trash-icon.svg";

const WorkspaceListView = ({
  workspaces,
  openEditDialog,
  openDeleteDialog,
  handleSelectedWorkspace,
}) => {
  const history = useHistory();
  const params = useParams();

  return (
    <div className="sync__table">
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr style={{ backgroundColor: "#FBFBFB" }}>
              <th style={{ border: "none", textAlign: "left" }}>ID</th>
              <th style={{ border: "none", textAlign: "left" }}>Name</th>
              <th style={{ border: "none", textAlign: "left" }}>Created</th>
              <th style={{ border: "none", textAlign: "left" }}>Status</th>
              <th style={{ border: "none", textAlign: "left" }}></th>
              <th style={{ border: "none", textAlign: "left" }}></th>
            </tr>
          </thead>
          <tbody>
            {workspaces.map((workspace, i) => {
              if (workspace.display === true) {
                return (
                  <tr key={i}>
                    <td>
                      <p style={{ fontWeight: "600", textAlign: "left" }} className="title m-0">
                        {workspace.space_id}
                      </p>
                    </td>
                    <td>
                      <p style={{ fontWeight: "600", textAlign: "left" }} className="title m-0">
                        {workspace.name}
                      </p>
                    </td>
                    <td style={{ fontWeight: "600", textAlign: "left" }}>
                      {workspace.created_on
                        ? formatDistanceToNow(parseISO(workspace.created_on)) +
                          " ago"
                        : ""}
                    </td>
                    <td style={{textAlign: "left"}}>
                      <span style={{ fontWeight: "600", textAlign: "left", padding: "3% 10%" }} className="info">
                        {workspace.details && workspace.details.status
                          ? workspace.details.status
                          : "none"}
                      </span>
                    </td>
                    <td>
                      <div className="dropdown">
                        <span
                          className="dropdown-item"
                          onClick={() => {
                            let link = `/dashboard/organisation/${params.id}/workspaces/${workspace.space_id}/apps`;

                            history.push(link);
                          }}
                        >
                          <img src={EyeIcon} alt="icon" /> View Apps
                        </span>
                      </div>
                    </td>
                    
                    <td>
                      <div className="dropdown">
                        <RenderByPermission>
                          <span
                            className="dropdown-item"
                            onClick={() => {
                              handleSelectedWorkspace(workspace.space_id);
                              openEditDialog(workspace.space_id);
                            }}
                            style={{color: "#DE911D"}}
                          >
                            <img src={EditIcon} alt="icon" width="20px" />
                            Edit Workspace
                          </span>
                        </RenderByPermission>
                      </div>
                    </td>
                  </tr>
                );
              }
              return "";
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default WorkspaceListView;
