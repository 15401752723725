import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FaExclamationCircle } from "react-icons/fa";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  btn: {
    height: "44px",
    width: "90px",
    backgroundColor: "#F0B429",
    borderRadius: "3px",
    textTransform: "capitalize",
  },
  text_field: {
    padding: "10px",
    background: "transparent",
    width: "100%",
    borderRadius: "5px",
    fontSize: "12px",
    marginTop: "44px",
    marginBottom: "44px",
  },
  warningIcon: {
    color: "#F0B429",
    fontSize: "80px",
  },
  backButton: {
    backgroundColor: "#f7c948",
    boxShadow: "0px 3px 6px #00000029",
    padding: "1vh 1vw",
    outline: "none",
    textDecoration: "none",
    border: "none",
    borderRadius: "6px",
    color: "white",
    marginBottom: "1%",
  },
}));

const NewMemberView = () => {
  const classes = useStyles();

  const handleBackButtonClick = () => {
    window.history.back();
  };

  return (
    <div>
      <div>
        <button onClick={handleBackButtonClick} className={classes.backButton}>
          Back
        </button>
      </div>
      <div className="mb-4">
        <h5 className="mb-2">Members</h5>
        <p className="mb-0">
          Defining your team is important as we use this data to match on sender
          addresses to determine if an email is inbound or outbound.
        </p>
      </div>

      <div className="d-flex align-items-center px-3 mb-5">
        <div className="mr-4">
          <p className="mb-2">
            Note: By default the advanced setting "Don't Email my Team" is
            enabled in each app so that emails to your team are not forwarded to
            you so you can stay in Podio and not get distracted in your Inbox.
          </p>
          <small>
            (It is recommended to keep this setting on. If you wish to have
            copies of those emails, it's best to set up one of the Email
            Archiving options.)
          </small>
        </div>

        <FaExclamationCircle className={classes.warningIcon} />
      </div>

      <div className="">
        <p className="mb-2">
          Specify email addresses of your team so we don't confuse them with
          customers
        </p>
        <small>
          (One address per line. You can use wildcards like *@yourdomain.com).
        </small>
        <TextareaAutosize
          aria-label="minimum height"
          minRows={10}
          className={classes.text_field}
          placeholder="Please input your email"
        />
        <div className="text-right">
          <Button
            variant="contained"
            color="primary"
            disableElevation={true}
            className={classes.btn}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NewMemberView;
