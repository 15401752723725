import React, { useState, useEffect } from "react";
import "../register.css";
import logo from "../../../assets/images/logo.png";
import eyeShow from "../../../assets/images/eye_show.svg";
import podio from "../../../assets/images/podio_form.svg";
import eyeHide from "../../../assets/images/eye_hide.svg";

import { Link, useHistory } from "react-router-dom";
import { loginUser } from "../../../services/auth";
import { setUser } from "../../../redux/actions/user";
import { useDispatch } from "react-redux";
import qs from "stringquery";

const NewLogin = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (typeof window.location.hash.substr(1) !== "undefined") {
      const type = window.location.hash.substr(1);
      const obj = qs(type);

      let access = {};

      if (typeof obj["ccess_token"] !== "undefined") {
        access["access_token"] = obj.ccess_token;
        access["refresh_token"] = obj.refresh_token;
        access["expires_in"] = obj.expires_in;
        access["user_id"] = type.split("=")[3].split("&")[0];
        localStorage.setItem("podio_access_object", JSON.stringify(access));
      }
    }

    if (window.opener !== null && window.opener !== "null") {
      setTimeout(function () {
        window.opener.location.href = "/authorizepass";
        window.close();
      }, 1000);
    }
  });

  const history = useHistory();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { email, password } = data;
    const userInfo = {
      email,
      password,
    };

    try {
      let response = await loginUser(userInfo);

      if (response) {
        if (response.data) {
          localStorage.setItem("chosen_token", response.data.access_token);
          const team_member_uuid = response.data?.team_member?.uuid;
          if (team_member_uuid) {
            localStorage.setItem(
              "team_member_uuid",
              response.data?.team_member?.uuid
            );
          }
          dispatch(setUser(response.data));
          if (response.data.new_user === true) {
            window.location.href = "/service";
          }
          setLoading(false);
        }
      }
    } catch (err) {
      setLoading(false);
      if (err.response?.status === 403) {
        setTimeout(() => {
          history.push("/account/resend");
        }, 2000);
      }
    }
  };

  const authorizeWithPodio = async () => {
    const url = `${window.location.origin}/login`;

    const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=0,height=0,left=-1000,top=-1000`;
    const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
    window.open(
      `https://podio.com/oauth/authorize?client_id=${CLIENT_ID}&redirect_uri=${url}&scope=global:all&response_type=token`,

      "login",
      params
    );
  };

  return (
    <div className="auth__forms__cont">
      <div className="auth__title__cont">
        <Link className="home__link" to="/">
          Home
        </Link>

        <h2>
          Don’t have an account?{" "}
          <span>
            <Link to="/register">Create account</Link>
          </span>
        </h2>
      </div>
      <div className="auth__form__wrapper">
        <form onSubmit={handleSubmit} className="create__form">
          <div className="create__logo__cont">
            <img src={logo} alt="thatapplogo" />
            <h2>Login to your account</h2>
            <p>Enter your details below</p>
          </div>
          <div className="create__inputs__cont">
            <div className="inputs__cont">
              <label htmlFor="email">Email Address</label>
              <input
                className="inputs__cont__input"
                type="email"
                name="email"
                id="email"
                value={data.email}
                onChange={handleChange}
                required
                placeholder="hello@gmail.com"
              />
            </div>
            <div className="inputs__cont">
              <label htmlFor="password">Password</label>
              <div className="create__pass__cont">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                  required
                  placeholder="• • • • • • • • • • "
                  value={data.password}
                  onChange={handleChange}
                />
                <div
                  onClick={() => setShowPassword(!showPassword)}
                  className="create__eye__cont"
                >
                  <img src={showPassword ? eyeHide : eyeShow} alt="show" />
                </div>
              </div>
            </div>
          </div>

          <div className="forgot__pass__link__cont">
            <span
              onClick={() => history.push("/forgotpassword")}
              className="forgot__pass__link"
            >
              Forgot password?
            </span>
          </div>

          <div className="log__but__cont">
            <button disabled={loading} className="form__bottom__button">
              {loading ? "Logging in..." : "Login"}
            </button>
          </div>
          <button
            type="button"
            onClick={() => authorizeWithPodio()}
            className="create__podio"
          >
            <img src={podio} alt="podio" />
            <p>Authorize with Podio</p>
          </button>
        </form>
      </div>
    </div>
  );
};

export default NewLogin;
