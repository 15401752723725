import React from "react";
import "./Error.css";
const Error = ({ errorMsg, errorAction, onClick }) => {
  return (
    <>
      <div className="d-flex justify-center">
        <div className="error-con">
          <span>Oops !</span>
          <div className="error-msg">{errorMsg}</div>
          <button className="error-action" onClick={onClick}>
            {errorAction}
          </button>
        </div>
      </div>
    </>
  );
};

export default Error;
