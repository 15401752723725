/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import MapperGrid from "./MapperGrid";
import { GetAllDatabases } from "../../redux/actions/connector";
import { useParams, useHistory } from "react-router";
import "./Connector.css";
import "./style.css";
import BackButton from "components/atoms/BackButton";
import useMediaQuery from "@mui/material/useMediaQuery";

const SqlMapper = () => {
  // const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:600px)");

  const dispatch = useDispatch();
  const history = useHistory();
  const { orgId, credentialId } = useParams();

  useEffect(() => {
    dispatch(GetAllDatabases());
  }, []);

  const goBack = () => {
    history.goBack();
  };

  return (
    <div>
      {isMobile ? (
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            marginBottom: "5%",
          }}
        >
          <div>
            <BackButton />
          </div>
          <div className="w-80 ml-3 mt-1">
            <h5 style={{ fontSize: "16px", fontWeight: "600" }}>
              Connectors {">"}
              <span
                style={{ cursor: "pointer", color: "#F0B429" }}
                onClick={goBack}
                className="ml-1"
              >
                Mappers List
              </span>{" "}
            </h5>
            {/* <p className="mb-0">Mappers List</p> */}
          </div>
        </div>
      ) : (
        <div>
          <div>
            <BackButton />
          </div>
          <div>
            <h5 className="mt-3">
              Connectors {">"}
              <span
                style={{ cursor: "pointer", color: "#F0B429" }}
                onClick={goBack}
                className="ml-1"
              >
                Mappers List
              </span>{" "}
            </h5>
          </div>
        </div>
      )}

      <MapperGrid connectorId={credentialId} selectedOrg={orgId} />
    </div>
  );
};

export default SqlMapper;
