import React from "react";
import DraftComponent from "./DraftComponent";
import { usePrintData } from "../../context/PrintProvider";

import "./editor.scss";

const DraftContainer = (props) => {
  const [pages, setPages] = React.useState([]);
  const { template } = usePrintData();

  React.useEffect(() => {
    if (template?.document?.pages) {
      const count = template?.document?.pages;
      if (typeof count === "number") {
        const pages = new Array(count).fill(DraftComponent);
        return setPages(pages);
      } else {
        setPages([]);
      }
    } else {
      setPages([DraftComponent]);
    }
  }, [template?.document?.pages]);

  const addNewPage = React.useCallback(function () {
    setPages((prev) => [...prev, DraftComponent]);
  }, []);

  const removePage = React.useCallback(function (index) {
    setPages((prev) => {
      if (prev.length > 1) {
        const newPages = [...prev];
        newPages.splice(index, 1);

        // remove data from the contents array
        props.setContents((prev) => {
          const newContent = [...prev];
          newContent.splice(index, 1);
          return newContent;
        });

        return newPages;
      }
      return prev;
    });

    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section>
      <div
        style={{
          width: "100%",
          margin: "0",
          height: "800px",
          minHeight: "400px",
        }}
        id="document-editor"
        size="A4"
        orientation="portrait"
      >
        <div
          id="document-editor-scale"
          className="scale"
          style={{ transform: "scale(1)" }}
        >
          {pages.length > 0
            ? pages.map((Page, index) => {
                return (
                  <Page
                    key={index}
                    index={index}
                    page={index + 1}
                    removePage={() => removePage(index)}
                    {...props}
                  />
                );
              })
            : null}
        </div>
      </div>

      <section className="text-center mt-5">
        <button
          className="btn btn-outline-success text-center"
          type="button"
          onClick={addNewPage}
          style={{border: "1px solid #F0B429", color: "#F0B429"}}
        >
          Add New Section
        </button>
      </section>
    </section>
  );
};

export default DraftContainer;
